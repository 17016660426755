import {
	LOGOUT_USER,
	UPDATE_DASHBOARD_RESPONSE,
	SET_BALANCE_VISIBILITY,
} from "../actions/types";

const initState = {
	dashboardResponse: {},
	isBalanceHidden: localStorage.getItem("isBalanceHidden") || true,
};

export default (state = initState, action) => {
	switch (action.type) {
		case UPDATE_DASHBOARD_RESPONSE:
			return { ...state, dashboardResponse: action.payload };
		case LOGOUT_USER:
			return { ...state, dashboardResponse: {} };
		case SET_BALANCE_VISIBILITY:
			return {
				...state,
				isBalanceHidden: action.payload,
			};
		default:
			return state;
	}
};
