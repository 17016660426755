import React from "react";
import { connect } from "react-redux";
import { switchModes } from "../actions/styleActions";
import { ReactSVG } from "react-svg";
import { nightTheme } from "./themeModes";
import TheMoon from "./files/moon.svg";
import TheSun from "./files/sun.svg";
import "./SwitchModes.css";

const SwitchModes = ({ theme, switchModes }) => {
    const modeImage = theme === nightTheme ? TheSun : TheMoon;
    return (
        <div
            className={`switch-modes ${theme}`}
            onClick={() => switchModes(theme)}>
            <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
                <ReactSVG src={modeImage} className='svgContainer' />
            </div>
        </div>
    );
};

export default connect((state) => ({ theme: state.style.theme }), {
    switchModes,
})(SwitchModes);
