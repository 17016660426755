import React from "react";
import "./CustomPinInput.scss";
import ReactPinInput from "react-pin-input";
import { useSelector } from "react-redux";
import { nightTheme } from "SwitchModes/themeModes";

export default function CustomPinInput({
	className,
	hasDashedBorder,
	length,
	focus = true,
	secret,
	containerStyle,
	inputStyle,
	onChange,
	onComplete,
	title,
}) {
	const style = useSelector((state) => state.style);
	let pinInput;

	const isDarkMode = style.theme === nightTheme;

	// Manually focus first pin input in modal
	React.useEffect(() => {
		if (focus) pinInput.focus();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div
			className={`custom-pin-input ${
				hasDashedBorder ? "has-dashed-border" : ""
			} ${className ? className : ""}`}
		>
			{title && <p>{title}</p>}

			<ReactPinInput
				ref={(n) => (pinInput = n)}
				length={length}
				focus={focus}
				secret={secret}
				initialValue=""
				type="numeric"
				inputMode="number"
				style={{
					padding: "0 8px",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					columnGap: "45px",
					...containerStyle,
				}}
				inputStyle={{
					width: "50px",
					height: "50px",
					margin: "0",
					textAlign: "center",
					fontSize: "16px",
					background:
						"linear-gradient(95.09deg,#d0a23c -10.51%,#77502f 109.52%)",
					borderRadius: "50%",
					borderColor: "#B38C0A",

					color: isDarkMode ? "#fff" : "#000",
					...inputStyle,
				}}
				inputFocusStyle={{
					outline: "none",
					borderColor: "#B38C0A",
					// boxShadow: "0 0 0 2px #e1f4e7",
				}}
				onChange={onChange}
				onComplete={onComplete}
			/>
		</div>
	);
}
