import ModalHeader from "components/utils/Modal/CustomModalHeader/CustomModalHeader";
import Modal from "components/utils/Modal/Modal";
import { ERROR, FINISHED, IDLE, LOADING } from "config/enums";
import React, { useState } from "react";
import ConfirmationSuccessModal from "./ConfirmationSuccessModal";
import { Formik } from "formik";
import * as yup from "yup";
import CustomCurrencyInput from "components/utils/CustomCurrencyInput/CustomCurrencyInput";
import { getErrorMessage, parseAmount } from "components/utils/helpers";
import { SmallLoader } from "components/utils/SmallLoader";
import { ToastsStore } from "react-toasts";
import { API } from "lib/api";
import { withdrawEmergencySavings } from "lib/api/apiRoutesConfig/services/SavingsMS/savingsGoals/subRoutes";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const formValidationSchema = yup.object().shape({
	amount: yup.number().required("Amount is required"),
});

export default function WithdrawalModal({
	show,
	onClose,
	goalId,
	// refetch,
	savingBalance,
}) {
	const [currentState, setCurrentState] = useState(IDLE);
	const [successMsg, setSuccessMsg] = useState("");

	const history = useHistory();

	const renderBasedOnState = () => {
		switch (currentState) {
			case FINISHED:
				return (
					<ConfirmationSuccessModal
						message={successMsg}
						onClose={onClose}
						onClick={() => {
							setCurrentState(IDLE);
							onClose();
							history.push("/dashboard");
						}}
					/>
				);

			default:
				return (
					<WithdrawalForm
						goalId={goalId}
						currentState={currentState}
						setSuccessMsg={(message) => setSuccessMsg(message)}
						setCurrentState={(state) => setCurrentState(state)}
						savingBalance={savingBalance}
					/>
				);
		}
	};

	return (
		<Modal show={show}>
			{currentState !== FINISHED && (
				<ModalHeader
					heading="Emergency withdrawal"
					className="p-0"
					headerClassName="setup-modals-header px-sm-5 px-3 py-3"
					dismissable={onClose}
				/>
			)}
			<>{renderBasedOnState()}</>
		</Modal>
	);
}

const WithdrawalForm = ({
	goalId,
	setSuccessMsg,
	currentState,
	setCurrentState,
	savingBalance,
}) => {
	const initialFormValues = {
		amount: savingBalance,
	};

	const { dashboardResponse } = useSelector((state) => ({
		dashboardResponse: state.dashboard.dashboardResponse,
	}));

	const { accountId: creditAccountId } = dashboardResponse?.bankAccounts
		? dashboardResponse.bankAccounts[0]
		: {};

	const handleWithdrawal = async (values) => {
		setCurrentState(LOADING);
		try {
			const response = await API.post(withdrawEmergencySavings, {
				...values,
				goalId,
				creditAccountId,
			});
			if (response.status) {
				setCurrentState(FINISHED);
				setSuccessMsg(response.data.message);
			}
		} catch (err) {
			setCurrentState(ERROR);
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		}
	};

	return (
		<div className="es-automatic-deposit px-sm-5 px-3">
			<Formik
				initialValues={initialFormValues}
				validationSchema={formValidationSchema}
				onSubmit={(values) => handleWithdrawal(values)}
			>
				{({
					values,
					errors,
					touched,
					handleSubmit,
					setFieldValue,
					handleBlur,
				}) => {
					return (
						<form
							className="py-3"
							onSubmit={(e) => {
								e.preventDefault();
								handleSubmit();
							}}
						>
							<div className="mb-4">
								<CustomCurrencyInput
									className="es-input"
									name="amount"
									label="How much would you like to withdraw?"
									id="amount"
									value={values.amount}
									placeholder="Enter Amount"
									onBlur={handleBlur}
									error={touched.amount && errors.amount}
									onChange={(value) => {
										setFieldValue(
											"amount",
											parseAmount(value)
										);
									}}
								/>
							</div>

							<button
								className="btn create-acc mt-3"
								disabled={currentState === LOADING}
								type="submit"
							>
								{currentState === LOADING ? (
									<SmallLoader />
								) : (
									"Confirm Withdrawal"
								)}
							</button>
						</form>
					);
				}}
			</Formik>
		</div>
	);
};
