import PropTypes from "prop-types";
import React, { Component } from "react";
import Select from "react-select";
import { SelectBox } from "../../../../styles/SelectBox";
import { API } from "../../../../../lib/api";
import { getAllBanksEndpoint } from "../../../../../lib/api/apiRoutesConfig/services/FundsManagementMS/common/subRoutes";
import { getAllBanksList as accountServiceBankList } from "../../../../../lib/api/apiRoutesConfig/services/AccountMS/common/subRoutes";

class BankInputSelect extends Component {
	state = {
		isLoading: false,
		banksList: [],
	};

	componentDidMount = () => {
		this.getBankCodes();
	};

	getBankCodes = async () => {
		try {
			this.setState({ isLoading: false });
			const { isOnboardingInput } = this.props;
			let URL = isOnboardingInput
				? accountServiceBankList
				: getAllBanksEndpoint;
			let res = await API.get(URL);
			if (res.status === 200) {
				let banksList = [];
				res.data.data.map((item) =>
					banksList.push({
						label: item.name,
						value: item.code,
						...item,
					})
				);
				this.setState({ banksList: banksList });
			}
		} catch (err) {
			this.setState({ banksList: [] });
		}
	};

	render = () => {
		const { error, inputName, selectedBank, handleSelect } = this.props;
		const { banksList, isLoading } = this.state;

		return (
			<div className="form-group mb-4">
				<label className="other-bank-label">Choose Bank</label>
				<Select
					name={inputName}
					value={selectedBank}
					onChange={handleSelect}
					isSearchable={true}
					isLoading={isLoading}
					classNamePrefix="react-select"
					styles={SelectBox(error)}
					options={banksList}
					placeholder="Select bank"
					cacheOptions
					defaultOptions
				/>
			</div>
		);
	};
}

BankInputSelect.propTypes = {
	error: PropTypes.any,
	handleSelect: PropTypes.any,
	inputName: PropTypes.string.isRequired,
};

export default BankInputSelect;
