import common from "../";
export const {
	getAllBanksEndpoint,
	getSpendingTagsEndpoint,
	getEmbassies,
	getEmbassyAddresses,
} = {
	getAllBanksEndpoint: `${common}/banks`,
	getSpendingTagsEndpoint: `${common}/spending-tags`,
	getEmbassies: `${common}/embassies`,
	getEmbassyAddresses: (embassyId) =>
		`${common}/embassies/${embassyId}/embassy-addresses`,
};
