/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Modal from "components/utils/Modal/Modal";
import ModalHeader from "components/utils/Modal/CustomModalHeader/CustomModalHeader";
import { useHistory } from "react-router-dom";
import { formatCurrency } from "components/utils/helpers";
import { SmallLoader } from "components/utils/SmallLoader";

const SplitPaymentModal = ({ show, onClose, nextModal, isLoading }) => {
	return (
		<>
			<Modal show={show} scrollable>
				<ModalHeader
					heading={"Split Payment"}
					className="p-0"
					headerClassName="setup-modals-header px-sm-5 px-3 py-3"
					dismissable={() => onClose?.()}
				/>
				<Content nextModal={nextModal} isLoading={isLoading} />
			</Modal>
		</>
	);
};
export default SplitPaymentModal;

const Content = ({ nextModal, isLoading }) => {
	const history = useHistory();
	const { location } = history;

	return (
		<div className="split-payment-modal">
			<div>
				<div className=" content">
					<p className="title">Amount to Send </p>
					<p className=" desc">
						{formatCurrency(location.state.data.amount)}
					</p>
				</div>
				<div className=" content">
					<p className="title">Recipient's Name</p>
					<p className=" desc">{location.state.data.sender}</p>
				</div>
				<div className=" content">
					<p className="title">Bank Name </p>
					<p className=" desc">Mint Finex</p>
				</div>
			</div>
			<button
				disabled={isLoading}
				onClick={() => {
					nextModal();
				}}
				className="btn btn-mint-default"
				type="button"
			>
				{isLoading ? <SmallLoader /> : "Confirm Transfer"}
			</button>
		</div>
	);
};
