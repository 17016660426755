import React, { Component } from "react";
import VerifyFPPhoneModel from "./VerifyFPPhoneModel/VerifyFPPhoneModel";
import FadeIn from "../../../dashboard/components/effects/FadeIn";

class VerifyFPPhoneNumber extends Component {
	render = () => {
		const { params: { sessionId } = {} } = this.props.match || {};
		return (
			<div className="auth-container">
				<div className="row justify-content-center align-items-center">
					<div className="col-xl-6 col-lg-8 col-md-9">
						<div className="">
							<FadeIn>
								<VerifyFPPhoneModel
									history={this.props.history}
									sessionId={sessionId}
								/>
							</FadeIn>
						</div>
					</div>
				</div>
			</div>
		);
	};
}

export default VerifyFPPhoneNumber;
