import React, { useEffect } from "react";
import { useState } from "react";
import {
	FINISHED,
	IDLE,
	LOADING,
	ERROR,
	DIRECTORS_DOCUMENTATION,
	TRUSTEES_DOCUMENTATION,
} from "../../../../../../config/enums";
import "./SetupModals.scss";
import { Formik } from "formik";
import * as yup from "yup";
import Modal from "../../../../../utils/Modal/Modal";
import ModalHeader from "../../../../../utils/Modal/CustomModalHeader/CustomModalHeader";
import FormInputGroup from "../../../../../utils/FormInputGroup/FormInputGroup";
import CommonSelect from "../../../../../utils/CommonSelect/CommonSelect";
import { Label } from "reactstrap";
import FormDatePickerGroup from "../../../../../utils/FormDatePickerGroup/FormDatePickerGroup";
import { format as formatDate } from "date-fns";
import SetupSuccessModal from "./SetupSuccessModal";
import { getErrorMessage } from "components/utils/helpers";
import { API } from "lib/api";
import {
	uploadDirectorPersonalInfo,
	uploadTrusteePersonalInfo,
} from "lib/api/apiRoutesConfig/services/Corporate/AccountServiceMS/accountSetup/subRoutes";
import { useSelector } from "react-redux";
import { ToastsStore } from "react-toasts";
import { SmallLoader } from "components/utils/SmallLoader";
import PhoneNumberInput from "components/utils/PhoneNumberInput/PhoneNumberInput";
import { getCorporateRoles } from "lib/api/apiRoutesConfig/services/AccountMS/common/subRoutes";
import CustomRadioButton from "components/utils/CustomRadioButton/CustomRadioButton";

const formValidationSchema = yup.object().shape({
	email: yup
		.string()
		.required("Email is required")
		.email("Please use a valid email"),
	gender: yup.string().required("Gender is required"),
	bvn: yup
		.string()
		.trim()
		.min(11, "Enter a value up to 11 digits")
		.required("BVN is required"),
	role: yup
		.object()
		.required("Role is required")
		.nullable()
		.shape({ label: yup.string(), value: yup.string() }),
});

export default function PersonalInfoModal({
	show,
	onClose,
	onSuccess,
	documentationType,
}) {
	const [currentState, setCurrentState] = useState(IDLE);
	const [successMsg, setSuccessMsg] = useState("");

	const renderBasedOnState = () => {
		switch (currentState) {
			case FINISHED:
				return (
					<SetupSuccessModal
						title="Congratulations!"
						message={successMsg}
						buttonLabel="Close"
						onClick={() => onSuccess?.()}
					/>
				);
			default:
				return (
					<PersonalInfoForm
						setCurrentState={(state) => setCurrentState(state)}
						setSuccessMsg={(message) => setSuccessMsg(message)}
						currentState={currentState}
						documentationType={documentationType}
					/>
				);
		}
	};

	return (
		<Modal show={show}>
			{currentState !== FINISHED && (
				<ModalHeader
					heading={
						documentationType === TRUSTEES_DOCUMENTATION
							? "Add Trustees Details"
							: "Add Director Details"
					}
					className="p-0"
					headerClassName="setup-modals-header px-sm-5 px-3 py-3"
					dismissable={() => onClose?.()}
				/>
			)}
			<>{renderBasedOnState()}</>
		</Modal>
	);
}

const PersonalInfoForm = ({
	currentState,
	setCurrentState,
	setSuccessMsg,
	documentationType,
}) => {
	const dashboardResponse = useSelector(
		(state) => state.dashboard.dashboardResponse
	);

	const [isRoleLoading, setIsRoleLoading] = useState(false);
	const [roles, setRoles] = useState([]);
	const [dialCode, setDialCode] = useState("");

	const initialFormValues = {
		email: "",
		gender: "",
		bvn: "",
		address: "",
		phoneNumber: "",
		role: null,
		dateOfBirth: undefined,
	};

	const getRoles = async () => {
		setIsRoleLoading(true);
		try {
			const response = await API.get(getCorporateRoles);
			const { status, data } = response || {};
			if (status === 200) {
				setRoles(data.data);
			}
		} catch (err) {
			setRoles([]);
			ToastsStore.error(getErrorMessage(err), 6000, "setup-toast");
		} finally {
			setIsRoleLoading(false);
		}
	};

	// get appropriate url to be used based on documentation type
	const getUrlBasedOnType = () => {
		switch (documentationType) {
			case DIRECTORS_DOCUMENTATION:
				return uploadDirectorPersonalInfo;
			case TRUSTEES_DOCUMENTATION:
				return uploadTrusteePersonalInfo;
			default:
				return "";
		}
	};

	const onHandleSubmit = async (values) => {
		setCurrentState(LOADING);
		try {
			const res = await API.post(
				getUrlBasedOnType(),
				{
					...values,
					phoneNumber: values.phoneNumber
						? "+" + dialCode + values.phoneNumber
						: "",
					role: values.role?.value,
					dateOfBirth: values.dateOfBirth
						? formatDate(values.dateOfBirth, "dd/MM/yyyy")
						: values.dateOfBirth,
				},
				{
					params: {
						accountId: dashboardResponse.mintAccountId,
					},
				}
			);
			if (res.status === 200) {
				setCurrentState(FINISHED);
				setSuccessMsg(res.data.message);
			}
		} catch (err) {
			setCurrentState(ERROR);
			ToastsStore.error(getErrorMessage(err), 6000, "setup-toast");
			console.log(err);
		}
	};

	useEffect(() => {
		getRoles();
	}, []);

	return (
		<Formik
			initialValues={initialFormValues}
			validationSchema={formValidationSchema}
			onSubmit={(values) => onHandleSubmit(values)}
		>
			{({
				values,
				errors,
				touched,
				handleSubmit,
				setFieldValue,
				handleChange,
			}) => {
				return (
					<form
						className="setup-modals-form px-sm-5 px-3 pt-4 pb-5 row"
						onSubmit={(e) => {
							e.preventDefault();
							handleSubmit();
						}}
					>
						{documentationType === DIRECTORS_DOCUMENTATION && (
							<p className="setup-modals-form-lead col-12">
								Add a director to your corporate account by
								filling the form below
							</p>
						)}

						<div className="mb-4 col-12">
							<FormInputGroup
								id="email"
								label="Email"
								type="email"
								name="email"
								placeholder="Enter email"
								className="setup-modal-input"
								value={values.email}
								onChange={handleChange}
								error={touched.email && errors.email}
							/>
						</div>

						<div className="mb-4 col-12">
							<Label for="cardType">Phone Number</Label>
							<PhoneNumberInput
								inputFieldName="phoneNumber"
								inputValue={values.phoneNumber}
								handlePhoneNumberChange={(
									__,
									value,
									countryData
								) => {
									let Numbers = value.replace(/[^0-9]/g, "");
									value = Numbers.replace(/^(-?)0+/, "");
									setDialCode(countryData.dialCode);
									setFieldValue("phoneNumber", value);
								}}
							/>
						</div>

						<div className="mb-4 col-12">
							<FormInputGroup
								id="bvn"
								label="BVN (11 Digits)"
								name="bvn"
								placeholder="Enter bvn"
								className="setup-modal-input"
								value={values.bvn}
								onChange={(e) => {
									const value = e.target.value.replace(
										/[^0-9]/g,
										""
									);
									if (value.length <= 11) {
										setFieldValue(e.target.name, value);
									}
								}}
								error={touched.bvn && errors.bvn}
							/>
						</div>

						<div className="col-12 mb-4">
							<FormDatePickerGroup
								isClearable={false}
								placeholder="DD/MM/YYYY"
								label="Date of Birth"
								selected={values.dateOfBirth}
								max={new Date()}
								name="dateOfBirth"
								onChange={(date) =>
									setFieldValue("dateOfBirth", date)
								}
							/>
						</div>

						<div className="mb-4 col-12">
							<FormInputGroup
								id="address"
								label="Address"
								name="address"
								placeholder="Enter address"
								className="setup-modal-input"
								value={values.address}
								onChange={handleChange}
								error={touched.address && errors.address}
							/>
						</div>

						<div className="mb-4 col-12">
							<Label>Gender</Label>
							<div className="row">
								<div className="col-3 mb-2 mr-2">
									<CustomRadioButton
										checked={values.gender === "MALE"}
										name="gender"
										label="Male"
										onChange={() =>
											setFieldValue("gender", "MALE")
										}
									/>
								</div>
								<div className="col-3 mb-2">
									<CustomRadioButton
										checked={values.gender === "FEMALE"}
										name="gender"
										label="Female"
										onChange={() =>
											setFieldValue("gender", "FEMALE")
										}
									/>
								</div>
							</div>
							{touched.gender && errors.gender && (
								<span className="error-msg">
									{errors.gender}
								</span>
							)}
						</div>

						<div className="mb-4 col-12">
							<Label for="role">Role</Label>
							<CommonSelect
								name="role"
								placeholder="Select Role"
								error={touched.role && errors.role}
								handleChange={(value) =>
									setFieldValue("role", value)
								}
								options={roles.map((role) => ({
									label: role?.name,
									value: role?.code,
								}))}
								showErrorMsg
								isLoading={isRoleLoading}
							/>
						</div>

						<div className="col-12">
							<button
								className="btn btn-mint-default"
								type="submit"
								disabled={currentState === LOADING}
							>
								{currentState === LOADING ? (
									<SmallLoader />
								) : (
									"Submit"
								)}
							</button>
						</div>
					</form>
				);
			}}
		</Formik>
	);
};
