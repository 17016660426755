import React, { Component } from "react";
import Modal from "../../../../utils/Modal/Modal";
import ModalHeader from "../../../../utils/Modal/CustomModalHeader/CustomModalHeader";

class DeleteBeneficiary extends Component {
    render() {
        const {
            show,
            toggleModal,
            handleDeletion,
            selectedBeneficiary: { accountName, beneficiaryId },
        } = this.props;
        return (
            <Modal show={show} size='md'>
                <ModalHeader heading='Remove Beneficiary' />
                <div className='p-4'>
                    <p className='myText mb-2'>
                        Confirm to remove <strong>{accountName}</strong> from
                        your list of beneficiaries
                    </p>
                    <div className='dialog-footer mt-5 mb-0'>
                        <div className='row'>
                            <div className='col-6'>
                                <button
                                    className='btn cancel'
                                    data-dismiss='modal'
                                    type='button'
                                    aria-label='Close'
                                    onClick={toggleModal}>
                                    CANCEL
                                </button>
                            </div>

                            <div className='col-6'>
                                <button
                                    className='btn create-acc'
                                    type='submit'
                                    onClick={() => handleDeletion(beneficiaryId)}>
                                    Remove
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default DeleteBeneficiary;
