import React from "react";
import { useState } from "react";
import {
	FINISHED,
	IDLE,
	LOADING,
	ERROR,
	BUSINESS_DOCUMENTATION,
	DIRECTORS_DOCUMENTATION,
	TRUSTEES_DOCUMENTATION,
} from "../../../../../../config/enums";
import "./SetupModals.scss";
import { Formik } from "formik";
import * as yup from "yup";
import Modal from "../../../../../utils/Modal/Modal";
import ModalHeader from "../../../../../utils/Modal/CustomModalHeader/CustomModalHeader";
import FormInputGroup from "../../../../../utils/FormInputGroup/FormInputGroup";
import CommonSelect from "../../../../../utils/CommonSelect/CommonSelect";
import { Label } from "reactstrap";
// import FormDatePickerGroup from "../../../../../utils/FormDatePickerGroup/FormDatePickerGroup";
import { format as formatDate } from "date-fns";
import CustomFileUploader from "../../../../../utils/CustomFileUploader/CustomFileUploader";
import SetupSuccessModal from "./SetupSuccessModal";
import { getErrorMessage } from "components/utils/helpers";
import { API } from "lib/api";
import {
	identityCard,
	uploadDirectorGovtId,
	uploadTrusteeGovtId,
} from "lib/api/apiRoutesConfig/services/Corporate/AccountServiceMS/accountSetup/subRoutes";
import { useSelector } from "react-redux";
import { ToastsStore } from "react-toasts";
import { SmallLoader } from "components/utils/SmallLoader";
import FormDatePickerGroup from "components/utils/FormDatePickerGroup/FormDatePickerGroup";

const SUPPORTED_FORMATS = ["image/png", "image/jpg", "image/jpeg"];
const FILE_SIZE = 3_000_000;

const formValidationSchema = yup.object().shape({
	cardType: yup
		.object()
		.required("Card type is required")
		.nullable()
		.shape({ label: yup.string(), value: yup.string() }),
	cardNumber: yup.string().required("Card number is required"),
	card: yup
		.mixed()
		.test("is-a-supported-type", "Unsupported file format", (value) => {
			if (value) return SUPPORTED_FORMATS.includes(value?.type);
			return true;
		})
		.test(
			"is-not-more-than-max-file-size",
			"File size is too large",
			(value) => {
				if (value) return value?.size <= FILE_SIZE;
				return true;
			}
		)
		.required("Card is required"),
	expiryDate: yup.mixed().when("cardType", {
		is: (type) =>
			type?.value !== "NATIONAL_ID" && type?.value !== "VOTERS_CARD",
		then: yup.date().required("Expiry date is required"),
		otherwise: yup.mixed(),
	}),
});

export default function GovtIssuedIdModal({
	show,
	onClose,
	onSuccess,
	documentationType,
}) {
	const [currentState, setCurrentState] = useState(IDLE);
	const [successMsg, setSuccessMsg] = useState("");

	const renderBasedOnState = () => {
		switch (currentState) {
			case FINISHED:
				return (
					<SetupSuccessModal
						title="Congratulations"
						message={successMsg}
						buttonLabel="Close"
						onClick={() => onSuccess?.()}
					/>
				);
			default:
				return (
					<GovtIssuedIdForm
						setCurrentState={(state) => setCurrentState(state)}
						setSuccessMsg={(message) => setSuccessMsg(message)}
						currentState={currentState}
						documentationType={documentationType}
					/>
				);
		}
	};

	return (
		<Modal show={show}>
			{currentState !== FINISHED && (
				<ModalHeader
					heading="Government Issued ID Card"
					className="p-0"
					headerClassName="setup-modals-header px-sm-5 px-3 py-3"
					dismissable={() => onClose?.()}
				/>
			)}
			<>{renderBasedOnState()}</>
		</Modal>
	);
}

const GovtIssuedIdForm = ({
	currentState,
	setCurrentState,
	setSuccessMsg,
	documentationType,
}) => {
	const dashboardResponse = useSelector(
		(state) => state.dashboard.dashboardResponse
	);

	const initialFormValues = {
		cardType: null,
		card: null,
		cardNumber: "",
		expiryDate: undefined,
	};

	const ID_TYPE_OPTIONS = [
		{
			label: "National ID",
			value: "NATIONAL_ID",
		},
		{
			label: "Drivers License",
			value: "DRIVERS_LICENCE",
		},
		{
			label: "Voters Card",
			value: "VOTERS_CARD",
		},
		{
			label: "International Passport",
			value: "INTERNATIONAL_PASSPORT",
		},
	];

	// get appropriate url to be used based on documentation type
	// BUSINESS_DOCUMENTATION || DIRECTORS_DOCUMENTATION || TRUSTEES_DOCUMENTATION
	const getUrlBasedOnType = () => {
		switch (documentationType) {
			case BUSINESS_DOCUMENTATION:
				return identityCard(dashboardResponse.mintAccountId);
			case DIRECTORS_DOCUMENTATION:
				return uploadDirectorGovtId;
			case TRUSTEES_DOCUMENTATION:
				return uploadTrusteeGovtId;
			default:
				return "";
		}
	};

	const onHandleSubmit = async (values) => {
		setCurrentState(LOADING);
		const payload = new FormData();

		documentationType !== BUSINESS_DOCUMENTATION &&
			payload.append("accountId", dashboardResponse.mintAccountId);

		documentationType !== BUSINESS_DOCUMENTATION &&
			payload.append("cardType", values.cardType?.value);

		documentationType !== BUSINESS_DOCUMENTATION &&
			values?.expiryDate &&
			payload.append(
				"expiryDate",
				formatDate(values.expiryDate, "dd/MM/yyyy")
			);

		documentationType !== BUSINESS_DOCUMENTATION &&
			payload.append("cardNumber", values.cardNumber);

		// card is always formData
		payload.append("card", values.card || "");

		try {
			const res =
				documentationType !== BUSINESS_DOCUMENTATION
					? await API.post(getUrlBasedOnType(), payload)
					: await API.put(getUrlBasedOnType(), payload, {
							params: {
								cardNumber: values.cardNumber,
								cardType: values.cardType?.value,
								expiryDate: values.expiryDate
									? formatDate(
											values.expiryDate,
											"dd/MM/yyyy"
									  )
									: values.expiryDate,
							},
					  });
			if (res.status === 200) {
				setCurrentState(FINISHED);
				setSuccessMsg(res.data.message);
			}
		} catch (err) {
			setCurrentState(ERROR);
			ToastsStore.error(getErrorMessage(err), 6000, "setup-toast");
		}
	};

	return (
		<Formik
			initialValues={initialFormValues}
			validationSchema={formValidationSchema}
			onSubmit={(values) => onHandleSubmit(values)}
		>
			{({
				values,
				errors,
				touched,
				handleSubmit,
				setFieldValue,
				handleChange,
			}) => {
				const showExpiryDate =
					values.cardType?.value &&
					values.cardType?.value !== "NATIONAL_ID" &&
					values.cardType?.value !== "VOTERS_CARD";

				return (
					<form
						className="setup-modals-form px-sm-5 px-3 pt-4 pb-5 row"
						onSubmit={(e) => {
							e.preventDefault();
							handleSubmit();
						}}
					>
						<div className="mb-3 col-12">
							<Label for="cardType">ID Type</Label>
							<CommonSelect
								name="cardType"
								placeholder="Choose ID Type"
								error={touched.cardType && errors.cardType}
								handleChange={(value) =>
									setFieldValue("cardType", value)
								}
								options={ID_TYPE_OPTIONS}
								showErrorMsg
							/>
						</div>

						<div className="col-12 mb-3">
							<div className="row">
								<div
									className={
										showExpiryDate ? "col-sm-6" : "col-12"
									}
								>
									<FormInputGroup
										id="cardNumber"
										label="ID Number"
										name="cardNumber"
										placeholder="Enter ID Number"
										className="setup-modal-input"
										value={values.cardNumber}
										onChange={handleChange}
										error={
											touched.cardNumber &&
											errors.cardNumber
										}
									/>
								</div>

								{showExpiryDate && (
									<div className="col-sm-6">
										<FormDatePickerGroup
											isClearable={false}
											placeholder="DD/MM/YYYY"
											label="Expiry Date"
											selected={values.expiryDate}
											min={new Date()}
											name="expiryDate"
											onChange={(date) =>
												setFieldValue(
													"expiryDate",
													date
												)
											}
											error={
												touched.expiryDate &&
												errors.expiryDate
											}
										/>
									</div>
								)}
							</div>
						</div>

						<div className="mb-3 col-12">
							<CustomFileUploader
								label="Upload ID"
								name="card"
								error={touched.card && errors.card}
								fileName={values.card?.name}
								onChange={(file) => {
									setFieldValue("card", file);
									// console.log(file)
								}}
							/>
						</div>

						<div className="col-12">
							<button
								className="btn btn-mint-default"
								type="submit"
								disabled={currentState === LOADING}
							>
								{currentState === LOADING ? (
									<SmallLoader />
								) : (
									"Upload and Save"
								)}
							</button>
						</div>
					</form>
				);
			}}
		</Formik>
	);
};
