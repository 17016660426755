import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import PageNavigatorV2 from "../../../utils/PageNavigator/PageNavigatorV2";
import GroomPanel from "../../../utils/GroomPanel/GroomPanel";
import { RouteTabSwitch } from "../../../../utils/RouteSwitch/RouteSwitch";
import EmptyState from "../../../../utils/ResponseState/EmptyState";
import ContentWrapper from "../../../../utils/ResponseState/ContentWrapper";
import { ReactComponent as InvestmentIcon } from "../../../../../assets/img/investment.svg";
import { ReactComponent as Plus } from "../../../../../assets/img/small-pluss.svg";
import investments from "../../../../../lib/api/apiRoutesConfig/services/SavingsMS/investments";
import { API } from "../../../../../lib/api";
import InvestmentRoutes from "../routes";
import { responseStatus } from "../../../../constants";
import { getErrorMessage } from "../../../../utils/helpers";
import "./InvestmentWrapper.scss";
import { useSelector } from "react-redux";
import { INDIVIDUAL } from "config/enums";
import UserRoleWarning from "components/utils/UserRoleWarning/UserRoleWarning";

const { LOADING, FINISHED, ERROR } = responseStatus;

const InvestmentWrapper = ({ investmentStatus, component: Component }) => {
	const { push } = useHistory();

	const [currentState, setCurrentState] = useState();
	const [investmentData, setInvestmentData] = useState([]);
	const [errorMsg, setErrorMsg] = useState();
	const { loginResponse, activeAccountType } = useSelector(
		(state) => state.auth
	);

	const isEmpty = investmentData?.investments?.totalRecords === 0;
	const status = _.startCase(investmentStatus.toLowerCase());

	const accountType =
		activeAccountType === INDIVIDUAL
			? "activeIndividualAccount"
			: "activeCorporateAccount";

	const { currentRole } = loginResponse[accountType]?.profileSetting || {};
	const isApprover = currentRole === "APPROVER";

	useEffect(() => {
		fetchInvestment();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchInvestment = async () => {
		try {
			setCurrentState(LOADING);
			const res = await API.get(investments, {
				params: {
					size: 100,
					page: 0,
					investmentStatus,
				},
			});

			const { status, data } = res || {};
			if (status === 200) {
				setInvestmentData(data?.data);
				setCurrentState(FINISHED);
			}
		} catch (err) {
			setCurrentState(ERROR);
			setErrorMsg(getErrorMessage(err));
		}
	};

	return (
		<>
			<Helmet>
				<title>{process.env.REACT_APP_NAME} - Investments</title>
			</Helmet>

			<PageNavigatorV2
				title="Investments"
				subtitle="Manage of all your investment plans"
			>
				<button
					className="btn create-acc px-3 text-nowrap"
					onClick={() => push("/investments/new")}
				>
					New Investment <Plus className="ml-2" />
				</button>
			</PageNavigatorV2>

			<div className="py-3">
				<RouteTabSwitch
					routes={InvestmentRoutes}
					cls="justify-content-start"
				/>
			</div>

			<div className="investment">
				<ContentWrapper
					currentState={currentState}
					errorMsg={errorMsg}
					refetch={fetchInvestment}
				>
					<>
						{isApprover ? (
							<GroomPanel cls="flex__center">
								<UserRoleWarning />
							</GroomPanel>
						) : isEmpty ? (
							<GroomPanel cls="flex__center">
								<EmptyState
									icon={InvestmentIcon}
									title={`You have no ${status} investments`}
									subtitle="Create a new investment by tapping on the button below"
									btnName="Create New Investment"
									handleClick={() => push("/investments/new")}
								/>
							</GroomPanel>
						) : (
							<Component data={investmentData} />
						)}
					</>
				</ContentWrapper>
			</div>
		</>
	);
};

export default InvestmentWrapper;
