import React from "react";
import { PENDING, VERIFIED } from "../../../../../../config/enums";
import "./SetupCard.scss";

export default function SetupCard({
	onClick,
	title,
	description,
	status,
	isDisabled,
}) {
	return (
		<div className="col-sm-6 mb-4">
			<div
				className={`setup-card ${
					isDisabled ? "setup-card-disabled" : ""
				}`}
			>
				<h5>{title}</h5>
				<p>{description}</p>
				{status === PENDING ? (
					<span className="setup-card-status mt-auto">
						Pending Verification
					</span>
				) : status === VERIFIED || status === true ? (
					<span className="setup-card-status verified mt-auto">
						Verified
					</span>
				) : (
					<button
						className="btn btn-custom-bordered mt-auto"
						onClick={() => onClick?.()}
					>
						Upload
					</button>
				)}
			</div>
		</div>
	);
}
