/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import Bill from "./utils/assets/bill.svg";
import RequestCard from "./utils/RequestCard";
import { ToastsStore } from "react-toasts";
import { MintInfinite } from "components/dashboard/utils/ShineLoader/ShineLoader";
import { getErrorMessage } from "components/utils/helpers";
import FailedTryAgain from "components/dashboard/utils/FailedTryAgain/FailedTryAgain";
import { API } from "lib/api";
import { ERROR, FINISHED, IDLE, LOADING, EQUAL } from "config/enums";
import { useHistory } from "react-router-dom";
import Reject from "./utils/Reject";
import {
	setNextStep,
	setSplitPayment,
	setParticipants,
	setSuccessModal,
} from "actions/splitPayment";
import { useDispatch } from "react-redux";
import {
	getSplitPaymentsPending,
	rejectSplitpayment,
} from "lib/api/apiRoutesConfig/services/BillsService/subRoutes";

const PendingRequest = () => {
	const dispatch = useDispatch();

	const initialFormValues = {
		name: "",
		description: "",
		amount: "",
		creditAccountId: "",
		splitPaymentType: EQUAL,
	};
	const history = useHistory();
	const [currentStatus, setCurrentStatus] = useState(IDLE);
	const [errorMsg, setErrorMsg] = useState("");
	const [pendingRequests, setPendingrequests] = useState([]);
	const [showRejectModal, setShowRejectModal] = useState(false);
	const [reject, setRejectId] = useState(null);

	// Get Data details
	const getAllPendingRequest = async () => {
		setCurrentStatus(LOADING);
		try {
			const res = await API.get(getSplitPaymentsPending);
			const { status, data } = res || {};

			if (status === 200) {
				setPendingrequests(data.data);
				setCurrentStatus(FINISHED);
			}
		} catch (err) {
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
			setErrorMsg(getErrorMessage(err));
			setCurrentStatus(FINISHED);
		}
	};

	// Reject split payment
	const handleRejectsplitpayment = async () => {
		setCurrentStatus(LOADING);
		try {
			const res = await API.put(rejectSplitpayment(reject));
			const { status, data } = res || {};

			if (status === 200) {
				setCurrentStatus(FINISHED);
				setShowRejectModal(false);
				getAllPendingRequest();
				ToastsStore.success(data.message, 6000, "right-toast");
			}
		} catch (err) {
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
			setErrorMsg(getErrorMessage(err));
			setCurrentStatus(FINISHED);
		}
	};

	const renderBasedOnStatus = () => {
		switch (currentStatus) {
			case LOADING:
				return (
					<div className=" loading-requests ">
						<MintInfinite relative />
					</div>
				);

			case ERROR:
				return (
					<div className="d-flex w-100 justify-content-center h-100 item mt-5 align-items-center ">
						<FailedTryAgain
							errorMssg={errorMsg}
							handleTryAgain={() => getAllPendingRequest()}
						/>
					</div>
				);

			case FINISHED:
				return (
					<>
						<div className="request-container">
							{pendingRequests?.length === 0 && <EmptyState />}
							{pendingRequests?.map((obj, index) => (
								<RequestCard
									rejectAction={() => {
										setShowRejectModal(true);
										setRejectId(obj.id);
									}}
									acceptAction={() => {
										history.push(
											"/split-payment/pending-requests/pay",
											{
												data: obj,
											}
										);
									}}
									key={index}
									data={obj}
									isPending={true}
								/>
							))}
						</div>
					</>
				);

			default:
				return null;
		}
	};
	useEffect(() => {
		dispatch(setNextStep(1));
		dispatch(setSplitPayment(initialFormValues));
		dispatch(setParticipants([]));
		dispatch(setSuccessModal(false));
		getAllPendingRequest();
	}, []);

	return (
		<>
			{renderBasedOnStatus()}
			<Reject
				show={showRejectModal}
				onClose={() => {
					setShowRejectModal(false);
				}}
				nextAction={() => {
					handleRejectsplitpayment();
				}}
			/>
		</>
	);
};
export default PendingRequest;

const EmptyState = () => {
	return (
		<div className="request-empty-state">
			<span>
				<ReactSVG src={Bill} />
			</span>
			<p className="title">No Request Found!</p>
			<p className="sub-title">
				Requests sent by others will appear here
			</p>
		</div>
	);
};
