import { MintInfinite } from "components/dashboard/utils/ShineLoader/ShineLoader";
import { formatCurrency, getErrorMessage } from "components/utils/helpers";
import ModalHeader from "components/utils/Modal/CustomModalHeader/CustomModalHeader";
import Modal from "components/utils/Modal/Modal";
import { API } from "lib/api";
import { getTransactionReceiptDetails } from "lib/api/apiRoutesConfig/services/FundsManagementMS/transactions/subRoutes";
import React, { useState } from "react";
import { useEffect } from "react";
import { ToastsStore } from "react-toasts";
import "../../TransactionHistory.scss";

export default function ReceiptModal({ show, transaction, onClose }) {
	return (
		<Modal show={show}>
			<ModalHeader
				heading="Receipt"
				className="p-0"
				headerClassName="setup-modals-header px-sm-5 px-3 py-3"
				dismissable={onClose}
			/>

			<Receipt transaction={transaction} />
		</Modal>
	);
}

const Receipt = ({ transaction }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [receiptDetails, setReceiptDetails] = useState({});

	const {
		transactionAmount,
		recipientAccountName,
		paymentNarration,
		referenceNumber,
		transactionDate,
		recipientBankName,
		recipientAccountNumber,
		senderAccountName,
		transactionFee,
		sessionId,
	} = receiptDetails || {};

	const { internalReference } = transaction || {};

	const fetchTransactionReceipt = async () => {
		setIsLoading(true);
		try {
			const res = await API.get(
				getTransactionReceiptDetails(internalReference)
			);
			const { status, data } = res || {};
			if (status === 200) {
				setReceiptDetails(data.data);
			}
		} catch (err) {
			setReceiptDetails({});
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchTransactionReceipt();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (isLoading) {
		return (
			<div
				className="d-flex justify-content-center align-items-center"
				style={{ minHeight: "400px" }}
			>
				<MintInfinite relative />
			</div>
		);
	}

	return (
		<div className="th-summary px-sm-5 px-3">
			<div className="th-summary__info">
				<span>Receiver</span>
				<span className="text-capitalize">{recipientAccountName}</span>
			</div>

			<div className="th-summary__info">
				<span>Amount</span>
				<span className="text-capitalize amount">
					{formatCurrency(transactionAmount)}
				</span>
			</div>

			<div className="th-summary__info">
				<span>Paid on</span>
				<span className="text-capitalize">
					{new Date(transactionDate)
						.toLocaleDateString([], {
							year: "numeric",
							month: "numeric",
							day: "numeric",
						})
						.replace(/\//g, "-")}
				</span>
			</div>

			<div className="th-summary__info">
				<span>Receiver Bank</span>
				<span className="text-capitalize">{recipientBankName}</span>
			</div>

			<div className="th-summary__info">
				<span>Receiver Account Number</span>
				<span className="text-capitalize">
					{recipientAccountNumber}
				</span>
			</div>

			<div className="th-summary__info">
				<span>Sender</span>
				<span className="text-capitalize">{senderAccountName}</span>
			</div>

			<div className="th-summary__info">
				<span>Narration</span>
				<span className="text-capitalize narration">
					{paymentNarration}
				</span>
			</div>

			<div className="th-summary__info">
				<span>Transaction fees</span>
				<span className="text-capitalize">{transactionFee}</span>
			</div>

			<div className="th-summary__info">
				<span>Reference ID</span>
				<span className="text-capitalize">{referenceNumber}</span>
			</div>

			<div className="th-summary__info">
				<span>Session ID</span>
				<span
					className="text-capitalize"
					style={{ wordBreak: "break-word" }}
				>
					{sessionId}
				</span>
			</div>
		</div>
	);
};
