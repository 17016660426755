import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { formatCurrency } from "components/utils/helpers";
import { API } from "lib/api";
import { getErrorMessage } from "components/utils/helpers";
import { ToastsStore } from "react-toasts";
import { SmallLoader } from "components/utils/SmallLoader";
import { defaultPath } from "../requestRoutes";
import {
	splitpaymentRemindOne,
	splitpaymentRemindAll,
	deleteSplitpayment,
} from "lib/api/apiRoutesConfig/services/BillsService/subRoutes";
import { COMPLETED, ACTIVE, PAID, SENT, PENDING, EQUAL } from "config/enums";
import GoBack from "./utils/GoBack";
import DeleteRequest from "./utils/DeleteRequest";

const ManageParticipants = () => {
	const history = useHistory();
	const { location } = history;
	const [isLoading, setIsLoading] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const [showdeleteModal, setShowdeleteModal] = useState(false);

	// Handle remind all
	const handleSubmit = async (id) => {
		setIsLoading(true);
		try {
			const res = await API.put(splitpaymentRemindAll(id));
			const { status, data } = res || {};
			if (status === 200) {
				setIsLoading(false);
				ToastsStore.success(data.message, 6000, "right-toast");
			}
		} catch (err) {
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
			setIsLoading(false);
		}
	};

	// Handle delete
	const handleDelete = async (id) => {
		setIsDeleting(true);
		try {
			const res = await API.delete(deleteSplitpayment(id));
			const { status, data } = res || {};
			if (status === 200) {
				setIsDeleting(false);
				ToastsStore.success(data.message, 6000, "right-toast");
				history.push(`${defaultPath}/my-requests`);
			}
		} catch (err) {
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
			setIsDeleting(false);
		}
	};
	return (
		<>
			<Helmet>
				<title>
					{process.env.REACT_APP_NAME} - Manage Participants
				</title>
			</Helmet>
			<section className="pb-5 split-payment ">
				<div className="split-rquests-container ">
					<div className="split-rquests-container__header">
						<h2>Manage Requests</h2>
					</div>
					<div className="split-rquests-container__content">
						<div className="manage-participants">
							<p>Participants</p>
							<Content
								participants={location.state.data.participants}
							/>
							<div className="participants-summary">
								<p>Request Details</p>
								<Summary data={location.state.data} />
								<div>
									{location.state.data.status !==
										COMPLETED && (
										<button
											onClick={() => {
												handleSubmit(
													location.state.data.id
												);
											}}
											disabled={isLoading}
											className="btn btn-mint-default remind-btn"
											type="button"
										>
											{isLoading ? (
												<SmallLoader />
											) : (
												"Remind all Participants"
											)}
										</button>
									)}

									{location.state.data.status === SENT && (
										<button
											onClick={() => {
												setShowdeleteModal(true);
											}}
											className="btn btn-mint-default delete-btn"
											type="button"
										>
											Delete Request
										</button>
									)}
								</div>
							</div>
							<DeleteRequest
								isLoading={isDeleting}
								show={showdeleteModal}
								onClose={() => {
									setShowdeleteModal(false);
								}}
								nextAction={() => {
									handleDelete(location.state.data.id);
								}}
							/>
							<GoBack url="my-requests" />
						</div>
					</div>
				</div>
			</section>
		</>
	);
};
export default ManageParticipants;
const Content = ({ participants }) => {
	const [isLoading, setIsLoading] = useState(false);

	// Handle remind one
	const handleSubmit = async (id) => {
		setIsLoading(true);
		try {
			const res = await API.put(splitpaymentRemindOne(id));
			const { status, data } = res || {};
			if (status === 200) {
				setIsLoading(false);
				ToastsStore.success(data.message, 6000, "right-toast");
			}
		} catch (err) {
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
			setIsLoading(false);
		}
	};

	const renderBasedOnStatus = (status) => {
		switch (status) {
			case PENDING:
				return <p className=" status active">Pending</p>;

			case PAID:
				return <p className=" status active">Paid</p>;

			default:
				return null;
		}
	};
	return (
		<>
			{participants?.map((obj, index) => (
				<div key={index} className="participants">
					<div className="content">
						<p className=" name">{obj.participantName}</p>
						<p className=" amt">{formatCurrency(obj.amount)}</p>
					</div>
					{renderBasedOnStatus(obj.status)}
					{obj.status === PENDING && (
						<button
							onClick={() => {
								handleSubmit(obj.id);
							}}
							disabled={isLoading}
							className="btn btn-mint-default remind-btn"
							type="button"
						>
							Remind
						</button>
					)}
				</div>
			))}
		</>
	);
};

const Summary = ({ data }) => {
	const renderBasedOnStatus = (status) => {
		switch (status) {
			case SENT:
				return <p className=" status sent">Sent</p>;

			case ACTIVE:
				return <p className=" status active">Active</p>;

			case COMPLETED:
				return <p className=" status completed">Completed</p>;

			default:
				return null;
		}
	};
	return (
		<>
			<div className="participants">
				<div className="content">
					<p className=" title">Request Name</p>
					<p className=" details">{data.name}</p>
				</div>
				<div className="content">
					<p className=" title">Description</p>
					<p className=" details desc">{data.description}</p>
				</div>
				<div className="content">
					<p className=" title">Amount</p>
					<p className=" details">{formatCurrency(data.amount)}</p>
				</div>
				<div className="content">
					<p className=" title">Status</p>
					<div className=" details">
						{renderBasedOnStatus(data.status)}
					</div>
				</div>
				<div className="content">
					<p className=" title">Split type</p>
					<p className=" details">
						{data.splitType === EQUAL ? "Equal" : "Custom"}
					</p>
				</div>
				<div className="content">
					<p className=" title">Sender</p>
					<p className=" details">{data.initiator}</p>
				</div>
				<div className="content">
					<p className=" title">Date created</p>
					<p className=" details">{data.dateCreated}</p>
				</div>
			</div>
		</>
	);
};
