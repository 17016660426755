/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import FormInputGroup from "components/utils/FormInputGroup/FormInputGroup";
import { Formik } from "formik";
import * as yup from "yup";
import { SmallLoader } from "components/utils/SmallLoader";
import { Label } from "reactstrap";
import CommonSelect from "components/utils/CommonSelect/CommonSelect";
import { ToastsStore } from "react-toasts";
import { API } from "lib/api";
import {
	getAllStates,
	getBusinessCategory,
	getAllLGAs,
} from "lib/api/apiRoutesConfig/services/AccountMS/common/subRoutes";
import { INCORPORATED_TRUSTEE } from "config/enums";
import { postBusinessDetailsAction } from "lib/api/apiRoutesConfig/services/AccountMS/CAC/subRoutes";
import { getErrorMessage } from "components/utils/helpers";

const IncorporatedTrusteeDetails = ({ setSteps, regInfo, refresh }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [statesLoading, setStatesLoading] = useState(false);
	const [states, setStates] = useState([]);
	const [lgaLoading, setLgaLoading] = useState(false);
	const [lga, setLga] = useState([]);

	// Validation Schema
	const formValidationSchema = yup.object().shape({
		proposedBusinessName1: yup.string().required("Is required"),
		proposedBusinessName2: yup.string().required("Is required"),
		proposedBusinessName3: yup.string().required("Is required"),
		businessAddress: yup.string().required("Is required"),
		city: yup.string().required("Is required"),
		state: yup
			.object()
			.nullable()
			.required(" Is required")
			.shape({ label: yup.string(), value: yup.string() }),
		lga: yup
			.object()
			.nullable()
			.required("Is required")
			.shape({ label: yup.string(), value: yup.mixed() }),
		numberOfTrustees: yup.string().required("Is required"),
	});

	const initialFormValues = {
		proposedBusinessName1: "",
		proposedBusinessName2: "",
		proposedBusinessName3: "",
		businessAddress: "",
		businessType: INCORPORATED_TRUSTEE,
		city: "",
		state: null,
		lga: null,
		numberOfTrustees: "",
		referralCode: "",
	};

	// Fetch states
	const fetchStates = async () => {
		setStatesLoading(true);
		try {
			const res = await API.get(getAllStates("NG"));
			const { status, data } = res || {};
			if (status === 200) {
				setStates(data.data);
			}
		} catch (err) {
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		} finally {
			setStatesLoading(false);
		}
	};
	// Fetch LGA
	const fetchLGA = async (stateCode) => {
		setLgaLoading(true);
		try {
			const res = await API.get(getAllLGAs(stateCode));
			const { status, data } = res || {};
			if (status === 200) {
				setLga(data.data);
			}
		} catch (err) {
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		} finally {
			setLgaLoading(false);
		}
	};

	// Handle form submit
	const handleSubmit = async (values) => {
		setIsLoading(true);

		try {
			const res = await API.put(
				postBusinessDetailsAction(regInfo.sessionId),
				{
					...values,
					stateCode: values.state.value,
					lgaCode: values.lga.value,
				}
			);
			const { status, data } = res || {};
			if (status === 200) {
				refresh(localStorage.getItem("s_id"));
				// setSteps(1);
				ToastsStore.success(data.message, 6000, "right-toast");
			}
		} catch (err) {
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchStates();
	}, []);
	return (
		<>
			<Formik
				initialValues={initialFormValues}
				validationSchema={formValidationSchema}
				onSubmit={(values) => handleSubmit(values)}
			>
				{({
					values,
					errors,
					touched,
					handleSubmit,
					setFieldValue,
					handleChange,
					handleBlur,
				}) => {
					return (
						<form
							className="reg-business-form  row"
							onSubmit={(e) => {
								e.preventDefault();
								handleSubmit();
							}}
						>
							<div className="mb-4 col-12">
								<FormInputGroup
									id="proposedBusinessName1"
									name="proposedBusinessName1"
									label="Proposed business name 1"
									className="red-business-input"
									onBlur={handleBlur}
									value={values.proposedBusinessName1}
									onChange={handleChange}
									error={
										touched.proposedBusinessName1 &&
										errors.proposedBusinessName1
									}
								/>
							</div>
							<div className="mb-4 col-12">
								<FormInputGroup
									id="proposedBusinessName2"
									name="proposedBusinessName2"
									label="Proposed business name 2"
									className="red-business-input"
									onBlur={handleBlur}
									value={values.proposedBusinessName2}
									onChange={handleChange}
									error={
										touched.proposedBusinessName2 &&
										errors.proposedBusinessName2
									}
								/>
							</div>
							<div className="mb-4 col-12">
								<FormInputGroup
									id="proposedBusinessName3"
									name="proposedBusinessName3"
									label="Proposed business name 3"
									className="red-business-input"
									onBlur={handleBlur}
									value={values.proposedBusinessName3}
									onChange={handleChange}
									error={
										touched.proposedBusinessName3 &&
										errors.proposedBusinessName3
									}
								/>
							</div>

							<div className="mb-4 col-12">
								<FormInputGroup
									id="businessAddress"
									name="businessAddress"
									label="Trustee's  Address"
									className="red-business-input"
									onBlur={handleBlur}
									value={values.businessAddress}
									onChange={handleChange}
									error={
										touched.businessAddress &&
										errors.businessAddress
									}
								/>
							</div>
							<div className="mb-4 col-12">
								<FormInputGroup
									id="city"
									name="city"
									label="City"
									className="red-business-input"
									onBlur={handleBlur}
									value={values.city}
									onChange={handleChange}
									error={touched.city && errors.city}
								/>
							</div>
							<div className="mb-4 col-12">
								<Label for="state">State</Label>
								<CommonSelect
									name="state"
									isSearchable
									placeholder="Select state"
									error={touched.state && errors.state}
									onBlur={handleBlur}
									handleChange={(state) => {
										setFieldValue("state", state);
										fetchLGA(state.value);
									}}
									options={states.map((state) => ({
										label: state?.name,
										value: state?.code,
									}))}
									showErrorMsg
									isLoading={statesLoading}
								/>
							</div>
							<div className="mb-4 col-12">
								<Label for="state">LGA</Label>
								<CommonSelect
									name="lga"
									isSearchable
									placeholder="Select local government"
									error={touched.lga && errors.lga}
									onBlur={handleBlur}
									handleChange={(lga) =>
										setFieldValue("lga", lga)
									}
									options={lga.map((item) => ({
										label: item?.name,
										value: item.code,
									}))}
									showErrorMsg
									isLoading={lgaLoading}
								/>
							</div>

							<div className="mb-4 col-12 requirements-reg">
								<FormInputGroup
									id="numberOfTrustees"
									label="Number of Trustees"
									name="numberOfTrustees"
									className="red-business-input"
									onBlur={handleBlur}
									value={values.numberOfTrustees}
									onChange={(e) => {
										const value = e.target.value
											.trim()
											.replace(/[^+0-9]/i, "");
										setFieldValue(e.target.name, value);
									}}
									error={
										touched.numberOfTrustees &&
										errors.numberOfTrustees
									}
								/>
								<p className="tip mt-1">
									You can only upload a minimum of 2 Trustees
									and maximum of 15 trustees including the
									chairman of the board of trustees
								</p>
							</div>

							<div className="mb-4 col-12">
								<FormInputGroup
									id="referralCode"
									name="referralCode"
									label="Referral code"
									className="red-business-input"
									onBlur={handleBlur}
									value={values.referralCode}
									onChange={handleChange}
									error={
										touched.referralCode &&
										errors.referralCode
									}
								/>
							</div>

							<div className="col-12 ">
								<button
									className="btn btn-mint-default"
									type="submit"
									disabled={isLoading}
								>
									{isLoading ? <SmallLoader /> : "Continue"}
								</button>
							</div>
						</form>
					);
				}}
			</Formik>
		</>
	);
};
export default IncorporatedTrusteeDetails;
