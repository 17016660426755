import FadeIn from "components/dashboard/components/effects/FadeIn";
import React from "react";
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";
import "../BillPayment.scss";
import CaretLeft from "assets/img/caret_left_gold.svg";
import { GroomPanel2 } from "components/dashboard/utils/GroomPanel/GroomPanel";
import { ReactSVG } from "react-svg";
import RemitaLogo from "assets/img/remita_logo.svg";
import RRRPaymentLogo from "assets/img/rrr_payment.svg";
import { defaultPath } from "../../components/BillsPaymentRoutes/BillsPaymentRoutes";

export default function Remita() {
	const history = useHistory();

	return (
		<>
			<Helmet>
				<title>
					{process.env.REACT_APP_NAME} - Bills Payment / Remita
				</title>
			</Helmet>

			<FadeIn>
				<section className="pb-5 bp-categories__category">
					<div className="d-flex align-items-center mb-5">
						<button
							className="back-btn d-flex align-items-center"
							onClick={() => history.goBack()}
						>
							<img src={CaretLeft} alt="" />
							<span>Back</span>
						</button>
					</div>

					<GroomPanel2 title="Remita" className="pb-5">
						<div className="bp-remita">
							<h2>Choose payment type</h2>

							<PaymentOption
								icon={RemitaLogo}
								title="Pay via Category"
								description="Select from a wide range of remita bill 
								payments"
								url={`${defaultPath}/remita/pay-via-category`}
							/>

							<PaymentOption
								icon={RRRPaymentLogo}
								title="Pay via RRR Number"
								description="Make a payment with your 12 digit 
								RRR number"
								url={`${defaultPath}/remita/pay-via-rrr`}
							/>
						</div>
					</GroomPanel2>
				</section>
			</FadeIn>
		</>
	);
}

const PaymentOption = ({ icon, title, description, url }) => {
	return (
		<Link to={url} className="bp-remita__option">
			<ReactSVG src={icon} />
			<div className="bp-remita-option-info">
				<h3>{title}</h3>
				<p>{description}</p>
			</div>
		</Link>
	);
};
