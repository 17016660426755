import React, { Component } from "react";
import { connect } from "react-redux";
import LTSkeletonLoader from "./dummy/LTSkeletonLoader";
import Axios from "axios";
import { API } from "../../../../../../../lib/api";
import { getlatestTranscations } from "../../../../../../../lib/api/apiRoutesConfig/services/FundsManagementMS/bankAccount/subRoutes";
import LTTable from "./LTTable/LTTable";
import FadeIn from "../../../../effects/FadeIn";
import "./LatestTransactions.scss";
import { Link } from "react-router-dom";

class LatestTransactions extends Component {
	_isMounted = false;
	signal = Axios.CancelToken.source();

	state = {
		recentTranscationList: [],
		fetching: true,
	};

	componentDidMount = () => {
		this._isMounted = true;
		this.fetchLatestTranscations();
	};

	componentWillUnmount = () => {
		this.signal.cancel("canceling network calls");
		this._isMounted = false;
	};

	fetchLatestTranscations = async () => {
		try {
			const { bankAccounts } = this.props;
			const { accountId } = bankAccounts ? bankAccounts[0] : {};
			const url = getlatestTranscations(accountId);
			const res = await API.get(url, {
				params: {
					size: 5,
				},
				cancelToken: this.signal.token,
			});
			if (res.status === 200) {
				const transcationGroup = res.data.data;
				let recentTranscationList = [];
				transcationGroup.forEach((groupItem) => {
					let transcationItem = groupItem["transactionList"];
					transcationItem &&
						transcationItem.forEach((listItem) => {
							recentTranscationList.push({ ...listItem });
						});
				});
				this._isMounted &&
					this.setState({
						recentTranscationList,
						fetching: false,
					});
			}
		} catch (err) {
			this._isMounted &&
				this.setState({
					fetching: false,
					fetchingError: "No Recent Transactions",
				});
		}
	};

	render = () => {
		const { fetching, fetchingError, recentTranscationList } = this.state;

		return (
			<div
				className="col-lg-5 d-flex lt-dashboard"
				style={{ flex: 1, flexDirection: "column" }}
			>
				<div
					className="head-wyx row align-items-center justify-content-center transactions-card-header"
					style={{ marginBottom: "15px" }}
				>
					<h4 className="sub-heading col-7" style={{ margin: 0 }}>
						Latest Transactions
					</h4>
					<div className="col-5" style={{ flexShrink: 0 }}>
						{recentTranscationList.length > 0 && (
							<Link
								to="/transaction-history"
								className="btn btn-mint-default"
								style={{ borderRadius: "8px", whiteSpace: "nowrap" }}
							>
								View all
							</Link>
						)}
					</div>
				</div>
				<div className="transcations-card h-100">
					{fetching ? (
						<LTSkeletonLoader />
					) : fetchingError ? (
						<FadeIn>
							<div className="px-3 py-5">
								<h6 className="text-center notready mt-5">
									{fetchingError}
								</h6>
							</div>
						</FadeIn>
					) : (
						<LTTable dataList={recentTranscationList} />
					)}
				</div>
			</div>
		);
	};
}

export default connect(
	({ auth: { currentUser }, dashboard: { dashboardResponse } }) => ({
		currentUser: currentUser,
		bankAccounts: dashboardResponse.bankAccounts,
	}),
	null
)(LatestTransactions);
