import React from "react"
import Modal from "components/utils/Modal/Modal"
import "./PaymentPanel.scss";
import SuccessImage from "components/utils/SuccessImage"
import { useHistory } from "react-router-dom";



const OrderConfirmed = ({show, onClose, content,title, rText,balanceStatus,isChecked}) => {
  return (
    <Modal show={show} scrollable>
      <FeedBack
        onClose={onClose}
        content={content}
        title={title}
        rText={rText}
        balanceStatus={balanceStatus}
        isChecked={isChecked}
      />
		</Modal>
  )
}
export default OrderConfirmed


const FeedBack = ({onClose, content, title, rText, balanceStatus,isChecked }) => {
  const history = useHistory()
  const PAY_NOW = 'payNow'
  
  return (
    <div className="mkt-payment-modal mkt-payment-modal__content">
      <div className="confirmed-order__content">
        <div className="d-flex flex-row  ">
          <SuccessImage/>
        </div>
        <div>
          <h4>{title}</h4>
          <p>{content}
          </p>
          
        </div>
      </div>
      <div className=" confirmed-order d-flex flex-row">
        <button
          onClick={() => {
            onClose()
            history.push('/dashboard')
        }}
        className="btn btn-mint-default filled-button mr-2 mr-lg-3"
      >
        Return to Dashboard
        </button>
         <button
          onClick={() => {
            !balanceStatus || isChecked === PAY_NOW?
              history.push('/market-place/personal-shopping')
              :
              onClose()
          }}
        className="btn btn-mint-default outline-button  ml-2 ml-lg-3"
      >
          {rText}
      </button>
        
     </div>
      
    </div>
  )
}