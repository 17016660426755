import React from "react";
import PlusIcon from "assets/img/plus-icon-white.svg";
import MinusIcon from "assets/img/minus-icon.svg";
import { ReactSVG } from "react-svg";
import "./QuantitySelector.scss";

export default function QuantitySelector({
	onIncrease,
	onDecrease,
	quantity,
	isDisabled,
	cls = "",
}) {
	return (
		<div
			className={`mkt-quantity-selector ${cls}`}
			onClick={(e) => e.stopPropagation()}
		>
			<button
				onClick={onDecrease}
				disabled={quantity === 1 || isDisabled}
			>
				<ReactSVG src={MinusIcon} />
			</button>

			<span>{quantity}</span>

			<button onClick={onIncrease} disabled={isDisabled}>
				<ReactSVG src={PlusIcon} />
			</button>
		</div>
	);
}
