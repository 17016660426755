import transactions from "../";
import { FundsManagementMS } from "../..";
export const {
	getTransExpenseData,
	getCorporateTransactions,
	getFundTransferRequest,
	updateTransferRequestStatus,
	getTransactionReceiptDetails,
} = {
	getTransExpenseData: `${transactions}/expense-manager`,
	getCorporateTransactions: `${FundsManagementMS.default}/corporate/transactions`,
	getFundTransferRequest: (requestId) =>
		`${FundsManagementMS.default}/corporate/transactions/${requestId}`,
	updateTransferRequestStatus: `${FundsManagementMS.default}/corporate/transactions/request-status`,
	getTransactionReceiptDetails: (reference) =>
		`${transactions}/${reference}/receipt`,
};
