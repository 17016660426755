/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Modal from "components/utils/Modal/Modal";
import { ReactSVG } from "react-svg";
import RejectIcon from "./assets/reget.svg";

const Reject = ({ show, onClose, nextAction }) => {
	return (
		<>
			<Modal show={show} scrollable>
				<Content onClose={onClose} nextAction={nextAction} />
			</Modal>
		</>
	);
};
export default Reject;

const Content = ({ onClose, nextAction }) => {
	return (
		<div className="request-reject-modal ">
			<div className=" content">
				<div className="icon">
					<ReactSVG src={RejectIcon} />
				</div>
				<h4>Reject Request!</h4>
				<p>
					This action will permanently remove this request and their
					records? Are you sure you want to continue?
				</p>
			</div>
			<div className="col-12 ">
				<button
					onClick={() => {
						nextAction();
					}}
					className="btn btn-mint-default"
					type="button"
				>
					Continue
				</button>
			</div>
			<div className="col-12 ">
				<button
					onClick={() => {
						onClose();
					}}
					className="btn border-button "
					type="button"
				>
					Cancel
				</button>
			</div>
		</div>
	);
};
