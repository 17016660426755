export const tableHeadings = [
	{
		cell: "S/N",
	},
	{
		cell: "Transaction Date",
	},
	{
		cell: "Time",
	},
	{
		cell: "Category",
	},
	{
		cell: "Transaction Type",
	},
	{
		cell: "Amount",
	},
	{
		cell: "Initiator",
	},
	{
		cell: "Reviewer",
	},
	{
		cell: "Date Reviewed",
	},
	{
		cell: "Details",
	},
];

export const approvalStatusEnums = {
	PENDING: "PENDING",
	APPROVED: "APPROVED",
	DECLINED: "DECLINED",
};

export const transactionTypeEnums = {
	// more to come
	ALL: "ALL",
	SINGLE_FUND_TRANSFER: "SINGLE_FUND_TRANSFER",
	BULK_FUND_TRANSFER: "BULK_FUND_TRANSFER",
	DATA_BUNDLE: "DATA_BUNDLE",
	AIRTIME: "AIRTIME",
	BILLS: "BILLS",
};

export const categoryEnums = {
	FUND_TRANSFER: "FUND_TRANSFER",
	BILL_PAYMENT: "BILL_PAYMENT",
	INVESTMENT: "INVESTMENT",
};

export const transactionStagesEnums = {
	CONFIRMATION: "CONFIRMATION",
	FINISHED: "FINISHED",
	IDLE: "IDLE",
};

export const requestStatusEnums = {
	APPROVE: "APPROVE",
	DECLINE: "DECLINE",
};
