export default {
	msName: "bills-service",
	msVersion: "/api/v1",
	msVersion2: "/api/v2",
	get default() {
		return this.msName + this.msVersion;
	},
	get version2() {
		return this.msName + this.msVersion2;
	},
};
