import GroomPanel from "components/dashboard/utils/GroomPanel/GroomPanel";
import React from "react";
import { useHistory } from "react-router-dom";
import { ReactSVG } from "react-svg";
import PiggyIcon from "assets/img/investment_piggy.svg";
import "../../SpendAndSave.scss";

export default function EmptySpendAndSaveList() {
	const history = useHistory();

	return (
		<GroomPanel cls="sas-groom">
			<div className="sas-empty">
				<ReactSVG src={PiggyIcon} />

				<h3>You have no active Spend and Save</h3>
				<p>Create a new spend and save by tapping on the button below</p>

				<button
					className="btn btn-mint-default"
					onClick={() =>
						history.push("/savings/spend-and-save/create")
					}
				>
					Create New Spend and Save
				</button>
			</div>
		</GroomPanel>
	);
}
