export const transactionTypeEnum = {
	CREDIT: "CREDIT",
	DEBIT: "DEBIT",
};

export const transactionStatusEnum = {
	PENDING: "PENDING",
	FAILED: "FAILED",
	CANCELLED: "CANCELLED",
	SUCCESSFUL: "SUCCESSFUL",
};
