import individualTrade from "..";

export const {
	getPopularProducts,
	getTopCategories,
	getCategories,
	getProductsByCategory,
	getAllProducts,
	performCartActions,
	deleteCartItem,
	deliveryAddressAction,
	editDeliveryAddress,

} = {
	getPopularProducts: `${individualTrade}/popular-products`,
	getTopCategories: `${individualTrade}/browse-products`,
	getCategories: `${individualTrade}/categories`,
	getProductsByCategory: (categoryCode) =>
		`${individualTrade}/categories/${categoryCode}/products`,
	getAllProducts: `${individualTrade}/products`,
	performCartActions: `${individualTrade}/shopping-cart`,
	deleteCartItem: (cartItemId) =>
		`${individualTrade}/shopping-cart/${cartItemId}`,
	deliveryAddressAction: `${individualTrade}/delivery-address`,
	editDeliveryAddress: (addressId) =>
		`${individualTrade}/delivery-address/${addressId}`,
};
