import React from "react";
import DurationSelectForm from "./DurationSelectForm/DurationSelectForm";
import InvestmentPreferenceForm from "./InvestmentPreferenceForm/InvestmentPreferenceForm";
import InvestmentPreview from "./InvestmentPreview/InvestmentPreview";

const CreateInvestmentContent = ({ step }) => {
	if (step === 1) return <DurationSelectForm />;

	if (step === 2) return <InvestmentPreferenceForm />;

	if (step === 3) return <InvestmentPreview />;

	return <></>;
};

export default CreateInvestmentContent;
