/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Modal from "components/utils/Modal/Modal";
import ModalHeader from "components/utils/Modal/CustomModalHeader/CustomModalHeader";
import { Formik } from "formik";
import * as yup from "yup";
import { ToastsStore } from "react-toasts";
import { getErrorMessage } from "components/utils/helpers";
import { API } from "lib/api";
import FormInputGroup from "components/utils/FormInputGroup/FormInputGroup";
import { postPayMintynAction } from "lib/api/apiRoutesConfig/services/AccountMS/CAC/subRoutes";
import { SmallLoader } from "components/utils/SmallLoader";
import CustomRadioButton from "components/utils/CustomRadioButton/CustomRadioButton";
import PhoneNumberInput from "components/utils/PhoneNumberInput/PhoneNumberInput";

const PayMintyn = ({ show, onClose, nextModal, regInfo, refresh }) => {
	const [showAccounts, setShowAccounts] = useState(true);
	const [accountList, setAccountList] = useState([]);

	useEffect(() => {
		setShowAccounts(false);
	}, [onClose]);

	return (
		<>
			<Modal show={show} scrollable>
				<ModalHeader
					heading=""
					className="p-0"
					// headerClassName="setup-modals-header px-sm-5 px-3 py-3"
					dismissable={() => onClose?.()}
				/>
				{!showAccounts && (
					<PayMintynContent
						onClose={onClose}
						regInfo={regInfo}
						refresh={refresh}
						setAccountList={setAccountList}
						accountList={accountList}
						setShowAccounts={setShowAccounts}
					/>
				)}
				{showAccounts && (
					<MintynAccount
						setAccountList={setAccountList}
						accountList={accountList}
						nextModal={nextModal}
						setShowAccounts={setShowAccounts}
						showAccounts={showAccounts}
					/>
				)}
			</Modal>
		</>
	);
};
export default PayMintyn;

const PayMintynContent = ({ regInfo, setShowAccounts, setAccountList }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [phoneNumber, setPhoneNumber] = useState("");
	const [dialCode, setDialCode] = useState("");

	const formValidationSchema = yup.object().shape({
		password: yup.string().trim().required("Is required"),
	});

	const initialFormValues = {
		password: "",
		phoneNumber: "",
	};
	//  Tester123*
	// 2348099652247

	// Handle form submit
	const handlePhone = (__, value, countryData) => {
		let Numbers = value.replace(/[^0-9]/g, "");
		value = Numbers.replace(/^(-?)0+/, "");
		setPhoneNumber(value);
		setDialCode(countryData.dialCode);
	};

	useEffect(() => {}, [phoneNumber, dialCode]);
	const handleSubmit = async (values) => {
		setIsLoading(true);
		try {
			const phone = "+" + dialCode + "" + phoneNumber;
			console.log(phone);
			const res = await API.put(postPayMintynAction(regInfo.sessionId), {
				...values,
				phoneNumber: phone,
			});
			const { status, data } = res || {};
			if (status === 200) {
				setAccountList(data.data);
				ToastsStore.success(data.message, 6000, "right-toast");
				setShowAccounts(true);
			}
		} catch (err) {
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
			setIsLoading(false);
		}
	};

	return (
		<div className="pay-mintyn-modal">
			<div className=" content">
				<div className="modal-title">
					<p className="title-header">Pay using Mintyn Account</p>
					<p>
						Please input your phone number to proceed with payment
					</p>
				</div>
			</div>
			<Formik
				initialValues={initialFormValues}
				validationSchema={formValidationSchema}
				onSubmit={(values) => handleSubmit(values)}
			>
				{({
					values,
					errors,
					touched,
					isValid,
					handleSubmit,
					setFieldValue,
					handleChange,
					handleBlur,
				}) => {
					return (
						<form className="reg-business-form-modal  row">
							<div className="mb-4 col-12">
								<label>Phone Number</label>
								<PhoneNumberInput
									inputFieldName="Phone Number"
									inputStyle={"py-4"}
									inputValue={phoneNumber}
									handlePhoneNumberChange={handlePhone}
									isDisabled={isLoading}
									onSelectFlag={() => setPhoneNumber("")}
								/>
							</div>

							{/* <div className="mb-4 col-12">
						<FormInputGroup
							id="phoneNumber"
							label="Phone Number"
							name="phoneNumber"
							placeholder="234 00 000 0000 "
							className="red-business-input"
							onBlur={handleBlur}
							value={values.phoneNumber }
							onChange={(e) => {
								const value = e.target.value
										.trim()
										.replace(/[^+0-9]/i, "");
								
										setFieldValue(e.target.name, value);
								}}
							error={
								touched.phoneNumber  &&
								errors.phoneNumber 
							}
						/>
						</div> */}
							<div className="mb-4 col-12">
								<FormInputGroup
									id="password"
									type="password"
									name="password"
									placeholder="*********"
									label="Password"
									className="red-business-input"
									onBlur={handleBlur}
									value={values.password}
									onChange={handleChange}
									error={touched.password && errors.password}
								/>
							</div>
							<div className="col-12 ">
								{/* ()=>setShowTermsModal(true) */}
								<button
									onClick={handleSubmit}
									className="btn btn-mint-default"
									type="button"
									disabled={isLoading || phoneNumber === ""}
								>
									{isLoading ? (
										<SmallLoader />
									) : (
										"Verify Account"
									)}
								</button>
							</div>
						</form>
					);
				}}
			</Formik>
		</div>
	);
};

const MintynAccount = ({ nextModal, accountList }) => {
	const [checkedAccount, setCheckedAccount] = useState("");

	//  Tester123*
	// 2348099652247
	// Handle select
	const handleSelected = (value) => {
		setCheckedAccount(value.accountId);
		localStorage.setItem("a_id", value.accountId);
	};

	return (
		<div className="pay-mintyn-modal">
			<>
				<div className=" content">
					<h3 className="sub-title">Pay using Mintyn Account</h3>

					<p className="sub-text">
						Please select an account to proceed with payment
					</p>
				</div>

				{accountList &&
					accountList.map((item, index) => (
						<div
							key={index}
							className={`account-list ${
								checkedAccount === item.accountId
									? "is-active-list"
									: ""
							}`}
						>
							<div className="check-option">
								<CustomRadioButton
									onChange={() => handleSelected(item)}
									checked={
										checkedAccount === item.accountId
											? true
											: false
									}
									vallue={item.accountId}
									name={item.accountId}
								/>
							</div>
							<div className="">
								<p className=" name">{item.accountName}</p>
								<p>
									{item.accountNumber} - {item.accountType}
								</p>
							</div>
						</div>
					))}
			</>

			<div className="col-12 mt-4 ">
				<button
					onClick={() => nextModal()}
					className="btn btn-mint-default"
					type="button"
					disabled={checkedAccount !== "" ? false : true}
				>
					Continue
				</button>
			</div>
		</div>
	);
};
