import InsufficientFundsModal from "components/utils/InsufficientFundsModal/InsufficientFundsModal";
import Modal from "components/utils/Modal/Modal";
import { ERROR, FINISHED, IDLE } from "config/enums";
import React from "react";
import { useHistory } from "react-router-dom";
import SavingsGoalSuccessModal from "./SavingsGoalSuccessModal";

export default function CreateSavingsGoalModal({
	show,
	currentState,
	errorMsg = "",
	onClose,
	setErrorMsg,
	setCurrentState,
	successMsg,
	openFundingModal,
	goalResponse = {},
}) {
	const history = useHistory();

	const renderBasedOnState = () => {
		switch (currentState) {
			case ERROR:
				if (
					errorMsg.includes(
						"You have insufficient balance for fund your savings goal."
					)
				) {
					return (
						<InsufficientFundsModal
							message="You have insufficient balance to fund your savings goal."
							onClick={() => {
								onClose();
								setErrorMsg("");
								setCurrentState(IDLE);
								openFundingModal();
							}}
						/>
					);
				}

				return null;

			case FINISHED:
				return (
					<SavingsGoalSuccessModal
						message={successMsg}
						buttonLabel="View Plan"
						onClick={() => {
							const { goalId } = goalResponse || {};
							onClose();
							history.replace(
								`/savings/goals/details/${goalId}`,
								{
									savingGoalData: goalResponse || {},
								}
							);
						}}
					/>
				);

			default:
				return null;
		}
	};

	return (
		<Modal
			show={show}
			toggle={() => {
				onClose();
				history.replace("/dashboard");
			}}
		>
			<>{renderBasedOnState()}</>
		</Modal>
	);
}
