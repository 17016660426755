import React from "react";
import { useHistory } from "react-router-dom";
import CaretLeft from "../../../../assets/img/caret_left_gold.svg";
import FadeIn from "../effects/FadeIn";
import "./CompleteProfile.scss";
import StepCard from "./components/StepCard/StepCard";
import AccessControl from "../../../auth/AccessControl/AccessControl";
import permissions from "../../../auth/permissions";
import { useSelector } from "react-redux";
import { ACCOUNT_SETUP_DOCUMENTS } from "./components/constants";
import {
	ENTERPRISE,
	FAILED,
	INCORPORATED_TRUSTEE,
	I_AM_DIRECTOR,
	I_AM_TRUSTEE,
} from "config/enums";

export default function CompleteProfile() {
	const history = useHistory();
	const { IS_ENTERPRISE, IS_INCORPORATED_TRUSTEE } = permissions;
	const { dashboardResponse, activeAccountType } = useSelector((state) => ({
		dashboardResponse: state.dashboard.dashboardResponse,
		activeAccountType: state.auth.activeAccountType,
	}));

	const { businessSetUp } = dashboardResponse?.setUpDetail;

	const cacVerified =
		dashboardResponse?.setUpDetail?.businessSetUp?.cacVerified?.status;

	const govtIdVerified =
		dashboardResponse?.setUpDetail?.businessSetUp?.govtIdVerified?.status;

	const certificateOfIncorporatedTrusteeVerified =
		dashboardResponse?.setUpDetail?.businessSetUp
			?.certificateOfIncorporatedTrusteeVerified?.status;

	const tinVerified =
		dashboardResponse?.setUpDetail?.businessSetUp?.tinVerified?.status;

	const memorandumOfAssociationDocument =
		dashboardResponse?.setUpDetail?.businessSetUp
			?.memorandumOfAssociationDocument?.status;

	const isBizGovtIdUploaded = businessSetUp?.govtIdUploaded;

	// Use this to check & set Directors govt id status
	const directorGovtId =
		dashboardResponse?.setUpDetail?.directorSetUp?.directorGovtId?.status;

	const directorTypeSelection =
		dashboardResponse?.setUpDetail?.directorSetUp?.directorTypeSelection;

	const setDirectorStatus = () => {
		if (
			directorGovtId === FAILED &&
			directorTypeSelection === I_AM_DIRECTOR &&
			isBizGovtIdUploaded
		) {
			return "PENDING";
		} else return directorGovtId;
	};

	// Use this to check & set Trustees govt id status
	const trusteeDirectorGovtId =
		dashboardResponse?.setUpDetail?.trusteeSetup?.directorGovtId?.status;

	const trusteeDirectorTypeSelection =
		dashboardResponse?.setUpDetail?.trusteeSetup?.directorTypeSelection;

	const setTrusteesStatus = () => {
		if (
			trusteeDirectorGovtId === FAILED &&
			trusteeDirectorTypeSelection === I_AM_TRUSTEE &&
			isBizGovtIdUploaded
		) {
			return "PENDING";
		} else return trusteeDirectorGovtId;
	};

	// Use this to check if any Business Documentation verification failed
	const businessSetupStatus = () => {
		if (
			cacVerified === FAILED ||
			govtIdVerified === FAILED ||
			certificateOfIncorporatedTrusteeVerified === FAILED ||
			tinVerified === FAILED ||
			memorandumOfAssociationDocument === FAILED
		) {
			return "FAILED";
		}
	};

	// Use this to set the disabled state of Directors and Trustees Documentation cards
	const checkIfCardIsDisabled = () => {
		const result = ACCOUNT_SETUP_DOCUMENTS.BUSINESS_DOCUMENTATION.filter(
			(doc) => {
				if (activeAccountType === ENTERPRISE)
					return (
						doc !== "certificateOfIncorporatedTrusteeUploaded" &&
						doc !== "tinUploaded"
					);
				else if (activeAccountType === INCORPORATED_TRUSTEE)
					return doc !== "moaUploaded" && doc !== "tinUploaded";
				else return [];
			}
		).some(
			(doc) =>
				businessSetUp[doc] === false &&
				businessSetupStatus() !== "FAILED" // disable card if any business documentation is false and none of the status is failed
		);
		return result;
	};

	return (
		<FadeIn>
			<section className="complete-profile pb-5">
				<div className="d-flex align-items-center mb-5">
					<button
						className="back-btn d-flex align-items-center"
						onClick={() => history.goBack()}
					>
						<img src={CaretLeft} alt="" />
						<span>Back</span>
					</button>
				</div>

				<h1>Complete your Profile</h1>
				<p>
					Please provide all the information below to generate your
					account number and give you access to full features of your
					account.
				</p>

				<div className="row mt-5">
					<StepCard
						title="Business Documentation"
						description="Provide your business registration, ID and TIN to help us
					verify your business."
						documentationType="BUSINESS_DOCUMENTATION"
						status={businessSetupStatus()}
						CTAAction={() =>
							history.push(
								"/setup-account/business-documentation"
							)
						}
					/>

					<AccessControl allowedPermissions={[IS_ENTERPRISE]}>
						<StepCard
							title="Directors Documentation"
							description="Provide details of a director of your business to start using your corporate account."
							documentationType="DIRECTORS_DOCUMENTATION"
							className="mt-4 mt-lg-0"
							status={setDirectorStatus()}
							isDisabled={checkIfCardIsDisabled()}
							directorTypeSelection={directorTypeSelection}
							CTAAction={() =>
								history.push(
									"/setup-account/directors-documentation"
								)
							}
						/>
					</AccessControl>

					<AccessControl
						allowedPermissions={[IS_INCORPORATED_TRUSTEE]}
					>
						<StepCard
							title="Trustees Documentation"
							description="Provide details of a trustee of your business to start using your corporate account."
							documentationType="TRUSTEES_DOCUMENTATION"
							className="mt-4 mt-lg-0"
							status={setTrusteesStatus()}
							isDisabled={checkIfCardIsDisabled()}
							directorTypeSelection={trusteeDirectorTypeSelection}
							CTAAction={() =>
								history.push(
									"/setup-account/trustees-documentation"
								)
							}
						/>
					</AccessControl>
				</div>
			</section>
		</FadeIn>
	);
}
