import React, { useState } from "react";
import { ReactComponent as InvestmentIcon } from "../../../../../../assets/img/investment.svg";
import { DifferenceInDays, formatCurrency } from "../../../../../utils/helpers";
import InvestmentActionModal from "../../modals/InvestmentActionModal/InvestmentActionModal";
import { LIQUIDATE, TOP_UP } from "../../extra";

const InvestmentDetailsContent = ({ investment }) => {
	const [currentAction, setCurrentAction] = useState();
	const [showModal, setShowMal] = useState(false);

	const openModal = (action) => {
		setCurrentAction(action);
		setShowMal(true);
	};

	return (
		<div className="col-lg-5">
			<div className="investment-card details full-width">
				<ul className="full-white ">
					<li className="d-flex align-items-center justify-content-start">
						<InvestmentIcon className="invest-icon" />
						<div>
							<h4>
								{formatCurrency(investment?.amountInvested)}
							</h4>
							<small className="d-block">
								{DifferenceInDays(investment?.maturityDate)}{" "}
								left for your investment to mature
							</small>
							<div className="investment-interest">
								{investment?.durationInMonths} month
								{investment?.durationInMonths > 1
									? "s"
									: ""} at {investment?.interestRate}% PA
							</div>
						</div>
					</li>
					<li>
						<div>
							<p>Expected Investment Returns</p>
							<h4>
								{formatCurrency(
									investment?.totalExpectedReturn
								)}
							</h4>
							<small>Matures {investment?.maturityDate}</small>
						</div>
						<div className="text-right">
							<p>Total Investment</p>
							<h4>
								{formatCurrency(investment?.amountInvested)}
							</h4>
							<small>Started on {investment?.startDate}</small>
						</div>
					</li>

					<li>
						<div>
							<p>Accrued Profit as at today</p>
							<h4>
								{formatCurrency(
									investment?.totalAccruedInterest
								)}
							</h4>
						</div>
						<div className="text-right">
							<p>Estimated profit at maturity</p>
							<h4>
								{formatCurrency(
									investment?.estimatedProfitAtMaturity
								)}
							</h4>
						</div>
					</li>

					<li>
						<div>
							<p>Withholding Tax</p>
							<h4>
								{formatCurrency(investment?.withholdingTax)}
							</h4>
						</div>
					</li>

					<li className="mb-0">
						<button
							className="btn create-acc"
							onClick={() => openModal(TOP_UP)}
						>
							Top Up +
						</button>
						<button
							type="button"
							className="btn btn-custom-bordered ml-2"
							disabled={!investment?.canLiquidate}
							onClick={() => openModal(LIQUIDATE)}
						>
							Liquidate
						</button>
					</li>
				</ul>
			</div>

			<InvestmentActionModal
				investment={investment}
				showModal={showModal}
				currentAction={currentAction}
				toggleModal={() => setShowMal(false)}
			/>
		</div>
	);
};

export default InvestmentDetailsContent;
