import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import FadeIn from "../../effects/FadeIn";
import "./SpendAndSave.scss";
import CaretLeft from "assets/img/caret_left_gold.svg";
import { useSelector } from "react-redux";
import { ERROR, FINISHED, IDLE, INDIVIDUAL, LOADING } from "config/enums";
import GroomPanel from "components/dashboard/utils/GroomPanel/GroomPanel";
import UserRoleWarning from "components/utils/UserRoleWarning/UserRoleWarning";
import { getErrorMessage } from "components/utils/helpers";
import { API } from "lib/api";
import { getSpendAndSaveDetails } from "lib/api/apiRoutesConfig/services/SavingsMS/savingsGoals/subRoutes";
import { MintInfinite } from "components/dashboard/utils/ShineLoader/ShineLoader";
import FailedTryAgain from "components/dashboard/utils/FailedTryAgain/FailedTryAgain";
import EmptySpendAndSaveList from "./components/EmptySpendAndSaveList/EmptySpendAndSaveList";
import SASListWrapper from "./components/SASListWrapper/SASListWrapper";

export default function SpendAndSave() {
	const history = useHistory();
	const [currentState, setCurrentState] = useState(IDLE);
	const [errorMsg, setErrorMsg] = useState("");
	const [spendAndSave, setSpendAndSave] = useState({});

	const { loginResponse, activeAccountType } = useSelector(
		(state) => state.auth
	);

	const accountType =
		activeAccountType === INDIVIDUAL
			? "activeIndividualAccount"
			: "activeCorporateAccount";

	const { currentRole } = loginResponse[accountType]?.profileSetting || {};
	const isApprover = currentRole === "APPROVER";

	const fetchSpendAndSave = async () => {
		setCurrentState(LOADING);
		try {
			const response = await API.get(getSpendAndSaveDetails);
			const { status, data } = response || {};
			if (status === 200) {
				setSpendAndSave(data.data);
				setCurrentState(FINISHED);
			}
		} catch (err) {
			setErrorMsg(getErrorMessage(err));
			setSpendAndSave({});
			setCurrentState(ERROR);
		}
	};

	useEffect(() => {
		fetchSpendAndSave();
	}, []);

	const renderBasedOnState = () => {
		const { exist } = spendAndSave || {};

		switch (currentState) {
			case LOADING:
				return (
					<div className="d-flex w-100 justify-content-center align-items-center sas-loading">
						<MintInfinite relative />
					</div>
				);

			case ERROR:
				return (
					<div className="d-flex w-100 justify-content-center align-items-center sas-loading">
						<FailedTryAgain
							errorMssg={errorMsg}
							handleTryAgain={fetchSpendAndSave}
						/>
					</div>
				);

			case FINISHED:
				if (!exist) {
					return <EmptySpendAndSaveList />;
				}

				return (
					<SASListWrapper
						data={spendAndSave}
						refetch={fetchSpendAndSave}
					/>
				);

			default:
				return null;
		}
	};

	return (
		<>
			<Helmet>
				<title>{process.env.REACT_APP_NAME} - Spend and Save</title>
			</Helmet>

			<FadeIn>
				<section className="spend-and-save-page pb-5">
					<div className="d-flex align-items-center mb-5 justify-content-between">
						<button
							className="back-btn d-flex align-items-center"
							onClick={() => history.goBack()}
						>
							<img src={CaretLeft} alt="" />
							<span>Back</span>
						</button>
					</div>

					<h1 className="page-title-text">
						{spendAndSave?.exist
							? "View Spend and Save"
							: "Spend and Save"}
					</h1>
					<p className="page-lead-text">
						{spendAndSave?.exist
							? "See how your Spend and Save is doing"
							: `Welcome to Mintyn Spend and Save. Create new Spend and
						Save to get started.`}
					</p>

					{isApprover ? (
						<GroomPanel cls="sas-groom position-relative">
							<UserRoleWarning
								style={{
									position: "absolute",
									top: "50%",
									left: "50%",
									transform: "translate(-50%, -50%)",
								}}
							/>
						</GroomPanel>
					) : (
						renderBasedOnState()
					)}
				</section>
			</FadeIn>
		</>
	);
}
