import Store from "../store";
import {
	INIT_VERIFICATION,
	NEXT_WAYBILL_CONFIRMATION_STEP,
	PREV_WAYBILL_CONFIRMATION_STEP,
	CLEAR_VERIFICATION,
	UPDATE_WAYBILL_DETAILS,
	UPDATE_ORDER_DETAILS,
	UPDATE_WAYBILL_STATUS,
	GO_TO_STEP,
} from "./types";

const validateLink = (token) => {
	const { waybillConfirmation } = Store.getState();
	const existed = waybillConfirmation[token];

	if (!existed) throw new Error("Invalid Waybill Link.");
};

const initWaybillComfirmation = (token) => (dispatch) => {
	dispatch({ type: INIT_VERIFICATION, payload: token });
};

const nextStep = (token) => (dispatch) => {
	validateLink(token);
	dispatch({ type: NEXT_WAYBILL_CONFIRMATION_STEP, payload: token });
};

const previousStep = (token) => (dispatch) => {
	validateLink(token);
	dispatch({ type: PREV_WAYBILL_CONFIRMATION_STEP, payload: token });
};

const goToStep = (data) => (dispatch) => {
	validateLink(data?.token);
	dispatch({ type: GO_TO_STEP, payload: data });
};

const updateWaybillDetails = (data) => (dispatch) => {
	validateLink(data?.token);
	dispatch({ type: UPDATE_WAYBILL_DETAILS, payload: data });
};

const updateWaybillStatus = (data) => (dispatch) => {
	validateLink(data?.token);
	dispatch({ type: UPDATE_WAYBILL_STATUS, payload: data });
};

const updateOrderDetails = (data) => (dispatch) => {
	validateLink(data?.token);
	dispatch({ type: UPDATE_ORDER_DETAILS, payload: data });
};

const clearVerification = (token) => (dispatch) => {
	validateLink(token);
	dispatch({ type: CLEAR_VERIFICATION, payload: token });
};

export {
	initWaybillComfirmation,
	updateWaybillDetails,
	updateOrderDetails,
	updateWaybillStatus,
	nextStep,
	previousStep,
	goToStep,
	clearVerification,
};
