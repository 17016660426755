import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { connect } from "react-redux";
import { LOADING, ERROR } from "../../../../config/enums";
import { API } from "../../../../lib/api";
import { validateWaybillLink } from "../../../../lib/api/apiRoutesConfig/services/tradeMS/common/subRoutes/waybill";
import { getErrorMessage } from "../../../utils/helpers";
import OperationStatus, {
	failureResponseStatus,
} from "../../../dashboard/utils/OperationStatus/OperationStatus";
import {
	nextStep,
	clearVerification,
} from "../../../../actions/waybillComfirmation";
import BigLoader from "../../../utils/BigLoader";
import FadeIn from "../../../dashboard/components/effects/FadeIn";

const WaybillLinkVerification = ({ history, nextStep, clearVerification }) => {
	const [currentState, setCurrentState] = useState(LOADING);
	const [statusObj, setStatusObj] = useState({});

	const { token } = queryString.parse(history.location.search);

	useEffect(() => {
		confirmWaybillLink();
		// eslint-disable-next-line
	}, []);

	const confirmWaybillLink = async () => {
		try {
			setCurrentState(LOADING);
			const res = await API.put(validateWaybillLink(token));

			if (res.status === 200) nextStep(token);
		} catch (err) {
			setCurrentState(ERROR);
			setStatusObj({
				status: failureResponseStatus,
				statusMessage: getErrorMessage(err),
			});
		}
	};

	const goHome = () => {
		clearVerification(token);
		history.replace("/dashboard");
	};

	const renderBasedOnState = () => {
		switch (currentState) {
			case LOADING:
				return <BigLoader />;

			case ERROR:
				return (
					<FadeIn>
						<div className="row">
							<div className="col-12">
								<OperationStatus
									statusObj={statusObj}
									handleTryAgain={confirmWaybillLink}
								/>
							</div>

							<div className="col-12 mt-n5" onClick={goHome}>
								<p className="pointer text-warning text-center">
									Go Home
								</p>
							</div>
						</div>
					</FadeIn>
				);

			default:
				return "";
		}
	};
	return (
		<div className="col-xl-4 col-lg-6 col-md-6 col-sm-7 col-10">
			{renderBasedOnState()}
		</div>
	);
};

export default connect(null, { nextStep, clearVerification })(
	WaybillLinkVerification
);
