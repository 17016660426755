import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// "react-datepicker": "^2.7.0",

function FormDatePickerGroup({
	label,
	min,
	max,
	placeholder = "dd/mm/yy",
	selected,
	error,
	onChange,
	isClearable = true,
	dateFormat = "dd/MM/yyyy",
	...restProps
}) {
	const filterPassedTime = (time) => {
		const currentDate = new Date();
		const selectedDate = new Date(time);

		return currentDate.getTime() < selectedDate.getTime();
	};

	return (
		<div className="form-group">
			{label && <label className="form-group__label">{label}</label>}

			<DatePicker
				dateFormat={dateFormat}
				isClearable={isClearable}
				clearButtonTitle="Clear selected date"
				showMonthDropdown
				showYearDropdown
				dropdownMode="select"
				popperPlacement="auto"
				selected={selected}
				minDate={min}
				maxDate={max}
				placeholderText={placeholder}
				className={
					error
						? "datepicker--has-error form-control"
						: "form-control"
				}
				{...restProps}
				onChange={onChange}
				filterTime={filterPassedTime}
			/>

			{error && <span className="error-msg">{error}</span>}
		</div>
	);
}

export default FormDatePickerGroup;
