import React from "react";
import { useState } from "react";
import "../../MarketPlace.scss";
import { LeftSideNav } from "../utils/LeftSideNav";
import { MktTopNav } from "../utils/MktTopNav";
import BackButton from "components/utils/BackButton/BackButton";
import { HeroBanner } from "../utils/HeroBanner";
// import PopularItems from "../PopularItems/PopularItems";
// import RecentlyAdded from "../RecentlyAdded/RecentlyAdded";
import { API } from "lib/api";
import {
	getCategories,
	getProductsByCategory,
	getPopularProducts,
	getAllProducts
} from "lib/api/apiRoutesConfig/services/tradeMS/individualTrade/subRoutes";
import { ToastsStore } from "react-toasts";
import { getErrorMessage } from "components/utils/helpers";
import { useEffect } from "react";
import { ERROR, FINISHED, IDLE, LOADING } from "config/enums";
import ProductsList from "../ProductsList/ProductsList";
import useFetchCart from "../utils/hooks/useFetchCart";
import PopularItems from "../PopularItems/PopularItems";
import RecentlyAdded from "../RecentlyAdded/RecentlyAdded";
import { searchProduct } from "actions/marketPlace";
import { useDispatch } from "react-redux";

export const Home = ({ cartType }) => {
	const dispatch = useDispatch()
	const [currentCategoryStatus, setCurrentCategoryStatus] = useState(IDLE);
	const [allProductStatus, setAllProductStatus] = useState(IDLE);
	const [showAllProduct, setShowAllProduct] = useState(false);
	const [categoryErrorMsg, setCategoryErrorMsg] = useState("");
	const [, setPopularProductErrorMsg] = useState("");
	const [currentProductsStatus, setCurrentProductsStatus] = useState(IDLE);
	const [, setPopularProductsStatus] = useState(IDLE);
	const [productsErrorMsg, setProductsErrorMsg] = useState("");
	const [selectedCategoryCode, setSelectedCategoryCode] = useState("");
	const [selectedCategoryName, setSelectedCategoryName] = useState("");
	const [categoryProductsList, setCategoryProductsList] = useState(null);
	const [popularProductsList, setPopularProductsList] = useState(null);
	const [allProduct, setAllProduct] = useState(null);
	const [categories, setCategories] = useState(null);
	const [categoryPage, setCategoryPage] = useState(0);
	const [page, setPage] = useState(0);
	const [size] = useState(20);

	const { handleFetchCart } = useFetchCart();
	// fetch all categories
	const fetchCategories = async () => {
		setCurrentCategoryStatus(LOADING);
		setCategoryErrorMsg("");
		try {
			const response = await API.get(getCategories, {
				params: {
					page: categoryPage,
					size: 50,
				},
			});
			const { data, status } = response || {};
			if (status === 200) {
				setCategories(data.data);
				setCurrentCategoryStatus(FINISHED);
			}
		} catch (err) {
			setCategories(null);
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
			setCurrentCategoryStatus(ERROR);
			setCategoryErrorMsg(getErrorMessage(err));
		}
	};

	// fetch products by category selected
	const fetchProductsByCategory = async (categoryCode) => {
		setCurrentProductsStatus(LOADING);
		try {
			const response = await API.get(
				getProductsByCategory(categoryCode),
				{
					params: {
						page,
						size
					},
				}
			);
			const { data, status } = response || {};
			if (status === 200) {
				setCategoryProductsList(data.data);
				setCurrentProductsStatus(FINISHED);
			}
		} catch (err) {
			setCategoryProductsList(null);
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
			setCurrentProductsStatus(ERROR);
			setProductsErrorMsg(getErrorMessage(err));
		}
	};

	// fetch all categories
	const fetchPopularProducts = async () => {
		setPopularProductsStatus(LOADING);
		setPopularProductErrorMsg("");
		try {
			const response = await API.get(getPopularProducts);
			const { data, status } = response || {};
			if (status === 200) {
				setPopularProductsList(data.data);
				setPopularProductsStatus(FINISHED);
			}
		} catch (err) {
			setCategories(null);
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
			setPopularProductsStatus(ERROR);
			setPopularProductErrorMsg(getErrorMessage(err));
		}
	};


		// fetch all view products
	const fetchAllProducts = async () => {
		setAllProductStatus(LOADING);
		setProductsErrorMsg("");
		try {
			const response = await API.get(getAllProducts, {
				params: {
					page,
					size
				},
			});
			const { data, status } = response || {};
      if (status === 200) {
				setAllProduct(data.data);
				setAllProductStatus(FINISHED);
			}
		} catch (err) {
			setAllProduct(null);
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
			setAllProductStatus(ERROR);
			setProductsErrorMsg(getErrorMessage(err));
		}
	};


	// handle view all products
	const handleViewAllProducts = () => {
		fetchAllProducts()
		setShowAllProduct(true)
	}

	useEffect(() => {
	
		fetchCategories();
		fetchPopularProducts()
		dispatch(searchProduct(''))
		handleFetchCart(cartType);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		fetchAllProducts()
		if (selectedCategoryCode) {
			fetchProductsByCategory(selectedCategoryCode )
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, size]);

	return (
		<>
			<div className="pb-5 market-place d-flex flex-column">
				<MktTopNav cartType={cartType} />
				{selectedCategoryCode && (
					<div className="mkt-back-button">
						<BackButton
							label="Home"
							onClick={() => {
								setSelectedCategoryCode("");
								setSelectedCategoryName("")
								setCategoryProductsList(null);
							}}
						/>
					</div>
				)}
					{showAllProduct===true && (
					<div className="mkt-back-button">
						<BackButton
							label="Home"
							onClick={() => {
								setShowAllProduct(false);
								setAllProduct(null);
							}}
						/>
					</div>
				)}

				{selectedCategoryCode && <div className="d-flex flex-column mkt-home-wrapper">
						<div className=" category-title">
						<h2>{selectedCategoryName}</h2>
					</div>
				</div>}

				<div className="d-flex flex-row mkt-home-wrapper">
				
					<LeftSideNav
						selectedCategoryCode={selectedCategoryCode}
						currentStatus={currentCategoryStatus}
						errorMsg={categoryErrorMsg}
						retry={fetchCategories}
						categories={categories}
						page={categoryPage}
						goToPage={(page) => setCategoryPage(page)}
						onSelect={(item) => {
							setSelectedCategoryCode(item.code);
							setSelectedCategoryName(item.name)
							fetchProductsByCategory(item.code);
						}}
					/>
					<div className="d-flex flex-grow-1 flex-column ml-3 mkt-home-products">
						{!selectedCategoryCode && !showAllProduct &&<HeroBanner />}
						{!selectedCategoryCode && !showAllProduct && (
							<>
								<PopularItems items={popularProductsList?.popularProducts} viewProducts ={handleViewAllProducts}  cartType={cartType} />
								<RecentlyAdded items={popularProductsList?.recentlyAddedProducts} viewProducts ={handleViewAllProducts}  cartType={cartType}  />
							</>
						)}
						{selectedCategoryCode && (
							<ProductsList
								products={categoryProductsList}
								retry={fetchProductsByCategory}
								errorMsg={productsErrorMsg}
								currentStatus={currentProductsStatus}
								page={page}
								cartType={cartType}
								goToPage={(page) => setPage(page)}
							/>
						)}

						{showAllProduct && !selectedCategoryCode && (
							<ProductsList
								products={allProduct}
								retry={fetchAllProducts}
								errorMsg={productsErrorMsg}
								currentStatus={allProductStatus}
								page={page}
								cartType={cartType}
								goToPage={(page) => setPage(page)}
							/>
						)}
					</div>
				</div>
			</div>
		</>
	);
};
