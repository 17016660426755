import React, { Component } from "react";
import Modal from "../../../../../../../utils/Modal/Modal";
import {
    INITIAL,
    LOADING,
    FINISHED,
} from "../../../../../../../../config/enums";
import OperationStatus, { failureResponseStatus, successResponseStatus } from "../../../../../../utils/OperationStatus/OperationStatus";
import { getErrorMessage } from "../../../../../../../utils/helpers";
import LoadingBox from "../../../../../../../utils/LoadingBox/LoadingBox";
import { API } from "../../../../../../../../lib/api";
import { changePasswordRoute } from "../../../../../../../../lib/api/apiRoutesConfig/services/AccountMS/profile/settings/subRoutes";
import ChangePasswordForm from "./ChangePasswordForm/ChangePasswordForm";

class ChangePasswordSettings extends Component {
    state = {
        currentState: INITIAL,
    };

    handleRequest = async (request) => {
        try {
            this.setState({ currentState: LOADING });
            const res =  await API.put(changePasswordRoute, {...request});
            if (res.status === 200){
                this.setState({
                    currentState: FINISHED,
                    statusObj: {
                        status: successResponseStatus,
                        statusMessage: res.data.message,
                        successLink: "/dashboard",
                        successLinkText: "Okay",
                    },
                });
            }
        } catch (err) {
            this.setState({
                currentRequest: {},
                currentState: FINISHED,
                statusObj: {
                    status: failureResponseStatus,
                    statusMessage: getErrorMessage(err),
                },
            });
        }
    };

    renderBasedOnState = () => {
        const { currentState } = this.state;
        const { toggleModal } = this.props;
        switch (currentState) {
            case INITIAL:
                return (
                    <ChangePasswordForm
                        handleRequest={this.handleRequest}
                        toggleModal={toggleModal}
                    />
                );
            case LOADING:
                return <LoadingBox />;

            case FINISHED:
                const { statusObj } = this.state;
                return (
                    <OperationStatus
                        statusObj={statusObj}
                        handleTryAgain={toggleModal}
                        {...this.props}
                    />
                );

            default:
                break;
        }
    };

    render() {
        const { show } = this.props;
        return <Modal show={show}>{this.renderBasedOnState()}</Modal>;
    }
}

export default ChangePasswordSettings;
