import React, { Component } from "react";
import { PepperStroke } from "./stroke";

class FailedImage extends Component {
	componentDidMount = () => {
		PepperStroke("gold_circle");
		PepperStroke("line_red_one");
		setTimeout(() => {
			PepperStroke("line_red_two");
		}, 500);
	};

	render = () => {
		return (
			<div className="d-flex justify-content-center w-100">
				<svg
					width="55"
					height="55"
					viewBox="0 0 55 55"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<circle
						id="gold_circle"
						cx="27.5"
						cy="27.5"
						r="26.5"
						stroke="#d1a23c"
						strokeWidth="2"
					/>
					<line
						id="line_red_one"
						x1="13.1724"
						y1="39.4716"
						x2="39.5963"
						y2="18.8279"
						stroke="#d1a23c"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<line
						id="line_red_two"
						x1="14.4039"
						y1="18.8277"
						x2="40.8274"
						y2="39.4719"
						stroke="#d1a23c"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</div>
		);
	};
}

export default FailedImage;
