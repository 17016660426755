import React, { Component } from "react";
import ModalHeader from "../../../../../../../../utils/Modal/CustomModalHeader/CustomModalHeader";
import TranscationPinInput from "../../../../../../transcations/TranscationPinInput";

class TransactionPinSetForm extends Component {
    state = {
        errors: [],
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ errors: [] });
        const { currentPinInput, newPinInput, confirmPinInput } = this.refs;
        const currentPinValue = currentPinInput.getPin();
        const newPinValue = newPinInput.getPin();
        const confirmPinValue = confirmPinInput.getPin();

        if (currentPinValue.length !== 4) {
            currentPinInput.shakeInvalid();
        } else if (newPinValue.length !== 4) {
            newPinInput.shakeInvalid();
        } else if (newPinValue !== confirmPinValue) {
            this.setState({
                errors: ["New pin and confirm new pin don't match"],
            });
        } else {
            const { handleRequest } = this.props;
            const request = {
                currentPin: currentPinValue,
                newPin: newPinValue,
            };
            handleRequest(request);
        }
    };

    render = () => {
        const { errors } = this.state;
        const { toggleModal } = this.props;

        return (
            <div>
                <ModalHeader heading='Change Transcation Pin' />
                <div className='low-form text-center px-5 pt-2 pb-4'>
                    <form onSubmit={this.handleSubmit}>
                        <TranscationPinInput
                            label='Current Pin'
                            ref='currentPinInput'
                            className="my-3"
                            id='pin0'
                        />
                        <TranscationPinInput
                            label='New Pin'
                            ref='newPinInput'
                            className="my-3"
                            id='pin1'
                        />
                        <TranscationPinInput
                            label='Confirm New Pin'
                            ref='confirmPinInput'
                            className="my-3"
                            id='pin2'
                        />
                        <span className='error-msg text-center'>
                            {errors[0]}
                        </span>{" "}
                        <br />
                        <div className='row justify-content-center'>
                            <div className='col-md-7'>
                                <div className='dialog-footer mt-3'>
                                    <button
                                        className='btn create-acc text-capitalize'
                                        style={{ boxShadow: "none" }}>
                                        Proceed
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='row justify-content-center'>
                            <div className='col-md-7'>
                                <div className='dialog-footer mt-1'>
                                    <button
                                        className='btn btn-cancel text-capitalize'
                                        type='button'
                                        onClick={toggleModal}>
                                        cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    };
}

export default TransactionPinSetForm;
