/* eslint-disable react-hooks/exhaustive-deps */
import React,{useState, useEffect} from "react"
import Modal from "components/utils/Modal/Modal"
import ModalHeader from "components/utils/Modal/CustomModalHeader/CustomModalHeader";
import "./PaymentPanel.scss";
import { getSummaryAction } from "lib/api/apiRoutesConfig/services/tradeMS/BNPL/subRoutes";
import { ToastsStore } from "react-toasts";
import { getErrorMessage } from "components/utils/helpers";
import { SmallLoader } from "components/utils/SmallLoader";
import { formatCurrency } from "components/utils/helpers";
import { API } from "lib/api";



const ClearBalance = ({show, onClose,nextModal}) => {
  return (
    <Modal show={show} scrollable>
      <ModalHeader
				heading="Clear Balance"
				className="p-0"
        headerClassName="setup-modals-header px-sm-5 px-3 py-3"
        dismissable={() => onClose?.()}
      />
      <FeedBack onClose={onClose} nextModal={nextModal} />
		</Modal>
  )
}
export default ClearBalance


const FeedBack = ({ onClose,nextModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [summary, setSummary] = useState(''|| null);

  const fetchBNPLSumarry = async () => {
		setIsLoading(true);
		try {
			const res = await API.get(getSummaryAction);
			const { status, data } = res || {};
      if (status === 200) {
        setSummary(data.data)
			}
    } catch (err) {
      console.log('error')
      onClose()
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		} finally {
			setIsLoading(false);
		}
	};

  
  const handleNextAction = () => {
    onClose()
    nextModal()
  }

  useEffect(() => {
    fetchBNPLSumarry()
  },[])

  return (
    <div className="mkt-payment-modal mkt-payment-modal__content">
      <div className="clear-balance__content">
        <div className="summary">
          <div className=" d-flex flex-row div-container ">
            <span className="title">Total Order Amount</span>
            <span className="amount">{formatCurrency(summary?.totalOrderAmount)}</span>
          </div>
          <div className=" d-flex flex-row div-container ">
            <span className="title">Amount Paid</span>
            <span className="amount">
              {formatCurrency(summary?.initialPaymentAmount)}
            </span>
          </div>

           <div className=" d-flex flex-row amount-to-pay   ">
            <span className=" title">Amount  Due for Repayment</span>
            <span className=" amount">
              {formatCurrency(summary?.outstandingBalance)}
            </span>
          </div>

        </div>
      </div>
      <div className=" d-flex flex-column">
        <button
          onClick={handleNextAction}
            className="btn btn-mint-default "
            type="button"
            disabled={isLoading}
          >
            {isLoading ? (
              <SmallLoader />
            ) : (
              <>
               Clear Balance
              </>
            )}
          </button>
     </div>

      
      
    </div>
  )
}