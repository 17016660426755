import React, { useState } from "react";
import { useSelector } from "react-redux";
import { API } from "../../../../../../lib/api";
import { liquidateInvestment } from "../../../../../../lib/api/apiRoutesConfig/services/SavingsMS/investments/subRoutes";
import { getErrorMessage, parseAmount } from "../../../../../utils/helpers";
import ModalHeader from "../../../../../utils/Modal/CustomModalHeader/CustomModalHeader";
import InvestmentPreviewModal from "../InvestmentPreviewModal/InvestmentPreviewModal";
import WarningResponse from "../../../../utils/WarningResponse/WarningResponse";
import CustomCurrencyInput from "components/utils/CustomCurrencyInput/CustomCurrencyInput";

const LiquidateInvestmentModal = ({ showModal, investment, toggleModal }) => {
	const [amount, setAmount] = useState("");
	const [loading, setLoading] = useState();
	const [errorMsg, setErrorMsg] = useState();
	const [successData, setSuccessData] = useState();
	const [showFirst, setShowFirst] = useState(true);
	const [fullLiquidation, setFullLiquidation] = useState(false);
	const [step, setStep] = useState(1);

	const {
		dashboardResponse: { bankAccounts },
	} = useSelector(({ dashboard }) => dashboard);

	const { accountId } = bankAccounts?.length ? bankAccounts[0] : {};

	const next = () => setStep((prev) => prev + 1);

	const LiquidateInvestment = async (transactionPin) => {
		try {
			setErrorMsg();
			setLoading(true);

			const payload = {
				creditAccountId: accountId,
				investmentCode: investment?.code,
				fullLiquidation,
				transactionPin,
			};

			!fullLiquidation && (payload.liquidationAmount = amount);

			const res = await API.post(liquidateInvestment, payload);

			if (res?.status === 200) {
				setLoading(false);
				setSuccessData(res.data);
			} else setError(res.data?.message);
		} catch (err) {
			setError(getErrorMessage(err));
		}
	};

	const setError = (msg) => {
		setLoading(false);
		setErrorMsg(msg);
	};

	const toggle = () => {
		setAmount("");
		setErrorMsg();
		setShowFirst(true);
		toggleModal();
		setSuccessData();
	};

	const renderByStep = () => {
		if (step === 1)
			return (
				<WarningResponse
					message="Are you sure you want to liquidate?"
					paragraph="Liquidating means you will be charged 20% of the accrued interest. Do you still want to proceed?"
					btnName="Liquidate"
					onClose={toggle}
					disabled={!investment?.canLiquidate}
					handleClick={() => next()}
				/>
			);
		if (step === 2)
			return (
				<div className="low-form">
					<ModalHeader
						heading="Liquidate Investment"
						dismissable={toggle}
						headerClassName="rel-button"
					/>

					<div style={{ padding: "0rem 1rem" }}>
						<label className="sub-heading form-heading mt-3 mb-4">
							Liquidating means you will be charged 20% of the
							accrued interest. Do you still want to proceed?
						</label>

						{/* <TransactionAmountInput
						label="How much are you liquidating?"
						updateAmount={(value) => setAmount(value)}
					/> */}

						<CustomCurrencyInput
							label="How much are you liquidating?"
							value={amount}
							name="amount"
							id="amount"
							onChange={(value) => setAmount(parseAmount(value))}
						/>

						<button
							className="btn create-acc mt-4"
							disabled={!amount}
							onClick={() => setShowFirst(false)}
						>
							Continue
						</button>

						<button
							className="btn btn-custom-bordered my-4"
							disabled={!amount}
							onClick={() => {
								setFullLiquidation(true);
								setShowFirst(false);
							}}
						>
							Liquidate All
						</button>
					</div>
				</div>
			);
	};

	return (
		<InvestmentPreviewModal
			closeBtn
			showPinModal={showModal}
			showFirst={showFirst}
			successData={successData}
			errorMsg={errorMsg}
			loading={loading}
			toggleModal={toggle}
			handleSubmit={LiquidateInvestment}
			clearErrorMsg={() => setErrorMsg()}
		>
			{renderByStep()}
		</InvestmentPreviewModal>
	);
};

export default LiquidateInvestmentModal;
