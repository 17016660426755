import React from "react";
import "./nav.scss";
import { ReactSVG } from "react-svg";
import Logo from "../businessName/assets/mintyn-logo.svg";
import { Link } from "react-router-dom";
import ConfirmBackModal from "./confirmBackModal";

const PublicNavbar = ({ children, isOpen, setIsOpen }) => {
	return (
		<>
			<nav className="public-nav">
				{children}
				<div className="logo">
					<ReactSVG src={Logo} />
				</div>
				<Link className="btn-link" to="/sign-in">
					Login to Personal Account
				</Link>
			</nav>
			<ConfirmBackModal onClose={() => setIsOpen(false)} show={isOpen} />
		</>
	);
};

export default PublicNavbar;
