import FailedTryAgain from "components/dashboard/utils/FailedTryAgain/FailedTryAgain";
import { MintInfinite } from "components/dashboard/utils/ShineLoader/ShineLoader";
import { getErrorMessage } from "components/utils/helpers";
import { ERROR, FINISHED, IDLE, LOADING } from "config/enums";
import { API } from "lib/api";
import { getSavingsGoalCategories } from "lib/api/apiRoutesConfig/services/SavingsMS/common/subRoutes";
import React, { useEffect } from "react";
import { useState } from "react";
import { ReactSVG } from "react-svg";
import "../CreateSavingsGoal.scss";
import HouseIcon from "assets/img/savings-icons/house-icon.svg";
import FamilyIcon from "assets/img/savings-icons/life_events_icon.svg";
import VehicleIcon from "assets/img/savings-icons/vehicle_icon.svg";
import OthersIcon from "assets/img/savings-icons/others_icon.svg";
import EducationIcon from "assets/img/savings-icons/education_icon.svg";
import CelebrationIcon from "assets/img/savings-icons/celebration_icon.svg";

export default function InitialStep({ setGoalName, goalName }) {
	const [categories, setCategories] = useState([]);
	const [currentState, setCurrentState] = useState(IDLE);
	const [errorMsg, setErrorMsg] = useState("");

	const fetchSavingsGoalCategories = async () => {
		setCurrentState(LOADING);
		try {
			const response = await API.get(getSavingsGoalCategories);
			const { status, data } = response || {};
			if (status === 200) {
				setCategories(data.data);
				setCurrentState(FINISHED);
			}
		} catch (err) {
			setErrorMsg(getErrorMessage(err));
			setCategories([]);
			setCurrentState(ERROR);
		}
	};

	useEffect(() => {
		fetchSavingsGoalCategories();
	}, []);

	const renderBasedOnState = () => {
		switch (currentState) {
			case LOADING:
				return (
					<div className="d-flex w-100 justify-content-center align-items-center saving-goal-loading">
						<MintInfinite relative />
					</div>
				);

			case ERROR:
				return (
					<div className="d-flex w-100 justify-content-center align-items-center saving-goal-loading">
						<FailedTryAgain
							errorMssg={errorMsg}
							handleTryAgain={fetchSavingsGoalCategories}
						/>
					</div>
				);

			case FINISHED:
				return (
					<div className="cesp-grid">
						{categories.map((category, index) => {
							const categoryIcon = () => {
								switch (category.name) {
									case "Vacation":
										return HouseIcon;

									case "Business":
									case "Others":
										return OthersIcon;

									case "Education":
										return EducationIcon;

									case "Family":
										return FamilyIcon;

									case "Celebration":
										return CelebrationIcon;

									default:
										return VehicleIcon;
								}
							};

							return (
								<Card
									key={index}
									title={category.name}
									code={category.code}
									setGoalName={setGoalName}
									goalName={goalName}
									icon={categoryIcon()}
								/>
							);
						})}
					</div>
				);

			default:
				return null;
		}
	};

	return (
		<div className="create-savings-goal-page__step">
			<h2>What do you want to save for?</h2>
			<p>Select a new savings plan to get started</p>

			{renderBasedOnState()}
		</div>
	);
}

const Card = ({ icon, title, setGoalName, goalName, code }) => {
	return (
		<div
			className="cesp-grid__card"
			onClick={() => setGoalName(title, code)}
		>
			<div
				className={`icon-containa ${
					title === goalName ? "selected" : ""
				}`}
			>
				<ReactSVG src={icon} />
			</div>
			<h3>{title}</h3>
		</div>
	);
};
