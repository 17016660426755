import React from "react";
import { Route, Switch, Redirect, NavLink } from "react-router-dom";

const RouteSwitch = ({ routes, to }) => (
	<Switch>
		{routes?.map(({ path, component, exact }, index) => (
			<Route
				key={index}
				exact={exact}
				path={path}
				component={component}
			/>
		))}
		<Redirect path="*" to={to} />
	</Switch>
);

export const RouteTabSwitch = ({ routes, cls }) => {
	return (
		<div className="pt-4">
			<ul id="setTab" className={`nav nav-tabs ${cls}`} role="tablist">
				{routes &&
					routes.map(({ path, iconName, title, hide }, index) => {
						return (
							!hide && (
								<li className="nav-item pl-0" key={index}>
									<NavLink className="nav-link" to={path}>
										{iconName && (
											<span className={iconName} />
										)}
										{title}
									</NavLink>
								</li>
							)
						);
					})}
			</ul>
		</div>
	);
};

export default RouteSwitch;
