import React, { Component } from "react";
import AccountNotifications from "./AccountNotifications/AccountNotifications";
import SpendLimitSetting from "./SpendLimitSetting/SpendLimitSetting";
import AccountSecuritySettings from "./AccountSecuritySettings/AccountSecuritySettings";
import "./AccountManagement.scss";
import AccessControl from "components/auth/AccessControl/AccessControl";
import permissions from "components/auth/permissions";

class AccountManagement extends Component {
	state = {};

	render() {
		return (
			<div>
				<div className="row justify-content-center">
					<div className="col-lg-7">
						<div className="px-3">
							<SpendLimitSetting />
							{/* Corporate accounts don't have notification settings */}
							<AccessControl
								allowedPermissions={[permissions.IS_INDIVIDUAL]}
							>
								<AccountNotifications />
							</AccessControl>
							<AccountSecuritySettings />
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default AccountManagement;
