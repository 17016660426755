import FadeIn from "components/dashboard/components/effects/FadeIn";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import "../../BillPayment.scss";
import CaretLeft from "assets/img/caret_left_gold.svg";
import { GroomPanel2 } from "components/dashboard/utils/GroomPanel/GroomPanel";
import { Formik, useFormikContext } from "formik";
import * as yup from "yup";
import { API } from "lib/api";
import {
	getAllBillsCategories,
	getBillerItemsbyVasId,
	getBillersbyCategory,
	getBillsBeneficiaries,
	initiateRemita,
} from "lib/api/apiRoutesConfig/services/BillsService/subRoutes";
import { ToastsStore } from "react-toasts";
import { getErrorMessage, parseAmount } from "components/utils/helpers";
import CommonSelect from "components/utils/CommonSelect/CommonSelect";
import CustomCurrencyInput from "components/utils/CustomCurrencyInput/CustomCurrencyInput";
import CustomRadioButton from "components/utils/CustomRadioButton/CustomRadioButton";
import FormInputGroup from "components/utils/FormInputGroup/FormInputGroup";
import FormDatePickerGroup from "components/utils/FormDatePickerGroup/FormDatePickerGroup";
import CustomLargeBadge from "components/utils/CustomLargeBadge/CustomLargeBadge";
import { scheduleFrequencyEnum } from "config/enums";
import CustomField from "./CustomField";
import { SmallLoader } from "components/utils/SmallLoader";
import PayViaCategorySummary from "./PayViaCategorySummary";

const formValidationSchema = (customFields) => {
	return yup.object().shape({
		billCategory: yup
			.object()
			.required("Select a category")
			.nullable()
			.shape({
				label: yup.string(),
				value: yup.mixed(),
			}),
		billMerchant: yup
			.object()
			.required("Select a biller")
			.nullable()
			.shape({
				label: yup.string(),
				value: yup.mixed(),
			}),
		billMerchantItem: yup
			.object()
			.required("Select a product")
			.nullable()
			.shape({
				label: yup.string(),
				value: yup.mixed(),
			}),
		beneficiary: yup.object().nullable().shape({
			label: yup.string(),
			value: yup.mixed(),
		}),
		amount: yup.number().when("billMerchantItem", {
			is: (merchantItem) => merchantItem?.value?.amountType !== "FIXED",
			then: yup
				.number()
				.required("Amount is required")
				.min(1, "Amount is required"),
			otherwise: yup.number(),
		}),
		addToBeneficiary: yup.boolean().default(false),
		recurringBill: yup.boolean().default(true),
		frequency: yup.mixed().when("recurringBill", {
			is: (value) => value === true,
			then: yup.string().required("Select a frequency"),
			otherwise: yup.mixed(),
		}),
		title: yup.string().when("recurringBill", {
			is: (value) => value === true,
			then: yup.string().required("Bill title is required"),
			otherwise: yup.string(),
		}),
		startDateTime: yup.mixed().when("recurringBill", {
			is: (value) => value === true,
			then: yup.date().required("Start date is required"),
			otherwise: yup.mixed(),
		}),
		endDate: yup.mixed().when("recurringBill", {
			is: (value) => value === true,
			then: yup.date().required("End date is required"),
			otherwise: yup.mixed(),
		}),
		customerName: yup.mixed().when("addToBeneficiary", {
			is: (value) => value === true,
			then: yup.string().required("Recipient name is required"),
			otherwise: yup.mixed(),
		}),
		/// custom fields from billMerchantItem selection
		...Object.fromEntries(
			customFields.map((field) => {
				let type;
				if (field?.type === "singleselect") {
					type = yup.string().required("Select an option");
				} else if (field?.type === "multiselect") {
					type = yup.array().required("Select options").nullable();
				} else if (field?.type === "date") {
					type = yup
						.date()
						.required(`${field?.display_name} is required`);
				} else {
					type = yup
						.string()
						.required(`${field?.display_name} is required`);
				}
				return [field.variable_name, type];
			})
		),
	});
};

export default function RemitaViaCategory() {
	const history = useHistory();
	const [isCategoriesLoading, setIsCategoriesLoading] = useState(false);
	const [billCategories, setBillCategories] = useState([]);
	const [billMerchants, setBillMerchants] = useState([]);
	const [isBillMerchantLoading, setIsBillMerchantLoading] = useState(false);
	const [billMerchantItems, setBillMerchantItems] = useState([]);
	const [isMerchantItemLoading, setIsMerchantItemLoading] = useState(false);
	const [transaction, setTransaction] = useState(null);
	const [isSummaryShown, setIsSummaryShown] = useState(false);
	const [isBeneficiariesLoading, setBeneficiariesLoading] = useState(false);
	const [beneficiaries, setBeneficiaries] = useState([]);
	const [customFields, setCustomFields] = useState([]);
	const [isInitiating, setIsInitiating] = useState(false);
	const [formValues, setFormValues] = useState({
		amount: "",
		billCategory: null,
		billMerchant: null,
		billMerchantItem: null,
		addToBeneficiary: false,
		customerName: "",
		endDate: undefined,
		frequency: "",
		recurringBill: true,
		startDateTime: undefined,
		title: "",
		beneficiary: null,
	});

	const [customPayload, setCustomPayload] = useState({});

	const fetchAllBillCategories = async () => {
		setIsCategoriesLoading(true);
		try {
			const response = await API.get(getAllBillsCategories, {
				params: {
					vasProviderName: "REMITA",
				},
			});
			const { data, status } = response || {};
			if (status === 200) setBillCategories(data.data);
		} catch (err) {
			setBillCategories([]);
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		} finally {
			setIsCategoriesLoading(false);
		}
	};

	const fetchBillMerchants = async (categoryId) => {
		setIsBillMerchantLoading(true);
		try {
			const response = await API.get(getBillersbyCategory(categoryId));
			const { data, status } = response || {};
			if (status === 200) setBillMerchants(data.data);
		} catch (err) {
			setBillMerchants([]);
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		} finally {
			setIsBillMerchantLoading(false);
		}
	};

	const fetchBillMerchantItems = async (vasBillerId) => {
		setIsMerchantItemLoading(true);
		try {
			const response = await API.get(getBillerItemsbyVasId(vasBillerId));
			const { data, status } = response || {};
			if (status === 200) setBillMerchantItems(data.data);
		} catch (err) {
			setBillMerchantItems([]);
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		} finally {
			setIsMerchantItemLoading(false);
		}
	};

	const fetchBillBeneficiary = async (vasBillerId) => {
		setBeneficiariesLoading(true);
		try {
			const response = await API.get(getBillsBeneficiaries, {
				params: {
					billerId: vasBillerId,
				},
			});
			const { status, data } = response || {};
			if (status === 200) setBeneficiaries(data.data);
		} catch (err) {
			setBeneficiaries([]);
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		} finally {
			setBeneficiariesLoading(false);
		}
	};

	const handleInitiateRemita = async (values) => {
		setIsInitiating(true);
		try {
			const response = await API.post(initiateRemita, {
				amount: values.amount,
				customFields: {
					...customPayload,
				},
				vasItemId: values.billMerchantItem?.value.vasItemId,
			});
			const { status, data } = response || {};
			if (status === 200) {
				setIsInitiating(false);
				setTransaction({
					...data.data,
					...values,
					biller: values.billMerchant?.value.name,
					itemCode: values.billMerchantItem?.value.itemCode,
				});
				setIsSummaryShown(true);
			}
		} catch (err) {
			setIsInitiating(false);
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		}
	};

	useEffect(() => {
		fetchAllBillCategories();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Helmet>
				<title>
					{process.env.REACT_APP_NAME} - Bills Payment / Remita Pay
					Via Category
				</title>
			</Helmet>

			<FadeIn>
				<section className="pb-5 bp-categories__category">
					<div className="d-flex align-items-center mb-5">
						<button
							className="back-btn d-flex align-items-center"
							onClick={() => history.goBack()}
						>
							<img src={CaretLeft} alt="" />
							<span>Back</span>
						</button>
					</div>

					<GroomPanel2 title="Pay via Category" className="pb-5">
						<Formik
							enableReinitialize
							initialValues={formValues}
							validationSchema={formValidationSchema(
								customFields
							)}
							onSubmit={(values) => {
								handleInitiateRemita(values);
							}}
						>
							{({
								values,
								errors,
								touched,
								handleSubmit,
								setFieldValue,
								handleChange,
								handleBlur,
								setFieldTouched,
								resetForm,
							}) => {
								return (
									<>
										<form
											className="bp-categories__form"
											onSubmit={(e) => {
												e.preventDefault();
												handleSubmit();
											}}
										>
											<div className="mb-4">
												<label htmlFor="billCategory">
													Category
												</label>
												<CommonSelect
													isSearchable
													menuPortalTarget={null}
													name="billCategory"
													value={values.billCategory}
													placeholder="Select Category"
													isLoading={
														isCategoriesLoading
													}
													onBlur={handleBlur}
													error={
														touched.billCategory &&
														errors.billCategory
													}
													handleChange={(
														category
													) => {
														setFieldValue(
															"billCategory",
															category
														);
														setBillMerchantItems(
															[]
														);
														fetchBillMerchants(
															category.value.id
														);
														// reset fields
														setCustomFields([]);
														setCustomPayload({});
														resetForm({
															values: {
																...values,
																billCategory:
																	category,
																billMerchant:
																	null,
																billMerchantItem:
																	null,
																beneficiary:
																	null,
																recurringBill:
																	values.recurringBill,
																frequency: "",
																amount: "",
																customerId: "",
																customerName:
																	"",
																title: "",
																startDateTime:
																	undefined,
																endDate:
																	undefined,
															},
														});
														setFieldTouched(
															"amount",
															false
														);
													}}
													options={billCategories.map(
														(item) => ({
															label: item.name,
															value: item,
														})
													)}
													showErrorMsg
												/>
											</div>

											<div className="mb-4">
												<label htmlFor="billMerchant">
													Merchant or Biller
												</label>
												<CommonSelect
													isSearchable
													menuPortalTarget={null}
													name="billMerchant"
													value={values.billMerchant}
													placeholder="Select Biller"
													isLoading={
														isBillMerchantLoading
													}
													onBlur={handleBlur}
													error={
														touched.billMerchant &&
														errors.billMerchant
													}
													handleChange={(
														merchant
													) => {
														setFieldValue(
															"billMerchant",
															merchant
														);
														fetchBillMerchantItems(
															merchant.value
																.vasBillerId
														);
														fetchBillBeneficiary(
															merchant.value
																.vasBillerId
														);
														// reset fields
														setCustomPayload({});
														resetForm({
															values: {
																...values,
																billMerchant:
																	merchant,
																billMerchantItem:
																	null,
																beneficiary:
																	null,
																recurringBill:
																	values.recurringBill,
																frequency: "",
																amount: "",
																customerId: "",
																customerName:
																	"",
																title: "",
																startDateTime:
																	undefined,
																endDate:
																	undefined,
															},
														});
														setFieldTouched(
															"amount",
															false
														);
													}}
													options={billMerchants.map(
														(item) => ({
															label: item.name,
															value: item,
														})
													)}
													showErrorMsg
												/>
											</div>

											<div className="mb-4">
												<label htmlFor="billMerchantItem">
													Product
												</label>
												<CommonSelect
													isSearchable
													menuPortalTarget={null}
													name="billMerchantItem"
													value={
														values.billMerchantItem
													}
													placeholder="Select Product"
													isLoading={
														isMerchantItemLoading
													}
													onBlur={handleBlur}
													error={
														touched.billMerchantItem &&
														errors.billMerchantItem
													}
													handleChange={(
														merchantItem
													) => {
														setFieldValue(
															"billMerchantItem",
															merchantItem
														);
														setFieldValue(
															"amount",
															merchantItem.value
																.amount
														);
														// reset fields
														setCustomPayload({});
													}}
													options={[
														...billMerchantItems,
													]
														.sort(
															(a, b) =>
																a.amount -
																b.amount
														)
														.map((item) => ({
															label: item.name,
															value: item,
														}))}
													showErrorMsg
												/>
											</div>

											<div className="mb-4">
												<label>Beneficiary</label>
												<CommonSelect
													isSearchable
													menuPortalTarget={null}
													name="beneficiary"
													isLoading={
														isBeneficiariesLoading
													}
													value={values.beneficiary}
													placeholder="Choose Beneficiary"
													onBlur={handleBlur}
													handleChange={(item) => {
														setFieldValue(
															"beneficiary",
															item
														);
														setFieldValue(
															"customerId",
															item.value
																.customerId
														);
														setFieldValue(
															"addToBeneficiary",
															false
														);
														setFieldValue(
															"customerName",
															""
														);
													}}
													options={beneficiaries.map(
														(item) => ({
															label: item.customerName,
															value: item,
														})
													)}
												/>
											</div>

											{/* Custom fields start */}
											<CustomField
												customFields={customFields}
												values={values}
												onBlur={handleBlur}
												touched={touched}
												errors={errors}
												setFieldValue={setFieldValue}
												customPayload={customPayload}
												setCustomPayload={
													setCustomPayload
												}
											/>
											{/* Custom fields end */}

											<div className="mb-4">
												<CustomCurrencyInput
													className="bp-input"
													name="amount"
													label="Amount"
													id="amount"
													value={values.amount}
													placeholder="Enter Amount"
													onBlur={handleBlur}
													disabled={
														values.billMerchantItem
															?.value
															?.amountType ===
															"FIXED" ||
														billMerchantItems.length ===
															0
													}
													error={
														touched.amount &&
														errors.amount
													}
													onChange={(value) => {
														setFieldValue(
															"amount",
															parseAmount(value)
														);
													}}
												/>
											</div>

											<div className="mb-4">
												<label>
													Would you like to schedule
													this bill?
												</label>
												<div className="d-flex align-items-center mt-2">
													<CustomRadioButton
														checked={
															values.recurringBill ===
															true
														}
														name="recurringBill"
														onChange={() =>
															setFieldValue(
																"recurringBill",
																true
															)
														}
														label="Yes"
													/>
													<CustomRadioButton
														cls="ml-5"
														checked={
															values.recurringBill ===
															false
														}
														name="recurringBill"
														onChange={() => {
															setFieldValue(
																"recurringBill",
																false
															);
															resetForm({
																values: {
																	...values,
																	recurringBill: false,
																	frequency:
																		"",
																	title: "",
																	startDateTime:
																		undefined,
																	endDate:
																		undefined,
																},
															});
														}}
														label="No"
													/>
												</div>
												{touched.recurringBill &&
													errors.recurringBill && (
														<span className="error-msg">
															{
																errors.recurringBill
															}
														</span>
													)}
											</div>

											{values.recurringBill && (
												<>
													<div className="mb-4">
														<FormInputGroup
															id="title"
															label="Bill Title"
															name="title"
															placeholder="Enter a title"
															className="bp-input"
															onBlur={handleBlur}
															value={values.title}
															onChange={
																handleChange
															}
															error={
																touched.title &&
																errors.title
															}
														/>
														<span className="bp-form-info">
															Enter a title that
															would help you
															remember this bill
															schedule.
														</span>
													</div>

													<div className="mb-4 bp-recurring-dates">
														<div className="mr-3">
															<label>
																Start Date
															</label>
															<FormDatePickerGroup
																showTimeSelect
																dateFormat="dd/MM/yyyy @ hh:mm a"
																timeIntervals={
																	60
																}
																isClearable={
																	false
																}
																placeholder="From"
																selected={
																	values.startDateTime
																}
																onBlur={
																	handleBlur
																}
																min={new Date()}
																name="startDateTime"
																onChange={(
																	date
																) => {
																	setFieldValue(
																		"startDateTime",
																		date
																	);
																}}
																error={
																	touched.startDateTime &&
																	errors.startDateTime
																}
															/>
														</div>
														<div>
															<label>
																End Date
															</label>
															<FormDatePickerGroup
																isClearable={
																	false
																}
																placeholder="To"
																selected={
																	values.endDate
																}
																onBlur={
																	handleBlur
																}
																min={new Date()}
																name="endDate"
																onChange={(
																	date
																) => {
																	setFieldValue(
																		"endDate",
																		date
																	);
																}}
																error={
																	touched.endDate &&
																	errors.endDate
																}
															/>
														</div>
													</div>

													<div className="mb-4">
														<label>Frequency</label>
														<div className="bp-frequency">
															<CustomLargeBadge
																title="One Time"
																isSelected={
																	values.frequency ===
																	scheduleFrequencyEnum.ONCE
																}
																onClick={() =>
																	setFieldValue(
																		"frequency",
																		scheduleFrequencyEnum.ONCE
																	)
																}
															/>
															<CustomLargeBadge
																title="Daily"
																isSelected={
																	values.frequency ===
																	scheduleFrequencyEnum.DAILY
																}
																onClick={() =>
																	setFieldValue(
																		"frequency",
																		scheduleFrequencyEnum.DAILY
																	)
																}
															/>
															<CustomLargeBadge
																title="Weekly"
																isSelected={
																	values.frequency ===
																	scheduleFrequencyEnum.WEEKLY
																}
																onClick={() =>
																	setFieldValue(
																		"frequency",
																		scheduleFrequencyEnum.WEEKLY
																	)
																}
															/>
														</div>
														{touched.frequency &&
															errors.frequency && (
																<span className="error-msg">
																	{
																		errors.frequency
																	}
																</span>
															)}
													</div>
												</>
											)}

											{!values.beneficiary?.value && (
												<div
													className="mb-4 d-flex custom-control custom-checkbox d-flex align-items-center"
													style={{
														marginTop:
															values.recurringBill
																? "20px"
																: "45px",
													}}
												>
													<input
														type="checkbox"
														className="custom-control-input"
														id="addToBeneficiary"
														name="addToBeneficiary"
														onChange={() => {
															setFieldValue(
																"addToBeneficiary",
																!values.addToBeneficiary
															);
															setFieldValue(
																"customerName",
																""
															);
														}}
														checked={
															values.addToBeneficiary
														}
													/>
													<label
														className="custom-control-label"
														htmlFor="addToBeneficiary"
													>
														Add recipient as
														beneficiary
													</label>
												</div>
											)}

											{values.addToBeneficiary && (
												<div className="mb-4">
													<FormInputGroup
														id="customerName"
														label="Recipient Name"
														name="customerName"
														placeholder="Enter recipient name"
														className="bp-input"
														onBlur={handleBlur}
														value={
															values.customerName
														}
														onChange={handleChange}
														error={
															touched.customerName &&
															errors.customerName
														}
													/>
												</div>
											)}

											<button
												className="btn btn-mint-default mt-2"
												type="submit"
												disabled={isInitiating}
											>
												{isInitiating ? (
													<SmallLoader />
												) : (
													"Continue"
												)}
											</button>
										</form>

										<FormikObserver
											setCustomFields={setCustomFields}
											customFields={customFields}
											setFormValues={setFormValues}
										/>
									</>
								);
							}}
						</Formik>

						<PayViaCategorySummary
							show={isSummaryShown && !!transaction}
							transaction={transaction}
							onClose={() => {
								setIsSummaryShown(false);
								setTransaction(null);
							}}
						/>
					</GroomPanel2>
				</section>
			</FadeIn>
		</>
	);
}

const FormikObserver = ({ setCustomFields, customFields, setFormValues }) => {
	const { values } = useFormikContext();

	useEffect(() => {
		if (values.billMerchantItem?.value) {
			const { metaData } = values.billMerchantItem?.value || {};
			setCustomFields(metaData?.customFields || []);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values]);

	useEffect(() => {
		if (customFields.length > 0) {
			setFormValues({
				...values,
				...Object.fromEntries(
					customFields.map((field) => {
						let initialValue;
						if (
							field?.type === "singleselect" ||
							field?.type === "multiselect"
						) {
							initialValue = null;
						} else if (field?.type === "date") {
							initialValue = undefined;
						} else {
							initialValue = "";
						}
						return [field?.variable_name, initialValue];
					})
				),
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customFields]);

	return null;
};
