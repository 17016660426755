import common, { commonV2 } from "../";

export const {
	allSavingsPlans,
	getAllSavingsTenors,
	getAllInvestmentTenors,
	getSavingsGoalCategories,
} = {
	allSavingsPlans: `${common}/savings-plans`,
	getAllSavingsTenors: `${common}/savings-tenors`,
	getAllInvestmentTenors: `${commonV2}/investment-tenors`,
	getSavingsGoalCategories: `${common}/savings-goal-categories`,
};
