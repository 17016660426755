import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
// import { retriveAccessToken } from "../storage/sessionStorage";

export const ProtectedRoute = ({ component: Component, ...rest }) => {
	// const hasToken = retriveAccessToken();

	const { isLoggedIn } = useSelector((state) => state.auth);

	return (
		<Route
			{...rest}
			render={(props) =>
				isLoggedIn ? (
					<Component {...props} />
				) : (
					<Redirect
						// exact
						to={{
							pathname: "/sign-in",
							state: {
								from: props.location,
							},
						}}
					/>
				)
			}
		/>
	);
};
