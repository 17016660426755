import React from "react";
import { Route } from "react-router-dom";

const RouteContainer = ({ routes, baseURL }) => (
	<>
		{routes.map(({ path, component, exact }, index) => (
			<Route
				key={index}
				exact={exact}
				path={baseURL + path}
				component={component}
			/>
		))}
	</>
);

export default RouteContainer;
