import React from "react";
import "../../AccountStatement.scss";
import CautionIcon from "assets/img/caution_circle_icon.svg";
import Modal from "components/utils/Modal/Modal";
import ModalHeader from "components/utils/Modal/CustomModalHeader/CustomModalHeader";
import { ReactSVG } from "react-svg";

export default function DisclaimerModal({ show, onClose, goToPrevStep }) {
	return (
		<Modal show={show}>
			<ModalHeader
				heading="Disclaimer"
				className="p-0"
				headerClassName="setup-modals-header px-sm-5 px-3 py-3"
				dismissable={goToPrevStep}
			/>

			<div className="ast-disclaimer">
				<ReactSVG src={CautionIcon} />
				<h2>Disclaimer</h2>
				<p>
					You can request for a signed bank statement for up to 6
					months for your Visa Application which comes with a signed
					cover letter. You will be charged <span>500 naira</span> for
					this service.
				</p>
				<div className="d-flex justify-content-between ast-disclaimer__footer">
					<button
						className="btn btn-mint-default"
						onClick={() => {
							onClose();
							goToPrevStep();
						}}
					>
						No
					</button>
					<button
						className="btn btn-custom-bordered"
						onClick={onClose}
					>
						Continue
					</button>
				</div>
			</div>
		</Modal>
	);
}
