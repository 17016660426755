import React, { useState } from "react";
import { useSelector } from "react-redux";
import { API } from "../../../../../../lib/api";
import { fundInvestment } from "../../../../../../lib/api/apiRoutesConfig/services/SavingsMS/investments/subRoutes";
import { getErrorMessage, parseAmount } from "../../../../../utils/helpers";
import ModalHeader from "../../../../../utils/Modal/CustomModalHeader/CustomModalHeader";
import InvestmentPreviewModal from "../InvestmentPreviewModal/InvestmentPreviewModal";
import CustomCurrencyInput from "components/utils/CustomCurrencyInput/CustomCurrencyInput";

const TopUpInvestmentModal = ({ showModal, investment, toggleModal }) => {
	const [amount, setAmount] = useState("");
	const [loading, setLoading] = useState();
	const [errorMsg, setErrorMsg] = useState();
	const [successData, setSuccessData] = useState();
	const [showFirst, setShowFirst] = useState(true);

	const {
		dashboardResponse: { bankAccounts },
	} = useSelector(({ dashboard }) => dashboard);

	const { accountId } = bankAccounts?.length ? bankAccounts[0] : {};

	const TopUpInvestment = async (transactionPin) => {
		try {
			setErrorMsg();
			setLoading(true);

			const payload = {
				debitAccountId: accountId,
				investmentCode: investment?.code,
				amount,
				transactionPin,
			};

			const res = await API.post(fundInvestment, payload);

			if (res?.status === 200) {
				setLoading(false);
				setSuccessData({
					message: res.data?.message,
					data: { ...investment, ...res.data?.data?.investment },
				});
			} else setError(res.data?.message);
		} catch (err) {
			setError(getErrorMessage(err));
		}
	};

	const setError = (msg) => {
		setLoading(false);
		setErrorMsg(msg);
	};

	const toggle = () => {
		setErrorMsg();
		setShowFirst(true);
		toggleModal();
		setAmount("");
		setSuccessData();
	};

	return (
		<InvestmentPreviewModal
			showFirst={showFirst}
			showPinModal={showModal}
			successData={successData}
			errorMsg={errorMsg}
			loading={loading}
			toggleModal={toggle}
			handleSubmit={TopUpInvestment}
			clearErrorMsg={() => setErrorMsg()}
		>
			<div className="low-form pt-0">
				<ModalHeader
					heading="Top Up Investment"
					dismissable={toggle}
					headerClassName="rel-button"
				/>

				<div style={{ padding: "0rem 1rem" }}>
					<CustomCurrencyInput
						label="Amount to Top"
						value={amount}
						name="amount"
						id="amount"
						onChange={(value) => setAmount(parseAmount(value))}
					/>

					<button
						className="btn create-acc mb-4 mt-4"
						disabled={!amount}
						onClick={() => setShowFirst(false)}
					>
						Continue
					</button>
				</div>
			</div>
		</InvestmentPreviewModal>
	);
};

export default TopUpInvestmentModal;
