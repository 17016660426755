import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import "../CreateSavingsGoal.scss";
import MintynLogo from "assets/img/mint_extra_bordered.svg";
import { formatCurrency, getErrorMessage } from "components/utils/helpers";
import { addDays, format as formatDate } from "date-fns";
import { ERROR, FINISHED, IDLE, LOADING } from "config/enums";
import { useSelector } from "react-redux";
import { SmallLoader } from "components/utils/SmallLoader";
import CreateSavingsGoalModal from "../../modals/CreateSavingsGoalModal";
import { API } from "lib/api";
import { savingsGoalsV2 } from "lib/api/apiRoutesConfig/services/SavingsMS/savingsGoals";
import { ToastsStore } from "react-toasts";
import FundMintAccountModal from "components/dashboard/components/Landing/modals/FundMintAccount/FundMintAccount";

export default function SummaryStep({ payload }) {
	const {
		name,
		fundingAmount,
		targetAmount,
		interestRate,
		durationInDays,
		startDate,
	} = payload;

	const { dashboardResponse } = useSelector((state) => ({
		dashboardResponse: state.dashboard.dashboardResponse,
	}));

	const { accountId: debitAccountId } = dashboardResponse?.bankAccounts
		? dashboardResponse.bankAccounts[0]
		: {};

	const [isAuthorized, setIsAuthorized] = useState(false);
	const [isAcknowledged, setIsAcknowleged] = useState(false);
	const [currentState, setCurrentState] = useState(IDLE);
	const [errorMsg, setErrorMsg] = useState("");
	const [successMsg, setSuccessMsg] = useState("");
	const [showModal, setShowModal] = useState(false);
	const [showFundingModal, setShowFundingModal] = useState(false);
	const [goalResponse, setGoalResponse] = useState(null);

	const handleCreateSavingsGoal = async () => {
		setCurrentState(LOADING);
		setErrorMsg("");
		try {
			const res = await API.post(savingsGoalsV2, {
				...payload,
				debitAccountId,
				startDate: startDate
					? formatDate(startDate, "dd/MM/yyyy")
					: startDate,
				interestRate: undefined, // not needed for submission
			});
			const { status, data } = res || {};
			if (status === 200) {
				setSuccessMsg(data.message);
				setGoalResponse(data.data);
				setCurrentState(FINISHED);
				setShowModal(true);
			}
		} catch (err) {
			setCurrentState(ERROR);
			setErrorMsg(getErrorMessage(err));
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
			if (
				getErrorMessage(err).includes(
					"You have insufficient balance for fund your savings goal."
				)
			) {
				setShowModal(true);
			}
		}
	};

	return (
		<>
			<div className="create-savings-goal-page__step shrink">
				<h2>Savings Goal Summary</h2>
				<p>Summary of your Savings Goal</p>

				<div className="create-savings-goal-page__summary">
					<div className="d-flex align-items-center justify-content-between">
						<ReactSVG src={MintynLogo} />
						<h3>Not started</h3>
					</div>

					<div className="cesp-summary-info">
						<div>
							<h4>Savings Goal Name</h4>
							<h5>{name}</h5>
						</div>

						<div></div>

						<div>
							<h4>Fund with</h4>
							<h5>{formatCurrency(fundingAmount)}</h5>
						</div>

						<div>
							<h4>Target</h4>
							<h5>{formatCurrency(targetAmount)}</h5>
						</div>

						<div>
							<h4>Saving Tier</h4>
							<h5>DEFAULT</h5>
						</div>

						<div>
							<h4>Interest rate</h4>
							<h5>{interestRate}%</h5>
						</div>

						<div>
							<h4>Maturity period</h4>
							<h5>{durationInDays} days</h5>
						</div>

						<div>
							<h4>Matures By</h4>
							<h5>
								{formatDate(
									addDays(new Date(), durationInDays),
									"MMM dd, yyyy"
								)}
							</h5>
						</div>
					</div>

					<div className="mb-4 d-flex custom-control custom-checkbox d-flex align-items-center">
						<input
							type="checkbox"
							className="custom-control-input"
							id="isAuthorized"
							name="isAuthorized"
							onChange={() => {
								setIsAuthorized(!isAuthorized);
							}}
							checked={isAuthorized}
						/>
						<label
							className="custom-control-label"
							htmlFor="isAuthorized"
						>
							I hereby authorize Mintyn to fund this goal with{" "}
							{formatCurrency(fundingAmount)} and make it
							available for withdrawal
						</label>
					</div>

					<div className="mb-4 d-flex custom-control custom-checkbox d-flex align-items-center">
						<input
							type="checkbox"
							className="custom-control-input"
							id="isAcknowledged"
							name="isAcknowledged"
							onChange={() => {
								setIsAcknowleged(!isAcknowledged);
							}}
							checked={isAcknowledged}
						/>
						<label
							className="custom-control-label"
							htmlFor="isAcknowledged"
						>
							I hereby acknowledge that this Savings Goal will be
							locked for {durationInDays} days before possible
							withdrawal.
						</label>
					</div>

					<button
						className="btn create-acc mt-3 text-capitalize"
						disabled={
							currentState === LOADING ||
							!isAuthorized ||
							!isAcknowledged
						}
						style={{ borderRadius: "15px", height: "50px" }}
						onClick={handleCreateSavingsGoal}
					>
						{currentState === LOADING ? (
							<SmallLoader />
						) : (
							"Fund Goal"
						)}
					</button>
				</div>
			</div>

			<CreateSavingsGoalModal
				show={showModal}
				currentState={currentState}
				errorMsg={errorMsg}
				successMsg={successMsg}
				onClose={() => setShowModal(false)}
				setCurrentState={(state) => setCurrentState(state)}
				setErrorMsg={(message) => setErrorMsg(message)}
				openFundingModal={() => setShowFundingModal(true)}
				goalResponse={goalResponse}
			/>

			<FundMintAccountModal
				show={showFundingModal}
				dismissModal={() => setShowFundingModal(false)}
			/>
		</>
	);
}
