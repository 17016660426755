import React, { useState } from "react";
import "./DeliveryAddressPanel.scss";
import PlusIcon from "assets/img/plus-icon-white.svg";
import { ReactSVG } from "react-svg";
import CustomRadioButton from "components/utils/CustomRadioButton/CustomRadioButton";
// import EditIcon from "assets/img/edit-icon.svg";
import TrashIcon from "assets/img/trash-icon.svg";
import { ToastsStore } from "react-toasts";
import { getErrorMessage } from "components/utils/helpers";
import { API } from "lib/api";
import { editDeliveryAddress } from "lib/api/apiRoutesConfig/services/tradeMS/individualTrade/subRoutes";
import AddNewAddress from "./AddNewAddress";

export default function DeliveryAddressPanel({
	data = [],
	refetch,
}) {
	const [showAddressModal, setShowAddressModal] = useState(false);

	const setDefaultAddress = async (addressId) => {
		try {
			const response = await API.put(editDeliveryAddress(addressId));
			const { status, data } = response || {};
			if (status === 200) {
				ToastsStore.success(data.message, 6000, "right-toast");
				refetch();
			}
		} catch (err) {
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		}
	};

	const deleteAddress = async (addressId) => {
		try {
			const response = await API.delete(editDeliveryAddress(addressId));
			const { status, data } = response || {};
			if (status === 200) {
				ToastsStore.success(data.message, 6000, "right-toast");
				refetch();
			}
		} catch (err) {
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		}
	};

	return (
		<>
			<div className="mkt-delivery-panel">
				<div className="mkt-delivery-panel__header">
					<h2>Delivery Address</h2>
				</div>

				<div className="mkt-delivery-panel__content">
					{data.length > 0 ? (
						<>
							{data.map((address, index) => (
								<AddressItem
									key={index}
									data={address}
									onClick={(id) => setDefaultAddress(id)}
									onDelete={(id) => deleteAddress(id)}
								/>
							))}
						</>
					) : (
						<div className="mkt-empty-delivery">
							No delivery address added yet
						</div>
					)}

					<button
						className="btn btn-mint-default new-address-btn"
						onClick={() => setShowAddressModal(true)}
					>
						<ReactSVG src={PlusIcon} />
						Add new Address
					</button>
				</div>
			</div>

			<AddNewAddress
				show={showAddressModal}
				onClose={() => setShowAddressModal(false)}
				refetch={refetch}
			/>
		</>
	);
}

const AddressItem = ({ data, onClick, onDelete, }) => {
	const { areaName, address, contactPhoneNumber, defaultAddress, id } =
		data || {};

	return (
		<div
			className={`mkt-address-item ${defaultAddress ? "selected" : ""}`}
		>
			<CustomRadioButton
				checked={defaultAddress}
				vallue={areaName + id}
				name={areaName + id}
				onChange={() => onClick(id)}
			/>

			<div className="mkt-address-item__content">
				<div>
					{areaName && <h4>{areaName}{defaultAddress}</h4>}
					{contactPhoneNumber && <p>{contactPhoneNumber}</p>}
					<p>{address}</p>
				</div>
				<div>
					{/* <button
						onClick={(e) => {
							e.stopPropagation();
						}}
					>
						Edit <ReactSVG src={EditIcon} />
					</button> */}
					<button
						onClick={(e) => {
							e.stopPropagation();
							onDelete(id);
						}}
					>
						<ReactSVG src={TrashIcon} />
					</button>
				</div>
			</div>
		</div>
	);
};
