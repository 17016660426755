import React from "react";
import TableRow from "../CustomTableRow/CustomTableRow";

const TableBody = ({ bodyData = [] }) => {
    return (
        <tbody>
            {bodyData &&
                bodyData.map(({data, ...otherProps}, i) => <TableRow key={i} data={data} {...otherProps} />)}
        </tbody>
    );
};

export default TableBody;
