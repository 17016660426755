import React from "react";
import IncorporatedTrusteeDetails from "./incorporatedTrusteeDetails";
import BusinessDocuments from "./businessDocuments";
import TrusteesDetails from "./trusteesDetails";
import { Range } from "../utils/range";
import TrusteeId from "./trusteeId";
import SecretaryDetails from "./secretaryDetails";

const tabs = [
	"Incorporated Trustee details",
	"Trustee documents",
	"Trustees",
	"Company Secretary",
];

export const TrusteeDetailsForm1 = ({ setSteps, regInfo, refresh }) => {
	return (
		<section className="form-container">
			<div>
				<div className="bread-crumb">
					{tabs.map((tab, index, array) => (
						<React.Fragment key={index}>
							{index === 0 ? (
								<span className="active-text">{tab}</span>
							) : (
								<span>{tab}</span>
							)}
							{index !== array.length - 1 && (
								<svg
									width="17"
									height="16"
									viewBox="0 0 17 16"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M5.96935 12.4701C5.82845 12.6109 5.7493 12.802 5.7493 13.0013C5.7493 13.2006 5.82845 13.3917 5.96935 13.5326C6.11025 13.6734 6.30134 13.7526 6.5006 13.7526C6.69986 13.7526 6.89096 13.6734 7.03185 13.5326L12.0319 8.53255C12.1018 8.46287 12.1572 8.38008 12.1951 8.28892C12.233 8.19775 12.2524 8.10001 12.2524 8.0013C12.2524 7.90259 12.233 7.80485 12.1951 7.71369C12.1572 7.62252 12.1018 7.53973 12.0319 7.47005L7.03185 2.47005C6.89095 2.32916 6.69986 2.25 6.5006 2.25C6.30134 2.25 6.11025 2.32916 5.96935 2.47005C5.82845 2.61095 5.7493 2.80204 5.7493 3.0013C5.7493 3.20056 5.82845 3.39166 5.96935 3.53255L10.4375 8.00068L5.96935 12.4701Z"
										fill={`${
											index === 0 ? "#d0a23c" : "#808080"
										}`}
									/>
								</svg>
							)}
						</React.Fragment>
					))}
				</div>
			</div>
			<div className="form-content">
				<h4>Trustee details</h4>
				<p>
					Fill out the form below to process your business
					registration with the CAC.
				</p>
			</div>
			<section className="forms">
				<IncorporatedTrusteeDetails
					setSteps={setSteps}
					refresh={refresh}
					regInfo={regInfo}
				/>
			</section>
		</section>
	);
};

export const TrusteeBussinessDocumentForm2 = ({
	setSteps,
	regInfo,
	refresh,
}) => {
	return (
		<section className="form-container">
			<div>
				<div className="bread-crumb">
					{tabs.map((tab, index, array) => (
						<React.Fragment key={index}>
							{index < 2 ? (
								<span className="active-text">{tab}</span>
							) : (
								<span>{tab}</span>
							)}
							{index !== array.length - 1 && (
								<svg
									width="17"
									height="16"
									viewBox="0 0 17 16"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M5.96935 12.4701C5.82845 12.6109 5.7493 12.802 5.7493 13.0013C5.7493 13.2006 5.82845 13.3917 5.96935 13.5326C6.11025 13.6734 6.30134 13.7526 6.5006 13.7526C6.69986 13.7526 6.89096 13.6734 7.03185 13.5326L12.0319 8.53255C12.1018 8.46287 12.1572 8.38008 12.1951 8.28892C12.233 8.19775 12.2524 8.10001 12.2524 8.0013C12.2524 7.90259 12.233 7.80485 12.1951 7.71369C12.1572 7.62252 12.1018 7.53973 12.0319 7.47005L7.03185 2.47005C6.89095 2.32916 6.69986 2.25 6.5006 2.25C6.30134 2.25 6.11025 2.32916 5.96935 2.47005C5.82845 2.61095 5.7493 2.80204 5.7493 3.0013C5.7493 3.20056 5.82845 3.39166 5.96935 3.53255L10.4375 8.00068L5.96935 12.4701Z"
										fill={`${
											index < 2 ? "#d0a23c" : "#808080"
										}`}
									/>
								</svg>
							)}
						</React.Fragment>
					))}
				</div>
			</div>
			<div className="form-content">
				<>
					<h4>Trustee Documents</h4>
					<p>
						Fill out your Trustees’ Details for us to process your
						CAC registration.
					</p>
				</>
			</div>

			<section className="forms">
				<BusinessDocuments
					setSteps={setSteps}
					refresh={refresh}
					regInfo={regInfo}
				/>
			</section>
		</section>
	);
};

export const TrusteeDetailsForm3 = ({ setSteps, regInfo, refresh }) => {
	return (
		<section className="form-container">
			<div>
				<div className="bread-crumb">
					{tabs.map((tab, index, array) => (
						<React.Fragment key={index}>
							{index < 2 ? (
								<span className="active-text">{tab}</span>
							) : (
								<span>{tab}</span>
							)}
							{index !== array.length - 1 && (
								<svg
									width="17"
									height="16"
									viewBox="0 0 17 16"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M5.96935 12.4701C5.82845 12.6109 5.7493 12.802 5.7493 13.0013C5.7493 13.2006 5.82845 13.3917 5.96935 13.5326C6.11025 13.6734 6.30134 13.7526 6.5006 13.7526C6.69986 13.7526 6.89096 13.6734 7.03185 13.5326L12.0319 8.53255C12.1018 8.46287 12.1572 8.38008 12.1951 8.28892C12.233 8.19775 12.2524 8.10001 12.2524 8.0013C12.2524 7.90259 12.233 7.80485 12.1951 7.71369C12.1572 7.62252 12.1018 7.53973 12.0319 7.47005L7.03185 2.47005C6.89095 2.32916 6.69986 2.25 6.5006 2.25C6.30134 2.25 6.11025 2.32916 5.96935 2.47005C5.82845 2.61095 5.7493 2.80204 5.7493 3.0013C5.7493 3.20056 5.82845 3.39166 5.96935 3.53255L10.4375 8.00068L5.96935 12.4701Z"
										fill={`${
											index < 2 ? "#d0a23c" : "#808080"
										}`}
									/>
								</svg>
							)}
						</React.Fragment>
					))}
				</div>
			</div>
			<div className="form-content">
				<>
					{regInfo.totalTrustees - 1 <= 2 && (
						<p className=" m-1">STEP 1 of 2 </p>
					)}
					<h4>
						{regInfo.trusteesAdded === 0
							? "Chairman Board of Trustees"
							: "Trustee's Details"}
					</h4>
					<p>
						Fill out your Trustees details for us to process your
						CAC registration.
					</p>
					{regInfo.trusteesAdded > 0 &&
						regInfo.totalTrustees - 1 > 1 && (
							<>
								<p className=" m-1">
									Details{" "}
									{regInfo.trusteesAdded === 0
										? "1"
										: regInfo.trusteesAdded}{" "}
									of {regInfo.totalTrustees - 1}
								</p>
								<Range
									added={regInfo.trusteesAdded}
									total={regInfo.totalTrustees - 1}
								/>
							</>
						)}
				</>
			</div>

			<section className="forms">
				<TrusteesDetails
					setSteps={setSteps}
					regInfo={regInfo}
					refresh={refresh}
				/>
			</section>
		</section>
	);
};

export const TrusteeIdForm4 = ({ setSteps, regInfo, refresh }) => {
	return (
		<section className="form-container">
			<div>
				<div className="bread-crumb">
					{tabs.map((tab, index, array) => (
						<React.Fragment key={index}>
							{index < 3 ? (
								<span className="active-text">{tab}</span>
							) : (
								<span>{tab}</span>
							)}
							{index !== array.length - 1 && (
								<svg
									width="17"
									height="16"
									viewBox="0 0 17 16"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M5.96935 12.4701C5.82845 12.6109 5.7493 12.802 5.7493 13.0013C5.7493 13.2006 5.82845 13.3917 5.96935 13.5326C6.11025 13.6734 6.30134 13.7526 6.5006 13.7526C6.69986 13.7526 6.89096 13.6734 7.03185 13.5326L12.0319 8.53255C12.1018 8.46287 12.1572 8.38008 12.1951 8.28892C12.233 8.19775 12.2524 8.10001 12.2524 8.0013C12.2524 7.90259 12.233 7.80485 12.1951 7.71369C12.1572 7.62252 12.1018 7.53973 12.0319 7.47005L7.03185 2.47005C6.89095 2.32916 6.69986 2.25 6.5006 2.25C6.30134 2.25 6.11025 2.32916 5.96935 2.47005C5.82845 2.61095 5.7493 2.80204 5.7493 3.0013C5.7493 3.20056 5.82845 3.39166 5.96935 3.53255L10.4375 8.00068L5.96935 12.4701Z"
										fill={`${
											index < 3 ? "#d0a23c" : "#808080"
										}`}
									/>
								</svg>
							)}
						</React.Fragment>
					))}
				</div>
			</div>
			<div className="form-content">
				<>
					{regInfo.totalTrustees - 1 <= 2 && (
						<p className=" m-1">STEP 2 of 2</p>
					)}
					<h4>
						{regInfo.trusteesAdded === 1
							? "Chairman’s Identification"
							: "Trustees Identification"}
					</h4>
					<p>
						{regInfo.trusteesAdded === 1
							? "Fill out the Chairman’s NIN details to continue"
							: "Fill out the Trustees NIN details to continue"}
					</p>
					{regInfo.trusteesAdded > 1 &&
						regInfo.totalTrustees - 1 > 1 && (
							<>
								<p className=" m-1">
									Details{" "}
									{regInfo.trusteesAdded === 0
										? "1"
										: regInfo.trusteesAdded - 1}{" "}
									of {regInfo.totalTrustees - 1}
								</p>
								<Range
									added={regInfo.trusteesAdded - 1}
									total={regInfo.totalTrustees - 1}
								/>
							</>
						)}
				</>
			</div>

			<section className="forms">
				<TrusteeId
					setSteps={setSteps}
					regInfo={regInfo}
					refresh={refresh}
				/>
			</section>
		</section>
	);
};

export const SecretaryDetailsForm5 = ({ setSteps, regInfo, refresh }) => {
	return (
		<section className="form-container">
			<div>
				<div className="bread-crumb">
					{tabs.map((tab, index, array) => (
						<React.Fragment key={index}>
							{index < 3 ? (
								<span className="active-text">{tab}</span>
							) : (
								<span>{tab}</span>
							)}
							{index !== array.length - 1 && (
								<svg
									width="17"
									height="16"
									viewBox="0 0 17 16"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M5.96935 12.4701C5.82845 12.6109 5.7493 12.802 5.7493 13.0013C5.7493 13.2006 5.82845 13.3917 5.96935 13.5326C6.11025 13.6734 6.30134 13.7526 6.5006 13.7526C6.69986 13.7526 6.89096 13.6734 7.03185 13.5326L12.0319 8.53255C12.1018 8.46287 12.1572 8.38008 12.1951 8.28892C12.233 8.19775 12.2524 8.10001 12.2524 8.0013C12.2524 7.90259 12.233 7.80485 12.1951 7.71369C12.1572 7.62252 12.1018 7.53973 12.0319 7.47005L7.03185 2.47005C6.89095 2.32916 6.69986 2.25 6.5006 2.25C6.30134 2.25 6.11025 2.32916 5.96935 2.47005C5.82845 2.61095 5.7493 2.80204 5.7493 3.0013C5.7493 3.20056 5.82845 3.39166 5.96935 3.53255L10.4375 8.00068L5.96935 12.4701Z"
										fill={`${
											index < 3 ? "#d0a23c" : "#808080"
										}`}
									/>
								</svg>
							)}
						</React.Fragment>
					))}
				</div>
			</div>
			<div className="form-content">
				<p className=" m-1">Step 1 of 2</p>
				<h4>Company’s secretary details</h4>
				<p>
					Fill out your Sectary’s Details for us to process your CAC
					registration.
				</p>
			</div>

			<section className="forms">
				<SecretaryDetails
					setSteps={setSteps}
					regInfo={regInfo}
					refresh={refresh}
				/>
			</section>
		</section>
	);
};

export const CompanySecretaryDetailsForm = ({ setSteps, regInfo, refresh }) => {
	return (
		<section className="form-container">
			<div>
				<div className="bread-crumb">
					{tabs.map((tab, index, array) => (
						<React.Fragment key={index}>
							{index < 4 ? (
								<span className="active-text">{tab}</span>
							) : (
								<span>{tab}</span>
							)}
							{index !== array.length - 1 && (
								<svg
									width="17"
									height="16"
									viewBox="0 0 17 16"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M5.96935 12.4701C5.82845 12.6109 5.7493 12.802 5.7493 13.0013C5.7493 13.2006 5.82845 13.3917 5.96935 13.5326C6.11025 13.6734 6.30134 13.7526 6.5006 13.7526C6.69986 13.7526 6.89096 13.6734 7.03185 13.5326L12.0319 8.53255C12.1018 8.46287 12.1572 8.38008 12.1951 8.28892C12.233 8.19775 12.2524 8.10001 12.2524 8.0013C12.2524 7.90259 12.233 7.80485 12.1951 7.71369C12.1572 7.62252 12.1018 7.53973 12.0319 7.47005L7.03185 2.47005C6.89095 2.32916 6.69986 2.25 6.5006 2.25C6.30134 2.25 6.11025 2.32916 5.96935 2.47005C5.82845 2.61095 5.7493 2.80204 5.7493 3.0013C5.7493 3.20056 5.82845 3.39166 5.96935 3.53255L10.4375 8.00068L5.96935 12.4701Z"
										fill={`${
											index < 4 ? "#d0a23c" : "#808080"
										}`}
									/>
								</svg>
							)}
						</React.Fragment>
					))}
				</div>
			</div>
			<div className="form-content">
				<>
					<p className=" m-1">Step 1 of 2</p>
					<h4>Company’s secretary's details</h4>
					<p>
						Fill out your Sectary’s Details for us to process your
						CAC registration.
					</p>
				</>
			</div>

			<section className="forms">
				<SecretaryDetails
					setSteps={setSteps}
					regInfo={regInfo}
					refresh={refresh}
				/>
			</section>
		</section>
	);
};
