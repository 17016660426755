import React from "react";
import MintDarkLogo from "../../assets/img/mintwhitelogo.svg";
import MintLightLogo from "assets/img/mintyn_light_logo.svg";
import { ReactSVG } from "react-svg";
import { useSelector } from "react-redux";

export default function Logo() {
	const { theme } = useSelector((state) => state.style);

	return (
		<div className="mintyn-logo-container">
			<ReactSVG
				src={theme === "midnight" ? MintDarkLogo : MintLightLogo}
				className="h-100"
			/>
		</div>
	);
}
