import React from "react";
import ShineLoader from "../../../../../utils/ShineLoader/ShineLoader";

const PSSkeleton = () => (
    <>
        {Array.from(Array(3)).map((_, i) => (
            <div className='card' key={i}>
                <div className='card-header' id='headingTwo'>
                    <div className='mb-0'>
                        <div className='row'>
                            <div className='col-md-9'>
                                <ShineLoader width={`${20 * (i + 1)}%`} height='15px' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ))}
    </>
);

export default PSSkeleton;
