import React, { Component } from "react";
import AccountTypeSelect from "../../../FundsTransfer/AccountTypeSelect/AccountTypeSelect";
import BankInputSelect from "../../../FundsTransfer/BankInputSelect/BankInputSelect";
import FadeIn from "../../../effects/FadeIn";
import config from "../../../../../../config";
import { SAMEBANK, OTHER_BANKS } from "../../../../../../config/enums";

class CreateBeneficiaryForm extends Component {
	state = {
		accountType: SAMEBANK,
		selectedBank: {},
		request: {
			accountNumber: "",
			bankCode: config.MINT_BANK_CODE,
			bankName: "Mintyn Finex",
		},
	};

	handleAccountTypeChange = (e) => {
		const { name, value } = e.target;
		this.setState((prevState) => ({
			[name]: value,
			request: {
				...prevState.request,
				bankCode: value === SAMEBANK ? config.MINT_BANK_CODE : "",
				bankName: value === SAMEBANK ? "Mintyn Finex" : "",
			},
		}));
	};

	handleBankSelect = (selected, { name }) => {
		const { value, label } = selected;
		this.setState((prevState) => ({
			...prevState,
			request: {
				...prevState.request,
				[name]: value,
				bankName: label,
			},
			selectedBank: selected,
		}));
	};

	handleInputChange = (e) => {
		let { name, value } = e.target;
		value = value.replace(/[^0-9]/g, "");
		this.setState((prevState) => ({
			request: {
				...prevState.request,
				[name]: value,
			},
		}));
	};

	handleSubmit = (e) => {
		e.preventDefault();
		const { request } = this.state;
		this.props.performNameEnquiry(request);
	};

	canBeSubmitted = () => {
		const { accountNumber, bankCode } = this.state.request;
		return accountNumber.length < 9 || bankCode === "";
	};

	render() {
		const { toggleModal } = this.props;
		const { accountType, selectedBank } = this.state;
		const isDisabled = this.canBeSubmitted();

		return (
			<div className="px-4 py-1">
				<form onSubmit={this.handleSubmit}>
					<div className="low-form">
						<div className="form-group">
							<label>Beneficiary</label>
							<input
								name="accountNumber"
								onChange={this.handleInputChange}
								maxLength={10}
								className="form-control"
								placeholder={"Type Account Number"}
								autoComplete="off"
							/>
						</div>
						<div className="form-group">
							<label>Account type</label>
							<div className="mt-1">
								<AccountTypeSelect
									selectedRadio={accountType}
									handleRadio={this.handleAccountTypeChange}
								/>
							</div>
						</div>
						{accountType === OTHER_BANKS && (
							<FadeIn>
								<BankInputSelect
									inputName="bankCode"
									handleSelect={this.handleBankSelect}
									selectedBank={selectedBank}
								/>
							</FadeIn>
						)}
					</div>

					<div className="dialog-footer mt-3 mb-4">
						<div className="row">
							<div className="col-6">
								<button
									className="btn cancel"
									data-dismiss="modal"
									type="button"
									aria-label="Close"
									onClick={toggleModal}
								>
									CANCEL
								</button>
							</div>

							<div className="col-6">
								<button
									className="btn create-acc"
									type="submit"
									disabled={isDisabled}
								>
									Proceed
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		);
	}
}

export default CreateBeneficiaryForm;
