import PropTypes from "prop-types";
import React, { Component } from "react";
import AsyncSelect from "react-select/async";
import { SelectBoxV2 } from "../../styles/SelectBoxV2";

class DynamicSelectV2 extends Component {
	handleRetry = (e) => {
		e.preventDefault();
		this.props.loadOptions();
	};

	render() {
		const {
			name,
			onChange,
			isSearchable,
			loadOptions,
			isLoading,
			error,
			placeholder,
			serverError,
			label,
		} = this.props;
		return (
			<div className="select-field-container">
				<label>{label}</label>
				<AsyncSelect
					name={name}
					onChange={onChange}
					isSearchable={isSearchable}
					loadOptions={loadOptions}
					classNamePrefix="react-select"
					styles={SelectBoxV2(error)}
					isLoading={isLoading}
					// maxMenuHeight={"50px"}
					placeholder={placeholder}
					cacheOptions
					defaultOptions
					menuPortalTarget={document.body}
					{...this.props}
				/>

				{serverError && (
					<div>
						<span className="error-msg">{serverError}</span>
						{` `}
						<a
							href="#! "
							onClick={this.handleRetry}
							className="retry__button"
						>
							retry?
						</a>
					</div>
				)}
			</div>
		);
	}
}

DynamicSelectV2.defaultProps = {
	placeholder: "",
	isSearchable: false,
};

DynamicSelectV2.propTypes = {
	error: PropTypes.any,
	onChange: PropTypes.func.isRequired,
	isSearchable: PropTypes.bool,
	name: PropTypes.string,
	placeholder: PropTypes.string,
};

export default DynamicSelectV2;
