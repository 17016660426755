
export const businessTypes = [
  {
    title: 'Business Name',
    content: 'This account is suitable for small business owners, sole proprietors, entrepreneurs, freelancers and merchants.',
    price: 'N 21,000.00',
    url: 'business-name',
    included:''
  },
  {
  title: 'Company',
  content: 'This account is suitable for Limited Liability companies, corporate organizations and Partnerships',
  price: 'N 75,000.00',
  url: 'company-name',
  included:'Includes Memorandum and Article of Association drafting by legal Counsel.'
  },
  {
    title: 'Incorporated trustee',
    content: 'This account is suitable for NGOs, schools and associations, research groups, sport clubs and academics.',
    price: 'N 100,000.00',
    url: 'incorporated-trustee',
    included:'Includes minutes of the meeting appointing the trustees and the impressions of the  common seal.'
  }
]

// Enums
export const BUSINESS_DETAILS = 'BUSINESS_DETAILS'
export const DIRECTOR_DETAILS = 'DIRECTOR_DETAILS'
export const NATIONAL_ID = 'NATIONAL_ID'

export const DIRECTOR_IDENTIFICATION_INFORMATION = 'DIRECTOR_IDENTIFICATION_INFORMATION'
export const TERMS_AND_CONDITION = "TERMS_AND_CONDITION"
export const PAYMENT = "PAYMENT"
export const COMPLETED = "COMPLETED"
export const SHARE_HOLDER_DETAILS = "SHARE_HOLDER_DETAILS"
export const SHARE_HOLDER_IDENTIFICATION_INFORMATION = "SHARE_HOLDER_IDENTIFICATION_INFORMATION"
export const SECRETARY_DETAILS = "SECRETARY_DETAILS"
export const SECRETARY_IDENTIFICATION_INFORMATION = "SECRETARY_IDENTIFICATION_INFORMATION"
export const BUSINESS_DOCUMENT = "BUSINESS_DOCUMENT"
export const TRUSTEE_DETAILS = "TRUSTEE_DETAILS"
export const TRUSTEE_IDENTIFICATION_INFORMATION = "TRUSTEE_IDENTIFICATION_INFORMATION"

// 4498000376
// 23459607986070