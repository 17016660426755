import moment from "moment";
import {
	ENTERPRISE,
	SOLE_PROPRIETORSHIP,
	INCORPORATED_TRUSTEE,
	SOLE_PROPRIETORSHIP_NO_CAC,
} from "../../config/enums";
import Cookies from "js-cookie";
import {
	MINTYN_INTERCOM_USER_HASH,
	MINTYN_USER_NEW_ACCOUNT,
	MINTYN_USER_REFRESH_TOKEN,
	MINTYN_USER_TOKEN,
	NCTO_CARD_ACTIVATION_PAYLOAD,
} from "components/constants";

export const isDev = process.env.NODE_ENV === "development";

export function separateNumWithCommas(n) {
	return String(n)
		.replace(/\D/g, "")
		.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatFloatInput(value) {
	let _value = value;

	if (_value === "") return "";

	const [firstDigit, secondDigit] = _value;

	if (firstDigit === ".") {
		_value = "0" + _value;
	}

	if (firstDigit === "0") {
		if (secondDigit === "0") {
			return _value.substring(1);
		}

		if (secondDigit?.match(/[0-9]/)) {
			_value = _value.substring(1);
		}
	}

	const decimalPos = _value.indexOf(".");

	if (decimalPos >= 0) {
		const int = separateNumWithCommas(_value.substring(0, decimalPos));

		const frac = _value
			.substring(decimalPos + 1)
			.replace(/[^0-9]/g, "")
			.substring(0, 2);

		_value = [int, ".", frac].join("");
	} else {
		_value = separateNumWithCommas(_value);
	}

	return _value;
}

export const insertSpaces = (string) => {
	string = string.replace(/([a-z])([A-Z])/g, "$1 $2");
	string = string.replace(/([A-Z])([A-Z][a-z])/g, "$1 $2");
	return string;
};

export const formatCurrency = (cash) => {
	const currency = "₦";
	const money = cash
		? Number(cash)
				.toFixed(2)
				.replace(/./g, (c, i, a) =>
					i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c
				)
		: "0.00";
	return `${currency}${money}`;
};

export const toCurrency = (n) => {
	return Number(n)
		.toFixed(2)
		.replace(/./g, function (c, i, a) {
			return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
		});
};

export const NumberCurrency = (amount) => {
	return Number(amount.replace(/[^0-9.-]+/g, ""));
};

export const toKobo = (naira) => {
	let kobo = naira * 100;
	return kobo;
};

export const stripKobo = (theKobo) => {
	theKobo = theKobo.toString();
	return parseInt(theKobo.replace(/[.,\s]/g, "").toString());
};

export const HumanDateFormat = (date) => {
	let d = new Date(date);
	let day = d.getDate();
	let month = d.toLocaleString("en-us", { month: "long" });
	let year = d.getFullYear();
	return day + " " + month + ", " + year;
};

export const getDateForDatepicker = (plug) => {
	if (plug === undefined || plug === "" || plug === null) {
		return "";
	} else {
		var date = new Date(plug);
		return (
			date.getDate() +
			"/" +
			(date.getMonth() + 1) +
			"/" +
			date.getFullYear()
		);
	}
};

export const computeDateForDatepicker = (plug) => {
	if (plug === undefined || plug === "" || plug === null) {
		return "";
	} else if (typeof plug === "string" && plug?.includes("/")) {
		return plug;
	} else {
		var date = new Date(plug);
		return (
			date.getDate() +
			"/" +
			(date.getMonth() + 1) +
			"/" +
			date.getFullYear()
		);
	}
};

export const formatDateToString = (date, separator = "/") => {
	if (date === "" || date === undefined || date === null) {
		return "";
	}

	let d = new Date(date),
		month = "" + (d.getMonth() + 1),
		day = "" + d.getDate(),
		year = d.getFullYear();

	if (month.length < 2) month = "0" + month;
	if (day.length < 2) day = "0" + day;

	return [day, month, year].join(separator);
};

export const formatDateToTimeString = (date) => {
	let formatedDate = formatDateToString(date);
	let hour = new Date(formatedDate).getHours();
	return [formatedDate, hour].join(" ");
};

export const MonthDay = (date) => {
	var months = new Array(12);
	months[0] = "Jan";
	months[1] = "Feb";
	months[2] = "Mar";
	months[3] = "Apr";
	months[4] = "May";
	months[5] = "Jun";
	months[6] = "Jul";
	months[7] = "Aug";
	months[8] = "Sep";
	months[9] = "Oct";
	months[10] = "Nov";
	months[11] = "Dec";

	var current_date = new Date(date);
	let month_value = current_date.getMonth();
	let day_value = current_date.getDate();
	return months[month_value] + ", " + day_value;
};
export const greetCustomer = () => {
	var hours = new Date().getHours();
	if (hours < 12) {
		return "Good Morning";
	} else if (hours >= 12 && hours <= 17) {
		return "Good Afternoon";
	} else if (hours > 17 && hours <= 24) {
		return "Good Evening";
	}
};

export const getDayTime = (date) => {
	let timeAMPM = moment(date).format("h:mm a"); // June 12th 2020, 5:27:00 pm
	return timeAMPM;
};

export const SmartTime = (date) => {
	let dayInwords = moment(date).format("MMMM Do YYYY, h:mm a"); // June 12th 2020, 5:27:00 pm
	return dayInwords;

	// let dayInwords = HumanDateFormat(date);
	// let strTime = getDayTime(date);
	// return `${dayInwords} ${strTime}`;
};

export const nth = (d) => {
	if (d > 3 && d < 21) return `${d}th`;
	switch (d % 10) {
		case 1:
			return `${d}st`;
		case 2:
			return `${d}nd`;
		case 3:
			return `${d}rd`;
		default:
			return `${d}th`;
	}
};

export const monthDateFormat = (date) => {
	let d = new Date(date);
	let day = d.getDate();
	let month = d.toLocaleString("en-us", { month: "long" });
	// let year = d.getFullYear();
	return nth(day) + " " + month;
};

export const monthYearDateFormat = (date, monthFormat = "long") => {
	let d = new Date(date);
	let day = d.getDate();
	let month = d.toLocaleString("en-us", { month: monthFormat });
	let year = d.getFullYear();
	return nth(day) + " " + month + " " + year;
};

export const monthDayYearFormat = (date) => {
	let d = new Date(date);
	let day = d.getDate();
	let month = d.toLocaleString("en-us", { month: "short" });
	let year = d.getFullYear();
	return month + " " + day + ", " + year;
};

export const extendDateWithMonth = (numOfMonths) => {
	if (!numOfMonths) return "";
	const date = new Date();
	date.setMonth(date.getMonth() + numOfMonths);
	return formatDateToString(date, "-");
};

export const asyncDebounce = (func, wait) => {
	let timer = null;
	return (...args) => {
		clearTimeout(timer);

		return new Promise(
			(resolve) =>
				(timer = setTimeout(() => resolve(func(...args)), wait))
		);
	};
};

export const getKeyValue = (keyCode) => {
	if (keyCode > 57) {
		//also check for numpad keys
		keyCode -= 48;
	}
	if (keyCode >= 48 && keyCode <= 57) {
		return String.fromCharCode(keyCode);
	}
};

export const formatNumber = (input) => {
	if (isNaN(parseFloat(input))) {
		return "0.00"; //if the input is invalid just set the value to 0.00
	}
	var num = parseFloat(input);
	return (num / 100).toFixed(2).replace(/./g, function (c, i, a) {
		return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
	});
	//move the decimal up to places return a X.00 format
};

export const TruncateParagraph = (input, maxLength = 33) =>
	input.length > maxLength ? `${input.substring(0, maxLength)}...` : input;

export const getKoboEquivalent = (input) => {
	let formattedInput = formatNumber(input);
	return stripKobo(formattedInput);
};

export const getErrorMessage = (errObj) => {
	const errResponse = errObj.response;
	const errorMessage =
		errResponse && errResponse.data
			? errResponse.data.message
			: "Something went wrong! Please try again";
	return errorMessage;
};

export const NumberDifferenceInDays = (d1, d2 = new Date()) => {
	d1 = new Date(d1);
	let t2 = d2.getTime();
	let t1 = d1.getTime();
	return Math.abs(parseInt((t2 - t1) / (24 * 3600 * 1000)));
};

export const DifferenceInDays = (d1, d2 = new Date()) => {
	const days = NumberDifferenceInDays(d1, d2);
	return `${days} ${days > 1 ? "days" : "day"}`;
};

export const SetAccountTypeCode = (activeAccountType) => {
	switch (activeAccountType) {
		case ENTERPRISE:
			return ["101"];
		case SOLE_PROPRIETORSHIP:
		case SOLE_PROPRIETORSHIP_NO_CAC:
			return ["102"];
		case INCORPORATED_TRUSTEE:
			return ["103"];
		default:
			return ["100"];
	}
};

export function formatEnum(enumValue) {
	if (enumValue == null) return "";
	return String(enumValue).replaceAll("_", " ").toLowerCase();
}

export const parseAmount = (amount) => {
	if (!amount || amount === "") return "";
	const parsedAmount = parseFloat(amount.replace(/,/g, ""));
	return parsedAmount;
};

export const getValidMobileNumberLength = (value = "") => {
	return value[0] === "+" ? 14 : 11;
};

export const cookieOptions = {
	sameSite: "strict",
	secure: true,
	// httpOnly: process.env.REACT_APP_STAGE === "dev" ? false : true,
};

export function removeCookieValues() {
	Cookies.remove(MINTYN_USER_TOKEN, cookieOptions);
	Cookies.remove(MINTYN_INTERCOM_USER_HASH, cookieOptions);
	Cookies.remove(MINTYN_USER_REFRESH_TOKEN, cookieOptions);
	Cookies.remove(MINTYN_USER_NEW_ACCOUNT, cookieOptions);
	Cookies.remove(NCTO_CARD_ACTIVATION_PAYLOAD, cookieOptions);
}
