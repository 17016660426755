import common from "../";

const waybill = `${common}/waybill`;

export const {
	validateWaybillLink,
	validateWaybillDriver,
	getWaybillOrderDetails,
	confirmWaybillOrder,
} = {
	validateWaybillLink: (token) => `${waybill}/validate-link/${token}`,
	validateWaybillDriver: `${waybill}/validate-driver`,
	getWaybillOrderDetails: `${waybill}/order-details`,
	confirmWaybillOrder: `${waybill}/confirm-order`,
};
