import PropTypes from "prop-types";
import React from "react";
import "./ShineLoader.css";

export const MintInfinite = ({ relative, className }) => (
	<div
		className={`d-flex w-100 h-100 align-items-center ${
			className ? className : ""
		}`}
		style={{ position: relative ? "relative" : "absolute" }}
	>
		<div id="mintInfinite">Mintyn</div>
	</div>
);

const ShineLoader = ({ width, height, customClass }) => {
	return (
		<div
			className={`shineLoader ${customClass ? customClass : ""}`}
			style={{ width, height }}
		></div>
	);
};

ShineLoader.default = {
	width: "20px",
	height: "10px",
};

ShineLoader.propTypes = {
	height: PropTypes.string,
	width: PropTypes.string,
	customClass: PropTypes.string,
};

export default ShineLoader;
