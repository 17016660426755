import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import CreateEmergencySaving from "../CreateEmergencySaving/CreateEmergencySaving";
import EmergencySavingDetails from "../EmergencySavingDetails/EmergencySavingDetails";

const defaultPath = "/savings/emergencies";

const routes = [
	{
		title: "Emergency Savings Goal Details",
		path: `${defaultPath}/details/:id`,
		component: EmergencySavingDetails,
	},
	{
		title: "Create New Emergency Savings",
		path: `${defaultPath}/create-emergency-saving`,
		component: CreateEmergencySaving,
	},
];

export default function EmergencySavingsRoutes() {
	return (
		<Switch>
			{routes.map((route, index) => (
				<Route
					key={index}
					exact
					path={route.path}
					component={route.component}
				/>
			))}
			<Redirect path="*" to={`${defaultPath}`} />
		</Switch>
	);
}
