import React from "react";
import { FileUploader } from "react-drag-drop-files";
import { ReactSVG } from "react-svg";
import "./CustomFileUploader.scss";
import UploadIcon from "../../../assets/img/upload-icon-new.svg";

export default function CustomFileUploader({
	fileTypes = ["PDF", "JPG", "PNG", "JPEG"],
	className,
	name,
	onChange,
	label,
	error,
	boxClass,
	fileName,
}) {
	return (
		<div className={`custom-file-uploader ${className ? className : ""}`}>
			{label && <label>{label}</label>}
			<FileUploader name={name} handleChange={onChange} types={fileTypes}>
				<div
					className={`custom-file-uploader-box ${
						boxClass ? boxClass : ""
					} ${error ? "custom-file-uploader-box-error" : ""}`}
				>
					<ReactSVG src={UploadIcon} />
					<p>
						Drag and drop files here or <span>Browse</span>
					</p>
					{fileName && (
						<span className="custom-file-uploader-file-name">
							{fileName}
						</span>
					)}
				</div>
			</FileUploader>
			{error && <span className="error-msg">{error}</span>}
		</div>
	);
}
