import React, { useState } from "react";
import { connect } from "react-redux";
import { LOADING, ERROR, FINISHED } from "../../../../../config/enums";
import Modal from "../../../../utils/Modal/Modal";
import { API } from "../../../../../lib/api";
import { confirmWaybillOrder } from "../../../../../lib/api/apiRoutesConfig/services/tradeMS/common/subRoutes/waybill";
import { getErrorMessage } from "../../../../utils/helpers";
import {
	goToStep,
	updateOrderDetails,
	updateWaybillStatus,
} from "../../../../../actions/waybillComfirmation";
import OperationStatus, {
	failureResponseStatus,
} from "../../../../dashboard/utils/OperationStatus/OperationStatus";
import BigLoader from "../../../../utils/BigLoader";
import ModalHeader from "../../../../utils/Modal/CustomModalHeader/CustomModalHeader";
import SuccessImage from "../../../../utils/SuccessImage";

const ConfirmOrderModal = ({
	toggle,
	token,
	waybillConfirmation,
	goToStep,
	updateOrderDetails,
	updateWaybillStatus,
}) => {
	const [currentState, setCurrentState] = useState();
	const [statusObj, setStatusObj] = useState({});
	const [successMsg, setSuccessMsg] = useState();

	const { order, waybill } = waybillConfirmation[token] || {};

	const confirmOrder = async () => {
		try {
			setCurrentState(LOADING);
			const { orderId, deliveryCode } = order || {};

			const res = await API.put(confirmWaybillOrder, {
				validationCode: waybill?.validationCode,
				deliveryCode,
			});

			if (res.status === 200) {
				setCurrentState(FINISHED);
				updateWaybillStatus({
					token,
					status: FINISHED,
					orderId,
				});
				setSuccessMsg(res.data?.message);
			}
		} catch (err) {
			setCurrentState(ERROR);
			setStatusObj({
				status: failureResponseStatus,
				statusMessage: getErrorMessage(err),
			});
		}
	};

	const backToWaybill = () => {
		goToStep({ token, step: 3 });
		updateOrderDetails({ token, order: {} });
	};

	const renderBasedOnState = () => {
		switch (currentState) {
			case LOADING:
				return (
					<div className="p-5">
						<BigLoader />;
					</div>
				);

			case ERROR:
				return (
					<OperationStatus
						statusObj={statusObj}
						handleTryAgain={setCurrentState}
					/>
				);

			case FINISHED:
				return (
					<div className="py-4">
						<SuccessImage />

						<div className="smile mt-3">
							<h6>Congratulations</h6>

							<p>{successMsg}</p>
						</div>
						<div className="row justify-content-center">
							<div className="col-md-5">
								<button
									className="btn create-acc mt-3 mb-3"
									onClick={backToWaybill}
								>
									Great, go home
								</button>
							</div>
						</div>
					</div>
				);

			default:
				return (
					<InitialState
						toggle={toggle}
						confirm={confirmOrder}
						order={order}
					/>
				);
		}
	};
	return (
		<Modal show={true}>
			<ModalHeader heading="Comfirm Order" />
			<>{renderBasedOnState()}</>
		</Modal>
	);
};

const InitialState = ({ toggle, confirm, order }) => {
	const { customerName, orderItems } = order || {};

	return (
		<div className="p-5">
			<p style={{ fontSize: "16px" }} className="all-fet py-4">
				Tap below to confirm this order of{" "}
				<strong>{orderItems?.length} Items</strong> by{" "}
				<strong>{customerName}</strong>
			</p>

			<div className="my-4 d-flex">
				<button
					className="btn btn-custom-bordered mr-2"
					onClick={toggle}
				>
					Cancel
				</button>
				<button className="btn create-acc ml-2" onClick={confirm}>
					Yes Confirm
				</button>
			</div>
		</div>
	);
};

export default connect(({ waybillConfirmation }) => ({ waybillConfirmation }), {
	goToStep,
	updateOrderDetails,
	updateWaybillStatus,
})(ConfirmOrderModal);
