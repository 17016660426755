import React, { Component } from "react";
import Helmet from "react-helmet";
import PageNavigator from "../../../utils/PageNavigator/PageNavigator";
import GroomPanel from "../../../utils/GroomPanel/GroomPanel";
import BillsManagerListWrapper from "./BillsManagerListWrapper/BillsManagerListWrapper";
import "./BillsManager.scss";
import { connect } from "react-redux";
import { INDIVIDUAL } from "config/enums";
import UserRoleWarning from "components/utils/UserRoleWarning/UserRoleWarning";

class BillsManager extends Component {
	render() {
		const { loginResponse, activeAccountType } = this.props;

		const userAccountType =
			activeAccountType === INDIVIDUAL
				? "activeIndividualAccount"
				: "activeCorporateAccount";

		const { currentRole } =
			loginResponse[userAccountType]?.profileSetting || {};
		const isApprover = currentRole === "APPROVER";

		return (
			<>
				<Helmet>
					<title>{process.env.REACT_APP_NAME} - Bills Manager</title>
				</Helmet>
				<PageNavigator label="Bills Manager" />
				<GroomPanel>
					{isApprover ? (
						<UserRoleWarning
							style={{
								position: "absolute",
								top: "50%",
								left: "50%",
								transform: "translate(-50%, -50%)",
							}}
						/>
					) : (
						<BillsManagerListWrapper />
					)}
				</GroomPanel>
			</>
		);
	}
}

export default connect(
	({ auth: { loginResponse, activeAccountType } }) => ({
		loginResponse: loginResponse,
		activeAccountType: activeAccountType,
	}),
	null
)(BillsManager);
