import ModalHeader from "components/utils/Modal/CustomModalHeader/CustomModalHeader";
import Modal from "components/utils/Modal/Modal";
import {
	DIRECTORS_DOCUMENTATION,
	ERROR,
	FINISHED,
	IDLE,
	LOADING,
	TRUSTEES_DOCUMENTATION,
} from "config/enums";
import { API } from "lib/api";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./SetupModals.scss";
import SetupSuccessModal from "./SetupSuccessModal";
import {
	uploadDirectorPhoto,
	uploadTrusteePhoto,
} from "lib/api/apiRoutesConfig/services/Corporate/AccountServiceMS/accountSetup/subRoutes";
import { ToastsStore } from "react-toasts";
import { getErrorMessage } from "components/utils/helpers";
import { Formik } from "formik";
import { SmallLoader } from "components/utils/SmallLoader";
import CustomFileUploader from "components/utils/CustomFileUploader/CustomFileUploader";
import * as yup from "yup";

const SUPPORTED_FORMATS = ["image/png", "image/jpg", "image/jpeg"];
const FILE_SIZE = 3_000_000;

const formValidationSchema = yup.object().shape({
	photo: yup
		.mixed()
		.test("is-a-supported-type", "Unsupported file format", (value) => {
			if (value) return SUPPORTED_FORMATS.includes(value?.type);
			return true;
		})
		.test(
			"is-not-more-than-max-file-size",
			"File size is too large",
			(value) => {
				if (value) return value?.size <= FILE_SIZE;
				return true;
			}
		)
		.required("Passport photograph is required"),
});

export default function PassportModal({
	show,
	onClose,
	onSuccess,
	documentationType,
}) {
	const [currentState, setCurrentState] = useState(IDLE);
	const [successMsg, setSuccessMsg] = useState("");

	const renderBasedOnState = () => {
		switch (currentState) {
			case FINISHED:
				return (
					<SetupSuccessModal
						title="Congratulations"
						message={successMsg}
						buttonLabel="Close"
						onClick={() => onSuccess?.()}
					/>
				);
			default:
				return (
					<PassportUploadForm
						setCurrentState={(state) => setCurrentState(state)}
						setSuccessMsg={(message) => setSuccessMsg(message)}
						currentState={currentState}
						documentationType={documentationType}
					/>
				);
		}
	};

	return (
		<Modal show={show}>
			{currentState !== FINISHED && (
				<ModalHeader
					heading="Passport Photograph"
					className="p-0"
					headerClassName="setup-modals-header px-sm-5 px-3 py-3"
					dismissable={() => onClose?.()}
				/>
			)}
			<>{renderBasedOnState()}</>
		</Modal>
	);
}

const PassportUploadForm = ({
	currentState,
	setCurrentState,
	setSuccessMsg,
	documentationType,
}) => {
	const dashboardResponse = useSelector(
		(state) => state.dashboard.dashboardResponse
	);

	const initialFormValues = {
		photo: null,
	};

	// get appropriate url to be used based on documentation type
	const getUrlBasedOnType = () => {
		switch (documentationType) {
			case DIRECTORS_DOCUMENTATION:
				return uploadDirectorPhoto;
			case TRUSTEES_DOCUMENTATION:
				return uploadTrusteePhoto;
			default:
				return "";
		}
	};

	const onHandleSubmit = async (values) => {
		setCurrentState(LOADING);
		const payload = new FormData();

		payload.append("accountId", dashboardResponse.mintAccountId);
		payload.append("photo", values.photo || "");

		try {
			const res = await API.post(getUrlBasedOnType(), payload);
			if (res.status === 200) {
				setCurrentState(FINISHED);
				setSuccessMsg(res.data.message);
			}
		} catch (err) {
			setCurrentState(ERROR);
			ToastsStore.error(getErrorMessage(err), 6000, "setup-toast");
		}
	};

	return (
		<Formik
			initialValues={initialFormValues}
			validationSchema={formValidationSchema}
			onSubmit={(values) => onHandleSubmit(values)}
		>
			{({
				values,
				errors,
				touched,
				handleSubmit,
				setFieldValue,
				handleChange,
			}) => {
				return (
					<form
						className="setup-modals-form px-sm-5 px-3 pt-4 pb-5"
						onSubmit={(e) => {
							e.preventDefault();
							handleSubmit();
						}}
					>
						<div className="mb-3">
							<CustomFileUploader
								label="Upload a passport photograph"
								name="photo"
								error={touched.photo && errors.photo}
								fileName={values.photo?.name}
								onChange={(file) => {
									setFieldValue("photo", file);
								}}
							/>
						</div>

						<div>
							<button
								className="btn btn-mint-default"
								type="submit"
								disabled={currentState === LOADING}
							>
								{currentState === LOADING ? (
									<SmallLoader />
								) : (
									"Submit"
								)}
							</button>
						</div>
					</form>
				);
			}}
		</Formik>
	);
};
