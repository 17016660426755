/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from "react";
import Modal from "components/utils/Modal/Modal";
import ModalHeader from "components/utils/Modal/CustomModalHeader/CustomModalHeader";
import { ReturnHome } from "./returnHome";
// import { ReturnHome } from "./returnHome";

const Terms = ({ show, onClose, nextModal, handleSubmit }) => {
	return (
		<>
			<Modal show={show} scrollable>
				<ModalHeader
					heading=""
					className="p-0"
					headerClassName="setup-modals-header px-sm-5 px-3 py-3"
					dismissable={false}
				/>
				<TermsContent onClose={onClose} nextModal={nextModal} />
			</Modal>
		</>
	);
};
export default Terms;

const TermsContent = ({ onClose, nextModal }) => {
	const [isAccepted, setIsAccepted] = useState(false);
	return (
		<div className="terms-modal">
			<div className=" content">
				<div className="modal-title">
					<p className="title-header">Terms and conditions</p>
					<p>
						I confirm that all the information provided to register
						my business are correct and are my business information.
					</p>
				</div>
				<div className=" col-12 mb-5 custom-check custom-checkbox">
					<input
						onClick={() => setIsAccepted(!isAccepted)}
						type="checkbox"
						className="custom-control-input"
						id="accept"
						name="accept"
					/>
					<label className="custom-control-label" htmlFor="accept">
						<a
							href="https://mintyn.com/privacy-policy"
							target="_blank"
							className="active-text"
						>
							Privacy Policy.
						</a>
					</label>
				</div>
			</div>
			<div className=" mb-4 col-12  ">
				<button
					className="btn btn-mint-default"
					type="button"
					onClick={() => nextModal()}
					disabled={isAccepted ? false : true}
				>
					Continue
				</button>

				<ReturnHome className={"back-home-modal"} />
			</div>
		</div>
	);
};
