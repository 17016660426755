import GroomPanel from "components/dashboard/utils/GroomPanel/GroomPanel";
import React from "react";
import "../../EmergencySavings.scss";
import { ReactSVG } from "react-svg";
import UmbrellaIcon from "assets/img/umbrella_icon.svg";
import { useHistory } from "react-router-dom";

export default function EmptyEmergencyList() {
	const history = useHistory();

	return (
		<GroomPanel cls="es-groom">
			<div className="es-empty">
				<ReactSVG src={UmbrellaIcon} />

				<h3>You have no active Emergency Savings</h3>
				<p>Create a new emergency savings by tapping on the button below</p>

				<button
					className="btn btn-mint-default"
					onClick={() =>
						history.push(
							"/savings/emergencies/create-emergency-saving"
						)
					}
				>
					Create New Emergency Savings
				</button>
			</div>
		</GroomPanel>
	);
}
