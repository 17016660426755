import React from "react";
import { useState, useEffect } from "react";
import { FINISHED, IDLE, LOADING, ERROR } from "../../../../../../config/enums";
import "./SetupModals.scss";
import { Formik } from "formik";
import * as yup from "yup";
import Modal from "../../../../../utils/Modal/Modal";
import ModalHeader from "../../../../../utils/Modal/CustomModalHeader/CustomModalHeader";
import CommonSelect from "../../../../../utils/CommonSelect/CommonSelect";
import { Label } from "reactstrap";
import FormDatePickerGroup from "../../../../../utils/FormDatePickerGroup/FormDatePickerGroup";
import { format as formatDate } from "date-fns";
import CustomFileUploader from "../../../../../utils/CustomFileUploader/CustomFileUploader";
import SetupSuccessModal from "./SetupSuccessModal";
import { getErrorMessage } from "components/utils/helpers";
import { API } from "lib/api";
import { useSelector } from "react-redux";
import { ToastsStore } from "react-toasts";
import { SmallLoader } from "components/utils/SmallLoader";
import Disclaimer from "../Disclaimer/Disclaimer";
import {
	getAllStates,
	getTINBusinessCode,
} from "lib/api/apiRoutesConfig/services/AccountMS/common/subRoutes";
import {
	submitTinApplication,
	tinGeneratePaymentRef,
	tinVerifyPayment,
} from "lib/api/apiRoutesConfig/services/Corporate/AccountServiceMS/accountSetup/subRoutes";
import CustomFlutterWaveBtn from "components/dashboard/components/savings/Common/FundGoals/CardFlutterwaveFunding/CustomFlutterWaveBtn";
import imageFiles from "components/common/imageFiles/imageFiles";
import config from "../../../../../../config";
import { FLUTTERWAVE } from "components/dashboard/enums";

const SUPPORTED_FORMATS = [
	"image/png",
	"image/jpg",
	"image/jpeg",
	"application/pdf",
];
const FILE_SIZE = 3_000_000;
const {
	paymentGateway: { PUBLIC_KEY },
} = config;

const formValidationSchema = yup.object().shape({
	stateCode: yup
		.object()
		.required("State of business is required")
		.nullable()
		.shape({ label: yup.string(), value: yup.string() }),
	businessCode: yup
		.object()
		.required("Business type is required")
		.nullable()
		.shape({ label: yup.string(), value: yup.string() }),
	utilityDocument: yup
		.mixed()
		.test("is-a-supported-type", "Unsupported file format", (value) => {
			if (value) return SUPPORTED_FORMATS.includes(value?.type);
			return true;
		})
		.test(
			"is-not-more-than-max-file-size",
			"File size is too large",
			(value) => {
				if (value) return value?.size <= FILE_SIZE;
				return true;
			}
		)
		.required("Utility Document is required"),
	dateOfBusinessCommencement: yup
		.date()
		.required("Date of business commencement is required"),
});

export default function TINApplicationModal({ show, onClose, onSuccess }) {
	const [currentState, setCurrentState] = useState(IDLE);
	// const [successMsg, setSuccessMsg] = useState("");
	const currentUser = useSelector((state) => state.auth.currentUser);
	const [tinApplicationResponse, setTinApplicationResponse] = useState({});

	const [fwConfig, setFwConfig] = useState({
		public_key: PUBLIC_KEY,
		tx_ref: "",
		amount: 100,
		currency: "NGN",
		payment_options: "card,mobilemoney,ussd",
		customer: {
			email: currentUser?.email,
			firstname: currentUser?.firstName,
			lastname: currentUser?.lastName,
		},
		customizations: {
			title: "Mintyn Digital Bank",
			description: "TIN Application Payment",
			logo: imageFiles.icons.mintIcon,
		},
	});

	// generate payment reference
	const generatePaymentRef = async () => {
		const payload = new FormData();
		payload.append("paymentGateway", FLUTTERWAVE);
		try {
			let res = await API.put(
				tinGeneratePaymentRef(tinApplicationResponse?.sessionId),
				payload
			);
			if (res.data) {
				const { amount, paymentReference } = res.data.data;
				setFwConfig({
					...fwConfig,
					amount: amount / 100, // kobo
					tx_ref: paymentReference,
				});
				document.querySelector("#flutterwave-btn button").click();
			}
		} catch (err) {
			ToastsStore.error(getErrorMessage(err), 6000, "setup-toast");
		}
	};

	// verify payment
	const handleVerifyPayment = async () => {
		const { tx_ref } = fwConfig;
		const payload = new FormData();
		payload.append("paymentReference", tx_ref);
		try {
			onClose();
			const res = await API.put(
				tinVerifyPayment(tinApplicationResponse?.sessionId),
				payload
			);
			if (res.data) {
				window.location.reload();
			}
		} catch (err) {}
	};

	// close modal and reset fwConfig
	const handleClose = () => {
		onClose();
		setFwConfig((prev) => ({
			fwConfig: {
				...prev.fwConfig,
				tx_ref: null,
			},
		}));
	};

	const renderBasedOnState = () => {
		switch (currentState) {
			case FINISHED:
				return (
					<>
						<SetupSuccessModal
							iconType="CAUTION"
							title="TIN Application Payment"
							message={`This service cost N${(
								tinApplicationResponse?.amount / 100
							).toLocaleString()} to process, please
							click the button below to make your payment.`}
							buttonLabel="Pay using any debit card"
							onClick={() => generatePaymentRef()}
						/>
						<CustomFlutterWaveBtn
							fwConfig={fwConfig}
							handleCallback={handleVerifyPayment}
							handleClose={handleClose}
						/>
					</>
				);
			default:
				return (
					<TINApplicationForm
						setCurrentState={(state) => setCurrentState(state)}
						// setSuccessMsg={(message) => setSuccessMsg(message)}
						currentState={currentState}
						setTinApplicationResponse={(response) =>
							setTinApplicationResponse(response)
						}
					/>
				);
		}
	};

	return (
		<Modal show={show}>
			<ModalHeader
				heading={
					currentState !== FINISHED
						? "Apply for Tax Identification Number"
						: undefined
				}
				className="p-0"
				headerClassName="setup-modals-header px-sm-5 px-3 py-3"
				dismissable={() => {
					onClose();
					setCurrentState(IDLE);
				}}
			/>

			<>{renderBasedOnState()}</>
		</Modal>
	);
}

// TIN application form
const TINApplicationForm = ({
	currentState,
	setCurrentState,
	setTinApplicationResponse,
}) => {
	const [isStateLoading, setIsStateLoading] = useState(false);
	const [isBusinessCodeLoading, setIsBusinessCodeLoading] = useState(false);
	const [countryStates, setCountryStates] = useState([]);
	const [businessCodes, setBusinessCodes] = useState([]);

	const countryCode = "NG";
	const dashboardResponse = useSelector(
		(state) => state.dashboard.dashboardResponse
	);
	const initialFormValues = {
		businessCode: null,
		stateCode: null,
		utilityDocument: null,
		dateOfBusinessCommencement: undefined,
	};

	const getStates = async () => {
		setIsStateLoading(true);
		try {
			const response = await API.get(getAllStates(countryCode));
			const { status, data } = response || {};
			if (status === 200) {
				setCountryStates(data.data);
			}
		} catch (err) {
			setCountryStates([]);
			ToastsStore.error(getErrorMessage(err), 6000, "setup-toast");
		} finally {
			setIsStateLoading(false);
		}
	};

	const getBusinessCodes = async () => {
		setIsBusinessCodeLoading(true);
		try {
			const response = await API.get(getTINBusinessCode);
			const { status, data } = response || {};
			if (status === 200) {
				setBusinessCodes(data.data);
			}
		} catch (err) {
			setBusinessCodes([]);
			ToastsStore.error(getErrorMessage(err), 6000, "setup-toast");
		} finally {
			setIsBusinessCodeLoading(false);
		}
	};

	const onHandleSubmit = async (values) => {
		setCurrentState(LOADING);
		const payload = new FormData();
		payload.append("utilityDocument", values.utilityDocument || "");

		try {
			const res = await API.post(
				submitTinApplication(dashboardResponse.mintAccountId),
				payload,
				{
					params: {
						businessCode: values.businessCode.value,
						stateCode: values.stateCode.value,
						dateOfBusinessCommencement:
							values.dateOfBusinessCommencement
								? formatDate(
										values.dateOfBusinessCommencement,
										"dd/MM/yyyy"
								  )
								: values.dateOfBusinessCommencement,
					},
				}
			);
			if (res.status === 200) {
				setCurrentState(FINISHED);
				// setSuccessMsg(res.data.message);
				setTinApplicationResponse(res.data.data);
			}
		} catch (err) {
			setCurrentState(ERROR);
			ToastsStore.error(getErrorMessage(err), 6000, "setup-toast");
		}
	};

	useEffect(() => {
		getStates();
		getBusinessCodes();
	}, []);

	return (
		<Formik
			initialValues={initialFormValues}
			validationSchema={formValidationSchema}
			onSubmit={(values) => onHandleSubmit(values)}
		>
			{({
				values,
				errors,
				touched,
				handleSubmit,
				setFieldValue,
				handleChange,
			}) => {
				return (
					<form
						className="setup-modals-form px-sm-5 px-3 pt-4 pb-5 row"
						onSubmit={(e) => {
							e.preventDefault();
							handleSubmit();
						}}
					>
						<div className="col-12 mb-4">
							<Disclaimer
								message="Mintyn does not file for tax on behalf of your business, as
					part of regulatory requirements we are required to have a
					Tax Identification number on your business."
							/>
						</div>

						<div className="mb-3 col-12">
							<Label for="stateCode">
								State of registered business
							</Label>
							<CommonSelect
								name="stateCode"
								placeholder="Select state"
								error={touched.stateCode && errors.stateCode}
								handleChange={(value) =>
									setFieldValue("stateCode", value)
								}
								options={countryStates.map((state) => ({
									label: state?.name,
									value: state?.code,
								}))}
								showErrorMsg
								isLoading={isStateLoading}
							/>
						</div>

						<div className="col-12 mb-3">
							<FormDatePickerGroup
								isClearable={false}
								placeholder="Select date"
								label="Date of Business Commencement"
								selected={values.dateOfBusinessCommencement}
								max={new Date()}
								name="dateOfBusinessCommencement"
								error={
									touched.dateOfBusinessCommencement &&
									errors.dateOfBusinessCommencement
								}
								onChange={(date) =>
									setFieldValue(
										"dateOfBusinessCommencement",
										date
									)
								}
							/>
						</div>

						<div className="mb-3 col-12">
							<Label for="businessCode">Business Type</Label>
							<CommonSelect
								name="businessCode"
								placeholder="Select business type"
								error={
									touched.businessCode && errors.businessCode
								}
								handleChange={(value) =>
									setFieldValue("businessCode", value)
								}
								options={businessCodes.map((business) => ({
									label: business?.name,
									value: business?.code,
								}))}
								showErrorMsg
								isLoading={isBusinessCodeLoading}
							/>
						</div>

						<div className="mb-3 col-12">
							<CustomFileUploader
								label="Upload your Utility Bill (Waste bill,Light bill etc)"
								name="utilityDocument"
								error={
									touched.utilityDocument &&
									errors.utilityDocument
								}
								fileName={values.utilityDocument?.name}
								onChange={(file) => {
									setFieldValue("utilityDocument", file);
								}}
							/>
						</div>

						<div className="col-12">
							<button
								className="btn btn-mint-default"
								type="submit"
								disabled={currentState === LOADING}
							>
								{currentState === LOADING ? (
									<SmallLoader />
								) : (
									"Apply for TIN"
								)}
							</button>
						</div>
					</form>
				);
			}}
		</Formik>
	);
};
