import React from "react";
import "./CustomSearchInput.scss";
import SearchIcon from "assets/img/search-icon.svg";

export default function CustomSearchInput({
	name,
	placeholder = "",
	value,
	className = "",
	error,
	onFocus,
	onChange,
	required,
	...rest
}) {
	return (
		<div className="custom-search-input">
			<div className="custom-search-input-container">
				<span className="custom-search-icon-container">
					<img src={SearchIcon} alt="" />
				</span>

				<input
					name={name}
					placeholder={placeholder}
					value={value}
					className={`form-control ${
						error ? "has-error" : ""
					} ${className}`}
					autoComplete="off"
					onFocus={onFocus}
					onChange={onChange}
					required={required}
					{...rest}
				/>
			</div>

			{/* {error && <span className="error-msg">{error}</span>} */}
		</div>
	);
}
