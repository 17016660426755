import React, { Component } from "react";
import Axios from "axios";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import queryString from "query-string";
import {
	updatePersonal,
	updateStepData,
	clearStage,
} from "../../../reducers/steps";
import { SmallLoader } from "../../utils/SmallLoader";
import Alert from "../../utils/Alert";
import ValidateForm from "../../utils/Validator";
import { API } from "../../../lib/api";
import { getErrorMessage } from "../../utils/helpers";
import { verifyPhoneNumber } from "../../../lib/api/apiRoutesConfig/services/AccountMS/signup/subRoutes";
import PhoneNumberInputV2 from "components/utils/PhoneNumberInput/PhoneNumberInputV2";

class Signup extends Component {
	signal = Axios.CancelToken.source();
	_isMounted = false;

	state = {
		request: {
			phoneNumber: "",
		},
		errors: {},
		loading: false,
		alert: { alert_newkey: 1 },
	};

	componentDidMount = () => {
		this._isMounted = true;
		this.props.clearStage();
		const { referral } = queryString.parse(
			this.props.history.location.search
		);
		if (referral) {
			this.props.updatePersonal("referralCode", referral);
			this.props.updateStepData({ withReferral: true });
		}
	};

	componentWillUnmount = () => {
		this.signal.cancel("canceling network calls");
		this._isMounted = false;
	};

	setError = (err) => {
		this._isMounted &&
			this.setState((prevState) => ({
				loading: false,
				alert: {
					alert_newkey: prevState.alert.alert_newkey + 1,
					alert_show: "show",
					alert_message: getErrorMessage(err),
					alert_type: "danger",
				},
			}));
	};

	handlePhone = (__, value, countryData) => {
		let Numbers = value.replace(/[^0-9]/g, "");
		value = Numbers.replace(/^(-?)0+/, "");
		this.props.updatePersonal("phoneNumber", countryData.dialCode + value);
		this._isMounted &&
			this.setState((prevState) => ({
				...prevState,
				request: {
					...prevState.request,
					phoneNumber: value,
				},
				errors: {
					...prevState.errors,
					phoneNumber: false,
				},
				dialCode: countryData.dialCode,
			}));
	};

	initateRegistration = async () => {
		try {
			this.setState({ loading: true });

			const { request, dialCode } = this.state;
			const requestPayload = { ...request };
			requestPayload.phoneNumber = `+${dialCode}${request.phoneNumber}`;

			const res = await API.put(verifyPhoneNumber, requestPayload);
			const { status, data } = res || {};
			if (status === 200) {
				const { customerDetailVerificationRequired: req } =
					data.data || {};
				const pathname = req
					? "/onboard"
					: "/onboard/account-type-verification";
				this.setState({ loading: false });
				this.props.history.replace({ pathname });
			}
		} catch (err) {
			this.setError(err);
		}
	};

	handleSubmit = (e) => {
		e.preventDefault();
		const { request } = this.state;
		const { errors, formisValid } = ValidateForm(e, request) || {};
		formisValid ? this.initateRegistration() : this.setState({ errors });
	};

	render() {
		const { errors, loading, alert, request } = this.state;
		const { alert_show, alert_message, alert_newkey, alert_type } = alert;
		const { phoneNumber } = request;
		return (
			<div>
				<div className="my-5 text-center">
					<h6 className="info-heading">
						Begin Your Free Banking Experience here.
					</h6>
				</div>
				<Alert
					key={alert_newkey}
					type={alert_type}
					message={alert_message}
					show={alert_show}
				/>
				<form onSubmit={this.handleSubmit}>
					<PhoneNumberInputV2
						label="Phone Number"
						inputFieldName="phoneNumber"
						inputValue={phoneNumber}
						handlePhoneNumberChange={this.handlePhone}
						error={errors["phoneNumber"]}
						isDisabled={loading}
					/>

					<div className="mt-4 mb-4">
						<button
							className="btn-primary w-100"
							disabled={loading || phoneNumber.length !== 10}
						>
							{loading ? <SmallLoader /> : "Continue"}
						</button>
					</div>
				</form>
			</div>
		);
	}
}

export default withRouter(
	connect(null, { updatePersonal, updateStepData, clearStage })(Signup)
);
