import AllOrders from "../AllOrders/AllOrders";
import PersonalOrders from "../PersonalOrders/PersonalOrders";

export const defaultPath = "/market-place/order-history";

export default [
	{
		title: "All Orders",
		path: `${defaultPath}/all-orders`,
		component: AllOrders,
	},
	{
		title: "Personal Order",
		path: `${defaultPath}/personal-orders`,
		component:PersonalOrders ,
	},

];