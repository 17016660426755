import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ToastsStore } from "react-toasts";
import "./DashboardFinanceCard.scss";

export default function DashboardFinanceCard({
	icon,
	title,
	count,
	iconSize,
	to,
}) {
	const { loginResponse } = useSelector((state) => state.auth);
	const { accountVerified } = loginResponse.activeCorporateAccount || {};

	const showToast = () => {
		ToastsStore.error(
			"Please complete your profile to get unlimited access to your account",
			6000,
			"right-toast"
		);
	};

	return (
		<Link
			to={to}
			className="dashboard-finance-card"
			onClick={(e) => {
				if (!accountVerified) {
					e.preventDefault();
					showToast();
				}
			}}
		>
			<div className="d-flex align-items-center">
				<img
					alt="transaction history"
					src={icon}
					style={{
						width: iconSize || "36px",
						height: iconSize || "36px",
					}}
				/>
				<div style={{ marginLeft: "12px" }}>
					{count || count === 0 ? <h3>{count}</h3> : null}
					<h4>{title}</h4>
				</div>
			</div>
		</Link>
	);
}
