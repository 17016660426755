/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import FormInputGroup from "components/utils/FormInputGroup/FormInputGroup";
import { Formik } from "formik";
import * as yup from "yup";
import { SmallLoader } from "components/utils/SmallLoader";
import { API } from "lib/api";
import { getValidMobileNumberLength } from "components/utils/helpers";
import { postSecretaryDetailsAction } from "lib/api/apiRoutesConfig/services/AccountMS/CAC/subRoutes";

import { getErrorMessage } from "components/utils/helpers";
import { ToastsStore } from "react-toasts";

const SecretaryDetails = ({ setSteps, regInfo, refresh }) => {
	const [isLoading, setIsLoading] = useState(false);

	const formValidationSchema = yup.object().shape({
		firstName: yup.string().required("Is required"),

		middleName: yup.string().required("Is required"),

		lastName: yup.string().required("Is required"),

		emailAddress: yup
			.string()
			.trim()
			.email("*Invalid email")
			.required("*Is required"),

		phoneNumber: yup
			.string()
			.min(11, "Invalid phone number!")
			.required("Is required"),
	});

	// Date
	const initialFormValues = {
		firstName: "",
		middleName: "",
		lastName: "",
		emailAddress: "",
		phoneNumber: "",
	};

	// Handle form submit
	const handleSubmit = async (values) => {
		setIsLoading(true);
		try {
			const res = await API.put(
				postSecretaryDetailsAction(regInfo.sessionId),
				{
					...values,
					phoneNumber: "+234" + values.phoneNumber,
				}
			);
			const { status, data } = res || {};
			if (status === 200) {
				refresh(localStorage.getItem("s_id"));
				// setSteps(5);
				ToastsStore.success(data.message, 6000, "right-toast");
			}
		} catch (err) {
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			<Formik
				initialValues={initialFormValues}
				validationSchema={formValidationSchema}
				onSubmit={(values) => handleSubmit(values)}
			>
				{({
					values,
					errors,
					touched,
					handleSubmit,
					setFieldValue,
					handleChange,
					handleBlur,
				}) => {
					return (
						<form
							className="reg-business-form  row"
							onSubmit={(e) => {
								e.preventDefault();
								handleSubmit();
							}}
						>
							<div className="mb-4 col-12">
								<FormInputGroup
									id="firstName"
									name="firstName"
									label="Firstname"
									className="red-business-input"
									onBlur={handleBlur}
									value={values.firstName}
									onChange={handleChange}
									error={
										touched.firstName && errors.firstName
									}
								/>
							</div>
							<div className="mb-4 col-12">
								<FormInputGroup
									id="middleName"
									name="middleName"
									label="Middlename"
									className="red-business-input"
									onBlur={handleBlur}
									value={values.middleName}
									onChange={handleChange}
									error={
										touched.middleName && errors.middleName
									}
								/>
							</div>
							<div className="mb-4 col-12">
								<FormInputGroup
									id="lastName"
									name="lastName"
									label="Lastname"
									className="red-business-input"
									onBlur={handleBlur}
									value={values.lastName}
									onChange={handleChange}
									error={touched.lastName && errors.lastName}
								/>
							</div>

							<div className="mb-4 col-12">
								<FormInputGroup
									id="emailAddress"
									name="emailAddress"
									label="Email Address "
									className="red-business-input"
									onBlur={handleBlur}
									value={values.emailAddress}
									onChange={handleChange}
									error={
										touched.emailAddress &&
										errors.emailAddress
									}
								/>
							</div>
							<div className="mb-4 col-12">
								<FormInputGroup
									id="phoneNumber"
									label="Phone Number"
									name="phoneNumber"
									placeholder="08023311233"
									className="red-business-input"
									onBlur={handleBlur}
									value={values.phoneNumber}
									onChange={(e) => {
										const value = e.target.value
											.trim()
											.replace(/[^+0-9]/i, "");
										if (
											value.length <=
											getValidMobileNumberLength(value)
										) {
											setFieldValue(e.target.name, value);
										}
									}}
									error={
										touched.phoneNumber &&
										errors.phoneNumber
									}
								/>
							</div>

							<div className="col-12 ">
								<button
									className="btn btn-mint-default"
									type="submit"
									disabled={isLoading}
								>
									{isLoading ? <SmallLoader /> : "Continue"}
								</button>
							</div>
						</form>
					);
				}}
			</Formik>
		</>
	);
};
export default SecretaryDetails;
