import React, { Component } from "react";
import Modal from "../../../../utils/Modal/Modal";
import ModalHeader from "../../../../utils/Modal/CustomModalHeader/CustomModalHeader";
import CreateBeneficiaryForm from "./CreateBeneficiaryForm/CreateBeneficiaryForm";
import LoadingBox from "../../../../utils/LoadingBox/LoadingBox";
import { bankNameEnquiry } from "../../../../../lib/api/apiRoutesConfig/services/FundsManagementMS/fundTransfer/subRoutes";
import { API } from "../../../../../lib/api";
import Axios from "axios";
import OperationStatus, {
    failureResponseStatus,
    successResponseStatus,
} from "../../../utils/OperationStatus/OperationStatus";
import { getErrorMessage } from "../../../../utils/helpers";
import ReviewBeneficiary from "../ReviewBeneficiary/ReviewBeneficiary";
import { createBeneficiary } from "../../../../../lib/api/apiRoutesConfig/services/FundsManagementMS/subRoutes";

const INITIAL = "INITIAL";
const LOADING = "LOADING";
const STATUS = "STATUS";
const REVIEW = "REVIEW";

class CreateBeneficiaryModal extends Component {
    _isMounted = false;
    signal = Axios.CancelToken.source();

    state = {
        currentState: INITIAL,
        currentRequest: {},
    };

    componentDidMount = () => {
        this._isMounted = true;
    };

    componentWillUnmount = () => {
        this._isMounted = false;
        this.signal.cancel("canceling network calls");
    };

    handleTryAgain = () => {
        this.setState({
            currentState: INITIAL,
        });
    };

    pushToBeneficiaryList = (e) => {
        e.preventDefault();
        const { newBeneficiary } = this.state;
        this.props.pushToBeneficiaryList(newBeneficiary);
    };

    completeAddition = async () => {
        try {
            this.setState({ currentState: LOADING });
            const { currentRequest } = this.state;
            let res = await API.post(
                createBeneficiary,
                { ...currentRequest },
                { cancelToken: this.signal.token }
            );
            if (res.status === 200) {
                const { message, data } = res.data;
                this._isMounted &&
                    this.setState({
                        currentState: STATUS,
                        statusObj: {
                            status: successResponseStatus,
                            statusMessage: message,
                            handleSuccessLink: this.pushToBeneficiaryList,
                            successLink: "/",
                            successLinkText: "Okay",
                        },
                        newBeneficiary: data,
                    });
            }
        } catch (err) {
            this._isMounted &&
                this.setState({
                    currentState: STATUS,
                    statusObj: {
                        status: failureResponseStatus,
                        statusMessage: getErrorMessage(err),
                    },
                });
        }
    };

    performNameEnquiry = async (request) => {
        try {
            this.setState({ currentState: LOADING });
            let res = await API.get(bankNameEnquiry, {
                params: { ...request },
                cancelToken: this.signal.token,
            });
            if (res.status === 200) {
                const { accountName } = res.data.data;
                request.accountName = accountName;
                this.setState({
                    currentState: REVIEW,
                    currentRequest: request,
                });
            }
        } catch (err) {
            this._isMounted &&
                this.setState({
                    currentState: STATUS,
                    statusObj: {
                        status: failureResponseStatus,
                        statusMessage: getErrorMessage(err),
                    },
                });
        }
    };

    renderBasedOnState = () => {
        const { currentState } = this.state;
        const { toggleModal } = this.props;

        switch (currentState) {
            case INITIAL:
                return (
                    <>
                        <ModalHeader heading={`Add New Beneficiary`} />
                        <CreateBeneficiaryForm
                            performNameEnquiry={this.performNameEnquiry}
                            toggleModal={toggleModal}
                        />
                    </>
                );

            case LOADING:
                return <LoadingBox />;

            case STATUS:
                const { statusObj } = this.state;
                return (
                    <OperationStatus
                        statusObj={statusObj}
                        handleTryAgain={this.handleTryAgain}
                    />
                );

            case REVIEW:
                const { currentRequest } = this.state;
                return (
                    <>
                        <ModalHeader heading={`Add New Beneficiary`} />
                        <ReviewBeneficiary
                            request={currentRequest}
                            toggleModal={toggleModal}
                            completeAddition={this.completeAddition}
                        />
                    </>
                );

            default:
                return "";
        }
    };

    render = () => {
        const { show } = this.props;
        return <Modal show={show}>{this.renderBasedOnState()}</Modal>;
    };
}

export default CreateBeneficiaryModal;
