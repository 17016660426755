import React, { Component } from "react";
import { ToastsStore } from "react-toasts";
import { connect } from "react-redux";
import { TinyLoad } from "../../../../../utils/SmallLoader";
import { API } from "../../../../../../lib/api";
import { getErrorMessage } from "../../../../../utils/helpers";
import { PHONE_VERIFICATION } from "../../../../../../config/enums";

class ResendConfirmationCode extends Component {
	state = {
		resending: false,
		minutes: 1,
		seconds: 0,
	};

	componentDidMount = () => {
		this.startTimer();
	};

	startTimer = () => {
		this.myInterval = setInterval(() => {
			const { seconds, minutes } = this.state;

			if (seconds > 0) {
				this.setState(({ seconds }) => ({
					seconds: seconds - 1,
				}));
			}
			if (seconds === 0) {
				if (minutes === 0) {
					clearInterval(this.myInterval);
				} else {
					this.setState(({ minutes }) => ({
						minutes: minutes - 1,
						seconds: 59,
					}));
				}
			}
		}, 1000);
	};

	componentWillUnmount() {
		clearInterval(this.myInterval);
	}

	startTimerAgain = () => {
		clearInterval(this.myInterval);
		this.startTimer();
	};

	resendConfirmation = async (e, type) => {
		try {
			e.preventDefault();
			this.setState({
				resending: true,
				resentStatus: false,
				resendMailError: false,
			});
			const { newAccount, endPoint } = this.props;
			const { sessionId } = newAccount;
			const res = await API.put(endPoint(sessionId), {
				type: type,
			});
			if (res.status === 200) {
				ToastsStore.success(res.data.message, 6000, "right-toast");
				this.startTimerAgain();
				this.setState({ resending: false });
			}
		} catch (err) {
			const errorMsg = getErrorMessage(err);
			ToastsStore.error(errorMsg, 6000, "right-toast");
			this.setState({ resending: false });
		}
	};

	render() {
		const { resending, minutes, seconds } = this.state;
		const { type, useText } = this.props;

		return (
			<div className="w-100">
				{type === PHONE_VERIFICATION && (
					<div className="info-alert mt-4">
						<p className="info-text">
							It may take up to 1 minute for your verification
							code to arrive
						</p>
					</div>
				)}

				<div className="bottom_links">
					{type === PHONE_VERIFICATION ? (
						<div>
							{minutes === 0 && seconds === 0 ? (
								<>
									<a
										href="!#"
										className={
											resending ? "not-active" : ""
										}
										onClick={(e) =>
											this.resendConfirmation(
												e,
												useText ? "TEXT" : "SMS"
											)
										}
									>
										<span className="mr-2">
											Resend Code
										</span>
										{resending && <TinyLoad />}
									</a>

									{/* <a
										href="!#"
										className={
											resending ? "not-active" : ""
										}
										onClick={(e) =>
											this.resendConfirmation(e, "CALL")
										}
									>
										<span className="mr-2">
											{resending ? "Resending " : "Resend"}{" "}
											Call Me{" "}
										</span>
										{resending && <TinyLoad />}
									</a> */}
								</>
							) : (
								<a
									href="#! "
									onClick={(e) => e.preventDefault()}
								>
									{" "}
									{minutes}:
									{seconds < 10 ? `0${seconds}` : seconds}
								</a>
							)}
						</div>
					) : (
						<div>
							<a
								href="!#"
								className={resending ? "not-active" : ""}
								onClick={(e) =>
									this.resendConfirmation(e, "TEXT")
								}
							>
								<span className="mr-2">Resend Code</span>
								{resending && <TinyLoad />}
							</a>
						</div>
					)}
				</div>
			</div>
		);
	}
}

export default connect((state) => ({ newAccount: state.auth.newAccount }))(
	ResendConfirmationCode
);
