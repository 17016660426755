import {
	UPDATE_PROFILE_SETTINGS,
	SET_SECURITY_QUESTION,
	UPDATE_NOTIFICATION_SETTTING,
	UPDATE_CUSTOMER_TRANSFER_LIMITS,
	UPDATE_CUSTOMER_ID,
} from "./types";
import { SAMEBANK } from "../config/enums";

export const updateProfileSettings = (data) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_PROFILE_SETTINGS, payload: data });
	};
};

export const updateSecurityQueStatus = () => (dispatch) => {
	dispatch({
		type: SET_SECURITY_QUESTION,
	});
};

export const updateNotificationSetting = (actionName) => (dispatch, getState) =>
	Promise.resolve().then(() => {
		const { notifications } = getState().settings;
		const newState = notifications[actionName];
		dispatch({
			type: UPDATE_NOTIFICATION_SETTTING,
			name: actionName,
		});
		return newState;
	});

export const updateCustomerTransferLimit = (accountType, limitObject) => (
	dispatch
) => {
	const { bulletLimit, dailyLimit } = limitObject;
	const bulletKey =
		accountType === SAMEBANK
			? "intraBankBulletLimit"
			: "interBankBulletLimit";
	const dailyKey =
		accountType === SAMEBANK
			? "intraBankDailyLimit"
			: "interBankDailyLimit";
	const spendingLimit = {
		[bulletKey]: bulletLimit,
		[dailyKey]: dailyLimit,
	};
	dispatch({
		type: UPDATE_CUSTOMER_TRANSFER_LIMITS,
		newSpendLimit: spendingLimit,
	});
};

export const updateCustomerIDStatus = (data) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_CUSTOMER_ID, payload: data });
	};
};
