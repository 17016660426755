import React from "react";
const docsDomain = `https://mymintapp-prod.web.app`;

const legalDocuments = [
	{
		title: "mintyn account privacy policy",
		docLink: `mint_account_privacy_policy.html`,
	},
	{
		title: "mintyn account terms and conditions",
		docLink: `mint_account_terms_and_conditions.html`,
	},
	{
		title: "mintyn savings goals terms and conditions",
		docLink: `mint_savings_goals_terms_and_conditions.html`,
	},
];

const DocumentView = ({ title, docLink }) => (
	<a
		href={`${docsDomain}/${docLink}`}
		className="btn external__btn--link"
		rel="noopener noreferrer"
		target="_blank"
	>
		{title}
	</a>
);

const MintAccountLegal = () => {
	return (
		<div className="row justify-content-center">
			<div className="col-lg-7">
				<div className="mt-3">
					{legalDocuments.map((item, index) => (
						<DocumentView key={index} {...item} />
					))}
				</div>
			</div>
		</div>
	);
};

export default MintAccountLegal;
