import { formatCurrency, formatEnum } from "components/utils/helpers";
import React from "react";
import "../../SpendAndSave.scss";
import { format as formatDate } from "date-fns";

export default function SASTransactionHistory({
	data = {},
	header = "Savings History",
}) {
	const { records } = data || {};

	return (
		<>
			<h2>{header}</h2>

			{records?.length <= 0 ? (
				<div className="d-flex w-100 justify-content-center align-items-center h-100 pb-5">
					<h6 className="notready">No Transactions For This Goal</h6>
				</div>
			) : (
				<div className="sas-list-transactions__table">
					{records &&
						records?.map((item, index) => {
							const {
								dateCreated,
								transactionType,
								transactionAmount,
							} = item || {};
							return (
								<div
									key={index}
									className="sas-list-transactions-table-row"
								>
									<div>
										{dateCreated && (
											<p>
												{formatDate(
													new Date(dateCreated) ?? 0,
													"dd-MM-yyyy hh:mm a"
												)}
											</p>
										)}
										<span className="text-uppercase">
											{formatEnum(transactionType)}
										</span>
									</div>

									<div>
										<p className="credit">
											{formatCurrency(transactionAmount)}
										</p>
										{/* <span
									className={`text-uppercase ${
										transactionStatus ===
										transactionStatusEnum.SUCCESSFUL
											? "success"
											: transactionStatus ===
											  transactionStatusEnum.FAILED
											? "failed"
											: transactionStatus ===
											  transactionStatusEnum.PENDING
											? "pending"
											: ""
									}`}
								>
									{formatEnum(transactionStatus)}
								</span> */}
									</div>
								</div>
							);
						})}
				</div>
			)}
		</>
	);
}
