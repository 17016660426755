import { getErrorMessage } from "components/utils/helpers";
import { ERROR, FINISHED, IDLE, LOADING } from "config/enums";
import { API } from "lib/api";
import { performCartActions } from "lib/api/apiRoutesConfig/services/tradeMS/individualTrade/subRoutes";
import { useState } from "react";
import { useCallback } from "react";
import { ToastsStore } from "react-toasts";
import useFetchCart from "./useFetchCart";

export default function useUpdateCartItem() {
	const [currentStatus, setCurrentStatus] = useState(IDLE);
	const { handleFetchCart } = useFetchCart();

	const handleUpdateCartItem = useCallback(async (requestBody, cartType) => {
		setCurrentStatus(LOADING);
		try {
			const response = await API.put(performCartActions, requestBody);
			const { status, data } = response || {};
			if (status === 200) {
				ToastsStore.success(data.message, 6000, "right-toast");
				handleFetchCart(cartType);
				setCurrentStatus(FINISHED);
			}
		} catch (err) {
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
			setCurrentStatus(ERROR);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return { currentStatus, handleUpdateCartItem };
}
