import React, { Component } from "react";
import { ReactSVG } from "react-svg";
import { connect } from "react-redux";
import { useFlutterwave, FlutterWaveButton } from "react-flutterwave";
import imageFiles from "../../../../../../common/imageFiles/imageFiles";
import FadeIn from "../../../../effects/FadeIn";
import { API } from "../../../../../../../lib/api";
import {
	genTransRefPaystack,
	verifyPaystackRef,
} from "../../../../../../../lib/api/apiRoutesConfig/services/FundsManagementMS/accountFunding/subRoutes";
import { SmallLoader } from "../../../../../../utils/SmallLoader";
import { getErrorMessage } from "../../../../../../utils/helpers";
import config from "../../../../../../../config";

const {
	paymentGateway: { PUBLIC_KEY },
} = config;

function CustomFlutterWaveBtn({ fwConfig, handleCallback, handleClose }) {
	const handleFlutterPayment = useFlutterwave(config);

	const fwBtnConfig = {
		...fwConfig,
		text: "Pay with Flutterwave!",
		callback: () => handleCallback(),
		onClose: () => handleClose(),
	};

	return (
		<>
			<div className="">
				<button
					id="lookTrigger"
					style={{ display: "none" }}
					onClick={() => {
						handleFlutterPayment({
							callback: (response) => {
								console.log(response);
							},
							onClose: () => {},
						});
					}}
				>
					Testing FW Payment
				</button>
				<div className="button__container">
					<FlutterWaveButton {...fwBtnConfig} />
				</div>
			</div>
		</>
	);
}

class CardPaystackFunding extends Component {
	state = {
		paymentGateway: "FLUTTERWAVE",
		makingReference: false,
		transcationRefError: false,
		askMoni: false,
		amount: "",
		fwConfig: {
			public_key: PUBLIC_KEY,
			tx_ref: "",
			amount: 100,
			currency: "NGN",
			payment_options: "card,mobilemoney,ussd",
			customer: {
				email: this.props.currentUser.email,
				firstname: this.props.currentUser.firstName,
				lastname: this.props.currentUser.lastName,
			},
			customizations: {
				title: "Mintyn Digital Bank",
				description: "Account Funding via web",
				logo: "https://res.cloudinary.com/dswxtf2ay/image/upload/v1581949033/Mint_Website/mintwhitelogo_ienkxe.svg",
			},
		},
	};

	handleCallback = async () => {
		try {
			this.props.dismissModal();
			const { tx_ref } = this.state.fwConfig;
			const url = verifyPaystackRef(tx_ref);
			const res = await API.get(url);
			if (res.data) {
				window.location.reload();
			}
		} catch (err) {}
	};

	handleClose = () => {
		this.setState((prevState) => ({
			fwConfig: {
				...prevState.fwConfig,
				tx_ref: null,
				amount: 0,
			},
		}));
	};

	handleTryAgain = (e) => {
		e.preventDefault();
		this.setState({ makingReference: true });
		this.getReference();
	};

	handleAmount = (e) => {
		let { name, value } = e.target;
		value = value.replace(/[^0-9]/g, "");
		let Amount = Number(value);
		this.setState({ [name]: Amount.toLocaleString() });
	};

	getReference = async () => {
		try {
			this.setState({
				transcationRefError: false,
				makingReference: true,
			});
			const { bankAccounts } = this.props;
			const { accountId } = bankAccounts ? bankAccounts[0] : {};
			const { amount, paymentGateway } = this.state;
			const realAmountNGN = Number(amount.replace(/[^0-9.-]+/g, ""));
			const request = { amount: realAmountNGN, paymentGateway };
			const url = genTransRefPaystack(accountId);
			const res = await API.post(url, request);
			if (res.data) {
				const { amount, transactionReference } = res.data.data;
				this.setState((prevState) => ({
					fwConfig: {
						...prevState.fwConfig,
						amount: amount / 100, //kobo
						tx_ref: transactionReference,
					},
					makingReference: false,
				}));
				document.querySelector(".button__container button").click();
			}
		} catch (err) {
			this.setState({
				makingReference: false,
				transcationRefError: getErrorMessage(err),
			});
		}
	};

	render = () => {
		const { yourCard } = imageFiles.illustrations;
		const {
			askMoni,
			fwConfig,
			makingReference,
			transcationRefError,
			amount,
		} = this.state;
		const realAmount = amount
			? Number(amount.replace(/[^0-9.-]+/g, ""))
			: 0;

		return (
			<div className="col-lg-4 mb-3 mb-lg-0">
				<div className="boxxie">
					<div className="small-talk">
						<ReactSVG src={yourCard} title="credit card" />
						<h6>Your card </h6>
						<p>
							You can add your current bank card <br /> to
							transfer funds to your Mintyn Account
						</p>
					</div>

					<div className="row justify-content-center align-items-center">
						<div className="col-12">
							{askMoni ? (
								<FadeIn>
									<div className="small-form">
										<label className="mkTell">
											How much do you want to fund your
											account with?
										</label>
										<input
											className="form-control more primary"
											name="amount"
											onChange={this.handleAmount}
											value={amount}
											autoComplete="off"
											disabled={makingReference}
										/>

										{transcationRefError && (
											<div
												className="error-text"
												style={{ fontSize: "10px" }}
											>
												{`${transcationRefError} `}
												<a
													href="#!"
													onClick={
														this.handleTryAgain
													}
												>
													try again
												</a>
											</div>
										)}

										<button
											className="btn card-btn mt-2"
											onClick={() => this.getReference()}
											disabled={
												realAmount <= 0 ||
												makingReference
											}
										>
											{makingReference ? (
												<SmallLoader />
											) : (
												"Proceed"
											)}
										</button>
										<CustomFlutterWaveBtn
											fwConfig={fwConfig}
											handleCallback={this.handleCallback}
											handleClose={this.handleClose}
										/>
									</div>
								</FadeIn>
							) : (
								<div className="small-form">
									<label>&nbsp;</label>
									<button
										className="btn card-btn"
										onClick={() =>
											this.setState({ askMoni: true })
										}
									>
										Use Card
									</button>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	};
}

export default connect(
	({ auth: { currentUser }, dashboard: { dashboardResponse } }) => ({
		currentUser: currentUser,
		bankAccounts: dashboardResponse.bankAccounts,
	}),
	null
)(CardPaystackFunding);
