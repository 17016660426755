import React from "react";
import InvestmentWrapper from "../InvestmentWrapper/InvestmentWrapper";
import CompletedInvestmentsContent from "./CompletedInvestmentsContent/CompletedInvestmentsContent";
import { investmentStatus } from "../../../../constants";

const CompletedInvestments = () => {
	return (
		<InvestmentWrapper
			investmentStatus={investmentStatus.COMPLETED}
			component={CompletedInvestmentsContent}
		/>
	);
};

export default CompletedInvestments;
