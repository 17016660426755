import Cookies from "js-cookie";
import {
	SetAccountTypeCode,
	cookieOptions,
	removeCookieValues,
} from "../components/utils/helpers";
import {
	LOG_USER,
	KEEP_LOGGED_IN,
	SET_CONNECTION,
	LOGOUT_USER,
	KICK_USER_OUT,
	CLEAR_SESSION_MESSAGE,
	UPDATE_CUSTOMER_SETTINGS,
	UPDATE_CUSTOMER_AUTH_INFO,
	UPDATE_IS_LOGGED_IN,
	// CLEAR_REDUX_STORE,
} from "./types";
import { MINTYN_USER_NEW_ACCOUNT } from "components/constants";

export const logUser = (LoginResponse) => {
	const {
		user: currentUser,
		activeAccountType,
		...newLoginResponse
	} = LoginResponse;

	const isAccountIndividual = activeAccountType === "INDIVIDUAL";
	const isEnterpriseOrTrustee =
		activeAccountType === "ENTERPRISE" ||
		activeAccountType === "INCORPORATED_TRUSTEE";
	const isSole =
		activeAccountType === "SOLE_PROPRIETORSHIP" ||
		activeAccountType === "SOLE_PROPRIETORSHIP_NO_CAC";

	// define user permissions
	const permissionsArray = [
		...SetAccountTypeCode(activeAccountType),
		...(newLoginResponse?.activeCorporateAccount?.setUpCompleted ===
			false && !isAccountIndividual
			? ["104"]
			: newLoginResponse?.activeCorporateAccount?.accountVerified ===
					true && isEnterpriseOrTrustee
			? ["105", "106"]
			: (newLoginResponse?.activeCorporateAccount?.accountVerified ===
					true &&
					isSole) ||
			  isAccountIndividual
			? ["105", "107"]
			: newLoginResponse?.activeCorporateAccount?.accountVerified ===
					true || isAccountIndividual
			? ["105"]
			: []),
	];

	return (dispatch) => {
		dispatch({
			type: LOG_USER,
			payload: currentUser,
			specific: newLoginResponse,
			activeAccountType: activeAccountType,
			accountTypeCode: permissionsArray,
		});
		dispatch({
			type: UPDATE_CUSTOMER_SETTINGS,
			profilePayload: isAccountIndividual
				? newLoginResponse.activeIndividualAccount.profileSetting
				: newLoginResponse.activeCorporateAccount.profileSetting,
			notisPayload: isAccountIndividual
				? newLoginResponse.activeIndividualAccount.notificationSetting
				: newLoginResponse.activeCorporateAccount?.notificationSetting,
			mintAccountPayload: isAccountIndividual
				? newLoginResponse.activeIndividualAccount.mintAccount
				: newLoginResponse.activeCorporateAccount.mintAccount,
		});
		return Promise.resolve();
	};
};

export const keepUserLogged = () => (dispatch) => {
	dispatch({
		type: KEEP_LOGGED_IN,
	});
};

export const logUserOut = () => (dispatch) => {
	sessionStorage.clear();
	removeCookieValues();
	// localStorage.clear();
	return dispatch({
		type: LOGOUT_USER,
	});
};

export const kickCustomerOut = (message) => {
	return (dispatch) => {
		sessionStorage.clear();
		removeCookieValues();
		// localStorage.clear();
		dispatch({
			type: KICK_USER_OUT,
			payload: message,
		});
		// dispatch({ type: CLEAR_REDUX_STORE });
	};
};

export const clearSessionMssg = () => (dispatch) => {
	dispatch({
		type: CLEAR_SESSION_MESSAGE,
	});
};

// used to update transcation Pin created status
export const SetConnection = () => {
	return (dispatch) => {
		dispatch({ type: SET_CONNECTION });
	};
};

export const updateLoginDetails = (loginData) => (dispatch) => {
	dispatch({ type: UPDATE_CUSTOMER_AUTH_INFO, payload: loginData });
	Cookies.set(
		MINTYN_USER_NEW_ACCOUNT,
		JSON.stringify(loginData),
		cookieOptions
	);
};

export const updateIsLoggedIn = (payload) => (dispatch) => {
	if (!payload) removeCookieValues();
	dispatch({ type: UPDATE_IS_LOGGED_IN, payload });
};
