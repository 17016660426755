import React from "react";
import { ReactComponent as WarningIcon } from "../../../../assets/img/tick-circle.svg";

const WarningResponse = ({
	message,
	paragraph,
	btnName = "Yes, Continue",
	handleClick,
	onClose,
	disabled,
}) => {
	return (
		<div className="text-center">
			<WarningIcon />
			<p className="sub-heading text-normal mt-4 mb-0">{message}</p>

			<div className="flex__center py-4">
				{paragraph && (
					<p className="sub-heading text-normal">{paragraph}</p>
				)}
			</div>

			<div className="flex__between mb-4">
				<button className="btn create-acc" onClick={onClose}>
					No
				</button>
				<button
					type="button"
					disabled={disabled}
					className="btn btn-custom-bordered ml-2"
					onClick={handleClick}
				>
					{btnName}
				</button>
			</div>
		</div>
	);
};

export default WarningResponse;
