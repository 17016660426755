import React, { Component } from "react";
import PropTypes from "prop-types";
import { API } from "../../../../../lib/api";
import { getSpendingTagsEndpoint } from "../../../../../lib/api/apiRoutesConfig/services/FundsManagementMS/common/subRoutes";
import "./SpendingTags.scss";

export default class SpendingTags extends Component {
    state = {
        fetching: true,
        tagsList: []
    };

    componentDidMount = async () => {
        try {
            this.mounted = true;
            let res = await API.get(getSpendingTagsEndpoint);
            if (res.status === 200) {
                const tagList = res.data.data;
                let Others = tagList.filter(x => x.name === "Others");
                let ID = Others[0].code;
                this.props.handleTagSelect(ID);
                this.setState({
                    fetching: false,
                    tagsList: tagList
                });
            }
        } catch (err) {}
    };

    componentWillUnmount = () => {
        this.mounted = false;
    };

    handleTag = (e, tagName) => {
        e.preventDefault();
        let ID = e.target.id;
        let allSpendTags = document.querySelectorAll("#spendingTags a");
        allSpendTags.forEach((__, i) => {
            allSpendTags[i].classList.remove("active");
        });
        document.getElementById(ID).classList.add("active");
        this.props.handleTagSelect(ID, tagName);
    };

    render = () => {
        const { tagsList, fetching } = this.state;
        const { currentTag } = this.props;

        if (fetching) return "";

        return (
            <div className='form-group'>
                <label>Tap to tag</label>
								<span className="spending-tags-lead">Stay in control of your spending by tracking where your money is going when you tag any of your transfers.</span>
                <div className='mt-1'>
                    <ul className='spendingTags' id='spendingTags'>
                        {tagsList &&
                            tagsList.map(({ code, name }, i) => (
                                <li key={i}>
                                    <a
                                        href='#!'
                                        className={
                                            "tag " +
                                            (code === currentTag && "active")
                                        }
                                        id={code}
                                        onClick={e => this.handleTag(e, code)}>
                                        {name}
                                    </a>
                                </li>
                            ))}
                    </ul>
                </div>
            </div>
        );
    };
}

SpendingTags.propTypes = {
    handleTagSelect: PropTypes.func.isRequired
};
