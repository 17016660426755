import menuToggleIcon from "../../../assets/img/menu-toggle.svg";
import plusMe from "../../../assets/img/plus-me.svg";
import bigMintPillar from "../../../assets/img/bigmint.svg";
import smallBankPic from "../../../assets/img/small_bank.svg";
import ShieldSvg from "../imageFiles/files/shield.svg";
import crossSvg from "../../../assets/img/x.svg";
import editSvg from "../../../assets/img/edits.svg";
import debitSvg from "../../../assets/img/credit-card-color.svg";
import downloadSvg from "../../../assets/img/download-white.svg";
import walletSvg from "../../../assets/img/wallet.svg";
import umbrelaSvg from "../../../assets/img/umbreala.svg";
import infoSvg from "../../../assets/img/info.svg";
import caretRightSvg from "../../../assets/img/caret-right.svg";
import whitePlusIcon from "../../../assets/img/white_plus_icon.svg";
import instagramIcon from "assets/img/instagram.svg";
import linkedInIcon from "assets/img/linkedIn.svg";

export default {
	menuToggle: menuToggleIcon,
	whitePlus: plusMe,
	whiteDownload: downloadSvg,
	bigMintLogo: bigMintPillar,
	whitePlusIcon: whitePlusIcon,
	icons: {
		copyCMD: `https://res.cloudinary.com/dswxtf2ay/image/upload/v1583076003/Mint_InternetBanking/icons/copy_sk63wc.svg`,
		mintIcon: `https://res.cloudinary.com/dswxtf2ay/image/upload/v1585123383/Mint_InternetBanking/icons/Mint_logo_icon-04_1_wdixwl.svg`,
		smallBank: smallBankPic,
		plusIcon: `https://res.cloudinary.com/dswxtf2ay/image/upload/v1587640725/Mint_InternetBanking/icons/plus_bykrwh.svg`,
		whitePlus: `https://res.cloudinary.com/dswxtf2ay/image/upload/v1587845235/Mint_InternetBanking/icons/plus_1_euc9a5.svg`,
		downloadIcon: `https://res.cloudinary.com/dswxtf2ay/image/upload/v1587845224/Mint_InternetBanking/icons/download_qhbymc.svg`,
		faceBookIcon: `https://res.cloudinary.com/dswxtf2ay/image/upload/v1591803725/Mint_InternetBanking/icons/facebook_qjp8oz.svg`,
		twitterIcon: `https://res.cloudinary.com/dswxtf2ay/image/upload/v1591803717/Mint_InternetBanking/icons/twitter_1_nfweqp.svg`,
		whatsappIcon: `https://res.cloudinary.com/dswxtf2ay/image/upload/v1591803723/Mint_InternetBanking/icons/whatsapp_slkeqb.svg`,
		instagramIcon,
		linkedInIcon,
		crossIcon: crossSvg,
		editIcon: editSvg,
		debitCard: debitSvg,
		walletIcon: walletSvg,
		umbrealaIcon: umbrelaSvg,
		infoIcon: infoSvg,
		caretRightIcon: caretRightSvg,
	},
	illustrations: {
		localBank: `https://res.cloudinary.com/dswxtf2ay/image/upload/v1583075363/Mint_InternetBanking/illustrations/local-bank_aqsn9k.svg`,
		yourCard: `https://res.cloudinary.com/dswxtf2ay/image/upload/v1583075515/Mint_InternetBanking/illustrations/your-card_lb389m.svg`,
		smileMoon: `https://res.cloudinary.com/dswxtf2ay/image/upload/v1587899005/Mint_InternetBanking/illustrations/Group_3_mdmqku.svg`,
		theBagAlert: `https://res.cloudinary.com/dswxtf2ay/image/upload/v1590586205/Mint_InternetBanking/illustrations/Group_34_ib1k7s.svg`,
		hangIdCard: `https://res.cloudinary.com/dswxtf2ay/image/upload/v1590587006/Mint_InternetBanking/illustrations/id-card_gfwli5.svg`,
		ShieldIllustration: ShieldSvg,
	},
};
