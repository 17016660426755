import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import SuccessImage from "../../../utils/SuccessImage";
import FailedImage from "../../../utils/FailedImage";
import PendingImage from "./files/pending.svg";
import { ReactSVG } from "react-svg";

export const successResponseStatus = "SUCCESS";
export const failureResponseStatus = "FAILED";
export const pendingResponseStatus = "PENDING";

const renderByStatus = (status) => {
	switch (status) {
		case successResponseStatus:
			return <SuccessImage />;
		case failureResponseStatus:
			return <FailedImage />;
		case pendingResponseStatus:
			return (
				<div className="text-center">
					<ReactSVG src={PendingImage} />
				</div>
			);
		default:
			break;
	}
};

const OperationStatus = ({ handleTryAgain, statusObj }) => {
	const {
		status,
		statusMessage,
		successLink,
		successLinkText,
		handleSuccessLink,
	} = statusObj || {};
	return (
		<div className="modal-body">
			<div className="py-5">
				{renderByStatus(status)}
				<div className="smile mt-3">
					{status === successResponseStatus && (
						<h6>Congratulations</h6>
					)}
					<p>{statusMessage} </p>
				</div>

				<div className="row justify-content-center">
					<div className="col-md-5">
						{status === successResponseStatus ||
						status === pendingResponseStatus ? (
							<Link
								className="btn create-acc mt-3 mb-3"
								onClick={handleSuccessLink}
								to={successLink}
							>
								{successLinkText}
							</Link>
						) : (
							<button
								className="btn create-acc mt-3 mb-3"
								onClick={handleTryAgain}
							>
								Try Again
							</button>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

OperationStatus.propTypes = {
	handleTryAgain: PropTypes.func,
	handleSuccessLink: PropTypes.func,
	statusObj: PropTypes.object.isRequired,
};

export default OperationStatus;
