import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { ToastsContainer, ToastsStore } from "react-toasts";
import Home from "./components/Home";
import Wave from "./components/utils/Wave";
import NetworkDetector from "./components/utils/NetworkDetector";
import { ProtectedRoute } from "./routes/ProtectedRoute";
import PublicRoute from "./routes/PublicRoute";
import { updateColorScheme } from "./actions/styleActions";
// import { retrieveColorScheme } from "./storage/localStorage";
import RegistrationView from "./components/pages/RegistrationView/RegistrationView";
import Login from "./components/auth/Login/Login";
import Onboarding from "./components/customers/Onboarding/Onboarding";
import ForgotPasswordWrapper from "./components/auth/ForgotPassword/ForgotPasswordWrapper";
import ResetSecurityDetailsWrapper from "./components/auth/ResetSecurityDetails/ResetSecurityDetailsWrapper";
import MintTradeOrderConfirmation from "./components/common/MintTradeOrderConfirmation/MintTradeOrderConfirmation";
import WaybillConfirmation from "./components/common/WaybillConfirmation/WaybillConfirmation";
import RegisterBusiness from "components/registerBusiness";
import DeactivateAccountView from "components/pages/DeactivateAccountView/DeactivateAccountView";
import NCTOCardActivation from "components/nctoCardActivation";

const NotFound = () => <h1>page not found</h1>;

class App extends Component {
	componentDidMount = () => {
		this.handleColorSchemeDetection();
	};

	componentDidUpdate = (prevProps) => {
		if (prevProps.theme !== this.props.theme) {
			this.handleColorSchemeDetection();
			// console.log("Theme", this.props.theme);
		}
	};

	handleColorSchemeDetection = () => {
		// try {
		// 	// const persitedColorScheme = retrieveColorScheme();
		// 	const persitedColorScheme = localStorage.getItem("colorScheme");
		// 	const { updateColorScheme } = this.props;
		// 	if (persitedColorScheme) {
		// 		updateColorScheme(persitedColorScheme);
		// 	}
		// } catch (err) {
		// 	// console.log(err);
		// }
		const persistedColorScheme = localStorage.getItem("colorScheme");
		const { updateColorScheme } = this.props;
		if (persistedColorScheme) {
			updateColorScheme(persistedColorScheme);
		}
	};

	render() {
		const { theme, isLoggedIn } = this.props;

		return (
			<>
				<div className={`${isLoggedIn ? "mintBank " + theme : ""}`}>
					{isLoggedIn && <Wave />}
					<Router>
						<Switch>
							<PublicRoute
								exact
								path="/sign-up"
								component={RegistrationView}
							/>
							<PublicRoute path="/sign-in" component={Login} />

							<PublicRoute
								path="/onboard"
								component={Onboarding}
							/>

							<PublicRoute
								path="/forgot-password"
								component={ForgotPasswordWrapper}
							/>

							<PublicRoute
								path="/reset-security-details"
								component={ResetSecurityDetailsWrapper}
							/>

							<PublicRoute
								exact
								path="/waybill-confirmation"
								component={WaybillConfirmation}
							/>

							<PublicRoute
								path="/register-business/"
								component={RegisterBusiness}
							/>

							<PublicRoute
								path="/ncto-card-activation"
								component={NCTOCardActivation}
							/>

							<PublicRoute
								path="/deactivate-account"
								component={DeactivateAccountView}
							/>

							<Route
								exact
								path="/order-confirmation"
								component={MintTradeOrderConfirmation}
							/>
							<ProtectedRoute exact component={Home} />

							<Route exact path="*" render={() => <NotFound />} />
						</Switch>
					</Router>
					<NetworkDetector />
				</div>
				<ToastsContainer store={ToastsStore} lightBackground />
			</>
		);
	}
}

export default connect(
	(state) => ({
		theme: state.style.theme,
		isLoggedIn: state.auth.isLoggedIn,
	}),
	{ updateColorScheme }
)(App);
