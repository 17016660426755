import React, { Component } from "react";
import { connect } from "react-redux";
import { updateCustomerTransferLimit } from "../../../../../../../../../actions/settingsActions";
import CurrencyInput from "../../../../../../../utils/CurrencyInput/CurrencyInput";

class MintOthersLimit extends Component {

    state = {
        setting: true,
        spendingLimit: {},
    };

    componentDidMount = () => {
        const {
            spendingLimit: { interBankBulletLimit, interBankDailyLimit },
        } = this.props.mintAccount;
        this.setState({
            spendingLimit: {
                bulletLimit: interBankBulletLimit,
                dailyLimit: interBankDailyLimit,
            },
            setting: false,
        });
    };

    handleValueChange = (value, name) => {
        this.setState((prevState) => ({
            spendingLimit: {
                ...prevState.spendingLimit,
                [name]: value,
            },
        }));
    };

    handleSubmit = e => {
        e.preventDefault();
        const {spendingLimit} = this.state;
        const {nextStep} = this.props
        nextStep(spendingLimit);
    }

    canBeSubmitted = () => {
        const { dailyLimit, bulletLimit } = this.state.spendingLimit;
        return dailyLimit === 0 || bulletLimit === 0;
    };

    render() {
        const { toggleModal } = this.props;
        const { setting, spendingLimit } = this.state;
        const { bulletLimit, dailyLimit } = spendingLimit;
        const isDisabled = this.canBeSubmitted();

        if (setting) return "";

        return (
            <form onSubmit={this.handleSubmit}>
                <div>
                    <div className='form-group'>
                        <label>Bullet Limit (N)</label>
                        <CurrencyInput
                            max={5000000}
                            onValueChange={(val) =>
                                this.handleValueChange(val, "bulletLimit")
                            }
                            value={bulletLimit}
                        />
                    </div>
                    <div className='form-group'>
                        <label>Daily Limit (N)</label>
                        <CurrencyInput
                            max={5000000}
                            onValueChange={(val) =>
                                this.handleValueChange(val, "dailyLimit")
                            }
                            value={dailyLimit}
                        />
                    </div>
                </div>
                <div className='dialog-footer mt-3 mb-4'>
                    <div className='row'>
                        <div className='col-6'>
                            <button
                                className='btn cancel'
                                data-dismiss='modal'
                                type='button'
                                aria-label='Close'
                                onClick={toggleModal}>
                                CANCEL
                            </button>
                        </div>

                        <div className='col-6'>
                            <button
                                className='btn create-acc'
                                type='submit'
                                disabled={isDisabled}>
                                Proceed
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

export default connect(
    (state) => ({ mintAccount: state.settings.mintAccount }),
    { updateCustomerTransferLimit }
)(MintOthersLimit);
