import React from "react";
import Banner from "../../../../../../assets/img/Banner.svg";

export const HeroBanner = () => {
	return (
		<>
			<div className=" flex w-100">
				<img
					src={Banner}
					width="100%"
					height="auto"
					alt="Buy now, Pay later"
				/>
			</div>
		</>
	);
};
