export default {
    name: `/trade-service`,
    version: `/api/v1`,
    get default() {
        return this.name + this.version;
    },
};



export const dashboardV3 = {
    name: `/trade-service`,
    version: `/api/v3`,
    get default() {
        return this.name + this.version;
    },
};