import React from "react";
import { useState } from "react";
import "../../MarketPlace.scss";
import { MktTopNav } from "../utils/MktTopNav";
import BackButton from "components/utils/BackButton/BackButton";
import { API } from "lib/api"; 

import { getAllProducts } from "lib/api/apiRoutesConfig/services/tradeMS/individualTrade/subRoutes";
import { ToastsStore } from "react-toasts";
import { getErrorMessage } from "components/utils/helpers";
import { useEffect } from "react";
import { ERROR, FINISHED, IDLE, LOADING } from "config/enums";
import ProductsList from "../ProductsList/ProductsList";
import useFetchCart from "../utils/hooks/useFetchCart";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { searchProduct } from "actions/marketPlace";

const SearchProducts = () => {
  const location = useLocation();
	const history = useHistory()
	const dispatch = useDispatch()
 
	const { searchTerm,cartType} = location.state || {};

  const [productStatus, setProductStatus] = useState(IDLE);
   const [products, setProducts] = useState(IDLE);
	const [productsErrorMsg, setProductsErrorMsg] = useState("");
	const [productsPage, setProductsPage] = useState(0);

	const { handleFetchCart } = useFetchCart();

	// fetch all categories
	const fetchAllProducts = async () => {
		setProductStatus(LOADING);
		setProductsErrorMsg("");
		try {
			const response = await API.get(getAllProducts, {
				params: {
					searchTerm,
					page: productsPage,
					size: 10,
				},
			});
			const { data, status } = response || {};
      if (status === 200) {
        console.log(data.data)
				setProducts(data.data);
				setProductStatus(FINISHED);
			}
		} catch (err) {
			setProducts(null);
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
			setProductStatus(ERROR);
			setProductsErrorMsg(getErrorMessage(err));
		}
	};

	console.log(cartType)
	useEffect(() => {
	  fetchAllProducts()
		handleFetchCart(cartType);
		 dispatch(searchProduct(''))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchTerm]);

	return (
		<>
			<div className="pb-5 market-place d-flex flex-column">
				<MktTopNav cartType={cartType} />
					<div className="mkt-back-button">
						<BackButton
							label="Home"
							 onClick={() =>
          history.push(
            "/market-place/personal-shopping"
          )
        }
						/>
					</div>
				<div className="d-flex flex-row mkt-home-wrapper">
					<div className="d-flex flex-grow-1 flex-column ml-3 mkt-home-products">
							<ProductsList
								products={products}
								retry={fetchAllProducts}
								errorMsg={productsErrorMsg}
								currentStatus={productStatus}
								page={productsPage}
								cartType={cartType}
								goToPage={(page) => setProductsPage(page)}
							/>
					</div>
				</div>
			</div>
		</>
	);
};

export default SearchProducts
