import React from "react";
import CaretLeft from "assets/img/caret_left_gold.svg";

export default function BackButton({ onClick, label = "Back" }) {
	return (
		<button
			className="back-btn d-flex align-items-center"
			onClick={onClick}
		>
			<img src={CaretLeft} alt="" />
			<span>{label}</span>
		</button>
	);
}
