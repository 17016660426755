import React from "react";
import { formatCurrency } from "../../../../../utils/helpers";

const SingleCompletedInvestment = ({ investment, count }) => {
	return (
		<div className="investment-card completed-card">
			<ul>
				<li>
					<h6 className="text--main font-weight-bold">
						Investment #{count}
					</h6>
				</li>
				<li>
					<div>
						<p>Total Returns</p>
						<h4 className="text--main">
							{formatCurrency(investment?.totalExpectedReturn)}
						</h4>
					</div>
					<div>
						<p>Total Invested</p>
						<h4 className="text--main text-right">
							{formatCurrency(investment?.amountInvested)}
						</h4>
					</div>
				</li>

				<li>
					<div>
						<p>Duration</p>
						<h4>
							{investment.durationInMonths} month
							{investment.durationInMonths > 1 && "s"}
						</h4>
					</div>
					<div>
						<p>Matured on</p>
						<h4>{investment?.maturityDate}</h4>
					</div>
				</li>
			</ul>
		</div>
	);
};

export default SingleCompletedInvestment;
