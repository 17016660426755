import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { InvestmentContext } from "../../CreateInvestment";
import { API } from "../../../../../../../lib/api";
import investments from "../../../../../../../lib/api/apiRoutesConfig/services/SavingsMS/investments";
import InvestmentPreviewModal from "../../../modals/InvestmentPreviewModal/InvestmentPreviewModal";
import {
	extendDateWithMonth,
	formatCurrency,
	getErrorMessage,
	NumberCurrency,
} from "../../../../../../utils/helpers";
import { getInvestmentEstReturn } from "../../../extra";

const InvestmentPreview = () => {
	const { formValues } = useContext(InvestmentContext);

	const [showPinModal, setShowPinModal] = useState(false);
	const [loading, setLoading] = useState();
	const [errorMsg, setErrorMsg] = useState();
	const [successData, setSuccessData] = useState();

	const {
		dashboardResponse: { bankAccounts },
	} = useSelector(({ dashboard }) => dashboard);

	const { accountId } = bankAccounts?.length ? bankAccounts[0] : {};
	const investmentAmount = NumberCurrency(formValues?.investmentAmount);
	const { durationInMonths, durationId } = formValues || {};

	const toggleModal = () => setShowPinModal((prev) => !prev);

	const getPayload = (transactionPin) => ({
		...formValues,
		debitAccountId: accountId,
		durationId: durationId.durationId,
		durationInMonths: formValues?.durationInMonths.value,
		investmentAmount,
		transactionPin,
	});

	const createInvestment = async (pin) => {
		try {
			setErrorMsg();
			setLoading(true);

			const payload = getPayload(pin);

			const res = await API.post(investments, payload);

			if (res?.status === 200) {
				setLoading(false);
				setSuccessData({
					message: res.data?.message,
					data: res.data?.data?.investment,
				});
			} else setError(res.data?.message);
		} catch (err) {
			setError(getErrorMessage(err));
		}
	};

	const setError = (msg) => {
		setLoading(false);
		setErrorMsg(msg);
	};

	const { profit, tax } = getInvestmentEstReturn(
		investmentAmount,
		durationId?.interestRate,
		durationInMonths?.value
	);

	const toggle = () => {
		setErrorMsg();
		toggleModal();
	};

	return (
		<div>
			<div className="w-100 my-4">
				<h3 className="sub-heading m-0">Preview Investment</h3>
				<span className="tiny__message m-0 font-weight-normal">
					Summary of your investment details
				</span>

				<ul className="mt-4">
					<li className="border-bottom">
						<h4>INVEST</h4>
						<h4 className="text-success">
							{formatCurrency(investmentAmount)}
						</h4>
					</li>
					<li>
						<div>
							<p>Profit Returns</p>
							<h4>{formatCurrency(profit)}</h4>
						</div>
						<div>
							<p>Percent</p>
							<h4>{durationId.interestRate}% P/A</h4>
						</div>
					</li>
					<li>
						<div>
							<p>Duration</p>
							<h4>
								{durationInMonths?.value} month
								{durationInMonths?.value > 1 && "s"}
							</h4>
						</div>
						<div>
							<p>Maturity Date</p>
							<h4>
								{extendDateWithMonth(durationInMonths?.value)}
							</h4>
						</div>
					</li>
					<li>
						<div>
							<p>Withholding Tax</p>
							<h4>{formatCurrency(tax)}</h4>
						</div>
					</li>
				</ul>
			</div>

			<button className="btn create-acc" onClick={toggle}>
				Proceed
			</button>

			<InvestmentPreviewModal
				showPinModal={showPinModal}
				successData={successData}
				errorMsg={errorMsg}
				loading={loading}
				toggleModal={toggle}
				handleSubmit={createInvestment}
				clearErrorMsg={() => setErrorMsg()}
			/>
		</div>
	);
};

export default InvestmentPreview;
