import React from "react";

const FormInputGroup = ({
	label,
	type = "text",
	id,
	name,
	placeholder = "",
	value,
	className = "",
	readonly,
	error,
	max,
	min,
	onFocus,
	onChange,
	required,
	...rest
}) => {
	return (
		<div>
			{label && <label htmlFor={id}>{label}</label>}
			<input
				type={type}
				id={id}
				name={name}
				placeholder={placeholder}
				value={value}
				className={`form-control ${
					error ? "has-error" : ""
				} ${className}`}
				readOnly={readonly}
				autoComplete="off"
				maxLength={max}
				minLength={min}
				onFocus={onFocus}
				onChange={onChange}
				required={required}
				{...rest}
			/>
			{error && <span className="error-msg">{error}</span>}
		</div>
	);
};

export default FormInputGroup;
