/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "../TopUp.scss";
import { ReactComponent as MtnLogo } from "assets/img/mtn_logo.svg";
import { ReactComponent as NineMobileLogo } from "assets/img/9mobile_logo.svg";
import { ReactComponent as GloLogo } from "assets/img/glo_logo.svg";
import { ReactComponent as AirtelLogo } from "assets/img/airtel_logo.svg";
import { Formik } from "formik";
import * as yup from "yup";
import { useState, useEffect } from "react";
import { beneficiaryTypeEnum } from "config/enums";
import CustomCurrencyInput from "components/utils/CustomCurrencyInput/CustomCurrencyInput";
import {
	getErrorMessage,
	getValidMobileNumberLength,
	parseAmount,
} from "components/utils/helpers";
import FormInputGroup from "components/utils/FormInputGroup/FormInputGroup";
import { ToastsStore } from "react-toasts";
import { API } from "lib/api";
import {
	getAirtimeBillers,
	getBillerItemsbyVasId,
} from "lib/api/apiRoutesConfig/services/BillsService/subRoutes";
import { MintInfinite } from "components/dashboard/utils/ShineLoader/ShineLoader";
import CommonSelect from "components/utils/CommonSelect/CommonSelect";
import { useSelector } from "react-redux";
import AirtimeSummary from "./AirtimeSummary/AirtimeSummary";

const formValidationSchema = yup.object().shape({
	customerId: yup.string().required("Recipient phone number is required"),
	amount: yup
		.number()
		.required("Amount is required")
		.min(100, "Minimum amount is ₦100")
		.max(20000, "Maximum amount is ₦20,000"),
});

export default function Airtime() {
	const [isBillerLoading, setIsBillerLoading] = useState(false);
	const [airtimeBillers, setAirtimeBillers] = useState([]);
	const [selectedBiller, setSelectedBiller] = useState({});
	const [transaction, setTransaction] = useState(null);
	const [isSummaryShown, setIsSummaryShown] = useState(false);
	const [billMerchants, setBillMerchants] = useState([]);

	const currentUser = useSelector((state) => state.auth.currentUser);
	const { phoneNumber } = currentUser || {};

	const initialFormValues = {
		customerId: phoneNumber || "",
		amount: "",
		beneficiaryType: { label: "For Myself", value: "SELF" },
	};

	// fetch airtime merchants
	const fetchAirtimeMerchants = async () => {
		setIsBillerLoading(true);
		try {
			const response = await API.get(getAirtimeBillers);
			const { status, data } = response || {};
			if (status === 200) setAirtimeBillers(data.data);
		} catch (err) {
			setAirtimeBillers([]);
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		} finally {
			setIsBillerLoading(false);
		}
	};

	// fetch bill merchants by vasId of airtime biller selected
	const fetchBillMerchants = async () => {
		try {
			const response = await API.get(
				getBillerItemsbyVasId(selectedBiller.vasBillerId)
			);
			const { data, status } = response || {};
			if (status === 200) setBillMerchants(data.data);
		} catch (err) {
			setBillMerchants([]);
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		}
	};

	useEffect(() => {
		fetchAirtimeMerchants();
	}, []);

	useEffect(() => {
		if (selectedBiller.vasBillerId) fetchBillMerchants();
	}, [selectedBiller]);

	if (isBillerLoading) return <MintInfinite relative />;

	return (
		<div>
			<Formik
				enableReinitialize
				initialValues={initialFormValues}
				validationSchema={formValidationSchema}
				onSubmit={(values) => {
					setTransaction({
						...values,
						vasBillerId: billMerchants[0].vasItemId,
						biller: selectedBiller.name.replace("VTU", ""),
					});
					setIsSummaryShown(true);
				}}
			>
				{({
					values,
					errors,
					touched,
					handleSubmit,
					setFieldValue,
					handleChange,
					handleBlur,
				}) => {
					return (
						<form
							className="top-up-form"
							onSubmit={(e) => {
								e.preventDefault();
								handleSubmit();
							}}
						>
							<h3 className="top-up-panel__header">
								Choose Mobile Network
							</h3>
							<div className="top-up-panel__logos mb-4">
								{airtimeBillers.map((item, index) => {
									const isActive =
										item.code === selectedBiller.code;
									// TO-DO: add codes for prod.
									const icons = {
										6001: <MtnLogo />,
										6003: <NineMobileLogo />,
										6005: <GloLogo />,
										6007: <AirtelLogo />,
									};

									return (
										<div
											key={index}
											role="button"
											className={`top-up-panel-logo ${
												isActive ? "selected" : ""
											}`}
											onClick={() => {
												setSelectedBiller(item);
												setFieldValue("amount", "");
											}}
										>
											{icons[Number(item.code)]}
										</div>
									);
								})}
							</div>

							<div className="mb-4">
								<label htmlFor="beneficiaryType">
									Who are you buying for?
								</label>
								<CommonSelect
									name="beneficiaryType"
									value={values.beneficiaryType}
									handleChange={(value) => {
										if (
											value.value ===
											values.beneficiaryType.value
										) {
											return;
										}

										setFieldValue("beneficiaryType", value);
										setFieldValue(
											"customerId",
											value.value ===
												beneficiaryTypeEnum.SELF
												? phoneNumber
												: ""
										);
									}}
									options={[
										{ label: "For Myself", value: "SELF" },
										{
											label: "For Others",
											value: "OTHERS",
										},
									]}
								/>
							</div>

							<div className="mb-4">
								<FormInputGroup
									id="customerId"
									label="Recipient Number"
									name="customerId"
									placeholder="Enter Phone Number"
									className="topup-input"
									onBlur={handleBlur}
									disabled={
										values.beneficiaryType.value ===
										beneficiaryTypeEnum.SELF
									}
									value={values.customerId}
									onChange={(e) => {
										const value = e.target.value
											.trim()
											.replace(/[^+0-9]/i, "");
										if (
											value.length <=
											getValidMobileNumberLength(value)
										) {
											setFieldValue(e.target.name, value);
										}
									}}
									error={
										touched.customerId && errors.customerId
									}
								/>
							</div>

							<div className="mb-4">
								<CustomCurrencyInput
									className="topup-input"
									name="amount"
									label="Amount"
									id="amount"
									value={values.amount}
									onBlur={handleBlur}
									placeholder="Enter Amount"
									error={touched.amount && errors.amount}
									disabled={billMerchants.length === 0}
									onChange={(value) => {
										setFieldValue(
											"amount",
											parseAmount(value)
										);
									}}
								/>
							</div>

							<button
								className="btn btn-mint-default mt-2"
								type="submit"
								disabled={
									Object.values(selectedBiller).length ===
										0 || billMerchants.length === 0
								}
							>
								Continue
							</button>
						</form>
					);
				}}
			</Formik>

			<AirtimeSummary
				show={isSummaryShown && !!transaction}
				transaction={transaction}
				onClose={() => {
					setIsSummaryShown(false);
					setTransaction(null);
				}}
			/>
		</div>
	);
}
