export const {
	validateCardInfo,
	confirmPhoneNumber,
	verifyPhoneNumber,
	resendCode,
	activateCard,
} = {
	validateCardInfo: "/card-service/api/v1/ncto-card-activation/card-info",
	confirmPhoneNumber: (sessionId) =>
		`/card-service/api/v1/ncto-card-activation/${sessionId}/confirm-phone-number`,
	verifyPhoneNumber: (sessionId) =>
		`/card-service/api/v1/ncto-card-activation/${sessionId}/verify-phone-number`,
	resendCode: (sessionId) =>
		`/card-service/api/v1/ncto-card-activation/${sessionId}/resend-code`,
	activateCard: "/card-service/api/v1/ncto-card-activation/activate-card",
};
