import "./DeliveryAddressPanel.scss";
import React, { useEffect } from "react";
import Modal from "components/utils/Modal/Modal";
import ModalHeader from "components/utils/Modal/CustomModalHeader/CustomModalHeader";
import { Formik } from "formik";
import { ReactSVG } from "react-svg";
import PlusIcon from "assets/img/plus-icon-white.svg";
import { Label } from "reactstrap";
import CommonSelect from "components/utils/CommonSelect/CommonSelect";
import FormInputGroup from "components/utils/FormInputGroup/FormInputGroup";
import CustomSwitch from "components/dashboard/utils/CustomSwitch/CustomSwitch";
import * as yup from "yup";
import { useState } from "react";
import { ToastsStore } from "react-toasts";
import { getErrorMessage,getValidMobileNumberLength } from "components/utils/helpers";
import { API } from "lib/api";
import {
	getAreas,
	getStates,
} from "lib/api/apiRoutesConfig/services/tradeMS/common/subRoutes";
import { SmallLoader } from "components/utils/SmallLoader";
import { deliveryAddressAction } from "lib/api/apiRoutesConfig/services/tradeMS/individualTrade/subRoutes";

const formValidationSchema = yup.object().shape({
	areaId: yup
		.object().nullable()
		.required("LGA is required")
		.shape({ label: yup.string(), value: yup.mixed() }),
	address: yup.string().required("Full address is required"),
	closestLandmark: yup.string().required('Closest landmark is required'),
	contactPhoneNumber: yup.string().min(11, 'Invalid phone number!').required('Phone number is required'),
	defaultAddress: yup.boolean().default(true),
	latitude: yup.string(),
	longitude: yup.string(),
	state: yup
		.object()
		.nullable().required("State is required")
		.shape({ label: yup.string(), value: yup.string() }),
});

export default function AddNewAddress({ show, onClose, refetch }) {
	return (
		<Modal show={show} scrollable>
			<ModalHeader
				heading="Add New Address"
				className="p-0"
				headerClassName="setup-modals-header px-sm-5 px-3 py-3"
				dismissable={() => onClose?.()}
			/>
			<NewAddressForm refetch={refetch} />
		</Modal>
	);
}

const NewAddressForm = ({ refetch }) => {
	const [statesLoading, setStatesLoading] = useState(false);
	const [states, setStates] = useState([]);
	const [lgaLoading, setLgaLoading] = useState(false);
	const [lga, setLga] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const initialFormValues = {
		address: "",
		areaId: null,
		closestLandmark: "",
		contactPhoneNumber: "",
		defaultAddress: true,
		latitude: "",
		longitude: "",
		state: null,
	};

	const fetchStates = async () => {
		setStatesLoading(true);
		try {
			const res = await API.get(getStates);
			const { status, data } = res || {};
			if (status === 200) {
				setStates(data.data);
			}
		} catch (err) {
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		} finally {
			setStatesLoading(false);
		}
	};

	const fetchLGA = async (stateCode) => {
		setLgaLoading(true);
		try {
			const res = await API.get(getAreas(stateCode));
			const { status, data } = res || {};
			if (status === 200) {
				setLga(data.data);
			}
		} catch (err) {
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		} finally {
			setLgaLoading(false);
		}
	};

	const handleSubmit = async (values) => {
		setIsLoading(true);
		try {
			const res = await API.post(deliveryAddressAction, {
				...values,
				state: undefined, // not needed for submission
				areaId: values.areaId?.value.id,
			});
			const { status, data } = res || {};
			if (status === 200) {
				ToastsStore.success(data.message, 6000, "right-toast");
				refetch();
			}
		} catch (err) {
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchStates();
	}, []);

	return (
		<Formik
			initialValues={initialFormValues}
			validationSchema={formValidationSchema}
			onSubmit={(values) => handleSubmit(values)}
		>
			{({
				values,
				errors,
				touched,
				handleSubmit,
				setFieldValue,
				handleChange,
				handleBlur,
			}) => {
				return (
					<form
						className="mkt-address-form px-sm-5 px-3 pt-4 pb-5 row"
						onSubmit={(e) => {
							e.preventDefault();
							handleSubmit();
						}}
					>
						<div className="mb-4 col-12">
							<Label for="state">State of residence</Label>
							<CommonSelect
								name="state"
								placeholder="Select State"
								error={touched.state && errors.state}
								onBlur={handleBlur}
								handleChange={(state) => {
									setFieldValue("state", state);
									fetchLGA(state.value);
								}}
								options={states.map((state) => ({
									label: state?.name,
									value: state?.code,
								}))}
								showErrorMsg
								isLoading={statesLoading}
							/>
						</div>

						<div className="mb-4 col-12">
							<Label for="areaId">Area/ LGA</Label>
							<CommonSelect
								isSearchable
								name="areaId"
								placeholder="Select LGA"
								error={touched.areaId && errors.areaId}
								value={values.areaId}
								onBlur={handleBlur}
								handleChange={(value) =>
									setFieldValue("areaId", value)
								}
								options={lga.map((item) => ({
									label: item?.name,
									value: item,
								}))}
								showErrorMsg
								isLoading={lgaLoading}
							/>
						</div>

						<div className="mb-4 col-12">
							<FormInputGroup
								id="address"
								placeholder="No 3 idejo close"
								name="address"
								label="Address Line"
								className="mkt-address-modal-input"
								onBlur={handleBlur}
								value={values.address}
								onChange={handleChange}
								error={touched.address && errors.address}
							/>
						</div>

						<div className="mb-4 col-12">
							<FormInputGroup
								id="closestLandmark"
								label="Closest Landmark"
								name="closestLandmark"
								placeholder="Beside NNPC fuel station"
								className="mkt-address-modal-input"
								onBlur={handleBlur}
								onChange={handleChange}
								error={
									touched.closestLandmark &&
									errors.closestLandmark
								}
							/>
						</div>

						<div className="mb-4 col-12">
							<FormInputGroup
								id="contactPhoneNumber"
								label="Contact Number"
								name="contactPhoneNumber"
								placeholder="08023311233"
								className="mkt-address-modal-input"
								onBlur={handleBlur}
								value={values.contactPhoneNumber}
								onChange={(e) => {
										const value = e.target.value
											.trim()
											.replace(/[^+0-9]/i, "");
										if (
											value.length <=
											getValidMobileNumberLength(value)
										) {
											setFieldValue(e.target.name, value);
										}
									}}
								error={
									touched.contactPhoneNumber &&
									errors.contactPhoneNumber
								}
							/>
						</div>

						<div className="mb-4 mkt-form-switch col-12">
							<p>Save as default Shipping Address</p>
							<div>
								<CustomSwitch
									className="es-switch"
									inputName="defaultAddress"
									isChecked={values.defaultAddress}
									handleCheckBox={() =>
										setFieldValue(
											"defaultAddress",
											!values.defaultAddress
										)
									}
								/>
							</div>
						</div>

						<div className="col-12">
							<button
								className="btn btn-mint-default new-address-btn"
								type="submit"
								disabled={isLoading}
							>
								{isLoading ? (
									<SmallLoader />
								) : (
									<>
										<ReactSVG src={PlusIcon} />
										Add new Address
									</>
								)}
							</button>
						</div>
					</form>
				);
			}}
		</Formik>
	);
};
