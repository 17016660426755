import React from "react";
import PropTypes from "prop-types";
import TableHead from "./CustomTableHead/CustomTableHead";
import TableBody from "./CustomTableBody/CustomTableBody";
import "./CustomTable.css";

const CustomTable = ({ headers, bodyData, className, tableId }) => {
    return (
        <div className='table-responsive'>
            <table className={`table table-bordered ${className}`} id={tableId}>
                <TableHead headers={headers} />
                <TableBody bodyData={bodyData} />
            </table>
        </div>
    );
};

CustomTable.propTypes = {
    headers: PropTypes.array.isRequired,
    bodyData: PropTypes.array.isRequired,
    className: PropTypes.string,
    tableId: PropTypes.string,
};

export default CustomTable;
