/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Modal from "components/utils/Modal/Modal";
import ModalHeader from "components/utils/Modal/CustomModalHeader/CustomModalHeader";
import FormInputGroup from "components/utils/FormInputGroup/FormInputGroup";
import { API } from "lib/api";
import { resolveAccountSplitpayment } from "lib/api/apiRoutesConfig/services/BillsService/subRoutes";
import { getTransferBeneficiaries } from "lib/api/apiRoutesConfig/services/FundsManagementMS/subRoutes";
import { getErrorMessage } from "components/utils/helpers";
import { ToastsStore } from "react-toasts";
import { SmallLoader } from "components/utils/SmallLoader";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { setParticipants } from "actions/splitPayment";
import { RECIPIENT, CUSTOM, ACCOUNT, BENEFICIARIES } from "config/enums";
import CustomCurrencyInput from "components/utils/CustomCurrencyInput/CustomCurrencyInput";
import { parseAmount, formatCurrency } from "components/utils/helpers";
import CommonSelect from "components/utils/CommonSelect/CommonSelect";
import { Label } from "reactstrap";

const ParticipantsData = ({ show, onClose, nextModal, sum, bal }) => {
	return (
		<>
			<Modal show={show} scrollable>
				<ModalHeader
					heading={"Participant Data"}
					className="p-0"
					headerClassName="setup-modals-header px-sm-5 px-3 py-3"
					dismissable={() => onClose?.()}
				/>
				<Content
					nextModal={nextModal}
					onClose={onClose}
					sum={sum}
					bal={bal}
				/>
			</Modal>
		</>
	);
};
export default ParticipantsData;

const Content = ({ nextModal, onClose, sum, bal }) => {
	const dispatch = useDispatch();

	const { participants, splitPayment } = useSelector(
		(state) => state.splitPayment
	);
	const [isLoading, setIsLoading] = useState(false);
	const [participantType, setParticipantType] = useState(ACCOUNT);
	const [beneficiaries, setBeneficiaries] = useState([]);
	const [beneficiariesLoading, setBeneficiariesLoading] = useState(false);

	// Validation Schema
	const formValidationSchema = yup.object().shape({
		accountNumber:
			participantType === ACCOUNT
				? yup.string().required("Is required")
				: yup.string(),
		amount:
			splitPayment.splitPaymentType === CUSTOM
				? yup.string().required("Is required")
				: yup.string(),
		beneficiary:
			participantType === BENEFICIARIES
				? yup
						.object()
						.nullable()
						.required(" Is required")
						.shape({ label: yup.string(), value: yup.string() })
				: yup
						.object()
						.nullable()
						.shape({ label: yup.string(), value: yup.string() }),
	});

	const initialFormValues = {
		accountNumber: "",
		amount: "",
		beneficiary: null,
	};

	// Fetch states
	const fetchBeneficiaries = async () => {
		setBeneficiariesLoading(true);
		try {
			const res = await API.get(getTransferBeneficiaries, {
				params: {
					type: "samebank",
				},
			});
			const { status, data } = res || {};
			if (status === 200) {
				setBeneficiaries(data.data);
			}
		} catch (err) {
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		} finally {
			setBeneficiariesLoading(false);
		}
	};

	// Handle form submit
	const handleSubmit = async (values) => {
		setIsLoading(true);
		try {
			if (participantType === ACCOUNT) {
				const res = await API.get(resolveAccountSplitpayment, {
					params: { accountNumber: values.accountNumber },
				});
				const { status, data } = res || {};
				if (status === 200) {
					checkparticipantExist(values, data);
				}
				onClose();
			} else {
				checkBeneficiaryExist(values);
				onClose();
			}
		} catch (err) {
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		} finally {
			setIsLoading(false);
		}
	};

	// Check if participant exist
	const checkparticipantExist = (values, data) => {
		const list = participants.filter(
			(participant, index) =>
				participant.accountNumber === data.data.accountNumber
		);
		if (list.length > 0) {
			ToastsStore.error("Participant already exist", 6000, "right-toast");
		} else {
			ToastsStore.success(data.message, 6000, "right-toast");
			dispatch(
				setParticipants([
					...participants,
					{
						accountName: data.data?.accountName,
						accountNumber: data.data?.accountNumber,
						amount:
							splitPayment.splitPaymentType === CUSTOM
								? values.amount
								: sum,
						participantType: RECIPIENT,
					},
				])
			);
		}
	};

	const checkBeneficiaryExist = (values) => {
		const list = participants.filter(
			(participant, index) =>
				participant.accountNumber === values.beneficiary.value
		);
		if (list.length > 0) {
			ToastsStore.error("Participant already exist", 6000, "right-toast");
		} else {
			ToastsStore.success(
				"Account resolved successfully",
				6000,
				"right-toast"
			);
			dispatch(
				setParticipants([
					...participants,
					{
						accountName: values.beneficiary.label,
						accountNumber: values.beneficiary.value,
						amount:
							splitPayment.splitPaymentType === CUSTOM
								? values.amount
								: sum,
						participantType: RECIPIENT,
					},
				])
			);
		}
	};

	useEffect(() => {
		fetchBeneficiaries();
	}, []);
	return (
		<div className="participants-data-modal">
			<div className=" content">
				<Formik
					initialValues={initialFormValues}
					validationSchema={formValidationSchema}
					onSubmit={(values) => handleSubmit(values)}
				>
					{({
						values,
						errors,
						touched,
						handleSubmit,
						setFieldValue,
						handleBlur,
					}) => {
						return (
							<form
								className="split-payment-form row"
								onSubmit={(e) => {
									e.preventDefault();
									handleSubmit();
								}}
							>
								<div className="mb-4 col-12 ">
									<label>Choose an Option</label>
									<div className="split-options">
										<button
											onClick={() => {
												setParticipantType(ACCOUNT);
											}}
											className={`btn btn-mint-default text-capitalize ${
												participantType === ACCOUNT
													? ""
													: "in-active"
											} `}
											type="button"
										>
											Account
										</button>
										<button
											onClick={() => {
												setFieldValue(
													"beneficiary",
													null
												);
												setParticipantType(
													BENEFICIARIES
												);
											}}
											className={`btn btn-mint-default text-capitalize  ${
												participantType ===
												BENEFICIARIES
													? ""
													: "in-active "
											}  `}
											type="button"
										>
											Saved Beneficiaries
										</button>
									</div>
								</div>
								{participantType === BENEFICIARIES && (
									<div className="mb-4 col-12">
										<Label for="beneficiary">
											Beneficiary
										</Label>
										<CommonSelect
											name="beneficiary"
											isSearchable
											placeholder="Select beneficiary"
											error={
												touched.beneficiary &&
												errors.beneficiary
											}
											onBlur={handleBlur}
											handleChange={(data) => {
												setFieldValue(
													"beneficiary",
													data
												);
											}}
											options={beneficiaries.map(
												(data) => ({
													label: data?.accountName,
													value: data?.accountNumber,
												})
											)}
											isLoading={beneficiariesLoading}
											showErrorMsg
										/>
									</div>
								)}
								{participantType === ACCOUNT && (
									<div className="mb-4 col-12 ">
										<FormInputGroup
											label="Participant"
											id="accountNumber "
											name="accountNumber "
											placeholder="Enter account number phone number"
											className="split-request-input"
											onBlur={handleBlur}
											value={values.accountNumber}
											onChange={(e) => {
												const value = e.target.value
													.trim()
													.replace(/[^+0-9]/i, "");
												setFieldValue(
													"accountNumber",
													value
												);
											}}
											error={
												touched.accountNumber &&
												errors.accountNumber
											}
										/>
									</div>
								)}
								{splitPayment.splitPaymentType === CUSTOM ? (
									<div className="mb-4 col-12 ">
										<CustomCurrencyInput
											label="Amount"
											id="amount"
											name="amount"
											placeholder="Enter amount"
											className="split-request-input"
											value={values.amount}
											onChange={(value) => {
												setFieldValue(
													"amount",
													parseAmount(value)
												);
											}}
											error={
												touched.amount && errors.amount
											}
										/>
										<p className=" bal">
											Remaining balance:{" "}
											{formatCurrency(bal)}{" "}
										</p>
									</div>
								) : (
									""
								)}

								<div className="col-12">
									<button
										className={`btn btn-mint-default text-capitalize `}
										type="submit"
										disabled={
											isLoading || values.amount > bal
										}
									>
										{isLoading ? <SmallLoader /> : "Verify"}
									</button>
								</div>
							</form>
						);
					}}
				</Formik>
			</div>
			<div className="col-12 "></div>
		</div>
	);
};
