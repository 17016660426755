/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Modal from "components/utils/Modal/Modal";
import { SuccessImageFunction } from "components/utils/SuccessImage";
import { useHistory } from "react-router-dom";

const ApplicationSubmited = ({ show, onClose, isVisible = true }) => {
	return (
		<>
			<Modal show={show} scrollable>
				<Content onClose={onClose} isVisible={isVisible} />
			</Modal>
		</>
	);
};
export default ApplicationSubmited;

const Content = ({ isVisible }) => {
	const history = useHistory();

	return (
		<div className="submitted-modal ">
			<div className=" content">
				<div className="icon">
					{/* <SuccessImage /> */}
					<SuccessImageFunction />
				</div>
				<h4>Application Submitted</h4>
				<p>
					{" "}
					Your application has been received. We will review and
					process as soon as possible.
				</p>
			</div>
			<div className="col-12 ">
				{isVisible ? (
					<button
						onClick={() => {
							history.push("/register-business");
						}}
						className="btn btn-mint-default"
						type="button"
					>
						Done
					</button>
				) : (
					<button
						onClick={() => {
							window.location.reload();
						}}
						className="btn btn-mint-default"
						type="button"
					>
						Ok, go back
					</button>
				)}
			</div>
		</div>
	);
};
