import React from "react";
import { formatCurrency } from "../../../../utils/helpers";
import SuccessHeader from "../../../utils/SuccessHeader/SuccessHeader";
import "./FTModals.scss";

const FundsTransferSuccess = ({ transcationRequest, successData }) => {
	const { recipientAccountName } = transcationRequest || {};
	const { amount, transactionDate, responseMessage, responseCode } =
		successData || {};

	// 00: successful, 01: Pending, 02: Failed
	const getHeadingBasedOnCode = () => {
		switch (responseCode) {
			case "01":
				return "Transaction Pending!";
			case "02":
				return "Transaction Failed!";
			default:
				return "Congratulations!";
		}
	};

	return (
		<>
			<div>
				<SuccessHeader
					heading={getHeadingBasedOnCode()}
					paragraph={responseMessage}
					hasNewIcon
					statusCode={responseCode}
					// paragraph="Approved by Financial Institution"
				/>
				<div className="ft-success-modal-body px-sm-5 px-3 pb-5">
					<div className="ft-success-modal-info">
						<span>Amount</span>
						<span>{formatCurrency(amount)}</span>
					</div>
					<div className="ft-success-modal-info">
						<span>Recipients Name</span>
						<span>{recipientAccountName}</span>
					</div>
					<div className="ft-success-modal-info">
						<span>Date & Time</span>
						<span>
							{new Date(transactionDate)
								.toLocaleTimeString([], {
									year: "numeric",
									month: "long",
									day: "numeric",
									hour: "2-digit",
									minute: "2-digit",
								})
								.replace("at", "/")}
						</span>
					</div>
					<div className="dialog-footer mt-4 mb-4">
						<a
							className="btn create-acc text-capitalize"
							href="/dashboard"
						>
							Close
						</a>
					</div>
				</div>
			</div>
		</>
	);
};

export default FundsTransferSuccess;
