import { ERROR, FINISHED, IDLE, INDIVIDUAL, LOADING } from "config/enums";
import { API } from "lib/api";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import savingsGoals from "lib/api/apiRoutesConfig/services/SavingsMS/savingsGoals";
import { getErrorMessage } from "components/utils/helpers";
import { Helmet } from "react-helmet";
import "./SavingGoals.scss";
import FadeIn from "../../effects/FadeIn";
import CaretLeft from "assets/img/caret_left_gold.svg";
import { ReactSVG } from "react-svg";
import PlusIcon from "assets/img/white_plus_icon.svg";
import { MintInfinite } from "components/dashboard/utils/ShineLoader/ShineLoader";
import FailedTryAgain from "components/dashboard/utils/FailedTryAgain/FailedTryAgain";
import GroomPanel from "components/dashboard/utils/GroomPanel/GroomPanel";
import UserRoleWarning from "components/utils/UserRoleWarning/UserRoleWarning";
import EmptySavingGoalList from "./components/EmptySavingGoalList/EmptySavingGoalList";
import SavingGoalsWrapper from "./components/SavingGoalsWrapper/SavingGoalsWrapper";

export default function SavingGoals() {
	const history = useHistory();
	const [currentState, setCurrentState] = useState(IDLE);
	const [errorMsg, setErrorMsg] = useState("");
	const [savingsGoalData, setSavingsGoalData] = useState([]);

	const { loginResponse, activeAccountType } = useSelector(
		(state) => state.auth
	);

	const accountType =
		activeAccountType === INDIVIDUAL
			? "activeIndividualAccount"
			: "activeCorporateAccount";

	const { currentRole } = loginResponse[accountType]?.profileSetting || {};
	const isApprover = currentRole === "APPROVER";

	const fetchSavingsGoal = async () => {
		setCurrentState(LOADING);
		try {
			const response = await API.get(savingsGoals);
			const { status, data } = response || {};
			if (status === 200) {
				setSavingsGoalData(data.data);
				setCurrentState(FINISHED);
			}
		} catch (err) {
			setErrorMsg(getErrorMessage(err));
			setSavingsGoalData([]);
			setCurrentState(ERROR);
		}
	};

	useEffect(() => {
		fetchSavingsGoal();
	}, []);

	const renderBasedOnState = () => {
		switch (currentState) {
			case LOADING:
				return (
					<div className="d-flex w-100 justify-content-center align-items-center saving-goal-loading">
						<MintInfinite relative />
					</div>
				);

			case ERROR:
				return (
					<div className="d-flex w-100 justify-content-center align-items-center saving-goal-loading">
						<FailedTryAgain
							errorMssg={errorMsg}
							handleTryAgain={fetchSavingsGoal}
						/>
					</div>
				);

			case FINISHED:
				if (savingsGoalData.length <= 0) {
					return <EmptySavingGoalList />;
				}

				return <SavingGoalsWrapper data={savingsGoalData} />;

			default:
				return null;
		}
	};

	return (
		<>
			<Helmet>
				<title>{process.env.REACT_APP_NAME} - Savings Goal</title>
			</Helmet>

			<FadeIn>
				<section className="savings-goal-page pb-5">
					<div className="d-flex align-items-center mb-5 justify-content-between">
						<button
							className="back-btn d-flex align-items-center"
							onClick={() => history.goBack()}
						>
							<img src={CaretLeft} alt="" />
							<span>Back</span>
						</button>

						{currentState === FINISHED && (
							<button
								className="btn btn-mint-default new-saving-goal-btn"
								onClick={() =>
									history.push(
										"/savings/goals/create-savings-goal"
									)
								}
							>
								New Savings Goal{" "}
								<ReactSVG src={PlusIcon} className="ml-2" />
							</button>
						)}
					</div>

					<h1 className="page-title-text">
						{savingsGoalData.length > 0
							? "View Savings Goal"
							: "Savings"}
					</h1>
					<p className="page-lead-text">
						{savingsGoalData.length > 0
							? "See an overview of your Savings goal"
							: "Manage all of your saving plans here"}
					</p>

					{isApprover ? (
						<GroomPanel cls="saving-goal-groom position-relative">
							<UserRoleWarning
								style={{
									position: "absolute",
									top: "50%",
									left: "50%",
									transform: "translate(-50%, -50%)",
								}}
							/>
						</GroomPanel>
					) : (
						renderBasedOnState()
					)}
				</section>
			</FadeIn>
		</>
	);
}
