import React, { Component } from "react";
import { connect } from "react-redux";
import { ReactSVG } from "react-svg";
import Modal from "../../../../utils/Modal/Modal";
import CustomModalHeader from "../../../../utils/Modal/CustomModalHeader/CustomModalHeader";
import BigLock from "./big-lock.svg";
import AnswerSecurityQuestion from "./AnswerSecurityQuestion/AnswerSecurityQuestion";
import "./SetSecurityQuestion.scss";

const WelcomeSecurityQuestions = ({ nextStage }) => (
	<>
		<CustomModalHeader
			heading={"Secure your account"}
			dismissable={false}
		/>
		<div className="text-center big-lock py-2">
			<ReactSVG src={BigLock} />
		</div>
		<div className="securityQuestion__section">
			<h6>Set up your security questions</h6>
			<p>
				This will make your account more secure and used for
				<br />
				account retrieval purposes.
			</p>
			<div className="row justify-content-center">
				<div className="col-md-6">
					<button
						className="btn btn-custom-bordered"
						onClick={nextStage}
					>
						Set Up
					</button>
				</div>
			</div>
		</div>
	</>
);

class SetSecurityQuestion extends Component {
	_mounted = false;

	state = {
		loading: false,
		currentStage: "INITIAL",
	};

	componentDidMount = () => (this._mounted = true);
	componentWillUnmount = () => (this._mounted = false);

	goToQuestionAnswer = () => {
		this._mounted && this.setState({ currentStage: "MAIN" });
	};

	renderPerStage = () => {
		const { currentStage, loading } = this.state;
		switch (currentStage) {
			case "INITIAL":
				return (
					<WelcomeSecurityQuestions
						nextStage={this.goToQuestionAnswer}
						loading={loading}
					/>
				);

			case "MAIN":
				return <AnswerSecurityQuestion />;

			default:
				return (
					<WelcomeSecurityQuestions
						fetchSecurityQuestions={this.fetchSecurityQuestions}
					/>
				);
		}
	};

	render() {
		const { securityQuestionCreated } = this.props.profileSettings || {};
		const showModal = !securityQuestionCreated;

		return (
			<Modal show={showModal}>
				<div>{this.renderPerStage()}</div>
			</Modal>
		);
	}
}

export default connect(
	(state) => ({ profileSettings: state.settings.profileSettings }),
	{}
)(SetSecurityQuestion);
