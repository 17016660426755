import React, { useState } from "react";
import { SmallLoader } from "components/utils/SmallLoader";
import Helmet from "react-helmet";
import { API } from "lib/api";
import FadeIn from "components/dashboard/components/effects/FadeIn";
import { getDetailsAction } from "lib/api/apiRoutesConfig/services/AccountMS/CAC/subRoutes";
import { ToastsStore } from "react-toasts";
import { getErrorMessage } from "components/utils/helpers";
import { useHistory } from "react-router-dom";
import FailedTryAgain from "components/dashboard/utils/FailedTryAgain/FailedTryAgain";
import ApplicationSubmited from "./utils/ApplicationSubmited";
import { MintInfinite } from "components/dashboard/utils/ShineLoader/ShineLoader";
import {
	ERROR,
	FINISHED,
	IDLE,
	LOADING,
	SOLE_PROPRIETORSHIP,
	ENTERPRISE,
	INCORPORATED_TRUSTEE,
} from "config/enums";
import { COMPLETED } from "./utils/exportData";
import PhoneNumberInputV2 from "components/utils/PhoneNumberInput/PhoneNumberInputV2";
import PublicNavbar from "./components/navbar";

const Start = () => {
	const history = useHistory();

	const [phoneNumber, setPhoneNumber] = useState("");
	const [dialCode, setDialCode] = useState("");
	const [errorMsg, setErrorMsg] = useState("");
	const [currentStatus, setCurrentStatus] = useState(IDLE);
	const [stage, setStage] = useState("");

	const handlePhone = (__, value, countryData) => {
		let Numbers = value.replace(/[^0-9]/g, "");
		value = Numbers.replace(/^(-?)0+/, "");
		setPhoneNumber(value);
		setDialCode(countryData.dialCode);
	};

	// Get Data details
	const startBusinessReg = async (value) => {
		setCurrentStatus(LOADING);
		try {
			const phone = "+" + dialCode + "" + phoneNumber;
			const res = await API.post(getDetailsAction, {
				phoneNumber: phone ?? value,
			});
			const { status, data } = res || {};

			if (status === 200) {
				if (data.data.nextStage === COMPLETED) {
					setStage(COMPLETED);
					setCurrentStatus(FINISHED);
				} else {
					localStorage.setItem("s_id", data.data.phoneNumber);
					// history.push("/register-business/business-type", {
					//   user:data.data.phoneNumber,
					// })
					renderBasedOnbusinessType(data.data.businessType);
				}
			}
		} catch (err) {
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
			setErrorMsg(getErrorMessage(err));
		}
	};
	//  2349494949432

	const renderBasedOnbusinessType = (value) => {
		switch (value) {
			case SOLE_PROPRIETORSHIP:
				return history.push("/register-business/business-name");

			case ENTERPRISE:
				return history.push("/register-business/company-name");

			case INCORPORATED_TRUSTEE:
				return history.push("/register-business/incorporated-trustee");

			default:
				return history.push("/register-business/business-type");
		}
	};
	const renderBasedOnStatus = () => {
		switch (currentStatus) {
			case LOADING:
				return <MintInfinite relative />;

			case ERROR:
				return (
					<div className="d-flex w-100 justify-content-center mt-5 align-items-center order-loading">
						<FailedTryAgain
							errorMssg={errorMsg}
							handleTryAgain={() =>
								startBusinessReg(localStorage.getItem("s_id"))
							}
						/>
					</div>
				);

			case FINISHED:
				return (
					<>
						<ApplicationSubmited show={true} isVisible={false} />{" "}
					</>
				);

			default:
				return null;
		}
	};
	return (
		<>
			<Helmet>
				<title>{process.env.REACT_APP_NAME} - Register Business</title>
			</Helmet>

			<FadeIn>
				<PublicNavbar>
					<div style={{ visibility: "hidden" }}>1</div>
				</PublicNavbar>
				{stage !== COMPLETED ? (
					<div className="row pt-5 justify-content-center">
						<div className="col-xl-4 col-lg-6 col-md-6 col-sm-7 col-10">
							<div className="account-verification-panel">
								<h3 className="info-heading text-center">
									Register Business
								</h3>
								<p className="info-text">
									Please enter your phone number to start
								</p>
							</div>
							<div className="login-form mt-5">
								<PhoneNumberInputV2
									label="Phone Number"
									inputFieldName="Phone Number"
									inputValue={phoneNumber}
									handlePhoneNumberChange={handlePhone}
									isDisabled={currentStatus === LOADING}
									onSelectFlag={() => setPhoneNumber("")}
								/>

								<button
									onClick={startBusinessReg}
									type="button"
									className="btn-primary w-100"
									disabled={
										currentStatus === LOADING ||
										phoneNumber === ""
											? true
											: false
									}
								>
									{currentStatus === LOADING ? (
										<SmallLoader />
									) : (
										"Continue"
									)}
								</button>
							</div>
						</div>
					</div>
				) : (
					renderBasedOnStatus()
				)}
			</FadeIn>
		</>
	);
};
export default Start;
