import FadeIn from "components/dashboard/components/effects/FadeIn";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import CaretLeft from "assets/img/caret_left_gold.svg";
import { useHistory } from "react-router-dom";
import GroomPanel from "components/dashboard/utils/GroomPanel/GroomPanel";
import "./CreateSavingsGoal.scss";
import InitialStep from "./steps/InitialStep";
import CreateGoalName from "./steps/CreateGoalName";
import FundSavings from "./steps/FundSavings";
import MaturityPeriod from "./steps/MaturityPeriod";
import SummaryStep from "./steps/SummaryStep";

const MIN_STEP = 0;
const MAX_STEP = 4;

export default function CreateSavingsGoal() {
	const history = useHistory();

	const [currentStep, setCurrentStep] = useState(MIN_STEP);
	const [payload, setPayload] = useState({
		name: "",
		categoryCode: "",
		autoDebit: true,
		frequency: "",
		startDate: undefined,
		fundingAmount: "",
		durationInDays: "",
		targetAmount: "",
		lockedSavings: false,
		interestRate: "",
	});

	const goToNextStep = () => {
		setCurrentStep((prev) => Math.min(prev + 1, MAX_STEP));
	};

	const goToPreviousStep = () => {
		setCurrentStep((prev) => Math.max(prev - 1, MIN_STEP));
	};

	const handleGoBack = () => {
		if (currentStep === MIN_STEP) {
			history.goBack();
			return;
		}
		goToPreviousStep();
	};

	const renderBasedOnStep = () => {
		switch (currentStep) {
			case 0:
				return (
					<InitialStep
						setGoalName={(title, code) => {
							setPayload({
								...payload,
								name: title,
								categoryCode: code,
								targetAmount: "",
							});
							goToNextStep();
						}}
						goalName={payload.name}
					/>
				);

			case 1:
				return (
					<CreateGoalName
						goalName={payload.name}
						targetAmount={payload.targetAmount}
						goToNextStep={(goalName, targetAmount) => {
							setPayload({
								...payload,
								name: goalName,
								targetAmount,
							});
							goToNextStep();
						}}
					/>
				);

			case 2:
				return (
					<FundSavings
						payload={payload}
						goToNextStep={(values) => {
							setPayload(values);
							goToNextStep();
						}}
					/>
				);

			case 3:
				return (
					<MaturityPeriod
						payload={payload}
						goToNextStep={(values) => {
							setPayload(values);
							goToNextStep();
						}}
					/>
				);

			case 4:
				return <SummaryStep payload={payload} />;

			default:
				return null;
		}
	};

	return (
		<>
			<Helmet>
				<title>
					{process.env.REACT_APP_NAME} - Create Savings Goal
				</title>
			</Helmet>

			<FadeIn>
				<section className="create-savings-goal-page pb-5">
					<div className="d-flex align-items-center mb-5 justify-content-between">
						<button
							className="back-btn d-flex align-items-center"
							onClick={() => handleGoBack()}
						>
							<img src={CaretLeft} alt="" />
							<span>Back</span>
						</button>
					</div>

					<h1 className="page-title-text">
						Create new Savings Goal
					</h1>
					<p className="page-lead-text mb-0">
						Please select your Saving Plan preferences and start
						saving
					</p>

					<GroomPanel cls="saving-goal-groom mt-5">
						<div
							className={`saving-goal-groom__header ${
								currentStep > MIN_STEP ? "shrink" : ""
							}`}
						>
							<span
								className="btn btn-setup-step d-flex align-items-center justify-content-center"
								style={{ cursor: "text" }}
							>
								Step {currentStep + 1}
							</span>
							<span className="step-count">
								{currentStep} of 5 Done
							</span>
						</div>

						{renderBasedOnStep()}
					</GroomPanel>
				</section>
			</FadeIn>
		</>
	);
}
