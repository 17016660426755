import React from "react";
import { useSelector } from "react-redux";
import { INDIVIDUAL } from "../../../../../../../config/enums";
import InfoIcon from "../../../../../../../assets/img/exclamation_icon.svg";
import ChevronRight from "../../.././../../../../assets/img/caret_right_gold.svg";
import { ReactSVG } from "react-svg";
import "./AccountSetupBanner.scss";
import { useHistory } from "react-router-dom";

export default function AccountSetupBanner() {
	const { loginResponse, activeAccountType } = useSelector(
		(state) => state.auth
	);

	const history = useHistory();
	const activeCorporateAccount = loginResponse?.activeCorporateAccount;

	const showBanner =
		activeAccountType !== INDIVIDUAL &&
		activeCorporateAccount?.setUpCompleted === false;

	return (
		<>
			{showBanner ? (
				<div
					className="account-setup-banner"
					onClick={() => history.push("/setup-account", {})}
				>
					<ReactSVG
						src={InfoIcon}
						className="account-setup-info-icon"
					/>
					<span>
						Complete your profile to get unlimited access to your
						account
					</span>
					<ReactSVG
						src={ChevronRight}
						className="account-setup-right-icon"
					/>
				</div>
			) : null}
		</>
	);
}
