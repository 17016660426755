import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { API } from "../../../../../../lib/api";
import { basicInformationEndpoint } from "../../../../../../lib/api/apiRoutesConfig/services/AccountMS/signup/subRoutes";
import AwarenessChannelSelectBox from "../../../../../utils/AwarenessChannelSelectBox/AwarenessChannelSelectBox";
import EmploymentStatusCheckBox from "../../../../../utils/CustomCheckBox/EmploymentStatusCheckBox";
import { SmallLoader } from "../../../../../utils/SmallLoader";
import { updateCustomer } from "../../../../../../actions/registrationActions";
import { formValidator } from "../../../../../utils/FormValidator/FormValidator";
import { getErrorMessage } from "../../../../../utils/helpers";
import { accountTypes } from "../../../enums";
import { clearStage, updatePersonal } from "../../../../../../reducers/steps";
import StateSelectBox from "components/utils/StateSelectBox/StateSelectBox";
import LgaSelectBox from "components/utils/LgaSelectBox/LgaSelectBox";
import FormInputGroupV2 from "components/utils/FormInputGroup/FormInputGroupV2";
import GenderSelect from "components/utils/GenderSelect/GenderSelect";

const OthersBasicInformation = ({ stepData, sessionId }) => {
	const [formErrors, setFormErrors] = useState({});
	const [loading, setLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState();

	const dispatch = useDispatch();
	const history = useHistory();

	const { withReferral, personalDetails, accountType } = stepData || {};
	const {
		referralCode,
		employmentStatus,
		mintAwarenessSource,
		email,
		businessName,
		businessNumber,
		gender,
		address,
		stateCode,
		lgaCode,
	} = personalDetails;

	const radioKeys = {
		name: "gender",
		values: ["MALE", "FEMALE"],
	};

	const handleBasicInformation = async (payload) => {
		try {
			setLoading(true);
			setErrorMsg();

			const request = {
				...getPayload(),
				...payload,
				mintAwarenessSource: payload.mintAwarenessSource.code,
			};

			const res = await API.put(
				basicInformationEndpoint(sessionId),
				request
			);

			if (res.status === 200) {
				dispatch(clearStage());
				dispatch(updateCustomer({}));
				history.replace("/onboard/complete");
			}
		} catch (err) {
			setLoading(false);
			setErrorMsg(getErrorMessage(err));
		}
	};

	const handleChange = ({ target: { name, value } }) => {
		handleSelect(name, value);
	};

	const handleSelect = (name, value) => {
		dispatch(updatePersonal(name, value));
		setFormErrors((prev) => ({
			...prev,
			[name]: "",
		}));
	};

	const getPayload = () => {
		const { businessName, businessNumber } = personalDetails || {};
		const businessData = { businessName, businessNumber };
		let payload = {
			// address,
			referralCode,
			gender: personalDetails.gender,
			lgaCode: personalDetails.lgaCode.code,
		};
		if (accountType !== accountTypes.INDIVIDUAL)
			payload = { ...payload, ...businessData };

		return payload;
	};

	const handleFormInfo = () => {
		let formInfo = {};
		if (accountType === accountTypes.SOLE_PROPRIETORSHIP)
			formInfo = {
				label: "Business Number (BN)",
				plc: "Business number on CAC Certificate",
			};
		if (accountType === accountTypes.ENTERPRISE)
			formInfo = {
				label: "Registered Company Number (RC Number)",
				plc: "RC number on CAC Certificate",
			};
		if (accountType === accountTypes.INCORPORATED_TRUSTEE)
			formInfo = {
				label: "IT Number",
				plc: "Enter IT Number",
			};
		return formInfo;
	};
	const busNum = handleFormInfo();

	const handleSubmit = (e) => {
		e.preventDefault();
		const isAccountIndividual = accountType === accountTypes.INDIVIDUAL;
		const payload = isAccountIndividual
			? {
					employmentStatus,
					mintAwarenessSource,
					email,
					gender,
					address,
					stateCode: stateCode.code,
					lgaCode: lgaCode.code,
			  }
			: {
					mintAwarenessSource,
					email,
					businessName,
					businessNumber,
					gender,
			  };

		const { errors, formisValid } = formValidator(payload);
		// const { errors, formisValid } = ValidateForm(e, payload);
		formisValid ? handleBasicInformation(payload) : setFormErrors(errors);
	};

	// const goBack = () => {
	// 	dispatch(updateStepData({ basicInfoStep: basicInfoSteps.ADDRESS }));
	// };

	return (
		<form
			onSubmit={handleSubmit}
			className="login-form basic-information-form"
		>
			{errorMsg && (
				<div className="desct error fade-in mb-3">
					<p>{errorMsg}</p>
				</div>
			)}

			<p className="info-heading text-center">
				{accountType !== accountTypes.INDIVIDUAL && (
					<>
						Kindly provide the following information about your
						business.
					</>
				)}{" "}
				We are NDPR compliant, we would not share your information
				without your consent.
			</p>

			{accountType !== accountTypes.INDIVIDUAL && (
				<>
					<FormInputGroupV2
						name="businessName"
						label="Business Name"
						placeholder="Business name on CAC Certificate"
						value={businessName}
						error={formErrors?.businessName}
						onChange={handleChange}
					/>

					<FormInputGroupV2
						name="businessNumber"
						label={busNum?.label}
						placeholder={busNum?.plc}
						value={businessNumber}
						error={formErrors?.businessNumber}
						// onChange={handleChange}
						onChange={(e) => {
							const value = e.target.value
								.trim()
								.replace(/[^+0-9]/i, "");
							handleChange({
								target: {
									name: "businessNumber",
									value: value,
								},
							});
						}}
					/>
				</>
			)}

			{accountType === accountTypes.INDIVIDUAL && (
				<>
					<FormInputGroupV2
						name="address"
						label="Please Provide Your Address"
						placeholder="Enter Address"
						value={address}
						error={formErrors?.address}
						onChange={handleChange}
					/>

					<StateSelectBox
						name="stateCode"
						label="Please Select Your State"
						placeholder="Select State"
						value={stateCode}
						error={formErrors?.stateCode}
						onChange={(e) => handleSelect("stateCode", e)}
					/>

					<LgaSelectBox
						name="lgaCode"
						label="Select Your Local Government"
						placeholder="Select LGA"
						value={lgaCode}
						error={formErrors?.lgaCode}
						stateCode={stateCode?.code}
						onChange={(e) => handleSelect("lgaCode", e)}
					/>
				</>
			)}

			<FormInputGroupV2
				name="email"
				type="email"
				label="Please Provide Your Email"
				placeholder="Enter email"
				value={email}
				error={formErrors?.email}
				onChange={handleChange}
			/>

			<GenderSelect
				radioKeys={radioKeys}
				selected={gender}
				onSelect={(value) => handleSelect("gender", value)}
				error={formErrors?.gender}
			/>

			<AwarenessChannelSelectBox
				label="How Did You Hear About Us?"
				name="mintAwarenessSource"
				value={mintAwarenessSource}
				error={formErrors?.mintAwarenessSource}
				onChange={(e) => handleSelect("mintAwarenessSource", e)}
			/>

			<FormInputGroupV2
				name="referralCode"
				label="Do You Have a Referral Code?"
				placeholder="Enter referral code"
				value={referralCode}
				error={formErrors?.referralCode}
				onChange={handleChange}
				disabled={withReferral ? true : false}
			/>

			{accountType === accountTypes.INDIVIDUAL && (
				<EmploymentStatusCheckBox
					name="employmentStatus"
					value={employmentStatus}
					handleChange={handleChange}
				/>
			)}

			<div className="d-flex">
				{/* <button
					type="button"
					className="btn btn-custom-bordered mr-2"
					onClick={goBack}
				>
					Back
				</button> */}

				<button
					type="submit"
					className="btn-primary w-100"
					disabled={loading}
				>
					{loading ? <SmallLoader /> : "Continue"}
				</button>
			</div>
		</form>
	);
};

export default OthersBasicInformation;
