import Modal from "components/utils/Modal/Modal";
import React from "react";
import "./nav.scss";
import { CancelImageFunction } from "components/utils/SuccessImage";
import { useHistory } from "react-router-dom";

const ConfirmBackModal = ({ show, onClose }) => {
	const history = useHistory();
	return (
		<Modal show={show} onClosed={onClose} scrollable>
			<div className="modal-container">
				<div className="modal-content-wrapper">
					<div>
						<CancelImageFunction />
					</div>

					<p className="modal-text">
						You are about to navigate back, this means you will
						resume your registration process later because you won't
						be able to continue from the previous page. Click on{" "}
						{""}
						<span>OK</span> to go back to the starting point and
						resume process later or <span>Cancel</span> to proceed
						with the registration process.
					</p>
				</div>
				<div className="btn-container">
					<button
						onClick={() => {
							onClose();
							history.push("/register-business");
						}}
						className="btn btn-mint-default"
						type="button"
					>
						Okay, go back
					</button>
					<button
						onClick={onClose}
						className="btn cancel-btn"
						type="button"
					>
						Cancel, proceed with registration
					</button>
				</div>
			</div>
		</Modal>
	);
};
export default ConfirmBackModal;
