import FadeIn from "components/dashboard/components/effects/FadeIn";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "../BusinessDocumentation/BusinessDocumentation.scss";
import CaretLeft from "../../../../../../assets/img/caret_left_gold.svg";
import SetupCard from "../../components/SetupCard/SetupCard";
import EnquiryModal from "../../components/modals/EnquiryModal";
import {
	ERROR,
	FAILED,
	FINISHED,
	IDLE,
	I_AM_TRUSTEE,
	LOADING,
} from "config/enums";
import Modal from "components/utils/Modal/Modal";
import SetupSuccessModal from "../../components/modals/SetupSuccessModal";
import { useSelector } from "react-redux";
import { API } from "lib/api";
import { getErrorMessage } from "components/utils/helpers";
import { ToastsStore } from "react-toasts";
import { createTrustee } from "lib/api/apiRoutesConfig/services/Corporate/AccountServiceMS/accountSetup/subRoutes";
import PersonalInfoModal from "../../components/modals/PersonalInfoModal";
import GovtIssuedIdModal from "../../components/modals/GovtIssuedIdModal";
import PassportModal from "../../components/modals/PassportModal";

export default function TrusteesDocumentation() {
	const history = useHistory();
	const [showEnquiryModal, setShowEnquiryModal] = useState(false);
	const [currentState, setCurrentState] = useState(IDLE);
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [successMsg, setSuccessMsg] = useState("");
	const [showPersonalInfoModal, setShowPersonalInfoModal] = useState(false);
	const [showGovtIdModal, setShowGovtIdModal] = useState(false);
	const [showPhotoModal, setShowPhotoModal] = useState(false);

	const enquiryModalConfig = () => ({
		iconType: "",
		title: "Are you a trustee?",
		message:
			"You will need to add at least a trustee to complete your corporate account setup.",
		positiveLabel: "I am a trustee",
		negativeLabel: "No, I am not",
		positiveBtnLoading: currentState === LOADING,
		onPositive: () => createUserTrustee(),
		onNegative: () => setShowEnquiryModal(false),
	});

	const dashboardResponse = useSelector(
		(state) => state.dashboard?.dashboardResponse
	);

	const createUserTrustee = async () => {
		setCurrentState(LOADING);
		try {
			const res = await API.post(createTrustee, null, {
				params: {
					accountId: dashboardResponse.mintAccountId,
				},
			});
			if (res.status === 200) {
				setShowEnquiryModal(false);
				setCurrentState(FINISHED);
				setSuccessMsg(res.data.message);
				setShowSuccessModal(true);
			}
		} catch (err) {
			setCurrentState(ERROR);
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		}
	};

	const refreshPage = () => {
		history.go(0);
	};

	const passportImage =
		dashboardResponse?.setUpDetail?.trusteeSetup?.passportImage;
	const personalInfo =
		dashboardResponse?.setUpDetail?.trusteeSetup?.personalInfo;
	const directorGovtId =
		dashboardResponse?.setUpDetail?.trusteeSetup?.directorGovtId?.status;
	const directorTypeSelection =
		dashboardResponse?.setUpDetail?.trusteeSetup?.directorTypeSelection;

	// console.log(directorTypeSelection)

	useEffect(() => {
		// Only show enquiry modal if personal info has not been completed
		if (!personalInfo) {
			setShowEnquiryModal(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<FadeIn>
			<section className="business-documentation-page pb-5">
				<div className="d-flex align-items-center mb-5">
					<button
						className="back-btn d-flex align-items-center"
						onClick={() => history.goBack()}
					>
						<img src={CaretLeft} alt="" />
						<span>Back</span>
					</button>
				</div>

				<p className="bdp-step-count">STEP 2</p>
				<h1>Trustees Documentation</h1>
				<p>
					Please provide all the information below to start enjoying
					the full features of your account.
				</p>

				<div className="row mt-5">
					<SetupCard
						title="Personal Information"
						description="Provide information on your trustee details."
						status={personalInfo}
						onClick={() => setShowPersonalInfoModal(true)}
					/>

					<SetupCard
						title="Government Issued ID Card"
						description="Upload a valid means of identification."
						status={directorGovtId}
						isDisabled={
							!personalInfo ||
							(directorGovtId === FAILED &&
								directorTypeSelection === I_AM_TRUSTEE)
						}
						onClick={() => setShowGovtIdModal(true)}
					/>

					<SetupCard
						title="Passport Photograph"
						description="Upload a clear picture of your trustee."
						status={passportImage}
						isDisabled={!personalInfo}
						onClick={() => setShowPhotoModal(true)}
					/>
				</div>
			</section>

			{/* Modals */}
			<EnquiryModal
				show={showEnquiryModal}
				onClose={() => setShowEnquiryModal(false)}
				{...enquiryModalConfig()}
			/>

			<SuccessModal
				show={showSuccessModal}
				successMsg={successMsg}
				onClose={() => {
					setShowSuccessModal(false);
					refreshPage();
				}}
			/>

			<PersonalInfoModal
				show={showPersonalInfoModal}
				documentationType="TRUSTEES_DOCUMENTATION"
				onClose={() => setShowPersonalInfoModal(false)}
				onSuccess={() => {
					setShowPersonalInfoModal(false);
					refreshPage();
				}}
			/>

			<GovtIssuedIdModal
				show={showGovtIdModal}
				documentationType="TRUSTEES_DOCUMENTATION"
				onClose={() => setShowGovtIdModal(false)}
				onSuccess={() => {
					setShowGovtIdModal(false);
					refreshPage();
				}}
			/>

			<PassportModal
				show={showPhotoModal}
				documentationType="TRUSTEES_DOCUMENTATION"
				onClose={() => setShowPhotoModal(false)}
				onSuccess={() => {
					setShowPhotoModal(false);
					refreshPage();
				}}
			/>
		</FadeIn>
	);
}

// Success Modal
const SuccessModal = ({ show, onClose, successMsg }) => {
	return (
		<Modal show={show}>
			<SetupSuccessModal
				title="Trustee Added"
				message={successMsg}
				buttonLabel="Close"
				onClick={() => onClose?.()}
			/>
		</Modal>
	);
};
