export default {
	IS_INDIVIDUAL: "100",
	IS_ENTERPRISE: "101",
	IS_SOLE_PROPRIETORSHIP: "102",
	IS_INCORPORATED_TRUSTEE: "103",
	IS_SETUP_UNCOMPLETED: "104",
	IS_ACCOUNT_VERIFIED: "105",
	IS_ENTERPRISE_TRUSTEE_VERIFIED: "106",
	IS_INDIVIDUAL_SOLE_VERIFIED: "107",
};
