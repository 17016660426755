import individual from "..";

export const {
	
	confirmOrderAction,
	getOrdersAction
} = {
	confirmOrderAction: `${individual}/customer-orders`,
	getOrdersAction: (orderType) => `${individual}/customer-orders`,
	
};
