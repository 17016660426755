export const PasswordRules = (p) => {
    let errors = [];
    if (p.length < 8 ) {
        errors.push("Password must not be less than 8 characters long");
    }
    
    if (p.search(/[a-z]/) === -1) {
        errors.push("Password must contain at least one lower case letter. ");
    }

    if (p.search(/[A-Z]/) === -1) {
        errors.push("Password must contain at least one upper case letter. ");
    }

    if (p.search(/[0-9]/) < 0) {
        errors.push("Password must contain at least one number");
    }

    if (p.search(/.*[!@#$%~^&*() =+_-]/) < 0) {
        errors.push("Password must contain at least one special character, (/~!@#$%^&*_-+=)");
    }

    if (p.length > 50){
        errors.push("Password should not be above 50 characters");
    }

    return errors;
}