import React from "react";
import PropTypes from "prop-types";
import "./CustomSwitch.scss";

const CustomSwitch = ({ inputName, isChecked, handleCheckBox, className }) => {
    return (
        <div className={`customSwitch ${className}`} onClick={handleCheckBox}>
            <input
                name={inputName}
                type='checkbox'
                onChange={() => ""}
                checked={isChecked}
            />
            <span className='slider round'></span>
        </div>
    );
};

CustomSwitch.propTypes = {
    handleCheckBox: PropTypes.func.isRequired,
    inputName: PropTypes.string.isRequired,
    className: PropTypes.string,
    isChecked: PropTypes.bool.isRequired,
};

export default CustomSwitch;
