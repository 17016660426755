import fundTransfer from "../";
export const {
	bankNameEnquiry,
	transferBankAccount,
	resolveMintAccountName,
	transferSingleTransaction,
	validateBeneficiary
} = {
	bankNameEnquiry: `${fundTransfer}/resolve-account-name`,
	transferBankAccount: `${fundTransfer}/bank-account`,
	resolveMintAccountName: `${fundTransfer}/resolve-mint-account`,
	transferSingleTransaction: `${fundTransfer}/single-transaction`,
	validateBeneficiary: (beneficiaryId) =>
		`${fundTransfer}/validate-beneficiary/${beneficiaryId}`,
};
