import React, { useEffect, useState } from "react";
import "./FinanceSection.scss";
import Axios from "axios";
import { getErrorMessage } from "../../../../../../utils/helpers";
import { API } from "../../../../../../../lib/api";
import { corporateTransactionStat } from "../../../../../../../lib/api/apiRoutesConfig/services/FundsManagementMS/dashboard/subRoutes";
import { LOADING, FINISHED, ERROR } from "../../../../../../../config/enums";
import { MintInfinite } from "../../../../../utils/ShineLoader/ShineLoader";
import DashboardFinanceCard from "components/utils/DashboardFinanceCard/DashboardFinanceCard";
import TransactionsIcon from "assets/img/finance_transaction_history.svg";
import ApprovedIcon from "assets/img/finance_approved_transactions.svg";
import PendingIcon from "assets/img/finance_pending_transactions.svg";
import DeclinedIcon from "assets/img/finance_declined_transactions.svg";
import StatementIcon from "assets/img/finance_account_statement.svg";

export default function FinanceSection() {
	const [errorMsg, setErrorMsg] = useState("");
	const [transactionStat, setTransactionStat] = useState({});
	const [currentStatus, setCurrentStatus] = useState(LOADING);

	const signal = Axios.CancelToken.source();

	const fetchCorporateTransactionStats = async () => {
		setErrorMsg("");
		try {
			const res = await API.get(corporateTransactionStat, {
				cancelToken: signal.token,
			});
			if (res.status === 200) {
				setTransactionStat(res.data.data);
				setCurrentStatus(FINISHED);
			}
		} catch (err) {
			setCurrentStatus(ERROR);
			setErrorMsg(getErrorMessage(err));
		}
	};

	useEffect(() => {
		fetchCorporateTransactionStats();
		return () => signal.cancel("canceling network calls");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const renderViewBasedOnStatus = () => {
		switch (currentStatus) {
			case LOADING:
				return (
					<div className="dashboard-transaction-error">
						<MintInfinite
							relative
							className="dashboard-transaction-loader"
						/>
					</div>
				);

			case ERROR:
				return (
					<div className="dashboard-transaction-error">
						<h6 className="text-center not-ready">{errorMsg}</h6>
						<button
							className="btn btn-cancel"
							onClick={() => fetchCorporateTransactionStats()}
						>
							Try Again
						</button>
					</div>
				);

			case FINISHED:
				return (
					<div className="dashboard-transaction-grid">
						<DashboardFinanceCard
							title="Transaction History"
							icon={TransactionsIcon}
							to="/transaction-history"
						/>

						<DashboardFinanceCard
							title="Approved Transactions"
							icon={ApprovedIcon}
							to={"/approved-transactions"}
						/>

						<DashboardFinanceCard
							title="Pending Transactions"
							count={transactionStat?.pendingApprovalTransaction}
							icon={PendingIcon}
							to="/pending-transaction-approvals"
						/>

						<DashboardFinanceCard
							title="Declined Transactions"
							icon={DeclinedIcon}
							to="/declined-transaction-approvals"
						/>

						<DashboardFinanceCard
							title="Account Statement"
							icon={StatementIcon}
							to="/account-statement"
						/>
					</div>
				);

			default:
				return null;
		}
	};

	return (
		<div className="col-lg-12">
			<div className="dashboard-transactions">
				<h4 className="sub-heading">Finance</h4>

				<div className="dashboard-transactions-content">
					{renderViewBasedOnStatus()}
				</div>
			</div>
		</div>
	);
}
