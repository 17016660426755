import React from "react";
import { useState } from "react";
import { FINISHED, IDLE, LOADING, ERROR } from "../../../../../../config/enums";
import "./SetupModals.scss";
import { Formik } from "formik";
import * as yup from "yup";
import Modal from "../../../../../utils/Modal/Modal";
import ModalHeader from "../../../../../utils/Modal/CustomModalHeader/CustomModalHeader";
import FormInputGroup from "../../../../../utils/FormInputGroup/FormInputGroup";
import SetupSuccessModal from "./SetupSuccessModal";
import { getErrorMessage } from "components/utils/helpers";
import { API } from "lib/api";
import { taxIdentificationNumber } from "lib/api/apiRoutesConfig/services/Corporate/AccountServiceMS/accountSetup/subRoutes";
import { useSelector } from "react-redux";
import { ToastsStore } from "react-toasts";
import { SmallLoader } from "components/utils/SmallLoader";
import Disclaimer from "../Disclaimer/Disclaimer";

const formValidationSchema = yup.object().shape({
	tin: yup.string().required("Tax Identification Number is required"),
});

export default function TINModal({ show, onClose, onSuccess }) {
	const [currentState, setCurrentState] = useState(IDLE);
	const [successMsg, setSuccessMsg] = useState("");

	const renderBasedOnState = () => {
		switch (currentState) {
			case FINISHED:
				return (
					<SetupSuccessModal
						title="Congratulations"
						message={successMsg}
						buttonLabel="Close"
						onClick={() => onSuccess?.()}
					/>
				);
			default:
				return (
					<TINForm
						setCurrentState={(state) => setCurrentState(state)}
						setSuccessMsg={(message) => setSuccessMsg(message)}
						currentState={currentState}
					/>
				);
		}
	};

	return (
		<Modal show={show}>
			{currentState !== FINISHED && (
				<ModalHeader
					heading="Tax Identification Number"
					className="p-0"
					headerClassName="setup-modals-header px-sm-5 px-3 py-3"
					dismissable={() => onClose?.()}
				/>
			)}
			<>{renderBasedOnState()}</>
		</Modal>
	);
}

const TINForm = ({ currentState, setCurrentState, setSuccessMsg }) => {
	const dashboardResponse = useSelector(
		(state) => state.dashboard.dashboardResponse
	);

	const initialFormValues = {
		tin: "",
	};

	const onHandleSubmit = async (values) => {
		setCurrentState(LOADING);
		try {
			const res = await API.put(
				taxIdentificationNumber(dashboardResponse.mintAccountId),
				null,
				{
					params: {
						tin: values.tin,
					},
				}
			);
			if (res.status === 200) {
				setCurrentState(FINISHED);
				setSuccessMsg(res.data.message);
			}
		} catch (err) {
			setCurrentState(ERROR);
			ToastsStore.error(getErrorMessage(err), 6000, "setup-toast");
		}
	};

	return (
		<Formik
			initialValues={initialFormValues}
			validationSchema={formValidationSchema}
			onSubmit={(values) => onHandleSubmit(values)}
		>
			{({
				values,
				errors,
				touched,
				handleSubmit,
				setFieldValue,
				handleChange,
			}) => {
				return (
					<form
						className="setup-modals-form px-sm-5 px-3 pt-4 pb-5 row"
						onSubmit={(e) => {
							e.preventDefault();
							handleSubmit();
						}}
					>
						<div className="col-12 mb-4">
							<Disclaimer
								message="Mintyn does not file for tax on behalf of your business, as
					part of regulatory requirements we are required to have a
					Tax Identification number on your business."
							/>
						</div>

						<div className="col-12 mb-3">
							<FormInputGroup
								id="tin"
								label="Enter Tax Identification Number"
								name="tin"
								placeholder="Enter TIN"
								className="setup-modal-input"
								value={values.tin}
								onChange={handleChange}
								error={touched.tin && errors.tin}
							/>
						</div>

						<div className="col-12">
							<button
								className="btn btn-mint-default"
								type="submit"
								disabled={currentState === LOADING}
							>
								{currentState === LOADING ? (
									<SmallLoader />
								) : (
									"Submit"
								)}
							</button>
						</div>
					</form>
				);
			}}
		</Formik>
	);
};
