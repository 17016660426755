import React, { Component } from "react";
import {
    formatCurrency,
    SmartTime,
    TruncateParagraph,
} from "../../../../../../../../utils/helpers";
import "aos/dist/aos.css";
import "./LTTableRow.scss";

export default class LTTableRow extends Component {
    render = () => {
        const {
            amount,
            transactionDate,
            narration,
            debitTransaction,
        } = this.props;
        return (
            <tr>
                <td>
                    <div className='trans-dsc'>
                        <p>{TruncateParagraph(narration)}</p>
                        <span>{SmartTime(transactionDate)}</span>
                    </div>
                </td>
                <td>
                    <div
                        className={
                            "trans-res " +
                            (debitTransaction ? "loss" : "profit")
                        }>
                        {formatCurrency(amount)}
                    </div>
                </td>
            </tr>
        );
    };
}
