import React from "react";
import { NavLink } from "react-router-dom";
import AccessControlNavItem from "../../../auth/AccessControlNavItem/AccessControlNavItem";

const SideNavItem = ({ route, index, activeRoute, trustClick }) => {
	const {
		path,
		title,
		iconName,
		dropDowns,
		groupAccessLevel,
		accessLevel,
		hide,
	} = route;

	const findNav = dropDowns
		? dropDowns.find((navItem) => !navItem.hide)
		: false;

	return (
		<AccessControlNavItem
			allowedPermissions={accessLevel || groupAccessLevel}
		>
			<li
				key={index}
				className={findNav ? "nav-item dropdown" : ""}
				id={`sidenav-item${index}`}
			>
				{!hide && (
					<NavLink
						to={path}
						onClick={(e) => {
							if (findNav) {
								e.preventDefault();
								return;
							}
							trustClick();
						}}
						className={`${
							findNav && "dropdown-toggle"
						} ${activeRoute(path, dropDowns)}`}
						onSelect={() => alert("me")}
						data-toggle={findNav && "dropdown"}
						exact
					>
						<span className={`side-icon ${iconName}`}></span>
						<span className="side-text pt-1">{title}</span>
					</NavLink>
				)}

				{findNav && (
					<div className="dropdown-menu">
						<form>
							{dropDowns.map(
								(
									{
										title,
										path,
										hide,
										accessLevel: dropdownAccessLevel,
									},
									i
								) => {
									return (
										!hide && (
											<AccessControlNavItem
												key={i}
												allowedPermissions={
													dropdownAccessLevel
														? dropdownAccessLevel
														: accessLevel ||
														  groupAccessLevel
												}
											>
												<NavLink
													className="dropdown-item"
													to={path}
												>
													{title}
												</NavLink>
											</AccessControlNavItem>
										)
									);
								}
							)}
						</form>
					</div>
				)}
			</li>
		</AccessControlNavItem>
	);
};

export default SideNavItem;
