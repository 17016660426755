import React, { Component } from "react";
import ReactHelmet from "../../../../utils/ReactHelmet/ReactHelmet";
import { SmallLoader } from "../../../../utils/SmallLoader";
import ResendFPCode from "./ResendFPCode/ResendFPCode";
import { verifyFPActivationCode } from "../../../../../lib/api/apiRoutesConfig/services/AccountMS/forgot-password/subRoutes";
import { API, httpSignal } from "../../../../../lib/api";
import { getErrorMessage } from "../../../../utils/helpers";
import Logo from "assets/img/new-logo.png";
import FormInputGroupV2 from "components/utils/FormInputGroup/FormInputGroupV2";

class VerifyFPPhoneModel extends Component {
	_isMounted = false;
	signal = httpSignal;

	state = {
		loading: false,
		code: "",
	};

	componentDidMount = () => {
		this._isMounted = true;
	};

	componentWillUnmount = () => {
		this.signal.cancel("canceling network calls");
		this._isMounted = false;
	};

	handleInputChange = (e) => {
		let { name, value } = e.target;
		value = value.replace(/[^0-9]/g, "");
		this.setState({ [name]: value });
	};

	verifyCustomerCode = async () => {
		try {
			this.setState({ loading: true, confirmEmailError: false });
			const { code } = this.state;
			const { sessionId } = this.props;
			const url = verifyFPActivationCode(sessionId);
			const res = await API.post(
				url,
				{ code },
				{
					cancelToken: this.signal.token,
				}
			);
			if (res.status === 200) {
				let redirectUrl = `/forgot-password/${sessionId}/answer-security-questions`;
				const { code, question } = res.data.data || {};
				this.props.history.replace(redirectUrl, {
					userSecurityQuestion: [{ label: question, value: code }],
				});
			}
		} catch (err) {
			this._isMounted &&
				this.setState({
					loading: false,
					confirmEmailError: getErrorMessage(err),
				});
		}
	};

	handleSubmit = (e) => {
		e.preventDefault();
		let { code } = this.state;
		code.length === 6 && this.verifyCustomerCode();
	};

	render() {
		const { confirmEmailError, loading } = this.state;
		return (
			<>
				<ReactHelmet title="Verify Phone" />
				<div className="row h-100 justify-content-center align-items-center">
					<div className="col-xl-9 col-lg-9 col-md-9">
						<div className="verif-center px-4">
							<div className="mintyn-logo">
								<a href="https://mintyn.com/">
									<img
										alt="Mintyn Bank"
										width={500}
										height={500}
										src={Logo}
									/>
								</a>
							</div>
							<div className="account-verification-panel">
								<h6 className="mb-3 info-heading">
									Account Verification
								</h6>
								<p className="info-text">
									To complete your account verification,
									<br />
									enter the code that was sent to your phone
									<br /> <br />
								</p>

								{confirmEmailError && (
									<div className="desct error fade-in">
										<p>{confirmEmailError}</p>
									</div>
								)}

								<div className="mt-5">
									<form onSubmit={this.handleSubmit}>
										<div className="form-row">
											<div className="col-9">
												<FormInputGroupV2
													name="code"
													type="text"
													value={this.state.code}
													maxLength={6}
													autoComplete="off"
													disabled={loading}
													placeholder="Enter six digit code"
													required
													onChange={
														this.handleInputChange
													}
												/>
											</div>
											<div className="col-3">
												<button
													className="btn-primary w-100"
													disabled={loading}
												>
													{loading ? (
														<SmallLoader />
													) : (
														"Verify"
													)}
												</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
					<ResendFPCode sessionId={this.props.sessionId} />
				</div>
			</>
		);
	}
}

export default VerifyFPPhoneModel;
