import React, { Component } from "react";
import PlusSign from "./files/small-pluss.svg";

export default class CreateBeneficiaryFilterRecords extends Component {
    render() {
        const {
            toggleAdditionBeneficiary,
            handleSearchFilterInput,
        } = this.props;
        return (
            <div className='container px-5 pt-3'>
                <div className='row mb-3'>
                    <div className='col-md-5'>
                        <input
                            name='globalSearch'
                            onChange={handleSearchFilterInput}
                            style={{ fontSize: "12px" }}
                            className='form-control transSearch'
                            placeholder='Search Beneficiaries'
                        />
                    </div>
                    <div className='col-md-4'></div>
                    <div className='col-md-3'>
                        <button
                            className='btn shupNew w-100'
                            onClick={toggleAdditionBeneficiary}>
                            <img src={PlusSign} className='mr-1' alt='plus' />
                            Add New
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
