import React, { Component } from 'react';
import "../../css/alert.css"

export default class Alert extends Component {
    static defaultProps = {
        type: "warning",
        message: "",
    }

    state = {
        check: true
    }

    _Toggle() {
        this.setState(prevState => ({
            check: !prevState.check
        }));
    }
    render() {
        return (
            ((this.props.message) !== "") ?
                <div className={"alert alert-" + this.props.type + " alert-dismissible fade " + ((this.state.check) ? "show" : "d-none")} role="alert">
                    {this.props.message}
                    <button type="button" className="close" onClick={this._Toggle.bind(this)}>
                        <span aria-hidden="true" className="arial">&times;</span>
                    </button>
                </div>
                : ""
        )
    }
}