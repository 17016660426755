import React from "react";
import PropTypes from "prop-types";
import "./PageNavigator.scss";

const PageNavigator = ({ label }) => (
	<ul className="nav nav-tabs" id="PageNavigator">
		<li className="nav-item">
			{/* eslint-disable-next-line */}
			<a className="nav-link active" href={null}>
				{label}
			</a>
		</li>
	</ul>
);

PageNavigator.propTypes = {
	label: PropTypes.string,
};

export default PageNavigator;
