import BillsManager from "../../BillsManager/BillsManager";
import BettingAndGaming from "../../BillsPayment/BettingGaming/BettingGaming";
import CableTv from "../../BillsPayment/CableTv/CableTv";
import DataRecharge from "../../BillsPayment/DataRecharge/DataRecharge";
import InternetServices from "../../BillsPayment/InternetServices/InternetServices";
import MobileRecharge from "../../BillsPayment/MobileRecharge/MobileRecharge";
import RemitaViaCategory from "../../BillsPayment/Remita/PayViaCategory/PayViaCategory";
import RemitaViaRRR from "../../BillsPayment/Remita/PayViaRRR/PayViaRRR";
import Remita from "../../BillsPayment/Remita/Remita";
import UtilityAndElectricity from "../../BillsPayment/UtilityElectricity/UtilityElectricity";
import BillsPaymentCategories from "../BillsPaymentCategories/BillsPaymentCategories";

export const defaultPath = "/pay-bills";

export default [
	{
		title: "Bills Payment",
		path: "/pay-bills",
		component: BillsPaymentCategories,
	},
	{
		title: "Bills Manager",
		path: "/manage-bills",
		component: BillsManager,
	},
];

export const billCategoryRoutes = [
	{
		title: "Mobile Recharge",
		path: `${defaultPath}/mobile-recharge`,
		component: MobileRecharge,
	},
	{
		title: "Data Bundle",
		path: `${defaultPath}/data-bundle`,
		component: DataRecharge,
	},
	{
		title: "Cable TV",
		path: `${defaultPath}/cable-tv`,
		component: CableTv,
	},
	{
		title: "Utility/Electricity",
		path: `${defaultPath}/utility-electricity`,
		component: UtilityAndElectricity,
	},
	{
		title: "Betting & Gaming",
		path: `${defaultPath}/betting-gaming`,
		component: BettingAndGaming,
	},
	{
		title: "Internet Services",
		path: `${defaultPath}/internet-services`,
		component: InternetServices,
	},
	{
		title: "Remita",
		path: `${defaultPath}/remita/`,
		component: Remita,
	},
];

export const remitaCategoryRoutes = [
	{
		title: "Remita - Pay via category",
		path: `${defaultPath}/remita/pay-via-category`,
		component: RemitaViaCategory,
	},
	{
		title: "Remita - Pay via RRR",
		path: `${defaultPath}/remita/pay-via-rrr`,
		component: RemitaViaRRR,
	},
];
