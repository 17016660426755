import React, { Component } from "react";
import ModalHeader from "../../../../../../../../utils/Modal/CustomModalHeader/CustomModalHeader";
import DynamicSelect from "../../../../../../../../utils/DynamicSelect/DynamicSelect";
import { getErrorMessage } from "../../../../../../../../utils/helpers";
import { API } from "../../../../../../../../../lib/api";
import { getSecurityQuestions } from "../../../../../../../../../lib/api/apiRoutesConfig/services/AccountMS/common/subRoutes";
import TranscationPinInput from "../../../../../../transcations/TranscationPinInput";

class ResetTransactionPinForm extends Component {
    _mounted = false;

    state = {
        request: {
            answer: "",
            questionCode: "",
        },
    };

    componentDidMount = () => (this._mounted = true);
    componentWillUnmount = () => (this._mounted = false);

    fetchSecurityQuestions = async () => {
        try {
            this._mounted &&
                this.setState({ isFetching: true, fetchQuesError: false });
            let res = await API.get(getSecurityQuestions);
            if (res.status === 200) {
                let questionList = [];
                res.data.data.forEach(({ code, question }) => {
                    questionList.push({ label: question, value: code });
                });
                this._mounted && this.setState({ isFetching: false });
                return questionList;
            }
        } catch (err) {
            this._mounted &&
                this.setState({
                    fetchQuesError: getErrorMessage(err),
                    isFetching: false,
                });
        }
    };

    handleInput = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
            ...prevState,
            request: {
                ...prevState.request,
                [name]: value,
            },
        }));
    };

    handleSelect = ({ value }, { name }) => {
        this.setState((prevState) => ({
            ...prevState,
            request: {
                ...prevState.request,
                [name]: value,
            },
        }));
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const { request } = this.state;
        const { processRequest } = this.props;
        const { newPinInput } = this.refs;
        const pin = newPinInput.getPin();
        request.newPin = pin;
        pin.length !== 4 ? newPinInput.shakeInvalid() : processRequest(request);
    };

    canBeSubmitted = () => {
        const {
            request: { answer, questionCode },
        } = this.state;
        return answer === "" || questionCode === "";
    };

    render() {
        const { toggleModal } = this.props;
        const { isFetching, fetchQuesError } = this.state;
        const isDisabled = this.canBeSubmitted();

        return (
            <>
                <ModalHeader
                    heading={"Reset Transaction Pin"}
                    dismissable={toggleModal}
                />
                <div className='modal-body'>
                    <div className='low-form'>
                        <form onSubmit={this.handleSubmit}>
                            <div className='form-group'>
                                <label>Select Security Question</label>
                                <DynamicSelect
                                    name='questionCode'
                                    onChange={this.handleSelect}
                                    loadOptions={this.fetchSecurityQuestions}
                                    isLoading={isFetching}
                                    serverError={fetchQuesError}
                                />
                            </div>
                            <div className='form-group'>
                                <label>Provide Security Answer</label>
                                <input
                                    className={`form-control more primary `}
                                    name='answer'
                                    onChange={this.handleInput}
                                    autoComplete='off'
                                />
                            </div>
                            <label>New Pin</label>
                            <div className='rounded-bordered mb-3'>
                                <TranscationPinInput
                                    label=''
                                    ref='newPinInput'
                                    className='my-3'
                                    id='pin1'
                                />
                            </div>

                            <button
                                className='btn btn-mint-default'
                                disabled={isDisabled}>
                                complete
                            </button>
                        </form>
                    </div>
                </div>
            </>
        );
    }
}

export default ResetTransactionPinForm;
