import React, { Component } from "react";
import IncomeExpenseBarChart from "./IncomeExpenseBarChart";
import "./IncomeExpense.scss";

export default class IncomeExpense extends Component {
	render = () => {
		return (
			<div className="col-lg-7 mt-4 mt-lg-0">
				<div className="head-wyx income-expenses-card-header row align-items-center pl-3 pt-4">
					<h4 className="sub-heading">Income & expenses chart</h4>
				</div>
				<IncomeExpenseBarChart />
			</div>
		);
	};
}
