import React from "react";

const PageNavigatorV2 = ({ title, subtitle, children }) => (
	<div className="flex__between">
		<div className="w-100">
			<h3 className="sub-heading m-0">{title}</h3>
			<span className="tiny__message m-0 font-weight-normal">
				{subtitle}
			</span>
		</div>
		<div>{children}</div>
	</div>
);

export default PageNavigatorV2;
