import Cookies from "js-cookie";
import {
	SHOW_VERIFY,
	UPDATE_CUSTOMER_AUTH_INFO,
	UPDATE_USER_ID,
	COMPLETE_REGISTRATION,
	PROMPT_RESETPASSWORD,
	LOG_USER,
	SET_CONNECTION,
	KEEP_LOGGED_IN,
	LOGOUT_USER,
	KICK_USER_OUT,
	CLEAR_SESSION_MESSAGE,
	ALT_PHONE_NUMBER_VERIFIED,
	UPDATE_IS_LOGGED_IN,
} from "../actions/types";
import {
	MINTYN_USER_NEW_ACCOUNT,
	MINTYN_USER_TOKEN,
} from "components/constants";

const initState = {
	kickedOut: false,
	sessionMessage: "",
	showVerifivation: false,
	altPhoneVerified: false,
	nowProfile: false,
	newAccount: JSON.parse(Cookies.get(MINTYN_USER_NEW_ACCOUNT) || "{}"),
	regNotComplete: false,
	passwordChangeRequired: false,
	reqUsername: "",
	isLoggedIn: !!Cookies.get(MINTYN_USER_TOKEN),
	currentUser: {},
	loginResponse: {},
	dashboardResponse: {},
	userPermissions: [],
	activeAccountType: null,
};

export const completeReg = () => ({ type: COMPLETE_REGISTRATION });
export const updateUserID = (data) => ({ type: UPDATE_USER_ID, payload: data });

export const promptPasswordChange = (data) => ({
	type: PROMPT_RESETPASSWORD,
	payload: data,
});

export default (state = initState, action) => {
	switch (action.type) {
		case SHOW_VERIFY:
			return {
				...state,
				showVerifivation: true,
				nowProfile: false,
				newAccount: action.payload,
			};

		case UPDATE_CUSTOMER_AUTH_INFO:
			return { ...state, newAccount: action.payload };

		case COMPLETE_REGISTRATION:
			return { ...state, regNotComplete: true };

		case UPDATE_USER_ID:
			return { ...state, newAccount: action.payload };

		case PROMPT_RESETPASSWORD:
			return {
				...state,
				passwordChangeRequired: true,
				tempUsername: action.payload,
			};

		case ALT_PHONE_NUMBER_VERIFIED:
			return {
				...state,
				altPhoneVerified: true,
				showVerifivation: true,
				nowProfile: false,
				newAccount: action.payload,
			};

		case LOG_USER:
			return {
				...state,
				isLoggedIn: true,
				currentUser: action.payload,
				loginResponse: action.specific,
				activeAccountType: action.activeAccountType,
				userPermissions: action.accountTypeCode,
			};

		case KEEP_LOGGED_IN:
			return {
				...state,
				isLoggedIn: true,
				currentUser: state.currentUser,
				loginResponse: state.loginResponse,
				activeAccountType: state.activeAccountType,
				userPermissions: state.userPermissions,
			};

		case LOGOUT_USER:
			return {
				...state,
				isLoggedIn: false,
				currentUser: {},
				loginResponse: {},
				activeAccountType: null,
				userPermissions: [],
			};

		case KICK_USER_OUT:
			return {
				...state,
				kickedOut: true,
				sessionMessage: action.payload,
			};

		case CLEAR_SESSION_MESSAGE:
			return {
				...state,
				kickedOut: false,
				sessionMessage: "",
			};

		case SET_CONNECTION:
			const accountType =
				state.activeAccountType === "INDIVIDUAL"
					? "activeIndividualAccount"
					: "activeCorporateAccount";
			return {
				...state,
				loginResponse: {
					...state.loginResponse,
					[accountType]: {
						...state.loginResponse[accountType],
						profileSetting: {
							...state.loginResponse[accountType].profileSetting,
							transactionPinCreated: true,
						},
					},
				},
			};

		case UPDATE_IS_LOGGED_IN:
			return {
				...state,
				isLoggedIn: action.payload,
			};

		default:
			return state;
	}
};
