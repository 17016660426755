export const SavingsMS = {
	msName: "savings-service",
	defaultPath: "/api/",
	msVersion: "v1",
	get default() {
		return this.msName + this.defaultPath + this.msVersion;
	},
	get v2() {
		return this.msName + this.defaultPath + "v2";
	},
	get v3() {
		return this.msName + this.defaultPath + "v3";
	},
};
