import PropTypes from "prop-types";
import React from "react";
import Select from "react-select";
import { SelectBox } from "../../styles/SelectBox";

const CommonSelect = ({
	name,
	handleChange,
	placeholder,
	options,
	optDesc,
	optValue,
	error,
	showErrorMsg,
	isSearchable = false,
	...props
}) => {
	return (
		<>
			<Select
				name={name}
				inputId={name}
				onChange={handleChange}
				isSearchable={isSearchable}
				styles={SelectBox(error)}
				classNamePrefix="react-select"
				placeholder={placeholder}
				options={options}
				getOptionLabel={(option) =>
					optDesc ? option[optDesc] : option.label
				}
				getOptionValue={(option) =>
					optValue ? option[optValue] : option.value
				}
				menuPortalTarget={document.body}
				blurInputOnSelect={false}
				{...props}
			/>
			{showErrorMsg && error && (
				<span className="error-msg">{error}</span>
			)}
		</>
	);
};

CommonSelect.propTypes = {
	error: PropTypes.any,
	handleChange: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	options: PropTypes.array,
	placeholder: PropTypes.string,
};

CommonSelect.defaultProps = {
	error: "",
	placeholder: "",
};

export default CommonSelect;
