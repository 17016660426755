import {
	UPDATE_PROFILE_SETTINGS,
	SET_SECURITY_QUESTION,
	UPDATE_CUSTOMER_SETTINGS,
	UPDATE_NOTIFICATION_SETTTING,
	UPDATE_CUSTOMER_TRANSFER_LIMITS,
	UPDATE_CUSTOMER_ID,
} from "../actions/types";

const initState = {
	profileSettings: {
		identityCardVerificationStatus: "",
		passwordChangeRequired: false,
		securityQuestionCreated: false,
		transactionPinCreated: false,
	},
	notifications: {
		emailEnabled: true,
		smsEnabled: true,
		gcmEnabled: true,
	},
	mintAccount: {
		spendingLimit: {},
	},
};

export default (state = initState, action) => {
	switch (action.type) {
		case UPDATE_PROFILE_SETTINGS:
			return {
				...state,
				profileSettings: action.payload,
			};

		case UPDATE_CUSTOMER_SETTINGS:
			return {
				...state,
				profileSettings: action.profilePayload,
				notifications: action.notisPayload,
				mintAccount: action.mintAccountPayload,
			};

		case UPDATE_NOTIFICATION_SETTTING:
			return {
				...state,
				notifications: {
					...state.notifications,
					[action.name]: !state.notifications[action.name],
				},
			};

		case UPDATE_CUSTOMER_TRANSFER_LIMITS:
			return {
				...state,
				mintAccount: {
					...state.mintAccount,
					spendingLimit: {
						...state.mintAccount.spendingLimit,
						...action.newSpendLimit,
					},
				},
			};

		case SET_SECURITY_QUESTION:
			return {
				...state,
				profileSettings: {
					...state.profileSettings,
					securityQuestionCreated: true,
				},
			};

		case UPDATE_CUSTOMER_ID:
			return {
				...state,
				profileSettings: {
					...state.profileSettings,
					identityCardVerificationStatus: action.payload,
				},
			};

		default:
			return state;
	}
};
