import React from "react";
import { LIQUIDATE, TOP_UP } from "../../extra";
import LiquidateInvestmentModal from "../LiquidateInvestmentModal/LiquidateInvestmentModal";
import TopUpInvestmentModal from "../TopUpInvestmentModal/TopUpInvestmentModal";

const InvestmentActionModal = ({
	showModal,
	currentAction,
	investment,
	toggleModal,
}) => {
	const render = () => {
		switch (currentAction) {
			case LIQUIDATE:
				return (
					<LiquidateInvestmentModal
						showModal={showModal}
						investment={investment}
						toggleModal={toggleModal}
					/>
				);

			case TOP_UP:
				return (
					<TopUpInvestmentModal
						showModal={showModal}
						investment={investment}
						toggleModal={toggleModal}
					/>
				);

			default:
				return <></>;
		}
	};
	return <div>{render()}</div>;
};

export default InvestmentActionModal;
