/* eslint-disable no-mixed-operators */
import React, { useState } from "react";
import FormInputGroup from "components/utils/FormInputGroup/FormInputGroup";
import { Formik } from "formik";
import * as yup from "yup";
import { SmallLoader } from "components/utils/SmallLoader";
import CustomFileUploader from "components/utils/CustomFileUploader/CustomFileUploader";
import { getErrorMessage } from "components/utils/helpers";
import { getValidMobileNumberLength } from "components/utils/helpers";
import { NATIONAL_ID } from "../utils/exportData";
import { postTrusteesIdAction } from "lib/api/apiRoutesConfig/services/AccountMS/CAC/subRoutes";
import { API } from "lib/api";
import { ToastsStore } from "react-toasts";
const SUPPORTED_FORMATS = ["image/png", "image/jpg", "image/jpeg"];
const FILE_SIZE = 3_000_000;

const TrusteeId = ({ setSteps, regInfo, refresh }) => {
	const [isLoading, setIsLoading] = useState(false);

	const formValidationSchema = yup.object().shape({
		cardNumber: yup
			.string()
			.min(11, "Invalid !")
			.max(11, "Invalid")
			.required("NIN is required"),
		card: yup
			.mixed()
			.test("is-a-supported-type", "Unsupported file format", (value) => {
				if (value) return SUPPORTED_FORMATS.includes(value?.type);
				return true;
			})
			.test(
				"is-not-more-than-max-file-size",
				"File size is too large",
				(value) => {
					if (value) return value?.size <= FILE_SIZE;
					return true;
				}
			)
			.required("Document is required"),
	});

	const initialFormValues = {
		cardNumber: "",
		card: null,
		cardType: NATIONAL_ID,
		id: regInfo.id,
	};

	// Handle form submit
	const handleSubmit = async (values) => {
		setIsLoading(true);
		const payload = new FormData();
		payload.append("card", values.card || "");

		try {
			const res = await API.put(
				postTrusteesIdAction(regInfo.id),
				payload,
				{
					params: {
						...values,
					},
				}
			);
			const { status, data } = res || {};
			if (status === 200) {
				refresh(localStorage.getItem("s_id"));
				// setSteps(4);
				ToastsStore.success(data.message, 6000, "right-toast");
			}
		} catch (err) {
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			<Formik
				initialValues={initialFormValues}
				validationSchema={formValidationSchema}
				onSubmit={(values) => handleSubmit(values)}
			>
				{({
					values,
					errors,
					touched,
					resetForm,
					isValid,
					handleSubmit,
					setFieldValue,
					handleChange,
					handleBlur,
				}) => {
					return (
						<form className="reg-business-form  row">
							<div className="mb-4 col-12">
								<FormInputGroup
									id="cardNumber"
									label="NIN Number"
									name="cardNumber"
									placeholder="00000000000000"
									className="red-business-input"
									onBlur={handleBlur}
									value={values.cardNumber}
									onChange={(e) => {
										const value = e.target.value
											.trim()
											.replace(/[^+0-9]/i, "");
										if (
											value.length <=
											getValidMobileNumberLength(value)
										) {
											setFieldValue(e.target.name, value);
										}
									}}
									error={
										touched.cardNumber && errors.cardNumber
									}
								/>
							</div>
							<div className="mb-4 col-12">
								<CustomFileUploader
									label="Upload ID"
									name="card"
									error={touched.card && errors.card}
									fileName={values.card?.name}
									onChange={(file) => {
										setFieldValue("card", file);
									}}
								/>
							</div>
							<div className="mb-4 col-12 ">
								<button
									name="card"
									onClick={(file) => {
										// resetForm("card")
										setFieldValue("card", null);
									}}
									className="btn  remove-upload"
									type="button"
									disabled={isLoading}
								>
									<span>Remove</span>
								</button>
							</div>

							<div className="col-12 ">
								{/* ()=>setShowTermsModal(true) */}
								<button
									onClick={handleSubmit}
									className="btn btn-mint-default"
									type="button"
									disabled={isLoading}
								>
									{isLoading ? <SmallLoader /> : "Continue"}
								</button>
							</div>
						</form>
					);
				}}
			</Formik>
		</>
	);
};
export default TrusteeId;
