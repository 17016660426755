/* eslint-disable react-hooks/exhaustive-deps */

import CommonSelect from "components/utils/CommonSelect/CommonSelect";
import FormInputGroup from "components/utils/FormInputGroup/FormInputGroup";
import { Formik } from "formik";
import { formatCurrency } from "components/utils/helpers";
import React, { useState, useEffect } from "react";
import { API } from "lib/api";
import "./PaymentPanel.scss";
import PaymentOptions from "./PaymentOptions";
import accountDetails from "lib/api/apiRoutesConfig/services/FundsManagementMS/dashboard";
import { ToastsStore } from "react-toasts";
import { getErrorMessage } from "components/utils/helpers";
import * as yup from "yup";
import { deliveryAddressAction } from "lib/api/apiRoutesConfig/services/tradeMS/individualTrade/subRoutes";
import { confirmOrderAction } from "lib/api/apiRoutesConfig/services/tradeMS/individual/subRoutes";
import { payBalanceAction } from "lib/api/apiRoutesConfig/services/tradeMS/BNPL/subRoutes";
import { ERROR, FINISHED, IDLE, LOADING } from "config/enums";
import { useSelector } from "react-redux";
import { GROUP_CART, JOINT_ORDER_DELIVERY, SINGLE_ORDER_DELIVERY } from "../utils/constant";
import OrderConfirmed from "./OrderConfirmed";
import EnterOTP from "./EnterOTP";
import PayLater from "./PayLater";
import PayUpFront from "./PayUpFront";
import ClearBalance from "./ClearBalance";
import { dashboardDetails } from "lib/api/apiRoutesConfig/services/tradeMS/Dashboard";
import { INDIVIDUAL } from "../utils/constant";
import EnterPin from "../RepaymentSchedule/EnterPin";

export default function PaymentPanel({
	goToNextStep
}) {
	const { myCartType,enableConfirmation} = useSelector((state) => state.marketPlace);
	const [showPaymentModal, setShowPaymentsModal] = useState(false);
	const [showConfirmedOrderModal, setShowConfirmedOrderModal] = useState(false);
	const [showPayLaterModal, setShowPayLaterModal] = useState(false);
	const [showOtpModal, setShowOtpModal] = useState(false);
	const [showPayUpfrontModal, setShowPayUpfrontModal] = useState(false);
	const [showClearBalanceModal,setShowClearBalanceModal]= useState(false)
	 const [showEnterPinModal, setShowEnterPinModal] = useState(false);
	const [accList, setAccList] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [address, setAddress] = useState([]);
	const [, setCurrentStatus] = useState(IDLE);
	const [, setErrorMsg] = useState("");
	const [isChecked, setIsChecked] = useState('payNow')
	const [userInfo, setUserInfo] = useState();
	const [orderId, setOrderId] = useState('')

	const [pin, setPin] = useState('');
	const [deliveryAddressId, setDeliveryAddressId] = useState('');
  const [debitAccountId, setDebitAccountId] = useState('');
	const PAY_NOW = 'payNow'
	const { customerType } = userInfo || {};
	const currentCustomer = customerType === INDIVIDUAL? userInfo?.individualCustomer
		: userInfo?.resellerCustomer;
	const { buyNowPayLaterPaymentPending } = currentCustomer || {}
		const initialFormValues = {
		debitAccountId:null,
		checkOutCode: "",
		checkOutCartType: "",
		deliveryAddressId: "",
		deliveryType:''
	};

	const currentDeliveryType = myCartType === GROUP_CART ? JOINT_ORDER_DELIVERY : SINGLE_ORDER_DELIVERY;

	const formValidationSchema = yup.object().shape({

	// debitAccountId: yup.object().required("Account is required"),
		debitAccountId: yup.object().required("Select an account").shape({
		label: yup.string(),
		value: yup.mixed(),
	}),
	checkOutCode: yup.string(),
});

	
		const fetchUserDetails = async () => {
		try {
			const res = await API.get(dashboardDetails);
			const { status, data } = res || {};
			if (status === 200) {
				// console.log(data.data)
				setUserInfo(data.data)

			}
		} catch (err) {
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		} finally {
			setIsLoading(false);
		}
	};

	const fetchAccounts = async () => {
		try {
			const res = await API.get(accountDetails);
			const { status, data } = res || {};
			if (status === 200) {
				setAccList(data.data.bankAccounts);
			}
		} catch (err) {
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		} finally {
			setIsLoading(false);
		}
	};

		const fetchDeliveryDetails = async () => {
		setCurrentStatus(LOADING);
		try {
			const response = await API.get(deliveryAddressAction);
			const { status, data } = response || {};
			if (status === 200) {
				setAddress(data.data);
				setCurrentStatus(FINISHED);
			}
		} catch (err) {
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
			setErrorMsg(getErrorMessage(err));
			setCurrentStatus(ERROR);
		}
	};

	// Get current delivery address
	const currentAddress = address.filter((obj) => {
		return obj.defaultAddress === true
		
	})

	// Handle next action
  const handleNextAction = () => {
    setShowEnterPinModal(false)
    setShowConfirmedOrderModal(true)
  }
	// Buy now pay later
	  const handleSubmitBNPL = async (values) => {
    setIsLoading(true);

    try {
			const res = await API.post(payBalanceAction, {
        authValue:pin,
        debitAccountId:debitAccountId || '',
			});
			const { status } = res || {};
			if (status === 200) {
        handleNextAction()
				
				setShowConfirmedOrderModal(true)
				// ToastsStore.success(data.message, 6000, "right-toast");
			}
		} catch (err) {
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		} finally {
			setIsLoading(false);
		}
  }

	// Pay now
		const handleSubmit = async (values) => {
		setIsLoading(true);
		try {
			const res = await API.post(confirmOrderAction, {
				...values,
				debitAccountId: values.debitAccountId.value,
				deliveryAddressId: currentAddress?.[0]?.id,
				checkOutCartType: myCartType,
				deliveryType: currentDeliveryType,
				productType:'ALL'
			});
			const { status,data } = res || {};
			if (status === 200) {
				setOrderId(data?.data?.orderId)
				setShowConfirmedOrderModal(true)
				goToNextStep()
				// ToastsStore.success(data.message, 6000, "right-toast");
			}
		} catch (err) {
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		} finally {
			setIsLoading(false);
		}
	};

	// currentAddress?.[0]?.id
	// Handle pay later modal
	const handlePayLaterModal = () => {
		localStorage.setItem("addId", currentAddress?.[0]?.id)
		if (buyNowPayLaterPaymentPending) {
			setShowClearBalanceModal(true)
		} else {
			setShowPayLaterModal(true)
		}
	}

		// Handle OTP modal
	const handleOtpModal = () => {
		setShowOtpModal(true)
	}
		// Handle Pay upfront modal
	const handlePayUpfrontModal = () => {
		setShowPayUpfrontModal(true)
	}

		useEffect(() => {
			fetchAccounts();
			fetchDeliveryDetails();
		}, []);
	
	 useEffect(() => {
    setDeliveryAddressId(localStorage.getItem("addId"))
    setDebitAccountId(localStorage.getItem("deId"))
		}, [debitAccountId,deliveryAddressId, ]);
	
	return (
		<>
			<div className="mkt-payment-panel">
				<div className="mkt-payment-panel__header">
					<h2>Choose a preferred payment option</h2>
				</div>
				<div className="mkt-payment-panel__content">
					<Formik
						initialValues={initialFormValues}
						validationSchema={formValidationSchema}
						onSubmit={(values) => handleSubmit(values)}
							
					>
						{({
							values,
							errors,
							touched,
							handleSubmit,
							setFieldValue,
							handleChange,
							handleBlur,
						}) => {
							return (
								<form
									className="mkt-payment-form row"
									onSubmit={(e) => {
										e.preventDefault();
										handleSubmit();
									}}
								>
									<div className="mb-4 col-12">
										<CommonSelect
											name="debitAccountId"
											placeholder="Select Account"
											error={touched.state && errors.state}
											value={values.debitAccountId || null}
											onBlur={handleBlur}
											handleChange={(debitAccountId) => {
												setFieldValue("debitAccountId", debitAccountId);
												setShowPaymentsModal(true)
												fetchUserDetails()
												localStorage.setItem("deId", debitAccountId?.value)
												}}
											showErrorMsg
											options={accList.map((account) => ({
												label: account?.accountNumber
													+ " ( " + formatCurrency(account?.availableBalance) + " )",
												value: account?.accountId,
								}))}
										/>
									</div>
									<div className="mb-4 col-12">
										<FormInputGroup
											id="checkOutCode"
											name="checkOutCode"
											value={values.checkOutCode}
											label="Affiliate Code (Optional)"
											className="mkt-payment-input"
											onBlur={handleBlur}
											onChange={handleChange}
										/>

										<p className="mkt-payment-form-info  ">
											Affiliate Code is a code gotten from
											a friend or trade partner that
											allows us to award them freebies
											when someone uses their code
										</p>
									</div>
									<div className="col-12">
										<button
											className={`btn btn-mint-default text-capitalize ${!enableConfirmation? 'disabled': ''} `}
											disabled={!enableConfirmation? true : false}
											type="submit"
										>
											Confirm Payment
										</button>
									</div>
								</form>
							);
						}}
					</Formik>
				</div>
				<OrderConfirmed
					isChecked={isChecked}
					balanceStatus={buyNowPayLaterPaymentPending}
					title={buyNowPayLaterPaymentPending && isChecked !== PAY_NOW?'Payment successful' : 'Personal Cart Order'}
					rText={buyNowPayLaterPaymentPending && isChecked !== PAY_NOW? 'Continue to Checkout' :'Continue Shopping'}
					content={!buyNowPayLaterPaymentPending || isChecked === PAY_NOW?
						`Thank you for choosing Mintyn Marketplace, your personal cart order ${ orderId } has been confirmed and is currently being processed.` : `Congratulations! You have successfully cleared your balance. You can proceed to buy more groceries.`}
					show={showConfirmedOrderModal}
					onClose={() => setShowConfirmedOrderModal(false)}
				/>
				<PaymentOptions
					isChecked={isChecked}
					setIsChecked={setIsChecked}
					show={showPaymentModal}
					nextModal={handlePayLaterModal}
					balanceStatus={buyNowPayLaterPaymentPending}
					onClose={() => setShowPaymentsModal(false)}
				/>
				<PayLater
					show={showPayLaterModal}
					nextModal={handleOtpModal}
					onClose={() =>setShowPayLaterModal(false)}
				/>
				<EnterOTP
					show={showOtpModal}
					nextModal={handlePayUpfrontModal}
					onClose={() =>setShowOtpModal(false)}
				/>
				<PayUpFront
					show={showPayUpfrontModal}
					onClose={() =>setShowPayUpfrontModal(false)}
				/>

				<ClearBalance
					show={showClearBalanceModal}
					nextModal={() => {
						setShowEnterPinModal(true)
					}}
					onClose={() =>setShowClearBalanceModal(false)}
				/>
					<EnterPin
					show={showEnterPinModal}
					nextModal={handleSubmitBNPL}
					handleChange={setPin}
					pin={pin}
					isLoading={isLoading}
					onClose={() =>setShowEnterPinModal(false)}
				/>
			
			</div>
			
		</>
	);
}
