import React, { useState } from "react";
import queryString from "query-string";
import { connect } from "react-redux";
import { API } from "../../../../lib/api";
import { ReactComponent as InfoIcon } from "../../../../assets/img/info.svg";
import { validateWaybillDriver } from "../../../../lib/api/apiRoutesConfig/services/tradeMS/common/subRoutes/waybill";
import { getErrorMessage } from "../../../utils/helpers";
import {
	nextStep,
	updateWaybillDetails,
} from "../../../../actions/waybillComfirmation";
import Alert from "../../../utils/Alert";
import { SmallLoader } from "../../../utils/SmallLoader";
import PhoneNumberInput from "../../../utils/PhoneNumberInput/PhoneNumberInput";
import FadeIn from "../../../dashboard/components/effects/FadeIn";

const WaybillDriverVerification = ({
	history,
	nextStep,
	updateWaybillDetails,
}) => {
	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState({ alert_newkey: 1 });
	const [request, setRequest] = useState();

	const { token } = queryString.parse(history.location.search);

	const handlePhone = (__, value, countryData) => {
		let Numbers = value.replace(/[^0-9]/g, "");
		value = Numbers.replace(/^(-?)0+/, "");
		setRequest({ phoneNumber: value, dialCode: countryData.dialCode });
	};

	const confirmWaybillDriver = async () => {
		try {
			setLoading(true);
			const { phoneNumber } = request;
			const res = await API.put(validateWaybillDriver, {
				phoneNumber,
				token,
			});

			if (res.status === 200) {
				const resData = res.data.data;
				setLoading(false);
				updateWaybillDetails({
					token,
					waybill: resData,
				});
				nextStep(token);
			}
		} catch (err) {
			setLoading(false);
			setError(getErrorMessage(err));
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		confirmWaybillDriver();
	};

	const setError = (errMsg) => {
		setLoading(false);
		setAlert((prev) => ({
			alert_newkey: prev.alert_newkey + 1,
			alert_show: "show",
			alert_message: errMsg,
			alert_type: "danger",
		}));
	};

	return (
		<FadeIn>
			<div className="col-xl-4 col-lg-5 col-md-6 col-sm-8 col-10 ">
				<div className="py-5">
					<h4 className="dashboardIntro__heading font-weight-bold mb-5 text-center">
						Driver’s verification
					</h4>

					<Alert
						key={alert?.alert_newkey}
						type={alert?.alert_type}
						message={alert?.alert_message}
						show={alert?.alert_show}
					/>

					<form onSubmit={handleSubmit}>
						<div className="low-form mt-4">
							<div className="form-group">
								<label htmlFor="phoneNumber">
									Enter your phone number
								</label>
								<PhoneNumberInput
									inputFieldName="phoneNumber"
									inputValue={request?.phoneNumber}
									handlePhoneNumberChange={handlePhone}
									isDisabled={loading}
								/>
							</div>

							<div className=" mt-n3">
								<label className="font-weight-lighter">
									<InfoIcon className="mr-1" height="12" />
									<small>
										Must be number registered with Mintyn
									</small>
								</label>
							</div>

							<div className="my-4">
								<button
									className="btn create-acc"
									disabled={
										loading ||
										request?.phoneNumber.length !== 10
									}
								>
									{loading ? <SmallLoader /> : "Continue"}
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</FadeIn>
	);
};

export default connect(null, { nextStep, updateWaybillDetails })(
	WaybillDriverVerification
);
