import { SWITCH_MODES, UPDATE_COLOR_SCHEME } from "./types";
import { dayTheme, nightTheme } from "../SwitchModes/themeModes";

export const switchModes = (theme) => (dispatch) => {
	const revesedTheme = theme === dayTheme ? nightTheme : dayTheme;
	localStorage.setItem("colorScheme", revesedTheme);
	document.body.style.background = theme === dayTheme ? "#000000" : "#ffffff";
	dispatch({ type: SWITCH_MODES });
};

export const updateColorScheme = (theme) => (dispatch) => {
	localStorage.setItem("colorScheme", theme);
	document.body.style.background = theme === dayTheme ? "#ffffff" : "#000000";
	dispatch({ type: UPDATE_COLOR_SCHEME, payload: theme });
};
