import React from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import FadeIn from "../effects/FadeIn";
import CaretLeft from "assets/img/caret_left_gold.svg";
import { useState } from "react";
import CustomTabs from "components/utils/CustomTabs/CustomTabs";
import Transfers from "./components/Transfers/Transfers";

const transactionHistoryTabList = [{ title: "Transfers", value: "TRANSFERS" }];

export default function TransactionHistory() {
	const history = useHistory();
	const [currentTab, setCurrentTab] = useState("TRANSFERS");

	const renderBasedOnTab = () => {
		switch (currentTab) {
			case "TRANSFERS":
				return <Transfers />;

			default:
				return null;
		}
	};

	return (
		<>
			<Helmet>
				<title>
					{process.env.REACT_APP_NAME} - Transaction History
				</title>
			</Helmet>

			<FadeIn>
				<section className="pb-5 transaction-history">
					<div className="d-flex align-items-center mb-5">
						<button
							className="back-btn d-flex align-items-center"
							onClick={() => history.goBack()}
						>
							<img src={CaretLeft} alt="" />
							<span>Back</span>
						</button>
					</div>

					<h1 className="page-title-text">Transactions</h1>
					<p className="page-lead-text">
						See all transaction history
					</p>

					<CustomTabs className="mt-5">
						{transactionHistoryTabList.map((tab) => (
							<li
								key={tab.value}
								role="tab"
								onClick={() => setCurrentTab(tab.value)}
							>
								<span data-current={tab.value === currentTab}>
									{tab.title}
								</span>
							</li>
						))}
					</CustomTabs>

					{renderBasedOnTab()}
				</section>
			</FadeIn>
		</>
	);
}
