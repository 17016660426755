import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Modal from "../../../utils/Modal/Modal";
import store from "../../../../store";
import { CLEAR_REDUX_STORE } from "../../../../actions/types";
import { updateIsLoggedIn } from "actions/loginActions";

class SessionTimeOut extends Component {
	// This was causing Maximum update depth exceeded error
	// componentWillUnmount = () => {
	// 	store.dispatch({ type: CLEAR_REDUX_STORE });
	// };

	handleRelogin = () => {
		store.dispatch({
			type: CLEAR_REDUX_STORE,
		});
		this.props.updateIsLoggedIn(false);
		this.props.history.push("/sign-in");
	};

	render = () => {
		const { showModal, message } = this.props;
		return (
			<Modal show={showModal} isStatic scrollable={false}>
				<div className="modal-dialog modal-dialog-center modal-md">
					<div className="modal-content" style={{ height: "350px" }}>
						<div
							className="modal-body"
							style={{ overflowY: "hidden" }}
						>
							<div className="py-5">
								<div className="d-flex justify-content-center">
									<div className="frownCenter m-0">
										<svg
											width="62"
											height="62"
											viewBox="0 0 48 48"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M24 6C14.0589 6 6 14.0589 6 24C6 33.9411 14.0589 42 24 42C33.9411 42 42 33.9411 42 24C42 14.0589 33.9411 6 24 6ZM2 24C2 11.8497 11.8497 2 24 2C36.1503 2 46 11.8497 46 24C46 36.1503 36.1503 46 24 46C11.8497 46 2 36.1503 2 24Z"
												fill="rgba(68, 84, 92, 0.8)"
											/>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M24 10C25.1046 10 26 10.8954 26 12V22.7639L32.8944 26.2111C33.8824 26.7051 34.2828 27.9065 33.7889 28.8944C33.2949 29.8824 32.0935 30.2828 31.1056 29.7889L23.1056 25.7889C22.428 25.4501 22 24.7575 22 24V12C22 10.8954 22.8954 10 24 10Z"
												fill="rgba(68, 84, 92, 0.8)"
											/>
										</svg>
									</div>
								</div>
								<div className="smile mt-5">
									<h4>Mintyn: {message}</h4>
									<p>Please log in to reactivate</p>
								</div>
								<div className="row justify-content-center">
									<div className="col-md-5">
										<button
											className="btn create-acc mt-3 mb-3"
											onClick={this.handleRelogin}
										>
											LOGIN
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		);
	};
}

export default connect(
	({ auth: { kickedOut, sessionMessage } }) => ({
		showModal: kickedOut,
		message: sessionMessage,
	}),
	{
		updateIsLoggedIn,
	}
)(withRouter(SessionTimeOut));
