import React, { useState } from "react";
import FadeIn from "../../effects/FadeIn";
import Helmet from "react-helmet";
import CaretLeft from "../../../../../assets/img/caret_left_gold.svg";
import { useHistory } from "react-router-dom";
import "./EmergencySavings.scss";
import { ReactSVG } from "react-svg";
import PlusIcon from "assets/img/white_plus_icon.svg";
import { ERROR, FINISHED, IDLE, INDIVIDUAL, LOADING } from "config/enums";
import { getErrorMessage } from "components/utils/helpers";
import { API } from "lib/api";
import { getEmergencySavingsV3 } from "lib/api/apiRoutesConfig/services/SavingsMS/savingsGoals/subRoutes";
import { useEffect } from "react";
import { MintInfinite } from "components/dashboard/utils/ShineLoader/ShineLoader";
import FailedTryAgain from "components/dashboard/utils/FailedTryAgain/FailedTryAgain";
import EmptyEmergencyList from "./components/EmptyEmergencyList/EmptyEmergencyList";
import ESListWrapper from "./components/ESListWrapper/ESListWrapper";
import { useSelector } from "react-redux";
import UserRoleWarning from "components/utils/UserRoleWarning/UserRoleWarning";
import GroomPanel from "components/dashboard/utils/GroomPanel/GroomPanel";

export default function EmergencySavings() {
	const history = useHistory();
	const [currentState, setCurrentState] = useState(IDLE);
	const [errorMsg, setErrorMsg] = useState("");
	const [savingsGoals, setSavingsGoals] = useState([]);
	const [hasSavingsGoals, setHasSavingsGoals] = useState(false);

	const { loginResponse, activeAccountType } = useSelector(
		(state) => state.auth
	);

	const accountType =
		activeAccountType === INDIVIDUAL
			? "activeIndividualAccount"
			: "activeCorporateAccount";

	const { currentRole } = loginResponse[accountType]?.profileSetting || {};
	const isApprover = currentRole === "APPROVER";

	const fetchEmergencySavings = async () => {
		setCurrentState(LOADING);
		try {
			const response = await API.get(getEmergencySavingsV3);
			const { status, data } = response || {};
			if (status === 200) {
				setSavingsGoals(data.data.savingsGoals);
				setHasSavingsGoals(data.data.exist);
				setCurrentState(FINISHED);
			}
		} catch (err) {
			setErrorMsg(getErrorMessage(err));
			setSavingsGoals([]);
			setCurrentState(ERROR);
		}
	};

	useEffect(() => {
		fetchEmergencySavings();
	}, []);

	const renderBasedOnState = () => {
		switch (currentState) {
			case LOADING:
				return (
					<div className="d-flex w-100 justify-content-center align-items-center es-loading">
						<MintInfinite relative />
					</div>
				);

			case ERROR:
				return (
					<div className="d-flex w-100 justify-content-center align-items-center es-loading">
						<FailedTryAgain
							errorMssg={errorMsg}
							handleTryAgain={fetchEmergencySavings}
						/>
					</div>
				);

			case FINISHED:
				if (savingsGoals.length <= 0) {
					return <EmptyEmergencyList />;
				}

				return <ESListWrapper data={savingsGoals} />;

			default:
				return null;
		}
	};

	return (
		<>
			<Helmet>
				<title>{process.env.REACT_APP_NAME} - Emergency Savings</title>
			</Helmet>

			<FadeIn>
				<section className="emergency-savings-page pb-5">
					<div className="d-flex align-items-center mb-5 justify-content-between">
						<button
							className="back-btn d-flex align-items-center"
							onClick={() => history.goBack()}
						>
							<img src={CaretLeft} alt="" />
							<span>Back</span>
						</button>

						{currentState === FINISHED && (
							<button
								className="btn btn-mint-default new-emergency-savings-btn"
								onClick={() =>
									history.push(
										"/savings/emergencies/create-emergency-saving",
										{
											step:
												!hasSavingsGoals ||
												savingsGoals.length <= 0
													? 0
													: 1,
										}
									)
								}
							>
								New Emergency Savings{" "}
								<ReactSVG src={PlusIcon} className="ml-2" />
							</button>
						)}
					</div>

					<h1 className="page-title-text">Emergency Savings</h1>
					<p className="page-lead-text">
						See how your Emergency savings are doing
					</p>

					{isApprover ? (
						<GroomPanel cls="es-groom position-relative">
							<UserRoleWarning
								style={{
									position: "absolute",
									top: "50%",
									left: "50%",
									transform: "translate(-50%, -50%)",
								}}
							/>
						</GroomPanel>
					) : (
						renderBasedOnState()
					)}
				</section>
			</FadeIn>
		</>
	);
}
