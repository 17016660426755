import savingsGoals, { savingsGoalsV2, savingsGoalsV3 } from "../";
export const {
	getGoalDetails,
	goalWithdrawalTrancation,
	goalFundingTrancation,
	goalFrequencyEdit,
	goalsDashboard,
	performEmergencySavings,
	getSavingGoalEarnings,
	getSavingGoalTransactions,
	getRoundUpSavingsTransactions,
	performRoundUpSavings,
	updateRoundUpSavingsType,
	deactivateRoundUpSavings,
	goalFundingWithRefernce,
	verifyReferenceFunding,
	getEmergencySavingsV3,
	getEmergencySavingDetailsV3,
	updateSavingFrequency,
	withdrawEmergencySavings,
	createEmergencySavings,
	getSpendAndSaveDetails,
	withdrawSpendAndSave,
	editSpendAndSave,
	updateSpendAndSaveStatus,
	setupSpendAndSave,
} = {
	getGoalDetails: (goalId) => `${savingsGoals}/${goalId}`,
	goalWithdrawalTrancation: `${savingsGoalsV2}/transaction/withdraw-fund`,
	goalFundingTrancation: `${savingsGoals}/transaction/fund-goal`,
	goalFrequencyEdit: (goalId) => `${savingsGoals}/${goalId}/saving-frequency`,
	goalsDashboard: `${savingsGoals}/dashboard`,
	performEmergencySavings: `${savingsGoalsV2}/emergency-savings`,
	getSavingGoalEarnings: (goalId) =>
		`${savingsGoalsV2}/${goalId}/accrued-interest`,
	getSavingGoalTransactions: (goalId) =>
		`${savingsGoalsV2}/${goalId}/transactions`,
	getRoundUpSavingsTransactions: (id) =>
		`${savingsGoalsV2}/roundup-savings/${id}/transactions`,
	performRoundUpSavings: `${savingsGoalsV2}/roundup-savings`,
	updateRoundUpSavingsType: (savingsId) =>
		`${savingsGoalsV2}/roundup-savings/${savingsId}/roundup-type`,
	deactivateRoundUpSavings: (savingsId) =>
		`${savingsGoalsV2}/roundup-savings/${savingsId}/status`,
	goalFundingWithRefernce: (goalId) =>
		`${savingsGoalsV2}/transaction/${goalId}/funding-reference`,
	verifyReferenceFunding: (reference) =>
		`${savingsGoalsV2}/transaction/reference/${reference}/verify`,
	getEmergencySavingsV3: `${savingsGoalsV3}/emergency-savings`,
	getEmergencySavingDetailsV3: (goalId) =>
		`${savingsGoalsV3}/emergency-savings/${goalId}`,
	updateSavingFrequency: (goalId) =>
		`${savingsGoals}/${goalId}/saving-frequency`,
	withdrawEmergencySavings: `${savingsGoalsV3}/emergency-savings/withdraw-fund`,
	createEmergencySavings: `${savingsGoalsV3}/emergency-savings`,
	getSpendAndSaveDetails: `${savingsGoals}/spend-and-save`,
	withdrawSpendAndSave: `${savingsGoals}/spend-and-save/withdraw-funds`,
	editSpendAndSave: `${savingsGoals}/spend-and-save/edit-savings`,
	updateSpendAndSaveStatus: `${savingsGoals}/spend-and-save/update-status`,
	setupSpendAndSave: `${savingsGoals}/spend-and-save/set-up`,
};
