import CustomTabs from "components/utils/CustomTabs/CustomTabs";
import React from "react";
import { Helmet } from "react-helmet";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";
import FadeIn from "../../effects/FadeIn";
import BillsPaymentRoutes from "../components/BillsPaymentRoutes/BillsPaymentRoutes";
import "./BillPayment.scss";

export default function BillsPayment() {
	return (
		<>
			<Helmet>
				<title>{process.env.REACT_APP_NAME} - Bills Payment</title>
			</Helmet>

			<FadeIn>
				<div className="py-5">
					<h1 className="page-title-text">Bills Payment</h1>
					<p className="page-lead-text">
						Purchase airtime, data and pay utility bills
					</p>

					<CustomTabs className="mt-5">
						{BillsPaymentRoutes.map((route, index) => (
							<li className="nav-item" key={index}>
								<NavLink className="nav-link" to={route.path}>
									{route.title}
								</NavLink>
							</li>
						))}
					</CustomTabs>

					<Switch>
						{BillsPaymentRoutes.map((route, index) => (
							<Route
								key={index}
								exact
								path={route.path}
								component={route.component}
							/>
						))}
						<Redirect path="*" to="/pay-bills" />
					</Switch>
				</div>
			</FadeIn>
		</>
	);
}
