import React from "react";
import { ReactSVG } from "react-svg";
import Calendar from "./assets/calendar.svg";
import { formatCurrency } from "components/utils/helpers";
import { COMPLETED, ACTIVE, SENT } from "config/enums";

const RequestCard = ({
	data,
	onClick,
	isPending = false,
	acceptAction,
	rejectAction,
}) => {
	const renderBasedOnStatus = () => {
		switch (data.status) {
			case SENT:
				return <p className=" status sent">Sent</p>;

			case ACTIVE:
				return <p className=" status active">Active</p>;

			case COMPLETED:
				return <p className=" status completed">Completed</p>;

			default:
				return null;
		}
	};
	return (
		<div
			onClick={() => {
				!isPending && onClick();
			}}
			className={`request-card ${!isPending ? "pointer" : ""}`}
		>
			<div className=" top">
				<div className="date">
					<ReactSVG src={Calendar} />
					{data.dateCreated}
				</div>
				{isPending && (
					<div className="participants-count">
						<p className="text">Participants:</p>
						<p className=" count">{data.numberOfParticipants}</p>
					</div>
				)}
				{!isPending && renderBasedOnStatus()}
			</div>
			<div className="title-section">
				<p className=" title">{data.name ?? data.requestName}</p>
				<p className=" amt">{formatCurrency(data.amount)} </p>
			</div>
			<p className="content"> {data.description}</p>
			{isPending && (
				<div className=" footer">
					<div className="sender-section">
						<p className="title">Sender </p>
						<p className="sender">Madu Tochukwu</p>
					</div>
					<div className=" button-section ">
						<button
							onClick={() => {
								rejectAction();
							}}
							className="btn  border-button "
							type="button"
						>
							Reject
						</button>
						<button
							onClick={() => {
								acceptAction();
							}}
							className="btn btn-mint-default"
							type="button"
						>
							Accept
						</button>
					</div>
				</div>
			)}
		</div>
	);
};
export default RequestCard;
