import React from "react";
import LogOutButton from "./LogOutButton";

const SideNavFooter = () => (
    <>
        <hr />
        <li>
            <LogOutButton />
        </li>
    </>
)

export default SideNavFooter;