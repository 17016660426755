import React, { useEffect } from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import FadeIn from "../../dashboard/components/effects/FadeIn";
import { initWaybillComfirmation } from "../../../actions/waybillComfirmation";
import WaybillLinkVerification from "./WaybillLinkVerification/WaybillLinkVerification";
import WaybillDriverVerification from "./WaybillDriverVerification/WaybillDriverVerification";
import VerififedWaybillDetails from "./VerififedWaybillDetails/VerififedWaybillDetails";
import CustomerOrderConfirmation from "./CustomerOrderConfirmation/CustomerOrderConfirmation";
import OrderDeliveryDetails from "./OrderDeliveryDetails/OrderDeliveryDetails";
import Logo from "../../utils/Logo";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const WaybillConfirmation = ({
	history,
	waybillConfirmation,
	initWaybillComfirmation,
}) => {
	const { token } = queryString.parse(history.location.search);

	useEffect(() => {
		const waybill = waybillConfirmation[token];
		!waybill && initWaybillComfirmation(token);
		// eslint-disable-next-line
	}, []);

	const renderBasedOnStep = () => {
		const { currentStep } = waybillConfirmation[token] || {};

		switch (currentStep) {
			case 1:
				return <WaybillLinkVerification history={history} />;
			case 2:
				return <WaybillDriverVerification history={history} />;
			case 3:
				return <VerififedWaybillDetails history={history} />;
			case 4:
				return <CustomerOrderConfirmation history={history} />;
			case 5:
				return <OrderDeliveryDetails history={history} />;

			default:
				break;
		}
	};

	return (
		<>
			<Helmet>
				<title>{process.env.REACT_APP_NAME} - Open Account</title>
			</Helmet>
			<div className="row justify-content-center pt-5 mt-3">
				<div className="center-logo mb-0">
					<Link to="https://mintyn.com/">
						<Logo />
					</Link>
				</div>

				<div className="col-12">
					<div className="row justify-content-center">
						<FadeIn>{renderBasedOnStep()}</FadeIn>
					</div>
				</div>
			</div>
		</>
	);
};

export default connect(({ waybillConfirmation }) => ({ waybillConfirmation }), {
	initWaybillComfirmation,
})(WaybillConfirmation);
