import { formatEnum } from "components/utils/helpers";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SwitchAccountPanel from "../../utils/SwitchAccountPanel/SwitchAccountPanel";
// import imageFiles from "../../common/imageFiles/imageFiles";

class TopNav extends Component {
	constructor(props) {
		super(props);
		this.state = { showSwitchAccountPanel: false };
	}

	// for switch account panel/dropdown
	hideHeaderMenu = () => {
		document.documentElement.classList.remove("_fixed");
		document.body.classList.remove("_fixed");

		this.setState((prevState) => ({
			...prevState,
			showSwitchAccountPanel: false,
		}));
	};

	toggleSwitchPanel = () => {
		document.documentElement.classList.toggle("_fixed");
		document.body.classList.toggle("_fixed");

		this.setState((prevState) => {
			return {
				...prevState,
				showSwitchAccountPanel: !prevState.showSwitchAccountPanel,
			};
		});
	};

	// componentDidMount() {
	// document.body.addEventListener("click", this.hideHeaderMenu);
	// }

	componentWillUnmount = () => {
		document.body.removeEventListener("click", this.hideHeaderMenu);
	};

	componentDidUpdate(prevProps) {
		const content = document.getElementById("content");
		const sidebar = document.getElementById("sidebar");
		const overlay = document.getElementById("overlay");

		if (
			this.props.location.pathname !== prevProps.location.pathname &&
			this.props.isLoggedIn
		) {
			this.hideHeaderMenu();
			sidebar.classList.remove("active");
			content.classList.remove("active");
			overlay.style.display = "none";
		}
	}

	// LogOut = (e) => {
	// 	e.preventDefault();
	// 	localStorage.clear();
	// 	sessionStorage.clear();
	// 	window.location.reload();
	// };

	render = () => {
		const { profileImageUrl, firstName } = this.props.currentUser || {};

		return (
			<nav className="navbar navbar-expand navbar-light">
				<div className="container-fluid">
					<button
						type="button"
						id="sidebarCollapse"
						className="btn scary"
						onClick={() => {
							const content = document.getElementById("content");
							const overlay = document.getElementById("overlay");
							const sidebar = document.getElementById("sidebar");

							sidebar.classList.toggle("active");
							content.classList.toggle("active");
							overlay.style.display = "block";
						}}
					>
						<span></span>
						<span></span>
						<span></span>
					</button>

					<button
						type="button"
						id="BigsidebarCollapse"
						className="btn scary"
						onClick={() => {
							const content = document.getElementById("content");
							const sidebar = document.getElementById("sidebar");

							sidebar.classList.toggle("active");
							content.classList.toggle("active");
						}}
					>
						<span></span>
						<span></span>
						<span></span>
					</button>

					<div
						className="collapse navbar-collapse"
						id="navbarSupportedContent"
					>
						{this.props.isLoggedIn && (
							<div
								className="nav navbar-nav ml-auto"
								onClick={(e) => e.stopPropagation()}
							>
								<div
									role="button"
									className="d-flex align-items-center switch-account-container"
									onClick={(e) => {
										e.stopPropagation();
										this.toggleSwitchPanel();
									}}
								>
									{/* <span>{firstName + " " + lastName}</span> */}
									{this.props.accounts
										?.filter(
											(account) =>
												account?.accountId ===
												this.props.dashboardResponse
													.mintAccountId
										)
										.map((account, index) => (
											<Fragment key={index}>
												<img
													src={profileImageUrl}
													alt={
														account?.accountName ||
														firstName
													}
												/>

												<span className="switch-account-container__account-info">
													{account?.accountName} •{" "}
													<span>
														{formatEnum(
															account?.accountTypeName
														)}
													</span>
												</span>
											</Fragment>
										))}
									<span className="chevron-down"></span>

									<SwitchAccountPanel
										show={this.state.showSwitchAccountPanel}
										onClose={this.hideHeaderMenu}
										accounts={this.props.accounts}
										activeAccountId={
											this.props.dashboardResponse
												.mintAccountId
										}
									/>
								</div>
							</div>
						)}
					</div>
				</div>
			</nav>
		);
	};
}

export default connect(
	(state) => ({
		currentUser: state.auth.currentUser,
		accounts: state.auth.loginResponse.accounts,
		dashboardResponse: state.dashboard.dashboardResponse,
		isLoggedIn: state.auth.isLoggedIn,
	}),
	null
)(withRouter(TopNav));
