import CustomPinInput from "components/utils/CustomPinInput/CustomPinInput";
import { formatCurrency } from "components/utils/helpers";
import React from "react";
import { useState } from "react";

export default function NewTransferDetailsPin({
	transcationRequest,
	confirmTranscation,
	selectedBank,
}) {
	const { recipientAccountName, amount } = transcationRequest || {};
	const { label: bankName } = selectedBank || {};
	const [pin, setPin] = useState("");

	const processSubmission = (e) => {
		e.preventDefault();
		// const pinInput = this.refs.pinInput;
		// const pin = pinInput.getPin();
		// pin.length !== 4 ? pinInput.shakeInvalid() : confirmTranscation(pin);
		confirmTranscation(pin);
	};

	return (
		<form
			className="ntdp-form px-sm-5 px-3 pb-5"
			onSubmit={processSubmission}
		>
			<div className="ntdp-info">
				<span>Amount to send</span>
				<span>{formatCurrency(amount)}</span>
			</div>
			<div className="ntdp-info">
				<span>Recipient Name</span>
				<span>{recipientAccountName}</span>
			</div>
			<div className="ntdp-info">
				<span>Bank Name</span>
				<span>{bankName || "Mint Finex"}</span>
			</div>
			<CustomPinInput
				hasDashedBorder
				className="my-5"
				length={4}
				secret
				title="Enter Transaction PIN to confirm"
				containerStyle={{ flexWrap: "wrap" }}
				onChange={(value) => {
					setPin(value);
				}}
			/>
			<button
				className="btn create-acc"
				type="submit"
				disabled={pin.length !== 4}
			>
				Confirm Transfer
			</button>
		</form>
	);
}
