import React from "react";
import "./RadioComponentV2.scss";

const RadioComponentV2 = ({ label, options, value, handleRadioSelect }) => {
	return (
		<div className="form-group radio__select">
			{label && <label>{label}</label>}

			{options?.map((option) => (
				<label
					key={option?.durationId}
					className={`radio__select__round-type ${
						value === option?.durationId && "active"
					}`}
				>
					<input
						type="radio"
						name="type"
						value={option?.durationId}
						checked={value === option?.durationId}
						onChange={() => handleRadioSelect(option)}
					/>
					<div className="round-type__text ">
						<p>
							{option?.description} at {option?.interestRate}% Per
							annum
						</p>
						<span>
							Penalty fee: {option?.penaltyRate}% of accrued
							interest
						</span>
					</div>
				</label>
			))}
		</div>
	);
};

export default RadioComponentV2;
