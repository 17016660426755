/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Modal from "components/utils/Modal/Modal";
import SuccessImage from "components/utils/SuccessImage";
import { useHistory } from "react-router-dom";
import { defaultPath } from "../../requestRoutes";

const Submitted = ({ show, onClose }) => {
	return (
		<>
			<Modal show={show} scrollable>
				<Content onClose={onClose} />
			</Modal>
		</>
	);
};
export default Submitted;

const Content = ({ onClose }) => {
	const history = useHistory();

	return (
		<div className="request-submitted-modal ">
			<div className=" content">
				<div className="icon">
					<SuccessImage />
				</div>
				<h4>Congratulations!</h4>
				<p> Split payment request has been sent succesfully.</p>
			</div>
			<div className="col-12 ">
				<button
					onClick={() => {
						history.push(`${defaultPath}/my-requests`);
					}}
					className="btn btn-mint-default"
					type="button"
				>
					Done
				</button>
			</div>
		</div>
	);
};
