import React from "react";
import { connect } from "react-redux";
import "./PersonalInformation.scss";

const PersonalInformation = ({ currentUser }) => {
    const {
        firstName,
        lastName,
        email,
        gender,
        phoneNumber,
        profileImageUrl,
        dateOfBirth,
        bvn
    } = currentUser || {};
    return (
        <div className='card'>
            <div className='card-header' id='headingOne'>
                <h5 className='mb-0'>
                    <button
                        className='btn btn-link'
                        data-toggle='collapse'
                        data-target='#collapseOne'
                        aria-expanded='false'
                        aria-controls='collapseOne'>
                        Personal Information
                    </button>
                    <div className='bus-down'></div>
                </h5>
            </div>

            <div
                id='collapseOne'
                className='collapse'
                aria-labelledby='headingOne'
                data-parent='#accordion'>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-md-3'>
                            <div className='mx-auto'>
                                <div className='bigThumb'>
                                    <img src={profileImageUrl} alt='big' />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-9'>
                            <div className='dialog-info marTy'>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <label>First Name</label>
                                        <input
                                            className='form-control transfew'
                                            name='firstName'
                                            readOnly={true}
                                            value={firstName}
                                        />
                                    </div>
                                    <div className='col-md-6'>
                                        <div>
                                            <label>Last Name</label>
                                            <input
                                                className='form-control transfew'
                                                name='lastName'
                                                readOnly={true}
                                                value={lastName}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-md-6'>
                                        <label>Email</label>
                                        <input
                                            className='form-control transfew'
                                            name='email'
                                            readOnly={true}
                                            value={email}
                                        />
                                    </div>

                                    <div className='col-md-6'>
                                        <div>
                                            <label>Phone Number</label>
                                            <input
                                                className='form-control transfew'
                                                name='phoneNumber'
                                                readOnly={true}
                                                value={phoneNumber}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-md-6'>
                                        <label>Gender</label>
                                        <p>{gender}</p>
                                    </div>
                                    <div className='col-md-6'>
                                        <div>
                                            <label>Date of Birth</label>
                                            <p>{dateOfBirth}</p>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div>
                                            <label>Bvn</label>
                                            <p>{bvn}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default connect(
    ({ auth: { currentUser } }) => ({
        currentUser: currentUser,
    }),
    null
)(PersonalInformation);
