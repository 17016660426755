import React, { Component } from "react";
import CreateTranscationPin from "../../../dashboard/components/security/CreateTranscationPin";
import FadeIn from "../../../dashboard/components/effects/FadeIn";
import ReactHelmet from "../../../utils/ReactHelmet/ReactHelmet";
import { RESET_STAGE } from "../../../../config/enums";
import CreateFPNewPassword from "../../ForgotPassword/CreateFPNewPassword/CreateFPNewPassword";

class UpdateSecurityDetails extends Component {
    state = {
        modelState: "CREATE_PIN",
        request: {},
    };

    render() {
        const { modelState } = this.state;
        const { params: { sessionId } = {} } = this.props.match || {};

        return (
            <>
                <ReactHelmet title='Update Transcation Pin' />
                {modelState === "CREATE_PIN" ? (
                    <FadeIn>
                        <div className='container h-100'>
                            <div className='row h-100 justify-content-center align-items-center absolute-center'>
                                <div className='col-xl-6 col-lg-8 col-md-9'>
                                    <div className='panel verify-account'>
                                        <div className='row h-100 justify-content-center align-items-center'>
                                            <div className='col-xl-9 col-lg-9 col-md-9'>
                                                <div className='verif-center px-4 mb-5'>
                                                    <>
                                                        <div className='breadcrumbs'>
                                                            <h6 className='blon'>
                                                                Update
                                                                Transaction Pin
                                                            </h6>
                                                        </div>
                                                        <CreateTranscationPin
                                                            stage={RESET_STAGE}
                                                            handleResetRequest={(
                                                                transPinPayload
                                                            ) => {
                                                                this.setState({
                                                                    request: transPinPayload,
                                                                    modelState:
                                                                        "CREATE_PASSWORD",
                                                                });
                                                            }}
                                                        />
                                                    </>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </FadeIn>
                ) : (
                    <FadeIn>
                        <CreateFPNewPassword
                            stage={RESET_STAGE}
                            sessionId={sessionId}
                            history={this.props.history}
                            pinRequestPayload={this.state.request}
                        />
                    </FadeIn>
                )}
            </>
        );
    }
}

export default UpdateSecurityDetails;
