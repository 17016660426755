/* eslint-disable no-unused-vars */
import Notification from "../utils/assets/notification_icon.svg";
import GShopping from "../utils/assets/group_shopping_icon.svg";
import PShopping from "../utils/assets/personal_shopping_icon.svg";

const defaultPath = "/market-place";

export const TopNavIconList = [
	// {
	// 	src: Notification,
	// 	name: "Notifications",
	// 	path: `${defaultPath}`,
	// },
	// {
	// 	src: GShopping,
	// 	name: "Shopping",
	// 	path: `${defaultPath}`,
	// },

	{
		src: PShopping,
		name: "Cart",
		path: `${defaultPath}/cart`,
	},
];

export const PaymentOptionList = [
	{
		title: 'Pay Now',
		sub: 'With your Mintyn Account',
		name:'payNow'
	},
	{
		title: 'Pay Later',
		sub: 'With your Mintyn Account',
		name:'payLater'
	}
]
