export const responseStatus = {
	LOADING: "LOADING",
	ERROR: "ERROR",
	FINISHED: "FINISHED",
};

export const investmentStatus = {
	ALL: "ALL",
	ACTIVE: "ACTIVE",
	COMPLETED: "COMPLETED",
};

export const MINTYN_USER_NEW_ACCOUNT = "MINTYN_USER_NEW_ACCOUNT";
export const MINTYN_USER_TOKEN = "MINTYN_USER_TOKEN";
export const MINTYN_USER_REFRESH_TOKEN = "MINTYN_USER_REFRESH_TOKEN";
export const MINTYN_INTERCOM_USER_HASH = "MINTYN_INTERCOM_USER_HASH";
export const NCTO_CARD_ACTIVATION_PAYLOAD = "NCTO_CARD_ACTIVATION_PAYLOAD";
