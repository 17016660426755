import React, { Component } from "react";
import { formatFloatInput } from "../../../utils/helpers";

export default class TranscationamountInput extends Component {
	state = {
		amount: "",
	};

	pushUpdate = (formattedInputValue) => {
		const { updateAmount, inputName } = this.props;
		this.setState({ amount: formattedInputValue });
		updateAmount(formattedInputValue, inputName);
	};

	handleChange = (e) => {
		const [firstDigit] = e.target.value;
		if (firstDigit === "." || firstDigit === "0") return "";
		const formattedInputValue = formatFloatInput(e.target.value);
		this.pushUpdate(formattedInputValue);
	};

	render = () => {
		const { amount } = this.state;
		const { label, inputName, error, cls } = this.props;

		return (
			<div className={`form-group ${cls}`}>
				{label && <label>{label}</label>}
				<div style={{ position: "relative" }}>
					{amount && (
						<span className="ft-currency" id="">
							₦
						</span>
					)}

					<input
						name={inputName ? inputName : "amount"}
						type="text"
						value={amount}
						maxLength={12}
						className={`form-control ft-recipient-input ${
							error ? " has-error" : ""
						}`}
						placeholder="Enter Amount"
						autoComplete="off"
						onChange={this.handleChange}
						style={{
							paddingLeft: amount ? "25px" : "12px",
							transition: "none",
						}}
					/>
				</div>
			</div>
		);
	};
}
