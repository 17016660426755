import FadeIn from "components/dashboard/components/effects/FadeIn";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useLocation, useParams } from "react-router-dom";
import CaretLeft from "assets/img/caret_left_gold.svg";
import { ERROR, FINISHED, IDLE, LOADING } from "config/enums";
import { API } from "lib/api";
import {
	getGoalDetails,
	getSavingGoalTransactions,
} from "lib/api/apiRoutesConfig/services/SavingsMS/savingsGoals/subRoutes";
import { getErrorMessage } from "components/utils/helpers";
import { MintInfinite } from "components/dashboard/utils/ShineLoader/ShineLoader";
import FailedTryAgain from "components/dashboard/utils/FailedTryAgain/FailedTryAgain";
import CustomPagination from "components/dashboard/utils/CustomPagination/CustomPagination";
import SavingsGoalTransactions from "../SavingsGoalTransactions/SavingsGoalTransactions";
import SavingGoalCard from "../SavingGoalCard/SavingGoalCard";
import AutomaticDepositModal from "../../../EmergencySavings/components/modals/AutomaticDepositModal";

export default function SavingGoalDetails() {
	const history = useHistory();
	const location = useLocation();
	const { id } = useParams();
	const { savingGoalData } = location.state || {};
	const [currentState, setCurrentState] = useState(IDLE);
	const [savingsGoal, setSavingsGoal] = useState({});
	const [errorMsg, setErrorMsg] = useState("");
	const [page, setPage] = useState(0);
	const [size] = useState(10);
	const [transactions, setTransactions] = useState({});
	const [showModal, setShowModal] = useState(false);

	const fetchSavingGoal = async () => {
		setCurrentState(LOADING);
		try {
			const response = await API.get(getGoalDetails(id));
			const { status, data } = response || {};
			if (status === 200) {
				setSavingsGoal(data.data);
				setCurrentState(FINISHED);
				fetchSavingGoalTransactions(data.data.goalId);
			}
		} catch (err) {
			setErrorMsg(getErrorMessage(err));
			setSavingsGoal({});
			setCurrentState(ERROR);
		}
	};

	const fetchSavingGoalTransactions = async (goalId) => {
		setCurrentState(LOADING);
		try {
			const response = await API.get(getSavingGoalTransactions(goalId), {
				params: {
					page,
					size,
				},
			});
			const { status, data } = response || {};
			if (status === 200) {
				setTransactions(data.data);
				setCurrentState(FINISHED);
			}
		} catch (err) {
			setErrorMsg(getErrorMessage(err));
			setTransactions({});
			setCurrentState(ERROR);
		}
	};

	const goToPage = (page) => {
		setPage(page);
	};

	useEffect(() => {
		fetchSavingGoal();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (savingsGoal?.goalId) {
			fetchSavingGoalTransactions(savingsGoal.goalId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, size]);

	const renderBasedOnState = () => {
		const { records, totalPages } = transactions || {};

		switch (currentState) {
			case LOADING:
				return (
					<div className="d-flex w-100 justify-content-center align-items-center saving-goal-loading">
						<MintInfinite relative />
					</div>
				);

			case ERROR:
				return (
					<div className="d-flex w-100 justify-content-center align-items-center saving-goal-loading">
						<FailedTryAgain
							errorMssg={errorMsg}
							handleTryAgain={fetchSavingGoal}
						/>
					</div>
				);

			case FINISHED:
				return (
					<>
						<SavingGoalCard
							savingsGoal={savingsGoal}
							setShowModal={setShowModal}
							refetch={fetchSavingGoal}
						/>

						{records?.length <= 0 ? (
							<div
								className="d-flex justify-content-center align-items-center saving-goal-loading"
								style={{ flex: 1 }}
							>
								<h6 className="notready">
									No Transactions For This Goal
								</h6>
							</div>
						) : (
							<div className="es-list-transactions">
								<SavingsGoalTransactions data={records} />

								<CustomPagination
									currentPage={page}
									pageSize={totalPages}
									goToPage={goToPage}
								/>
							</div>
						)}
					</>
				);

			default:
				return null;
		}
	};

	return (
		<>
			<Helmet>
				<title>{process.env.REACT_APP_NAME} - Savings Goal</title>
			</Helmet>

			<FadeIn>
				<section className="savings-goal-page pb-5">
					<div className="d-flex align-items-center mb-5">
						<button
							className="back-btn d-flex align-items-center"
							onClick={() => history.goBack()}
						>
							<img src={CaretLeft} alt="" />
							<span>Back</span>
						</button>
					</div>

					<h1 className="page-title-text text-capitalize">
						View {savingGoalData?.name}
					</h1>
					<p className="page-lead-text">
						See an overview of your Savings goal
					</p>

					<div className="es-details-container">
						{renderBasedOnState()}
					</div>
				</section>
			</FadeIn>

			<AutomaticDepositModal
				show={showModal}
				autoSaveEnabled={savingsGoal?.autoSaveEnabled}
				onClose={() => setShowModal(false)}
				goalId={savingsGoal?.goalId}
				refetch={fetchSavingGoal}
			/>
		</>
	);
}
