import React, { Component } from "react";
import {
	bankNameEnquiry,
	resolveMintAccountName,
} from "../../../../../lib/api/apiRoutesConfig/services/FundsManagementMS/fundTransfer/subRoutes";
import { API } from "../../../../../lib/api";
import { getErrorMessage } from "../../../../utils/helpers";
import { TinyLoad } from "../../../../utils/SmallLoader";
import { OTHER_BANKS } from "config/enums";

class AcctNameEnquiryInput extends Component {
	state = {
		fetching: false,
		recipientAccountNumber: "",
	};

	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState.defaultValue !== nextProps.defaultValue) {
			return { recipientAccountNumber: nextProps.defaultValue };
		}
		return null;
	}

	// Debounce
	debounce = (callback, delay) => {
		let timer;
		return (...args) => {
			clearTimeout(timer);
			timer = setTimeout(() => callback(...args), delay);
		};
	};

	performNameEnquiry = async (recipientAccountNumber) => {
		try {
			if (recipientAccountNumber.length === 10) {
				this.setState({ fetching: true });
				const { bankCode, onSuccess } = this.props;
				let res = await API.get(bankNameEnquiry, {
					params: {
						accountNumber: recipientAccountNumber,
						bankCode,
					},
				});
				if (res.status === 200) {
					const { accountName, accountNumber } = res.data.data;
					onSuccess(accountName, accountNumber);
					this.setState({ fetching: false });
				}
			}
		} catch (err) {
			this.setState({
				serverError: getErrorMessage(err),
				fetching: false,
			});
		}
	};

	// for mint recipients
	// also accepts phone number
	performMintNameEnquiry = async (recipientAccountNumber) => {
		const debonceAccountNumber = this.debounce(recipientAccountNumber, 500);
		try {
			if (recipientAccountNumber.length >= 10 && debonceAccountNumber) {
				this.setState({ fetching: true });
				const { onSuccess } = this.props;
				let res = await API.get(resolveMintAccountName, {
					params: {
						accountNumber: recipientAccountNumber,
					},
				});
				if (res.status === 200) {
					const { accountName, accountNumber } = res.data.data;
					onSuccess(accountName, accountNumber);
					this.setState({ fetching: false });
				}
			}
		} catch (err) {
			this.setState({
				serverError: getErrorMessage(err),
				fetching: false,
			});
		}
	};

	checkTrue = (e) => {
		const { value: recipientAccountNumber } = e.target;
		if (recipientAccountNumber.length >= 10) {
			this.props.accountType === OTHER_BANKS
				? this.performNameEnquiry(recipientAccountNumber)
				: this.performMintNameEnquiry(recipientAccountNumber);
		}
	};

	handleNumberChange = (e) => {
		this.setState({
			serverError: "",
		});

		let { name, value } = e.target;
		value = value.replace(/[^0-9]/g, "");
		this.setState({
			[name]: value,
			serverError: false,
		});
		this.props.updateValue(e);
	};

	checkDisabledInput = () => {
		if (this.props.accountType === OTHER_BANKS) {
			return (
				this.state.fetching &&
				this.state.recipientAccountNumber.length === 10
			);
		}

		// else {
		// 	return (
		// 		this.state.fetching &&
		// 		this.state.recipientAccountNumber.length === 11
		// 	);
		// }
	};

	componentDidUpdate = (prevProps, prevState) => {
		if (
			prevState.recipientAccountNumber !==
			this.state.recipientAccountNumber
		) {
			this.setState((prevState) => {
				return {
					...prevState,
					serverError: false,
				};
			});
		}
	};

	render = () => {
		const { fetching, serverError, recipientAccountNumber } = this.state;
		const { error, handleAcctNumberDel, accountType, selectedBank } =
			this.props;

		return (
			<>
				<input
					name="recipientAccountNumber"
					type="text"
					className={
						"form-control ft-recipient-input " +
						(error ? "has-error" : "")
					}
					maxLength={accountType === OTHER_BANKS ? 10 : 11}
					value={recipientAccountNumber}
					onChange={this.handleNumberChange}
					onKeyUp={this.checkTrue}
					onKeyDown={handleAcctNumberDel}
					disabled={
						this.checkDisabledInput() ||
						(accountType === OTHER_BANKS && !selectedBank?.value)
					}
					// onBlur={this.checkTrue}
					placeholder={
						accountType === OTHER_BANKS
							? "Enter Account Number"
							: "Enter Account Number or Phone Number"
					}
					autoComplete="off"
				/>
				{fetching && recipientAccountNumber.length >= 10 && (
					<div className="float-right mt-2">
						<TinyLoad />
					</div>
				)}
				{serverError && recipientAccountNumber && (
					<span className="error-msg">{serverError}</span>
				)}
				{/* {errors["recipientAccountNumber"] ? (
                    <span className='error-msg'>{errors["accountNumber"]}</span>
                ) : null} */}
			</>
		);
	};
}

export default AcctNameEnquiryInput;
