/* eslint-disable react-hooks/exhaustive-deps */
import FadeIn from "components/dashboard/components/effects/FadeIn";
import FailedTryAgain from "components/dashboard/utils/FailedTryAgain/FailedTryAgain";
import { MintInfinite } from "components/dashboard/utils/ShineLoader/ShineLoader";
import BackButton from "components/utils/BackButton/BackButton";
import { getErrorMessage } from "components/utils/helpers";
import { ERROR, FINISHED, IDLE, LOADING } from "config/enums";
import { API } from "lib/api";
import { deliveryAddressAction } from "lib/api/apiRoutesConfig/services/tradeMS/individualTrade/subRoutes";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useLocation } from "react-router-dom";
import { ToastsStore } from "react-toasts";
import CartSummary from "../CartSummary/CartSummary";
import DeliveryAddressPanel from "../DeliveryAddressPanel/DeliveryAddressPanel";
import "./DeliveryDetails.scss";
import CheckmarkIcon from "assets/img/check-mark.svg";
import { ReactSVG } from "react-svg";
import PaymentPanel from "../PaymentPanel/PaymentPanel";

const MIN_STEP = 0;
const MAX_STEP = 2;

export default function DeliveryDetails() {
	const location = useLocation();
	const history = useHistory();
	const { cartType,} = location.state || {};

	const [currentStatus, setCurrentStatus] = useState(IDLE);
	const [errorMsg, setErrorMsg] = useState("");
	const [addresses, setAddresses] = useState([]);
	const [currentStep, setCurrentStep] = useState(MIN_STEP);

	const goToNextStep = () => {
		setCurrentStep((prev) => Math.min(prev + 1, MAX_STEP));
		
	};
	const goToPreviousStep = () => {
		setCurrentStep((prev) => Math.max(prev - 1, MIN_STEP));
		localStorage.setItem("st",currentStep-1)
	};

	const handleGoBack = () => {
		if (currentStep === MIN_STEP) {
			history.goBack();
			return;
		}
		goToPreviousStep();
	};

	const fetchDeliveryDetails = async () => {
		setCurrentStatus(LOADING);
		try {
			const response = await API.get(deliveryAddressAction);
			const { status, data } = response || {};
			if (status === 200) {
				setAddresses(data.data);
				setCurrentStatus(FINISHED);
			}
		} catch (err) {
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
			setErrorMsg(getErrorMessage(err));
			setCurrentStatus(ERROR);
		}
	};

	useEffect(() => {
		fetchDeliveryDetails();
		const steps = localStorage.getItem("st")
		setCurrentStep(Number(steps)??currentStep)
	}, []);


	const renderBasedOnStep = () => {
		switch (currentStep) {
			case 0:
				return (
					<DeliveryAddressPanel
						data={addresses}
						refetch={fetchDeliveryDetails}
						goToNextStep={goToNextStep}
					/>
				);

			case 1:
				return <PaymentPanel
					goToNextStep={goToNextStep}
				/>;
				case 2:
				return <PaymentPanel/>;

			default:
				return null;
		}
	};

	const renderBasedOnStatus = () => {
		switch (currentStatus) {
			case LOADING:
				return (
					<div className="d-flex w-100 justify-content-center align-items-center mkt-loading">
						<MintInfinite relative />
					</div>
				);

			case ERROR:
				return (
					<div className="d-flex w-100 justify-content-center align-items-center mkt-loading mkt-error-loading">
						<FailedTryAgain
							errorMssg={errorMsg}
							handleTryAgain={() => fetchDeliveryDetails()}
						/>
					</div>
				);

			case FINISHED:
				return renderBasedOnStep();

			default:
				return null;
		}
	};

	return (
		<>
			<Helmet>
				<title>
					{process.env.REACT_APP_NAME} - Market Place (Delivery
					Details)
				</title>
			</Helmet>

			<FadeIn>
				<section className="pb-5 mkt-delivery-details market-place">
					{/* <MktTopNav cartType={cartType} /> */}
					<div className="mkt-back-button">
						<BackButton onClick={handleGoBack} />
					</div>

					<div className="mkt-delivery-details__header">
						<div>
							<h1>Delivery Details</h1>
							<p>Review and confirm your personal cart</p>
						</div>
						<div className="mkt-delivery-steps">
							<DeliveryStep
								currentStep={currentStep}
								step={1}
								title="Delivery Address"
							/>
							<DeliveryStep
								currentStep={currentStep}
								step={2}
								title="Payment"
							/>
							<DeliveryStep
								currentStep={currentStep}
								step={3}
								title="Confirmation"
							/>
						</div>
					</div>

					<div className="mkt-delivery-details__content">
						{renderBasedOnStatus()}
						<CartSummary
							header="Personal Cart Summary"
							cartType={cartType}
							currentStep={currentStep}
							isAddress ={addresses}
							goToNextStep={goToNextStep}
							
						/>
					</div>
				</section>
			</FadeIn>
		</>
	);
}

const DeliveryStep = ({ currentStep, step, title }) => {
	const isHightlighted = currentStep + 1 >= step;

	return (
		<div className={`mkt-delivery-step`}>
			{isHightlighted ? (
				<ReactSVG src={CheckmarkIcon} />
			) : (
				<div className="mkt-delivery-step__count">{step}</div>
			)}
			<span className={`${isHightlighted ? "highlighted" : ""}`}>
				{title}
			</span>
		</div>
	);
};
