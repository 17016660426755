import React from "react";
import { toCurrency } from "../../../../utils/helpers";

const OrderDeliveryDetail = ({ order, toggle }) => {
	const { customerName, orderItems, orderAmount, customerPhone } = order;

	return (
		<div className="col-xl-6 col-lg-7 col-md-8 col-sm-9 col-11">
			<div className="low-form">
				<div className="step-box rounded p-0 form-group CustomerSavings__List h-auto">
					<div className="p-sm-5 p-4">
						<small style={{ fontSize: "12px" }} className="all-fet">
							Customer Information
						</small>

						<p
							style={{ fontSize: "16px" }}
							className="saving-target mt-2 font-weight-bold"
						>
							{customerName}
						</p>

						<p className="bigMoni my-1">{customerPhone}</p>

						<label className="font-weight-lighter">
							<small>{order?.deliveryAddress}</small>
						</label>

						<small
							style={{ fontSize: "12px" }}
							className="all-fet d-block mt-3 mb-2 text-left "
						>
							Goods Ordered
						</small>

						{orderItems?.map((orderItem, i) => (
							<p
								className="saving-target d-flex justify-content-between"
								key={i}
							>
								<span>
									<span>{i + 1}.</span>
									<span className="pl-3 pr-2">
										{orderItem?.quantityOrdered}
									</span>
									<span>{orderItem?.productName}</span>
								</span>
								<span>
									{toCurrency(
										orderItem?.quantityOrdered *
											orderItem?.itemPrice
									)}
								</span>
							</p>
						))}

						<p className="bigMoni my-3 d-flex justify-content-between">
							Total :{" "}
							<span className="ml-3">
								{toCurrency(orderAmount)}
							</span>
						</p>

						<div className="mt-4">
							<button className="btn create-acc" onClick={toggle}>
								Confirm Order
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OrderDeliveryDetail;
