import React from "react";
import "./GroomPanel.scss";

const GroomPanel = ({ cls, children }) => (
	<div className={`groom-panel ${cls}`}>{children}</div>
);

export const GroomPanel2 = ({ children, className, title }) => {
	return (
		<div className={`groom-panel-2 ${className ? className : ""}`}>
			<div className="groom-panel-header">
				<p>{title}</p>
			</div>
			<div className="pt-5">{children}</div>
		</div>
	);
};

export default GroomPanel;
