import React, { Component } from "react";
import { connect } from "react-redux";
import Axios from "axios";
import { sendOTPWebAuth } from "../../../../../lib/api/apiRoutesConfig/services/AccountMS/login/web/subRoutes";
import { API } from "../../../../../lib/api";
import { getErrorMessage } from "../../../../utils/helpers";
import pushToDashboard from "../../../../auth/Login/pushToDashboard";
import { SmallLoader } from "../../../../utils/SmallLoader";
import FormInputGroupV2 from "components/utils/FormInputGroup/FormInputGroupV2";

class TwoFactorAuthenticationForm extends Component {
	_isMounted = false;
	signal = Axios.CancelToken.source();

	state = {
		otp: "",
		showError: false,
		confirmOTPError: false,
		loading: false,
	};

	componentDidMount = () => {
		this._isMounted = true;
	};

	componentWillUnmount = () => {
		this.signal.cancel("canceling network calls");
		this._isMounted = false;
	};

	handleInputChange = ({ target }) => {
		let { name, value } = target;
		value = value.replace(/[^0-9]/g, "");
		this.setState({ [name]: value });
	};

	verifyCustomerCode = async () => {
		try {
			this.setState({ loading: true, showError: false });
			const { otp } = this.state;
			const {
				newAccount: { sessionId },
			} = this.props;

			const res = await API.post(
				sendOTPWebAuth,
				{ otp, sessionId },
				{
					cancelToken: this.signal.token,
				}
			);
			if (res.status === 200) {
				pushToDashboard(res.data.data, this.goToDashboard);
			}
		} catch (err) {
			this._isMounted &&
				this.setState({
					loading: false,
					showError: true,
					confirmOTPError: getErrorMessage(err),
				});
		}
	};

	handleSubmit = (e) => {
		e.preventDefault();
		this.state.otp?.length === 6 && this.verifyCustomerCode();
	};

	goToDashboard = () => {
		this.props.history.replace("/dashboard");
	};

	render = () => {
		const { otp, loading, confirmOTPError, showError } = this.state;

		return (
			<div className="mt-5">
				{showError && (
					<div className="desct error fade-in">
						<p>{confirmOTPError}</p>
					</div>
				)}

				<form onSubmit={this.handleSubmit}>
					<div className="form-row align-items-center">
						<div className="col-xl-9 col-8">
							<FormInputGroupV2
								name="otp"
								autoFocus
								value={otp}
								maxLength={6}
								required
								disabled={loading}
								className="form-control primary"
								placeholder="Enter six digit code"
								onChange={this.handleInputChange}
							/>
						</div>

						<div className="col-xl-3 col-4">
							<button
								className="btn-primary small"
								disabled={loading}
							>
								{loading ? <SmallLoader /> : "Continue"}
							</button>
						</div>
					</div>
				</form>
			</div>
		);
	};
}

export default connect((state) => ({ newAccount: state.auth.newAccount }))(
	TwoFactorAuthenticationForm
);
