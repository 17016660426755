import React from "react";
import PropTypes from "prop-types";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";

const PhoneNumberInputV2 = ({
	inputFieldName,
	inputValue,
	handlePhoneNumberChange,
	error,
	isDisabled,
	inputStyle,
	label,
	fieldId,
	// showErrorMsg,
	preferredCountries = ["NG"],
	...rest
}) => {
	return (
		<div className="input-field-container">
			{label && (
				<label htmlFor={fieldId ? fieldId : inputFieldName}>
					{label}
				</label>
			)}

			<IntlTelInput
				key={preferredCountries[0]}
				preferredCountries={preferredCountries}
				onPhoneNumberChange={handlePhoneNumberChange}
				separateDialCode={true}
				fieldName={inputFieldName}
				value={inputValue}
				disabled={isDisabled}
				inputClassName={`phone-number-input-v2 ${
					error ? "invalid" : ""
				}`}
				autoComplete="off"
				fieldId={fieldId ? fieldId : inputFieldName}
				{...rest}
			/>

			{/* {showErrorMsg && error && (
				<span className="error-msg">{error}</span>
			)} */}
		</div>
	);
};

PhoneNumberInputV2.propTypes = {
	error: PropTypes.any,
	inputFieldName: PropTypes.string.isRequired,
	inputValue: PropTypes.string.isRequired,
	handlePhoneNumberChange: PropTypes.func.isRequired,
	isDisabled: PropTypes.bool,
};

export default PhoneNumberInputV2;
