import { connect } from "react-redux";
import PropTypes from "prop-types";

const checkPermissions = (userPermissions, allowedPermissions = []) => {
	return allowedPermissions.some((allowedPermission) =>
		userPermissions.includes(allowedPermission)
	);
};

const AccessControlNavItem = ({
	children,
	userPermissions,
	allowedPermissions,
}) => {
	if (checkPermissions(userPermissions, allowedPermissions)) {
		return children;
	}

	return null;
};

AccessControlNavItem.propTypes = {
	children: PropTypes.element,
	userPermissions: PropTypes.array.isRequired,
};

export default connect(
	(state) => ({ userPermissions: state.auth.userPermissions }),
	null
)(AccessControlNavItem);
