export const SWITCH_MODES = "SWITCH_MODES";
export const UPDATE_COLOR_SCHEME = "UPDATE_COLOR_SCHEME";
export const SHOW_VERIFY = "SHOW_VERIFY";
export const CLEAR_VERIFY = "CLEAR_VERIFY";
export const UPDATE_USER_ID = "UPDATE_USER_ID";
export const COMPLETE_REGISTRATION = "COMPLETE_REGISTRATION";
export const PROMPT_RESETPASSWORD = "PROMPT_RESETPASSWORD";
export const LOG_USER = "LOG_USER";
export const KEEP_LOGGED_IN = "KEEP_LOGGED_IN";
export const KICK_USER_OUT = "KICK_USER_OUT";
export const LOGOUT_USER = "LOGOUT_USER";
export const UPDATE_IS_LOGGED_IN = "UPDATE_IS_LOGGED_IN";
export const SET_CONNECTION = "SET_CONNECTION";
export const UPDATE_ACTIVEGOALS = "UPDATE_ACTIVEGOALS";
export const UPDATE_CUSTOMER_AUTH_INFO = "UPDATE_CUSTOMER_AUTH_INFO";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const UPDATE_DASHBOARD_RESPONSE = "UPDATE_DASHBOARD_RESPONSE";
export const UPDATE_CUSTOMER_SETTINGS = "UPDATE_CUSTOMER_SETTINGS";
export const UPDATE_NOTIFICATION_SETTTING = "UPDATE_NOTIFICATION_SETTTING";
export const UPDATE_PROFILE_SETTINGS = "UPDATE_PROFILE_SETTINGS";
export const UPDATE_CUSTOMER_TRANSFER_LIMITS =
	"UPDATE_CUSTOMER_TRANSFER_LIMITS";
export const SET_SECURITY_QUESTION = "SET_SECURITY_QUESTION";
export const CLEAR_REDUX_STORE = "CLEAR_REDUX_STORE";
export const CLEAR_SESSION_MESSAGE = "CLEAR_SESSION_MESSAGE";
export const ALT_PHONE_NUMBER_VERIFIED = "ALT_PHONE_NUMBER_VERIFIED";
export const UPDATE_CUSTOMER_ID = "UPDATE_CUSTOMER_ID";
export const UPDATE_STEP_DATA = "UPDATE_STEP_DATA";
export const SET_BALANCE_VISIBILITY = "SET_BALANCE_VISIBILITY";

export const INIT_VERIFICATION = "INIT_VERIFICATION";
export const NEXT_STEP = "NEXT_STEP";
export const PREV_STEP = "PREV_STEP";
export const CLEAR_VERIFICATION = "CLEAR_VERIFICATION";
export const UPDATE_WAYBILL_DETAILS = "UPDATE_WAYBILL_DETAILS";
export const UPDATE_ORDER_DETAILS = "UPDATE_ORDER_DETAILS";
export const GO_TO_STEP = "GO_TO_STEP";
export const UPDATE_WAYBILL_STATUS = "UPDATE_WAYBILL_STATUS";

export const NEXT_WAYBILL_CONFIRMATION_STEP = "NEXT_WAYBILL_CONFIRMATION_STEP";
export const PREV_WAYBILL_CONFIRMATION_STEP = "PREV_WAYBILL_CONFIRMATION_STEP";

export const UPDATE_CART = "UPDATE_CART";
export const UPDATE_CARTTYPE = "UPDATE_CARTTYPE";
export const ALLOW_CONFIRMATION = "ALLOW_CONFIRMATION";
export const SEARCH_PRODUCTS = "SEARCH_PRODUCTS";
export const UPDATE_DEBIT_ACCOUNT_ID = "UPDATE_DEBIT_ACCOUNT_ID";
export const UPDATE_ADDRESS_ID = "UPDATE_ADDRESS_ID";

export const SET_SPLITPAYMENT = "SET_SPLITPAYMENT";
export const SET_PARTICIPANT = "SET_PARTICIPANT";

export const SET_SUCCESS_MODAL = "SET_SUCCESS_MODAL";
