import CustomPagination from "components/dashboard/utils/CustomPagination/CustomPagination";
import FailedTryAgain from "components/dashboard/utils/FailedTryAgain/FailedTryAgain";
import GroomPanel from "components/dashboard/utils/GroomPanel/GroomPanel";
import { MintInfinite } from "components/dashboard/utils/ShineLoader/ShineLoader";
import CustomTable from "components/utils/CustomTable/CustomTable";
import {
	formatCurrency,
	formatDateToString,
	getDayTime,
	getErrorMessage,
	monthYearDateFormat,
} from "components/utils/helpers";
import { ERROR, FINISHED, IDLE, LOADING } from "config/enums";
import { API } from "lib/api";
import { getAllTransactionListings } from "lib/api/apiRoutesConfig/services/FundsManagementMS/bankAccount/subRoutes";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ToastsStore } from "react-toasts";
import "../../TransactionHistory.scss";
import { tableHeadings } from "../constants";
import ReceiptModal from "../ReceiptModal/ReceiptModal";
import TransfersFilters from "../TransfersFilters/TransfersFilters";

const getDefaultFilters = () => ({
	fromDate: "",
	toDate: "",
	transactionType: "",
});

export default function Transfers() {
	const [currentStatus, setCurrentStatus] = useState(IDLE);
	const [transactions, setTransactions] = useState({});
	const [transactionTableData, setTransactionTableData] = useState([]);
	const [page, setPage] = useState(0);
	const [size] = useState(50);
	const [errorMsg, setErrorMsg] = useState("");
	const [filters, setFilters] = useState(getDefaultFilters());
	const [singleTransaction, setSingleTransation] = useState(null);
	const [isDetailsShown, setIsDetailsShown] = useState(false);

	const { dashboardResponse } = useSelector((state) => ({
		dashboardResponse: state.dashboard.dashboardResponse,
	}));

	const { accountId } = dashboardResponse?.bankAccounts
		? dashboardResponse.bankAccounts[0]
		: {};

	const createRowData = (row, index) => {
		const {
			transactionDate,
			amount,
			narration,
			reference,
			debitTransaction,
			inAppTransaction,
			transactionType,
		} = row || {};

		const hasReceipt =
			transactionType === "FUND TRANSFER" || transactionType === "BILLS";

		return {
			data: [
				{
					cell: index + 1,
				},
				{
					cell: monthYearDateFormat(transactionDate, "short"),
				},
				{
					cell: getDayTime(transactionDate),
				},
				{
					cell: (
						<div
							className={`${
								debitTransaction ? "is-debit" : "is-credit"
							}`}
						>
							{formatCurrency(amount)}
						</div>
					),
				},
				{
					cell: <div className="th-narration">{narration}</div>,
				},
				{
					cell: reference,
				},
				{
					cell: inAppTransaction && hasReceipt && (
						<button
							className="th-details-btn"
							onClick={() => {
								setSingleTransation(row);
								setIsDetailsShown(true);
							}}
						>
							View details
						</button>
					),
				},
			],
		};
	};

	const fetchTransactionHistory = async () => {
		setCurrentStatus(LOADING);
		try {
			const response = await API.get(
				getAllTransactionListings(accountId),
				{
					params: {
						...filters,
						fromDate: formatDateToString(filters.fromDate),
						toDate: formatDateToString(filters.toDate),
						size,
						page,
					},
				}
			);
			const { status, data } = response || {};
			if (status === 200) {
				const { records } = data.data;
				setTransactions(data.data);
				let tableData = [];
				records.forEach((item, index) => {
					const newRow = createRowData(item, index);
					tableData.push(newRow);
				});
				setTransactionTableData(tableData);
				setCurrentStatus(FINISHED);
			}
		} catch (err) {
			setTransactions([]);
			setTransactionTableData([]);
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
			setCurrentStatus(ERROR);
			setErrorMsg(getErrorMessage(err));
		}
	};

	const goToPage = (page) => {
		setPage(page);
	};

	const handleFilterFormSubmit = (values) => {
		setPage(0);
		setFilters((prev) => ({ ...prev, ...values }));
	};

	const handleFilterFormReset = () => {
		const filters = getDefaultFilters();
		setPage(0);
		setFilters(filters);
		return filters;
	};

	useEffect(() => {
		fetchTransactionHistory();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, size, filters]);

	const renderBasedOnStatus = () => {
		const { records, totalPages } = transactions;

		switch (currentStatus) {
			case LOADING:
				return (
					<div className="d-flex w-100 justify-content-center align-items-center trh-loading">
						<MintInfinite relative />
					</div>
				);

			case ERROR:
				return (
					<div className="d-flex w-100 justify-content-center align-items-center trh-loading">
						<FailedTryAgain
							errorMssg={errorMsg}
							handleTryAgain={fetchTransactionHistory}
						/>
					</div>
				);

			case FINISHED:
				if (records?.length <= 0) {
					return (
						<div className="d-flex w-100 justify-content-center align-items-center trh-loading">
							<h6 className="notready">
								No Transaction History For This Period.
							</h6>
						</div>
					);
				}

				return (
					<>
						<GroomPanel cls="th-groom-panel">
							<CustomTable
								tableId="pending-transactions"
								className="th-table"
								headers={tableHeadings}
								bodyData={transactionTableData}
							/>
						</GroomPanel>

						<CustomPagination
							currentPage={page}
							pageSize={totalPages}
							goToPage={goToPage}
						/>
					</>
				);

			default:
				return null;
		}
	};

	return (
		<>
			<div className="trh-page">
				<TransfersFilters
					initialValues={filters}
					onSubmit={handleFilterFormSubmit}
					onReset={handleFilterFormReset}
				/>
				{renderBasedOnStatus()}
			</div>

			<ReceiptModal
				show={isDetailsShown && !!singleTransaction}
				transaction={singleTransaction}
				onClose={() => {
					setIsDetailsShown(false);
					setSingleTransation(null);
				}}
			/>
		</>
	);
}
