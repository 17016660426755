import React from "react";
import { ReactSVG } from "react-svg";
import { Link } from "react-router-dom";
import imageFiles from "../../../../../common/imageFiles/imageFiles";
import PiggyIcon from "../../widgets/ActiveSavingsGoalsPlans/DefaultSavingsPlan/files/piggy.svg";
import Modal from "../../../../../utils/Modal/Modal";
import "./SavingGoalsCategories.scss";

const { umbrealaIcon, walletIcon } = imageFiles.icons;

const SavingGoalsCategories = ({ show, toggleModal }) => {
	return (
		<Modal show={show} size="lg" toggle={toggleModal}>
			<div className="seriesA mt-2 pb-2" style={{ overflow: "visible" }}>
				<div className="text-center mt-2">
					<h6>Create New Savings Plan</h6>
					<p style={{ textTransform: "initial" }}>
						Choose a savings type
					</p>
				</div>
			</div>

			<div className="SavingGoalsCat">
				<div className="row mr-0">
					<div className="col-lg-6 pr-0">
						<Link to="/savings/emergencies/">
							<div className="savings-box danger">
								<div className="round-money default">
									<ReactSVG src={umbrealaIcon} title="Save" />
								</div>

								<div className="ground">
									<h6>Emergency Funds</h6>
									<p>
										Set aside accessible funds for life’s
										unexpected events.
									</p>
								</div>
							</div>
						</Link>
					</div>

					<div className="col-lg-6 pr-0">
						<Link to="/savings/spend-and-save/">
							<div className="savings-box success">
								<div className="round-money default">
									<ReactSVG src={walletIcon} title="Save" />
								</div>

								<div className="ground">
									<h6 style={{ textTransform: "initial" }}>
										Spend and Save
									</h6>
									<p>
										Save as you spend. Build up your savings
										with no effort required. All you have to
										do is activate the spend and save
										feature.
									</p>
								</div>
							</div>
						</Link>
					</div>

					<div className="col-lg-6 pr-0">
						<Link to="/savings/goals">
							<div className="savings-box primary">
								<div className="round-money default">
									<ReactSVG src={PiggyIcon} title="Save" />
								</div>
								<div className="ground">
									<h6>Savings Goal</h6>
									<p>
										Start saving towards a major life goal
										for a minimum of 30 days and get
										rewarded with impressive interest rates.
									</p>
								</div>
							</div>
						</Link>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default SavingGoalsCategories;
