/* eslint-disable react-hooks/exhaustive-deps */
import "./PaymentPanel.scss";
import React, { useEffect } from "react";
import Modal from "components/utils/Modal/Modal";
import ModalHeader from "components/utils/Modal/CustomModalHeader/CustomModalHeader";
import { Formik } from "formik";
import { Label } from "reactstrap";
import { ReactSVG } from "react-svg";
import CommonSelect from "components/utils/CommonSelect/CommonSelect";
import FormInputGroup from "components/utils/FormInputGroup/FormInputGroup";
import * as yup from "yup";
import EditIcon from "./../utils/assets/edit-pen.svg";
import { useState } from "react";
import { ToastsStore } from "react-toasts";
import { getErrorMessage } from "components/utils/helpers";
import { API } from "lib/api";
import { SmallLoader } from "components/utils/SmallLoader";
import { getSalaryRange } from "lib/api/apiRoutesConfig/services/tradeMS/common/subRoutes";
import { createAccountAction, editAccountAction, getAccountAction,sendOTPAction } from "lib/api/apiRoutesConfig/services/tradeMS/BNPL/subRoutes";


const formValidationSchema = yup.object().shape({
	companyName: yup.string().required("Company name is required"),
	// rangeAmmount: yup.object().required("Select salary range"),
		rangeAmmount: yup
		.object()
		.nullable().required("Range is required")
		.shape({ label: yup.string(), value: yup.mixed() }),
	officialEmail: yup.string().trim().email('*Invalid email').required('*Email is required'),

});

export default function PayLater({ show, onClose,nextModal }) {
	return (
		<Modal show={show} scrollable>
			<ModalHeader
				heading="Pay Later"
				className="p-0"
				headerClassName="setup-modals-header px-sm-5 px-3 py-3"
				dismissable={() => onClose?.()}
			/>
			<PayLaterForm nextModal={nextModal} onClose={onClose} />
		</Modal>
	);
}
const PayLaterForm = ({nextModal, onClose}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [salaryRange, setSalaryRange] = useState([]) || null;
	const [BNPLAccountDetails, setBNPLAccountDetails] = useState('') || null;
	const [isEdit, setIsEdit] = useState(false);

	//Salary 
	const salary = salaryRange && salaryRange.filter((obj) => {
		const data = obj.description === BNPLAccountDetails.salaryRange
		return data
		// return {
		// 	label: data[0]?.description ,
		// 	value: {
		// 		maximumAmount:data[0]?.maximumAmount,
		// 		minimumAmount:data[0]?.minimumAmount
		// 				},
		// }
	}  )
	const newSalaryData = salary[0]

	const initialFormValues = {
		companyName: !isEdit? "" :  BNPLAccountDetails?.companyName,
		officialEmail: !isEdit? "" :  BNPLAccountDetails?.officialEmail,
		rangeAmmount: !isEdit? null : {
			label:newSalaryData?.description,
			value: {
			maximumAmount:newSalaryData?.maximumAmount,
			minimumAmount:newSalaryData?.minimumAmount
		},
		}
	};

	const fetchSalarayRange = async () => {
		setIsLoading(true);
		try {
			const res = await API.get(getSalaryRange);
			const { status, data } = res || {};
			if (status === 200) {
				// console.log(data)
				setSalaryRange(data.data)
			}
		} catch (err) {
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		} finally {
			setIsLoading(false);
		}
	};

		const fetchBNLPAccount = async () => {
		setIsLoading(true);
		try {
			const res = await API.get(getAccountAction);
			const { status, data } = res || {};
			if (status === 200) {
				// console.log('BNPL', data)
				setBNPLAccountDetails(data.data)
				setIsLoading(true);
			}
		} catch (err) {
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		} finally {
			setIsLoading(false);
		}
	};


	// Resend OTP

	const resendOTP = async () => {
			setIsLoading(true);
		try {
			const res = await API.get(sendOTPAction);
			const { status, data } = res || {};
			if (status === 200) {
				onClose()
				nextModal(true)
				ToastsStore.success(data.data, 6000, "right-toast");
			}
		} catch (err) {
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		} finally {
			setIsLoading(false);
		}
	}

// Display exitsing BNPL account details
	
	const BNPLDetails = () => {
		return (
			<div className="mkt-BNPL-account  ">
				<div>
					<span>Company Name</span>
					<div className="input">
						{BNPLAccountDetails.companyName}
					</div>
				</div>
				<div>
					<span>Official Email</span>
					<div className="input">
						{BNPLAccountDetails.officialEmail}
					</div>
				</div>
				<div>
					<span>Salary Range</span>
					<div className="input">
						{BNPLAccountDetails.salaryRange}
					</div>
				</div>
				<div className=" w-100">
					<button
						className="btn btn-mint-default"
						type="button"
						onClick={resendOTP}
						disabled={isLoading}
					>
						{isLoading ? (
							<SmallLoader />
						) : (
							<>
								Continue
							</>
						)}
					</button>
				</div>
			</div>
		)

	}

	const handleSubmit = async (values) => {
		setIsLoading(true);
		console.log(values)
		const takeAction = isEdit ? editAccountAction : createAccountAction
		try {
			const res = await API.post(takeAction, {
				companyName:values.companyName,
				officialEmail: values.officialEmail,
				minimumAmount: values.rangeAmmount.value.minimumAmount,
				maximumAmount:values.rangeAmmount.value.maximumAmount
			});
			const { status, data } = res || {};
			if (status === 200) {
				ToastsStore.success(data.message, 6000, "right-toast");
				onClose()
				nextModal()

			}
		} catch (err) {
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		} finally {
			setIsLoading(false);
		}
	};

	// BNPL Form 
	const BNPLForm = () => {
		return (
			<Formik
			initialValues={initialFormValues}
			validationSchema={formValidationSchema}
			onSubmit={(values) => handleSubmit(values)}
		>
			{({
				values,
				errors,
				touched,
				handleSubmit,
				setFieldValue,
				handleChange,
				handleBlur,
				}) => {
					console.log(errors)
					console.log(values)
				return (
					<form
						className="mkt-payment-modal-form "
						onSubmit={(e) => {
							e.preventDefault();
							handleSubmit();
						}}
					>

						<div className="mb-4 col-12">
							<FormInputGroup
								id="companyName"
								placeholder=""
								name="companyName"
								label="What is the name of your company?"
								className="mkt-pay-later-modal-input"
								onBlur={handleBlur}
								value={values.companyName}
								onChange={handleChange}
								error={touched.companyName && errors.companyName}
							/>
						</div>

						<div className="mb-4 col-12">
							<FormInputGroup
								id="officialEmail"
								label="Provide your work email address"
								name="officialEmail"
								placeholder=""
								className="mkt-pay-later-modal-input"
								onBlur={handleBlur}
								value={values.officialEmail}
								onChange={handleChange}
								error={
									touched.officialEmail &&
									errors.officialEmail
								}
							/>
						</div>

				
						<div className="mb-4 col-12">
							<Label for="rangeAmmount">Choose your salary range</Label>
							<CommonSelect
								name="rangeAmmount"
								placeholder={isEdit? values.rangeAmmount.label : "Select Range"}
								onBlur={handleBlur}
								error={touched.rangeAmmount && errors.rangeAmmount}
									handleChange={(rangeAmmount) => {
									setFieldValue("rangeAmmount", rangeAmmount);
									}}
								showErrorMsg
								options={salaryRange?.map((salary) => ({
												label: salary?.description ,
									value: {
										maximumAmount:salary?.maximumAmount,
										minimumAmount:salary?.minimumAmount
												},
								}))}
							/>
						</div>

						<div className=" d-flex flex-row px-3">
							<button
								onClick={()=> isEdit? setIsEdit(false) : onClose()}
								className="button-outline btn btn-mint-default "
								type="button"
								disabled={isLoading}
							>
								Cancel
							</button>
							<button
								className="btn btn-mint-default "
								type="submit"
								disabled={isLoading}
							>
								{isLoading ? (
									<SmallLoader />
								) : (
									<>
										{!isEdit? 'Continue' : 'Update'}
									</>
								)}
							</button>
						</div>
					</form>
				);
			}}
			</Formik>
		)
	}
	useEffect(() => {
		fetchSalarayRange();
		fetchBNLPAccount()
	},[]);

	return (
    <div className="mkt-payment-modal ">
			<span>
				<p className="sub-text">Please provide the following information to proceed</p>
				{BNPLAccountDetails && BNPLAccountDetails.accountExists && !isLoading && !isEdit ? <span
					onClick={()=>setIsEdit(true)}
				>
					<span className="icon">
						<ReactSVG src={EditIcon} />
					</span>
					<p className="small-text">Edit information</p>
				</span> : ''}
			</span>
			{BNPLAccountDetails && !BNPLAccountDetails.accountExists && !isLoading ?
			<BNPLForm/>
				:
				<>
					{!isEdit?<BNPLDetails/>:<BNPLForm/> }
				</>
			}

    </div>
	);
};
