import { getErrorMessage, parseAmount } from "components/utils/helpers";
import ModalHeader from "components/utils/Modal/CustomModalHeader/CustomModalHeader";
import Modal from "components/utils/Modal/Modal";
import { SmallLoader } from "components/utils/SmallLoader";
import { ERROR, FINISHED, IDLE, LOADING } from "config/enums";
import { API } from "lib/api";
import { updateSavingFrequency } from "lib/api/apiRoutesConfig/services/SavingsMS/savingsGoals/subRoutes";
import React, { useState } from "react";
import { ToastsStore } from "react-toasts";
import ConfirmationSuccessModal from "./ConfirmationSuccessModal";
import "../../EmergencySavings.scss";
import { Formik } from "formik";
import * as yup from "yup";
import CustomCurrencyInput from "components/utils/CustomCurrencyInput/CustomCurrencyInput";
import CustomLargeBadge from "components/utils/CustomLargeBadge/CustomLargeBadge";

const formValidationSchema = yup.object().shape({
	amount: yup
		.number()
		.required("Amount is required")
		.min(100, "Minimum amount is ₦100"),
	frequency: yup.string().required("Select a frequency"),
});

const MIN_STEP = 0;
const MAX_STEP = 1;

const savingFrequencyEnums = {
	DAILY: "DAILY",
	WEEKLY: "WEEKLY",
	MONTHLY: "MONTHLY",
};

export default function AutomaticDepositModal({
	show,
	autoSaveEnabled,
	goalId,
	onClose,
	refetch,
}) {
	const [currentState, setCurrentState] = useState(IDLE);
	const [successMsg, setSuccessMsg] = useState("");
	const [currentStep, setCurrentStep] = useState(MIN_STEP);

	const goToNextStep = () => {
		setCurrentStep((prev) => Math.min(prev + 1, MAX_STEP));
	};

	const renderBasedOnState = () => {
		switch (currentState) {
			case FINISHED:
				return (
					<ConfirmationSuccessModal
						message={successMsg}
						onClose={onClose}
						onClick={() => {
							setCurrentStep(MIN_STEP);
							setCurrentState(IDLE);
							onClose();
							refetch();
						}}
					/>
				);

			default:
				return (
					<ConfirmationModal
						autoSaveEnabled={autoSaveEnabled}
						currentStep={currentStep}
						goToNextStep={goToNextStep}
						currentState={currentState}
						goalId={goalId}
						setCurrentState={(state) => setCurrentState(state)}
						setSuccessMsg={(message) => setSuccessMsg(message)}
						onClose={() => {
							onClose();
							setCurrentStep(MIN_STEP);
						}}
					/>
				);
		}
	};

	return (
		<Modal show={show}>
			{currentState !== FINISHED && (
				<ModalHeader
					heading={
						currentStep === MAX_STEP
							? "Change Savings Frequency"
							: autoSaveEnabled
							? "Stop Automatic Deposit"
							: "Confirm Automatic Deposit"
					}
					className="p-0"
					headerClassName="setup-modals-header px-sm-5 px-3 py-3"
					dismissable={() => {
						onClose();
						setCurrentStep(MIN_STEP);
					}}
				/>
			)}
			<>{renderBasedOnState()}</>
		</Modal>
	);
}

const ConfirmationModal = ({
	autoSaveEnabled,
	currentStep,
	goToNextStep,
	setCurrentState,
	goalId,
	setSuccessMsg,
	currentState,
	onClose,
}) => {
	const initialFormValues = {
		amount: "",
		frequency: "",
	};

	const handleStopAutomaticDeposit = async () => {
		setCurrentState(LOADING);
		try {
			const res = await API.delete(updateSavingFrequency(goalId));
			if (res.status) {
				setCurrentState(FINISHED);
				setSuccessMsg(res.data.message);
			}
		} catch (err) {
			setCurrentState(ERROR);
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		}
	};

	const handleStartAutomaticDeposit = async (values) => {
		setCurrentState(LOADING);
		try {
			const res = await API.put(updateSavingFrequency(goalId), {
				...values,
			});
			if (res.status) {
				setCurrentState(FINISHED);
				setSuccessMsg(res.data.message);
			}
		} catch (err) {
			setCurrentState(ERROR);
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		}
	};

	const renderBasedOnStep = () => {
		switch (currentStep) {
			case 0:
				return (
					<>
						<p>
							{autoSaveEnabled
								? "Are you sure you want to stop Automatic Deposit?"
								: "Are you sure you want to start Automatic Deposit?"}
						</p>
						<div className="es-automatic-deposit__footer">
							<button
								className="btn create-acc"
								disabled={currentState === LOADING}
								onClick={() => {
									if (autoSaveEnabled) {
										handleStopAutomaticDeposit();
										return;
									}
									goToNextStep();
								}}
							>
								{currentState === LOADING ? (
									<SmallLoader />
								) : (
									"Yes"
								)}
							</button>
							<button
								className="btn create-acc"
								onClick={onClose}
							>
								No
							</button>
						</div>
					</>
				);

			case 1:
				return (
					<Formik
						initialValues={initialFormValues}
						validationSchema={formValidationSchema}
						onSubmit={(values) =>
							handleStartAutomaticDeposit(values)
						}
					>
						{({
							values,
							errors,
							touched,
							handleSubmit,
							setFieldValue,
							handleBlur,
						}) => {
							return (
								<form
									onSubmit={(e) => {
										e.preventDefault();
										handleSubmit();
									}}
								>
									<div className="mb-4">
										<CustomCurrencyInput
											className="es-input"
											name="amount"
											label="Amount"
											id="amount"
											value={values.amount}
											placeholder="Enter Amount"
											onBlur={handleBlur}
											error={
												touched.amount && errors.amount
											}
											onChange={(value) => {
												setFieldValue(
													"amount",
													parseAmount(value)
												);
											}}
										/>
									</div>

									<div className="mb-4">
										<label>Savings frequency</label>
										<div className="es-frequency">
											<CustomLargeBadge
												title="Daily"
												isSelected={
													values.frequency ===
													savingFrequencyEnums.DAILY
												}
												onClick={() =>
													setFieldValue(
														"frequency",
														savingFrequencyEnums.DAILY
													)
												}
											/>

											<CustomLargeBadge
												title="Monthly"
												isSelected={
													values.frequency ===
													savingFrequencyEnums.MONTHLY
												}
												onClick={() =>
													setFieldValue(
														"frequency",
														savingFrequencyEnums.MONTHLY
													)
												}
											/>

											<CustomLargeBadge
												title="Weekly"
												isSelected={
													values.frequency ===
													savingFrequencyEnums.WEEKLY
												}
												onClick={() =>
													setFieldValue(
														"frequency",
														savingFrequencyEnums.WEEKLY
													)
												}
											/>
										</div>
										{touched.frequency &&
											errors.frequency && (
												<span className="error-msg">
													{errors.frequency}
												</span>
											)}
									</div>

									<button
										className="btn create-acc"
										disabled={currentState === LOADING}
										type="submit"
									>
										{currentState === LOADING ? (
											<SmallLoader />
										) : (
											"Activate"
										)}
									</button>
								</form>
							);
						}}
					</Formik>
				);

			default:
				return null;
		}
	};

	return (
		<div className="es-automatic-deposit px-sm-5 px-3">
			{renderBasedOnStep()}
		</div>
	);
};
