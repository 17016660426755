import FailedTryAgain from "components/dashboard/utils/FailedTryAgain/FailedTryAgain";
import { GroomPanel2 } from "components/dashboard/utils/GroomPanel/GroomPanel";
import { MintInfinite } from "components/dashboard/utils/ShineLoader/ShineLoader";
import { getErrorMessage } from "components/utils/helpers";
import UserRoleWarning from "components/utils/UserRoleWarning/UserRoleWarning";
import { ERROR, FINISHED, IDLE, INDIVIDUAL, LOADING } from "config/enums";
import { API } from "lib/api";
import { getDetailedCategories } from "lib/api/apiRoutesConfig/services/BillsService/subRoutes";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ToastsStore } from "react-toasts";
import "../../BillsPayment/BillPayment.scss";
import { defaultPath } from "../BillsPaymentRoutes/BillsPaymentRoutes";

export default function BillsPaymentCategories() {
	const [currentStatus, setCurrentStatus] = useState(IDLE);
	const [categories, setCategories] = useState([]);
	const [errorMsg, setErrorMsg] = useState("");

	const { loginResponse, activeAccountType } = useSelector(
		(state) => state.auth
	);

	const accountType =
		activeAccountType === INDIVIDUAL
			? "activeIndividualAccount"
			: "activeCorporateAccount";

	const { currentRole } = loginResponse[accountType]?.profileSetting || {};
	const isApprover = currentRole === "APPROVER";

	const fetchCategories = async () => {
		setCurrentStatus(LOADING);
		try {
			const response = await API.get(getDetailedCategories);
			const { status, data } = response || {};
			if (status === 200) {
				setCategories(data.data);
				setCurrentStatus(FINISHED);
			}
		} catch (err) {
			setCategories([]);
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
			setCurrentStatus(ERROR);
			setErrorMsg(getErrorMessage(err));
		}
	};

	useEffect(() => {
		fetchCategories();
	}, []);

	const renderBasedOnStatus = () => {
		switch (currentStatus) {
			case LOADING:
				return (
					<div className="d-flex w-100 justify-content-center align-items-center">
						<MintInfinite relative />
					</div>
				);

			case ERROR:
				return (
					<div className="d-flex w-100 justify-content-center align-items-center">
						<FailedTryAgain
							errorMssg={errorMsg}
							handleTryAgain={fetchCategories}
						/>
					</div>
				);

			case FINISHED:
				return (
					<>
						<h2>Select a category</h2>
						<div className="bp-categories__categories">
							{categories.map((item, index) => {
								const url = item.name
									// eslint-disable-next-line no-useless-escape
									.replace(/[\s&\/]+/g, "-")
									.toLowerCase();
								return (
									<Link
										key={index}
										to={{
											pathname: `${defaultPath}/${url}`,
											state: {
												categoryId: item.categoryId,
											},
										}}
										className="bp-category-box"
									>
										<img
											alt={item.name}
											src={item.iconUrl}
										/>
										<h3>{item.name}</h3>
									</Link>
								);
							})}
						</div>
					</>
				);

			default:
				return null;
		}
	};

	return (
		<GroomPanel2 title="Choose Bill Payment Type">
			<div className="bp-categories">
				{isApprover ? <UserRoleWarning /> : renderBasedOnStatus()}
			</div>
		</GroomPanel2>
	);
}
