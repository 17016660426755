import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import settingRoutes, { defaultPath } from "../routes";

class SettingsComponent extends Component {
	render() {
		return (
			<>
				<Switch>
					{settingRoutes.map(({ path, component }, index) => (
						<Route
							key={index}
							exact={true}
							path={path}
							component={component}
						/>
					))}
					<Redirect path="*" to={`${defaultPath}/profile`} />
				</Switch>
			</>
		);
	}
}

export default SettingsComponent;
