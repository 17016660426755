import { UPDATE_DASHBOARD_RESPONSE, SET_BALANCE_VISIBILITY } from "./types";
import { keepUserLogged } from "./loginActions";

export const updateDashResponse = (data) => (dispatch) => {
	dispatch({
		type: UPDATE_DASHBOARD_RESPONSE,
		payload: data,
	});
	dispatch(keepUserLogged());
};

export const updateBalanceVisibility = (payload) => (dispatch) => {
	localStorage.setItem("isBalanceHidden", payload);
	dispatch({ type: SET_BALANCE_VISIBILITY, payload });
};
