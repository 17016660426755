import React, { createContext, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import GroomPanel from "../../../utils/GroomPanel/GroomPanel";
import PageNavigatorV2 from "../../../utils/PageNavigator/PageNavigatorV2";
import CreateInvestmentContent from "./CreateInvestmentContent/CreateInvestmentContent";
import { API } from "../../../../../lib/api";
import { getAllInvestmentTenors } from "../../../../../lib/api/apiRoutesConfig/services/SavingsMS/common/subRoutes";
import { responseStatus } from "../../../../constants";
import ContentWrapper from "../../../../utils/ResponseState/ContentWrapper";
import { getErrorMessage } from "../../../../utils/helpers";

const { LOADING, FINISHED, ERROR } = responseStatus;
export const InvestmentContext = createContext({});

const CreateInvestment = () => {
	const { push } = useHistory();

	const [currentState, setCurrentState] = useState();
	const [durationCategory, setDurationCategory] = useState([]);
	const [errorMsg, setErrorMsg] = useState();
	const [step, setCurrentStep] = useState(1);
	const [formValues, setFormValues] = useState({
		durationId: "",
		referralCode: "",
		investmentAmount: "",
		durationInMonths: "",
	});

	useEffect(() => {
		fetchDurationCategory();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchDurationCategory = async () => {
		try {
			setCurrentState(LOADING);
			const res = await API.get(getAllInvestmentTenors);

			const { status, data } = res || {};
			if (status === 200) {
				setDurationCategory(data?.data);
				setCurrentState(FINISHED);
			}
		} catch (err) {
			setCurrentState(ERROR);
			setErrorMsg(getErrorMessage(err));
		}
	};

	const next = () => setCurrentStep((prev) => prev + 1);

	const previous = () => setCurrentStep((prev) => prev - 1);

	const handleRadioSelect = (value) => handleChange("durationId", value);

	const handleChange = (name, value) => {
		setFormValues((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const goBack = () => (step === 1 ? push("/investments") : previous());

	return (
		<InvestmentContext.Provider
			value={{
				formValues,
				next,
				previous,
				handleChange,
				handleRadioSelect,
				durationCategory,
			}}
		>
			<Helmet>
				<title>{process.env.REACT_APP_NAME} - Investments</title>
			</Helmet>

			<div className="emergency__details primary">
				<span className="bigback__btn--link primary" onClick={goBack}>
					Back
				</span>
			</div>

			<div className="mb-4 mt-5">
				<PageNavigatorV2
					title="Setting up a new Investment"
					subtitle="Please select your Investment preferences  and start Investing"
				/>
			</div>

			<GroomPanel cls="flex__center">
				<ContentWrapper
					currentState={currentState}
					errorMsg={errorMsg}
					refetch={fetchDurationCategory}
				>
					<div className="investment row flex__center w-100 py-5">
						<div className="col-xl-6 col-lg-7 low-form">
							<div className="flex__between">
								<div className="investment-step">
									Step {step}
								</div>
								<small className="text--main">
									{step} of 3 Done
								</small>
							</div>

							<CreateInvestmentContent step={step} />
						</div>
					</div>
				</ContentWrapper>
			</GroomPanel>
		</InvestmentContext.Provider>
	);
};

export default CreateInvestment;
