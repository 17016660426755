import React, { Component } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { withRouter } from "react-router-dom";
import ProfileIcon from "./files/profile.svg";
import CameraIcon from "./files/camera.svg";
import { ReactSVG } from "react-svg";
import "./WelcomeCustomer.scss";

class WelcomeCustomerSection extends Component {
	moveNext = () => {
		window.dataLayer.push({ event: "PROFCOMP_btn_click" });
		this.props.history.replace("/onboard/complete-profile");
	};

	render = () => {
		const { firstName } = this.props.newAccount;
		return (
			<>
				<Helmet>
					<title>
						{process.env.REACT_APP_NAME} - {"  Welcome to Mintyn"}
					</title>
				</Helmet>
				<div className="row h-100 justify-content-center mt-5">
					<div className="col-xl-8 col-lg-9 col-md-9">
						<div className="">
							<div>
								<div className="my-1">
									<h6 className="mb-4 text-left info-heading">
										Welcome, {firstName}
									</h6>
									<i className="info-text text-left mb-4">
										To complete your account setup, we will
										be asking you to provide some
										information in 2 steps below
									</i>
									<ul className="unstyled progressTimeline">
										<li>
											<div className="row align-items-center">
												<div className="col-1">
													<ReactSVG
														src={ProfileIcon}
														className="roundIcon"
													/>
												</div>
												<div className="col-10">
													<p>
														Fill basic information
														about yourself
													</p>
												</div>
											</div>
										</li>

										<li>
											<div className="row align-items-center">
												<div className="col-1">
													<ReactSVG
														src={CameraIcon}
														className="roundIcon"
													/>
												</div>
												<div className="col-10">
													<p>
														Take a selfie to confirm
														identity.
													</p>
												</div>
											</div>
										</li>
									</ul>
									<div className="mt-5">
										<button
											className="btn-primary w-100"
											onClick={this.moveNext}
										>
											Complete Profile
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	};
}

const WelcomeCustomer = withRouter(WelcomeCustomerSection);
export default connect(
	(state) => ({ newAccount: state.auth.newAccount }),
	null
)(WelcomeCustomer);
