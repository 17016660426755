import signup from "../";
export const {
	verifyBvnEndpoint,
	verifyBankAccountDetails,
	bvnVerifCodeEndpoint,
	resendBvnVerifCode,
	basicInformationEndpoint,
	updateIdentitySelfie,
	policyTermsConditionAcceptance,
	loginDetailsEndpoint,
	verifyPhoneNumber,
	verifyAccountType,
	validateAddress,
} = {
	verifyBvnEndpoint: `${signup}/verify-user/bvn`,
	verifyBankAccountDetails: `${signup}/verify-user/account-detail`,
	bvnVerifCodeEndpoint: (sessionID) => `${signup}/${sessionID}/verify-code`,
	resendBvnVerifCode: (sessionID) =>
		`${signup}/${sessionID}/resend-verification-code`,
	basicInformationEndpoint: (sessionID) =>
		`${signup}/${sessionID}/basic-information`,
	updateIdentitySelfie: (sessionID) =>
		`${signup}/${sessionID}/identity/selfie`,
	policyTermsConditionAcceptance: (sessionID) =>
		`${signup}/${sessionID}/policy-terms-condition-acceptance`,
	loginDetailsEndpoint: (sessionID) => `${signup}/${sessionID}/login-details`,
	verifyPhoneNumber: `${signup}/confirm-phone-availability`,
	verifyAccountType: (sessionID) => `${signup}/${sessionID}/account-type`,
	validateAddress: (sessionID) => `${signup}/${sessionID}/validate-address`,
};
