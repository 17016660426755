import React, { Component } from "react";
import Modal from "../../../../../../utils/Modal/Modal";
import { INITIAL, LOADING, FINISHED } from "../../../../../../../config/enums";
import OperationStatus, {
	failureResponseStatus,
	successResponseStatus,
} from "../../../../../utils/OperationStatus/OperationStatus";
import LoadingBox from "../../../../../../utils/LoadingBox/LoadingBox";
import { getErrorMessage } from "../../../../../../utils/helpers";
import { API } from "../../../../../../../lib/api";
import { deactivateSchdeuleBill } from "../../../../../../../lib/api/apiRoutesConfig/services/BillsService/subRoutes";
import DeactivateSchdeuleBill from "../DeactivateScheduleBill/DeactivateScheduleBill";

class DeactivateScheduleBillModal extends Component {
	state = {
		currentState: INITIAL,
		statusObj: {},
	};

	componentDidMount() {
		const { bill, toggleModal } = this.props;
		this.setState({ bill, toggleModal });
	}

	refetchOnSuccess = () => {
		const { toggleModal, refetch } = this.props;
		toggleModal();
		refetch();
	};

	deactivateBill = async (id) => {
		try {
			this.setState({ currentState: LOADING });
			const res = await API.delete(deactivateSchdeuleBill(id));
			if (res.status === 200) {
				this.setState({
					currentState: FINISHED,
					statusObj: {
						status: successResponseStatus,
						statusMessage: res.data.message,
						successLink: "#",
						successLinkText: "Okay",
						handleSuccessLink: this.refetchOnSuccess,
					},
				});
			}
		} catch (err) {
			this.setState({
				currentState: FINISHED,
				statusObj: {
					status: failureResponseStatus,
					statusMessage: getErrorMessage(err),
				},
			});
		}
	};

	renderBasedOnState = () => {
		const { currentState, bill, toggleModal } = this.state;

		switch (currentState) {
			case INITIAL:
				return (
					<DeactivateSchdeuleBill
						bill={bill}
						deactivateBill={this.deactivateBill}
						toggleModal={toggleModal}
					/>
				);

			case LOADING:
				return <LoadingBox />;

			case FINISHED:
				const { statusObj } = this.state;
				return (
					<OperationStatus
						statusObj={statusObj}
						handleTryAgain={toggleModal}
						{...this.props}
					/>
				);

			default:
				break;
		}
	};

	render() {
		return (
			<Modal show={true}>
				<>{this.renderBasedOnState()}</>
			</Modal>
		);
	}
}

export default DeactivateScheduleBillModal;
