import FormDatePickerGroup from "components/utils/FormDatePickerGroup/FormDatePickerGroup";
import FormInputGroup from "components/utils/FormInputGroup/FormInputGroup";
import React, { useEffect } from "react";
import "../../AccountStatement.scss";
import { subDays, format as formatDate } from "date-fns";
import CommonSelect from "components/utils/CommonSelect/CommonSelect";
import { accountStatementTypeEnum } from "config/enums";
import { Formik } from "formik";
import * as yup from "yup";
import { useState } from "react";
import SubmitModal from "../SubmitModal/SubmitModal";
import { ToastsStore } from "react-toasts";
import { getErrorMessage } from "components/utils/helpers";
import { API } from "lib/api";
import {
	getEmbassies,
	getEmbassyAddresses,
} from "lib/api/apiRoutesConfig/services/FundsManagementMS/common/subRoutes";
import DisclaimerModal from "../DisclaimerModal/DisclaimerModal";
import { useSelector } from "react-redux";
import CustomLargeBadge from "components/utils/CustomLargeBadge/CustomLargeBadge";

const formValidationSchema = yup.object().shape({
	accountStatementType: yup
		.string()
		.required("Please select an account statement type"),
	embassy: yup.mixed().when("accountStatementType", {
		is: (value) => value === accountStatementTypeEnum.VISA_APPLICATION,
		then: yup.object().required("Embassy is required").nullable().shape({
			label: yup.string(),
			value: yup.string(),
		}),
		otherwise: yup.mixed(),
	}),
	embassyAddressId: yup.mixed().when("accountStatementType", {
		is: (value) => value === accountStatementTypeEnum.VISA_APPLICATION,
		then: yup
			.object()
			.required("Embassy address is required")
			.nullable()
			.shape({
				label: yup.string(),
				value: yup.string(),
			}),
		otherwise: yup.mixed(),
	}),
	recipient: yup
		.string()
		.required("Email address is required")
		.email("Please use a valid email"),
	fromDate: yup.date().required("Start date is required"),
	toDate: yup.date().required("End date is required"),
});

export default function StatementForm({ accountStatementType, goToPrevStep }) {
	const [selectedDate, setSelectedDate] = useState("");
	const [showModal, setShowModal] = useState(false);
	const [payload, setPayload] = useState(null);
	const [isEmbassiesLoading, setIsEmbassiesLoading] = useState(false);
	const [isAddressesLoading, setIsAddressesLoading] = useState(false);
	const [embassies, setEmbassies] = useState([]);
	const [addresses, setAddresses] = useState({});
	const [showDisclaimer, setShowDisclaimer] = useState(false);
	const { currentUser } = useSelector((state) => ({
		currentUser: state.auth.currentUser,
	}));

	const { email: userEmail } = currentUser || {};

	const { embassyAddresses } = addresses || {};

	const initialValues = {
		accountStatementType,
		embassyAddressId: null,
		fromDate: undefined,
		toDate: undefined,
		recipient: userEmail,
		embassy: null,
	};

	const handleSelectDate = (date, setFieldTouched) => {
		setFieldTouched("fromDate", false);
		setFieldTouched("toDate", false);
		if (selectedDate === date) return;
		setSelectedDate(date);
	};

	// fetch embassies
	const fetchEmbassies = async () => {
		setIsEmbassiesLoading(true);
		try {
			const response = await API.get(getEmbassies);
			const { status, data } = response || {};
			if (status === 200) setEmbassies(data.data);
		} catch (err) {
			setEmbassies([]);
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		} finally {
			setIsEmbassiesLoading(false);
		}
	};

	// fetch embassy addresses
	const fetchAddresses = async (embassyId) => {
		setIsAddressesLoading(true);
		try {
			const response = await API.get(getEmbassyAddresses(embassyId));
			const { status, data } = response || {};
			if (status === 200) setAddresses(data.data);
		} catch (err) {
			setAddresses({});
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		} finally {
			setIsAddressesLoading(false);
		}
	};

	useEffect(() => {
		if (
			accountStatementType === accountStatementTypeEnum.VISA_APPLICATION
		) {
			setShowDisclaimer(true);
			fetchEmbassies();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Formik
				initialValues={initialValues}
				validationSchema={formValidationSchema}
				onSubmit={(values) => {
					setShowModal(true);
					setPayload({
						...values,
						embassyAddressId: values.embassyAddressId?.value,
						fromDate: formatDate(values.fromDate, "dd/MM/yyyy"),
						toDate: formatDate(values.toDate, "dd/MM/yyyy"),
						embassy: undefined, // not needed for submission
					});
				}}
			>
				{({
					values,
					errors,
					touched,
					handleSubmit,
					setFieldValue,
					handleChange,
					handleBlur,
					setFieldTouched,
				}) => {
					return (
						<form
							className="account-statement__form"
							onSubmit={(e) => {
								e.preventDefault();
								handleSubmit();
							}}
						>
							<div className="ast-past">
								<h2>Request statement from the past</h2>
								<div className="ast-past-wrapper">
									<CustomLargeBadge
										title="30 days"
										isSelected={selectedDate === "30_DAYS"}
										onClick={() => {
											handleSelectDate(
												"30_DAYS",
												setFieldTouched
											);
											setFieldValue(
												"fromDate",
												subDays(new Date(), 30)
											);
											setFieldValue("toDate", new Date());
										}}
									/>
									<CustomLargeBadge
										title="60 days"
										isSelected={selectedDate === "60_DAYS"}
										onClick={() => {
											handleSelectDate(
												"60_DAYS",
												setFieldTouched
											);
											setFieldValue(
												"fromDate",
												subDays(new Date(), 60)
											);
											setFieldValue("toDate", new Date());
										}}
									/>
									<CustomLargeBadge
										title="90 days"
										isSelected={selectedDate === "90_DAYS"}
										onClick={() => {
											handleSelectDate(
												"90_DAYS",
												setFieldTouched
											);
											setFieldValue(
												"fromDate",
												subDays(new Date(), 90)
											);
											setFieldValue("toDate", new Date());
										}}
									/>
								</div>
								<div className="ast-divider">
									<span>or</span>
								</div>

								<label className="mb-0">Custom range</label>
								<div
									className="ast-form-group"
									style={{ marginTop: "8px" }}
								>
									<div className="mr-3">
										<FormDatePickerGroup
											isClearable={false}
											placeholder="From"
											selected={values.fromDate}
											onBlur={handleBlur}
											max={new Date()}
											name="fromDate"
											onChange={(date) => {
												setFieldValue("fromDate", date);
												setSelectedDate("");
											}}
											error={
												touched.fromDate &&
												errors.fromDate
											}
										/>
									</div>
									<div>
										<FormDatePickerGroup
											isClearable={false}
											placeholder="To"
											selected={values.toDate}
											onBlur={handleBlur}
											max={new Date()}
											name="toDate"
											onChange={(date) => {
												setFieldValue("toDate", date);
												setSelectedDate("");
											}}
											error={
												touched.toDate && errors.toDate
											}
										/>
									</div>
								</div>

								<div className="ast-form-group d-block">
									<FormInputGroup
										id="recipient"
										label="Email address"
										name="recipient"
										placeholder="Enter email address"
										onBlur={handleBlur}
										className="ast-input"
										value={values.recipient}
										onChange={handleChange}
										error={
											touched.recipient &&
											errors.recipient
										}
									/>
									<span className="ast-form-info">
										You can get your account statement sent
										to a different email other than the
										email registered with your mintyn
										account using the column above.
									</span>
								</div>

								{accountStatementType ===
									accountStatementTypeEnum.VISA_APPLICATION && (
									<>
										<div className="ast-form-group d-block">
											<label>
												Which embassy are you sending
												this letter to?
											</label>
											<CommonSelect
												name="embassy"
												placeholder="Select Embassy"
												onBlur={handleBlur}
												isLoading={isEmbassiesLoading}
												error={
													touched.embassy &&
													errors.embassy
												}
												handleChange={(embassy) => {
													setFieldValue(
														"embassy",
														embassy
													);
													fetchAddresses(
														embassy.value
													);
												}}
												options={embassies.map(
													(item) => ({
														label: item.embassyName,
														value: item.embassyId,
													})
												)}
												showErrorMsg
											/>
										</div>

										<div className="ast-form-group d-block">
											<label>
												What’s the address of your
												embassy?
											</label>
											<CommonSelect
												name="embassyAddressId"
												placeholder="Select Address"
												onBlur={handleBlur}
												isLoading={isAddressesLoading}
												error={
													touched.embassyAddressId &&
													errors.embassyAddressId
												}
												handleChange={(value) =>
													setFieldValue(
														"embassyAddressId",
														value
													)
												}
												options={embassyAddresses?.map(
													(item) => ({
														label: item.embassyAddress,
														value: item.embassyAddressId,
													})
												)}
												showErrorMsg
											/>
										</div>
									</>
								)}

								<button
									className="btn btn-mint-default ast-submit-btn"
									type="submit"
								>
									Request Statement
								</button>
							</div>
						</form>
					);
				}}
			</Formik>

			<SubmitModal
				show={showModal && !!payload}
				payload={payload}
				onClose={() => {
					setShowModal(false);
					setPayload(null);
				}}
			/>

			{/* show only if accountStatementType is Visa Application */}
			<DisclaimerModal
				show={
					showDisclaimer &&
					accountStatementType ===
						accountStatementTypeEnum.VISA_APPLICATION
				}
				onClose={() => setShowDisclaimer(false)}
				goToPrevStep={goToPrevStep}
			/>
		</>
	);
}
