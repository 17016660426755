import { formatCurrency } from "components/utils/helpers";
import React from "react";
import { useHistory } from "react-router-dom";
import "../../EmergencySavings.scss";

export default function EmergencySaving({ savingGoalData, cls, onClick }) {
	const { name, startDate, savingsBalance, goalId } =
		savingGoalData || {};

	const history = useHistory();

	return (
		<div className={`es-saving-item ${cls ? cls : ""}`} onClick={onClick}>
			<div className="es-saving-item__header">
				<h2>{name}</h2>
				<button
					className="btn gradient"
					onClick={(e) => {
						e.stopPropagation();
						history.push(`/savings/emergencies/details/${goalId}`, {
							savingGoalData,
						});
					}}
				>
					View details
				</button>
			</div>

			<div className="d-flex justify-content-between es-saving-item__info">
				<div>
					<h4>Savings Goal Name</h4>
					<p className="text-capitalize">{name}</p>
				</div>
				<div>
					<h4 className="text-right">Start Date</h4>
					<p>{startDate}</p>
				</div>
			</div>

			<div className="d-flex justify-content-between es-saving-item__info">
				<div>
					<h4>Balance</h4>
					<p>{formatCurrency(savingsBalance)}</p>
				</div>
			</div>
		</div>
	);
}
