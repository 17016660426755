import React, { Component } from "react";
import { connect } from "react-redux";
import DashboardIntro from "./DashboardIntro/DashboardIntro";
import DashboardBalance from "./DashboardBalance/DashboardBalance";
import IDVerificationStatusBanner from "./IDVerificationStatusBanner/IDVerificationStatusBanner";
import QuickActions from "./QuickActions/QuickActions";
import AccessControl from "../../../../../auth/AccessControl/AccessControl";
import permissions from "../../../../../auth/permissions";
import AccountSetupBanner from "./AccountSetupBanner/AccountSetupBanner";

class IntroBalance extends Component {
	render = () => {
		const {
			IS_INDIVIDUAL,
			IS_ENTERPRISE,
			IS_INCORPORATED_TRUSTEE,
			IS_SOLE_PROPRIETORSHIP,
		} = permissions;

		return (
			<div className="mt-5">
				<div className="d-flex justify-content-between flex-wrap">
					<DashboardIntro />
					{/* showing this only for Individual accounts for now */}
					<AccessControl allowedPermissions={[IS_INDIVIDUAL]}>
						<IDVerificationStatusBanner />
					</AccessControl>
					{/*  */}
					<AccessControl
						allowedPermissions={[
							IS_ENTERPRISE,
							IS_INCORPORATED_TRUSTEE,
							IS_SOLE_PROPRIETORSHIP,
						]}
					>
						<AccountSetupBanner />
					</AccessControl>
				</div>
				<div className="row mt-5">
					<DashboardBalance />
					<QuickActions />
				</div>
			</div>
		);
	};
}

export default connect(
	(state) => ({ mydata: state.auth.dashboardResponse }),
	null
)(IntroBalance);
