import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./store";
import App from "./App";

import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "text-security/text-security.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./assets/scss/_index.scss";

import "./assets/css/fonts.css";
import "./assets/css/pace.css";
import "./assets/css/main.css";
import "./assets/css/dashboard.css";
import "./assets/scss/_new_index.scss";

ReactDOM.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<App />
		</PersistGate>
	</Provider>,
	document.getElementById("root")
);
