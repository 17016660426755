import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";
import Axios from "axios";
import Logo from "../../utils/Logo";
import ReactHelmet from "../../utils/ReactHelmet/ReactHelmet";
import PhoneNumberInput from "../../utils/PhoneNumberInput/PhoneNumberInput";
import { SmallLoader } from "../../utils/SmallLoader";
import ValidateForm from "../../utils/Validator";
import Alert from "../../utils/Alert";
import { verifySDCode } from "../../../lib/api/apiRoutesConfig/services/AccountMS/security-detail/subRoutes";
import { API } from "../../../lib/api";
import { getErrorMessage } from "../../utils/helpers";

const AuthTabNav = () => (
    <>
        <ul
            className='nav nav-tabs justify-content-center'
            id='myTab'
            role='tablist'>
            <li className='nav-item w-100'>
                <NavLink
                    to='/reset-security-details'
                    className='w-100 nav-link text-left pl-0'
                    activeClassName='active'
                    exact>
                    Reset Security Details
                </NavLink>
            </li>
        </ul>
    </>
);

class ResetSecurityDetails extends Component {
    signal = Axios.CancelToken.source();
    _isMounted = false;

    state = {
        loading: false,
        alert: {
            alert_newkey: 1,
        },
        request: {
            phoneNumber: "",
            resetCode: "",
        },
        errors: [],
    };

    componentDidMount = () => {
        this._isMounted = true;
    };

    componentWillUnmount = () => {
        this.signal.cancel("canceling network calls");
        this._isMounted = false;
    };

    setError = (errMsg) => {
        this._isMounted &&
            this.setState((prevState) => ({
                loading: false,
                alert: {
                    alert_newkey: prevState.alert.alert_newkey + 1,
                    alert_show: "show",
                    alert_message: errMsg,
                    alert_type: "danger",
                },
            }));
    };

    handleNumberInput = (e) => {
        let { value, name } = e.target;
        value = value.replace(/[^0-9]/g, "");
        this._isMounted &&
            this.setState((prevState) => ({
                ...prevState,
                errors: {
                    ...prevState.errors,
                    [name]: false,
                },
                request: {
                    ...prevState.request,
                    [name]: value,
                },
            }));
    };

    handlePhone = (__, value, countryData) => {
        let Numbers = value.replace(/[^0-9]/g, "");
        value = Numbers.replace(/^(-?)0+/, "");
        this._isMounted &&
            this.setState((prevState) => ({
                ...prevState,
                request: {
                    ...prevState.request,
                    phoneNumber: value,
                },
                errors: {
                    ...prevState.errors,
                    phoneNumber: false,
                },
                dialCode: countryData.dialCode,
            }));
    };

    processRequest = async () => {
        try {
            this.setState({ loading: true });
            const { request, dialCode } = this.state;
            const requestPayload = { ...request };
            requestPayload.phoneNumber = "+" + dialCode + request.phoneNumber;
            const res = await API.post(
                verifySDCode,
                { ...requestPayload },
                {
                    cancelToken: this.signal.token,
                }
            );
            if (res.status === 200) {
                const { sessionId } = res.data.data;
                const redirectUrl = `/reset-security-details/${sessionId}/update`;
                this.props.history.replace(redirectUrl);
            }
        } catch (err) {
            const error = getErrorMessage(err);
            this.setState((prevState) => ({
                request: {
                    ...prevState.request,
                    resetCode: "",
                },
            }));
            this.setError(error);
        }
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const { request } = this.state;
        const { errors, formisValid } = ValidateForm(e, request) || {};
        formisValid ? this.processRequest() : this.setState({ errors });
    };

    render() {
        const {
            loading,
            errors,
            alert,
            request: { phoneNumber, resetCode },
        } = this.state;
        const { alert_show, alert_message, alert_newkey, alert_type } = alert;

        return (
            <>
                <ReactHelmet title='Reset Security Details' />
                <>
                    <div className='row justify-content-center pt-5 mt-5'>
                        <div className='col-xl-4 col-lg-6 col-md-6 col-sm-7 col-10'>
                            <div className='center-logo'>
                                <Link to='/'>
                                    <Logo />
                                </Link>
                            </div>
                            <div>
                                <AuthTabNav />
                                <div
                                    className='tab-content mt-4'
                                    id='myTabContent'>
                                    <p className='form-heading'>
                                        If you don’t have a reset code, please
                                        contact us through any of our support
                                        channels
                                    </p>
                                    <Alert
                                        key={alert_newkey}
                                        type={alert_type}
                                        message={alert_message}
                                        show={alert_show}
                                    />
                                    <br />
                                    <form onSubmit={this.handleSubmit}>
                                        <div className='low-form'>
                                            <div className='form-group'>
                                                <label>Phone Number</label>
                                                <PhoneNumberInput
                                                    inputFieldName='phoneNumber'
                                                    inputValue={phoneNumber}
                                                    handlePhoneNumberChange={
                                                        this.handlePhone
                                                    }
                                                    error={
                                                        errors["phoneNumber"]
                                                    }
                                                    isDisabled={loading}
                                                />
                                            </div>
                                            <div className='form-group'>
                                                <label>Reset Code</label>
                                                <input
                                                    type='text'
                                                    name='resetCode'
                                                    onChange={
                                                        this.handleNumberInput
                                                    }
                                                    value={resetCode}
                                                    autoComplete='off'
                                                    maxLength={6}
                                                    className={
                                                        "form-control passwordType" +
                                                        (errors["resetCode"]
                                                            ? "has-error"
                                                            : "")
                                                    }
                                                />
                                            </div>
                                            <div className='mt-4'>
                                                <button
                                                    className='btn create-acc'
                                                    disabled={loading}>
                                                    {loading ? (
                                                        <SmallLoader />
                                                    ) : (
                                                        "Continue"
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </>
        );
    }
}

export default ResetSecurityDetails;
