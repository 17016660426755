import React, { Component } from "react";
import { ReactSVG } from "react-svg";
import Axios from "axios";
import { ToastsStore } from "react-toasts";
import DatePicker from "react-datepicker";
import imageFiles from "../../../../../../common/imageFiles/imageFiles";
import { API } from "../../../../../../../lib/api";
import {
	formatDateToString,
	getErrorMessage,
	computeDateForDatepicker,
} from "../../../../../../utils/helpers";
import { employmentInformation } from "../../../../../../../lib/api/apiRoutesConfig/services/AccountMS/profile/subRoutes";
import ValidateForm from "../../../../../../utils/Validator";
import { employmentStatuses } from "./data";
import "react-datepicker/dist/react-datepicker.css";

class EmployeeInformation extends Component {
	_isMounted = false;
	signal = Axios.CancelToken.source();

	state = {
		employmentDetail: {
			contactEmail: "",
			contactPhoneNumber: "",
			dateOfEmployment: "",
			employerAddress: "",
			employerName: "",
			employmentStatus: "",
			currentSalary: "",
		},
		editEmploy: false,
		loading: false,
		errors: {},
	};

	componentDidMount = () => {
		this._isMounted = true;
		this.setStateFromProps();
	};

	componentWillUnmount = () => {
		this._isMounted = false;
	};

	setStateFromProps = () => {
		const { employmentDetail } = this.props;
		this._isMounted &&
			this.setState({
				employmentDetail,
				setting: false,
			});
	};

	handleDate = (date, name) => {
		this._isMounted &&
			this.setState((prevState) => ({
				employmentDetail: {
					...prevState.employmentDetail,
					[name]: date,
				},
				errors: {
					...prevState.errors,
					[name]: false,
				},
			}));
	};

	handleInput = (e) => {
		const { name, value } = e.target;
		this._isMounted &&
			this.setState((prevState) => ({
				employmentDetail: {
					...prevState.employmentDetail,
					[name]: value,
				},
				errors: {
					...prevState.errors,
					[name]: false,
				},
			}));
	};

	handleNumberInput = (e) => {
		let { name, value } = e.target;
		value = value.replace(/[^0-9]/g, "");
		this._isMounted &&
			this.setState((prevState) => ({
				employmentDetail: {
					...prevState.employmentDetail,
					[name]: value,
				},
				errors: {
					...prevState.errors,
					[name]: false,
				},
			}));
	};

	handleSelect = (e) => {
		const { name, value } = e.target;
		if (value === "UNEMPlOYED") {
			this._isMounted &&
				this.setState((prevState) => ({
					employmentDetail: {
						[name]: value,
						contactEmail: "",
						contactPhoneNumber: "",
						dateOfEmployment: "",
						employerAddress: "",
						employerName: "",
						currentSalary: 0,
					},
					errors: {},
				}));
		} else {
			this._isMounted &&
				this.setState((prevState) => ({
					employmentDetail: {
						...prevState.employmentDetail,
						[name]: value,
					},
					errors: {
						...prevState.errors,
						[name]: false,
					},
				}));
		}
	};

	updateEmployeeInformation = async () => {
		try {
			const { employmentDetail } = this.state;
			const { dateOfEmployment } = employmentDetail;
			let requestObj = Object.assign({}, employmentDetail);
			// requestObj.dateOfEmployment = formatDateToString(dateOfEmployment);
			requestObj.dateOfEmployment = dateOfEmployment;
			this.setState({ loading: true });
			const res = await API.put(
				employmentInformation,
				{
					...requestObj,
				},
				{
					cancelToken: this.signal.token,
				}
			);

			if (res.status === 200) {
				this.setState((prevState) => ({
					editEmploy: !prevState.editEmploy,
					loading: false,
				}));
				ToastsStore.success(res.data.message, 3000, "right-toast");
			}
		} catch (err) {
			let errorMsg = getErrorMessage(err);
			ToastsStore.error(errorMsg, 3000, "right-toast");
			this.setState((prevState) => ({
				loading: false,
				// editEmploy: !prevState.editEmploy,
			}));
		}
	};

	validateForm = (e) => {
		const { employmentDetail } = this.state;
		const { employmentStatus } = employmentDetail;
		if (employmentStatus === "EMPLOYED") {
			const { contactPhone, currentSalary, ...requiredFields } =
				employmentDetail;
			const { errors, formisValid } =
				ValidateForm(e, requiredFields) || {};
			formisValid
				? this.updateEmployeeInformation()
				: this.setState({ errors });
		} else {
			this.updateEmployeeInformation();
		}
	};

	handleSubmit = (e) => {
		e.preventDefault();
		const { editEmploy } = this.state;
		if (editEmploy === true) {
			this.validateForm(e);
		} else {
			this.setState({ editEmploy: !editEmploy });
		}
	};

	render() {
		const {
			illustrations: { theBagAlert },
		} = imageFiles;
		const { editEmploy, employmentDetail, setting, errors, loading } =
			this.state;
		const {
			employmentStatus,
			dateOfEmployment,
			employerName,
			employerAddress,
			contactPhoneNumber,
			contactEmail,
			currentSalary,
		} = employmentDetail;
		const dateOfEmploymentPickerVal = dateOfEmployment
			? computeDateForDatepicker(dateOfEmployment)
			: "";

		if (setting) return "";
		return (
			<div className="card">
				<div className="card-header" id="headingTwo">
					<h5 className="mb-0">
						<button
							className="btn btn-link collapsed"
							data-toggle="collapse"
							data-target="#collapseTwo"
							aria-expanded="false"
							aria-controls="collapseTwo"
						>
							Employee Information
						</button>
					</h5>
				</div>
				<div
					id="collapseTwo"
					className="collapse"
					aria-labelledby="headingTwo"
					data-parent="#accordion"
				>
					<div className="card-body">
						<div className="row">
							<div className="col-md-2">
								<div className="mx-auto">
									<ReactSVG
										src={theBagAlert}
										className="center-img"
										// alt='bag'
									/>
								</div>
							</div>
							<div className="col-10">
								<div className="dialog-info">
									<form onSubmit={this.handleSubmit}>
										<div className="row">
											<div className="col-md-6">
												<div className="form-group">
													<label>
														Employment status
													</label>
													<select
														type="select"
														name="employmentStatus"
														className={
															"smartSec " +
															(errors[
																"employmentStatus"
															]
																? "has-error"
																: "")
														}
														onChange={
															this.handleSelect
														}
														value={employmentStatus}
														readOnly={!editEmploy}
													>
														{employmentStatuses.map(
															(item, i) => (
																<option
																	key={i}
																	value={
																		item.value
																	}
																>
																	{item.label}
																</option>
															)
														)}
													</select>
												</div>
											</div>
											<div className="col-md-6">
												<div className="form-group">
													<label>
														Date of employment
													</label>
													<DatePicker
														className={
															"w-100 form-control transfew " +
															(errors[
																"dateOfEmployment"
															]
																? "has-error"
																: "")
														}
														value={
															dateOfEmploymentPickerVal
														}
														onChange={(date) =>
															this.handleDate(
																formatDateToString(date),
																"dateOfEmployment"
															)
														}
														maxDate={new Date()}
														readOnly={!editEmploy}
														showYearDropdown
														dateFormatCalendar="MMMM"
														scrollableYearDropdown
														dropdownMode="select"
													/>
													<input
														type="hidden"
														name="dateOfEmployment"
														value={
															dateOfEmployment ===
															null
																? ""
																: dateOfEmployment
														}
													/>
												</div>
											</div>
										</div>

										<div className="row">
											<div className="col-md-12">
												<div className="form-group">
													<label>Employer name</label>
													<input
														name="employerName"
														className={
															"form-control transfew " +
															(errors[
																"employerName"
															]
																? "has-error"
																: "")
														}
														onChange={
															this.handleInput
														}
														readOnly={!editEmploy}
														value={employerName}
													/>
												</div>
											</div>
											<div className="col-md-12">
												<div className="form-group">
													<label>
														Employer address
													</label>
													<input
														name="employerAddress"
														className={
															"form-control transfew " +
															(errors[
																"employerAddress"
															]
																? "has-error"
																: "")
														}
														onChange={
															this.handleInput
														}
														readOnly={!editEmploy}
														maxLength={"60"}
														value={employerAddress}
													/>
												</div>
											</div>
										</div>

										<div className="row">
											<div className="col-md-6">
												<div className="form-group">
													<label>Contact Phone</label>
													<input
														// className='form-control transfew'
														name="contactPhoneNumber"
														className={
															"form-control transfew " +
															(errors[
																"contactPhoneNumber"
															]
																? "has-error"
																: "")
														}
														field-type="phoneNumber"
														onChange={
															this
																.handleNumberInput
														}
														maxLength={11}
														readOnly={!editEmploy}
														value={
															contactPhoneNumber
														}
													/>
													{errors[
														"contactPhoneNumber"
													] && (
														<span className="error">
															{
																errors[
																	"contactPhoneNumber"
																]
															}
														</span>
													)}
												</div>
											</div>
											<div className="col-md-6">
												<div className="form-group">
													<label>Contact Email</label>
													<input
														name="contactEmail"
														type="email"
														className={
															"form-control transfew " +
															(errors[
																"contactEmail"
															]
																? "has-error"
																: "")
														}
														onChange={
															this.handleInput
														}
														readOnly={!editEmploy}
														value={contactEmail}
													/>
													{errors["contactEmail"] && (
														<span className="error">
															{
																errors[
																	"contactEmail"
																]
															}
														</span>
													)}
												</div>
											</div>
											<div className="col-md-6">
												<div className="form-group">
													<label>
														Currently Salary
													</label>
													<input
														name="currentSalary"
														className={
															"form-control transfew " +
															(errors[
																"currentSalary"
															]
																? "has-error"
																: "")
														}
														onChange={
															this
																.handleNumberInput
														}
														readOnly={!editEmploy}
														value={currentSalary}
													/>
												</div>
											</div>
										</div>

										<div className="row justify-content-end mt-2">
											<div className="col-md-6">
												<button
													className="btn edit-dets"
													disabled={loading}
												>
													{loading
														? "Processing..."
														: !editEmploy
														? "Edit"
														: "Update"}
												</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default EmployeeInformation;
