/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import Bill from "./utils/assets/bill.svg";
import { useHistory } from "react-router-dom";
import RequestCard from "./utils/RequestCard";
import { API } from "lib/api";
import { ERROR, FINISHED, IDLE, LOADING, EQUAL } from "config/enums";
import {
	setNextStep,
	setSplitPayment,
	setParticipants,
	setSuccessModal,
} from "actions/splitPayment";
import { useDispatch } from "react-redux";
import { getSplitPayments } from "lib/api/apiRoutesConfig/services/BillsService/subRoutes";
import { ToastsStore } from "react-toasts";
import { MintInfinite } from "components/dashboard/utils/ShineLoader/ShineLoader";
import { getErrorMessage } from "components/utils/helpers";
import FailedTryAgain from "components/dashboard/utils/FailedTryAgain/FailedTryAgain";

const MyRequest = () => {
	const dispatch = useDispatch();
	const [currentStatus, setCurrentStatus] = useState(IDLE);
	const [errorMsg, setErrorMsg] = useState("");
	const [myRequests, setMyrequests] = useState([]);

	const initialFormValues = {
		name: "",
		description: "",
		amount: "",
		creditAccountId: "",
		splitPaymentType: EQUAL,
	};
	const history = useHistory();

	// Get Data details
	const getAllMyrequest = async (value) => {
		setCurrentStatus(LOADING);
		try {
			const res = await API.get(getSplitPayments);
			const { status, data } = res || {};

			if (status === 200) {
				setMyrequests(data.data);
				setCurrentStatus(FINISHED);
			}
		} catch (err) {
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
			setErrorMsg(getErrorMessage(err));
			setCurrentStatus(FINISHED);
		}
	};

	const renderBasedOnStatus = () => {
		switch (currentStatus) {
			case LOADING:
				return (
					<div className="loading-requests">
						<MintInfinite relative />
					</div>
				);

			case ERROR:
				return (
					<div className="d-flex w-100 justify-content-center h-100 item mt-5 align-items-center order-loading">
						<FailedTryAgain
							errorMssg={errorMsg}
							handleTryAgain={() => getAllMyrequest()}
						/>
					</div>
				);

			case FINISHED:
				return (
					<>
						<div className="request-container">
							{myRequests?.length === 0 && <EmptyState />}
							{myRequests?.map((obj, index) => (
								<RequestCard
									onClick={() => {
										history.push(
											"/split-payment/my-requests/manage-requests",
											{
												data: obj,
											}
										);
									}}
									key={index}
									data={obj}
								/>
							))}
						</div>
					</>
				);

			default:
				return null;
		}
	};

	useEffect(() => {
		dispatch(setNextStep(1));
		dispatch(setSplitPayment(initialFormValues));
		dispatch(setParticipants([]));
		dispatch(setSuccessModal(false));
		getAllMyrequest();
	}, []);

	return <>{renderBasedOnStatus()}</>;
};
export default MyRequest;

const EmptyState = () => {
	const history = useHistory();
	return (
		<div className="request-empty-state">
			<span>
				<ReactSVG src={Bill} />
			</span>
			<p className="title">No Request Found!</p>
			<p className="sub-title">The requests you send will appear here</p>
			<button
				onClick={() => {
					history.push(
						"/split-payment/my-requests/start-split-payment"
					);
				}}
				className="btn btn-mint-default"
				type="button"
			>
				Start a Split Payment
			</button>
		</div>
	);
};
