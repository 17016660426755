/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { ReactSVG } from "react-svg";
import { Helmet } from "react-helmet";
import { API } from "lib/api";
import ParticipantsCard from "./utils/ParticipantsCard";
import AddIcon from "./utils/assets/add.svg";
import ParticipantsData from "./utils/ParticipantsData";
import Submitted from "./utils/Submitted";
import { useDispatch, useSelector } from "react-redux";
import { getErrorMessage, formatCurrency } from "components/utils/helpers";
import { SmallLoader } from "components/utils/SmallLoader";
import { CUSTOM, EQUAL, INITIATOR } from "config/enums";
import { postSplitPayment } from "lib/api/apiRoutesConfig/services/BillsService/subRoutes";
import { ToastsStore } from "react-toasts";
import {
	setNextStep,
	setSuccessModal,
	setParticipants,
} from "actions/splitPayment";

const AddParticipants = () => {
	const dispatch = useDispatch();
	const { participants, splitPayment, showSuccessModal } = useSelector(
		(state) => state.splitPayment
	);
	const [showAddParticipantModal, setShowAddParticipantModal] =
		useState(false);
	const [showSubmittedModal, setShowSubmittedModal] =
		useState(showSuccessModal);

	const [isLoading, setIsLoading] = useState(false);

	const [sumAmount, setSumAmount] = useState(0);
	const [subAmount, setSubAmount] = useState(0);
	const [sumAllTotal, setsumAllTotal] = useState(0);

	const sum = () => {
		const subTotal = splitPayment.amount % participants.length;
		const total =
			participants.length > 0
				? Math.floor(splitPayment.amount / participants.length)
				: splitPayment.amount;
		setSubAmount(subTotal);
		setSumAmount(total);
	};

	// Sum all participants total
	const sumAll = () => {
		const total = participants.reduce((a, b) => a + b.amount, 0);
		setsumAllTotal(total);
	};

	// Update participants amount in state
	const updateParticipants = () => {
		const newState = participants.map((obj) => {
			return { ...obj, amount: sumAmount };
		});

		const newInitiator = newState.map((obj) => {
			if (obj?.participantType === INITIATOR) {
				return { ...obj, amount: sumAmount + subAmount };
			}
			return obj;
		});
		dispatch(setParticipants(newInitiator));
	};

	const updateInitiatorCustom = (value) => {
		const newInitiator = participants.map((obj) => {
			if (obj?.participantType === INITIATOR) {
				return { ...obj, amount: value };
			}
			return obj;
		});
		dispatch(setParticipants(newInitiator));
	};

	// Handle Remove Selected
	const handleRemoveSelected = (id) => {
		const list = participants.filter((participant, index) => index !== id);
		dispatch(setParticipants(list));
	};

	// Check if initiator amount is empty
	const checkInitiatorAmount = () => {
		const list = participants.filter((participant, index) => index === 0);
		return list[0].amount;
	};

	// Handle form submit
	const handleSubmit = async () => {
		setIsLoading(true);
		try {
			const res = await API.post(postSplitPayment, {
				...splitPayment,
				participants: participants,
			});
			const { status } = res || {};
			if (status === 200) {
				setShowSubmittedModal(true);
				setIsLoading(false);
				dispatch(setSuccessModal(true));

				// ToastsStore.success(data.message, 6000, "right-toast");
				// history.push(`${defaultPath}/my-requests`);
			}
		} catch (err) {
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
			setIsLoading(false);
		}
	};

	useEffect(() => {
		sum();
		sumAll();
		checkInitiatorAmount();
	}, [splitPayment, participants]);

	useEffect(() => {
		if (splitPayment.splitPaymentType === EQUAL) {
			updateParticipants();
		}
	}, [sumAmount, subAmount]);

	return (
		<>
			<Helmet>
				<title>{process.env.REACT_APP_NAME} - Add Participants</title>
			</Helmet>
			<section className="pb-5 split-payment ">
				<div className="split-rquests-container ">
					<div className="split-rquests-container__header">
						<h2>Add Participants</h2>
					</div>
					<div className="split-rquests-container__content">
						<div className="add-participants">
							<p> {participants.length} Participants</p>
							{participants?.map((item, index) => (
								<ParticipantsCard
									key={index}
									initAmt={splitPayment.amount}
									bal={sumAllTotal}
									setInitiatorCustomAmt={
										updateInitiatorCustom
									}
									splitOption={splitPayment.splitPaymentType}
									isInitiator={
										item?.participantType === INITIATOR
											? true
											: false
									}
									position={
										item?.participantType === INITIATOR
											? "Initiator"
											: "Participant"
									}
									customAmt={item.amount}
									name={item?.accountName}
									amt={
										item?.participantType === INITIATOR
											? sumAmount + subAmount
											: sumAmount
									}
									remove={() => {
										handleRemoveSelected(index);
									}}
								/>
							))}
							{splitPayment.splitPaymentType === CUSTOM && (
								<div className="range-container">
									<p>
										{sumAllTotal > splitPayment.amount
											? "0.00"
											: formatCurrency(sumAllTotal)}
									</p>
									<div className=" range">
										<p
											className="range-inner"
											style={{
												width:
													sumAllTotal >
													splitPayment.amount
														? 0
														: (sumAllTotal /
																splitPayment.amount) *
																100 +
														  "%",
											}}
										></p>
									</div>
									<p>{formatCurrency(splitPayment.amount)}</p>
								</div>
							)}
							{splitPayment.splitPaymentType === CUSTOM &&
								sumAllTotal < splitPayment.amount &&
								checkInitiatorAmount() > 0 && (
									<button
										onClick={() => {
											setShowAddParticipantModal(true);
										}}
										type="button"
										className="add-participants-btn"
									>
										<ReactSVG src={AddIcon} />
										Add Participant
									</button>
								)}{" "}
							{splitPayment.splitPaymentType !== CUSTOM && (
								<button
									onClick={() => {
										setShowAddParticipantModal(true);
									}}
									type="button"
									className="add-participants-btn"
								>
									<ReactSVG src={AddIcon} />
									Add Participant
								</button>
							)}
							{participants.length > 1 &&
								splitPayment.splitPaymentType !== CUSTOM && (
									<button
										onClick={() => {
											handleSubmit();
										}}
										className="btn btn-mint-default"
										type="button"
										disabled={isLoading}
									>
										{isLoading ? (
											<SmallLoader />
										) : (
											"Send Request"
										)}
									</button>
								)}
							{participants.length > 1 &&
								splitPayment.splitPaymentType === CUSTOM &&
								sumAllTotal === splitPayment.amount && (
									<button
										onClick={() => {
											handleSubmit();
										}}
										className="btn btn-mint-default"
										type="button"
										disabled={isLoading}
									>
										{isLoading ? (
											<SmallLoader />
										) : (
											"Send Request"
										)}
									</button>
								)}
						</div>
						<p
							onClick={() => {
								dispatch(setNextStep(1));
								dispatch(setParticipants([]));
							}}
							id="go-back"
						>
							Go Back{" "}
						</p>
					</div>

					<ParticipantsData
						sum={sumAmount}
						bal={splitPayment.amount - sumAllTotal}
						show={showAddParticipantModal}
						onClose={() => setShowAddParticipantModal(false)}
					/>

					<Submitted
						show={showSubmittedModal}
						onClose={() => {
							setShowSubmittedModal(false);
							dispatch(setSuccessModal(true));
						}}
					/>
				</div>
			</section>
		</>
	);
};
export default AddParticipants;
