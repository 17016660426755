import React from "react";
import { Formik } from "formik";
import * as yup from "yup";
import FormInputGroup from "components/utils/FormInputGroup/FormInputGroup";

const formValidationSchema = yup.object().shape({
	goalName: yup
		.string()
		.required("Please enter a goal name")
		.test(
			"len",
			"Goal name cannot be less than 3 characters.",
			(val) => val?.length >= 3
		)
		.test(
			"len",
			"Goal name cannot be more than 25 characters.",
			(val) => val?.length <= 25
		),
});

export default function CreateGoalName({ goalName, goToNextStep }) {
	const initialFormValues = {
		goalName: goalName !== "Others" ? goalName : "",
	};

	const handleSubmitGoalName = ({ goalName }) => {
		goToNextStep(goalName);
	};

	return (
		<div className="create-emergency-savings-page__step shrink">
			<h2>Create Goal name</h2>
			<p>Select a new savings plan to get started</p>

			<Formik
				initialValues={initialFormValues}
				validationSchema={formValidationSchema}
				onSubmit={(values) => handleSubmitGoalName(values)}
			>
				{({
					values,
					errors,
					touched,
					handleSubmit,
					handleChange,
					handleBlur,
				}) => {
					return (
						<form
							onSubmit={(e) => {
								e.preventDefault();
								handleSubmit();
							}}
						>
							<div className="mb-4">
								<FormInputGroup
									id="goalName"
									label="Goal Name"
									name="goalName"
									placeholder="Enter goal name"
									className="cesp-input"
									onBlur={handleBlur}
									value={values.goalName}
									onChange={handleChange}
									error={touched.goalName && errors.goalName}
								/>
								<p className="cesp-form-info">
									This is a description of what you want to
									save for
								</p>
							</div>

							<button
								className="btn create-acc mt-3 text-capitalize"
								type="submit"
								disabled={!values.goalName}
								style={{ height: "50px", borderRadius: "15px" }}
							>
								Proceed
							</button>
						</form>
					);
				}}
			</Formik>
		</div>
	);
}
