import React, { Component } from "react";
import { connect } from "react-redux";
import { ReactSVG } from "react-svg";
import imageFiles from "../../../../../../common/imageFiles/imageFiles";

class BankAcctFunding extends Component {
	state = {
		copied: false,
	};

	copyAcc = () => {
		var copyText = document.getElementById("primaryNumber");
		copyText.select();
		document.execCommand("copy");
		this.setState({ copied: true });
	};

	render = () => {
		const { illustrations: { localBank } = {}, icons: { copyCMD } = {} } =
			imageFiles;

		const { bankAccounts } = this.props;
		const { accountNumber } = bankAccounts ? bankAccounts[0] : {};

		return (
			<div className="col-lg-4 mb-3 mb-lg-0">
				<div className="boxxie">
					<div className="small-talk">
						<ReactSVG src={localBank} title="Local Bank" />
						<h6>Your local bank</h6>
						<p>
							You can use your bank app to transfer,
							<br /> or make a deposit at the branch
						</p>
					</div>
					<div className="row justify-content-center align-items-center">
						<div className="col-12">
							<div className="small-form">
								<label className="text-center">Your Mintyn Account Number</label>
								{this.state.copied ? (
									<div
										className="small-tool fadeIn"
										id="myTooltip"
									>
										Copied
									</div>
								) : (
									""
								)}
								<div className="input-group bordered">
									<div className="form-row w-100 flexxxxx">
										<div className="px-0">
											<input
												type="text"
												className="bordered"
												name="AccountNumber"
												id="primaryNumber"
												value={accountNumber}
												readOnly={true}
											/>
										</div>
										<div className="px-0">
											<button
												className="btn input-group-btn h-100 ml-auto"
												onClick={this.copyAcc}
											>
												<ReactSVG
													src={copyCMD}
													title="Copy Mintyn Account Number"
												/>
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};
}

export default connect(
	({ dashboard }) => ({
		bankAccounts: dashboard.dashboardResponse.bankAccounts,
	}),
	null
)(BankAcctFunding);
