import React from "react";
import "./DeactivateAccountView.scss";
import { Helmet } from "react-helmet";
import FadeIn from "components/dashboard/components/effects/FadeIn";
import { Formik } from "formik";
import * as yup from "yup";
import FormInputGroup from "components/utils/FormInputGroup/FormInputGroup";
import { Label } from "reactstrap";
import PhoneNumberInput from "components/utils/PhoneNumberInput/PhoneNumberInput";
import { ERROR, FINISHED, IDLE, LOADING } from "config/enums";
import { SmallLoader } from "components/utils/SmallLoader";
import { ToastsStore } from "react-toasts";
import { getErrorMessage } from "components/utils/helpers";
import { API } from "lib/api";
import { deactivateAccount } from "lib/api/apiRoutesConfig/services/AccountMS/common/subRoutes";

const formValidationSchema = yup.object().shape({
	email: yup
		.string()
		.required("Email is required")
		.email("Please use a valid email"),
	phoneNumber: yup.string().trim().required("Phone number is required"),
});

export default function DeactivateAccountView() {
	const [currentState, setCurrentState] = React.useState(IDLE);
	const [dialCode, setDialCode] = React.useState("");

	const initialValues = {
		email: "",
		phoneNumber: "",
	};

	const onDeactivateAccount = async (values) => {
		setCurrentState(LOADING);
		try {
			const res = await API.post(deactivateAccount, values);
			if (res.status === 200) {
				setCurrentState(FINISHED);
				ToastsStore.success(res.data.message, 6000, "right-toast");
			}
		} catch (err) {
			setCurrentState(ERROR);
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		}
	};

	return (
		<>
			<Helmet>
				<title>{process.env.REACT_APP_NAME} - Deactivate Account</title>
			</Helmet>

			<FadeIn>
				<div className="deactivate-account-view">
					<h1>Deactivate Account</h1>

					<Formik
						initialValues={initialValues}
						validationSchema={formValidationSchema}
						onSubmit={(values) =>
							onDeactivateAccount({
								email: values.email,
								phoneNumber:
									"+" + dialCode + values.phoneNumber,
							})
						}
					>
						{({
							values,
							errors,
							touched,
							handleSubmit,
							handleChange,
							handleBlur,
							setFieldValue,
							setFieldTouched,
						}) => {
							return (
								<form
									className="deactivate-account-view__form"
									onSubmit={(e) => {
										e.preventDefault();
										handleSubmit();
									}}
								>
									<div className="deactivate-account-form-group">
										<FormInputGroup
											id="email"
											label="Email"
											name="email"
											placeholder="Enter email"
											onBlur={handleBlur}
											className="deactivate-account-input primary"
											value={values.email}
											onChange={handleChange}
											error={
												touched.email && errors.email
											}
										/>
									</div>

									<div className="deactivate-account-form-group">
										<Label for="phoneNumber">
											Phone Number
										</Label>
										<PhoneNumberInput
											placeholder="Enter phone number"
											inputFieldName="phoneNumber"
											inputValue={values.phoneNumber}
											onPhoneNumberBlur={() =>
												setFieldTouched(
													"phoneNumber",
													true
												)
											}
											handlePhoneNumberChange={(
												__,
												value,
												countryData
											) => {
												let Numbers = value.replace(
													/[^0-9]/g,
													""
												);
												value = Numbers.replace(
													/^(-?)0+/,
													""
												);
												setDialCode(
													countryData.dialCode
												);
												setFieldValue(
													"phoneNumber",
													value
												);
											}}
											showErrorMsg
											error={
												touched.phoneNumber &&
												errors.phoneNumber
											}
										/>
									</div>

									<button
										className="btn btn-mint-default"
										type="submit"
										disabled={currentState === LOADING}
									>
										{currentState === LOADING ? (
											<SmallLoader />
										) : (
											"Deactivate Account"
										)}
									</button>
								</form>
							);
						}}
					</Formik>
				</div>
			</FadeIn>
		</>
	);
}
