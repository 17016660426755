import React, { useState, useEffect } from "react";
import { API } from "../../../lib/api";
import { getAllStates } from "../../../lib/api/apiRoutesConfig/services/AccountMS/common/subRoutes";
import FormGroupSelect from "../FormGroupSelect/FormGroupSelect";

const StateSelectBox = ({
	label,
	name,
	isMulti,
	placeholder,
	value,
	error,
	countryCode = "NG",
	onChange,
}) => {
	const [countryStates, setCountryStates] = useState();

	useEffect(() => {
		fetStates();
		// eslint-disable-next-line
	}, []);

	const fetStates = async () => {
		try {
			const response = await API.get(getAllStates(countryCode));

			const { status, data } = response || {};

			if (status === 200) {
				setCountryStates(data.data);
			}
		} catch (error) {
			setCountryStates([]);
		}
	};

	return (
		<FormGroupSelect
			name={name}
			label={label}
			isMulti={isMulti}
			placeholder={placeholder}
			options={countryStates}
			error={error}
			value={value}
			getOptionLabel={({ name }) => name}
			getOptionValue={({ code }) => code}
			onChange={onChange}
		/>
	);
};

export default StateSelectBox;
