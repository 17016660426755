import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TinyLoad } from "../../../../utils/SmallLoader";
import { API } from "../../../../../lib/api";
import { verifyAccountType } from "../../../../../lib/api/apiRoutesConfig/services/AccountMS/signup/subRoutes";
import { updateStepData } from "../../../../../reducers/steps";
import AccountTypeWrapper from "./AccountTypeWrapper/AccountTypeWrapper";
import FadeIn from "../../../../dashboard/components/effects/FadeIn";
import ReactHelmet from "../../../../utils/ReactHelmet/ReactHelmet";
import Logo from "assets/img/new-logo.png";
import { getErrorMessage } from "../../../../utils/helpers";
import { accountTypeList, accountTypes } from "../../enums";
import { basicInfoSteps } from "../../../../../config/enums";
import "./AccountTypeVerification.scss";

const AccountTypeVerification = ({ history }) => {
	const { newAccount } = useSelector(({ auth }) => auth);
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState();

	const handleAccountType = async (accountType) => {
		try {
			setLoading(true);
			setErrorMsg();

			const { sessionId } = newAccount || {};

			const res = await API.put(
				verifyAccountType(sessionId),
				{},
				{
					params: { accountType },
				}
			);

			if (res.status === 200) {
				const { accountType, nextStage } = res.data.data;
				const { ADDRESS, BUSINESS } = basicInfoSteps || {};
				const payload = {
					accountType,
					basicInfoStep:
						accountType === accountTypes.INDIVIDUAL
							? ADDRESS
							: BUSINESS,
				};
				dispatch(updateStepData({ ...payload, nextStage }));
				history.push("/onboard/basic-information");
			}
		} catch (err) {
			setLoading(true);
			setErrorMsg(getErrorMessage(err));
		}
	};

	return (
		<>
			<ReactHelmet title="Account Type Verification" />

			<div className="auth-container">
				<div className="row h-100 justify-content-center ">
					<div className="col-lg-10 col-md-9 col-12">
						<div className="mintyn-logo">
							<a href="https://mintyn.com/">
								<img
									alt="Mintyn Bank"
									width={500}
									height={500}
									src={Logo}
								/>
							</a>
						</div>

						<FadeIn>
							<div className="account-verification-panel">
								<p className="info-heading mb-4 d-flex justify-content-center">
									Which account would you like to open today?{" "}
									{loading && (
										<span className="ml-2">
											<TinyLoad />
										</span>
									)}
								</p>

								{errorMsg && (
									<div className="desct error fade-in mb-3">
										<p>{errorMsg}</p>
									</div>
								)}

								<div className="grid account-options">
									{accountTypeList?.map((account) => (
										<AccountTypeWrapper
											key={account?.title}
											account={account}
											loading={loading}
											handleSubmit={handleAccountType}
										/>
									))}
								</div>
							</div>
						</FadeIn>
					</div>
				</div>
			</div>
		</>
	);
};

export default AccountTypeVerification;
