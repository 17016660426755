import React from "react";
import { connect } from "react-redux";
import FreshChat from "react-freshchat";
import { freshChatToken } from "./config";

const WebChatWidget = ({ currentUser }) => (
	<FreshChat
		token={freshChatToken}
		email={currentUser.email}
		first_name={currentUser.firstName}
		last_name={currentUser.lastName}
		phone={currentUser.phoneNumber}
	/>
);

export default connect(
	({ auth: { currentUser } }) => ({
		currentUser: currentUser,
	}),
	null
)(WebChatWidget);
