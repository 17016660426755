import React from "react";
import { useHistory } from "react-router-dom";

export const ReturnHome = ({ className }) => {
	const history = useHistory();
	return (
		<>
			<p
				onClick={() => history.push("/register-business")}
				className={className}
			>
				Return to Home
			</p>
		</>
	);
};
