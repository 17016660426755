import React from "react";
import { useHistory } from "react-router-dom";
import FailedImage from "../../../../../utils/FailedImage";
import Modal from "../../../../../utils/Modal/Modal";
import FailedTryAgain from "../../../../utils/FailedTryAgain/FailedTryAgain";
import SuccessHeader from "../../../../utils/SuccessHeader/SuccessHeader";
import CollectExistingPin from "../../../CustomerSettings/components/AccountManagement/SpendLimitSetting/TransferLimitModal/AcceptPinInput/CollectExistingPin/CollectExistingPin";
import FundMintAccountModal from "../../../../components/Landing/modals/FundMintAccount/FundMintAccount";

const InvestmentPreviewModal = ({
	showPinModal,
	errorMsg,
	loading,
	showFirst,
	successData,
	toggleModal,
	handleSubmit,
	children,
	closeBtn,
	clearErrorMsg,
}) => {
	const [showFundAccount, setShowFundAccount] = React.useState(false);
	const { push } = useHistory();

	const toggleFundAccount = () => setShowFundAccount((prev) => !prev);

	const close = () => {
		toggleModal();
		push("/investments/details", {
			state: successData?.data,
		});
	};

	const getError = () => {
		if (errorMsg?.includes("you have insufficient"))
			return {
				btnName: "Fund Account",
				action: () => {
					toggleModal();
					toggleFundAccount();
				},
			};
		else
			return {
				btnName: "Close",
				action: errorMsg?.includes("Invalid transaction pin.")
					? clearErrorMsg
					: toggleModal,
			};
	};

	const render = () => {
		const { btnName, action } = getError();

		if (showFirst) return <>{children}</>;

		if (successData)
			return (
				<>
					<SuccessHeader
						cls="no-bg"
						heading="Congratulations!"
						paragraph={successData?.message}
					/>
					<div className="flex__between py-4 px-3">
						{closeBtn ? (
							<button
								type="button"
								className="btn create-acc"
								onClick={toggleModal}
							>
								Done
							</button>
						) : (
							<>
								<button
									type="button"
									className="btn create-acc"
									onClick={close}
								>
									View Investment
								</button>
								<button
									type="button"
									className="btn btn-custom-bordered ml-2"
									onClick={() => push("/investments/active")}
								>
									Cancel
								</button>
							</>
						)}
					</div>
				</>
			);

		if (errorMsg)
			return (
				<div className="row justify-content-center py-5">
					<FailedImage />
					<FailedTryAgain
						errorMssg="Failed"
						paragraph={errorMsg}
						btnName={btnName}
						handleTryAgain={action}
						cls="col-md-10"
					/>
				</div>
			);

		return (
			<CollectExistingPin
				loading={loading}
				toggleModal={toggleModal}
				label="Enter Transaction PIN to confirm"
				confirmTranscation={handleSubmit}
				heading="Confirm Investment"
				btnName="Confirm Transaction"
			/>
		);
	};
	return (
		<>
			{showPinModal && (
				<Modal show={true}>
					<div className="px-4 mt-4">{render()}</div>
				</Modal>
			)}

			{showFundAccount && (
				<FundMintAccountModal
					show={showFundAccount}
					dismissModal={toggleFundAccount}
				/>
			)}
		</>
	);
};

export default InvestmentPreviewModal;
