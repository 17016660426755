/* eslint-disable no-mixed-operators */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import FormInputGroup from "components/utils/FormInputGroup/FormInputGroup";
import { Formik } from "formik";
import * as yup from "yup";
import { SmallLoader } from "components/utils/SmallLoader";
import { Label } from "reactstrap";
import CommonSelect from "components/utils/CommonSelect/CommonSelect";
import { ReactSVG } from "react-svg";
import Hint from "../assets/hint.svg";
import { API } from "lib/api";
import moment from "moment";
import FormDatePickerGroup from "components/utils/FormDatePickerGroup/FormDatePickerGroup";
import { getValidMobileNumberLength } from "components/utils/helpers";
import { postTrusteesDetailsAction } from "lib/api/apiRoutesConfig/services/AccountMS/CAC/subRoutes";
import {
	getAllStates,
	getAllLGAs,
} from "lib/api/apiRoutesConfig/services/AccountMS/common/subRoutes";
import { getErrorMessage } from "components/utils/helpers";
import { ToastsStore } from "react-toasts";
import { Range } from "../utils/range";

const TrusteesDetails = ({ setSteps, regInfo, refresh }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [statesLoading, setStatesLoading] = useState(false);
	const [states, setStates] = useState([]);
	const [lgaLoading, setLgaLoading] = useState(false);
	const [lga, setLga] = useState([]);

	const formValidationSchema = yup.object().shape({
		firstName: yup.string().required("Is required"),

		middleName: yup.string().required("Is required"),

		lastName: yup.string().required("Is required"),

		dateOfBirth:
			regInfo.trusteesAdded === 0
				? yup.string().nullable().required("Is required")
				: yup.string().nullable(),

		emailAddress: yup
			.string()
			.trim()
			.email("*Invalid email")
			.required("*Is required"),

		phoneNumber: yup
			.string()
			.min(11, "Invalid phone number!")
			.required("Is required"),

		state: yup
			.object()
			.nullable()
			.required("Is required")
			.shape({ label: yup.string(), value: yup.string() }),

		lga: yup
			.object()
			.nullable()
			.required("Is required")
			.shape({ label: yup.string(), value: yup.mixed() }),

		residentialAddress: yup.string().required("Is required"),

		gender:
			regInfo.trusteesAdded === 0
				? yup
						.object()
						.nullable()
						.required("Is required")
						.shape({ label: yup.string(), value: yup.string() })
				: yup.string().nullable(),
	});

	// Date
	const priorDate = new Date("January 1, 2003 00:00:00");
	const initialFormValues = {
		firstName: "",
		middleName: "",
		lastName: "",
		dateOfBirth: null,
		gender: null,
		emailAddress: "",
		phoneNumber: "",
		state: null,
		lga: null,
		residentialAddress: "",
		chairman: regInfo.trusteesAdded === 0 ? true : false,
	};

	// Fetch states
	const fetchStates = async () => {
		setStatesLoading(true);
		try {
			const res = await API.get(getAllStates("NG"));
			const { status, data } = res || {};
			if (status === 200) {
				setStates(data.data);
			}
		} catch (err) {
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		} finally {
			setStatesLoading(false);
		}
	};

	// Fetch LGA
	const fetchLGA = async (stateCode) => {
		setLgaLoading(true);
		try {
			const res = await API.get(getAllLGAs(stateCode));
			const { status, data } = res || {};
			if (status === 200) {
				setLga(data.data);
			}
		} catch (err) {
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		} finally {
			setLgaLoading(false);
		}
	};

	// Handle form submit
	const handleSubmit = async (values) => {
		setIsLoading(true);
		const payload =
			regInfo.trusteesAdded === 0
				? {
						...values,
						dateOfBirth: moment(priorDate?.toDateString()).format(
							"DD/MM/YYYY"
						),
						gender: values.gender.value,
						lgaCode: values.lga.value,
						stateCode: values.state.value,
						phoneNumber: "+234" + values.phoneNumber,
				  }
				: {
						...values,
						lgaCode: values.lga.value,
						stateCode: values.state.value,
						phoneNumber: "+234" + values.phoneNumber,
				  };

		try {
			const res = await API.put(
				postTrusteesDetailsAction(regInfo.sessionId),
				payload
			);
			const { status, data } = res || {};
			if (status === 200) {
				refresh(localStorage.getItem("s_id"));
				// setSteps(3);
				ToastsStore.success(data.message, 6000, "right-toast");
			}
		} catch (err) {
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchStates();
	}, []);
	return (
		<>
			<Formik
				initialValues={initialFormValues}
				validationSchema={formValidationSchema}
				onSubmit={(values) => handleSubmit(values)}
			>
				{({
					values,
					errors,
					touched,
					handleSubmit,
					setFieldValue,
					handleChange,
					handleBlur,
				}) => {
					console.log(errors);
					return (
						<form
							className="reg-business-form  row"
							onSubmit={(e) => {
								e.preventDefault();
								handleSubmit();
							}}
						>
							<div className="mb-4 col-12">
								<FormInputGroup
									id="firstName"
									name="firstName"
									label="Firstname"
									className="red-business-input"
									onBlur={handleBlur}
									value={values.firstName}
									onChange={handleChange}
									error={
										touched.firstName && errors.firstName
									}
								/>
							</div>
							<div className="mb-4 col-12">
								<FormInputGroup
									id="middleName"
									name="middleName"
									label="Middlename"
									className="red-business-input"
									onBlur={handleBlur}
									value={values.middleName}
									onChange={handleChange}
									error={
										touched.middleName && errors.middleName
									}
								/>
							</div>
							<div className="mb-4 col-12">
								<FormInputGroup
									id="lastName"
									name="lastName"
									label="Lastname"
									className="red-business-input"
									onBlur={handleBlur}
									value={values.lastName}
									onChange={handleChange}
									error={touched.lastName && errors.lastName}
								/>
							</div>

							<div className="mb-4 col-12">
								<FormInputGroup
									id="residentialAddress"
									name="residentialAddress"
									label="Address"
									className="red-business-input"
									onBlur={handleBlur}
									value={values.residentialAddress}
									onChange={handleChange}
									error={
										touched.residentialAddress &&
										errors.residentialAddress
									}
								/>
							</div>
							<div className="mb-4 col-12">
								<Label for="state">State</Label>
								<CommonSelect
									name="state"
									isSearchable
									placeholder="Select state"
									error={touched.state && errors.state}
									onBlur={handleBlur}
									handleChange={(state) => {
										setFieldValue("state", state);
										fetchLGA(state.value);
									}}
									options={states.map((state) => ({
										label: state?.name,
										value: state?.code,
									}))}
									showErrorMsg
									isLoading={statesLoading}
								/>
							</div>
							<div className="mb-4 col-12">
								<Label for="state">LGA</Label>
								<CommonSelect
									name="lga"
									isSearchable
									placeholder="Select local government"
									error={touched.lga && errors.lga}
									onBlur={handleBlur}
									handleChange={(lga) =>
										setFieldValue("lga", lga)
									}
									options={lga.map((item) => ({
										label: item?.name,
										value: item.code,
									}))}
									showErrorMsg
									isLoading={lgaLoading}
								/>
							</div>

							<div className="mb-4 col-12">
								<FormInputGroup
									id="emailAddress"
									name="emailAddress"
									label="Email Address "
									className="red-business-input"
									onBlur={handleBlur}
									value={values.emailAddress}
									onChange={handleChange}
									error={
										touched.emailAddress &&
										errors.emailAddress
									}
								/>
								{regInfo.trusteesAdded === 0 && (
									<div className="hint">
										<ReactSVG src={Hint} />
										<p>
											This email address would be used for
											the delivery of the registration
											documents
										</p>
									</div>
								)}
							</div>
							<div className="mb-4 col-12">
								<FormInputGroup
									id="phoneNumber"
									label="Phone Number"
									name="phoneNumber"
									placeholder="08023311233"
									className="red-business-input"
									onBlur={handleBlur}
									value={values.phoneNumber}
									onChange={(e) => {
										const value = e.target.value
											.trim()
											.replace(/[^+0-9]/i, "");
										if (
											value.length <=
											getValidMobileNumberLength(value)
										) {
											setFieldValue(e.target.name, value);
										}
									}}
									error={
										touched.phoneNumber &&
										errors.phoneNumber
									}
								/>
							</div>
							<>
								{regInfo.trusteesAdded === 0 && (
									<>
										<div className="mb-4 col-12">
											<FormDatePickerGroup
												label="Date of Birth"
												isClearable={false}
												selected={values.dateOfBirth}
												placeholder="DD/MM/YYYY"
												name="dateOfBirth"
												onBlur={handleBlur}
												onChange={(dateOfBirth) => {
													setFieldValue(
														"dateOfBirth",
														dateOfBirth
													);
												}}
											/>
											{touched.dateOfBirth &&
												errors.dateOfBirth && (
													<span className="error-msg">
														{touched.dateOfBirth &&
															errors.dateOfBirth}
													</span>
												)}
										</div>
										<div className="mb-4 col-12">
											<Label for="state">Gender</Label>
											<CommonSelect
												name="gender"
												placeholder="Select gender"
												error={
													touched.gender &&
													errors.gender
												}
												onBlur={handleBlur}
												handleChange={(gender) => {
													setFieldValue(
														"gender",
														gender
													);
												}}
												options={[
													{
														label: "Male",
														value: "MALE",
													},
													{
														label: "Female",
														value: "FEMALE",
													},
												]}
												showErrorMsg
											/>
										</div>
									</>
								)}
							</>

							<div className="col-12 ">
								<button
									className="btn btn-mint-default"
									type="submit"
									disabled={isLoading}
								>
									{isLoading ? <SmallLoader /> : "Continue"}
								</button>
							</div>
						</form>
					);
				}}
			</Formik>
		</>
	);
};
export default TrusteesDetails;
