import React from "react";
import { ReactSVG } from "react-svg";
import TickIcon from "assets/img/tick_circle_icon.svg";
import CautionIcon from "assets/img/caution_circle_icon.svg";

export default function SetupSuccessModal({
	iconType,
	title,
	message,
	onClick,
	buttonLabel,
}) {
	return (
		<div className="setup-success-modal">
			<ReactSVG src={iconType === "CAUTION" ? CautionIcon : TickIcon} />
			{title && <h2>{title}</h2>}
			<p>{message}</p>
			<button className="btn btn-mint-default" onClick={onClick}>
				{buttonLabel}
			</button>
		</div>
	);
}
