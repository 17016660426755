import React, { Component } from "react";
import imageFiles from "../../../../../../common/imageFiles/imageFiles";
import { ReactSVG } from "react-svg";

const {
    illustrations: { hangIdCard },
} = imageFiles;

class IdentificationInformation extends Component {
    render() {
        const { idCardDetail } = this.props;
        const { idCardType, expiryDate, idCardNumber, imageUrl, verificationStatus } = idCardDetail;
        return (
            <div className='card'>
                <div className='card-header' id='headingThree'>
                    <h5 className='mb-0'>
                        <button
                            className='btn btn-link collapsed'
                            data-toggle='collapse'
                            data-target='#collapseThree'
                            aria-expanded='false'
                            aria-controls='collapseThree'>
                            Identity Information
                        </button>
                    </h5>
                </div>
                <div
                    id='collapseThree'
                    className='collapse'
                    aria-labelledby='headingThree'
                    data-parent='#accordion'>
                    <div className='card-body'>
                        <div className='row'>
                            <div className='col-md-2'>
                                <div className='mx-auto'>
                                    <ReactSVG
                                        src={hangIdCard}
                                        className='center-img'
                                        alt='ID'
                                    />
                                </div>
                            </div>
                            <div className='col-md-10'>
                                <div className='dialog-info'>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label>ID Type</label>
                                                <p>{idCardType}</p>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label>Expiry Date</label>
                                                <p>{expiryDate} </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label>ID number</label>
                                                <p>{idCardNumber}</p>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label>Verification Status</label>
                                                <p>{verificationStatus}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Uploaded ID</label>
                                        <div className='row'>
                                            <div className='col-xl-7'>
                                                <div
                                                    className='upload-btn-wrapper mt-2'>
                                                    <div className='file-container'>
                                                        <img
                                                            alt='valid ID'
                                                            src={imageUrl}
                                                            className='img-responsive contain-img myIdenti'
                                                            style={{
                                                                width: "100%",
                                                                height: "auto",
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default IdentificationInformation;
