import React from "react";
import { employmentStatus } from "../../../config/enums";
import "./EmploymentStatusCheckBox.scss";
import { titleCase } from "title-case";
import { formatEnum } from "../helpers";

const EmploymentStatusCheckBox = ({ name, value, handleChange }) => {
	const handleCheck = (e) => {
		e.persist();
		handleChange(e);
	};

	return (
		<div className="custom-check-box-container">
			<label>Employment Status</label>

			<div
				className="custom-check-box justify-content-between"
				onChange={handleCheck}
			>
				{Object.values(employmentStatus).map((status) => {
					// eslint-disable-next-line array-callback-return
					if (status === employmentStatus.STUDENT) return;
					return (
						<div key={status} className="custom-check-box-input">
							<label>{titleCase(formatEnum(status))}</label>
							<input
								type="radio"
								name={name}
								id={status}
								value={status}
								checked={value === status}
							/>
							<span />
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default EmploymentStatusCheckBox;
