/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unreachable */
import React, { useEffect, useState } from "react";
import BackButton from "components/utils/BackButton/BackButton";
import { useHistory } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { ReturnHome } from "../utils/returnHome";
import Helmet from "react-helmet";
import FadeIn from "components/dashboard/components/effects/FadeIn";
import { API } from "lib/api";
import { ToastsStore } from "react-toasts";
import Terms from "../utils/Terms";
import { getDetailsAction } from "lib/api/apiRoutesConfig/services/AccountMS/CAC/subRoutes";
import PayMintyn from "../utils/payMintyn";
import ConfirmPayment from "../utils/ConfirmPayment";
import { MintInfinite } from "components/dashboard/utils/ShineLoader/ShineLoader";
import FailedTryAgain from "components/dashboard/utils/FailedTryAgain/FailedTryAgain";
import {
	BUSINESS_DETAILS,
	DIRECTOR_DETAILS,
	DIRECTOR_IDENTIFICATION_INFORMATION,
	TERMS_AND_CONDITION,
	PAYMENT,
	COMPLETED,
} from "../utils/exportData";
import { postAcceptTermsAction } from "lib/api/apiRoutesConfig/services/AccountMS/CAC/subRoutes";
import Logo from "./assets/mintyn-logo.svg";
import { Steps } from "../utils/Steps";
import { getErrorMessage } from "components/utils/helpers";
import ProcessingFee from "../utils/ProcessingFee";
import ApplicationSubmited from "../utils/ApplicationSubmited";
import { ERROR, FINISHED, IDLE, LOADING } from "config/enums";
import "./business.scss";
import {
	BuisessFormStep1,
	BuisessFormStep2,
	BusinessFormIdVerify,
} from "./businessForm";
import { useSelector } from "react-redux";
import PublicNavbar from "../components/navbar";

const BusinessName = () => {
	const history = useHistory();
	const [, setIsLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState("");
	const [termsModal, setShowTermsModal] = useState(true);
	const [feeModal, setShowFeeModal] = useState(false);
	const [showPayMintynModal, setShowPayMintynModal] = useState(false);
	const [showConfirmPayModal, setShowConfirmPayModal] = useState(false);
	const [showSubmittedModal, setShowSubmittedModal] = useState(false);
	const [steps, setSteps] = useState(null);
	const [debitAccountId, setDebitAccountId] = useState("");
	const [sid, setSid] = useState("");
	const [currentStatus, setCurrentStatus] = useState(IDLE);

	// const {  } = useSelector(({ auth }) => auth);
	const { theme } = useSelector(({ style }) => style);
	console.log(theme, "THEME");
	const [regInfo, setRegInfo] = useState("");
	// console.log(regInfo)

	// Fetch data
	const fetchRegData = async (value) => {
		setCurrentStatus(LOADING);

		try {
			const res = await API.post(getDetailsAction, {
				phoneNumber: value,
			});
			const { status, data } = res || {};

			if (status === 200) {
				setRegInfo(data.data);
				renderSteps(data.data.nextStage);
				setCurrentStatus(FINISHED);
			}
		} catch (err) {
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
			setErrorMsg(getErrorMessage(err));
			setCurrentStatus(ERROR);
		}
	};

	// Handle terms modal
	const handleTermsModal = async () => {
		setIsLoading(true);

		try {
			const res = await API.put(postAcceptTermsAction(regInfo.sessionId));
			const { status, data } = res || {};
			if (status === 200) {
				setShowTermsModal(false);
				ToastsStore.success(data.message, 6000, "right-toast");
				fetchRegData(localStorage.getItem("s_id"));
				setShowFeeModal(true);
			}
		} catch (err) {
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		} finally {
			setIsLoading(false);
		}
	};
	// Handle fee modal
	const handleFeeModal = () => {
		setShowFeeModal(false);
		setShowPayMintynModal(true);
	};

	const handlePayMintynModal = () => {
		setShowPayMintynModal(false);
		setShowConfirmPayModal(true);
	};
	const handleConfirmModal = () => {
		setShowConfirmPayModal(false);
		setShowSubmittedModal(true);
	};

	// Handle display content
	const renderContent = (value) => {
		switch (value) {
			case BUSINESS_DETAILS:
				return (
					<BuisessFormStep1
						regInfo={regInfo}
						refresh={fetchRegData}
						setSteps={setSteps}
					/>
				);

			case DIRECTOR_DETAILS:
				return (
					<BuisessFormStep2
						regInfo={regInfo}
						refresh={fetchRegData}
						setSteps={setSteps}
					/>
				);
			case DIRECTOR_IDENTIFICATION_INFORMATION:
				return (
					<BusinessFormIdVerify
						regInfo={regInfo}
						refresh={fetchRegData}
						setSteps={setSteps}
						handleFeeModal={handleFeeModal}
						handleTermsModal={() => setShowTermsModal(true)}
					/>
				);

				break;

			case TERMS_AND_CONDITION:
				return (
					<>
						<BusinessFormIdVerify
							regInfo={regInfo}
							refresh={fetchRegData}
						/>
					</>
				);

				break;

			case PAYMENT:
				return (
					<>
						<BusinessFormIdVerify
							regInfo={regInfo}
							refresh={fetchRegData}
						/>
					</>
				);

				break;

			case COMPLETED:
				return (
					<>
						<BusinessFormIdVerify
							regInfo={regInfo}
							refresh={fetchRegData}
						/>
					</>
				);

				break;

			default:
				break;
		}
	};

	const renderSteps = (value) => {
		switch (value) {
			case BUSINESS_DETAILS:
				setSteps(0);
				break;
			case DIRECTOR_DETAILS:
				setSteps(1);
				break;
			case DIRECTOR_IDENTIFICATION_INFORMATION:
				setSteps(1);
				break;
			case TERMS_AND_CONDITION:
				setSteps(2);
				break;
			case PAYMENT:
				setSteps(3);
				break;
			case COMPLETED:
				setSteps(4);
				break;

			default:
				break;
		}
	};

	useEffect(() => {
		if (localStorage.getItem("s_id")) {
			setSid(localStorage.getItem("s_id"));
		}
	}, [steps]);

	useEffect(() => {
		if (sid !== "") {
			fetchRegData(sid);
		}
	}, [sid, steps]);

	const renderBasedOnStatus = () => {
		switch (currentStatus) {
			case LOADING:
				return <MintInfinite relative />;

			case ERROR:
				return (
					<div className="d-flex w-100 justify-content-center mt-5 align-items-center order-loading">
						<FailedTryAgain
							errorMssg={errorMsg}
							handleTryAgain={() => fetchRegData(sid)}
						/>
					</div>
				);

			case FINISHED:
				return (
					<>
						<section className="business-reg-container">
							<div className="top-logo-container">
								{renderContent(regInfo?.nextStage)}
							</div>
						</section>

						<Terms
							show={
								regInfo?.nextStage === TERMS_AND_CONDITION
									? termsModal
									: !termsModal
							}
							nextModal={handleTermsModal}
							onClose={() => setShowTermsModal(false)}
						/>
						<ProcessingFee
							show={
								!showPayMintynModal &&
								!showConfirmPayModal &&
								!showSubmittedModal &&
								regInfo?.nextStage === PAYMENT
									? true
									: false
							}
							regInfo={regInfo}
							businessType={regInfo.businessType}
							nextModal={handleFeeModal}
							onClose={() => setShowFeeModal(false)}
						/>
						<PayMintyn
							show={showPayMintynModal}
							regInfo={regInfo}
							setDebitAccountId={setDebitAccountId}
							refresh={fetchRegData}
							nextModal={handlePayMintynModal}
							onClose={() => setShowPayMintynModal(false)}
						/>
						<ConfirmPayment
							show={showConfirmPayModal}
							regInfo={regInfo}
							debitAccountId={debitAccountId}
							refresh={fetchRegData}
							nextModal={handleConfirmModal}
							onClose={() => setShowConfirmPayModal(false)}
						/>
						<ApplicationSubmited
							show={
								showSubmittedModal ||
								regInfo?.nextStage === COMPLETED
									? true
									: false
							}
							onClose={() => setShowConfirmPayModal(false)}
						/>
					</>
				);

			default:
				return null;
		}
	};

	const [isOpen, setIsOpen] = useState(false);

	return (
		<>
			<Helmet>
				<title>{process.env.REACT_APP_NAME} - Business Name</title>
			</Helmet>
			<PublicNavbar isOpen={isOpen} setIsOpen={setIsOpen}>
				<BackButton
					onClick={
						regInfo?.nextStage === BUSINESS_DETAILS
							? () =>
									history.push(
										"/register-business/business-type"
									)
							: () => setIsOpen(true)
					}
				/>
			</PublicNavbar>
			<FadeIn>{renderBasedOnStatus()}</FadeIn>
		</>
	);
};
export default BusinessName;
