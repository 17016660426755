import React, { useEffect, useState } from "react";
import "../TopUp.scss";
import { Formik, useFormikContext } from "formik";
import * as yup from "yup";
import { MintInfinite } from "components/dashboard/utils/ShineLoader/ShineLoader";
import { API } from "lib/api";
import {
	getActiveCountries,
	getBillerItemsbyVasId,
	getBillersbyCategory,
} from "lib/api/apiRoutesConfig/services/BillsService/subRoutes";
import {
	formatFloatInput,
	getErrorMessage,
	parseAmount,
} from "components/utils/helpers";
import { ToastsStore } from "react-toasts";
import CommonSelect from "components/utils/CommonSelect/CommonSelect";
import CustomCurrencyInput from "components/utils/CustomCurrencyInput/CustomCurrencyInput";
import getSymbolFromCurrency from "currency-symbol-map";
import GlobalTopupSummary from "./GlobalTopupSummary/GlobalTopupSummary";
import PhoneNumberInput from "components/utils/PhoneNumberInput/PhoneNumberInput";

const formValidationSchema = (minAmount, maxAmount, currencySymbol) =>
	yup.object().shape({
		customerId: yup.string().required("Recipient phone number is required"),
		country: yup.object().required("Select a country").nullable().shape({
			label: yup.string(),
			value: yup.mixed(),
		}),
		billMerchant: yup
			.object()
			.required("Select a network operator")
			.nullable()
			.shape({
				label: yup.string(),
				value: yup.mixed(),
			}),
		billMerchantItem: yup.object().nullable().shape({
			label: yup.string(),
			value: yup.mixed(),
		}),
		amount: yup.number().when("billMerchantItem", {
			is: (merchantItem) => merchantItem?.value?.amountType !== "FIXED",
			then: yup
				.number()
				.required("Amount is required")
				.min(
					minAmount,
					`Minimum amount is ${currencySymbol} ${formatFloatInput(
						minAmount.toString()
					)}`
				)
				.max(
					maxAmount,
					`Maximum amount is ${currencySymbol} ${formatFloatInput(
						maxAmount.toString()
					)}`
				),
			otherwise: yup.number(),
		}),
		amountInNaira: yup.number(),
	});

export default function GlobalTopUp() {
	const [isCountryLoading, setIsCountryLoading] = useState(false);
	const [countries, setCountries] = useState([]);
	const [billMerchants, setBillMerchants] = useState([]);
	const [isBillMerchantLoading, setIsBillMerchantLoading] = useState(false);
	const [billMerchantItems, setBillMerchantItems] = useState([]);
	const [isMerchantItemLoading, setIsMerchantItemLoading] = useState(false);
	const [transaction, setTransaction] = useState(null);
	const [isSummaryShown, setIsSummaryShown] = useState(false);
	const [amountConfig, setAmountConfig] = useState({
		currencySymbol: null,
		minAmount: 0,
		maxAmount: 0,
	});

	const [dialCode, setDialCode] = useState("");
	const initialFormValues = {
		customerId: "",
		amount: "",
		country: null,
		billMerchant: null,
		billMerchantItem: null,
		amountInNaira: "",
	};

	const resetAmountConfig = () => {
		setAmountConfig({
			currencySymbol: null,
			minAmount: 0,
			maxAmount: 0,
		});
	};

	// fetch active countries
	const fetchCountries = async () => {
		setIsCountryLoading(true);
		try {
			const response = await API.get(getActiveCountries, {
				params: {
					useType: "GLOBAL_TOPUP",
				},
			});
			const { status, data } = response || {};
			if (status === 200) setCountries(data.data);
		} catch (err) {
			setCountries([]);
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		} finally {
			setIsCountryLoading(false);
		}
	};

	// fetch network operator by country
	const fetchBillMerchants = async (isoCode2) => {
		setIsBillMerchantLoading(true);
		try {
			const response = await API.get(getBillersbyCategory("693"), {
				params: {
					countryIsoCode2: isoCode2,
				},
			});
			const { data, status } = response || {};
			if (status === 200) setBillMerchants(data.data);
		} catch (err) {
			setBillMerchants([]);
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		} finally {
			setIsBillMerchantLoading(false);
		}
	};

	// fetch bill merchant items by network selected
	const fetchBillMerchantItems = async (vasBillerId) => {
		setIsMerchantItemLoading(true);
		try {
			const response = await API.get(getBillerItemsbyVasId(vasBillerId));
			const { data, status } = response || {};
			// console.log(data.data);
			if (status === 200) setBillMerchantItems(data.data);
		} catch (err) {
			setBillMerchantItems([]);
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		} finally {
			setIsMerchantItemLoading(false);
		}
	};

	useEffect(() => {
		fetchCountries();
	}, []);

	if (isCountryLoading) return <MintInfinite relative />;

	return (
		<div>
			<Formik
				enableReinitialize
				initialValues={initialFormValues}
				validationSchema={formValidationSchema(
					amountConfig.minAmount,
					amountConfig.maxAmount,
					amountConfig.currencySymbol
				)}
				onSubmit={(values) => {
					setTransaction({
						...values,
						vasBillerId:
							values.billMerchantItem?.value.vasItemId ||
							billMerchantItems[0].vasItemId,
						biller: values.billMerchant.value.name,
						itemCode:
							values.billMerchantItem?.value.itemCode ||
							billMerchantItems[0].itemCode,
						country: values.country?.value.isoCode2,
						customerId: "+" + dialCode + values.customerId,
					});
					setIsSummaryShown(true);
				}}
			>
				{({
					values,
					errors,
					touched,
					handleSubmit,
					setFieldValue,
					handleChange,
					handleBlur,
					setFieldTouched,
				}) => {
					// form
					return (
						<>
							<form
								className="top-up-form"
								onSubmit={(e) => {
									e.preventDefault();
									handleSubmit();
								}}
							>
								<div className="mb-4">
									<label>Phone Number</label>
									<div className="row">
										<div className="col-12 col-md-4 mb-4 mb-md-0">
											<CommonSelect
												name="country"
												value={values.country}
												placeholder="Select country"
												isLoading={isCountryLoading}
												onBlur={handleBlur}
												error={
													touched.country &&
													errors.country
												}
												handleChange={(country) => {
													setFieldValue(
														"country",
														country
													);
													fetchBillMerchants(
														country.value.isoCode2
													);
													// reset every field
													resetAmountConfig();
													setDialCode("");
													setFieldValue(
														"customerId",
														""
													);
													setBillMerchants([]);
													setBillMerchantItems([]);
													setFieldValue(
														"billMerchant",
														null
													);
													setFieldValue(
														"billMerchantItem",
														null
													);
													setFieldValue("amount", "");
													setFieldValue(
														"amountInNaira",
														""
													);
												}}
												options={countries.map(
													(item) => ({
														label: item.name,
														value: item,
													})
												)}
												formatOptionLabel={(
													option,
													{ context }
												) => {
													return context === "menu"
														? option.label
														: option.value.isoCode2;
												}}
												showErrorMsg
											/>
										</div>

										<div className="col-12 col-md-8">
											<PhoneNumberInput
												isDisabled={
													!values.country?.value
												}
												inputFieldName="customerId"
												placeholder="Enter Phone Number"
												inputValue={values.customerId}
												onPhoneNumberBlur={() =>
													setFieldTouched(
														"customerId",
														true
													)
												}
												showErrorMsg
												preferredCountries={
													values.country?.value
														? [
																values.country
																	.value
																	.isoCode2,
														  ]
														: ["US"]
												}
												onlyCountries={
													values.country?.value
														? [
																values.country
																	.value
																	.isoCode2,
														  ]
														: ["US"]
												}
												error={
													touched.customerId &&
													errors.customerId
												}
												handlePhoneNumberChange={(
													__,
													value,
													countryData
												) => {
													let Numbers = value.replace(
														/[^0-9]/g,
														""
													);
													value = Numbers.replace(
														/^(-?)0+/,
														""
													);
													setDialCode(
														countryData.dialCode
													);
													setFieldValue(
														"customerId",
														value
													);
												}}
											/>
										</div>
									</div>
								</div>

								<div className="mb-4">
									<label htmlFor="billMerchant">
										Network Operator
									</label>
									<CommonSelect
										menuPortalTarget={null}
										name="billMerchant"
										value={values.billMerchant}
										placeholder="Select a network operator"
										isLoading={isBillMerchantLoading}
										onBlur={handleBlur}
										error={
											touched.billMerchant &&
											errors.billMerchant
										}
										handleChange={(merchant) => {
											setFieldValue(
												"billMerchant",
												merchant
											);
											fetchBillMerchantItems(
												merchant.value.vasBillerId
											);
											// reset fields
											resetAmountConfig();
											setFieldValue(
												"billMerchantItem",
												null
											);
											setFieldValue("amount", "");
											setFieldValue("amountInNaira", "");
										}}
										options={billMerchants.map((item) => ({
											label: item.name,
											value: item,
										}))}
										showErrorMsg
									/>
								</div>

								{billMerchantItems.length > 1 && (
									<div className="mb-4">
										<label htmlFor="billMerchantItem">
											Product
										</label>
										<CommonSelect
											menuPortalTarget={null}
											name="billMerchantItem"
											value={values.billMerchantItem}
											placeholder="Select a product"
											isLoading={isMerchantItemLoading}
											onBlur={handleBlur}
											error={
												touched.billMerchantItem &&
												errors.billMerchantItem
											}
											handleChange={(merchantItem) => {
												setFieldValue(
													"billMerchantItem",
													merchantItem
												);
												setFieldValue(
													"amount",
													merchantItem.value.amount
												);
											}}
											options={[...billMerchantItems]
												.sort(
													(a, b) =>
														a.amount - b.amount
												)
												.map((item) => ({
													label: item.name,
													value: item,
												}))}
											showErrorMsg
										/>
									</div>
								)}

								<div className="mb-4">
									<CustomCurrencyInput
										className="topup-input"
										name="amount"
										label="Amount"
										id="amount"
										value={values.amount}
										placeholder="Enter Amount"
										onBlur={handleBlur}
										currency={amountConfig.currencySymbol}
										// inputPaddingLeft="40px"
										disabled={
											values.billMerchantItem?.value
												?.amountType === "FIXED" ||
											billMerchantItems.length === 0
										}
										error={touched.amount && errors.amount}
										onChange={(value) => {
											setFieldValue(
												"amount",
												parseAmount(value)
											);
										}}
									/>
									<p className="topup-global-amount-info">
										Top up amount ranges:{" "}
										<span>
											Min of {amountConfig.currencySymbol}{" "}
											{formatFloatInput(
												amountConfig.minAmount.toString()
											)}{" "}
											- Max of{" "}
											{amountConfig.currencySymbol}{" "}
											{formatFloatInput(
												amountConfig.maxAmount.toString()
											)}
										</span>
									</p>
								</div>

								<div className="mb-4">
									<CustomCurrencyInput
										className="topup-input"
										label="Amount in Naira"
										name="amountInNaira"
										id="amountInNaira"
										value={values.amountInNaira}
										disabled
									/>
								</div>

								<button
									className="btn btn-mint-default mt-2"
									type="submit"
									disabled={
										countries.length === 0 ||
										billMerchantItems.length === 0
									}
								>
									Continue
								</button>
							</form>

							<FormikObserver
								amountConfig={amountConfig}
								setAmountConfig={setAmountConfig}
								billMerchantItems={billMerchantItems}
							/>
						</>
					);
				}}
			</Formik>

			<GlobalTopupSummary
				show={isSummaryShown && !!transaction}
				transaction={transaction}
				onClose={() => {
					setIsSummaryShown(false);
					setTransaction(null);
				}}
			/>
		</div>
	);
}

// Formik observer for setting and updating amountConfig
const FormikObserver = ({
	amountConfig,
	setAmountConfig,
	billMerchantItems,
}) => {
	const { values, setFieldValue } = useFormikContext();

	useEffect(() => {
		if (values.billMerchantItem?.value || billMerchantItems.length > 0) {
			const {
				currencyCode,
				minAmount,
				maxAmount,
				rate: merchantRate,
			} = values.billMerchantItem?.value || billMerchantItems[0] || {};

			setAmountConfig({
				...amountConfig,
				currencySymbol: getSymbolFromCurrency(currencyCode) || "",
				minAmount: minAmount || 0,
				maxAmount: maxAmount || 0,
			});

			if (values.amount) {
				const parsedAmount = parseAmount(values.amount.toString());
				setFieldValue("amountInNaira", merchantRate * parsedAmount);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values, billMerchantItems]);

	return null;
};
