import React from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const checkPermissions = (userPermissions, allowedPermissions = []) => {
	return allowedPermissions.some((allowedPermission) =>
		userPermissions.includes(allowedPermission)
	);
};

const AccessControlRoute = ({
	path,
	exact,
	component,
	userPermissions,
	allowedPermissions,
}) => {
	if (checkPermissions(userPermissions, allowedPermissions)) {
		return <Route exact={exact} path={path} component={component} />;
	}

	return (
		// <div className="py-5 text-center">
		// 	<h4>You don't have right permission to access this page</h4>
		// </div>
		<Redirect to="/dashboard" />
	);
};

AccessControlRoute.propTypes = {
	userPermissions: PropTypes.array.isRequired,
};

export default connect(
	(state) => ({ userPermissions: state.auth.userPermissions }),
	null
)(AccessControlRoute);
