import React from 'react';
import TableCell from '../CustomTableCell/CustomTableCell';

const TableHead = ({ headers = [] }) => {
    return (
        <thead>
            <tr>
                {headers.map((content, i) => (
                    <TableCell key={i} content={content} HTMLTag="th" />
                ))}
            </tr>
        </thead>
    );
};

export default TableHead;
