import BusinessType from "../BusinessType";
import Start from "../Home";
import BusinessName from "../businessName/businessName";
import CompanyName from "../companyName/companyName";
import IncorporatedTrustee from "../incorporatedTrustee/incorporatedTrustee";

export default [
	{
		title: "Home",
		exact: true,
		path: "/",
		component: Start,
	},
	{
		title: "Business Type",
		path: "/business-type",
		component: BusinessType,
	},

	{
		title: "Business Name Reg",
		path: "/business-name",
		component: BusinessName,
	},

	{
		title: "Company Name Reg",
		path: "/company-name",
		component: CompanyName,
	},

	{
		title: "Company Name Reg",
		path: "/incorporated-trustee",
		component: IncorporatedTrustee,
	},
];
