import { updateCart } from "actions/marketPlace";
import { getErrorMessage } from "components/utils/helpers";
import { ERROR, FINISHED, IDLE, LOADING } from "config/enums";
import { API } from "lib/api";
import { performCartActions } from "lib/api/apiRoutesConfig/services/tradeMS/individualTrade/subRoutes";
import { useState } from "react";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { ToastsStore } from "react-toasts";

export default function useFetchCart() {
	const [currentStatus, setCurrentStatus] = useState(IDLE);
	const [errorMsg, setErrorMsg] = useState("");
	const dispatch = useDispatch();

	const handleFetchCart = useCallback(async (cartType) => {
		setCurrentStatus(LOADING);
		setErrorMsg("");
		try {
			const response = await API.get(performCartActions, {
				params: {
					cartType,
				},
			});
			const { status, data } = response || {};
			if (status === 200) {
				dispatch(updateCart(data.data));
				setCurrentStatus(FINISHED);
			}
		} catch (err) {
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
			setErrorMsg(getErrorMessage(err));
			setCurrentStatus(ERROR);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return { currentStatus, errorMsg, handleFetchCart };
}
