import ProfileSettings from "../components/ProfileSettings/ProfileSettings";
import AccountManagement from "../components/AccountManagement/AccountManagement";
import MintAccountLegal from "../components/MintAccountLegal/MintAccountLegal";
import FaqSettings from "../components/FaqSettings/FaqSettings";

export const defaultPath = "/settings";

export default [
    {
        iconName: "user-icon",
        title: "Profile",
        path: `${defaultPath}/profile`,
        component: ProfileSettings,
    },
    {
        iconName: "credit-icon",
        title: "Account Management",
        path: `${defaultPath}/account-management`,
        component: AccountManagement,
    },
    {
        iconName: "smartshield",
        title: "Legal",
        path: `${defaultPath}/legal`,
        component: MintAccountLegal,
    },
    {
        iconName: "bigedit",
        title: "Help & Support",
        path: `${defaultPath}/help`,
        component: FaqSettings,
    },
];
