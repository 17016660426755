import React from "react";
import "./ActivationSuccessModal.scss";
import Modal from "components/utils/Modal/Modal";
import TickCircle from "assets/img/dark-tick-circle.svg";
import { ReactSVG } from "react-svg";

export default function ActivationSuccessModal({ show }) {
	return (
		<Modal show={show}>
			<div className="ncto-activation-success-modal">
				<ReactSVG
					src={TickCircle}
					className="ncto-activation-success-modal__success-icon"
				/>
				<h2>Activation Successful</h2>
				<p>
					Congratulations! Your card activation has been successful.
				</p>
				<button
					className="btn btn-mint-default"
					onClick={() =>
						(window.location.href = "https://mintyn.com/")
					}
				>
					Done
				</button>
			</div>
		</Modal>
	);
}
