import axios from "axios";
import { refreshTokenEndpoint } from "./apiRoutesConfig/services/AuthenticationService/oauth/subRoutes";
import store from "../../store";
import config from "../../config";
import { kickCustomerOut } from "../../actions/loginActions";
import {
	MINTYN_USER_REFRESH_TOKEN,
	MINTYN_USER_TOKEN,
} from "components/constants";
import Cookies from "js-cookie";
import { cookieOptions } from "components/utils/helpers";

// const CORSResolve = (url) => `https://cors-anywhere.herokuapp.com/${url}`;
export const httpSignal = axios.CancelToken.source();
const CancelToken = axios.CancelToken;
let requestSignal;

const { BASE_URL, CLIENT_KEY } = config.apiGateway;
const API = axios.create({
	baseURL: BASE_URL,
});

API.interceptors.request.use(
	(config) => {
		const accessToken = Cookies.get(MINTYN_USER_TOKEN);
		config.headers["x-request-client-key"] = CLIENT_KEY;
		accessToken &&
			(config.headers["Authorization"] = "Bearer " + accessToken);
		requestSignal = CancelToken.source();
		config.cancelToken = requestSignal.token;
		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);

API.interceptors.response.use(
	async (response) => {
		return response;
	},
	async (error) => {
		const status = error.response.status;
		const originalRequest = error.config;
		const { baseURL, url } = originalRequest;
		const requestPATH = url.replace(baseURL, "");
		if (status === 401 && requestPATH === refreshTokenEndpoint) {
			sessionStorage.clear();
			return Promise.reject(error);
		}

		if (status === 401 && !originalRequest._retry) {
			const { userId } = store.getState().auth.currentUser;
			originalRequest._retry = true;
			const refreshToken = Cookies.get(MINTYN_USER_REFRESH_TOKEN);
			return API.post(refreshTokenEndpoint, {
				userId,
				refreshToken,
			})
				.then((res) => {
					if (res.status === 200) {
						Cookies.set(
							MINTYN_USER_TOKEN,
							res.data.data.token,
							cookieOptions
						);
						return API(originalRequest);
					}
				})
				.catch((err) => {
					const refreshTokenErrStatus = err.response.status;
					if (refreshTokenErrStatus === 401) {
						sessionStorage.clear();
						store.dispatch(
							kickCustomerOut("Your Session has been compromised")
						);
						return Promise.reject(error);
					}
				});
		}

		return Promise.reject(error);
	}
);

export { API, requestSignal };
