import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import EyeOpenIcon from "../../../assets/img/eye-open-v2.svg";
import EyeCloseIcon from "../../../assets/img/eye-close-v2.svg";

export default function FormInputGroupV2({
	className,
	label,
	name,
	onChange,
	value,
	placeholder,
	inputClassName,
	error,
	type,
	...props
}) {
	const [view, setView] = useState(false);
	return (
		<>
			{type === "password" ? (
				<>
					<div className="password-field-container">
						{label && (
							<label htmlFor={props.id ? props.id : name}>
								{label}
							</label>
						)}

						<section className="password-container">
							<input
								name={name}
								placeholder={placeholder}
								id={props.id ? props.id : name}
								onChange={onChange}
								value={value}
								type={view ? "text" : "password"}
								className={error ? "invalid" : ""}
								{...props}
							/>
							{type === "password" && (
								<button
									type="button"
									onClick={() => setView(!view)}
								>
									<ReactSVG
										src={view ? EyeCloseIcon : EyeOpenIcon}
									/>
								</button>
							)}
						</section>

						{error && error.length > 0 && (
							<span className="error-msg">{error}</span>
						)}
					</div>
				</>
			) : (
				<>
					<div className="input-field-container">
						{label && (
							<label htmlFor={props.id ? props.id : name}>
								{label}
							</label>
						)}

						<input
							name={name}
							placeholder={placeholder}
							id={props.id ? props.id : name}
							onChange={onChange}
							value={value}
							className={error ? "invalid" : ""}
							{...props}
						/>

						{error && error.length > 0 && (
							<span className="error-msg">{error}</span>
						)}
					</div>
				</>
			)}
		</>
	);
}
