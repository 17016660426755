import PropTypes from "prop-types";
import React, { Component } from "react";
import Select from "react-select";
import { API } from "../../../../../lib/api";
import { getAllBanksEndpoint } from "../../../../../lib/api/apiRoutesConfig/services/FundsManagementMS/common/subRoutes";
import { getAllBanksList as accountServiceBankList } from "../../../../../lib/api/apiRoutesConfig/services/AccountMS/common/subRoutes";
import { SelectBoxV2 } from "components/styles/SelectBoxV2";

class BankInputSelectV2 extends Component {
	state = {
		isLoading: false,
		banksList: [],
	};

	componentDidMount = () => {
		this.getBankCodes();
	};

	getBankCodes = async () => {
		try {
			this.setState({ isLoading: false });
			const { isOnboardingInput } = this.props;
			let URL = isOnboardingInput
				? accountServiceBankList
				: getAllBanksEndpoint;
			let res = await API.get(URL);
			if (res.status === 200) {
				let banksList = [];
				res.data.data.map((item) =>
					banksList.push({
						label: item.name,
						value: item.code,
						...item,
					})
				);
				this.setState({ banksList: banksList });
			}
		} catch (err) {
			this.setState({ banksList: [] });
		}
	};

	render = () => {
		const { error, inputName, selectedBank, handleSelect } = this.props;
		const { banksList, isLoading } = this.state;

		return (
			<div className="select-field-container">
				<label htmlFor={inputName}>Choose Bank</label>
				<Select
					name={inputName}
					inputId={inputName}
					instanceId={inputName}
					value={selectedBank}
					onChange={handleSelect}
					isSearchable={true}
					isLoading={isLoading}
					classNamePrefix="react-select"
					styles={SelectBoxV2(error)}
					options={banksList}
					placeholder="Select bank"
					cacheOptions
					defaultOptions
					blurInputOnSelect={false}
				/>
				{/* {error && <span className="error-msg">{error}</span>} */}
			</div>
		);
	};
}

BankInputSelectV2.propTypes = {
	error: PropTypes.any,
	handleSelect: PropTypes.any,
	inputName: PropTypes.string.isRequired,
};

export default BankInputSelectV2;
