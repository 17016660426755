import ResetSecurityDetails from "../../ResetSecurityDetails/ResetSecurityDetails";
import UpdateSecurityDetails from "../../ResetSecurityDetails/UpdateSecurityDetails/UpdateSecurityDetails";

export default [
	{
		title: "Reset Security Details",
		path: "",
		component: ResetSecurityDetails,
		exact: true
	},
	{
		title: "Update Security Details",
		path: "/:sessionId/update",
		component: UpdateSecurityDetails,
		exact: true
	},
];
