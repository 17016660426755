import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { showVerify } from "../../../../../../actions/registrationActions";
import { SmallLoader } from "../../../../../utils/SmallLoader";
import Alert from "../../../../../utils/Alert";
import { formatDateToString } from "../../../../../utils/helpers";
import ValidateForm from "../../../../../utils/Validator";
import { API } from "../../../../../../lib/api";
import { getErrorMessage } from "../../../../../utils/helpers";
import { verifyBankAccountDetails } from "../../../../../../lib/api/apiRoutesConfig/services/AccountMS/signup/subRoutes";
import ReactHelmet from "../../../../../utils/ReactHelmet/ReactHelmet";
import FadeIn from "../../../../../dashboard/components/effects/FadeIn";
import Logo from "assets/img/new-logo.png";
import BankInputSelectV2 from "components/dashboard/components/FundsTransfer/BankInputSelect/BankInputSelectV2";
import FormInputGroupV2 from "components/utils/FormInputGroup/FormInputGroupV2";
import FormDatePickerGroupV2 from "components/utils/FormDatePickerGroup/FormDatePickerGroupV2";

class BankAccount extends Component {
	_isMounted = false;

	state = {
		request: {
			accountType: "INDIVIDUAL",
			bankCode: "",
			accountNumber: "",
			dateOfBirth: null,
		},
		errors: {},
		loading: false,
		alert: { alert_newkey: 1 },
	};

	componentDidMount = () => {
		this._isMounted = true;
	};

	componentWillUnmount = () => {
		this._isMounted = false;
	};

	setError = (err) => {
		this._isMounted &&
			this.setState((prevState) => ({
				loading: false,
				alert: {
					alert_newkey: prevState.alert.alert_newkey + 1,
					alert_show: "show",
					alert_message: getErrorMessage(err),
					alert_type: "danger",
				},
			}));
	};

	handleAccountNumber = (e) => {
		let { name, value } = e.target;
		value = value.replace(/[^0-9]/g, "");
		this.setState((prevState) => ({
			...prevState,
			request: {
				...prevState.request,
				[name]: value,
			},
			errors: {
				...prevState.errors,
				[name]: false,
			},
		}));
	};

	handleField = (name, value) => {
		this.setState((prevState) => ({
			...prevState,
			request: {
				...prevState.request,
				[name]: value,
			},
			errors: {
				...prevState.errors,
				[name]: false,
			},
		}));
	};

	initateRegistration = async () => {
		try {
			this.setState({ loading: true });

			const { request } = this.state;
			let { phoneNumber } = this.props.userInfo;

			const res = await API.post(verifyBankAccountDetails, {
				...request,
				bankCode: request.bankCode.code,
				phoneNumber: "+" + phoneNumber,
				dateOfBirth: formatDateToString(request?.dateOfBirth),
				uniqueDeviceId: window.navigator.userAgent,
			});
			if (res.status === 200) {
				this.setState({ loading: false });
				const data = res.data.data;
				const { history, showVerify } = this.props;
				// clearStage();
				showVerify({ ...data });
				history.replace({ pathname: "/onboard/confirm-OTP" });
			}
		} catch (err) {
			this.setError(err);
		}
	};

	handleSubmit = (e) => {
		e.preventDefault();
		const { request } = this.state;
		const { errors, formisValid } = ValidateForm(e, request) || {};
		formisValid ? this.initateRegistration() : this.setState({ errors });
	};

	render() {
		const { errors, loading, alert, request } = this.state;
		const { alert_show, alert_message, alert_newkey, alert_type } = alert;
		const { bankCode, accountNumber, dateOfBirth } = request;
		const {
			history,
			userInfo: { phoneNumber },
		} = this.props;
		phoneNumber === "" && history.push("/sign-up");

		return (
			<>
				<ReactHelmet title="Signup with bank account" />
				<div className="auth-container">
					<div className="row h-100 justify-content-center ">
						<div className="col-xl-6 col-lg-8 col-md-9">
							<div className="mintyn-logo">
								<a href="https://mintyn.com/">
									<img
										alt="Mintyn Bank"
										width={500}
										height={500}
										src={Logo}
									/>
								</a>
							</div>
							<section className="account-verification-panel centered">
								<FadeIn>
									<div className="row justify-content-center">
										<div className="col-xl-9 col-lg-9 col-md-9">
											<div className="">
												<p className="info-heading">
													Your bank account will not
													be linked to your Mintyn
													account. This is an
													alternative signup option.
												</p>

												<Alert
													key={alert_newkey}
													type={alert_type}
													message={alert_message}
													show={alert_show}
												/>
												<br />

												<form
													className="text-left login-form"
													onSubmit={this.handleSubmit}
												>
													<BankInputSelectV2
														inputName="bankCode"
														handleSelect={(value) =>
															this.handleField(
																"bankCode",
																value
															)
														}
														selectedBank={bankCode}
														error={
															!!errors["bankCode"]
														}
														isOnboardingInput
													/>

													<FormInputGroupV2
														name="accountNumber"
														label="Account Number"
														value={accountNumber}
														error={
															!!errors[
																"accountNumber"
															]
														}
														max={10}
														min={10}
														onChange={
															this
																.handleAccountNumber
														}
													/>

													<FormDatePickerGroupV2
														label="Date Of Birth"
														selected={dateOfBirth}
														name="dateOfBirth"
														error={
															!!errors[
																"dateOfBirth"
															]
														}
														max={new Date()}
														onChange={(date) =>
															this.handleField(
																"dateOfBirth",
																date
															)
														}
													/>

													<button
														className="btn-primary w-100"
														disabled={loading}
													>
														{loading ? (
															<SmallLoader />
														) : (
															"Continue"
														)}
													</button>

													<div className="form-footer">
														<Link to="/onboard/with-bvn">
															Use BVN instead?
															click here
														</Link>
													</div>
												</form>
											</div>
										</div>
									</div>
								</FadeIn>
							</section>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const ExisitingBankAccountVerification = withRouter(BankAccount);
export default connect(
	(state) => ({ userInfo: state.steps.stepData.personalDetails }),
	{ showVerify }
)(ExisitingBankAccountVerification);
