import profileSettings from "../";
export const {
    createPinEndpoint,
    resetPinEndpoint,
    createSecurityQuestion,
    notificationPreference,
    inTraBankTransLimit,
    inTerBankTransLimit,
    changePasswordRoute
} = {
    createPinEndpoint: `${profileSettings}/transaction-pin`,
    resetPinEndpoint: `${profileSettings}/transaction-pin/reset`,
    createSecurityQuestion: `${profileSettings}/security-question`,
    notificationPreference: `${profileSettings}/notification-preference`,
    inTraBankTransLimit: `${profileSettings}/transaction-limit/intra-bank`,
    inTerBankTransLimit: `${profileSettings}/transaction-limit/inter-bank`,
    changePasswordRoute: `${profileSettings}/password`
};
