import React from "react"
import "./CartView.scss"
import { Helmet } from "react-helmet"
import FadeIn from "components/dashboard/components/effects/FadeIn"
import { useHistory,useLocation } from "react-router-dom";
import BackButton from "components/utils/BackButton/BackButton";
import MemberCard from "../../utils/MemberCard";
import OrderItemsCard from "../../utils/OrderItemsCard";
import { formatCurrency } from "components/utils/helpers";
import { PERSONAL_ORDER } from "../../utils/constant";
import { renderOrderStatus } from "../../utils/OrderCard";

const CartView = () => {
  const history = useHistory();
  const location = useLocation();
  const { item,} = location.state || {};
  console.log(item)
  const pOrders = item.orderType === PERSONAL_ORDER? true : false
  return (
    <>
      <Helmet>
				<title>{process.env.REACT_APP_NAME} - Cart View</title>
      </Helmet>
      <FadeIn>
        <section className="pb-5 mkt-product-details market-place cart-view">
          <div className="mkt-back-button mb-4">
						<BackButton
							label="Back"
							onClick={() => history.goBack()}
						/>
					</div>

          <div className="cart-view__content">
            <div className=" mb-4 cv-top-section">
              <h4 className=" mb-0">
                {pOrders ? "Personal" : "Office Group "} {" " }Cart Order
              </h4>
            { !pOrders && <span>
                <MemberCard
                />
              </span>}
              <span className="sub-text mt-2">
                Order number <span>{ item.orderId}</span>
              </span>
            </div>
          <div className="cv-content-section cv-content-section__content">
              <div className="cv-items-details ">
                <div className="cv-items-details__header">
                   <h2>
                    {pOrders ? "Personal" : "Group "} {" "}  Cart Items
                    </h2>
                </div>

                <div className="cv-items-details__content">
                  {item.orderItemList && item.orderItemList.map((list, index) => (
                    <OrderItemsCard key={index} list={list} type={pOrders} />
                  ))}
                </div>

              </div>
              <div className="cv-summary-details">
                <Summary summary={item} />
              </div>
          </div>
         </div>
        </section>

      </FadeIn>
    </>
  )
}
const Summary = ({summary}) => {
  return (
    <>
      <div className=" cv-summary-details__header">
        <div className=" d-flex">
          <h2>
              Individual  Cart Details
          </h2>
          {renderOrderStatus(summary.orderStatus)}
        </div>
        <p className="date-text">12 Nov 2022</p>
        </div>
        <div className="cv-summary-details__content">
          <div className=" cost-summary">
            <div className="cost-summary-items">
              <p>
                Cost of Goods
              </p>
              <p>
              {formatCurrency(summary.totalPaymentAmount -summary.deliveryCost)}
              </p>
            </div>
            <div className="cost-summary-items">
              <p>
                Delivery Fee
              </p>
              <p>
               {formatCurrency(summary.deliveryCost)}
              </p>
            </div>
              <div className="cost-summary-items final-cost">
              <p>
                Total Cost
              </p>
              <p>
                 {formatCurrency(summary.totalPaymentAmount)}
              </p>
            </div>

          </div>
          <div className="address-summary">
            <p>Delivery address:</p>
          <p className="d-address">{ summary.deliveryAddress} </p>
          </div>

        </div>
    </>
  )
}
export default CartView