import React, { useState, useEffect } from "react";
import FormInputGroup from "components/utils/FormInputGroup/FormInputGroup";
import { Formik } from "formik";
import * as yup from "yup";
import { SmallLoader } from "components/utils/SmallLoader";
import { Label } from "reactstrap";
import CommonSelect from "components/utils/CommonSelect/CommonSelect";
import { ToastsStore } from "react-toasts";
import { API } from "lib/api";
import {
	getAllStates,
	getBusinessCategory,
} from "lib/api/apiRoutesConfig/services/AccountMS/common/subRoutes";
import { SOLE_PROPRIETORSHIP } from "config/enums";
import { postBusinessDetailsAction } from "lib/api/apiRoutesConfig/services/AccountMS/CAC/subRoutes";
import { getErrorMessage } from "components/utils/helpers";

const BusinessDetails = ({ regInfo, refresh }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [statesLoading, setStatesLoading] = useState(false);
	const [states, setStates] = useState([]);
	const [, setCategoryLoading] = useState(false);
	const [businessCategory, setBusinessCategory] = useState([]);

	// Validation Schema
	const formValidationSchema = yup.object().shape({
		proposedBusinessName1: yup.string().required("Is required"),
		proposedBusinessName2: yup.string().required("Is required"),
		proposedBusinessName3: yup.string().required("Is required"),
		businessDescription: yup.string().required("Is required"),
		businessAddress: yup.string().required("Is required"),
		businessCategory: yup
			.object()
			.nullable()
			.required(" Is required")
			.shape({ label: yup.string(), value: yup.string() }),
		city: yup.string().required("Is required"),
		state: yup
			.object()
			.nullable()
			.required(" Is required")
			.shape({ label: yup.string(), value: yup.string() }),
		numberOfDirectors: yup.string().required("Is required"),
	});

	const initialFormValues = {
		proposedBusinessName1: "",
		proposedBusinessName2: "",
		proposedBusinessName3: "",
		businessDescription: "",
		businessAddress: "",
		businessType: SOLE_PROPRIETORSHIP,
		businessCategory: null,
		city: "",
		state: null,
		numberOfDirectors: "",
		referralCode: "",
	};

	// Fetch states
	const fetchStates = async () => {
		setStatesLoading(true);
		try {
			const res = await API.get(getAllStates("NG"));
			const { status, data } = res || {};
			if (status === 200) {
				setStates(data.data);
			}
		} catch (err) {
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		} finally {
			setStatesLoading(false);
		}
	};

	// Fetch business category
	const fetchBusinessCategory = async () => {
		setCategoryLoading(true);
		try {
			const res = await API.get(getBusinessCategory);
			const { status, data } = res || {};
			if (status === 200) {
				setBusinessCategory(data.data);
			}
		} catch (err) {
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		} finally {
			setCategoryLoading(false);
		}
	};

	// Handle form submit
	const handleSubmit = async (values) => {
		setIsLoading(true);

		try {
			const res = await API.put(
				postBusinessDetailsAction(regInfo.sessionId),
				{
					...values,
					businessCategory: values.businessCategory.value,
					stateCode: values.state.value,
				}
			);
			const { status, data } = res || {};
			if (status === 200) {
				refresh(localStorage.getItem("s_id"));
				ToastsStore.success(data.message, 6000, "right-toast");
			}
		} catch (err) {
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchStates();
		fetchBusinessCategory();
	}, []);
	return (
		<>
			<Formik
				initialValues={initialFormValues}
				validationSchema={formValidationSchema}
				onSubmit={(values) => handleSubmit(values)}
			>
				{({
					values,
					errors,
					touched,
					handleSubmit,
					setFieldValue,
					handleChange,
					handleBlur,
				}) => {
					return (
						<form
							className="reg-business-form  row"
							onSubmit={(e) => {
								e.preventDefault();
								handleSubmit();
							}}
						>
							<div className="mb-4 col-12">
								<FormInputGroup
									id="proposedBusinessName1"
									name="proposedBusinessName1"
									label="Proposed business name 1"
									className="red-business-input"
									onBlur={handleBlur}
									value={values.proposedBusinessName1}
									onChange={handleChange}
									error={
										touched.proposedBusinessName1 &&
										errors.proposedBusinessName1
									}
								/>
							</div>
							<div className="mb-4 col-12">
								<FormInputGroup
									id="proposedBusinessName2"
									name="proposedBusinessName2"
									label="Proposed business name 2"
									className="red-business-input"
									onBlur={handleBlur}
									value={values.proposedBusinessName2}
									onChange={handleChange}
									error={
										touched.proposedBusinessName2 &&
										errors.proposedBusinessName2
									}
								/>
							</div>
							<div className="mb-4 col-12">
								<FormInputGroup
									id="proposedBusinessName3"
									name="proposedBusinessName3"
									label="Proposed business name 3"
									className="red-business-input"
									onBlur={handleBlur}
									value={values.proposedBusinessName3}
									onChange={handleChange}
									error={
										touched.proposedBusinessName3 &&
										errors.proposedBusinessName3
									}
								/>
							</div>

							<div className="mb-4 col-12">
								<Label for="businessDescription">
									Business Description
								</Label>
								<textarea
									onBlur={handleBlur}
									value={values.businessDescription}
									onChange={handleChange}
									id="businessDescription"
									name="businessDescription"
									className=" form-control text-area red-business-input"
								></textarea>
								{touched.businessDescription &&
									errors.businessDescription && (
										<span className="error-msg">
											{touched.businessDescription &&
												errors.businessDescription}
										</span>
									)}
							</div>
							<div className="mb-4 col-12">
								<FormInputGroup
									id="businessAddress"
									name="businessAddress"
									label="Business Address"
									className="red-business-input"
									onBlur={handleBlur}
									value={values.businessAddress}
									onChange={handleChange}
									error={
										touched.businessAddress &&
										errors.businessAddress
									}
								/>
							</div>

							<div className="mb-4 col-12">
								<Label for="businessCategory">
									Business Category
								</Label>
								<CommonSelect
									name="businessCategory"
									placeholder="Select category"
									error={
										touched.businessCategory &&
										errors.businessCategory
									}
									onBlur={handleBlur}
									handleChange={(businessCategory) => {
										setFieldValue(
											"businessCategory",
											businessCategory
										);
									}}
									options={businessCategory.map(
										(businessCategory) => ({
											label: businessCategory?.name,
											value: businessCategory?.code,
										})
									)}
									showErrorMsg
								/>
							</div>
							<div className="mb-4 col-12">
								<FormInputGroup
									id="city"
									name="city"
									label="City"
									className="red-business-input"
									onBlur={handleBlur}
									value={values.city}
									onChange={handleChange}
									error={touched.city && errors.city}
								/>
							</div>
							<div className="mb-4 col-12">
								<Label for="state">State</Label>
								<CommonSelect
									name="state"
									isSearchable
									placeholder="Select state"
									error={touched.state && errors.state}
									onBlur={handleBlur}
									handleChange={(state) => {
										setFieldValue("state", state);
									}}
									options={states.map((state) => ({
										label: state?.name,
										value: state?.code,
									}))}
									isLoading={statesLoading}
									showErrorMsg
								/>
							</div>
							<div className="mb-4 col-12">
								<FormInputGroup
									id="numberOfDirectors"
									label="No of business owners"
									name="numberOfDirectors"
									className="red-business-input"
									onBlur={handleBlur}
									value={values.numberOfDirectors}
									onChange={(e) => {
										const value = e.target.value
											.trim()
											.replace(/[^+0-9]/i, "");
										setFieldValue(e.target.name, value);
									}}
									error={
										touched.numberOfDirectors &&
										errors.numberOfDirectors
									}
								/>
							</div>

							<div className="mb-4 col-12">
								<FormInputGroup
									id="referralCode"
									name="referralCode"
									label="Referral code"
									className="red-business-input"
									onBlur={handleBlur}
									value={values.referralCode}
									onChange={handleChange}
									error={
										touched.referralCode &&
										errors.referralCode
									}
								/>
							</div>

							<div className="col-12 ">
								<button
									style={{ background: "#000" }}
									className="btn btn-mint-default"
									type="submit"
									disabled={isLoading}
								>
									{isLoading ? <SmallLoader /> : "Continue"}
								</button>
							</div>
						</form>
					);
				}}
			</Formik>
		</>
	);
};
export default BusinessDetails;
