import React from "react";
import { Formik } from "formik";
import * as yup from "yup";
import CustomCurrencyInput from "components/utils/CustomCurrencyInput/CustomCurrencyInput";
import { parseAmount } from "components/utils/helpers";
import CustomLargeBadge from "components/utils/CustomLargeBadge/CustomLargeBadge";
import { scheduleFrequencyEnum } from "config/enums";
import FormDatePickerGroup from "components/utils/FormDatePickerGroup/FormDatePickerGroup";
import "../CreateEmergencySaving.scss";

const formValidationSchema = yup.object().shape({
	autoDebit: yup.boolean().default(true).required("Please select an option"),
	fundingAmount: yup
		.number()
		.required("Amount is required")
		.min(100, "Minimum amount is ₦100"),
	frequency: yup.mixed().when("autoDebit", {
		is: (value) => value === true,
		then: yup.string().required("Select a frequency"),
		otherwise: yup.mixed(),
	}),
	startDate: yup.mixed().when("autoDebit", {
		is: (value) => value === true,
		then: yup.date().required("Start date is required"),
		otherwise: yup.mixed(),
	}),
});

export default function FundSavings({ goToNextStep, payload }) {
	const initialFormValues = {
		autoDebit: payload.autoDebit,
		fundingAmount: payload.fundingAmount,
		frequency: payload.frequency,
		startDate: payload.startDate,
	};

	return (
		<div className="create-emergency-savings-page__step shrink">
			<h2>Fund Savings</h2>
			<p>How do you want to fund this savings?</p>

			<Formik
				initialValues={initialFormValues}
				validationSchema={formValidationSchema}
				onSubmit={(values) =>
					goToNextStep({
						...payload,
						...values,
					})
				}
			>
				{({
					values,
					errors,
					touched,
					handleSubmit,
					handleBlur,
					setFieldValue,
					resetForm,
				}) => {
					return (
						<form
							onSubmit={(e) => {
								e.preventDefault();
								handleSubmit();
							}}
						>
							<div className="mb-4">
								<div className="d-flex justify-content-between">
									<AutoDebitPanel
										title="Automatically"
										selected={values.autoDebit === true}
										onClick={() => {
											setFieldValue("autoDebit", true);
											resetForm({
												values: {
													...values,
													autoDebit: true,
													frequency: "",
													startDate: undefined,
												},
											});
										}}
									/>
									<AutoDebitPanel
										title="Manually"
										selected={values.autoDebit === false}
										onClick={() => {
											setFieldValue("autoDebit", false);
											resetForm({
												values: {
													...values,
													autoDebit: false,
													frequency: "",
													startDate: undefined,
												},
											});
										}}
									/>
								</div>
								<p className="cesp-form-info">
									{values.autoDebit
										? `This means your account will be debited
										automatically at specific intervals
										beginning from the selected start date.`
										: "This means you can fund your savings goal whenever you want."}
								</p>
								{touched.autoDebit && errors.autoDebit && (
									<span className="error-msg">
										{errors.autoDebit}
									</span>
								)}
							</div>

							<div className="mb-4">
								<CustomCurrencyInput
									className="cesp-input"
									name="fundingAmount"
									label="Amount (₦)"
									id="amofundingAmountunt"
									value={values.fundingAmount}
									placeholder="Enter Amount"
									onBlur={handleBlur}
									error={
										touched.fundingAmount &&
										errors.fundingAmount
									}
									onChange={(value) => {
										setFieldValue(
											"fundingAmount",
											parseAmount(value)
										);
									}}
								/>
							</div>

							{values.autoDebit && (
								<>
									<div className="mb-4">
										<label>Frequency</label>
										<div className="cesp-frequency">
											<CustomLargeBadge
												title="Daily"
												isSelected={
													values.frequency ===
													scheduleFrequencyEnum.DAILY
												}
												onClick={() =>
													setFieldValue(
														"frequency",
														scheduleFrequencyEnum.DAILY
													)
												}
											/>
											<CustomLargeBadge
												title="Weekly"
												isSelected={
													values.frequency ===
													scheduleFrequencyEnum.WEEKLY
												}
												onClick={() =>
													setFieldValue(
														"frequency",
														scheduleFrequencyEnum.WEEKLY
													)
												}
											/>
											<CustomLargeBadge
												title="Monthly"
												isSelected={
													values.frequency ===
													scheduleFrequencyEnum.MONTHLY
												}
												onClick={() =>
													setFieldValue(
														"frequency",
														scheduleFrequencyEnum.MONTHLY
													)
												}
											/>
										</div>
										{touched.frequency &&
											errors.frequency && (
												<span className="error-msg">
													{errors.frequency}
												</span>
											)}
									</div>

									<div className="mb-4">
										<label>Start Date</label>
										<FormDatePickerGroup
											isClearable={false}
											placeholder="Choose Date"
											selected={values.startDate}
											onBlur={handleBlur}
											min={new Date()}
											name="startDate"
											onChange={(date) => {
												setFieldValue(
													"startDate",
													date
												);
											}}
											error={
												touched.startDate &&
												errors.startDate
											}
										/>
									</div>
								</>
							)}

							<button
								className="btn btn-mint-default text-capitalize"
								type="submit"
								style={{ borderRadius: "15px", height: "50px" }}
							>
								Continue
							</button>
						</form>
					);
				}}
			</Formik>
		</div>
	);
}

const AutoDebitPanel = ({ title, selected, onClick }) => {
	return (
		<div
			role="button"
			className={`auto-debit-panel ${selected ? "selected" : ""}`}
			onClick={onClick}
		>
			{title}
		</div>
	);
};
