import React, { Component } from "react";
import { connect } from "react-redux";
import FundsTransferSuccess from "./FundsTransferSuccess";
import CreateTranscationPin from "../../security/CreateTranscationPin";
// import TransferDetailPin from "./TransferDetailsPin";
import { API } from "../../../../../lib/api";
import { transferSingleTransaction } from "../../../../../lib/api/apiRoutesConfig/services/FundsManagementMS/fundTransfer/subRoutes";
import { getErrorMessage } from "../../../../utils/helpers";
import FailedTranscation from "./extras/FailedTranscation/FailedTranscation";
import Modal from "../../../../utils/Modal/Modal";
import Axios from "axios";
import LoadingBox from "../../../../utils/LoadingBox/LoadingBox";
import ModalHeader from "components/utils/Modal/CustomModalHeader/CustomModalHeader";
import NewTransferDetailsPin from "./NewTransferDetailsPin";
import { INDIVIDUAL } from "config/enums";

class FundsTransferForm extends Component {
	signal = Axios.CancelToken.source();
	_isMounted = false;

	state = {
		loading: false,
		stillWorking: true,
		error: false,
		errorMessage: "",
		failed: false,
		done: false,
		successData: {},
	};

	componentDidMount = () => {
		this._isMounted = true;
		// console.log(this.props.loginResponse);
	};

	componentWillUnmount = () => {
		this.signal.cancel("canceling network calls");
		this._isMounted = false;
	};

	handleTryAgain = (e) => {
		e.preventDefault();
		this.props.toggleModal();
	};

	confirmTranscation = async (pin) => {
		try {
			this.setState({ loading: true });
			const { transcationRequest, bankAccounts } = this.props;
			const { accountId } = bankAccounts ? bankAccounts[0] : {};
			transcationRequest.authType = "PIN";
			transcationRequest.authValue = pin;
			transcationRequest.debitAccountId = accountId;
			const res = await API.post(
				transferSingleTransaction,
				transcationRequest,
				{
					cancelToken: this.signal.token,
				}
			);
			if (res.status === 200) {
				let data = res.data.data;
				this._isMounted &&
					this.setState({
						loading: false,
						successData: { ...data },
						asKPin: false,
						done: true,
					});
			}
		} catch (err) {
			this.setState({
				errorMessage: getErrorMessage(err),
				loading: false,
				failed: true,
			});
		}
	};

	render = () => {
		const { errorMessage, loading, done, failed, successData } = this.state;
		const {
			transcationRequest,
			show,
			toggleModal,
			selectedBank,
			activeAccountType,
			loginResponse,
		} = this.props;
		// const { transactionPinCreated } = customerSettings || {};
		const accountType =
			activeAccountType === INDIVIDUAL
				? "activeIndividualAccount"
				: "activeCorporateAccount";

		const { transactionPinCreated } =
			loginResponse[accountType]?.profileSetting || {};

		return (
			<Modal show={show} size="md">
				{!done && (
					<ModalHeader
						heading="Send Money"
						className="p-0"
						headerClassName="setup-modals-header px-sm-5 px-3 py-3"
						dismissable={toggleModal}
					/>
				)}
				<div className="">
					{loading ? (
						<LoadingBox />
					) : failed ? (
						<FailedTranscation
							message={errorMessage}
							handleTryAgain={this.handleTryAgain}
						/>
					) : done ? (
						<FundsTransferSuccess
							successData={successData}
							transcationRequest={transcationRequest}
						/>
					) : transactionPinCreated ? (
						<NewTransferDetailsPin
							transcationRequest={transcationRequest}
							confirmTranscation={this.confirmTranscation}
							selectedBank={selectedBank}
							// toggleModal={this.props.toggleModal}
						/>
					) : (
						<CreateTranscationPin
							ConfirmTranscation={this.confirmTranscation}
							toggleModal={this.props.toggleModal}
						/>
					)}
				</div>
			</Modal>
		);
	};
}

export default connect(
	(state) => ({
		loginResponse: state.auth.loginResponse,
		// customerSettings: state.settings.profileSettings,
		bankAccounts: state.dashboard.dashboardResponse.bankAccounts,
		activeAccountType: state.auth.activeAccountType,
	}),
	null
)(FundsTransferForm);
