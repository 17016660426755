import React from "react";
import { PERSONAL_ORDER,} from "./constant";
import { formatCurrency } from "components/utils/helpers";

import Personal from "../../components/utils/assets/personal-icon.svg"


export const OrderCard = ({list, onClick}) => {
const pOrders = list.orderType === PERSONAL_ORDER? true : false

  

  return (
    <div onClick={onClick} className="order-history-card">
        <div className="details ">
          <span className="icon">
            <img src={ Personal} alt='personal order' />
          </span>
          <div className=" ">
            <span className="title">{pOrders? "":list?.groupShoppingPayment?.groupName}</span>
          <span className="sub-title"> {pOrders? `Personal Order ${list.orderId}` :`Group order ${list.orderId}`}</span>
            {renderOrderStatus(list.orderStatus)}
          </div>
        </div>
        <div className="amount">
        <span>{formatCurrency(list.totalPaymentAmount)}</span>
        </div>
      </div>
  )
}

export 	const renderOrderStatus = (orderStatus) => {
		switch (orderStatus) {
			case "CANCELLED":
				return (
					<span className={` status-badge cancelled`}>
              {'Cancelled'}
          </span>
				);

			case "PENDING_CONFIRMATION":
				return <span className={` status-badge pending`}>
               {'Pending'}
          </span>;
				case "PROCESSING":
				return <span className={` status-badge processing`}>
               {'Processing'}
        </span>;
      
      	case "DELIVERED":
				return <span className={` status-badge delivered`}>
              {'Delivered'}
          </span>;

			default:
				return null;
		}
	};