import React, { Component } from "react";
import TransferLimitModal from "./TransferLimitModal/TransferLimitModal";

class SpendLimitSetting extends Component {
    state = {
        showModal: false,
    };

    render() {
        const { showModal } = this.state;
        return (
            <>
                <div className='accountSetting'>
                    <h6 className='label--heading'>Spend Limit</h6>
                    <h5 className='mb-0'>
                        <button
                            className=' w-100 btn accordion__btn--link'
                            onClick={() => this.setState({ showModal: true })}>
                            Transfers
                        </button>
                        <div className='bus-down'></div>
                    </h5>
                </div>
                {showModal && (
                    <TransferLimitModal
                        show={showModal}
                        toggleModal={() => this.setState({ showModal: false })}
                    />
                )}
            </>
        );
    }
}

export default SpendLimitSetting;
