import BillsService from "../";

export const {
	getAllBillsCategories,
	getBillersbyCategory,
	getBillerItemsbyVasId,
	validateBillsPayload,
	completeBillsPayment,
	performScheduleBillsPayment,
	deactivateSchdeuleBill,
	getAirtimeBillers,
	getDataBundleBillers,
	getActiveCountries,
	getBillPaymentRequest,
	updateBillPaymentRequestStatus,
	getDetailedCategories,
	getBillsBeneficiaries,
	completeBillsPaymentV2,
	remitaLookUp,
	initiateRemita,
	getSplitPayments,
	postSplitPayment,
	resolveAccountSplitpayment,
	splitpaymentRemindOne,
	splitpaymentRemindAll,
	deleteSplitpayment,
	getSplitPaymentsPending,
	paySplitPayment,
	rejectSplitpayment,
} = {
	getAllBillsCategories: `${BillsService.default}/categories`,
	getBillersbyCategory: (categoryID) =>
		`${BillsService.default}/categories/${categoryID}/billers`,
	getBillerItemsbyVasId: (vasBillerId) =>
		`${BillsService.default}/billers/${vasBillerId}/bill-items`,
	validateBillsPayload: (vasBillerId) =>
		`${BillsService.default}/bill-items/${vasBillerId}/validate`,
	completeBillsPayment: (vasBillerId) =>
		`${BillsService.default}/bill-items/${vasBillerId}/pay`,
	performScheduleBillsPayment: `${BillsService.default}/bill/schedule`,
	deactivateSchdeuleBill: (id) =>
		`${BillsService.default}/bill/schedule/${id}`,
	getAirtimeBillers: `${BillsService.default}/categories/airtime/billers`,
	getDataBundleBillers: `${BillsService.default}/categories/data/billers`,
	getActiveCountries: `${BillsService.default}/countries`,
	getBillPaymentRequest: (requestId) =>
		`${BillsService.default}/corporate/transactions/${requestId}/bill-payment`,
	updateBillPaymentRequestStatus: `${BillsService.default}/corporate/transactions/update-status`,
	getDetailedCategories: `${BillsService.default}/detailed-categories`,
	getBillsBeneficiaries: `${BillsService.default}/bills/beneficiary`,
	completeBillsPaymentV2: (vasBillerId) =>
		`${BillsService.version2}/bill-items/${vasBillerId}/pay`,
	remitaLookUp: `${BillsService.default}/bill/remita/look-up`,
	initiateRemita: `${BillsService.default}/bill/remita/initiate`,
	getSplitPayments: `${BillsService.default}/split-payment`,
	postSplitPayment: `${BillsService.default}/split-payment`,
	resolveAccountSplitpayment: `${BillsService.default}/split-payment/resolve-account`,
	splitpaymentRemindOne: (id) =>
		`${BillsService.default}/split-payment/${id}/transaction/remind`,
	splitpaymentRemindAll: (id) =>
		`${BillsService.default}/split-payment/${id}/remind`,
	deleteSplitpayment: (id) =>
		`${BillsService.default}/split-payment/${id}/delete`,
	getSplitPaymentsPending: `${BillsService.default}/split-payment/pending`,
	paySplitPayment: `${BillsService.default}/split-payment/transaction/pay`,
	rejectSplitpayment: (id) =>
		`${BillsService.default}/split-payment/${id}/transaction/reject`,
};
