import React, { useState, useContext } from "react";
import _ from "lodash";
import TransactionAmountInput from "../../../../transcations/TranscationAmountInput";
import FormInputGroup from "../../../../../../utils/FormInputGroup/FormInputGroup";
import FormGroupSelect from "../../../../../../utils/FormGroupSelect/FormGroupSelect";
import ValidateForm from "../../../../../../utils/Validator";
import { InvestmentContext } from "../../CreateInvestment";

const InvestmentPreferenceForm = () => {
	const { formValues, next, handleChange } = useContext(InvestmentContext);

	const [formErrors, setFormErrors] = useState({});
	const [terms, setTerms] = useState(false);

	const { durationInMonths, investmentAmount, durationId } = formValues || {};
	const { minimumDuration: min, maximumDuration: max } = durationId;

	const disabled =
		!durationInMonths ||
		!investmentAmount ||
		!terms ||
		investmentAmount.replace(/,/g, "") < 5000;

	const range = _.range(min, max + 1, 1);

	const options = range?.map((value) => ({
		label: `${value} month${value > 1 ? "s" : ""}`,
		value,
	}));

	const handleSubmit = (e) => {
		e.preventDefault();

		const { errors, formisValid } =
			ValidateForm(e, { durationInMonths, investmentAmount }) || {};
		formisValid ? next() : setFormErrors(errors);
	};

	const toggle = () => setTerms((prev) => !prev);

	return (
		<>
			<form onSubmit={handleSubmit} className="w-100">
				<div className="my-4">
					<h3 className="sub-heading m-0">Create Investment</h3>
					<span className="tiny__message m-0 font-weight-normal">
						Enter your investment preferences
					</span>
				</div>
				<div className="mb-3">
					<TransactionAmountInput
						cls="mb-1"
						label="Amount"
						updateAmount={(value) =>
							handleChange("investmentAmount", value)
						}
					/>
					<small className="tiny__message">
						Minimum amount to invest is N5,000.00
					</small>
				</div>
				<FormGroupSelect
					name="durationInMonths"
					label="Select Investment Duration"
					placeholder="Choose duration"
					options={options}
					value={durationInMonths}
					error={formErrors?.durationInMonths}
					onChange={(e) => handleChange("durationInMonths", e)}
				/>
				<FormInputGroup
					name="referralCode"
					label="Referral Code"
					placeholder="Enter referral code"
					value={formValues?.referralCode}
					className="form-control primary"
					onChange={({ target }) =>
						handleChange("referralCode", target?.value)
					}
				/>
				<div className="py-4">
					<div
						className="custom-control custom-checkbox pointer"
						onClick={toggle}
					>
						<input
							type="checkbox"
							className="custom-control-input"
							id="terms"
							name="terms"
							checked={terms}
							onChange={() => {}}
						/>
						<div
							className="custom-control-label zero-text"
							htmlFor="terms"
						>
							Mintyn investment
							<a
								href="https://mintyn.com/terms"
								className="specialLink px-1"
								target="_blank"
								rel="noopener noreferrer"
							>
								Terms and Conditions
							</a>
							, I confirm that I act on my own behalf
						</div>
					</div>

					<small className="zero-text ">
						I hereby create a Mintyn Investment according to the
						following legal documents which I have read and to which
						I consent
					</small>
				</div>

				<button
					className="btn create-acc"
					onClick={disabled ? () => {} : next}
					disabled={disabled}
				>
					Proceed
				</button>
			</form>
		</>
	);
};

export default InvestmentPreferenceForm;
