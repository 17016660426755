import React from "react";
import { ReactSVG } from "react-svg";
import quickTellerSvg from "./quickteller.svg";

const QuickTellerAcctFunding = () => {
    function openInNewTab(url) {
        var win = window.open(url, "_blank");
        win.focus();
    }

    return (
        <div className='col-lg-4'>
            <div className='boxxie'>
                <div className='small-talk'>
                    <ReactSVG src={quickTellerSvg} title='Local Bank' />
                    <h6>Quickteller</h6>
                    <p>
                        Fund your account using quickteller
                        <br /> payment gateway
                    </p>
                </div>
                <div className='row justify-content-center align-items-center'>
                    <div className='col-12'>
                        <div className='small-form'>
                            <label>&nbsp;</label>
                            <button
                                className='btn card-btn'
                                onClick={() =>
                                    openInNewTab(
                                        "https://www.quickteller.com/finexmfb"
                                    )
                                }>
                                Go to QuickTeller
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QuickTellerAcctFunding;
