export const TOP_UP = "TOP_UP";
export const LIQUIDATE = "LIQUIDATE";

export const getInvestmentEstReturn = (amount, rate, duration) => {
	amount = parseFloat(amount);
	rate = parseFloat(rate);
	duration = parseFloat(duration);

	const profitPerMonth = amount * (rate / (100 * 12));
	const profit = profitPerMonth * duration;
	const tax = 0.1 * profit;
	return { profit, tax };
};
