import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";
import { API } from "lib/api";
import {
	getBillerItemsbyVasId,
	getDataBundleBillers,
} from "lib/api/apiRoutesConfig/services/BillsService/subRoutes";
import { ToastsStore } from "react-toasts";
import {
	getErrorMessage,
	getValidMobileNumberLength,
	parseAmount,
} from "components/utils/helpers";
import { MintInfinite } from "components/dashboard/utils/ShineLoader/ShineLoader";
import { ReactComponent as MtnLogo } from "assets/img/mtn_logo.svg";
import { ReactComponent as NineMobileLogo } from "assets/img/9mobile_logo.svg";
import { ReactComponent as GloLogo } from "assets/img/glo_logo.svg";
import { ReactComponent as AirtelLogo } from "assets/img/airtel_logo.svg";
import CommonSelect from "components/utils/CommonSelect/CommonSelect";
import { beneficiaryTypeEnum } from "config/enums";
import FormInputGroup from "components/utils/FormInputGroup/FormInputGroup";
import CustomCurrencyInput from "components/utils/CustomCurrencyInput/CustomCurrencyInput";
import DataBundleSummary from "./DataBundleSummary/DataBundleSummary";
import "../TopUp.scss";

const formValidationSchema = yup.object().shape({
	customerId: yup.string().required("Recipient phone number is required"),
	billMerchantItem: yup
		.object()
		.required("Select a data bundle package")
		.nullable()
		.shape({
			label: yup.string(),
			value: yup.mixed(),
		}),
	amount: yup.number().when("billMerchantItem", {
		is: (merchantItem) => merchantItem?.value?.amountType !== "FIXED",
		then: yup
			.number()
			.required("Amount is required")
			.min(1, "Amount is required"),
		otherwise: yup.number(),
	}),
});

export default function DataBundle() {
	const [isBillerLoading, setIsBillerLoading] = useState(false);
	const [dataBillers, setDataBillers] = useState([]);
	const [selectedBiller, setSelectedBiller] = useState({});
	const [transaction, setTransaction] = useState(null);
	const [isSummaryShown, setIsSummaryShown] = useState(false);
	const [billMerchants, setBillMerchants] = useState([]);
	const [isBillMerchantLoading, setIsBillMerchantLoading] = useState(false);

	const currentUser = useSelector((state) => state.auth.currentUser);
	const { phoneNumber } = currentUser || {};

	const initialFormValues = {
		customerId: phoneNumber || "",
		amount: "",
		beneficiaryType: { label: "For Myself", value: "SELF" },
		billMerchantItem: null,
	};

	// fetch data bundle merchants
	const fetchDataMerchants = async () => {
		setIsBillerLoading(true);
		try {
			const response = await API.get(getDataBundleBillers);
			const { status, data } = response || {};
			if (status === 200) setDataBillers(data.data);
		} catch (err) {
			setDataBillers([]);
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		} finally {
			setIsBillerLoading(false);
		}
	};

	// fetch bill merchant by vasId of data bundle biller selected
	const fetchBillMerchants = async () => {
		setIsBillMerchantLoading(true);
		try {
			const response = await API.get(
				getBillerItemsbyVasId(selectedBiller.vasBillerId)
			);
			const { data, status } = response || {};
			if (status === 200) setBillMerchants(data.data);
		} catch (err) {
			setBillMerchants([]);
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		} finally {
			setIsBillMerchantLoading(false);
		}
	};

	useEffect(() => {
		fetchDataMerchants();
	}, []);

	useEffect(() => {
		if (selectedBiller.vasBillerId) fetchBillMerchants();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedBiller]);

	if (isBillerLoading) return <MintInfinite relative />;

	return (
		<div>
			<Formik
				enableReinitialize
				initialValues={initialFormValues}
				validationSchema={formValidationSchema}
				onSubmit={(values) => {
					setTransaction({
						...values,
						vasBillerId: values.billMerchantItem.value.vasItemId,
						biller: selectedBiller.name,
						itemCode: values.billMerchantItem.value.itemCode,
					});
					setIsSummaryShown(true);
				}}
			>
				{({
					values,
					errors,
					touched,
					handleSubmit,
					setFieldValue,
					handleChange,
					handleBlur,
				}) => {
					return (
						<form
							className="top-up-form"
							onSubmit={(e) => {
								e.preventDefault();
								handleSubmit();
							}}
						>
							<h3 className="top-up-panel__header">
								Choose Mobile Network
							</h3>
							<div className="top-up-panel__logos mb-4">
								{dataBillers.map((item, index) => {
									const isActive =
										item.code === selectedBiller.code;
									// TO-DO: add codes for prod.
									const icons = {
										6002: <MtnLogo />,
										6004: <NineMobileLogo />,
										6006: <GloLogo />,
										6008: <AirtelLogo />,
									};

									return (
										<div
											key={index}
											role="button"
											className={`top-up-panel-logo ${
												isActive ? "selected" : ""
											}`}
											onClick={() => {
												setBillMerchants([]);
												setSelectedBiller(item);
												setFieldValue("amount", "");
												setFieldValue(
													"billMerchantItem",
													null
												);
											}}
										>
											{icons[Number(item.code)]}
										</div>
									);
								})}
							</div>

							<div className="mb-4">
								<label htmlFor="beneficiaryType">
									Who are you buying for?
								</label>
								<CommonSelect
									name="beneficiaryType"
									value={values.beneficiaryType}
									handleChange={(value) => {
										if (
											value.value ===
											values.beneficiaryType.value
										) {
											return;
										}

										setFieldValue("beneficiaryType", value);
										setFieldValue(
											"customerId",
											value.value ===
												beneficiaryTypeEnum.SELF
												? phoneNumber
												: ""
										);
									}}
									options={[
										{ label: "For Myself", value: "SELF" },
										{
											label: "For Others",
											value: "OTHERS",
										},
									]}
								/>
							</div>

							<div className="mb-4">
								<FormInputGroup
									id="customerId"
									label="Recipient Number"
									name="customerId"
									placeholder="Enter Phone Number"
									className="topup-input"
									onBlur={handleBlur}
									disabled={
										values.beneficiaryType.value ===
										beneficiaryTypeEnum.SELF
									}
									value={values.customerId}
									onChange={(e) => {
										const value = e.target.value
											.trim()
											.replace(/[^+0-9]/i, "");
										if (
											value.length <=
											getValidMobileNumberLength(value)
										) {
											setFieldValue(e.target.name, value);
										}
									}}
									error={
										touched.customerId && errors.customerId
									}
								/>
							</div>

							<div className="mb-4">
								<label htmlFor="billMerchantItem">
									Choose a bundle
								</label>
								<CommonSelect
									name="billMerchantItem"
									value={values.billMerchantItem}
									placeholder="Select a data bundle"
									isLoading={isBillMerchantLoading}
									onBlur={handleBlur}
									error={
										touched.billMerchantItem &&
										errors.billMerchantItem
									}
									handleChange={(merchantItem) => {
										setFieldValue(
											"billMerchantItem",
											merchantItem
										);
										setFieldValue(
											"amount",
											merchantItem.value.amount
										);
									}}
									options={[...billMerchants]
										.sort((a, b) => a.amount - b.amount)
										.map((item) => ({
											label: item.name,
											value: item,
										}))}
									showErrorMsg
								/>
							</div>

							{values.billMerchantItem &&
								Object.values(values.billMerchantItem).length >
									0 && (
									<div className="mb-4">
										<CustomCurrencyInput
											className="topup-input"
											name="amount"
											label="Amount"
											id="amount"
											value={values.amount}
											placeholder="Enter Amount"
											onBlur={handleBlur}
											disabled={
												values.billMerchantItem?.value
													?.amountType === "FIXED" ||
												billMerchants.length === 0
											}
											error={
												touched.amount && errors.amount
											}
											onChange={(value) => {
												setFieldValue(
													"amount",
													parseAmount(value)
												);
											}}
										/>
									</div>
								)}

							<button
								className="btn btn-mint-default mt-2"
								type="submit"
								disabled={
									Object.values(selectedBiller).length ===
										0 || billMerchants.length === 0
								}
							>
								Continue
							</button>
						</form>
					);
				}}
			</Formik>

			<DataBundleSummary
				show={isSummaryShown && !!transaction}
				transaction={transaction}
				onClose={() => {
					setIsSummaryShown(false);
					setTransaction(null);
				}}
			/>
		</div>
	);
}

// VARIABLE | FIXED
