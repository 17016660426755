import React, { Component } from "react";
import { GroomPanel2 } from "../../utils/GroomPanel/GroomPanel";
import FTransfer from "./form/FTransfer";
import { SAMEBANK, OTHER_BANKS, INDIVIDUAL } from "../../../../config/enums";
import CustomTabs from "components/utils/CustomTabs/CustomTabs";
import FadeIn from "../effects/FadeIn";
import { connect } from "react-redux";
import UserRoleWarning from "components/utils/UserRoleWarning/UserRoleWarning";
import { Helmet } from "react-helmet";
class FundsTransfer extends Component {
	state = {
		getting: true,
		accountType: SAMEBANK,
	};

	componentDidMount = () => {
		window.scroll(0, 0);
		const { state: locationState } = this.props.location || {};
		const { accountType = "", availableBeneficiary = "" } =
			locationState || {};
		const hasLocationProps =
			accountType !== "" && availableBeneficiary !== "";
		this.setState({
			accountType: hasLocationProps ? accountType : SAMEBANK,
			availableBeneficiary: hasLocationProps
				? availableBeneficiary
				: undefined,
			getting: false,
		});
	};

	handleAccountTypeChange = (value) => {
		this.setState({ accountType: value });
	};

	render = () => {
		const { accountType, availableBeneficiary, getting } = this.state;
		const { loginResponse, activeAccountType } = this.props;

		const userAccountType =
			activeAccountType === INDIVIDUAL
				? "activeIndividualAccount"
				: "activeCorporateAccount";

		const { currentRole } =
			loginResponse[userAccountType]?.profileSetting || {};
		const isApprover = currentRole === "APPROVER";

		const fundTransferTabList = [
			{ title: "Mintyn to Mintyn", value: SAMEBANK },
			{ title: "Mintyn to Other Banks", value: OTHER_BANKS },
		];

		if (getting) return "";

		return (
			<>
				<Helmet>
					<title>{process.env.REACT_APP_NAME} - Fund Transfer</title>
				</Helmet>

				<FadeIn>
					<div className="py-5">
						<h1 className="page-title-text">Send Money</h1>
						<p className="page-lead-text">
							Perform intrabank and interbank transfer operations
							here
						</p>

						<CustomTabs className="mt-5">
							{fundTransferTabList.map((tab) => (
								<li
									key={tab.value}
									role="tab"
									onClick={() =>
										this.handleAccountTypeChange(tab.value)
									}
								>
									<span
										data-current={tab.value === accountType}
									>
										{tab.title}
									</span>
								</li>
							))}
						</CustomTabs>

						<GroomPanel2
							title={
								accountType === OTHER_BANKS
									? "Send Money to a Non-Mintyn user"
									: "Send Money to a Mintyn user"
							}
						>
							{isApprover ? (
								<UserRoleWarning
									style={{ minHeight: "200px" }}
									cls="d-flex align-items-center justify-content-center"
								/>
							) : (
								<FTransfer
									key={accountType}
									accountType={accountType}
									availableBeneficiary={availableBeneficiary}
								/>
							)}
						</GroomPanel2>
					</div>
				</FadeIn>
			</>
		);
	};
}

export default connect(
	({ auth: { loginResponse, activeAccountType } }) => ({
		loginResponse: loginResponse,
		activeAccountType: activeAccountType,
	}),
	null
)(FundsTransfer);
