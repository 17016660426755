export const ACCOUNT_SETUP_DOCUMENTS = {
	BUSINESS_DOCUMENTATION: [
		"cacUploaded",
		"certificateOfIncorporatedTrusteeUploaded",
		"govtIdUploaded",
		"tinUploaded",
		"moaUploaded",
	],
	DIRECTORS_DOCUMENTATION: ["governmentID", "passportImage", "personalInfo"],
	TRUSTEES_DOCUMENTATION: ["governmentID", "passportImage", "personalInfo"],
};
