import React, { Component } from "react";
import { connect } from "react-redux";
import { updateCustomerIDStatus } from "../../../../../actions/settingsActions";
import OperationStatus from "../../../utils/OperationStatus/OperationStatus";
import { INITIAL, SUCCESS } from "../../../enums";
import CustomModalHeader from "../../../../utils/Modal/CustomModalHeader/CustomModalHeader";
import UploadCustomerIDForm from "./UploadCustomerIDForm/UploadCustomerIDForm";
import "react-datepicker/dist/react-datepicker.css";

class UploadCustomerID extends Component {
	state = {
		currentStage: INITIAL,
		statusObj: {},
	};

	updateCurrentStage = (response) => {
		this.props.updateCustomerIDStatus(response.data.verificationStatus);
		this.setState({
			currentStage: SUCCESS,
			statusObj: {
				status: SUCCESS,
				statusMessage: response.message,
				successLink: "#",
				successLinkText: "Okay",
				handleSuccessLink: (e) => {
					e.preventDefault();
					this.props.toggleModal();
				},
			},
		});
	};

	renderBasedOnState = () => {
		const { currentStage, statusObj } = this.state;
		switch (currentStage) {
			case INITIAL:
				return (
					<UploadCustomerIDForm
						updateCurrentStage={this.updateCurrentStage}
						toggleModal={this.props.toggleModal}
					/>
				);

			case SUCCESS:
				return <OperationStatus statusObj={statusObj} />;

			default:
				break;
		}
	};

	render = () => {
		return (
			<>
				<CustomModalHeader heading="Verify Your Identity" />
				{this.renderBasedOnState()}
			</>
		);
	};
}

export default connect(null, { updateCustomerIDStatus })(UploadCustomerID);
