import React, { Component } from "react";
import TranscationPinSettings from "./TransactionPinSettings/TransactionPinSettings";
import ResetTransactionPin from "./ResetTransactionPin/ResetTransactionPin";
import ChangePasswordSettings from "./ChangePasswordSettings/ChangePasswordSettings";

class AccountSecuritySettings extends Component {
    state = {
        showPinModal: false,
        showResetPinModal: false,
        showChangePass: false,
    };

    render() {
        const { showPinModal, showResetPinModal, showChangePass } = this.state;
        return (
            <>
                <div className='accountSetting'>
                    <h6 className='label--heading'>Security</h6>
                    <h5 className='mb-0'>
                        <button
                            className=' w-100 btn accordion__btn--link'
                            onClick={() =>
                                this.setState({ showPinModal: true })
                            }>
                            Change Transaction Pin
                        </button>
                        <div className='bus-down'></div>
                    </h5>
                    <h5 className='mb-0'>
                        <button
                            className=' w-100 btn accordion__btn--link'
                            onClick={() =>
                                this.setState({ showResetPinModal: true })
                            }>
                            Reset Transaction Pin
                        </button>
                        <div className='bus-down'></div>
                    </h5>
                    <h5 className='mb-0'>
                        <button
                            className=' w-100 btn accordion__btn--link'
                            onClick={() =>
                                this.setState({ showChangePass: true })
                            }>
                            Change Password
                        </button>
                        <div className='bus-down'></div>
                    </h5>
                </div>

                {showPinModal && (
                    <TranscationPinSettings
                        show={showPinModal}
                        toggleModal={() =>
                            this.setState({ showPinModal: false })
                        }
                    />
                )}
                {showResetPinModal && (
                    <ResetTransactionPin
                        show={showResetPinModal}
                        toggleModal={() =>
                            this.setState({ showResetPinModal: false })
                        }
                    />
                )}
                {showChangePass && (
                    <ChangePasswordSettings
                        show={showChangePass}
                        toggleModal={() =>
                            this.setState({ showChangePass: false })
                        }
                    />
                )}
            </>
        );
    }
}

export default AccountSecuritySettings;
