import React from "react";
import FailedTryAgain from "../../dashboard/utils/FailedTryAgain/FailedTryAgain";
import { MintInfinite } from "../../dashboard/utils/ShineLoader/ShineLoader";
import FadeIn from "../../dashboard/components/effects/FadeIn";
import { responseStatus } from "../../constants";
import GroomPanel from "../../dashboard/utils/GroomPanel/GroomPanel";

const { LOADING, FINISHED, ERROR } = responseStatus;

const ContentWrapper = ({ currentState, errorMsg, refetch, children }) => {
	const renderBasedOnState = () => {
		switch (currentState) {
			case LOADING:
				return (
					<GroomPanel cls="flex__center">
						<MintInfinite />;
					</GroomPanel>
				);

			case ERROR:
				return (
					<GroomPanel cls="px-5 d-flex w-100 h-100 justify-content-center align-items-center">
						<FailedTryAgain
							errorMssg={errorMsg}
							handleTryAgain={refetch}
						/>
					</GroomPanel>
				);

			case FINISHED:
				return <>{children}</>;

			default:
				return "";
		}
	};

	return <FadeIn>{renderBasedOnState()}</FadeIn>;
};

export default ContentWrapper;
