import React, { Component } from "react";
import ModalHeader from "../../../../../../../../utils/Modal/CustomModalHeader/CustomModalHeader";
import { SAMEBANK } from "../../../../../../../../../config/enums";
import AccountTypeSelect from "../../../../../../FundsTransfer/AccountTypeSelect/AccountTypeSelect";
import MintMintLimit from "./MintMintLimit";
import MintOthersLimit from "./MintOthersLimit";

class TransferLimitForm extends Component {
	state = {};

	render() {
		const { toggleModal, accountType, handleAccountTypeChange, nextStep } =
			this.props;
		return (
			<>
				<ModalHeader
					heading={
						accountType === SAMEBANK
							? "Mintyn to Mintyn"
							: "Mintyn to Others"
					}
				/>
				<div className="px-4 py-1">
					<div className="low-form">
						<div className="form-group">
							<label>Account type</label>
							<div className="mt-1">
								<AccountTypeSelect
									selectedRadio={accountType}
									handleRadio={handleAccountTypeChange}
								/>
							</div>
						</div>
						{accountType === SAMEBANK ? (
							<MintMintLimit
								nextStep={nextStep}
								toggleModal={toggleModal}
							/>
						) : (
							<MintOthersLimit
								nextStep={nextStep}
								toggleModal={toggleModal}
							/>
						)}
					</div>
				</div>
			</>
		);
	}
}

export default TransferLimitForm;
