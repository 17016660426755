import React from "react";
import { ReactSVG } from "react-svg";
import "../CreateEmergencySaving.scss";
import HouseIcon from "assets/img/savings-icons/house-icon.svg";
import FamilyIcon from "assets/img/savings-icons/life_events_icon.svg";
import VehicleIcon from "assets/img/savings-icons/vehicle_icon.svg";
import TruckIcon from "assets/img/savings-icons/moving_vehicle_icon.svg";
import HealthIcon from "assets/img/savings-icons/health_icon.svg";
import OthersIcon from "assets/img/savings-icons/others_icon.svg";

export default function InitialStep({ setGoalName, goalName }) {
	return (
		<div className="create-emergency-savings-page__step">
			<h2>What do you want to save for?</h2>
			<p>Select a new savings plan to get started</p>

			<div className="cesp-grid">
				<Card
					icon={HouseIcon}
					title="Home Repairs"
					setGoalName={setGoalName}
					goalName={goalName}
				/>
				<Card
					icon={FamilyIcon}
					title="Family Emergency"
					setGoalName={setGoalName}
					goalName={goalName}
				/>
				<Card
					icon={VehicleIcon}
					title="Vehicle Issues"
					setGoalName={setGoalName}
					goalName={goalName}
				/>
				<Card
					icon={TruckIcon}
					title="Moving Expenses"
					setGoalName={setGoalName}
					goalName={goalName}
				/>
				<Card
					icon={HealthIcon}
					title="Health"
					setGoalName={setGoalName}
					goalName={goalName}
				/>
				<Card
					icon={OthersIcon}
					title="Others"
					setGoalName={setGoalName}
					goalName={goalName}
				/>
			</div>
		</div>
	);
}

const Card = ({ icon, title, setGoalName, goalName }) => {
	return (
		<div className="cesp-grid__card" onClick={() => setGoalName(title)}>
			<div
				className={`icon-containa ${
					title === goalName ? "selected" : ""
				}`}
			>
				<ReactSVG src={icon} />
			</div>
			<h3>{title}</h3>
		</div>
	);
};
