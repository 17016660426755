import ModalHeader from "components/utils/Modal/CustomModalHeader/CustomModalHeader";
import Modal from "components/utils/Modal/Modal";
import { ERROR, FINISHED, IDLE, LOADING } from "config/enums";
import React, { useState } from "react";
import "../../SpendAndSave.scss";
import { Formik } from "formik";
import * as yup from "yup";
import FormInputGroup from "components/utils/FormInputGroup/FormInputGroup";
import CustomRadioButton from "components/utils/CustomRadioButton/CustomRadioButton";
import CustomLargeBadge from "components/utils/CustomLargeBadge/CustomLargeBadge";
import { addDays, differenceInCalendarDays } from "date-fns";
import { getErrorMessage, parseAmount } from "components/utils/helpers";
import { SmallLoader } from "components/utils/SmallLoader";
import FormDatePickerGroup from "components/utils/FormDatePickerGroup/FormDatePickerGroup";
import { API } from "lib/api";
import { editSpendAndSave } from "lib/api/apiRoutesConfig/services/SavingsMS/savingsGoals/subRoutes";
import { ToastsStore } from "react-toasts";
import SASSuccessModal from "./SASSuccessModal";

const formValidationSchema = (isSavingsLocked) =>
	yup.object().shape({
		duration: yup.mixed().when("savingsLocked", {
			is: (value) => value === true && isSavingsLocked === false,
			then: yup
				.number()
				.required("Select a duration")
				.min(30, "Minimum duration is 30 days"),
			otherwise: yup.number(),
		}),
		savingsLocked: yup.boolean().default(true),
		transactionPercentage: yup
			.number()
			.required("Enter a percentage value")
			.min(1, "Minimum percentage is 1%"),
		customDate: yup.date(),
	});

export default function EditSASModal({
	show,
	onClose,
	refetch,
	savingsLocked,
}) {
	const [currentState, setCurrentState] = useState(IDLE);
	const [successMsg, setSuccessMsg] = useState("");

	const renderBasedOnState = () => {
		switch (currentState) {
			case FINISHED:
				return (
					<SASSuccessModal
						message={successMsg}
						onClose={() => {
							setCurrentState(IDLE);
							onClose();
							refetch();
						}}
					/>
				);

			default:
				return (
					<EditSASForm
						currentState={currentState}
						setSuccessMsg={(message) => setSuccessMsg(message)}
						setCurrentState={(state) => setCurrentState(state)}
						onClose={onClose}
						savingsLocked={savingsLocked}
					/>
				);
		}
	};

	return (
		<Modal show={show}>
			{currentState !== FINISHED && (
				<ModalHeader
					heading="Edit Spend and Save"
					className="p-0"
					headerClassName="setup-modals-header px-sm-5 px-3 py-3"
					dismissable={onClose}
				/>
			)}

			<>{renderBasedOnState()}</>
		</Modal>
	);
}

const EditSASForm = ({
	setSuccessMsg,
	currentState,
	setCurrentState,
	onClose,
	savingsLocked,
}) => {
	const [showDatePicker, setShowDatePicker] = useState(false);

	const initialFormValues = {
		duration: "",
		savingsLocked: true,
		transactionPercentage: "",
		customDate: undefined,
	};

	const resetCustomDate = (setFieldValue) => {
		setFieldValue("customDate", undefined);
		setShowDatePicker(false);
	};

	const handleEdit = async (values) => {
		setCurrentState(LOADING);
		try {
			const res = await API.post(editSpendAndSave, {
				...values,
				customDate: undefined,
			});
			if (res.status) {
				setCurrentState(FINISHED);
				setSuccessMsg(res.data.message);
			}
		} catch (err) {
			setCurrentState(ERROR);
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		}
	};

	return (
		<div className="sas-modal px-sm-5 px-3">
			<Formik
				initialValues={initialFormValues}
				validationSchema={formValidationSchema(savingsLocked)}
				onSubmit={(values) => handleEdit(values)}
			>
				{({
					values,
					errors,
					touched,
					handleSubmit,
					setFieldValue,
					handleChange,
					handleBlur,
					resetForm,
					setFieldTouched,
				}) => {
					return (
						<form
							className="py-3"
							onSubmit={(e) => {
								e.preventDefault();
								handleSubmit();
							}}
						>
							<p className="sas-form-info__large">
								Save this percentage on all transactions i.e.
								Fund transfers and Bill payments
							</p>

							<div
								className={`${savingsLocked ? "mb-5" : "mb-4"}`}
							>
								<FormInputGroup
									id="transactionPercentage"
									label="Enter percentage of transaction to be saved"
									name="transactionPercentage"
									placeholder="%"
									className="sas-input percentage"
									onBlur={handleBlur}
									value={values.transactionPercentage}
									onChange={(e) => {
										const value = e.target.value;
										setFieldValue(
											"transactionPercentage",
											parseAmount(value)
										);
									}}
									error={
										touched.transactionPercentage &&
										errors.transactionPercentage
									}
								/>
							</div>

							{!savingsLocked && (
								<>
									<div style={{ marginBottom: "50px" }}>
										<label>
											Would you like to safe lock your
											savings and earn interest?
										</label>
										<div className="d-flex align-items-center mt-2">
											<CustomRadioButton
												checked={
													values.savingsLocked ===
													true
												}
												name="savingsLocked"
												onChange={() =>
													setFieldValue(
														"savingsLocked",
														true
													)
												}
												label="Yes"
											/>
											<CustomRadioButton
												cls="ml-5"
												checked={
													values.savingsLocked ===
													false
												}
												name="savingsLocked"
												onChange={() => {
													setFieldValue(
														"savingsLocked",
														false
													);
													resetForm({
														values: {
															...values,
															savingsLocked: false,
															duration: "",
															transactionPercentage:
																values.transactionPercentage,
															customDate:
																undefined,
														},
													});
													setShowDatePicker(false);
												}}
												label="No"
											/>
										</div>
										{touched.savingsLocked &&
											errors.savingsLocked && (
												<span className="error-msg">
													{errors.savingsLocked}
												</span>
											)}
									</div>

									{values.savingsLocked && (
										<>
											<div className="mb-3">
												<label>Savings duration</label>
												<div className="sas-frequency">
													<CustomLargeBadge
														title="30 days"
														isSelected={
															values.duration ===
															30
														}
														onClick={() => {
															setFieldTouched(
																"customDate",
																false
															);
															setFieldValue(
																"duration",
																30
															);
															resetCustomDate(
																setFieldValue
															);
														}}
													/>
													<CustomLargeBadge
														title="60 days"
														isSelected={
															values.duration ===
															60
														}
														onClick={() => {
															setFieldTouched(
																"customDate",
																false
															);
															setFieldValue(
																"duration",
																60
															);
															resetCustomDate(
																setFieldValue
															);
														}}
													/>
													<CustomLargeBadge
														title="90 days"
														isSelected={
															values.duration ===
															90
														}
														onClick={() => {
															setFieldTouched(
																"customDate",
																false
															);
															setFieldValue(
																"duration",
																90
															);
															resetCustomDate(
																setFieldValue
															);
														}}
													/>
												</div>
												{touched.duration &&
													errors.duration && (
														<span className="error-msg">
															{errors.duration}
														</span>
													)}
											</div>

											<div
												style={{ marginBottom: "48px" }}
											>
												{!showDatePicker ? (
													<CustomLargeBadge
														title="Pick my own date"
														cls="w-100"
														onClick={() =>
															setShowDatePicker(
																true
															)
														}
													/>
												) : (
													<FormDatePickerGroup
														isClearable={false}
														placeholder="Select date"
														selected={
															values.customDate
														}
														onBlur={handleBlur}
														minDate={addDays(
															new Date(),
															30
														)}
														name="customDate"
														onChange={(date) => {
															setFieldValue(
																"customDate",
																date
															);
															setFieldValue(
																"duration",
																differenceInCalendarDays(
																	date,
																	new Date()
																)
															);
														}}
														error={
															touched.duration &&
															errors.duration
														}
													/>
												)}
											</div>
										</>
									)}
								</>
							)}

							<div className="sas-modal__footer mt-0">
								<button
									className="btn create-acc"
									disabled={currentState === LOADING}
									type="submit"
								>
									{currentState === LOADING ? (
										<SmallLoader />
									) : (
										"Activate"
									)}
								</button>

								<button
									className="btn btn-custom-bordered"
									type="button"
									onClick={onClose}
								>
									Cancel
								</button>
							</div>
						</form>
					);
				}}
			</Formik>
		</div>
	);
};
