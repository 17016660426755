import React from "react";
import "../DetailsModal/DetailsModal.scss";
import { ReactSVG } from "react-svg";
import TickIcon from "assets/img/tick_circle_icon.svg";
import CloseCircleIcon from "assets/img/close_circle_success.svg";
import { requestStatusEnums } from "../constants";

export default function PTASuccessModal({ message, requestStatus }) {
	const goToDashboard = () => (window.location.href = "/dashboard");

	return (
		<div className="pta-success-modal px-sm-5 px-3">
			<ReactSVG
				src={
					requestStatus === requestStatusEnums.DECLINE
						? CloseCircleIcon
						: TickIcon
				}
			/>

			<h2>
				{requestStatus === requestStatusEnums.DECLINE
					? "Declined"
					: "Congratulations!"}
			</h2>
			<p>{message}</p>

			<button className="btn btn-mint-default" onClick={goToDashboard}>
				Done
			</button>
		</div>
	);
}
