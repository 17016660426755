import React, { Component } from "react";
import { connect } from "react-redux";
import imageFiles from "../../../../../../common/imageFiles/imageFiles";
import { ReactSVG } from "react-svg";
import FundMintAccountModal from "../../../modals/FundMintAccount/FundMintAccount";
import { formatCurrency } from "../../../../../../utils/helpers";
import "./DashboardBalance.scss";
import { updateBalanceVisibility } from "actions/dasboardActions";
import RevealIcon from "assets/img/eye-show.png";
import HideIcon from "assets/img/eye-hide.png";

class DashboardBalance extends Component {
	state = {
		showModal: false,
	};

	toggleModal = () => {
		this.setState((prevState) => ({
			...prevState,
			showModal: !prevState.showModal,
		}));
	};

	toggleBalanceVisibility = () => {
		const payload = this.props.isBalanceHidden ? false : true;
		this.props.updateBalanceVisibility(payload);
	};

	render() {
		const { showModal } = this.state;
		const { bankAccounts, hideBalance, isBalanceHidden } = this.props;
		const { availableBalance, ledgerBalance } = bankAccounts
			? bankAccounts[0]
			: {};

		return (
			<>
				<section className="col-lg-6">
					<div className="balance-box">
						<div className="row">
							<div className="col-md-12">
								<div className="account-lead">
									<h6>Account Balance</h6>
									<div className="row">
										<div className="col-sm-7">
											<h2>
												{hideBalance ||
												isBalanceHidden
													? "₦XXX.XXX"
													: formatCurrency(
															availableBalance
													  )}
											</h2>
										</div>
										<div className="col-sm-5 px-sm-0">
											<div
												className="balance-toggle"
												role="button"
												onClick={
													this.toggleBalanceVisibility
												}
											>
												<img
													alt=""
													src={
														isBalanceHidden
															? HideIcon
															: RevealIcon
													}
												/>
												<span>Hide Balance</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="row mt-auto justify-content-between align-items-center">
							<div className="col-sm-6">
								<div className="ledger-balance">
									<h6>Ledger balance:</h6>
									<h4>
										{hideBalance || isBalanceHidden
											? "₦XXX.XXX"
											: formatCurrency(ledgerBalance)}
									</h4>
								</div>
							</div>
							<div className="col-sm-6 d-flex justify-content-sm-end">
								<button
									className="btn fundAcc-btn"
									onClick={this.toggleModal}
								>
									<ReactSVG
										src={imageFiles.whitePlusIcon}
										className="mr-2"
									/>{" "}
									<span>ADD MONEY</span>
								</button>
							</div>
						</div>
					</div>
				</section>

				{showModal && (
					<FundMintAccountModal
						show={showModal}
						dismissModal={this.toggleModal}
					/>
				)}
			</>
		);
	}
}

export default connect(
	({
		auth: { kickedOut },
		dashboard: { dashboardResponse, isBalanceHidden },
	}) => ({
		bankAccounts: dashboardResponse.bankAccounts,
		hideBalance: kickedOut,
		isBalanceHidden,
	}),
	{ updateBalanceVisibility }
)(DashboardBalance);
