export const ALL = "ALL";
export const PERSONAL_CART = "PERSONAL_CART";
export const GROUP_CART = "GROUP_CART";
export const SINGLE_ORDER_DELIVERY = "SINGLE_ORDER_DELIVERY"
export const JOINT_ORDER_DELIVERY = "JOINT_ORDER_DELIVERY"
export const PERSONAL_ORDER = "PERSONAL_ORDER"
export const GROUP_ORDER = "GROUP_ORDER"
export const INDIVIDUAL = "INDIVIDUAL"

export const checkIfProductExistsInCart = (cart = [], productCode) => {
	return cart.some((item) => item.product.productCode === productCode);
};

export const getExistingCartItemId = (cart = [], productCode) => {
	const existingCartItem = cart.find(
		(item) => item.product.productCode === productCode
	);

	const cartItemId = existingCartItem.id;
	return cartItemId;
};
