import React from "react";
import "./nctoCardActivation.scss";
import { Helmet } from "react-helmet";
import FadeIn from "components/dashboard/components/effects/FadeIn";
import PublicNavbar from "components/registerBusiness/components/navbar";
import BackButton from "components/utils/BackButton/BackButton";
import { useHistory, useLocation } from "react-router-dom";
import RouteContainer from "routes/RouteContainer";
import nctoCardActivationRoutes from "./nctoCardActivationRoutes";
import { useDispatch } from "react-redux";
import { updateColorScheme } from "actions/styleActions";

export default function NCTOCardActivation() {
	const history = useHistory();
	const dispatch = useDispatch();
	const location = useLocation();

	React.useEffect(() => {
		dispatch(updateColorScheme("light"));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Helmet>
				<title>
					{process.env.REACT_APP_NAME} - NCTO Card Activation
				</title>
			</Helmet>

			<FadeIn>
				<PublicNavbar>
					{location.pathname.split("/")[2] !== "activate-card" ? (
						<BackButton onClick={() => history.goBack()} />
					) : null}
				</PublicNavbar>

				<div className="auth-container">
					<main className="ncto-card-activation">
						<RouteContainer
							baseURL="/ncto-card-activation"
							routes={nctoCardActivationRoutes}
						/>
					</main>
				</div>
			</FadeIn>
		</>
	);
}
