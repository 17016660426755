import React from "react";
import "./SplitPayment.scss";
import { Helmet } from "react-helmet";
import FadeIn from "../effects/FadeIn";
import CustomTabs from "components/utils/CustomTabs/CustomTabs";
import { NavLink, Redirect, Route, useHistory, Switch } from "react-router-dom";
import requestRoutes, { defaultPath } from "./requestRoutes";

const SplitPayment = () => {
	const history = useHistory();

	const { location } = history;
	return (
		<>
			<Helmet>
				<title>{process.env.REACT_APP_NAME} - Split Payment</title>
			</Helmet>
			<FadeIn>
				<div className="pb-5 split-payment">
					<div className="mkt-back-button mb-4"></div>
					<div className="title-section">
						<div>
							<h1 className="page-title-text"> Split Payment</h1>
							<p className="page-lead-text">
								Perform split payments among Mintyn account
								holders here
							</p>
						</div>
						{location?.pathname ===
							`${defaultPath}/pending-requests` ||
						location?.pathname === `${defaultPath}/my-requests` ? (
							<button
								onClick={() => {
									history.push(
										"/split-payment/my-requests/start-split-payment"
									);
								}}
								className="btn btn-mint-default"
								type="button"
							>
								Start a Split Payment
							</button>
						) : (
							""
						)}
					</div>

					<CustomTabs className="mt-5">
						{requestRoutes.map(
							(route, index) =>
								!route.hide && (
									<li className="nav-item" key={index}>
										<NavLink
											className="nav-link"
											to={route.path}
										>
											{route.title}
										</NavLink>
									</li>
								)
						)}
					</CustomTabs>

					<div className="split-rquests-panel ">
						<Switch>
							{requestRoutes.map((route, index) => (
								<Route
									key={index}
									exact
									path={route.path}
									component={route.component}
								/>
							))}
							<Redirect
								path="*"
								to={`${defaultPath}/pending-requests`}
							/>
						</Switch>
					</div>
				</div>
			</FadeIn>
		</>
	);
};

export default SplitPayment;
