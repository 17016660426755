import PropTypes from "prop-types";
import React from "react";
import "./SuccessHeader.scss";
import { ReactSVG } from "react-svg";
import TickIcon from "assets/img/tick_circle_icon.svg";
import CautionIcon from "assets/img/caution_circle_icon.svg";
import SuccessImage from "components/utils/SuccessImage";

const SuccessHeader = ({ heading, paragraph, cls, hasNewIcon, statusCode }) => {
	return (
		<div className={`congrats-mssg ${cls}`}>
			{hasNewIcon ? (
				<ReactSVG src={statusCode === "00" ? TickIcon : CautionIcon} />
			) : (
				<SuccessImage />
			)}
			<div className="mt-4 px-3">
				<h6>{heading}</h6>
				<p>{paragraph}</p>
			</div>
		</div>
	);
};

SuccessHeader.propTypes = {
	heading: PropTypes.string,
	paragraph: PropTypes.string,
	statusCode: PropTypes.string,
};

export default SuccessHeader;
