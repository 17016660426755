import React from "react"
import "../../../MarketPlace/components/OrderHistory/CartView/CartView.scss";
import Logo from "../../components/utils/assets/m-logo-white.svg"
import LogoB from "../../components/utils/assets/m-logo-black.svg"

const MemberCard = ({ color}) => {
  const sLogo = color === 'black' ? LogoB : Logo;
  return (
    <div className="member-icon">
      <p> MX</p>
      <img
        src={sLogo}
        className="logo"
        alt="logo" />
    </div>
  )
}
export default MemberCard