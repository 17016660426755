import { UPDATE_CART,UPDATE_CARTTYPE,ALLOW_CONFIRMATION,SEARCH_PRODUCTS,UPDATE_DEBIT_ACCOUNT_ID,UPDATE_ADDRESS_ID } from "./types";

export const updateCart = (data) => (dispatch) => {
	dispatch({
		type: UPDATE_CART,
		payload: data,
	});
	
};

export const updateCartType = (data) => (dispatch) => {
	dispatch({
		type: UPDATE_CARTTYPE,
		payload: data,
	});
};

export const allowConfirmation = (data) => (dispatch) => {
	dispatch({
		type: ALLOW_CONFIRMATION,
		payload: data,
	});
};

export const searchProduct = (data) => (dispatch) => {
	dispatch({
		type: SEARCH_PRODUCTS,
		payload: data,
	});
};

export const updateDeliveryAddressId = (data) => (dispatch) => {
	dispatch({
		type: UPDATE_ADDRESS_ID,
		payload: data,
	});
};
export const updateDebitAccoutId = (data) => (dispatch) => {
	dispatch({
		type: UPDATE_DEBIT_ACCOUNT_ID,
		payload: data,
	});
};
