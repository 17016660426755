import React, { Fragment } from "react";
import routes from "./routes";
import RouteContainer from "../../../routes/RouteContainer";

const ResetSecurityDetailsWrapper = () => (
	<Fragment>
		<RouteContainer baseURL="/reset-security-details" routes={routes} />
	</Fragment>
);

export default ResetSecurityDetailsWrapper;
