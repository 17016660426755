import React, { Component } from "react";
import { LOADING, ERROR, FINISHED } from "../../../../../../config/enums";
import { MintInfinite } from "../../../../utils/ShineLoader/ShineLoader";
import FailedTryAgain from "../../../../utils/FailedTryAgain/FailedTryAgain";
import FailedImage from "../../../../../utils/FailedImage";
import BillsManagerLists from "./BillsManagerLists/BillsManagerLists";
import { API } from "../../../../../../lib/api";
import { performScheduleBillsPayment } from "../../../../../../lib/api/apiRoutesConfig/services/BillsService/subRoutes";
import { getErrorMessage } from "../../../../../utils/helpers";
import Axios from "axios";
import FadeIn from "../../../effects/FadeIn";

class BillsManagerListWrapper extends Component {
	_isMounted = false;
	signal = Axios.CancelToken.source();

	state = {
		currentState: LOADING,
		bills: [],
	};

	componentDidMount = () => {
		this._isMounted = true;
		this.fetchAllScheduleBills();
	};

	componentWillUnmount = () => {
		this.signal.cancel("canceling network calls");
		this._isMounted = false;
	};

	fetchAllScheduleBills = async () => {
		try {
			this.setState({ currentState: LOADING });
			const res = await API(performScheduleBillsPayment, {
				cancelToken: this.signal.token,
			});
			if (res.status === 200) {
				const allCategories = res.data.data;

				this._isMounted &&
					this.setState({
						currentState: FINISHED,
						bills: allCategories,
						allCategories,
					});
			}
		} catch (err) {
			this._isMounted &&
				this.setState({
					currentState: ERROR,
					fetchingError: getErrorMessage(err),
				});
		}
	};

	renderBasedOnState = () => {
		const { currentState } = this.state;
		switch (currentState) {
			case LOADING:
				return (
					<div className="py-5">
						<MintInfinite relative />
					</div>
				);

			case ERROR:
				const { fetchingError } = this.state;
				return (
					<div className="row justify-content-center py-5">
						<FailedImage />
						<FailedTryAgain
							errorMssg={fetchingError}
							handleTryAgain={this.fetchAllScheduleBills}
						/>
					</div>
				);

			case FINISHED:
				const { bills } = this.state;
				return (
					<div className="row justify-content-center py-5 ">
						<div className="col-md-7">
							<BillsManagerLists
								bills={bills}
								refetch={this.fetchAllScheduleBills}
							/>
						</div>
					</div>
				);

			default:
				return "";
		}
	};

	render() {
		return <FadeIn>{this.renderBasedOnState()}</FadeIn>;
	}
}

export default BillsManagerListWrapper;
