import React, { Component } from "react";
import Helmet from "react-helmet";
import FadeIn from "../../effects/FadeIn";
import AcctNameEnquiryInput from "../AcctNameEnquiryInput/AcctNameEnquiryInput";
import BankInputSelect from "../BankInputSelect/BankInputSelect";
import TranscationAmountInput from "../../transcations/TranscationAmountInput";
import SpendingTags from "../../essentials/SpendingTags/SpendingTags";
import FundsTransferForm from "../modals/FundsTransferForm";
import config from "../../../../../config";
import ChooseBeneficiaryModal from "../modals/ChooseBeneficiaryModal/ChooseBeneficiaryModal";
import { SAMEBANK, OTHER_BANKS } from "../../../../../config/enums";
import "./FTransfer.scss";
import ChevronDown from "../../../../../assets/img/chevron-down.svg";

export default class FTransfer extends Component {
	_isMounted = false;
	transAmount = React.createRef();

	state = {
		selectedBank: { value: "" },
		newRequest: {
			amount: 0,
			recipientAccountNumber: "",
			recipientAccountName: "",
			recipientBankCode: "",
			spendingTagCode: "",
			narration: "",
			saveBeneficiary: false,
		},
		errors: {},
		showTranferModal: false,
		validAcc: false,
		setting: true,
	};

	componentDidMount = () => {
		this._isMounted = true;
		const { accountType, availableBeneficiary } = this.props;
		if (availableBeneficiary) {
			this.acceptNewBankDetails(availableBeneficiary);
		} else {
			this.setState((prevState) => ({
				...prevState,
				newRequest: {
					...prevState.newRequest,
					recipientBankCode:
						accountType === SAMEBANK ? config.MINT_BANK_CODE : "",
				},
				setting: false,
			}));
		}
	};

	componentWillUnmount = () => {
		this._isMounted = false;
	};

	acceptNewBankDetails = (existingAccount) => {
		const {
			accountName: recipientAccountName,
			accountNumber: recipientAccountNumber,
			beneficiaryId,
			bankCode,
			bankName,
		} = existingAccount;

		this._isMounted &&
			this.setState(
				(prevState) => ({
					newRequest: {
						...prevState.newRequest,
						recipientAccountNumber,
						recipientAccountName,
						recipientBankCode: bankCode,
						beneficiaryId,
						saveBeneficiary: true,
					},
					selectedBank: { value: bankCode, label: bankName },
					showBeneficiaryModal: false,
					setting: false,
					errors: {
						...prevState.errors,
						recipientAccountNumber: false,
					},
				}),
				() =>
					this.showAccountName(
						recipientAccountName,
						recipientAccountNumber
					)
			);
	};

	showAccountName = (accountName, accountNumber) => {
		this._isMounted &&
			this.setState((prevState) => ({
				...prevState,
				newRequest: {
					...prevState.newRequest,
					recipientAccountName: accountName,
					recipientAccountNumber:
						accountNumber || prevState.newRequest.accountNumber,
				},
				validAcc: true,
			}));
	};

	handleAcctNotFound = () => {
		this._isMounted && this.setState({ validAcc: false });
	};

	handleCheckBox = () => {
		this._isMounted &&
			this.setState((prevState) => ({
				...prevState,
				newRequest: {
					...prevState.newRequest,
					saveBeneficiary: !prevState.newRequest.saveBeneficiary,
				},
			}));
	};

	handleAmountUpdate = (amount) => {
		this._isMounted &&
			this.setState((prevState) => ({
				...prevState,
				errors: {
					...prevState.errors,
					amount: false,
				},
				newRequest: {
					...prevState.newRequest,
					amount: parseFloat(amount.replace(/,/g, "")),
				},
			}));
	};

	handleBankSelect = (selected, { name }) => {
		const { value } = selected;
		this._isMounted &&
			this.setState((prevState) => ({
				...prevState,
				errors: {
					...prevState.errors,
					[name]: false,
				},
				newRequest: {
					...prevState.newRequest,
					[name]: value,
				},
				selectedBank: selected,
			}));
	};

	handleTagSelect = (tagID) => {
		this._isMounted &&
			this.setState((prevState) => ({
				...prevState,
				newRequest: {
					...prevState.newRequest,
					spendingTagCode: tagID,
				},
			}));
	};

	handleInput = (e) => {
		let { name, value } = e.target;
		this._isMounted &&
			this.setState((prevState) => ({
				...prevState,
				errors: {
					...prevState.errors,
					[name]: false,
				},
				newRequest: {
					...prevState.newRequest,
					[name]: value,
				},
			}));
	};

	toggleBenefiary = () => {
		this._isMounted &&
			this.setState({
				showBeneficiaryModal: true,
			});
	};

	handleSubmit = (e) => {
		e.preventDefault();
		this._isMounted &&
			this.setState({
				showTranferModal: true,
			});
	};

	canBeSubmitted = () => {
		const {
			newRequest: { amount },
			validAcc,
		} = this.state;
		return amount >= 100 && validAcc;
	};

	render = () => {
		const isEnabled = this.canBeSubmitted();
		const {
			newRequest,
			selectedBank,
			validAcc,
			showTranferModal,
			showBeneficiaryModal,
			setting,
			errors,
		} = this.state;
		const {
			recipientAccountNumber,
			recipientAccountName,
			narration,
			recipientBankCode,
			spendingTagCode,
			saveBeneficiary,
		} = newRequest;
		const { accountType } = this.props;

		if (setting) return "working...";

		return (
			<>
				<Helmet>
					<title>{process.env.REACT_APP_NAME} - Send Money</title>
				</Helmet>
				<>
					<form onSubmit={this.handleSubmit} className="ft-form">
						<div className="form-row mb-4">
							<div className="col-md-12">
								<span
									className="select-beneficiary-button"
									onClick={this.toggleBenefiary}
									role="button"
								>
									Choose Beneficiary
									<img src={ChevronDown} alt="" />
								</span>
							</div>
						</div>

						{accountType === OTHER_BANKS && (
							<BankInputSelect
								inputName="recipientBankCode"
								handleSelect={this.handleBankSelect}
								selectedBank={selectedBank}
							/>
						)}

						<div className="form-group mb-4">
							<div className="form-row">
								<div className="col-md-12">
									<label>Recipient Account</label>
									<AcctNameEnquiryInput
										bankCode={recipientBankCode}
										updateValue={this.handleInput}
										onSuccess={this.showAccountName}
										onFailure={this.handleAcctNotFound}
										handleAcctNumberDel={
											this.handleAcctNotFound
										}
										defaultValue={recipientAccountNumber}
										accountType={accountType}
										selectedBank={selectedBank}
									/>
								</div>
							</div>
						</div>

						{validAcc && (
							<>
								<FadeIn>
									<div>
										<div className="form-group mb-4">
											<div className="form-row">
												<div className="col-12">
													<label>Account Name</label>
												</div>
											</div>
											<input
												name="recipientAccountName"
												type="text"
												className="form-control bolden ft-recipient-input"
												maxLength={50}
												value={recipientAccountName}
												readOnly={true}
												placeholder=""
												autoComplete="off"
											/>
										</div>

										<TranscationAmountInput
											updateAmount={
												this.handleAmountUpdate
											}
											ref={this.transAmount}
											label={"Amount"}
											cls="mb-4"
										/>

										<div className="form-group mb-4">
											<div className="form-row">
												<div className="col-12">
													<label>Description</label>
												</div>
											</div>
											<input
												name="narration"
												type="text"
												className={
													"form-control ft-recipient-input " +
													(errors["narration"]
														? "has-error"
														: "")
												}
												maxLength={50}
												onChange={this.handleInput}
												value={narration}
												placeholder="Enter Description"
												autoComplete="off"
											/>
										</div>
										<SpendingTags
											currentTag={spendingTagCode}
											handleTagSelect={
												this.handleTagSelect
											}
										/>

										<div className="add-as-beneficiary w-100 fade-in my-4">
											<div className="custom-control custom-checkbox flex-star">
												<input
													type="checkbox"
													className="custom-control-input"
													id="saveBeneficiary"
													name="saveBeneficiary"
													onChange={
														this.handleCheckBox
													}
													checked={saveBeneficiary}
												/>
												<label
													className="custom-control-label"
													htmlFor="saveBeneficiary"
												>
													Add
													<strong>
														{` ${recipientAccountName} `}
													</strong>
													as beneficiary
												</label>
											</div>
										</div>
									</div>
								</FadeIn>
							</>
						)}

						<div className="mt-2">
							<button
								className="btn create-acc ft-submit-btn"
								disabled={!isEnabled}
							>
								Continue
							</button>
						</div>
					</form>
					<div className="pt-5"></div>
				</>

				{showBeneficiaryModal && (
					<ChooseBeneficiaryModal
						accountType={accountType}
						show={showBeneficiaryModal}
						toggleModal={() =>
							this.setState({ showBeneficiaryModal: false })
						}
						acceptBankDetails={this.acceptNewBankDetails}
					/>
				)}

				{showTranferModal && (
					<FundsTransferForm
						show={showTranferModal}
						transcationRequest={newRequest}
						selectedBank={selectedBank}
						toggleModal={() =>
							this.setState({ showTranferModal: false })
						}
					/>
				)}
			</>
		);
	};
}
