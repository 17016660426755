import React from "react";
import { useSelector } from "react-redux";
import {
	SOLE_PROPRIETORSHIP,
	BUSINESS_DOCUMENTATION,
	DIRECTORS_DOCUMENTATION,
	TRUSTEES_DOCUMENTATION,
	FAILED,
	PENDING,
	I_AM_DIRECTOR,
	I_AM_TRUSTEE,
	VERIFIED,
} from "../../../../../../config/enums";
import { ACCOUNT_SETUP_DOCUMENTS } from "../constants";
import "./StepCard.scss";

export default function StepCard({
	className,
	title,
	description,
	CTAAction,
	documentationType,
	isDisabled,
	status,
	directorTypeSelection,
}) {
	const { activeAccountType, dashboardResponse, isLoggedIn } = useSelector(
		(state) => ({
			activeAccountType: state.auth.activeAccountType,
			dashboardResponse: state.dashboard.dashboardResponse,
			isLoggedIn: state.auth.isLoggedIn,
		})
	);

	// check if user is director or trustee
	const isUserDirector =
		directorTypeSelection === I_AM_DIRECTOR ||
		directorTypeSelection === I_AM_TRUSTEE;

	const { businessSetUp, directorSetUp, trusteeSetup } =
		dashboardResponse?.setUpDetail;

	const currentStepCount = () => {
		let steps = [];

		if (documentationType === BUSINESS_DOCUMENTATION) {
			steps = ACCOUNT_SETUP_DOCUMENTS[documentationType].filter(
				(field) => businessSetUp?.[field] === true
			);
		}

		if (documentationType === DIRECTORS_DOCUMENTATION) {
			steps = ACCOUNT_SETUP_DOCUMENTS[documentationType].filter(
				(field) => directorSetUp?.[field] === true
			);
		}

		if (documentationType === TRUSTEES_DOCUMENTATION) {
			steps = ACCOUNT_SETUP_DOCUMENTS[documentationType].filter(
				(field) => trusteeSetup?.[field] === true
			);
		}

		if (isUserDirector) {
			// total step count for director and trustee documentation is 3
			return 3;
		}

		return steps.length;
	};

	const totalStepCount = () => {
		switch (documentationType) {
			case BUSINESS_DOCUMENTATION:
				if (activeAccountType === SOLE_PROPRIETORSHIP) {
					return 3;
				} else {
					return 4;
				}
			default:
				return 3;
		}
	};

	if (!isLoggedIn) return null;

	return (
		<div className={`col-lg-6 ${className ? className : ""}`}>
			<div
				className={`step-card ${
					isDisabled ? "step-card-disabled" : ""
				}`}
			>
				<span
					className="btn btn-setup-step d-flex align-items-center justify-content-center"
					style={{ cursor: "text" }}
				>
					{documentationType === BUSINESS_DOCUMENTATION
						? "Step 1"
						: "Step 2"}
				</span>

				<h5>{title}</h5>
				<p>{description}</p>

				{status !== FAILED && (
					<span className="step-count">
						{currentStepCount()} of {totalStepCount()} Done
					</span>
				)}

				{status === FAILED && (
					<>
						<span className="step-card-status failed mb-2">
							Verification Failed
						</span>
						<span className="mb-5 failed-info">
							Verification of some of the information you
							submitted failed. Please reupload documents.
						</span>
					</>
				)}

				{currentStepCount() === totalStepCount() &&
				status === VERIFIED ? (
					<span className="step-card-status verified">Verified</span>
				) : (currentStepCount() === totalStepCount() &&
						status !== FAILED) ||
				  status === PENDING ? (
					<span className="step-card-status">
						Pending Verification
					</span>
				) : (
					<button
						className="btn btn-mint-default"
						onClick={() => CTAAction()}
						disabled={isDisabled}
					>
						{status === FAILED ? "Reupload" : "Complete"}
					</button>
				)}
			</div>
		</div>
	);
}
