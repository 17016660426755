import React, { useState } from "react";
import InvestmentTransactions from "../../InvestmentTransactions/InvestmentTransactions";
import SingleActiveInvestment from "./SingleActiveInvestment";

const ActiveInvestmentsContent = ({ data }) => {
	const { records } = data?.investments || {};
	const { code } = records?.length ? records[0] : {};

	const [investmentId, setInvestmentId] = useState(code);

	return (
		<>
			<div className="d-flex overflow-auto">
				{records?.map((investment, i) => (
					<SingleActiveInvestment
						count={i + 1}
						key={i}
						investment={investment}
						investmentId={investmentId}
						setInvestmentId={setInvestmentId}
					/>
				))}
			</div>
			<InvestmentTransactions investment={{ code: investmentId }} />
		</>
	);
};

export default ActiveInvestmentsContent;
