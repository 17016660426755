/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Modal from "components/utils/Modal/Modal";
import SuccessImage from "components/utils/SuccessImage";
import { useHistory } from "react-router-dom";
import { formatCurrency } from "components/utils/helpers";
import { defaultPath } from "../../requestRoutes";
import moment from "moment/moment";

const Accepted = ({ show, onClose }) => {
	return (
		<>
			<Modal show={show} scrollable>
				<Content onClose={onClose} />
			</Modal>
		</>
	);
};
export default Accepted;

const Content = ({ onClose }) => {
	const history = useHistory();
	const { location } = history;

	return (
		<div className="request-accepted-modal">
			<div className=" content">
				<div className="icon">
					<SuccessImage />
				</div>
				<h4>Congratulations!</h4>
				<p> Approved by Financial Institution</p>
			</div>
			<ContentData data={location.state.data} />
			<button
				onClick={() => {
					history.push(`${defaultPath}/pending-requests`);
				}}
				className="btn btn-mint-default"
				type="button"
			>
				Close
			</button>
		</div>
	);
};

const ContentData = ({ data }) => {
	const currentDate = moment(new Date()).format("DD/MM/YYYY, h:mm a");
	return (
		<div className="request-accepted-modal">
			<div className=" content-data">
				<p className="title">Amount </p>
				<p className=" desc">{formatCurrency(data.amount)}</p>
			</div>
			<div className=" content-data">
				<p className="title">Recipient's Name</p>
				<p className=" desc">{data.sender}</p>
			</div>
			<div className=" content-data">
				<p className="title">Date and Time</p>
				<p className=" desc">{currentDate}</p>
			</div>
		</div>
	);
};
