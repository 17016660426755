import React from "react";
import queryString from "query-string";
import { connect } from "react-redux";
import {
	nextStep,
	previousStep,
} from "../../../../actions/waybillComfirmation";
import VerififedWaybillDetail from "./VerififedWaybillDetail/VerififedWaybillDetail";
import FadeIn from "../../../dashboard/components/effects/FadeIn";

const VerififedWaybillDetails = ({
	history,
	nextStep,
	previousStep,
	waybillConfirmation,
}) => {
	const { token } = queryString.parse(history.location.search);
	const { waybill } = waybillConfirmation[token] || {};
	const { orderDetails } = waybill || {};

	const render = () => {
		if (orderDetails?.length === 0 || orderDetails === undefined)
			return (
				<div className="step-box col-lg-4 col-md-6 col-sm-8 col-10 mx-auto px-0">
					<p
						style={{ fontSize: "18px" }}
						className="wording py-5 w-100 text-center"
					>
						No Waybill Found
					</p>
				</div>
			);

		return (
			<>
				{orderDetails?.map((orderDetail, i) => (
					<VerififedWaybillDetail
						key={i}
						orderDetail={orderDetail}
						token={token}
						nextStep={nextStep}
					/>
				))}
			</>
		);
	};

	return (
		<FadeIn>
			<div className="col-10 py-5 position-relative">
				<div
					className="position-absolute pt-2 ml-2 mt-md-0 mt-n4"
					onClick={() => previousStep(token)}
				>
					<span className="bigback__btn--link">Go Back</span>
				</div>

				<h4 className="dashboardIntro__heading font-weight-bold mb-5 mt-md-0 mt-3 text-center">
					Waybill information
				</h4>

				<div className="row justify-content-between">{render()}</div>
			</div>
		</FadeIn>
	);
};

export default connect(({ waybillConfirmation }) => ({ waybillConfirmation }), {
	nextStep,
	previousStep,
})(VerififedWaybillDetails);
