import CustomCurrencyInput from "components/utils/CustomCurrencyInput/CustomCurrencyInput";
import FormInputGroup from "components/utils/FormInputGroup/FormInputGroup";
import React, { useState } from "react";
import "../../BillPayment.scss";
import RemitaViaRRRSummary from "./PayViaRRRSummary";

export default function ReviewPayment({ transaction }) {
	const [isSummaryShown, setIsSummaryShown] = useState(false);

	const { rrr, payerEmail, payerName, payerPhone, description, totalAmount } =
		transaction || {};

	return (
		<>
			<h2>Review Payment</h2>
			<p>Please review your transaction below</p>

			<div className="bp-categories__form review-remita">
				<div className="mb-4">
					<FormInputGroup
						className="bp-input"
						label="RRR Number"
						disabled
						value={rrr}
					/>
				</div>

				<div className="mb-4">
					<FormInputGroup
						className="bp-input"
						label="Payer Email"
						disabled
						value={payerEmail}
					/>
				</div>

				<div className="mb-4">
					<FormInputGroup
						className="bp-input"
						label="Payer Name"
						disabled
						value={payerName}
					/>
				</div>

				<div className="mb-4">
					<FormInputGroup
						className="bp-input"
						label="Payer Phone"
						disabled
						value={payerPhone}
					/>
				</div>

				<div className="mb-4">
					<FormInputGroup
						className="bp-input"
						label="Description (Payee)"
						disabled
						value={description}
					/>
				</div>

				<div className="mb-4">
					<CustomCurrencyInput
						className="bp-input"
						label="Amount Due"
						disabled
						value={totalAmount}
					/>
				</div>

				<button
					className="btn btn-mint-default mt-2"
					type="button"
					onClick={() => setIsSummaryShown(true)}
				>
					Submit
				</button>
			</div>

			<RemitaViaRRRSummary
				show={isSummaryShown && !!transaction}
				transaction={transaction}
				onClose={() => setIsSummaryShown(false)}
			/>
		</>
	);
}
