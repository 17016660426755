import accountSetup, {
	tinApplication,
	tinApplicationV2,
	directorSignatory,
	trusteeMgt,
} from "..";
export const {
	identityCard,
	businessDocument,
	taxIdentificationNumber,
	submitTinApplication,
	tinGeneratePaymentRef,
	tinVerifyPayment,
	uploadMoaDocument,
	createDirectory,
	uploadDirectorPersonalInfo,
	uploadDirectorGovtId,
	uploadDirectorPhoto,
	createTrustee,
	uploadTrusteePersonalInfo,
	uploadTrusteeGovtId,
	uploadTrusteePhoto,
} = {
	identityCard: (accountId) => `${accountSetup}/${accountId}/identity-card`,
	businessDocument: (accountId) =>
		`${accountSetup}/${accountId}/upload-business-document`,
	taxIdentificationNumber: (accountId) => `${accountSetup}/${accountId}/tin`,
	submitTinApplication: (accountId) =>
		`${tinApplicationV2}/${accountId}/submit-document`,
	tinGeneratePaymentRef: (sessionId) =>
		`${tinApplication}/${sessionId}/generate-payment-ref`,
	tinVerifyPayment: (sessionId) =>
		`${tinApplication}/${sessionId}/verify-payment`,
	uploadMoaDocument: (accountId) => `${accountSetup}/${accountId}/upload-moa`,
	createDirectory: `${directorSignatory}/me`,
	uploadDirectorPersonalInfo: `${directorSignatory}/personal-info`,
	uploadDirectorGovtId: `${directorSignatory}/government-id`,
	uploadDirectorPhoto: `${directorSignatory}/photo-image`,
	createTrustee: `${trusteeMgt}/me`,
	uploadTrusteePersonalInfo: `${trusteeMgt}/personal-info`,
	uploadTrusteeGovtId: `${trusteeMgt}/government-id`,
	uploadTrusteePhoto: `${trusteeMgt}/photo-image`,
};
