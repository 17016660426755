import React, { useState } from "react";
import queryString from "query-string";
import { connect } from "react-redux";
import { API } from "../../../../lib/api";
import { getWaybillOrderDetails } from "../../../../lib/api/apiRoutesConfig/services/tradeMS/common/subRoutes/waybill";
import { getErrorMessage } from "../../../utils/helpers";
import {
	nextStep,
	previousStep,
	updateOrderDetails,
} from "../../../../actions/waybillComfirmation";
import Alert from "../../../utils/Alert";
import { SmallLoader } from "../../../utils/SmallLoader";
import FadeIn from "../../../dashboard/components/effects/FadeIn";
import FormInputGroup from "../../../utils/FormInputGroup/FormInputGroup";

const CustomerOrderConfirmation = ({
	history,
	waybillConfirmation,
	nextStep,
	previousStep,
	updateOrderDetails,
}) => {
	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState({ alert_newkey: 1 });
	const [deliveryCode, setDeliveryCode] = useState();

	const { token } = queryString.parse(history.location.search);
	const { waybill } = waybillConfirmation[token] || {};
	const { validationCode } = waybill || {};

	const handleChange = ({ target: { value } }) => {
		let numbers = value.replace(/[^0-9]/g, "");
		setDeliveryCode(numbers);
	};

	const confirmCustomerOrder = async () => {
		try {
			setLoading(true);
			const res = await API.get(getWaybillOrderDetails, {
				params: { deliveryCode, validationCode },
			});

			if (res.status === 200) {
				const resData = res.data.data;
				const order = { ...resData, deliveryCode };
				updateOrderDetails({ token, order });
				setLoading(false);
				nextStep(token);
			}
		} catch (err) {
			setLoading(false);
			setError(getErrorMessage(err));
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		confirmCustomerOrder();
	};

	const setError = (errMsg) => {
		setLoading(false);
		setAlert((prev) => ({
			alert_newkey: prev.alert_newkey + 1,
			alert_show: "show",
			alert_message: errMsg,
			alert_type: "danger",
		}));
	};

	const previous = (e) => {
		e.preventDefault();
		previousStep(token);
	};

	return (
		<FadeIn>
			<div className="col-lg-4 col-md-6 col-sm-8 col-10 ">
				<div className="py-5">
					<h4
						style={{ fontSize: "18px" }}
						className="dashboardIntro__heading font-weight-bold mb-5 text-center"
					>
						Customer Order Confirmation
					</h4>

					<Alert
						key={alert?.alert_newkey}
						type={alert?.alert_type}
						message={alert?.alert_message}
						show={alert?.alert_show}
					/>

					<form onSubmit={handleSubmit}>
						<div className="low-form mt-4">
							<div className="form-group">
								<FormInputGroup
									name="deliveryCode"
									label="Delivery Code"
									placeholder="Enter code e.g 09898789"
									value={deliveryCode}
									onChange={handleChange}
									required
								/>
							</div>

							<div className="my-4 d-flex">
								<button
									className="btn btn-custom-bordered mr-2"
									onClick={previous}
								>
									Previous
								</button>
								<button
									className="btn create-acc ml-2"
									disabled={loading}
								>
									{loading ? <SmallLoader /> : "Continue"}
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</FadeIn>
	);
};

export default connect(({ waybillConfirmation }) => ({ waybillConfirmation }), {
	nextStep,
	previousStep,
	updateOrderDetails,
})(CustomerOrderConfirmation);
