/* eslint-disable react-hooks/exhaustive-deps */
import React,{ useState,useEffect} from "react";
import "../../MarketPlace.scss";
import CustomSearchInput from "components/utils/CustomSearchInput/CustomSearchInput";
import { TopNavIconList } from "./exportData";
import { updateCartType,searchProduct } from "actions/marketPlace";
import { ReactSVG } from "react-svg";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { GROUP_CART } from "./constant";
import { useHistory } from "react-router-dom";
import useDebounce  from "./hooks/useDebounce";
import { getAllProducts } from "lib/api/apiRoutesConfig/services/tradeMS/individualTrade/subRoutes";
import { API } from "lib/api";
import { IDLE, LOADING, FINISHED, ERROR } from "config/enums";
import { getErrorMessage } from "components/utils/helpers";
import { ToastsStore } from "react-toasts";
import FailedTryAgain from "components/dashboard/utils/FailedTryAgain/FailedTryAgain";

export const MktTopNav = ({ cartType }) => {
	const history = useHistory()
	const dispatch = useDispatch();
	const { cart,searchTerm } = useSelector((state) => state.marketPlace);
	const { groupCart, personalCart } = cart || {};
	const currentCart = cartType === GROUP_CART ? groupCart : personalCart;
	const { cartItems } = currentCart || {};
	const [searchSuggestions, setSearchSuggestions] = useState ([]);
	const [productStatus, setProductStatus] = useState(IDLE);
	const [productsErrorMsg, setProductsErrorMsg] = useState("");
	const debouncedSearch = useDebounce(searchTerm, 500);
	

	// fetch searched products
	const fetchAllProducts = async () => {
		setProductStatus(LOADING);
		setProductsErrorMsg("");
		try {
			const response = await API.get(getAllProducts, {
				params: {
					searchTerm,
				},
			});
			const { data, status } = response || {};
      if (status === 200) {
        console.log(data.data)
				setSearchSuggestions(data.data.records);
				setProductStatus(FINISHED);
			}
		} catch (err) {
			setSearchSuggestions(null);
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
			setProductStatus(ERROR);
			setProductsErrorMsg(getErrorMessage(err));
		}
	};

  const initiateSearchProducts = async () => {
    if (debouncedSearch.trim() === '') {
     setSearchSuggestions([])
		} else {
			fetchAllProducts()
		}
	};

	useEffect(() => {
		dispatch(updateCartType(cartType));
	}, [cartType, dispatch])

	useEffect(() => {
		initiateSearchProducts()
	}, [debouncedSearch])

		const renderBasedOnStatus = () => {
		switch (productStatus) {
			case LOADING:
				return (
					<div className=" suggestions col-xl-8 ">
					<h6 className="">Loading...</h6>
					</div>
				);

			case ERROR:
				return (
					<div className=" suggestions col-xl-8 ">
						<FailedTryAgain
							errorMssg={productsErrorMsg}
						/>
					</div>
				);

			case FINISHED:
				if (searchSuggestions?.length <= 0)
					return <>
						{
					searchTerm
					&& <div className=" suggestions col-xl-8 ">
						<h6 className="">No Product Found</h6>
				
					</div>
						}
					</>
				
			

				return (
					<>
						{searchTerm
						&& <div className=" suggestions col-xl-8 ">
					<>
								{searchSuggestions && searchSuggestions.map((list, index) => (
									<li
										key={index}
										onClick={() => {
											history.push(
												"/market-place/personal-shopping/search",
												{ searchTerm: list.name, cartType });
										}}
										className=" result-list">
										{list.name}
									</li>
								))
									
									}
							{searchSuggestions?.length>10 &&(<button
							type="button"
							className="view-all-results"
						>
							View all results

							</button>)}
					</>
					</div>}

					</>
				);

			default:
				return null;
		}
	};


	return (
		<>
			<div className=" d-flex flex-row mb-5 topNav ">
				<div className=" d-flex flex-column flex-grow-1">
					<div className="mkt-search-wrapper col-xl-8 d-flex flex-row p-0">
						<>
							<CustomSearchInput
							name="search"
							value={searchTerm}
							className="mkt-search-input"
							placeholder="Find a product"
							onChange={(e) => dispatch(searchProduct(e.target.value))}
							onKeyPress={(e) => e.key === 'Enter' && 	history.push(
							"/market-place/personal-shopping/search",
								{ searchTerm: searchTerm, cartType } )}
						/>
						<button
							className="btn btn-mint-default search-btn"
							type="button"
							disabled={searchTerm ===""? true :false }
							onClick={() => {
								history.push(
									"/market-place/personal-shopping/search",
									{ searchTerm: searchTerm, cartType });
							}}
						>
							Search
						</button>
						</>
					</div>
					{renderBasedOnStatus()}
				</div>
				<div className=" d-flex flex-row icon-container">
					{TopNavIconList &&
						TopNavIconList.map((item, index) => (
							<div
								key={index}
								className="mkt-top-nav-icon-container"
							>
								<NavLink
									className="mkt-top-nav-icon"
									to={{
										pathname: item.path,
										state: { cartType },
									}}
								>
									<ReactSVG src={item.src} />
								</NavLink>
								{item.name === "Cart" && (
									<span>{cartItems?.length || 0}</span>
								)}
							</div>
						))}
				</div>
			</div>
		</>
	);
};
