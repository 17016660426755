import React from "react";
import { ReactSVG } from "react-svg";
import "../../SpendAndSave.scss";
import TickIcon from "assets/img/tick_circle_icon.svg";

export default function SASSuccessModal({
	message,
	buttonLabel = "Done",
	onClose,
}) {
	return (
		<div className="sas-success-modal">
			<ReactSVG src={TickIcon} />

			<h3>Congratulations!</h3>
			<p>{message}</p>

			<button className="btn btn-mint-default" onClick={onClose}>
				{buttonLabel}
			</button>
		</div>
	);
}
