/* eslint-disable no-mixed-operators */
import FadeIn from "components/dashboard/components/effects/FadeIn";
import FailedTryAgain from "components/dashboard/utils/FailedTryAgain/FailedTryAgain";
import { MintInfinite } from "components/dashboard/utils/ShineLoader/ShineLoader";
import BackButton from "components/utils/BackButton/BackButton";
import { ERROR, FINISHED, LOADING } from "config/enums";
import React from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import CartItemCard from "../CartItemCard/CartItemCard";
import CartSummary from "../CartSummary/CartSummary";
import { GROUP_CART } from "../utils/constant";
import useFetchCart from "../utils/hooks/useFetchCart";
import { MktTopNav } from "../utils/MktTopNav";
import "./ShoppingCart.scss";
import { ReactSVG } from "react-svg";
import PShopping from "../utils/assets/personal_shopping_icon.svg";

export default function ShoppingCart() {
	const location = useLocation();
	const history = useHistory();
	const { cartType } = location.state || {};
	const { handleFetchCart, currentStatus, errorMsg } = useFetchCart();
	const { cart } = useSelector((state) => state.marketPlace);

	const { groupCart, personalCart } = cart || {};
	const currentCart = cartType === GROUP_CART ? groupCart : personalCart;
	const { cartItems } = currentCart || {};

	useEffect(() => {
		handleFetchCart(cartType);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const renderBasedOnStatus = () => {
		switch (currentStatus) {
			case LOADING:
				return (
					<div className="d-flex w-100 justify-content-center align-items-center mkt-loading">
						<MintInfinite relative />
					</div>
				);

			case ERROR:
				return (
					<div className="d-flex w-100 justify-content-center align-items-center mkt-loading mkt-error-loading">
						<FailedTryAgain
							errorMssg={errorMsg}
							handleTryAgain={() => handleFetchCart(cartType)}
						/>
					</div>
				);

			case FINISHED:
				if (cartItems?.length <= 0 || !currentCart) {
					return (
						<div className="d-flex flex-column w-100 justify-content-center mkt-empty-state align-items-center mkt-loading">
							
							<div className=" text-center  mb-n3"><ReactSVG src={PShopping} /></div>
							<h6 className="notready">Your Cart is Empty</h6>
							<span>
								Please add to cart! <br/>As soon as you add to cart, your cart will be full
							</span>
							<button
								 onClick={() => {
              history.push('/market-place/personal-shopping')
          			}}
								className="btn btn-mint-default new-address-btn"
								type="submit"
							>
								Start Shopping
							</button>
							
						</div>
					);
				}

				return (
					<div className="mkt-cart__content">
						<div className="mkt-cart-items">
							{cartItems?.map((item, index) => (
								<CartItemCard
									key={index}
									product={item.product}
									cartItems={cartItems}
									currentQuantity={item.quantity}
									cartType={cartType}
									itemId={item.id}
								/>
							))}
						</div>

						<CartSummary
							header="Personal Cart Summary"
							cartType={cartType}
							currentStep={4}
						/>
					</div>
				);

			default:
				return null;
		}
	};

	return (
		<>
			<Helmet>
				<title>
					{process.env.REACT_APP_NAME} - Market Place (Cart)
				</title>
			</Helmet>

			<FadeIn>
				<section className="pb-5 mkt-cart market-place">
					<MktTopNav cartType={cartType} />
					<div className="mkt-back-button">
						<BackButton onClick={() => history.goBack()} />
					</div>

					{cartItems?.length >0 && currentCart && <h1>Personal Cart Details</h1>}
					{cartItems?.length >0 && currentCart && (<p>Review and confirm your personal cart</p>)}

					{renderBasedOnStatus()}
				</section>
			</FadeIn>
		</>
	);
}
