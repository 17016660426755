import DashboardHome from "../components/Landing/Landing";
import FundsTransfer from "../components/FundsTransfer/FundsTransfer";
import TransactionHistory from "../components/TransactionHistory/TransactionHistory";
import BeneficiaryManagement from "../components/BeneficiaryManagement/BeneficiaryManagement";
import CustomerSettings from "../components/CustomerSettings/CustomerSettings";
import BillsPayment from "../components/bills/BillsPayment/BillsPayment";
import EmergencySavings from "../components/savings/EmergencySavings/EmergencySavings";
// import SavingsEarnings from "../components/savings/SavingsEarnings/SavingsEarnings";
import Investments from "../components/Investments/Investments";
import allRoles from "../../auth/Roles/all-roles";
import CompleteProfile from "../components/CompleteProfile/CompleteProfile";
import permissions from "../../auth/permissions";
import BusinessDocumentation from "../components/CompleteProfile/subRoutes/BusinessDocumentation/BusinessDocumentation";
import DirectorsDocumentation from "../components/CompleteProfile/subRoutes/DirectorsDocumentation/DirectorsDocumentation";
import TrusteesDocumentation from "../components/CompleteProfile/subRoutes/TrusteesDocumentation/TrusteesDocumentation";
import TopUp from "../components/TopUp/TopUp";
import PendingTransactionApprovals from "../components/PendingTransactionApprovals/PendingTransactionApprovals";
import ApprovedTransactions from "../components/ApprovedTransactions/ApprovedTransactions";
import DeclinedTransactions from "../components/DeclinedTransactions/DeclinedTransactions";
import AccountStatement from "../components/AccountStatement/AccountStatement";
import BillsPaymentCategory from "../components/bills/BillsPayment/BillsPaymentCategory/BillsPaymentCategory";
import BillsManager from "../components/bills/BillsManager/BillsManager";
import RemitaCategory from "../components/bills/BillsPayment/Remita/RemitaCategory/RemitaCategory";
import EmergencySavingsRoutes from "../components/savings/EmergencySavings/components/EmergencySavingsRoutes/EmergencySavingsRoutes";
import EmergencySavingDetails from "../components/savings/EmergencySavings/components/EmergencySavingDetails/EmergencySavingDetails";
import SpendAndSave from "../components/savings/SpendAndSave/SpendAndSave";
import CreateSpendAndSave from "../components/savings/SpendAndSave/components/CreateSpendAndSave/CreateSpendAndSave";
import SavingGoals from "../components/savings/SavingGoals/SavingGoals";
import SavingGoalsRoutes from "../components/savings/SavingGoals/components/SavingGoalsRoutes/SavingGoalsRoutes";
import SavingGoalDetails from "../components/savings/SavingGoals/components/SavingGoalDetails/SavingGoalDetails";
import PersonalShopping from "../components/MarketPlace/PersonalShopping";
import ProductDetails from "../components/MarketPlace/components/ProductDetails/ProductDetails";
import ShoppingCart from "../components/MarketPlace/components/ShoppingCart/ShoppingCart";
import DeliveryDetails from "../components/MarketPlace/components/DeliveryDetails/DeliveryDetails";
import OderHistory from "../components/MarketPlace/components/OrderHistory";
import CartView from "../components/MarketPlace/components/OrderHistory/CartView/CartView";
import SearchProducts from "../components/MarketPlace/components/SearchProducts/SearchProducts";
import RepaymentSchedule from "../components/MarketPlace/components/RepaymentSchedule";
import SplitPayment from "../components/SplitPayment/SplitPayment";

// Using allRoles for now since the side bar nav items are the same for Individual and Corporate accounts
export default [
	{
		exact: true,
		iconName: "home-icon",
		title: "Home",
		path: "/dashboard",
		component: DashboardHome,
		accessLevel: allRoles,
	},
	{
		iconName: "",
		title: "Setup Account",
		path: "/setup-account",
		hide: true,
		groupAccessLevel: [permissions.IS_SETUP_UNCOMPLETED],
		dropDowns: [
			{
				iconName: "",
				title: "Setup Account",
				path: "/setup-account/",
				hide: true,
				component: CompleteProfile,
			},
			{
				iconName: "",
				title: "Business Documentation",
				path: "/setup-account/business-documentation",
				hide: true,
				component: BusinessDocumentation,
			},
			{
				iconName: "",
				title: "Directors Documentation",
				path: "/setup-account/directors-documentation",
				hide: true,
				component: DirectorsDocumentation,
			},
			{
				iconName: "",
				title: "Trustees Documentation",
				path: "/setup-account/trustees-documentation",
				hide: true,
				component: TrusteesDocumentation,
			},
		],
	},
	{
		iconName: "bills-icon",
		title: "Bills",
		path: "/pay-bills",
		groupAccessLevel: [permissions.IS_ACCOUNT_VERIFIED],
		dropDowns: [
			{
				iconName: "",
				title: "Pay Bills",
				path: "/pay-bills/",
				component: BillsPayment,
			},
			{
				iconName: "",
				title: "Bills Payment Category",
				path: "/pay-bills/:id",
				component: BillsPaymentCategory,
				hide: true,
			},
			{
				iconName: "",
				title: "Bills Payment - Remita",
				path: "/pay-bills/remita/:id",
				component: RemitaCategory,
				hide: true,
			},
			// {
			// 	title: "Schedule Bill Payment",
			// 	path: "/pay-bills/schedule-payment",
			// 	component: ScheduleBillPayment,
			// 	hide: true,
			// },
			{
				title: "Manage Bills",
				path: "/manage-bills",
				component: BillsManager,
			},
		],
	},
	{
		iconName: "top-up-nav-icon",
		title: "Top Up",
		path: "/top-up",
		groupAccessLevel: [permissions.IS_ACCOUNT_VERIFIED],
		dropDowns: [
			{
				iconName: "",
				title: "Top-Up",
				path: "/top-up/",
				component: TopUp,
				hide: true,
			},
			{
				iconName: "",
				title: "Top-Up",
				path: "/top-up/:id",
				component: TopUp,
				hide: true,
			},
		],
	},
	{
		iconName: "savings-icon",
		title: "Savings",
		path: "/savings",
		groupAccessLevel: [permissions.IS_INDIVIDUAL_SOLE_VERIFIED],
		dropDowns: [
			{
				iconName: "savings-icon",
				title: "Savings Goal",
				path: "/savings/goals/",
				component: SavingGoals,
			},
			{
				iconName: "",
				title: "Savings Goal Details",
				path: "/savings/goals/:id",
				component: SavingGoalsRoutes,
				hide: true,
			},
			{
				iconName: "",
				title: "Savings Goal Details",
				path: "/savings/goals/details/:id",
				component: SavingGoalDetails,
				hide: true,
			},
			// {
			// 	iconName: "",
			// 	title: "Savings Goal Earnings",
			// 	path: "/savings/goals/:goalID/earnings",
			// 	component: SavingsEarnings,
			// 	hide: true,
			// },
			{
				iconName: "",
				title: "Emergency Savings",
				path: "/savings/emergencies/",
				component: EmergencySavings,
			},
			{
				iconName: "",
				title: "Emergency Savings Goal Details",
				path: "/savings/emergencies/:id",
				component: EmergencySavingsRoutes,
				hide: true,
			},
			{
				iconName: "",
				title: "Emergency Savings Goal Details",
				path: "/savings/emergencies/details/:id",
				component: EmergencySavingDetails,
				hide: true,
			},
			{
				iconName: "",
				title: "Spend and Save",
				path: "/savings/spend-and-save/",
				component: SpendAndSave,
			},
			{
				iconName: "",
				title: "Create Spend and Save",
				path: "/savings/spend-and-save/create",
				component: CreateSpendAndSave,
				hide: true,
			},
		],
	},
	{
		iconName: "send-money-icon",
		title: " Send Money",
		path: "/send-money",
		exact: true,
		component: FundsTransfer,
		accessLevel: [permissions.IS_ACCOUNT_VERIFIED],
	},

	{
		iconName: "send-money-icon",
		title: "Split Payment ",
		path: "/split-payment",
		component: SplitPayment,
		accessLevel: [permissions.IS_ACCOUNT_VERIFIED],
	},
	{
		iconName: "investment-icon",
		title: "Investments",
		path: "/investments",
		component: Investments,
		accessLevel: [permissions.IS_ACCOUNT_VERIFIED],
	},

	{
		iconName: "market-place-icon",
		title: "Mintyn Marketplace",
		path: "/market-place/",
		groupAccessLevel: [permissions.IS_INDIVIDUAL],
		dropDowns: [
			{
				iconName: "",
				title: "Personal Shopping",
				path: "/market-place/personal-shopping",
				component: PersonalShopping,
			},

			{
				iconName: "",
				title: "Search",
				path: "/market-place/personal-shopping/search",
				component: SearchProducts,
				hide: true,
			},

			// {
			// iconName: "",
			// 	title: "Group Shopping",
			// 	path: "/group-shopping",
			// 	component: BillsManager,
			// },

			{
				iconName: "",
				title: "Order History",
				path: "/market-place/order-history",
				component: OderHistory,
			},
			{
				iconName: "",
				title: "Order History",
				path: "/market-place/order-history/:id",
				component: OderHistory,
				hide: true,
			},

			{
				iconName: "",
				title: "Cart View",
				path: "/market-place/cart-view",
				component: CartView,
				hide: true,
			},

			{
				iconName: "",
				title: "Product Details",
				path: "/market-place/product-details",
				hide: true,
				component: ProductDetails,
			},
			{
				iconName: "",
				title: "Shopping Cart",
				path: "/market-place/cart",
				hide: true,
				component: ShoppingCart,
			},
			{
				iconName: "",
				title: "Delivery Details",
				path: "/market-place/delivery-details",
				hide: true,
				component: DeliveryDetails,
			},

			{
				iconName: "",
				title: "Repayment Schedule",
				path: "/market-place/delivery-details/repayment-schedule",
				hide: true,
				component: RepaymentSchedule,
			},
		],
	},
	{
		exact: true,
		iconName: "beneficiary-icon",
		title: "Beneficiaries",
		path: "/manage-beneficiaries",
		component: BeneficiaryManagement,
		accessLevel: [permissions.IS_ACCOUNT_VERIFIED],
	},
	{
		exact: true,
		iconName: "transaction-history-icon",
		title: "Finance",
		path: "/transaction-history",
		groupAccessLevel: [permissions.IS_ACCOUNT_VERIFIED],
		dropDowns: [
			{
				iconName: "",
				title: "Transaction History",
				path: "/transaction-history",
				component: TransactionHistory,
			},
			{
				iconName: "",
				title: "Approved Transactions",
				path: "/approved-transactions",
				component: ApprovedTransactions,
				accessLevel: [permissions.IS_ENTERPRISE_TRUSTEE_VERIFIED],
			},
			{
				iconName: "",
				title: "Pending Transactions",
				path: "/pending-transaction-approvals",
				component: PendingTransactionApprovals,
				accessLevel: [permissions.IS_ENTERPRISE_TRUSTEE_VERIFIED],
			},
			{
				iconName: "",
				title: "Declined Transactions",
				path: "/declined-transaction-approvals",
				component: DeclinedTransactions,
				accessLevel: [permissions.IS_ENTERPRISE_TRUSTEE_VERIFIED],
			},
			{
				iconName: "",
				title: "Account Statement",
				path: "/account-statement",
				component: AccountStatement,
			},
		],
	},
	{
		iconName: "settings-nav-icon",
		title: "Settings",
		path: "/settings",
		groupAccessLevel: allRoles,
		dropDowns: [
			{
				iconName: "",
				title: "Customer Settings",
				path: "/settings/",
				component: CustomerSettings,
				hide: true,
			},
			{
				iconName: "",
				title: "Customer Settings",
				path: "/settings/:id",
				component: CustomerSettings,
				hide: true,
			},
		],
	},
];
