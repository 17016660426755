import { MintInfinite } from "components/dashboard/utils/ShineLoader/ShineLoader";
import {
	formatCurrency,
	formatEnum,
	getErrorMessage,
	monthDayYearFormat,
} from "components/utils/helpers";
import ModalHeader from "components/utils/Modal/CustomModalHeader/CustomModalHeader";
import Modal from "components/utils/Modal/Modal";
import { API } from "lib/api";
import { getBillPaymentRequest } from "lib/api/apiRoutesConfig/services/BillsService/subRoutes";
import { getFundTransferRequest } from "lib/api/apiRoutesConfig/services/FundsManagementMS/transactions/subRoutes";
import React, { Fragment, useEffect, useState } from "react";
import { ToastsStore } from "react-toasts";
import { categoryEnums } from "../../PendingTransactionApprovals/components/constants";
import "../../PendingTransactionApprovals/components/DetailsModal/DetailsModal.scss";

export default function DTDetailsModal({ show, transaction, onClose }) {
	const { transactionCategory, requestId } = transaction || {};

	return (
		<Modal show={show}>
			<ModalHeader
				heading="Declined Transaction"
				className="p-0"
				headerClassName="setup-modals-header px-sm-5 px-3 py-3"
				dismissable={() => {
					onClose();
				}}
			/>

			<SummaryDetails
				requestId={requestId}
				category={transactionCategory}
			/>
		</Modal>
	);
}

const SummaryDetails = ({ requestId, category }) => {
	const [isTransactionLoading, setIsTransactionLoading] = useState(false);
	const [transactionRequest, setTransactionRequest] = useState({});
	// Needed for bulk payments and fund transfers
	const [transferRecipients, setTransferRecipients] = useState([]);

	const {
		transactionCategory,
		amount,
		initiatedBy,
		initiator, // for bill payment
		dateRequested,
		dateInitiated, // for bill payment
		// reviewedBy,
		// reviewer, // for bill payment
		requestId: transactionId,
		transactionId: billPaymentTransactionId, // for bill payment
		approvalStatus,
		biller, // for bill payment
		billerItem, // for bill payment
		recipient, // for bill payment
	} = transactionRequest || {};

	// for fund transfer
	const fetchFundTransactionRequest = async () => {
		setIsTransactionLoading(true);
		try {
			const response = await API.get(getFundTransferRequest(requestId));
			const { status, data } = response || {};
			if (status === 200) {
				setTransactionRequest(data.data?.transactionRequest);
				setTransferRecipients(data.data?.transferRecipients);
			}
		} catch (err) {
			setTransactionRequest({});
			// setTransferRecipients([]);
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		} finally {
			setIsTransactionLoading(false);
		}
	};

	// for bill payment
	const fetchBillPaymentRequest = async () => {
		setIsTransactionLoading(true);
		try {
			const response = await API.get(getBillPaymentRequest(requestId));
			const { status, data } = response || {};
			if (status === 200) {
				setTransactionRequest(data.data);
			}
		} catch (err) {
			setTransactionRequest({});
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		} finally {
			setIsTransactionLoading(false);
		}
	};

	useEffect(() => {
		if (category === categoryEnums.FUND_TRANSFER) {
			fetchFundTransactionRequest();
			return;
		}
		if (category === categoryEnums.BILL_PAYMENT) {
			fetchBillPaymentRequest();
			return;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (isTransactionLoading)
		return (
			<div
				className="d-flex w-100 justify-content-center align-items-center pta-loading"
				style={{ paddingTop: "20px", minHeight: "500px" }}
			>
				<MintInfinite relative />
			</div>
		);

	return (
		<div className="pta-summary px-sm-5 px-3">
			<div className="pta-summary-info">
				<span>Category</span>
				<span className="text-capitalize">
					{formatEnum(transactionCategory)}
				</span>
			</div>

			{category === categoryEnums.BILL_PAYMENT && (
				<>
					<div className="pta-summary-info">
						<span>Biller</span>
						<span className="text-capitalize">{biller}</span>
					</div>

					<div className="pta-summary-info">
						<span>Biller Item</span>
						<span className="text-capitalize">{billerItem}</span>
					</div>
				</>
			)}

			<div className="pta-summary-info">
				<span>Amount</span>
				<span className="amount">{formatCurrency(amount)}</span>
			</div>

			<div className="pta-summary-info">
				<span>Initiator</span>
				<span>{initiatedBy || initiator}</span>
			</div>

			<div className="pta-summary-info">
				<span>Date Initiated</span>
				<span>
					{monthDayYearFormat(dateRequested || dateInitiated)}
				</span>
			</div>

			{/* <div className="pta-summary-info">
						<span>Approved by</span>
						<span>{reviewedBy || reviewer}</span>
					</div> */}

			{category === categoryEnums.BILL_PAYMENT && (
				<div className="pta-summary-info">
					<span>Recipient</span>
					<span>{recipient}</span>
				</div>
			)}

			{category === categoryEnums.FUND_TRANSFER &&
				transferRecipients.map((item, index) => (
					<Fragment key={index}>
						<div className="pta-summary-info">
							<span>Bank</span>
							<span>{item.recipientBankName}</span>
						</div>
						<div className="pta-summary-info">
							<span>Account number</span>
							<span>{item.recipientAccountNumber}</span>
						</div>
					</Fragment>
				))}

			<div className="pta-summary-info">
				<span>Transaction ID</span>
				<span>{billPaymentTransactionId || transactionId}</span>
			</div>

			<div className="pta-summary-info">
				<span>Transaction Status</span>
				<span>{approvalStatus}</span>
			</div>
		</div>
	);
};
