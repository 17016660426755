import FormInputGroup from "components/utils/FormInputGroup/FormInputGroup";
import { getErrorMessage } from "components/utils/helpers";
import { SmallLoader } from "components/utils/SmallLoader";
import { Formik } from "formik";
import { API } from "lib/api";
import { remitaLookUp } from "lib/api/apiRoutesConfig/services/BillsService/subRoutes";
import React from "react";
import { useState } from "react";
import { ToastsStore } from "react-toasts";
import * as yup from "yup";
import "../../BillPayment.scss";

const formValidationSchema = yup.object().shape({
	rrr: yup.string().required(`Remita Retrieval Reference
	Number is required`),
});

export default function EnterRRRNumber({ setTransaction, goToNextStep }) {
	const [isLoading, setIsLoading] = useState(false);

	const initialFormValues = {
		rrr: "",
	};

	const handleRemitaLookup = async ({ rrr }) => {
		setIsLoading(true);
		try {
			const response = await API.post(remitaLookUp, null, {
				params: {
					rrr,
				},
			});
			const { status, data } = response || {};
			if (status === 200) {
				setIsLoading(false);
				setTransaction(data.data);
				goToNextStep();
			}
		} catch (err) {
			setIsLoading(false);
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		}
	};

	return (
		<>
			<h2>Enter your RRR Number</h2>
			<p>Please enter your Remita Retrieval Reference Number below</p>

			<Formik
				initialValues={initialFormValues}
				validationSchema={formValidationSchema}
				onSubmit={(values) => handleRemitaLookup(values)}
			>
				{({
					values,
					errors,
					touched,
					handleSubmit,
					handleChange,
					handleBlur,
				}) => (
					<form
						className="bp-categories__form"
						onSubmit={(e) => {
							e.preventDefault();
							handleSubmit();
						}}
					>
						<div className="mb-4">
							<FormInputGroup
								id="rrr"
								className="bp-input"
								name="rrr"
								placeholder="Enter Number"
								label="RRR Number"
								value={values.rrr}
								onBlur={handleBlur}
								error={touched.rrr && errors.rrr}
								onChange={handleChange}
							/>
						</div>

						<button
							className="btn btn-mint-default mt-2"
							type="submit"
							disabled={isLoading}
						>
							{isLoading ? <SmallLoader /> : "Submit"}
						</button>
					</form>
				)}
			</Formik>
		</>
	);
}
