import React from "react";
import { withRouter, matchPath } from "react-router-dom";
import SideNavHeader from "./SideNavHeader/SideNavHeader";
import SmothScrollContent from "../../utils/SmothScrollContent/SmothScrollContent";
import dashRoutes from "../../dashboard/routes/routes";
import SideNavFooter from "./SideNavFooter/SideNavFooter";
import SideNavItems from "./SideNavItems/SideNavItems";
import "./SideNav.scss";

const SideNav = ({ location }) => {
	const activeRoute = (path, dropDowns) => {
		const { pathname: currentPath } = location;
		const matchFunction = (pathVariable) =>
			matchPath(currentPath, {
				path: pathVariable,
				exact: false,
				strict: false,
			});
		let match = matchFunction(path);
		let isActive = dropDowns
			? dropDowns.find(
					(x) => x.path === currentPath || matchFunction(x.path)
			  )
			: false;
		return path === currentPath || isActive || match ? "active" : "";
	};

	const trustClick = () => {
		var sidebar = document.getElementById("sidebar");
		var overlay = document.getElementById("overlay");
		sidebar.classList.remove("active");
		overlay.style.display = "none";
	};

	return (
		<SmothScrollContent ID="sidebar" tag="nav">
			<SideNavHeader />
			<ul className="list-unstyled components">
				{dashRoutes.map((route, index) => {
					return (
						<SideNavItems
							key={index}
							route={route}
							index={index}
							trustClick={trustClick}
							location={location}
							activeRoute={activeRoute}
						/>
					);
				})}
				<SideNavFooter />
			</ul>
		</SmothScrollContent>
	);
};

export default withRouter(SideNav);
