import React from "react";

const usePopstate = ({ alertDescription, callback, isPrompt = false }) => {
	const [isBackButtonClicked, setBackButtonClicked] = React.useState(false);

	React.useEffect(() => {
		window.history.pushState(null, null, window.location.pathname);
		window.addEventListener("popstate", onBackButtonEvent);

		window.onbeforeunload = function () {
			return alertDescription;
		};
		return () => {
			window.removeEventListener("popstate", onBackButtonEvent);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onBackButtonEvent = (e) => {
		e.preventDefault();
		if (!isBackButtonClicked) {
			if (isPrompt) {
				if (window.confirm(alertDescription)) {
					if (callback) {
						callback();
					}
					setBackButtonClicked(true);
				} else {
					window.history.pushState(
						null,
						null,
						window.location.pathname
					);
					setBackButtonClicked(false);
				}
			} else {
				if (callback) {
					callback();
				}
				setBackButtonClicked(true);
			}
		}
	};

	return {
		isBackButtonClicked,
	};
};

export default usePopstate;
