import React, { Component } from "react";
import queryString from "query-string";
import { LOADING, ERROR, FINISHED } from "../../../config/enums";
import { getErrorMessage } from "../../utils/helpers";
import { orderConfirmationEndpoint } from "../../../lib/api/apiRoutesConfig/services/tradeMS/common/subRoutes";
import { API } from "../../../lib/api";

class MintTradeOrderConfirmation extends Component {
    state = {
        currentState: LOADING,
    };

    componentDidMount = () => {
        let params = queryString.parse(this.props.location.search);
        if (params.token) {
            this.confirmCustomerOrder(params.token);
        } else {
            this.setState({
                currentState: ERROR,
                errorText:
                    "Can not process order confirmation, please use a valid url",
            });
        }
    };

    confirmCustomerOrder = async (token) => {
        try {
            const url = orderConfirmationEndpoint(token);
            const res = await API.put(url);
            if (res.status === 200) {
                this.setState({
                    currentState: FINISHED,
                });
            }
        } catch (err) {
            this.setState({
                currentState: ERROR,
                errorText: getErrorMessage(err),
            });
        }
    };

    renderBasedOnState = () => {
        const { currentState, errorText } = this.state;
        switch (currentState) {
            case LOADING:
                return "";

            case ERROR:
                return <h6 style={{ color: "#ff0000" }}>{errorText}</h6>;

            case FINISHED:
                return <h3>Order confirmed successfully...</h3>;
            default:
                return "";
        }
    };

    render() {
        return (
            <div
                style={{
                    background: "#fff",
                    width: "100%",
                    height: "100vh",
                    zIndex: "1",
                    position: "absolute",
                }}>
                <div className='my-3'>{this.renderBasedOnState()}</div>
            </div>
        );
    }
}

export default MintTradeOrderConfirmation;
