import CustomPinInput from "components/utils/CustomPinInput/CustomPinInput";
import { getErrorMessage } from "components/utils/helpers";
import { SmallLoader } from "components/utils/SmallLoader";
import { API } from "lib/api";
import { updateBillPaymentRequestStatus } from "lib/api/apiRoutesConfig/services/BillsService/subRoutes";
import { updateTransferRequestStatus } from "lib/api/apiRoutesConfig/services/FundsManagementMS/transactions/subRoutes";
import React, { useState } from "react";
import { ToastsStore } from "react-toasts";
import {
	categoryEnums,
	requestStatusEnums,
	transactionStagesEnums,
} from "../constants";
import "../DetailsModal/DetailsModal.scss";

export default function RequestStatusModal({
	category,
	requestStatus,
	requestId,
	setSuccessMsg,
	goToPrevStage,
	goToNextStage,
}) {
	const [transactionPin, setTransactionPin] = useState("");
	const [loading, setLoading] = useState(false);

	// for fund transfer
	const updateFundTransferRequestStatus = async () => {
		setLoading(true);
		try {
			const response = await API.post(updateTransferRequestStatus, {
				approved:
					requestStatus === requestStatusEnums.DECLINE ? false : true,
				requestId,
				transactionPin,
			});
			const { status, data } = response || {};
			if (status === 200) {
				setSuccessMsg(data.message);
				goToNextStage(transactionStagesEnums.FINISHED);
			}
		} catch (err) {
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		} finally {
			setLoading(false);
		}
	};

	// for bill payment
	const _updateBillPaymentRequestStatus = async () => {
		setLoading(true);
		try {
			const response = await API.post(updateBillPaymentRequestStatus, {
				approved:
					requestStatus === requestStatusEnums.DECLINE ? false : true,
				requestId,
				transactionPin,
			});
			const { status, data } = response || {};
			if (status === 200) {
				setSuccessMsg(data.message);
				goToNextStage(transactionStagesEnums.FINISHED);
			}
		} catch (err) {
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		} finally {
			setLoading(false);
		}
	};

	const updateRequestStatus = () => {
		if (category === categoryEnums.FUND_TRANSFER) {
			updateFundTransferRequestStatus();
			return;
		}
		if (category === categoryEnums.BILL_PAYMENT) {
			_updateBillPaymentRequestStatus();
			return;
		}
	};

	return (
		<div className="pta-request-status px-sm-5 px-3">
			<CustomPinInput
				hasDashedBorder
				length={4}
				secret
				title="Enter Transaction PIN to confirm"
				containerStyle={{ flexWrap: "wrap" }}
				onChange={(value) => {
					setTransactionPin(value);
				}}
			/>

			<div className="pta-request-status__footer">
				<button
					className="btn btn-custom-bordered"
					onClick={() => goToPrevStage(transactionStagesEnums.IDLE)}
				>
					Cancel
				</button>
				<button
					className="btn btn-mint-default"
					onClick={updateRequestStatus}
					disabled={loading || transactionPin.length !== 4}
				>
					{loading ? <SmallLoader /> : "Confirm Transaction"}
				</button>
			</div>
		</div>
	);
}
