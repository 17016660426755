import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Switch } from "react-router-dom";
import Helmet from "react-helmet";
import SideNav from "./SideNav/SideNav";
import TopNav from "./components/TopNav";
import Overlay from "./components/Overlay";
import HandleIdle from "./HandleIdle";
import DashContents from "./DashContents/DashContents";
import routes from "./routes/routes";
import SetSecurityQuestionModal from "./settings/popUps/SetSecurityQuestion/SetSecurityQuestion";
import SessionTimeOut from "./components/modals/SessionTimeOut";
import SwitchModes from "../../SwitchModes/SwitchModes";
import WebChatWidget from "../../essentials/WebChatWidget/WebChatWidget";
import AccessControlRoute from "../auth/AccessControlRoute/AccessControlRoute";

class Dashboard extends Component {
	render() {
		return (
			<>
				<Helmet>
					<title>{process.env.REACT_APP_NAME} - Dashboard</title>
				</Helmet>
				<div className="wrapper" id="wrapper">
					<BrowserRouter>
						<SideNav key={this.props.activeAccountType} />
						<DashContents>
							<TopNav />
							<div className="container">
								<Switch>
									{routes.map(
										(
											{
												path,
												exact,
												component,
												dropDowns,
												groupAccessLevel,
												accessLevel,
											},
											index
										) => {
											return dropDowns &&
												dropDowns.length > 0 ? (
												dropDowns.map(
													(
														{
															component,
															path,
															...dropdown
														},
														dropdownIndex
													) => (
														<AccessControlRoute
															key={dropdownIndex}
															exact={true}
															path={path}
															component={
																component
															}
															allowedPermissions={
																dropdown.accessLevel
																	? dropdown.accessLevel
																	: groupAccessLevel
															}
														/>
													)
												)
											) : (
												<AccessControlRoute
													key={index}
													exact={exact}
													path={path}
													component={component}
													allowedPermissions={
														accessLevel
													}
												/>
											);
										}
									)}
								</Switch>
							</div>
						</DashContents>
						<Overlay />
						<WebChatWidget />
					</BrowserRouter>
					<HandleIdle />
					<SessionTimeOut />
					<SwitchModes />
					<SetSecurityQuestionModal />
					{/* <SuggestMobileApp /> */}
				</div>
			</>
		);
	}
}

export default connect(
	({
		auth: { currentUser, activeAccountType },
		dashboard: { dashboardResponse },
	}) => ({
		currentUser: currentUser,
		bankAccounts: dashboardResponse.bankAccounts,
		activeAccountType,
	}),
	null
)(Dashboard);
