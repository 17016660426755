import common from "../";
export const {
	employmentStatusList,
	getSecurityQuestions,
	getFrequentlyAskedQues,
	getAwarenessChannel,
	getAllBanksList,
	getAllStates,
	getAllLGAs,
	getAllCountryAction,
	getTINBusinessCode,
	getCorporateRoles,
	getBusinessCategory,
	deactivateAccount,
} = {
	employmentStatusList: `${common}/employment-status-list`,
	getSecurityQuestions: `${common}/security-questions`,
	getFrequentlyAskedQues: `${common}/faq`,
	getAwarenessChannel: `${common}/mint-awareness-channels`,
	getAllBanksList: `${common}/banks`,
	getAllStates: (countryCode) => `${common}/${countryCode}/states`,
	getAllLGAs: (stateCode) => `${common}/${stateCode}/lgas`,
	getAllCountryAction: `${common}/countries`,
	getTINBusinessCode: `${common}/corporate/tin/business-code`,
	getCorporateRoles: `${common}/corporate/roles`,
	getBusinessCategory: `${common}/corporate/business-category`,
	deactivateAccount: `${common}/deactivate-account`,
};
