import React from "react";
import { ReactSVG } from "react-svg";
import "../../SpendAndSave.scss";
import ExclamationIcon from "assets/img/tiny_exclamation.svg";
import {
	formatCurrency,
	formatEnum,
	getErrorMessage,
} from "components/utils/helpers";
import { format as formatDate } from "date-fns";
import SASWithdrawal from "../modals/SASWithdrawal";
import { useState } from "react";
import EditSASModal from "../modals/EditSASModal";
import { ERROR, FINISHED, IDLE, LOADING } from "config/enums";
import { API } from "lib/api";
import { updateSpendAndSaveStatus } from "lib/api/apiRoutesConfig/services/SavingsMS/savingsGoals/subRoutes";
import { ToastsStore } from "react-toasts";
import { TinyLoad } from "components/utils/SmallLoader";

export default function SpendAndSaveItem({ data = {}, refetch }) {
	const {
		totalAmount,
		savingsLocked,
		amountSaved,
		accruedInterest,
		percentage,
		status,
		maturityDate,
	} = data || {};

	const [showWithdrawalModal, setShowWithdrawalModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [currentState, setCurrentState] = useState(IDLE);

	const handleUpdateStatus = async () => {
		setCurrentState(LOADING);
		try {
			const response = await API.put(updateSpendAndSaveStatus, {
				status: status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
			});
			if (response.status) {
				setCurrentState(FINISHED);
				ToastsStore.success(response.data.message, 6000, "right-toast");
				refetch();
			}
		} catch (err) {
			setCurrentState(ERROR);
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		}
	};

	return (
		<>
			<div className="sas-item">
				<div className="sas-item__header">
					<div>
						<span className="btn btn-green">Total Balance</span>
						<h3>{formatCurrency(totalAmount)}</h3>
					</div>

					<div>
						<button
							className="btn gradient"
							disabled={savingsLocked}
							onClick={() => setShowWithdrawalModal(true)}
						>
							Withdraw
						</button>
						<span className="d-flex align-items-center">
							<ReactSVG src={ExclamationIcon} className="mr-2" />{" "}
							Withdrawal not available until due date
						</span>
					</div>
				</div>

				<div className="sas-item__info-container">
					<div>
						<div className="sas-item-info">
							<h4>Amount Saved</h4>
							<h3>{formatCurrency(amountSaved)}</h3>
						</div>
						<div className="sas-item-info">
							<h4>Interest accrued</h4>
							<h3>{accruedInterest}</h3>
						</div>
					</div>

					<div>
						<div className="sas-item-info">
							<h4>Percentage saved</h4>
							<h3>{percentage}%</h3>
						</div>
						<div className="sas-item-info">
							<h4>Status</h4>
							<h3 className="text-capitalize">
								{formatEnum(status)}
							</h3>
						</div>
					</div>

					<div>
						<div className="sas-item-info">
							<h4 className="text-left">Maturity Date</h4>
							{maturityDate && (
								<h3 className="text-left">
									{formatDate(
										new Date(maturityDate) ?? 0,
										"dd/MM/yyyy"
									)}
								</h3>
							)}
						</div>
					</div>
				</div>

				<div className="sas-item__footer">
					<button
						className="btn btn-savings-lg"
						onClick={() => setShowEditModal(true)}
					>
						Edit
					</button>

					<button
						className="btn btn-custom-bordered"
						disabled={currentState === LOADING}
						onClick={handleUpdateStatus}
					>
						{currentState === LOADING ? (
							<TinyLoad />
						) : status === "ACTIVE" ? (
							"Deactivate"
						) : (
							"Activate"
						)}
					</button>
				</div>
			</div>

			{/* Modals */}
			<SASWithdrawal
				show={showWithdrawalModal}
				totalBalance={totalAmount}
				refetch={refetch}
				onClose={() => setShowWithdrawalModal(false)}
			/>

			<EditSASModal
				show={showEditModal}
				onClose={() => setShowEditModal(false)}
				refetch={refetch}
				savingsLocked={savingsLocked}
			/>
		</>
	);
}
