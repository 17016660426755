import React, { useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { SmallLoader } from "components/utils/SmallLoader";
import CustomFileUploader from "components/utils/CustomFileUploader/CustomFileUploader";
import { getErrorMessage } from "components/utils/helpers";
import { postBusinessDocumentsAction } from "lib/api/apiRoutesConfig/services/AccountMS/CAC/subRoutes";
import { API } from "lib/api";
import { ToastsStore } from "react-toasts";
const SUPPORTED_FORMATS = [
	"image/png",
	"image/jpg",
	"image/jpeg",
	"application/pdf",
];
const FILE_SIZE = 3_000_000;

const BusinessDocuments = ({ setSteps, regInfo, refresh }) => {
	const [isLoading, setIsLoading] = useState(false);

	const formValidationSchema = yup.object().shape({
		organizationConstitution: yup
			.mixed()
			.test("is-a-supported-type", "Unsupported file format", (value) => {
				if (value) return SUPPORTED_FORMATS.includes(value?.type);
				return true;
			})
			.test(
				"is-not-more-than-max-file-size",
				"File size is too large",
				(value) => {
					if (value) return value?.size <= FILE_SIZE;
					return true;
				}
			)
			.required("Document is required"),
		minuteOfMeeting: yup
			.mixed()
			.test("is-a-supported-type", "Unsupported file format", (value) => {
				if (value) return SUPPORTED_FORMATS.includes(value?.type);
				return true;
			})
			.test(
				"is-not-more-than-max-file-size",
				"File size is too large",
				(value) => {
					if (value) return value?.size <= FILE_SIZE;
					return true;
				}
			)
			.required("Document is required"),

		impressionOfCommon: yup
			.mixed()
			.test("is-a-supported-type", "Unsupported file format", (value) => {
				if (value) return SUPPORTED_FORMATS.includes(value?.type);
				return true;
			})
			.test(
				"is-not-more-than-max-file-size",
				"File size is too large",
				(value) => {
					if (value) return value?.size <= FILE_SIZE;
					return true;
				}
			)
			.required("Document is required"),

		organizationLetterhead: yup
			.mixed()
			.test("is-a-supported-type", "Unsupported file format", (value) => {
				if (value) return SUPPORTED_FORMATS.includes(value?.type);
				return true;
			})
			.test(
				"is-not-more-than-max-file-size",
				"File size is too large",
				(value) => {
					if (value) return value?.size <= FILE_SIZE;
					return true;
				}
			)
			.required("Document is required"),

		aimOfOrganization: yup
			.mixed()
			.test("is-a-supported-type", "Unsupported file format", (value) => {
				if (value) return SUPPORTED_FORMATS.includes(value?.type);
				return true;
			})
			.test(
				"is-not-more-than-max-file-size",
				"File size is too large",
				(value) => {
					if (value) return value?.size <= FILE_SIZE;
					return true;
				}
			)
			.required("Document is required"),
	});

	const initialFormValues = {
		organizationConstitution: "",
		minuteOfMeeting: "",
		impressionOfCommon: "",
		organizationLetterhead: "",
		aimOfOrganization: "",
	};

	// Handle form submit
	const handleSubmit = async (values) => {
		setIsLoading(true);
		const payload = new FormData();
		payload.append(
			"organizationConstitution",
			values.organizationConstitution || ""
		);
		payload.append("minuteOfMeeting", values.minuteOfMeeting || "");
		payload.append("impressionOfCommon", values.impressionOfCommon || "");
		payload.append(
			"organizationLetterhead",
			values.organizationLetterhead || ""
		);
		payload.append("aimOfOrganization", values.aimOfOrganization || "");

		try {
			const res = await API.put(
				postBusinessDocumentsAction(regInfo.sessionId),
				payload
			);
			const { status, data } = res || {};
			if (status === 200) {
				refresh(localStorage.getItem("s_id"));
				// setSteps(2);
				ToastsStore.success(data.message, 6000, "right-toast");
			}
		} catch (err) {
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			<Formik
				initialValues={initialFormValues}
				validationSchema={formValidationSchema}
				onSubmit={(values) => handleSubmit(values)}
			>
				{({
					values,
					errors,
					touched,
					resetForm,
					isValid,
					handleSubmit,
					setFieldValue,
					handleChange,
					handleBlur,
				}) => {
					return (
						<form className="reg-business-form  row">
							<>
								<div className="mb-4 col-12">
									<CustomFileUploader
										label="Upload Constitution of Organization"
										name="organizationConstitution"
										error={
											touched.organizationConstitution &&
											errors.organizationConstitution
										}
										fileName={
											values.organizationConstitution
												?.name
										}
										onChange={(file) => {
											setFieldValue(
												"organizationConstitution",
												file
											);
										}}
									/>
								</div>

								<div className="mb-4 col-12 ">
									<button
										name="organizationConstitution"
										onClick={() => {
											// resetForm()
											setFieldValue(
												"organizationConstitution",
												null
											);
										}}
										className="btn  remove-upload"
										type="button"
										disabled={isLoading}
									>
										<span>Remove</span>
									</button>
								</div>
							</>

							<>
								<div className="mb-4 col-12">
									<CustomFileUploader
										label="Upload signed copies of the minutes of the meeting appointing the trustees showing the people present together with the votes scored."
										name="minuteOfMeeting"
										error={
											touched.minuteOfMeeting &&
											errors.minuteOfMeeting
										}
										fileName={values.minuteOfMeeting?.name}
										onChange={(file) => {
											setFieldValue(
												"minuteOfMeeting",
												file
											);
										}}
									/>
								</div>

								<div className="mb-4 col-12 ">
									<button
										name="minuteOfMeeting"
										onClick={(file) => {
											// resetForm()
											setFieldValue(
												"minuteOfMeeting",
												null
											);
										}}
										className="btn  remove-upload"
										type="button"
										disabled={isLoading}
									>
										<span>Remove</span>
									</button>
								</div>
							</>

							<>
								<div className="mb-4 col-12">
									<CustomFileUploader
										label="Upload impression of the common seal ( Please sign on a plain paper and upload)."
										name="impressionOfCommon"
										error={
											touched.impressionOfCommon &&
											errors.impressionOfCommon
										}
										fileName={
											values.impressionOfCommon?.name
										}
										onChange={(file) => {
											setFieldValue(
												"impressionOfCommon",
												file
											);
										}}
									/>
								</div>

								<div className="mb-4 col-12 ">
									<button
										name="impressionOfCommon"
										onClick={(file) => {
											// resetForm()
											setFieldValue(
												"impressionOfCommon",
												null
											);
										}}
										className="btn  remove-upload"
										type="button"
										disabled={isLoading}
									>
										<span>Remove</span>
									</button>
								</div>
							</>

							<>
								<div className="mb-4 col-12">
									<CustomFileUploader
										label="Upload the letter head of the proposed organization."
										name="organizationLetterhead"
										error={
											touched.organizationLetterhead &&
											errors.organizationLetterhead
										}
										fileName={
											values.organizationLetterhead?.name
										}
										onChange={(file) => {
											setFieldValue(
												"organizationLetterhead",
												file
											);
										}}
									/>
								</div>

								<div className="mb-4 col-12 ">
									<button
										name="organizationLetterhead"
										onClick={(file) => {
											// resetForm()
											setFieldValue(
												"organizationLetterhead",
												null
											);
										}}
										className="btn  remove-upload"
										type="button"
										disabled={isLoading}
									>
										<span>Remove</span>
									</button>
								</div>
							</>

							<>
								<div className="mb-4 col-12">
									<CustomFileUploader
										label=" Upload the aim & objective of the association"
										name="aimOfOrganization"
										error={
											touched.aimOfOrganization &&
											errors.aimOfOrganization
										}
										fileName={
											values.aimOfOrganization?.name
										}
										onChange={(file) => {
											setFieldValue(
												"aimOfOrganization",
												file
											);
										}}
									/>
								</div>

								<div className="mb-4 col-12 ">
									<button
										name="aimOfOrganization"
										onClick={(file) => {
											// resetForm()
											setFieldValue(
												"aimOfOrganization",
												null
											);
										}}
										className="btn  remove-upload"
										type="button"
										disabled={isLoading}
									>
										<span>Remove</span>
									</button>
								</div>
							</>

							<div className="col-12 ">
								<button
									onClick={handleSubmit}
									className="btn btn-mint-default"
									type="button"
									disabled={isLoading}
								>
									{isLoading ? <SmallLoader /> : "Continue"}
								</button>
							</div>
						</form>
					);
				}}
			</Formik>
		</>
	);
};
export default BusinessDocuments;
