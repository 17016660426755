import React from "react";
import "./ProductCard.scss";
import { useState } from "react";
import CartIcon from "assets/img/cart-icon.svg";
import { ReactSVG } from "react-svg";
import QuantitySelector from "../QuantitySelector/QuantitySelector";
import { formatCurrency } from "components/utils/helpers";
import useAddToCart from "../utils/hooks/useAddToCart";
import { LOADING } from "config/enums";
import { useSelector } from "react-redux";
import useUpdateCartItem from "../utils/hooks/useUpdateCartItem";
import {
	checkIfProductExistsInCart,
	getExistingCartItemId,
	GROUP_CART,
} from "../utils/constant";

export default function ProductCard({ product, onClick, cartType }) {
	const [quantity, setQuantity] = useState(1);
	const { cart } = useSelector((state) => state.marketPlace);
	const { currentStatus, handleAddToCart } = useAddToCart();
	const { currentStatus: updateStatus, handleUpdateCartItem } =
		useUpdateCartItem();

	const { imageUrl, name, price, productCode } = product || {};
	const { groupCart, personalCart } = cart || {};
	const currentCart = cartType === GROUP_CART ? groupCart : personalCart;
	const { cartItems } = currentCart || {};

	const isLoading = currentStatus === LOADING || updateStatus === LOADING;

	const onDecreaseQuantity = () => {
		if (quantity === 1) return;
		setQuantity(quantity - 1);
	};

	const onIncreaseQuantity = () => {
		setQuantity(quantity + 1);
	};

	const addItemToCart = (e) => {
		e.stopPropagation();
		if (checkIfProductExistsInCart(cartItems, productCode)) {
			const cartItemId = getExistingCartItemId(cartItems, productCode);
			handleUpdateCartItem({ cartItemId, quantity }, cartType);
		} else {
			handleAddToCart({ cartType, productCode, quantity });
		}
	};

	return (
		<div className="mkt-product-card" onClick={onClick}>
			<div className="mkt-product-card__image">
				<img src={imageUrl} alt={name} />
				<button
					className="mkt-cart-icon"
					onClick={addItemToCart}
					disabled={isLoading}
				>
					<ReactSVG src={CartIcon} />
				</button>
			</div>
			<p>{name}</p>
			<div className="mkt-product-card__footer">
				<QuantitySelector
					quantity={quantity}
					onDecrease={onDecreaseQuantity}
					onIncrease={onIncreaseQuantity}
					isDisabled={isLoading}
				/>
				<h4>{formatCurrency(price)}</h4>
			</div>
		</div>
	);
}
