import CreateTranscationPin from "components/dashboard/components/security/CreateTranscationPin";
import CustomPinInput from "components/utils/CustomPinInput/CustomPinInput";
import { getErrorMessage } from "components/utils/helpers";
import ModalHeader from "components/utils/Modal/CustomModalHeader/CustomModalHeader";
import Modal from "components/utils/Modal/Modal";
import { SmallLoader } from "components/utils/SmallLoader";
import { ERROR, FINISHED, IDLE, INDIVIDUAL, LOADING } from "config/enums";
import { API } from "lib/api";
import { completeBillsPaymentV2 } from "lib/api/apiRoutesConfig/services/BillsService/subRoutes";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ToastsStore } from "react-toasts";
import BillsPaymentSuccessModal from "../../../components/BillsPaymentSuccessModal/BillsPaymentSuccessModal";
import "../../BillPayment.scss";

export default function RemitaViaRRRSummary({ show, transaction, onClose }) {
	const [currentState, setCurrentState] = useState(IDLE);
	const [successMsg, setSuccessMsg] = useState("");
	const [transactionResponse, setTransactionResponse] = useState();

	const renderBasedOnState = () => {
		switch (currentState) {
			case FINISHED:
				return (
					<BillsPaymentSuccessModal
						message={successMsg}
						transaction={transaction}
						transactionResponse={transactionResponse}
					/>
				);
			default:
				return (
					<SummaryDetails
						setCurrentState={(state) => setCurrentState(state)}
						setSuccessMsg={(message) => setSuccessMsg(message)}
						setTransactionResponse={(response) =>
							setTransactionResponse(response)
						}
						currentState={currentState}
						transaction={transaction}
						onClose={onClose}
					/>
				);
		}
	};

	return (
		<Modal show={show} scrollable>
			{currentState !== FINISHED && (
				<ModalHeader
					heading="Remita Confirmation"
					className="p-0"
					headerClassName="setup-modals-header px-sm-5 px-3 py-3"
					dismissable={onClose}
				/>
			)}
			<>{renderBasedOnState()}</>
		</Modal>
	);
}

const SummaryDetails = ({
	transaction,
	setCurrentState,
	currentState,
	setSuccessMsg,
	setTransactionResponse,
	onClose,
}) => {
	const [transactionPin, setTransactionPin] = useState("");
	const { dashboardResponse, loginResponse, activeAccountType } = useSelector(
		(state) => ({
			dashboardResponse: state.dashboard.dashboardResponse,
			loginResponse: state.auth.loginResponse,
			activeAccountType: state.auth.activeAccountType,
		})
	);

	const { accountId: debitAccountId } = dashboardResponse?.bankAccounts
		? dashboardResponse.bankAccounts[0]
		: {};

	const accountType =
		activeAccountType === INDIVIDUAL
			? "activeIndividualAccount"
			: "activeCorporateAccount";

	const { transactionPinCreated } =
		loginResponse[accountType]?.profileSetting || {};

	const { totalAmount, rrr, vasItemId } = transaction || {};

	const handleSubmit = async () => {
		setCurrentState(LOADING);
		try {
			const res = await API.post(completeBillsPaymentV2(vasItemId), {
				amount: totalAmount,
				customerId: rrr,
				rrr,
				debitAccountId,
				transactionPin,
			});
			if (res.status === 200) {
				setCurrentState(FINISHED);
				setSuccessMsg(res.data.message);
				setTransactionResponse(res.data.data);
			}
		} catch (err) {
			setCurrentState(ERROR);
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		}
	};

	return (
		<div className="bp-summary-form px-sm-5 px-3">
			{transactionPinCreated ? (
				<CustomPinInput
					hasDashedBorder
					length={4}
					secret
					title="Enter Transaction PIN to confirm"
					containerStyle={{ flexWrap: "wrap" }}
					onChange={(value) => {
						setTransactionPin(value);
					}}
				/>
			) : (
				<CreateTranscationPin
					ConfirmTranscation={handleSubmit}
					toggleModal={onClose}
				/>
			)}

			{transactionPinCreated && (
				<div className="bp-summary-form__footer">
					<button
						className="btn btn-custom-bordered"
						onClick={onClose}
					>
						Cancel
					</button>

					<button
						className="btn create-acc"
						disabled={
							transactionPin.length !== 4 ||
							currentState === LOADING
						}
						onClick={handleSubmit}
					>
						{currentState === LOADING ? (
							<SmallLoader />
						) : (
							"Confirm Transaction"
						)}
					</button>
				</div>
			)}
		</div>
	);
};
