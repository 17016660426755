import { CLEAR_VERIFY, UPDATE_STEP_DATA } from "../actions/types";
import { employmentStatus, basicInfoSteps } from "../config/enums";

const defaultValues = {
	codeVerify: {
		verifyStage: false,
		isVerified: false,
	},
	stepData: {
		accountType: "",
		basicInfoStep: basicInfoSteps.ADDRESS,
		withReferral: false,
		personalDetails: {
			businessName: "",
			businessNumber: "",
			referralCode: "",
			stateCode: "",
			lgaCode: "",
			email: "",
			gender: "MALE",
			address: "",
			employmentStatus: employmentStatus.EMPLOYED,
			mintAwarenessSource: "",
			phoneNumber: "",
		},
		customerImageDetails: {
			image: "",
		},
	},
};

const initState = {
	...defaultValues,
	currentStep: 1,
	registrationEnums: {},
};

export const NEXT_STEP = "NEXT_STEP";
export const PREVIOUS_STEP = "PREVIOUS_STEP";
export const SET_STEP = "SET_STEP";

export const SET_VERIFICATION_STAGE = "SET_VERIFICATION_STAGE";
export const SAY_VERIFIED = "SAY_VERIFIED";

export const UPDATE_PERSONAL = "UPDATE_PERSONAL";
export const UPDATE_EMPLOYMENT = "UPDATE_EMPLOYMENT";
export const UPDATE_CUSTOMER_IMAGE = "UPDATE_CUSTOMER_IMAGE";

export const UPDATE_ENUMURATIONS = "UPDATE_ENUMURATIONS";

export const nextStep = () => ({ type: NEXT_STEP });
export const prevStep = () => ({ type: PREVIOUS_STEP });
export const setStep = (step) => ({ type: SET_STEP, payload: step });

export const updatePersonal = (field, value) => ({
	type: UPDATE_PERSONAL,
	payload: field,
	value: value,
});
export const updateEmployment = (field, value) => ({
	type: UPDATE_EMPLOYMENT,
	payload: field,
	value: value,
});
export const updateCustomerImageDetails = (image) => ({
	type: UPDATE_CUSTOMER_IMAGE,
	payload: image,
});

export const clearStage = (data = {}) => ({
	type: CLEAR_VERIFY,
	payload: data,
});

// VERIFICATION STAGE
export const setStage = () => ({ type: SET_VERIFICATION_STAGE });
export const sayVerified = () => ({ type: SAY_VERIFIED });

export const updateEnums = (data) => ({
	type: UPDATE_ENUMURATIONS,
	payload: data,
});

export const updateCustomer = (data) => ({
	type: "UPDATE_CUSTOMER",
	payload: data,
});

export const updateStepData = (data) => ({
	type: UPDATE_STEP_DATA,
	payload: data,
});

export default (state = initState, action) => {
	switch (action.type) {
		case SET_VERIFICATION_STAGE:
			return {
				...state,
				codeVerify: {
					...state.codeVerify,
					verifyStage: true,
				},
			};
		case SAY_VERIFIED:
			return {
				...state,
				codeVerify: {
					...state.codeVerify,
					isVerified: true,
				},
			};
		case CLEAR_VERIFY:
			return {
				...state,
				...defaultValues,
				...action.payload,
			};

		case UPDATE_ENUMURATIONS:
			return { ...state, registrationEnums: action.payload };
		case NEXT_STEP:
			return { ...state, currentStep: state.currentStep + 1 };

		case SET_STEP:
			return { ...state, currentStep: action.payload };

		case PREVIOUS_STEP:
			return { ...state, currentStep: state.currentStep - 1 };

		case UPDATE_PERSONAL:
			return {
				...state,
				stepData: {
					...state.stepData,
					personalDetails: {
						...state.stepData.personalDetails,
						[action.payload]: action.value,
					},
				},
			};

		case UPDATE_EMPLOYMENT:
			return {
				...state,
				stepData: {
					...state.stepData,
					employmentDetails: {
						...state.stepData.employmentDetails,
						[action.payload]: action.value,
					},
				},
			};

		case UPDATE_CUSTOMER_IMAGE:
			return {
				...state,
				stepData: {
					...state.stepData,
					customerImageDetails: {
						...state.stepData.customerImageDetails,
						Image: action.payload,
					},
				},
			};
		case UPDATE_STEP_DATA:
			return {
				...state,
				stepData: {
					...state.stepData,
					...action.payload,
				},
			};

		default:
			return state;
	}
};
