import React from "react";
import { connect } from "react-redux";
import { greetCustomer } from "../../../../../../utils/helpers";
import "./DashboardIntro.scss";

const DashboardIntro = ({ currentUser: { firstName } = {}, bankAccounts }) => {
    const { accountNumber } = bankAccounts ? bankAccounts[0] : {};
    return (
        <div className='dashboardIntro'>
            <h4 className='dashboardIntro__heading'>
                {`${greetCustomer()}, ${(firstName !== undefined) ? firstName : ""}`}
            </h4>
            <p
                className='dashboardIntro__paragraph'
                style={{ marginBottom: "5px" }}>
                <span className='dashboardIntro__paragraph__bigTitle'>
                    Account Number: <strong>{accountNumber || "Pending"}</strong>
                </span>
                <span className='dashboardIntro__paragraph__accNumber-text pl-2'></span>
            </p>
            {/* <p className='dashboardIntro__paragraph'>
                <span className='dashboardIntro__paragraph__bigTitle'>
                    Last Login :
                </span>
            </p> */}
        </div>
    );
};

export default connect(
    ({ auth, dashboard }) => ({
        currentUser: auth.currentUser,
        bankAccounts: dashboard.dashboardResponse.bankAccounts
    }),
    null
)(DashboardIntro);
