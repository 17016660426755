import React from "react";
import Logo from "assets/img/new-logo.png";
import { resendOTPWebAuth } from "../../../../lib/api/apiRoutesConfig/services/AccountMS/login/web/subRoutes";
import ResendConfirmationCode from "../../../customers/Onboarding/Verification/PhoneNumberOTPVerification/ResendConfirmationCode/ResendConfirmationCode";
import ReactHelmet from "../../../utils/ReactHelmet/ReactHelmet";
import FadeIn from "../../../dashboard/components/effects/FadeIn";
import { PHONE_VERIFICATION } from "../../../../config/enums";
import TwoFactorAuthenticationForm from "./TwoFactorAuthenticationForm/TwoFactorAuthenticationForm";

const TwoFactorAuthentication = ({ history }) => (
	<>
		<ReactHelmet title="Two Factor Authentication" />
		<FadeIn>
			<div className="auth-container">
				<div className="row h-100 justify-content-center align-items-center">
					<div className="col-xl-6 col-lg-8 col-md-9">
						<div className="verif-center px-4">
							{/* <div className="text-center mb-5">
								<Logo />
							</div> */}
							<div className="mintyn-logo mt-0">
								<a href="https://mintyn.com/">
									<img
										alt="Mintyn Bank"
										width={500}
										height={500}
										src={Logo}
									/>
								</a>
							</div>

							<div className="my-5">
								<h6 className="info-heading mb-3">
									Two-Factor Authentication
								</h6>
								<p className="info-text px-xl-5 px-0 ">
									Your account is being protected for extra
									security using two-factor authentication.
									We’ve sent a message to your phone number .
									Please enter the code below.
								</p>

								<TwoFactorAuthenticationForm
									history={history}
								/>
							</div>
						</div>

						<div className="px-4">
							<ResendConfirmationCode
								type={PHONE_VERIFICATION}
								endPoint={resendOTPWebAuth}
								useText
							/>
						</div>
					</div>
				</div>
			</div>
		</FadeIn>
	</>
);

export default TwoFactorAuthentication;
