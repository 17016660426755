import React from "react";
import { useHistory } from "react-router-dom";
import { formatCurrency } from "../../../../../utils/helpers";

const SingleActiveInvestment = ({
	investment,
	count,
	investmentId,
	setInvestmentId,
}) => {
	const { push } = useHistory();
	const active = investmentId === investment?.code ? "active" : "";

	return (
		<div
			className={`investment-card pointer ${active}`}
			onClick={() => setInvestmentId(investment?.code)}
		>
			<ul>
				<li>
					<h6 className="text--main font-weight-bold">
						Investment #{count}
					</h6>
					<button
						className="btn btn-sm create-accs gradient w-auto"
						onClick={() =>
							push(`/investments/details`, {
								state: { ...investment, count },
							})
						}
					>
						View Details
					</button>
				</li>
				<li>
					<div>
						<p>Total Expected returns</p>
						<h4>
							{formatCurrency(investment?.totalExpectedReturn)}
						</h4>
					</div>
					<div>
						<p>Profit Percentage</p>
						<h4 className="text-right">
							{investment?.interestRate}% P/A
						</h4>
					</div>
				</li>

				<li>
					<div>
						<p>Total Invested</p>
						<h4>{formatCurrency(investment?.amountInvested)}</h4>
					</div>
					<div>
						<p>Duration</p>
						<h4>
							{investment.durationInMonths} month
							{investment.durationInMonths > 1 && "s"}
						</h4>
					</div>
				</li>

				<li>
					<p className="m-0 font-weight-bold">
						Matures on {investment?.maturityDate}
					</p>
					<p className="m-0 font-weight-bold">#{investment?.code}</p>
				</li>
			</ul>
		</div>
	);
};

export default SingleActiveInvestment;
