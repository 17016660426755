import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
// import { accessToken } from "../config/security";

export default ({ component: Component, SaveContinue, ...rest }) => {
	const { isLoggedIn } = useSelector((state) => state.auth);
	//accessToken !== undefined
	return (
		<Route
			{...rest}
			render={(props) =>
				isLoggedIn ? (
					<Redirect
						to={{
							pathname: "/dashboard",
							state: { from: props.location },
						}}
					/>
				) : (
					<Component {...props} />
				)
			}
		/>
	);
};
