import React from "react";
import imageFiles from "../../../../../common/imageFiles/imageFiles";
import { ReactSVG } from "react-svg";

const { faceBookIcon, whatsappIcon, instagramIcon, twitterIcon, linkedInIcon } =
	imageFiles.icons;

const OnSocialMedia = () => {
	return (
		<div className="card">
			<div className="card-header border-bottom-0" id="headingThree">
				<h5 className="mb-0">
					<span className=" sweet_subText">
						Reach us on social media
					</span>
				</h5>
			</div>

			<div className="py-1">
				<div className="d-flex w-100" id="socials">
					<a
						href="https://www.facebook.com/mintynapp"
						rel="noopener noreferrer"
						target="_blank"
					>
						<ReactSVG src={faceBookIcon} title="Mintyn on Facebook" />
					</a>
					<a
						href="https://wa.me/+2349066771851"
						rel="noopener noreferrer"
						target="_blank"
					>
						<ReactSVG
							src={whatsappIcon}
							title="Mintyn on Whatsapp"
						/>
					</a>
					<a
						href="https://www.linkedin.com/company/mymintapp/"
						rel="noopener noreferrer"
						target="_blank"
					>
						<ReactSVG
							src={linkedInIcon}
							title="Mintyn on Linkedin"
						/>
					</a>
					<a
						href="https://instagram.com/mintynapp"
						rel="noopener noreferrer"
						target="_blank"
					>
						<ReactSVG
							src={instagramIcon}
							title="Mintyn on Instagram"
						/>
					</a>
					<a
						href="https://www.twitter.com/mintynapp"
						rel="noopener noreferrer"
						target="_blank"
					>
						<ReactSVG src={twitterIcon} title="Mintyn on Twitter" />
					</a>
				</div>
			</div>
		</div>
	);
};

export default OnSocialMedia;
