import React, { Component } from "react";

export default class Overlay extends Component {
	render = () => {
		return (
			<div id="overlay">
				<button
					type="button"
					id="toggleSideNav"
					className="btn btn-close"
					aria-label="Close"
					onClick={() => {
						const sidebar = document.getElementById("sidebar");
						const content = document.getElementById("content");
						const overlay = document.getElementById("overlay");

						sidebar.classList.toggle("active");
						content.classList.toggle("active");
						overlay.style.display = "none";
					}}
				>
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
		);
	};
}
