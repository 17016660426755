import React, { Component } from "react";
import CustomTable from "../../../../utils/CustomTable/CustomTable";

const tHeaders = [
    {
        cell: "Account Holder",
    },
    {
        cell: "Bank",
    },
    {
        cell: "",
    },
    {
        cell: "",
    },
];

class AllBeneficiariesTable extends Component {
    render() {
        const { bodyData } = this.props;
        return (
            <div className='container px-5 pt-3'>
                {bodyData.length > 0 ? (
                    <>
                        <CustomTable
                            tableId='beneficiary'
                            headers={tHeaders}
                            bodyData={bodyData}
                        />
                    </>
                ) : (
                    <h6 className='notready'>No Beneficiary Records</h6>
                )}
            </div>
        );
    }
}

export default AllBeneficiariesTable;
