import React, { Component } from "react";

export default class ReviewBeneficiary extends Component {
    render = () => {
        const {
            request: { accountName, accountNumber, bankName },
            toggleModal,
            completeAddition,
        } = this.props;

        return (
            <div className='px-4 py-1'>
                <div className='dialog-info'>
                    <div className='row'>
                        <div className='col-6'>
                            <label>Name</label>
                            <p>{accountName}</p>
                        </div>
                        <div className='col-6'>
                            <div className='ml-auto block-circle'>
                                {accountName && accountName[0]}
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-6'>
                            <label className='lite'>Bank</label>
                            <p>{bankName}</p>
                        </div>
                        <div className='col-6'>
                            <div className='text-right'>
                                <label>ACCOUNT NO</label>
                                <p>{accountNumber}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='dialog-footer mt-4 mb-4'>
                    <div className='row'>
                        <div className='col-6'>
                            <button
                                className='btn cancel'
                                data-dismiss='modal'
                                type='button'
                                aria-label='Close'
                                onClick={toggleModal}>
                                CANCEL
                            </button>
                        </div>

                        <div className='col-6'>
                            <button
                                className='btn create-acc'
                                type='submit'
                                onClick={completeAddition}>
                                Add beneficiary
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}
