import React from "react";
import ShineLoader from "../../../../../../utils/ShineLoader/ShineLoader";

const LTSkeletonLoader = () => {
    return (
        <>
            <table className='table transcation-table'>
                <tbody>
                    {Array.from(Array(4)).map((_, i) => (
                        <tr key={i}>
                            <td>
                                <div className='row'>
                                    <div className='col-8'>
                                        <ShineLoader />
                                        <ShineLoader width='100px' />
                                    </div>
                                    <div className='col-4'>
                                        <ShineLoader
                                            width='50px'
                                            customClass='ml-auto'
                                        />
                                    </div>
                                </div>
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td></td>
                    </tr>
                    <tr>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </>
    );
};

export default LTSkeletonLoader;
