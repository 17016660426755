import React, { useState, useEffect } from "react";
import { API } from "../../../lib/api";
import { getAwarenessChannel } from "../../../lib/api/apiRoutesConfig/services/AccountMS/common/subRoutes";
import Select from "react-select";
import { SelectBoxV2 } from "../../styles/SelectBoxV2";

const AwarenessChannelSelectBoxV2 = ({
	label,
	name,
	placeholder,
	value,
	error,
	onChange,
	defaultValue,
	getOptionLabel = ({ name }) => name,
	getOptionValue = ({ code }) => code,
}) => {
	const [awarenessChannel, setAwarenessChannel] = useState();

	useEffect(() => {
		fetchAwarenessChannel();
	}, []);

	async function fetchAwarenessChannel() {
		try {
			// eslint-disable-next-line no-undef
			const response = await API.get(getAwarenessChannel);
			if (response.status === 200) {
				setAwarenessChannel(response.data.data);
			}
		} catch (error) {
			setAwarenessChannel([]);
		}
	}

	return (
		<div className="select-field-container">
			<label htmlFor={name}>{label}</label>

			<Select
				inputId={name}
				name={name}
				placeholder={placeholder}
				isMulti={false}
				isSearchable={true}
				options={awarenessChannel}
				value={value}
				isLoading={!awarenessChannel}
				defaultValue={defaultValue}
				getOptionLabel={getOptionLabel}
				getOptionValue={getOptionValue}
				classNamePrefix="react-select"
				styles={SelectBoxV2(error)}
				onChange={onChange}
			/>
			{/* {error && <span className="error-msg">{error}</span>} */}
		</div>
	);
};

export default AwarenessChannelSelectBoxV2;
