import FadeIn from "components/dashboard/components/effects/FadeIn";
import BackButton from "components/utils/BackButton/BackButton";
import { formatCurrency } from "components/utils/helpers";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useLocation } from "react-router-dom";
import { MktTopNav } from "../utils/MktTopNav";
import QuantitySelector from "../QuantitySelector/QuantitySelector";
import "./ProductDetails.scss";
import useAddToCart from "../utils/hooks/useAddToCart";
import { LOADING } from "config/enums";
import useUpdateCartItem from "../utils/hooks/useUpdateCartItem";
import {
	checkIfProductExistsInCart,
	getExistingCartItemId,
	GROUP_CART,
} from "../utils/constant";
import { useSelector } from "react-redux";
import { SmallLoader } from "components/utils/SmallLoader";

export default function ProductDetails() {
	const history = useHistory();
	const location = useLocation();
	const { cart } = useSelector((state) => state.marketPlace);
	const { product, cartType } = location.state || {};
	const { imageUrl, name, price, productCode } = product || {};
	const { groupCart, personalCart } = cart || {};
	const currentCart = cartType === GROUP_CART ? groupCart : personalCart;
	const { cartItems } = currentCart || {};

	const [quantity, setQuantity] = useState(1);
	const { currentStatus, handleAddToCart } = useAddToCart();
	const { currentStatus: updateStatus, handleUpdateCartItem } =
		useUpdateCartItem();

	const isLoading = currentStatus === LOADING || updateStatus === LOADING;

	const onDecreaseQuantity = () => {
		if (quantity === 1) return;
		setQuantity(quantity - 1);
	};

	const onIncreaseQuantity = () => {
		setQuantity(quantity + 1);
	};

	const _addToCart = () => {
		if (checkIfProductExistsInCart(cartItems, productCode)) {
			const cartItemId = getExistingCartItemId(cartItems, productCode);
			handleUpdateCartItem({ cartItemId, quantity }, cartType);
		} else {
			handleAddToCart({
				cartType,
				productCode,
				quantity,
			});
		}
	};

	return (
		<>
			<Helmet>
				<title>
					{process.env.REACT_APP_NAME} - Market Place (Product
					Details)
				</title>
			</Helmet>

			<FadeIn>
				<section className="pb-5 mkt-product-details market-place">
					<MktTopNav cartType={cartType} />
					<div className="mkt-back-button">
						<BackButton
							label="Home"
							onClick={() => history.goBack()}
						/>
					</div>

					<h1>Product Details</h1>

					<div className="mkt-product-details__container">
						<div className="product-details-img">
							<img alt={name} src={imageUrl} />
						</div>
						<div className="product-details-content">
							<h2>{name}</h2>
							<h3>{formatCurrency(price)}</h3>
							<div className="product-details-content__footer">
								<QuantitySelector
									cls="mkt-product-quantity-selector"
									quantity={quantity}
									onDecrease={onDecreaseQuantity}
									onIncrease={onIncreaseQuantity}
									isDisabled={isLoading}
								/>
								<button
									className="btn btn-mint-default"
									disabled={isLoading}
									onClick={_addToCart}
								>
									{isLoading ? (
										<SmallLoader />
									) : (
										"Add to Cart"
									)}
								</button>
							</div>
						</div>
					</div>
				</section>
			</FadeIn>
		</>
	);
}
