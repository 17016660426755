export const tableHeadings = [
	{
		cell: "S/N",
	},
	{
		cell: "Transaction Date",
	},
	{
		cell: "Time",
	},
	{
		cell: "Transfer amount",
	},
	{
		cell: "Narration",
	},
	{
		cell: "Transaction Ref",
	},
	{
		cell: "Details",
	},
];

export const transactionTypeEnums = {
	CREDIT: "CREDIT",
	DEBIT: "DEBIT",
};
