import React from "react";
import { FlutterWaveButton } from "react-flutterwave";

const CustomFlutterWaveBtn = ({ fwConfig, handleCallback, handleClose }) => {
	const fwBtnConfig = {
		...fwConfig,
		text: "Pay with Flutterwave!",
		callback: () => handleCallback(),
		onClose: () => handleClose(),
	};

	return (
		<div className="button__container" id="flutterwave-btn">
			<FlutterWaveButton {...fwBtnConfig} />
		</div>
	);
};

export default CustomFlutterWaveBtn;
