import ModalHeader from "components/utils/Modal/CustomModalHeader/CustomModalHeader";
import Modal from "components/utils/Modal/Modal";
import { ERROR, FINISHED, IDLE, LOADING } from "config/enums";
import { API } from "lib/api";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./SetupModals.scss";
import SetupSuccessModal from "./SetupSuccessModal";
import { businessDocument } from "lib/api/apiRoutesConfig/services/Corporate/AccountServiceMS/accountSetup/subRoutes";
import { ToastsStore } from "react-toasts";
import { getErrorMessage } from "components/utils/helpers";
import { Formik } from "formik";
import { SmallLoader } from "components/utils/SmallLoader";
import CustomFileUploader from "components/utils/CustomFileUploader/CustomFileUploader";
import * as yup from "yup";

const SUPPORTED_FORMATS = ["image/png", "image/jpg", "image/jpeg"];
const FILE_SIZE = 3_000_000;

const formValidationSchema = yup.object().shape({
	documentFile: yup
		.mixed()
		.test("is-a-supported-type", "Unsupported file format", (value) => {
			if (value) return SUPPORTED_FORMATS.includes(value?.type);
			return true;
		})
		.test(
			"is-not-more-than-max-file-size",
			"File size is too large",
			(value) => {
				if (value) return value?.size <= FILE_SIZE;
				return true;
			}
		)
		.required("Document is required"),
});

export default function CACModal({ show, onClose, onSuccess }) {
	const [currentState, setCurrentState] = useState(IDLE);
	const [successMsg, setSuccessMsg] = useState("");

	const renderBasedOnState = () => {
		switch (currentState) {
			case FINISHED:
				return (
					<SetupSuccessModal
						title="Pending Verification"
						message={successMsg}
						buttonLabel="Close"
						onClick={() => onSuccess?.()}
					/>
				);
			default:
				return (
					<CACUploadForm
						setCurrentState={(state) => setCurrentState(state)}
						setSuccessMsg={(message) => setSuccessMsg(message)}
						currentState={currentState}
					/>
				);
		}
	};

	return (
		<Modal show={show}>
			{currentState !== FINISHED && (
				<ModalHeader
					heading="CAC Document"
					className="p-0"
					headerClassName="setup-modals-header px-sm-5 px-3 py-3"
					dismissable={() => onClose?.()}
				/>
			)}
			<>{renderBasedOnState()}</>
		</Modal>
	);
}

const CACUploadForm = ({ currentState, setCurrentState, setSuccessMsg }) => {
	const dashboardResponse = useSelector(
		(state) => state.dashboard.dashboardResponse
	);

	const initialFormValues = {
		documentFile: null,
	};

	const onHandleSubmit = async (values) => {
		setCurrentState(LOADING);
		const payload = new FormData();
		payload.append("documentFile", values.documentFile || "");
		try {
			const res = await API.put(
				businessDocument(dashboardResponse.mintAccountId),
				payload,
				{
					params: {
						documentType: "CAC_DOCUMENT",
					},
				}
			);
			if (res.status === 200) {
				setCurrentState(FINISHED);
				setSuccessMsg(res.data.message);
			}
		} catch (err) {
			setCurrentState(ERROR);
			ToastsStore.error(getErrorMessage(err), 6000, "setup-toast");
		}
	};

	return (
		<Formik
			initialValues={initialFormValues}
			validationSchema={formValidationSchema}
			onSubmit={(values) => onHandleSubmit(values)}
		>
			{({
				values,
				errors,
				touched,
				handleSubmit,
				setFieldValue,
				handleChange,
			}) => {
				return (
					<form
						className="setup-modals-form px-sm-5 px-3 pt-4 pb-5"
						onSubmit={(e) => {
							e.preventDefault();
							handleSubmit();
						}}
					>
						<div className="mb-3">
							<CustomFileUploader
								label="Upload a copy of your CAC Document"
								name="documentFile"
								error={
									touched.documentFile && errors.documentFile
								}
								fileName={values.documentFile?.name}
								onChange={(file) => {
									setFieldValue("documentFile", file);
								}}
							/>
						</div>

						<div>
							<button
								className="btn btn-mint-default"
								type="submit"
								disabled={currentState === LOADING}
							>
								{currentState === LOADING ? (
									<SmallLoader />
								) : (
									"Upload and Save"
								)}
							</button>
						</div>
					</form>
				);
			}}
		</Formik>
	);
};
