import Axios from "axios";
import CustomPagination from "components/dashboard/utils/CustomPagination/CustomPagination";
import FailedTryAgain from "components/dashboard/utils/FailedTryAgain/FailedTryAgain";
import { MintInfinite } from "components/dashboard/utils/ShineLoader/ShineLoader";
import { getErrorMessage } from "components/utils/helpers";
import { ERROR, FINISHED, IDLE, LOADING } from "config/enums";
import { API } from "lib/api";
import { getSavingGoalTransactions } from "lib/api/apiRoutesConfig/services/SavingsMS/savingsGoals/subRoutes";
import React, { useEffect, useState } from "react";
import SavingGoal from "../SavingGoal/SavingGoal";
import SavingsGoalTransactions from "../SavingsGoalTransactions/SavingsGoalTransactions";

export default function SavingGoalsWrapper({ data = [] }) {
	const [transactions, setTransactions] = useState({});
	const [currentState, setCurrentState] = useState(IDLE);
	const [errorMsg, setErrorMsg] = useState("");
	const [page, setPage] = useState(0);
	const [size] = useState(10);
	const [currentGoalId, setCurrentGoalId] = useState(null);

	const signal = Axios.CancelToken.source();

	const fetchSavingGoalTransactions = async (goalId) => {
		setCurrentState(LOADING);
		try {
			const response = await API.get(getSavingGoalTransactions(goalId), {
				params: {
					page,
					size,
				},
				cancelToken: signal.token,
			});
			const { status, data } = response || {};
			if (status === 200) {
				setTransactions(data.data);
				setCurrentState(FINISHED);
			}
		} catch (err) {
			setErrorMsg(getErrorMessage(err));
			setTransactions({});
			setCurrentState(ERROR);
		}
	};

	const goToPage = (page) => {
		setPage(page);
	};

	const cleanup = () => {
		signal.cancel("canceling network calls");
	};

	useEffect(() => {
		if (currentGoalId) {
			fetchSavingGoalTransactions(currentGoalId);
		}
		return cleanup;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, size, currentGoalId]);

	useEffect(() => {
		if (data.length > 0) {
			setCurrentGoalId(data[0]?.goalId);
		}
		return cleanup;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const renderBasedOnState = () => {
		const { records, totalPages } = transactions || {};

		switch (currentState) {
			case LOADING:
				return (
					<div className="d-flex w-100 justify-content-center align-items-center saving-goal-loading">
						<MintInfinite relative />
					</div>
				);

			case ERROR:
				return (
					<div className="d-flex w-100 justify-content-center align-items-center saving-goal-loading">
						<FailedTryAgain
							errorMssg={errorMsg}
							handleTryAgain={() =>
								fetchSavingGoalTransactions(currentGoalId)
							}
						/>
					</div>
				);

			case FINISHED:
				if (records?.length <= 0) {
					return (
						<div className="d-flex w-100 justify-content-center align-items-center saving-goal-loading">
							<h6 className="notready">
								No Transactions For This Goal
							</h6>
						</div>
					);
				}

				return (
					<>
						<SavingsGoalTransactions data={records} />

						<CustomPagination
							currentPage={page}
							pageSize={totalPages}
							goToPage={goToPage}
						/>
					</>
				);

			default:
				return null;
		}
	};

	return (
		<div className="es-list-wrapper">
			<div className="es-list-horizontal-scroll">
				{data.map((item, index) => {
					const { goalId } = item || {};
					return (
						<SavingGoal
							key={index}
							savingGoalData={item}
							cls={currentGoalId === goalId ? "selected" : ""}
							onClick={() => {
								setCurrentGoalId(goalId);
								fetchSavingGoalTransactions(goalId);
							}}
						/>
					);
				})}
			</div>

			{currentState !== IDLE && (
				<div className="es-list-transactions">
					{renderBasedOnState()}
				</div>
			)}
		</div>
	);
}
