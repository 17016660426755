/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Modal from "components/utils/Modal/Modal";
import ModalHeader from "components/utils/Modal/CustomModalHeader/CustomModalHeader";
import CustomPinInput from "components/utils/CustomPinInput/CustomPinInput";
import { formatCurrency } from "components/utils/helpers";

const TransactionPin = ({
	data,
	show,
	onClose,
	nextModal,
	handleChange,
	pin,
}) => {
	return (
		<>
			<Modal show={show} scrollable>
				<ModalHeader
					heading={"Participant Data"}
					className="p-0"
					headerClassName="setup-modals-header px-sm-5 px-3 py-3"
					dismissable={() => onClose?.()}
				/>
				<Content
					nextModal={nextModal}
					data={data}
					handleChange={handleChange}
					pin={pin}
				/>
			</Modal>
		</>
	);
};
export default TransactionPin;

const Content = ({ nextModal, data, handleChange, pin }) => {
	return (
		<div className="transaction-pin-data-modal">
			<div className=" content">
				<form
					className="split-payment-form row"
					onSubmit={(e) => {
						e.preventDefault();
					}}
				>
					<div className="tip-container">
						<p className="title">Quick Tip</p>
						<p>
							You are about to accept and make a split payment of{" "}
							<span>{formatCurrency(data.amount)}</span> Please
							enter your Mintyn Transaction Pin to proceed with
							the transfer{" "}
						</p>
					</div>
					<div className="mb-4 col-12 ">
						<CustomPinInput
							hasDashedBorder
							className=""
							length={4}
							secret
							title="Enter Transaction PIN to confirm"
							containerStyle={{ columnGap: "30px" }}
							onChange={(value) => {
								handleChange(value);
							}}
						/>
					</div>

					<div className="col-12">
						<button
							className={`btn btn-mint-default text-capitalize `}
							onClick={() => nextModal()}
							type="button"
							disabled={pin === ""}
						>
							Continue
						</button>
					</div>
				</form>
			</div>
			<div className="col-12 "></div>
		</div>
	);
};
