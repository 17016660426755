import CustomPagination from "components/dashboard/utils/CustomPagination/CustomPagination";
import FailedTryAgain from "components/dashboard/utils/FailedTryAgain/FailedTryAgain";
import { MintInfinite } from "components/dashboard/utils/ShineLoader/ShineLoader";
import { ERROR, FINISHED, LOADING } from "config/enums";
import React from "react";
import { useHistory } from "react-router-dom";
import ProductCard from "../ProductCard/ProductCard";


export default function ProductsList({
	products,
	retry,
	errorMsg,
	currentStatus,
	page,
	goToPage,
	cartType,
}) {
	const { records, totalPages } = products || {};
	const history = useHistory();

	const renderBasedOnStatus = () => {
		switch (currentStatus) {
			case LOADING:
				return (
					<div className="mkt-main-loader">
						<MintInfinite relative />
					</div>
				);

			case ERROR:
				return (
					<div className="mkt-main-loader">
						<FailedTryAgain
							errorMssg={errorMsg}
							handleTryAgain={retry}
						/>
					</div>
				);

			case FINISHED:
				if (records?.length <= 0)
					return <h6 className="notready">No Product</h6>;

				return (
					<>
						<div className="mkt-products mt-0">
							{records?.map((product, index) => {
								return (
									<ProductCard
										key={index}
										product={product}
										cartType={cartType}
										onClick={() =>
											history.push(
												"/market-place/product-details",
												{ product, cartType }
											)
										}
									/>
								);
							})}
						</div>

						<CustomPagination
							currentPage={page}
							pageSize={totalPages}
							goToPage={goToPage}
						/>
					</>
				);

			default:
				return null;
		}
	};

	return <div className="mkt-popular-items">{renderBasedOnStatus()}</div>;
}
