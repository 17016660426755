import React from "react";
import routes from "./routes";
import RouteContainer from "routes/RouteContainer";
import "./registerBusiness.scss";

const RegisterBusiness = () => {
	return (
		<div className="auth-container">
			<main className="reg-business">
				{/* <PublicNavbar /> */}
				<RouteContainer baseURL="/register-business" routes={routes} />
			</main>
		</div>
	);
};
export default RegisterBusiness;
