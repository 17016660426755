import React, { Component } from "react";
import { connect } from "react-redux";
import Axios from "axios";
import { updateCustomer } from "../../../../../actions/registrationActions";
import { sayVerified, setStep } from "../../../../../reducers/steps";
import { SmallLoader } from "../../../../utils/SmallLoader";
import {
	bvnVerifCodeEndpoint,
	resendBvnVerifCode,
} from "../../../../../lib/api/apiRoutesConfig/services/AccountMS/signup/subRoutes";
import { API } from "../../../../../lib/api";
import { getErrorMessage } from "../../../../utils/helpers";
import ResendConfirmationCode from "./ResendConfirmationCode/ResendConfirmationCode";
import { PHONE_VERIFICATION } from "../../../../../config/enums";
import SEO from "../../../../../essentials/SEO/SEO";
import FormInputGroupV2 from "components/utils/FormInputGroup/FormInputGroupV2";

class PhoneNumberOTPVerificaion extends Component {
	_isMounted = false;
	signal = Axios.CancelToken.source();

	state = {
		code: "",
		resentStatus: false,
		confirmEmailError: false,
		loading: false,
		resending: false,
	};

	componentDidMount = () => {
		this._isMounted = true;
		window.dataLayer.push({ event: "VERIFICATION_screen_launched" });
	};

	componentWillUnmount = () => {
		this.signal.cancel("canceling network calls");
		this._isMounted = false;
	};

	handleInputChange = (e) => {
		let { name, value } = e.target;
		value = value.replace(/[^0-9]/g, "");
		this.setState({ [name]: value });
	};

	verifyCustomerCode = async () => {
		try {
			this.setState({ loading: true, confirmEmailError: false });
			const { code } = this.state;
			const { sessionId } = this.props?.newAccount || {};
			const url = bvnVerifCodeEndpoint(sessionId);
			const res = await API.post(
				url,
				{ code },
				{
					cancelToken: this.signal.token,
				}
			);
			if (res.status === 200) {
				this.props.history.replace(
					"/onboard/account-type-verification"
				);
			}
		} catch (err) {
			this._isMounted &&
				this.setState({
					loading: false,
					confirmEmailError: getErrorMessage(err),
				});
		}
	};

	handleSubmit = (e) => {
		e.preventDefault();
		let { code } = this.state;
		code.length === 6 && this.verifyCustomerCode();
	};

	render = () => {
		const { loading, confirmEmailError } = this.state;
		const { phoneNumber, email, verificationType } = this.props.newAccount;

		return (
			<>
				<SEO
					title={`Verify ${
						verificationType === PHONE_VERIFICATION
							? "Phone Number"
							: "Email Address"
					}`}
				/>
				<div className="row h-100 justify-content-center account-verification-panel">
					<div className="col-xl-9 col-lg-9 col-md-9">
						<div>
							<div>
								<h6 className="mb-3 info-heading text-center">
									Account Verification
								</h6>
								<p className="info-text mb-0">
									Provide the One-Time-Password (OTP) sent to
									your {verificationType}
								</p>
								<p className="info-text gradient mt-2">
									{verificationType === PHONE_VERIFICATION
										? phoneNumber
										: email}
								</p>

								{confirmEmailError && (
									<div className="desct error fade-in">
										<p>{confirmEmailError}</p>
									</div>
								)}

								<div className="mt-5">
									<form
										className="login-form"
										onSubmit={this.handleSubmit}
									>
										<FormInputGroupV2
											name="code"
											label="Enter six digit code"
											type="text"
											value={this.state.code}
											onChange={this.handleInputChange}
											maxLength={6}
											autoComplete="off"
											disabled={loading}
											required
										/>

										<button
											className="btn-primary w-100"
											disabled={loading}
										>
											{loading ? (
												<SmallLoader />
											) : (
												"Verify"
											)}
										</button>
									</form>
								</div>
							</div>
						</div>
					</div>

					<ResendConfirmationCode
						type={verificationType}
						endPoint={resendBvnVerifCode}
					/>
				</div>
			</>
		);
	};
}

export default connect((state) => ({ newAccount: state.auth.newAccount }), {
	sayVerified,
	updateCustomer,
	setStep,
})(PhoneNumberOTPVerificaion);
