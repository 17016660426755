import React from "react";
import { ReactSVG } from "react-svg";
import TickIcon from "assets/img/tick_circle_icon.svg";
import DirectorEnquiryIcon from "assets/img/director_enquiry_icon.svg";
import Modal from "components/utils/Modal/Modal";
import { SmallLoader } from "components/utils/SmallLoader";

export default function EnquiryModal({
	show,
	iconType,
	title,
	message,
	onPositive,
	onNegative,
	positiveLabel,
	negativeLabel,
	onClose,
	positiveBtnLoading,
}) {
	return (
		<Modal show={show} toggle={() => onClose?.()}>
			<div
				className="setup-success-modal"
				style={{ alignItems: "normal" }}
			>
				<ReactSVG
					src={iconType === "TIN" ? TickIcon : DirectorEnquiryIcon}
				/>
				{title && <h2 className="text-center">{title}</h2>}

				<p
					style={{
						maxWidth: "331px",
						textAlign: "center",
						alignSelf: "center",
					}}
				>
					{message}
				</p>

				<div className="d-flex">
					<button
						className="btn btn-mint-default mr-3"
						onClick={onPositive}
						style={{ textTransform: "none" }}
						disabled={positiveBtnLoading}
					>
						{positiveBtnLoading ? <SmallLoader /> : positiveLabel}
					</button>

					<button
						className="btn btn-custom-bordered"
						onClick={onNegative}
						style={{ textTransform: "none" }}
					>
						{negativeLabel}
					</button>
				</div>
			</div>
		</Modal>
	);
}
