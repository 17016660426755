import React from "react";
import { Helmet } from "react-helmet";
import FadeIn from "../effects/FadeIn";
import { Home } from "./components/layouts/home";
import { PERSONAL_CART } from "./components/utils/constant";

const PersonalShopping = () => {
	return (
		<>
			<Helmet>
				<title>
					{process.env.REACT_APP_NAME} - Market Place (Personal
					Shopping)
				</title>
			</Helmet>
			<FadeIn>
				<Home cartType={PERSONAL_CART} />
			</FadeIn>
		</>
	);
};

export default PersonalShopping;
