import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import {
	billCategoryRoutes,
	defaultPath,
} from "../../components/BillsPaymentRoutes/BillsPaymentRoutes";

export default function BillsPaymentCategory() {
	return (
		<Switch>
			{billCategoryRoutes.map((route, index) => (
				<Route
					key={index}
					exact
					path={route.path}
					component={route.component}
				/>
			))}
			<Redirect path="*" to={`${defaultPath}`} />
		</Switch>
	);
}
