import CustomCurrencyInput from "components/utils/CustomCurrencyInput/CustomCurrencyInput";
import FormInputGroup from "components/utils/FormInputGroup/FormInputGroup";
import { parseAmount } from "components/utils/helpers";
import { Formik } from "formik";
import React from "react";
import * as yup from "yup";
import "../CreateSavingsGoal.scss";

const formValidationSchema = yup.object().shape({
	goalName: yup
		.string()
		.required("Please enter a goal name")
		.test(
			"len",
			"Goal name cannot be less than 3 characters.",
			(val) => val?.length >= 3
		)
		.test(
			"len",
			"Goal name cannot be more than 25 characters.",
			(val) => val?.length <= 25
		),
	targetAmount: yup
		.number()
		.required("Amount is required")
		.min(100, "Minimum amount is ₦100"),
});

export default function CreateGoalName({
	goalName,
	targetAmount,
	goToNextStep,
}) {
	const initialFormValues = {
		goalName: goalName !== "Others" ? goalName : "",
		targetAmount,
	};

	const handleSubmit = ({ goalName, targetAmount }) => {
		goToNextStep(goalName, targetAmount);
	};

	return (
		<div className="create-savings-goal-page__step shrink">
			<h2>Create Goal name</h2>
			<p>Select a new savings plan to get started</p>

			<Formik
				initialValues={initialFormValues}
				validationSchema={formValidationSchema}
				onSubmit={(values) => handleSubmit(values)}
			>
				{({
					values,
					errors,
					touched,
					handleSubmit,
					handleChange,
					handleBlur,
					setFieldValue,
				}) => {
					return (
						<form
							onSubmit={(e) => {
								e.preventDefault();
								handleSubmit();
							}}
						>
							<div className="mb-4">
								<FormInputGroup
									id="goalName"
									label="Goal Name"
									name="goalName"
									placeholder="Enter goal name"
									className="cesp-input"
									onBlur={handleBlur}
									value={values.goalName}
									onChange={handleChange}
									error={touched.goalName && errors.goalName}
								/>
								<p className="cesp-form-info">
									This is a description of what you want to
									save for
								</p>
							</div>

							<div className="mb-4">
								<CustomCurrencyInput
									className="cesp-input"
									name="targetAmount"
									label="What is your saving target (₦)"
									id="targetAmount"
									value={values.targetAmount}
									placeholder="Enter Amount"
									onBlur={handleBlur}
									error={
										touched.targetAmount &&
										errors.targetAmount
									}
									onChange={(value) => {
										setFieldValue(
											"targetAmount",
											parseAmount(value)
										);
									}}
								/>
								<p className="cesp-form-info">
									This is the amount you want to have saved up
									and available for withdrawal at the end of
									the saving period
								</p>
							</div>

							<button
								className="btn create-acc mt-3 text-capitalize"
								type="submit"
								disabled={
									!values.goalName || !values.targetAmount
								}
								style={{ height: "50px", borderRadius: "15px" }}
							>
								Continue
							</button>
						</form>
					);
				}}
			</Formik>
		</div>
	);
}
