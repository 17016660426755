import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Helmet from "react-helmet";
import Modal from "../../../../../utils/Modal/Modal";
import LocalBankAcctFunding from "./LocalBankAcctFunding/LocalBankAcctFunding";
import CardPaystackFunding from "./CardPaystackFunding/CardPaystackFunding";
import QuickTellerAcctFunding from "./QuickTellerAcctFunding/QuickTellerAcctFunding";
import "./FundMintAccount.scss";

const FundMintAccount = ({ show, dismissModal }) => {
	return (
		<>
			<Helmet>
				<title>
					{process.env.REACT_APP_NAME} - Fund Mintyn Account
				</title>
			</Helmet>
			<Modal show={show} size="xl" toggle={dismissModal}>
				<div className="seriesA px-4 py-5">
					<div className="text-center mt-2 mb-4">
						<h6>Fund Account</h6>
						<p>Fund your mintyn account with</p>
					</div>
					<div className="row">
						<LocalBankAcctFunding />
						<CardPaystackFunding dismissModal={dismissModal} />
						<QuickTellerAcctFunding />
					</div>
				</div>
			</Modal>
		</>
	);
};

const FundMintAccountModal = withRouter(FundMintAccount);

export default connect(
	(state, ownProps) => ({
		mydata: state.auth.dashboardResponse,
		...ownProps,
	}),
	null
)(FundMintAccountModal);
