import React from "react";
import "./CustomTabs.scss";

export default function CustomTabs({ className, children }) {
	return (
		<div className={`custom-tabs ${className ? className : ""}`}>
			<ul role="tablist">
				{children}
			</ul>
		</div>
	);
}
