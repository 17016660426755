import React from "react";
import UmbrellaIcon from "assets/img/umbrella_white.svg";
import { ReactSVG } from "react-svg";
import { formatCurrency, formatEnum } from "components/utils/helpers";
import CustomSwitch from "components/dashboard/utils/CustomSwitch/CustomSwitch";
import WithdrawalModal from "../modals/WithdrawalModal";
import { useState } from "react";
import DepositModal from "../modals/DepositModal";
import FundMintAccountModal from "components/dashboard/components/Landing/modals/FundMintAccount/FundMintAccount";

export default function EmergencySavingCard({
	savingsGoal,
	setShowModal,
	refetch,
}) {
	// TODO - ask about these representations
	const {
		savingsBalance,
		savingFrequency,
		savingsAmount,
		autoSaveEnabled,
		goalId,
	} = savingsGoal || {};

	const [showWithdrawalModal, setShowWithdrawalModal] = useState(false);
	const [showDepositModal, setShowDepositModal] = useState(false);
	const [showFundingModal, setShowFundingModal] = useState(false);

	return (
		<>
			<div className="es-saving-card">
				<div className="es-saving-card__header">
					<span>
						<ReactSVG src={UmbrellaIcon} />
					</span>
					<div>
						<div
							className="btn btn-savings-sm"
							style={{ cursor: "default" }}
						>
							Balance
						</div>
						<h3>{formatCurrency(savingsBalance)}</h3>
					</div>
				</div>

				<div className="d-flex justify-content-between">
					<div>
						<p>Frequency</p>
						<h4 className="text-capitalize">
							{formatEnum(savingFrequency)}
						</h4>
					</div>
					<div>
						<p>Automated Amount</p>
						<h4>{formatCurrency(savingsAmount)}</h4>
					</div>
				</div>

				<div
					className="d-flex justify-content-between align-items-center"
					style={{ marginTop: "32px" }}
				>
					<div>
						<p>Automatic Deposit</p>
						<h4>{autoSaveEnabled ? "Active" : "Inactive"}</h4>
					</div>
					<div>
						<CustomSwitch
							className="es-switch"
							inputName="autoSaveEnabled"
							isChecked={autoSaveEnabled}
							handleCheckBox={() => setShowModal(true)}
						/>
					</div>
				</div>

				<div className="es-saving-card__footer">
					<button
						className="btn btn-savings-lg"
						onClick={() => setShowDepositModal(true)}
					>
						Add Funds
					</button>

					<button
						className="btn btn-bordered-light"
						onClick={() => setShowWithdrawalModal(true)}
					>
						Withdraw
					</button>
				</div>
			</div>

			<WithdrawalModal
				show={showWithdrawalModal}
				goalId={goalId}
				// refetch={refetch}
				onClose={() => setShowWithdrawalModal(false)}
				savingBalance={savingsBalance}
			/>

			<DepositModal
				show={showDepositModal}
				refetch={refetch}
				savingsGoal={savingsGoal}
				onClose={() => setShowDepositModal(false)}
				openFundingModal={() => setShowFundingModal(true)}
			/>

			<FundMintAccountModal
				show={showFundingModal}
				dismissModal={() => setShowFundingModal(false)}
			/>
		</>
	);
}
