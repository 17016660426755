import React, { Component } from "react";
import { connect } from "react-redux";
import { ToastsStore } from "react-toasts";
import { updateNotificationSetting } from "../../../../../../../actions/settingsActions";
import CustomSwitch from "../../../../../utils/CustomSwitch/CustomSwitch";
import { notificationPreference } from "../../../../../../../lib/api/apiRoutesConfig/services/AccountMS/profile/settings/subRoutes";
import { API } from "../../../../../../../lib/api";
import { getErrorMessage } from "../../../../../../utils/helpers";

class AccountNotifications extends Component {
    state = {
        notificationsSettings: {},
    };

    handleCheckBox = async (inputName, notificationType) => {
        try {
            const { updateNotificationSetting } = this.props;
            const isEnabled = await updateNotificationSetting(inputName);
            const res = await API.put(notificationPreference, {
                enable: !isEnabled,
                notificationType,
            });
            if (res.status === 200) {
                ToastsStore.success(res.data.message, 3000, "right-toast");
            }
        } catch (err) {
            let errorMsg = getErrorMessage(err);
            ToastsStore.error(errorMsg, 3000, "right-toast");
        }
    };

    render() {
        const {
            emailEnabled,
            smsEnabled,
            gcmEnabled,
        } = this.props.notificationsSettings;
        return (
            <div className='accountSetting'>
                <h6 className='label--heading'>Notifications</h6>
                <div className='form-row'>
                    <div className='col-6'>
                        <label className='label--text'>
                            Email notifications
                        </label>
                    </div>
                    <div className='col-6'>
                        <CustomSwitch
                            inputName='emailEnabled'
                            className='float-right'
                            isChecked={emailEnabled}
                            handleCheckBox={() =>
                                this.handleCheckBox("emailEnabled", "EMAIL")
                            }
                        />
                    </div>
                </div>
                <div className='form-row'>
                    <div className='col-6'>
                        <label className='label--text'>
                            Push notifications
                        </label>
                    </div>
                    <div className='col-6'>
                        <CustomSwitch
                            inputName='gcmEnabled'
                            isChecked={gcmEnabled}
                            className='float-right'
                            handleCheckBox={() =>
                                this.handleCheckBox("gcmEnabled", "GCM")
                            }
                        />
                    </div>
                </div>
                <div className='form-row'>
                    <div className='col-6'>
                        <label className='label--text'>
                            SMS{" "}
                            <span className='lighter text-lowercase'>
                                (attracts extra charges)
                            </span>
                        </label>
                    </div>
                    <div className='col-6'>
                        <CustomSwitch
                            inputName='smsEnabled'
                            className='float-right'
                            isChecked={smsEnabled}
                            handleCheckBox={() =>
                                this.handleCheckBox("smsEnabled", "SMS")
                            }
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => ({ notificationsSettings: state.settings.notifications }),
    { updateNotificationSetting }
)(AccountNotifications);
