import React from "react";
import BigLoader from "../BigLoader";

const LoadingBox = () => {
    return (
        <div className='loading-box'>
            <div className='d-flex justify-content-center'>
                <BigLoader />
            </div>
        </div>
    );
};

export default LoadingBox;
