import { UPDATE_CART,UPDATE_CARTTYPE,ALLOW_CONFIRMATION,SEARCH_PRODUCTS,UPDATE_ADDRESS_ID,UPDATE_DEBIT_ACCOUNT_ID} from "../actions/types";

const initState = {
	cart: {},
	myCartType: '',
	enableConfirmation: false,
	searchTerm: '',
	debitAccountId: '',
	deliveryAddressId:''

};

export default (state = initState, action) => {
	switch (action.type) {
		case UPDATE_CART:
			return { ...state, cart: action.payload };
		
		case UPDATE_CARTTYPE:
			return { ...state, myCartType: action.payload };
		
		case ALLOW_CONFIRMATION:
			return { ...state, enableConfirmation: action.payload };
		
		case SEARCH_PRODUCTS:
			return { ...state, searchTerm: action.payload };
		
		case UPDATE_ADDRESS_ID:
			return { ...state, deliveryAddressId: action.payload };
		
		case UPDATE_DEBIT_ACCOUNT_ID:
			return { ...state, debitAccountId: action.payload };

		default:
			return state;
	}

};
