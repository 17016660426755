import React, { Component } from "react";
import settingRoutes from "../routes";
import { NavLink } from "react-router-dom";

class SettingsTabs extends Component {
	render() {
		return (
			<div className="pt-4">
				<ul className="nav nav-tabs" id="setTab" role="tablist">
					{settingRoutes &&
						settingRoutes.map(
							({ path, iconName, title }, index) => (
								<li className="nav-item" key={index}>
									<NavLink className="nav-link" to={path}>
										<span className={iconName}></span>
										{title}
									</NavLink>
								</li>
							)
						)}
				</ul>
			</div>
		);
	}
}

export default SettingsTabs;
