import React, { Component } from "react";
import "./TranscationPinInput.scss";

class TranscationPinInput extends Component {
    inputRef = React.createRef();

    state = {
        pin: {
            zero: "",
            one: "",
            two: "",
            three: "",
        },
    };

    componentDidMount = () => {
        // Add a timeout here
        if (this.inputRef) {
            setTimeout(() => {
                this.inputRef.current !== null && this.inputRef.current.focus();
            }, 1);
        }
    };

    setInitial = () => {
        this.setState({
            pin: {
                zero: "",
                one: "",
                two: "",
                three: "",
            },
        });
    };

    getPin = () => {
        const { pin } = this.state;
        const newPin = Object.values(pin).join("");
        return newPin;
    };

    shakeInvalid = () => {
        let pinInputs = document.getElementById("pinCode");
        pinInputs.classList.add("invalid");
        setTimeout(function () {
            pinInputs.classList.remove("invalid");
        }, 500);
    };

    moveOnMax = (e, nextFieldID) => {
        let { value, name } = e.target;
        value = value.replace(/[^0-9]/g, "");

        if (value.length === 1) {
            this.setState((prevState) => ({
                ...prevState,
                pin: {
                    ...prevState.pin,
                    [name]: value,
                },
            }));
            document.getElementById(nextFieldID).focus();
        }
    };

    goBack = async (e, ID) => {
        let { name } = e.target;
        if (e.keyCode === 8) {
            // e.target.value = "";
            await this.setState((prevState) => ({
                ...prevState,
                pin: {
                    ...prevState.pin,
                    [name]: "",
                },
            }));
            document.getElementById(ID).focus();
        }
    };

    handleLast = (e, ID) => {
        let { name } = e.target;
        if (e.keyCode === 8) {
            this.setState((prevState) => ({
                ...prevState,
                pin: {
                    ...prevState.pin,
                    [name]: "",
                },
            }));
            document.getElementById(ID).focus();
        }
    };

    handlePinInputChange = (e) => {
        let { name, value } = e.target;
        value = value.replace(/[^0-9]/g, "");
        this.setState((prevState) => ({
            ...prevState,
            pin: {
                ...prevState.pin,
                [name]: value,
            },
        }));
    };

    render = () => {
        const { zero, one, two, three } = this.state.pin;
        const { id, label, className } = this.props;
        return (
            <div className={`mt-2 mb-3 ${className}`}>
                {label && <label>{label}</label>}
                <div className='pin-input' id='pinCode'>
                    <input
                        name='zero'
                        value={zero}
                        ref={this.inputRef}
                        onChange={this.handlePinInputChange}
                        maxLength='1'
                        id={`zero-${id}`}
                        onKeyUp={(e) => this.moveOnMax(e, `one-${id}`)}
                        autoComplete='off'
                    />
                    <input
                        maxLength='1'
                        name='one'
                        value={one}
                        id={`one-${id}`}
                        onChange={this.handlePinInputChange}
                        onKeyUp={(e) => this.moveOnMax(e, `two-${id}`)}
                        onKeyDown={(e) => this.goBack(e, `zero-${id}`)}
                        autoComplete='off'
                    />
                    <input
                        maxLength='1'
                        name='two'
                        value={two}
                        onChange={this.handlePinInputChange}
                        id={`two-${id}`}
                        onKeyUp={(e) => this.moveOnMax(e, `three-${id}`)}
                        onKeyDown={(e) => this.goBack(e, `one-${id}`)}
                        autoComplete='off'
                    />
                    <input
                        maxLength='1'
                        name='three'
                        value={three}
                        id={`three-${id}`}
                        onChange={this.handlePinInputChange}
                        onKeyDown={(e) => this.handleLast(e, `two-${id}`)}
                        autoComplete='off'
                    />
                </div>
            </div>
        );
    };
}

TranscationPinInput.defaultProps = {
    id: 98895,
    label: "Transaction pin",
    className: "",
};

export default TranscationPinInput;
