import React from "react";
import Select from "react-select";
import { SelectBoxV2 } from "../../styles/SelectBoxV2";

const FormGroupSelect = ({
	label,
	name,
	isMulti,
	placeholder = "",
	value,
	className = "",
	options,
	defaultValue,
	error,
	onChange,
	required,
	...rest
}) => {
	return (
		<div className="select-field-container">
			<label>{label}</label>

			<Select
				inputId={name}
				name={name}
				placeholder={placeholder}
				isMulti={isMulti}
				isSearchable={true}
				options={options}
				value={value}
				isLoading={!options}
				defaultValue={defaultValue}
				classNamePrefix="react-select"
				styles={SelectBoxV2(error)}
				onChange={onChange}
				{...rest}
			/>

			{/* {error && <span className="error-msg">{error}</span>} */}
		</div>
	);
};

export default FormGroupSelect;
