export const AccountMS = {
	msName: "account-service",
	msVersion: "/api/v1",
	msVersion2: "/api/v2",
	msVersion3: "/api/v3",
	msVersion5: "/api/v5",

	get default() {
		return this.msName + this.msVersion;
	},

	get v2() {
		return this.msName + this.msVersion2;
	},

	get v3() {
		return this.msName + this.msVersion3;
	},

	get v5() {
		return this.msName + this.msVersion5;
	},
};
