import React, { useContext } from "react";
import RadioComponentV2 from "../../../../../../utils/RadioComponent/RadioComponentV2";

import { InvestmentContext } from "../../CreateInvestment";

const DurationSelectForm = () => {
	const { formValues, durationCategory, next, handleRadioSelect } =
		useContext(InvestmentContext);

	return (
		<>
			<div className="w-100 my-4">
				<h3 className="sub-heading m-0">Duration Category</h3>
				<span className="tiny__message m-0 font-weight-normal">
					Select a Period category you would want to invest within
				</span>
			</div>

			<RadioComponentV2
				value={formValues?.durationId?.durationId}
				options={durationCategory}
				handleRadioSelect={handleRadioSelect}
			/>

			<button
				type="button"
				className="btn create-acc"
				onClick={next}
				disabled={!formValues?.durationId.durationId}
			>
				Proceed
			</button>
		</>
	);
};

export default DurationSelectForm;
