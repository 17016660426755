export const employmentStatuses = [
    {
        label: "Employed",
        value: "EMPLOYED",
    },
    {
        label: "UnEmployed",
        value: "UNEMPLOYED",
    },
    {
        label: "Self Employed",
        value: "SELF_EMPLOYED",
    },
    {
        label: "Student",
        value: "STUDENT",
    },
];
