import React from "react";
import "./GenderSelect.scss";
import { titleCase } from "title-case";
import { formatEnum } from "../helpers";

export default function GenderSelect({ radioKeys, selected, onSelect, error }) {
	return (
		<div className="gender-select">
			<div className="gender-select__options">
				{radioKeys?.values &&
					radioKeys.values.map((gender) => (
						<div
							key={gender}
							role="radiogroup"
							onClick={() => onSelect?.(gender)}
							className={`gender-select__field ${
								selected === gender ? "selected" : ""
							}`}
						>
							{titleCase(formatEnum(gender))}
						</div>
					))}
			</div>
			{error && (
				<span className="error-msg">Please specify your gender</span>
			)}
		</div>
	);
}
