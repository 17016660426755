import ActiveInvestments from "./ActiveInvestments/ActiveInvestments";
import CompletedInvestments from "./CompletedInvestments/CompletedInvestments";
import CreateInvestment from "./CreateInvestment/CreateInvestment";
import InvestmentDetails from "./InvestmentDetails/InvestmentDetails";

export const defaultPath = "/investments";

export default [
	{
		exact: true,
		title: "Active Investments",
		path: `${defaultPath}/active`,
		component: ActiveInvestments,
	},
	{
		exact: true,
		title: "Completed Investments",
		path: `${defaultPath}/completed`,
		component: CompletedInvestments,
	},
	{
		exact: true,
		title: "New Investment",
		path: "/investments/new",
		component: CreateInvestment,
		hide: true,
	},
	{
		exact: true,
		title: "Investment Details",
		path: "/investments/details",
		component: InvestmentDetails,
		hide: true,
	},
];
