import React, { Component } from "react";
import { API } from "../../../../../../lib/api";
import { getFrequentlyAskedQues } from "../../../../../../lib/api/apiRoutesConfig/services/AccountMS/common/subRoutes";
import Axios from "axios";
import OnSocialMedia from "./OnSocialMedia";
import ChatMintSupport from "./ChatMintSupport";
import PSSkeleton from "../ProfileSettings/PSSkeleton/PSSkeleton";

class FaqSettings extends Component {
    _isMounted = false;

    signal = Axios.CancelToken.source();

    state = {
        QAS: [],
        fetching: true,
    };

    componentDidMount = () => {
        this._isMounted = true;
        this.fetchFrequentQuestions();
    };

    componentWillUnmount = () => {
        this._isMounted = false;
        this.signal.cancel("canceling network calls");
    };

    fetchFrequentQuestions = async () => {
        try {
            const res = await API.get(getFrequentlyAskedQues, {
                cancelToken: this.signal.token,
            });
            if (res.status === 200) {
                this.setState({ QAS: res.data.data, fetching: false });
            }
        } catch (err) {
            this.setState({ QAS: [], fetching: false });
        }
    };

    render = () => {
        const { fetching } = this.state;
        return (
            <div className='row justify-content-center'>
                <div className='col-lg-7'>
                    <div id='accordion'>
                        <div className='card'>
                            <div className='card-header' id='headingOne'>
                                <h5 className='mb-0'>
                                    <button
                                        className='btn btn-link'
                                        data-toggle='collapse'
                                        data-target='#collapseOne'
                                        aria-expanded='false'
                                        aria-controls='collapseOne'>
                                        Frequently asked questions
                                    </button>
                                </h5>
                            </div>

                            <div
                                id='collapseOne'
                                className='collapse'
                                aria-labelledby='headingOne'
                                data-parent='#accordion'>
                                <div className='card-body'>
                                    {fetching ? (
                                        <PSSkeleton />
                                    ) : (
                                        <div className='custom-accordion'>
                                            {this.state.QAS.map(
                                                (
                                                    { category, content, code },
                                                    i
                                                ) => (
                                                    <div
                                                        className='card'
                                                        key={i}>
                                                        <div
                                                            className='card-header py-0'
                                                            id='headingOne'>
                                                            <div key={i}>
                                                                <button
                                                                    className='btn btn-link greyworm'
                                                                    data-toggle='collapse'
                                                                    href={
                                                                        "#collapseExample" +
                                                                        code
                                                                    }
                                                                    aria-expanded='false'
                                                                    aria-controls='collapseExample'>
                                                                    {category}
                                                                </button>
                                                                <div
                                                                    className='collapse'
                                                                    id={
                                                                        "collapseExample" +
                                                                        code
                                                                    }>
                                                                    <div className='card card-body'>
                                                                        <div className='custom-accordion'>
                                                                            {content.map(
                                                                                (
                                                                                    {
                                                                                        question,
                                                                                        answer,
                                                                                        id,
                                                                                    },
                                                                                    index
                                                                                ) => (
                                                                                    <div
                                                                                        className='card'
                                                                                        key={
                                                                                            index
                                                                                        }>
                                                                                        <div className='card-header py-0'>
                                                                                            <div
                                                                                                key={
                                                                                                    i
                                                                                                }>
                                                                                                <button
                                                                                                    className='btn btn-link greyworm'
                                                                                                    data-toggle='collapse'
                                                                                                    href={
                                                                                                        "#collapseExample" +
                                                                                                        id
                                                                                                    }
                                                                                                    aria-expanded='false'
                                                                                                    aria-controls='collapseExample'>
                                                                                                    {
                                                                                                        question
                                                                                                    }
                                                                                                </button>
                                                                                                <div
                                                                                                    className='collapse'
                                                                                                    id={
                                                                                                        "collapseExample" +
                                                                                                        id
                                                                                                    }>
                                                                                                    <div className='card card-body'>
                                                                                                        <p className="questionAnswer">{answer}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <ChatMintSupport />
                        <OnSocialMedia />
                    </div>
                </div>
            </div>
        );
    };
}

export default FaqSettings;