import React from "react";
import { ReactSVG } from "react-svg";
import CloseIcon from "assets/img/close_circle_success.svg";
import "./InsufficientFundsModal.scss";

export default function InsufficientFundsModal({ message, onClick }) {
	return (
		<div className="insufficient-funds-modal">
			<ReactSVG src={CloseIcon} />

			<h3>Insufficient Funds</h3>
			<p>{message}</p>

			<button className="btn btn-mint-default" onClick={onClick}>
				Fund Account
			</button>
		</div>
	);
}
