import React from "react";
import FinanceSection from "./FinanceSection/FinanceSection";
// import EmployeesPayroll from "./EmployeesPayroll/EmployeesPayroll";

export default function Finance() {
	return (
		<section className="mt-45">
			<div className="row">
				<FinanceSection />
				{/* <EmployeesPayroll /> */}
			</div>
		</section>
	);
}
