import React, { Component } from "react";
import IntroBalance from "./widgets/IntroBalance/IntroBalance";
import FadeIn from "../effects/FadeIn";
import IncomeExpenseTranscationHistory from "./widgets/IncomeExpenseTranscationHistory/IncomeExpenseTranscationHistory";
// import ActiveSavingsGoalsPlans from "./widgets/ActiveSavingsGoalsPlans/ActiveSavingsGoalsPlans";
// import SpendingWidget from "./widgets/SpendingWidget/SpendingWidget";
import Finance from "./widgets/Finance/Finance";
import AccessControl from "../../../auth/AccessControl/AccessControl";
import permissions from "../../../auth/permissions";

export default class Landing extends Component {
	render = () => {
		const { IS_ENTERPRISE_TRUSTEE_VERIFIED } = permissions;

		return (
			<div className="mt-5 pb-5">
				<FadeIn>
					<IntroBalance />
					<AccessControl
						allowedPermissions={[IS_ENTERPRISE_TRUSTEE_VERIFIED]}
					>
						<Finance />
					</AccessControl>
					<IncomeExpenseTranscationHistory />
					{/* <ActiveSavingsGoalsPlans />
					<SpendingWidget /> */}
				</FadeIn>
			</div>
		);
	};
}
