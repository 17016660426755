import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import FadeIn from "../effects/FadeIn";
import "./PendingTransactionApprovals.scss";
import CaretLeft from "assets/img/caret_left_gold.svg";
import PTAFilters from "./components/PTAFilters/PTAFilters";
import { ERROR, FINISHED, IDLE, LOADING } from "config/enums";
import CustomTable from "components/utils/CustomTable/CustomTable";
import {
	tableHeadings,
	categoryEnums,
	approvalStatusEnums,
} from "./components/constants";
import { API } from "lib/api";
import { getCorporateTransactions } from "lib/api/apiRoutesConfig/services/FundsManagementMS/transactions/subRoutes";
import { ToastsStore } from "react-toasts";
import {
	formatCurrency,
	formatDateToString,
	formatEnum,
	getDayTime,
	getErrorMessage,
	monthYearDateFormat,
} from "components/utils/helpers";
import { MintInfinite } from "components/dashboard/utils/ShineLoader/ShineLoader";
import FailedTryAgain from "components/dashboard/utils/FailedTryAgain/FailedTryAgain";
import CustomPagination from "components/dashboard/utils/CustomPagination/CustomPagination";
import TransactionDetailsModal from "./components/DetailsModal/DetailsModal";

const getDefaultFilters = () => ({
	transactionType: "",
	fromDate: "",
	toDate: "",
});

export default function PendingTransactionApprovals() {
	const history = useHistory();
	const [currentStatus, setCurrentStatus] = useState(IDLE);
	const [transactions, setTransactions] = useState({});
	const [transactionTableData, setTransactionTableData] = useState([]);
	const [page, setPage] = useState(0);
	const [size] = useState(10);
	const [errorMsg, setErrorMsg] = useState("");
	const [filters, setFilters] = useState(getDefaultFilters());
	const [singleTransaction, setSingleTransation] = useState(null);
	const [isDetailsShown, setIsDetailsShown] = useState(false);

	const tableHeaders = tableHeadings.filter(
		(item) => item.cell !== "Reviewer" && item.cell !== "Date Reviewed"
	);

	const createRowData = (row, index) => {
		const {
			dateRequested,
			transactionType,
			transactionCategory,
			amount,
			initiatedBy,
		} = row || {};
		return {
			data: [
				{
					cell: index + 1,
				},
				{
					cell: monthYearDateFormat(dateRequested, "short"),
				},
				{
					cell: getDayTime(dateRequested),
				},
				{
					cell: (
						<div
							className={`pta-badge ${
								transactionCategory ===
								categoryEnums.FUND_TRANSFER
									? "pta-transfer"
									: transactionCategory ===
									  categoryEnums.BILL_PAYMENT
									? "pta-bills"
									: ""
							}`}
						>
							{formatEnum(transactionCategory)}
						</div>
					),
				},
				{
					cell: (
						<div className="text-capitalize">
							{formatEnum(transactionType)}
						</div>
					),
				},
				{
					cell: (
						<div className="pta-amount">
							{formatCurrency(amount)}
						</div>
					),
				},
				{
					cell: initiatedBy,
				},
				{
					cell: (
						<button
							className="pta-details-btn"
							onClick={() => {
								setSingleTransation(row);
								setIsDetailsShown(true);
							}}
						>
							View details
						</button>
					),
				},
			],
			// onClick,
			// className,
		};
	};

	const fetchPendingTransactions = async () => {
		setCurrentStatus(LOADING);
		try {
			const response = await API.get(getCorporateTransactions, {
				params: {
					...filters,
					fromDate: formatDateToString(filters.fromDate),
					toDate: formatDateToString(filters.toDate),
					size,
					page,
					approvalStatus: approvalStatusEnums.PENDING,
				},
			});
			const { status, data } = response || {};
			if (status === 200) {
				const { records: transactionGroup } = data.data;
				setTransactions(data.data);
				let tableData = [];
				transactionGroup.forEach((item, index) => {
					const newRow = createRowData(item, index);
					tableData.push(newRow);
				});
				setTransactionTableData(tableData);
				setCurrentStatus(FINISHED);
			}
		} catch (err) {
			setTransactions([]);
			setTransactionTableData([]);
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
			setCurrentStatus(ERROR);
			setErrorMsg(getErrorMessage(err));
		}
	};

	const handleFilterFormSubmit = (values) => {
		setPage(0);
		setFilters((prev) => ({ ...prev, ...values }));
	};

	const handleFilterFormReset = () => {
		const filters = getDefaultFilters();
		setPage(0);
		setFilters(filters);
		return filters;
	};

	const goToPage = (page) => {
		setPage(page);
	};

	useEffect(() => {
		fetchPendingTransactions();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, size, filters]);

	const renderBasedOnStatus = () => {
		const { records, totalPages } = transactions;

		switch (currentStatus) {
			case LOADING:
				return (
					<div className="d-flex w-100 justify-content-center align-items-center pta-loading">
						<MintInfinite relative />
					</div>
				);

			case ERROR:
				return (
					<div className="d-flex w-100 justify-content-center align-items-center pta-loading">
						<FailedTryAgain
							errorMssg={errorMsg}
							handleTryAgain={fetchPendingTransactions}
						/>
					</div>
				);

			case FINISHED:
				if (records?.length <= 0) {
					return (
						<h6 className="notready">No Pending Transactions</h6>
					);
				}

				return (
					<>
						<CustomTable
							tableId="pending-transactions"
							className="pta-table"
							headers={tableHeaders}
							bodyData={transactionTableData}
						/>

						<CustomPagination
							currentPage={page}
							pageSize={totalPages}
							goToPage={goToPage}
						/>
					</>
				);

			default:
				return null;
		}
	};

	return (
		<>
			<Helmet>
				<title>
					{process.env.REACT_APP_NAME} - Pending Transaction Approvals
				</title>
			</Helmet>

			<FadeIn>
				<section className="pending-approvals pb-5">
					<div className="d-flex align-items-center mb-5">
						<button
							className="back-btn d-flex align-items-center"
							onClick={() => history.goBack()}
						>
							<img src={CaretLeft} alt="" />
							<span>Back</span>
						</button>
					</div>

					<h1>Pending Approvals</h1>
					<p>See all pending transaction approval requests</p>

					<PTAFilters
						initialValues={filters}
						onSubmit={handleFilterFormSubmit}
						onReset={handleFilterFormReset}
					/>

					{renderBasedOnStatus()}
				</section>
			</FadeIn>

			<TransactionDetailsModal
				show={isDetailsShown && !!singleTransaction}
				transaction={singleTransaction}
				onClose={() => {
					setIsDetailsShown(false);
					setSingleTransation(null);
				}}
			/>
		</>
	);
}
