import React from "react";
import PropTypes from "prop-types";
import { CloseModalBtn } from "../Modal";

const ModalHeader = ({
	heading,
	dismissable,
	hidden,
	className,
	headerClassName,
}) => {
	return (
		<div
			className={`modal-header ${hidden ? "d-none" : ""} ${
				className ? className : ""
			}`}
		>
			<div
				className={`${heading === "" ? "" : "drive-head py-1 w-100"} ${
					headerClassName ? headerClassName : ""
				}`}
			>
				<h6 className="newText">{heading}</h6>
				{dismissable && <CloseModalBtn actionMethod={dismissable} />}
			</div>
		</div>
	);
};

ModalHeader.propTypes = {
	heading: PropTypes.string.isRequired,
	dismissable: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};

export default ModalHeader;
