import { formatCurrency, getErrorMessage } from "components/utils/helpers";
import { LOADING } from "config/enums";
import React, { useState } from "react";
import QuantitySelector from "../QuantitySelector/QuantitySelector";
import {
	checkIfProductExistsInCart,
	getExistingCartItemId,
} from "../utils/constant";
import useUpdateCartItem from "../utils/hooks/useUpdateCartItem";
import "./CartItemCard.scss";
import TrashIcon from "assets/img/trash_icon_fill.svg";
import { ReactSVG } from "react-svg";
import { ToastsStore } from "react-toasts";
import { API } from "lib/api";
import { deleteCartItem } from "lib/api/apiRoutesConfig/services/tradeMS/individualTrade/subRoutes";
import useFetchCart from "../utils/hooks/useFetchCart";

export default function CartItemCard({
	product,
	cartType,
	cartItems,
	currentQuantity,
	itemId,
}) {
	const [quantity, setQuantity] = useState(currentQuantity);
	const { currentStatus, handleUpdateCartItem } = useUpdateCartItem();
	const { handleFetchCart } = useFetchCart();
	const [deleteLoading, setDeleteLoading] = useState(false);

	const { imageUrl, name, price, productCode } = product || {};
	const isLoading = currentStatus === LOADING;

	const updateCartItem = (newQuantity) => {
		if (checkIfProductExistsInCart(cartItems, productCode)) {
			const cartItemId = getExistingCartItemId(cartItems, productCode);
			handleUpdateCartItem(
				{ cartItemId, quantity: newQuantity },
				cartType
			);
		}
	};

	const onDecreaseQuantity = () => {
		if (quantity === 1) return;
		const newQuantity = quantity - 1;
		setQuantity(newQuantity);
		updateCartItem(newQuantity);
	};

	const onIncreaseQuantity = () => {
		const newQuantity = quantity + 1;
		setQuantity(newQuantity);
		updateCartItem(newQuantity);
	};

	const handleDeleteItem = async () => {
		setDeleteLoading(true);
		try {
			const response = await API.delete(deleteCartItem(itemId));
			const { status, data } = response || {};
			if (status === 200) {
				ToastsStore.success(data.message, 6000, "right-toast");
				handleFetchCart(cartType);
			}
		} catch (err) {
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		} finally {
			setDeleteLoading(false);
		}
	};

	return (
		<div className="mkt-cart-item-card">
			<div className="mkt-cart-item-card__left">
				<div className="mkt-cart-item-img">
					<img alt={name} src={imageUrl} />
				</div>
				<div>
					<div className="mkt-cart-item-header">
						<h3>{name}</h3>
						<h4>{formatCurrency(price)}</h4>
					</div>
					<div className="mkt-cart-item-footer">
						<QuantitySelector
							quantity={quantity}
							onDecrease={onDecreaseQuantity}
							onIncrease={onIncreaseQuantity}
							isDisabled={isLoading}
							cls="mkt-cart-quantity-selector"
						/>
						<button
							disabled={isLoading || deleteLoading}
							onClick={handleDeleteItem}
						>
							<ReactSVG src={TrashIcon} />
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
