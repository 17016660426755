import CommonSelect from "components/utils/CommonSelect/CommonSelect";
// import CustomSearchInput from "components/utils/CustomSearchInput/CustomSearchInput";
import FormDatePickerGroup from "components/utils/FormDatePickerGroup/FormDatePickerGroup";
import { formatEnum } from "components/utils/helpers";
import React, { useState } from "react";
import { transactionTypeEnums } from "../constants";

export default function PTAFilters({ initialValues, onSubmit, onReset }) {
	const [formValues, setFormValues] = useState(initialValues);
	const [isFiltersApplied, setIsFiltersApplied] = useState(false);

	// const handleChange = ({ currentTarget }) => {
	// 	setFormValues((prev) => ({
	// 		...prev,
	// 		[currentTarget.name]: currentTarget.value,
	// 	}));
	// };

	const setFormFieldValue = (name, value) => {
		setFormValues((prev) => ({ ...prev, [name]: value }));
	};

	const handleReset = () => {
		setIsFiltersApplied(false);
		const defaultFilters = onReset();
		setFormValues(defaultFilters);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setIsFiltersApplied(true);

		onSubmit({
			...formValues,
			transactionType: formValues.transactionType?.value,
		});
	};

	return (
		<div className="pta-filters">
			{/* <div className="pta-search">
				<div className="pta-search-wrapper">
					<CustomSearchInput placeholder="Search for a transaction" />
				</div>
				<button className="btn btn-mint-default">Search</button>
			</div> */}

			<form className="pta-filter-form" onSubmit={handleSubmit}>
				<CommonSelect
					name="transactionType"
					placeholder="Transaction Type"
					value={formValues.transactionType}
					handleChange={(value, { name }) =>
						setFormFieldValue(name, value)
					}
					options={Object.values(transactionTypeEnums).map(
						(item) => ({
							label: formatEnum(item),
							value: item,
						})
					)}
				/>

				<FormDatePickerGroup
					isClearable={false}
					placeholder="Start Date"
					selected={formValues.fromDate}
					max={new Date()}
					name="fromDate"
					onChange={(date) => setFormFieldValue("fromDate", date)}
				/>

				<FormDatePickerGroup
					isClearable={false}
					placeholder="End Date"
					selected={formValues.toDate}
					max={new Date()}
					minDate={formValues.fromDate}
					name="toDate"
					onChange={(date) => setFormFieldValue("toDate", date)}
				/>

				<button
					type="submit"
					className="btn btn-mint-default pta-filter-apply"
					disabled={Object.values(formValues).every(
						(val) => val === ""
					)}
				>
					Apply
				</button>

				{isFiltersApplied && (
					<button
						type="button"
						className="btn btn-custom-bordered"
						onClick={handleReset}
					>
						Reset
					</button>
				)}
			</form>
		</div>
	);
}
