import bankAccount from "../";
export const {
	getlatestTranscations,
	getAllTranscation,
	getAccountStatement,
	getAllTransactionListings,
} = {
	getlatestTranscations: (accountId) =>
		`${bankAccount}/${accountId}/latest-transactions`,
	getAllTranscation: (accountId) =>
		`${bankAccount}/${accountId}/transactions`,
	getAccountStatement: (accountId) =>
		`${bankAccount}/${accountId}/account-statement`,
	getAllTransactionListings: (accountId) =>
		`${bankAccount}/${accountId}/transaction-listing`,
};
