import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "../../../../../../../utils/Modal/Modal";
import TransferLimitForm from "./TransferLimitForm/TransferLimitForm";
import {
	INITIAL,
	SAMEBANK,
	LOADING,
	FINISHED,
} from "../../../../../../../../config/enums";
import AcceptPinInput from "./AcceptPinInput/AcceptPinInput";
import LoadingBox from "../../../../../../../utils/LoadingBox/LoadingBox";
import OperationStatus, {
	failureResponseStatus,
	successResponseStatus,
} from "../../../../../../utils/OperationStatus/OperationStatus";
import {
	inTraBankTransLimit,
	inTerBankTransLimit,
} from "../../../../../../../../lib/api/apiRoutesConfig/services/AccountMS/profile/settings/subRoutes";
import { API } from "../../../../../../../../lib/api";
import { updateCustomerTransferLimit } from "../../../../../../../../actions/settingsActions";
import { getErrorMessage } from "../../../../../../../utils/helpers";

const INPUT_PIN = "INPUT_PIN";

class TransferLimitModal extends Component {
	state = {
		currentState: INITIAL,
		accountType: SAMEBANK,
	};

	handleAccountTypeChange = (e) => {
		const { name, value } = e.target;
		this.setState({ [name]: value });
	};

	updateTransferLimit = async (pin) => {
		try {
			this.setState({ currentState: LOADING });
			let { currentRequest, accountType } = this.state;
			const { updateCustomerTransferLimit } = this.props;
			currentRequest.transactionPin = pin;
			const url =
				accountType === SAMEBANK
					? inTraBankTransLimit
					: inTerBankTransLimit;
			const res = await API.put(url, { ...currentRequest });
			if (res.status === 200) {
				await updateCustomerTransferLimit(accountType, currentRequest);
				this.setState({
					currentRequest: {},
					currentState: FINISHED,
					statusObj: {
						status: successResponseStatus,
						statusMessage: res.data.message,
						successLink: "/dashboard",
						successLinkText: "Okay",
					},
				});
			}
		} catch (err) {
			this.setState({
				currentRequest: {},
				currentState: FINISHED,
				statusObj: {
					status: failureResponseStatus,
					statusMessage: getErrorMessage(err),
				},
			});
		}
	};

	proceedToAskPin = (request) => {
		this.setState({
			currentRequest: { ...request },
			currentState: INPUT_PIN,
		});
	};

	handleTryAgain = () => {
		this.setState({ currentState: INITIAL });
	};

	renderBasedOnState = () => {
		const { currentState } = this.state;
		const { toggleModal } = this.props;
		switch (currentState) {
			case INITIAL:
				const { accountType } = this.state;
				return (
					<TransferLimitForm
						accountType={accountType}
						handleAccountTypeChange={this.handleAccountTypeChange}
						toggleModal={toggleModal}
						nextStep={this.proceedToAskPin}
					/>
				);

			case INPUT_PIN:
				return (
					<AcceptPinInput
						confirmTranscation={this.updateTransferLimit}
						toggleModal={toggleModal}
					/>
				);

			case LOADING:
				return <LoadingBox />;

			case FINISHED:
				const { statusObj } = this.state;
				return (
					<OperationStatus
						statusObj={statusObj}
						handleTryAgain={this.handleTryAgain}
						{...this.props}
					/>
				);

			default:
				break;
		}
	};

	render() {
		const { show } = this.props;
		return (
			<Modal show={show}>
				<>{this.renderBasedOnState()}</>
			</Modal>
		);
	}
}

export default connect(null, { updateCustomerTransferLimit })(
	TransferLimitModal
);
