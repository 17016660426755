import React from "react";
import { NavLink } from "react-router-dom";

const PreAuthNav = ({ isForgot }) => (
	<>
		<ul className="auth-tabs" id="myTab" role="tablist">
			<li>
				<NavLink to="/sign-in" activeClassName="active" exact>
					{isForgot ? "Forgot Password" : "Sign In"}
				</NavLink>
			</li>
			<li>
				<NavLink to="/sign-up" activeClassName="active" exact>
					Open Account
				</NavLink>
			</li>
		</ul>
	</>
);

export default PreAuthNav;
