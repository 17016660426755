import React, { useState, useEffect } from "react";
import { API } from "../../../../../lib/api";
import { transactionHistory } from "../../../../../lib/api/apiRoutesConfig/services/SavingsMS/investments/subRoutes";
import { ReactComponent as InvestmentIcon } from "../../../../../assets/img/investment.svg";
import { responseStatus } from "../../../../constants";
import { formatCurrency, getErrorMessage } from "../../../../utils/helpers";
import ContentWrapper from "../../../../utils/ResponseState/ContentWrapper";
import EmptyState from "../../../../utils/ResponseState/EmptyState";

const { LOADING, FINISHED, ERROR } = responseStatus;

const InvestmentTransactions = ({ investment, showBorder }) => {
	const [currentState, setCurrentState] = useState();
	const [transactions, setTransactions] = useState([]);
	const [errorMsg, setErrorMsg] = useState();

	useEffect(() => {
		investment && fetchTransactions();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [investment]);

	const fetchTransactions = async () => {
		try {
			setCurrentState(LOADING);
			const res = await API.get(transactionHistory(investment?.code));

			const { status, data } = res || {};
			if (status === 200) {
				setTransactions(data?.data);
				setCurrentState(FINISHED);
			}
		} catch (err) {
			setCurrentState(ERROR);
			setErrorMsg(getErrorMessage(err));
		}
	};
	return (
		<div className="investment-card full-width">
			<div
				className={`flex__between mb-4 ${
					showBorder ? "border-bottom" : ""
				}`}
			>
				<h3 className="sub-heading text--main">
					Investment Transaction
				</h3>
			</div>

			<ContentWrapper
				currentState={currentState}
				errorMsg={errorMsg}
				refetch={fetchTransactions}
			>
				<>
					{transactions?.length === 0 ? (
						<EmptyState
							icon={InvestmentIcon}
							title={`No Investment Transaction Found`}
						/>
					) : (
						<>
							{transactions?.map((transaction) => (
								<Transactions
									key={transaction?.reference}
									transaction={transaction}
								/>
							))}
						</>
					)}
				</>
			</ContentWrapper>
		</div>
	);
};

const Transactions = ({ transaction }) => {
	const status =
		transaction?.type === "CREDIT" ? "text-success" : "text-danger";

	return (
		<ul>
			<li>
				<div>
					<h4>{transaction?.description}</h4>
					<small>{transaction?.date}</small>
				</div>

				<h4 className={status}>
					{formatCurrency(transaction?.amount)}
				</h4>
			</li>
		</ul>
	);
};

export default InvestmentTransactions;
