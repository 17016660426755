import React, { Component } from "react";
import { ToastsStore } from "react-toasts";
import { getErrorMessage } from "../../../../../utils/helpers";
import { TinyLoad } from "../../../../../utils/SmallLoader";
import { API } from "../../../../../../lib/api";
import { resendFPVerificationCode } from "../../../../../../lib/api/apiRoutesConfig/services/AccountMS/forgot-password/subRoutes/";

class ResendFPCode extends Component {
	state = {
		resending: false,
	};

	resendConfirmation = async (e, type) => {
		try {
			e.preventDefault();
			this.setState({
				resending: true,
			});
			const { sessionId } = this.props;
			let url = resendFPVerificationCode(sessionId);
			const res = await API.put(url, {
				type: type,
			});
			if (res.status === 200) {
				ToastsStore.success(res.data.message, 6000, "right-toast");
				this.setState({ resending: false });
			}
		} catch (err) {
			const errorMsg = getErrorMessage(err);
			ToastsStore.error(errorMsg, 6000, "right-toast");
			this.setState({ resending: false });
		}
	};

	render() {
		const { resending } = this.state;

		return (
			<div className="w-100 mt-3">
				<div className="desct">
					<p>
						It may take up to 1 minute for your verification code to
						arrive
					</p>
				</div>

				<div className="bottom_links">
					<div>
						<>
							<a
								href="!#"
								className={resending ? "not-active" : ""}
								onClick={(e) =>
									this.resendConfirmation(e, "SMS")
								}
							>
								<span className="mr-2">Resend Code</span>
								{resending && <TinyLoad />}
							</a>
							{/* <a
								href="!#"
								className={resending ? "not-active" : ""}
								onClick={(e) =>
									this.resendConfirmation(e, "CALL")
								}
							>
								<span className="mr-2">
									{resending ? "Resending " : "Resend"}{" "}
									Call Me{" "}
								</span>
								{resending && <TinyLoad />}
							</a> */}
						</>
					</div>
				</div>
			</div>
		);
	}
}

export default ResendFPCode;
