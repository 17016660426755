import React from "react";
import Logo from "../../../utils/Logo";
import "./SideNavHeader.css";

const SideNavHeader = () => (
    <div className="sidebar-header text-center">
        <Logo />
    </div>
)

export default SideNavHeader;