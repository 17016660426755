import CustomRadioButton from "components/utils/CustomRadioButton/CustomRadioButton";
import { formatEnum } from "components/utils/helpers";
import { accountStatementTypeEnum } from "config/enums";
import React from "react";
import "../../AccountStatement.scss";

export default function StatementType({
	accountStatementType,
	setStatementType,
	goToNextStep,
}) {
	const handleSelectOption = (item) => {
		setStatementType(item);
		goToNextStep();
	};

	return (
		<div className="account-statement__type">
			<h2>Select an option</h2>

			{Object.values(accountStatementTypeEnum).map((item, index) => (
				<div
					key={index}
					className={`ast-option ${
						accountStatementType === item ? "selected" : ""
					}`}
					onClick={() => handleSelectOption(item)}
				>
					<CustomRadioButton
						checked={accountStatementType === item}
						name={item}
						onChange={() => handleSelectOption(item)}
						label={`For ${formatEnum(item)}`}
					/>
				</div>
			))}
		</div>
	);
}
