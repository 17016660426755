import React from "react";
import "../../css/small-spinner.css";

export const SmallLoader = () => (
	<div className="lds-spinner-small">
		<div></div>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
	</div>
);

export const TinyLoad = () => (
	<svg
		width="21px"
		height="21px"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 100 100"
		preserveAspectRatio="xMidYMid"
		className="lds-eclipse"
		style={{ background: "none" }}
	>
		<path
			ng-attr-d="{{config.pathCmd}}"
			ng-attr-fill="{{config.color}}"
			stroke="none"
			d="M10 50A40 40 0 0 0 90 50A40 43 0 0 1 10 50"
			fill="#d1a23c"
			transform="rotate(305.815 50 51.5)"
		>
			<animateTransform
				attributeName="transform"
				type="rotate"
				calcMode="linear"
				values="0 50 51.5;360 50 51.5"
				keyTimes="0;1"
				dur="1s"
				begin="0s"
				repeatCount="indefinite"
			></animateTransform>
		</path>
	</svg>
);
