import React, { Component } from "react";
import { connect } from "react-redux";
import { SetConnection } from "../../../../actions/loginActions";
import { API } from "../../../../lib/api";
import { createPinEndpoint } from "../../../../lib/api/apiRoutesConfig/services/AccountMS/profile/settings/subRoutes";
import { SmallLoader } from "../../../utils/SmallLoader";
import TranscationPinInput from "../transcations/TranscationPinInput";
import { getErrorMessage } from "../../../utils/helpers";
import { RESET_STAGE } from "../../../../config/enums";

class CreateTranscationPin extends Component {
    state = {
        errors: [],
        isLoading: false,
    };

    createTranscationPin = async (newPin) => {
        try {
            this.setState({ isLoading: true });
            const res = await API.post(createPinEndpoint, { newPin });
            if (res.status === 200) {
                await this.props.SetConnection();
                await this.props.ConfirmTranscation(newPin);
            }
        } catch (err) {
            const newPinInput = this.refs.newPinInput;
            const confirmPinInput = this.refs.confirmPinInput;
            newPinInput.setInitial();
            confirmPinInput.setInitial();
            this.setState({
                isLoading: false,
                errors: [getErrorMessage(err)],
            });
        }
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ errors: [] });
        const newPinInput = this.refs.newPinInput;
        const confirmPinInput = this.refs.confirmPinInput;
        const newPinValue = newPinInput.getPin();
        const confirmPinValue = confirmPinInput.getPin();

        if (newPinValue.length !== 4) {
            newPinInput.shakeInvalid();
        } else if (newPinValue !== confirmPinValue) {
            this.setState({
                errors: ["Pins don't match"],
            });
        } else {
            this.props.stage === RESET_STAGE
                ? this.props.handleResetRequest({
                      pin: newPinValue,
                      confirmPin: confirmPinValue,
                  })
                : (() => {
                      this.setState({ isLoading: true });
                      this.createTranscationPin(newPinValue);
                  })();
        }
    };

    render = () => {
        const { errors, isLoading } = this.state;
        const { stage } = this.props;

        return (
            <div>
                <div className='sub__section py-3'>
                    {stage === RESET_STAGE ? (
                        <p className='sub-heading text-center'>
                            Please create a new transaction pin
                        </p>
                    ) : (
                        <h6>Set Pin</h6>
                    )}
                </div>

                <div className='low-form text-center px-5 pt-2 pb-4'>
                    <form onSubmit={this.handleSubmit}>
                        <TranscationPinInput
                            label='Choose pin'
                            ref='newPinInput'
                            id='pin1'
                        />
                        <TranscationPinInput
                            label='Confirm pin'
                            ref='confirmPinInput'
                            id='pin2'
                        />
                        <span className='error-msg text-center'>
                            {errors[0]}
                        </span>{" "}
                        <br />
                        <div className='row justify-content-center'>
                            <div className='col-md-7'>
                                <div className='dialog-footer mt-3'>
                                    <button
                                        className='btn create-acc text-capitalize'
                                        style={{ boxShadow: "none" }}>
                                        Proceed
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='row justify-content-center'>
                            <div className='col-md-7'>
                                <div className='dialog-footer mt-1'>
                                    {stage !== RESET_STAGE && (
                                        <button
                                            className='btn btn-cancel text-capitalize'
                                            type='button'
                                            onClick={() =>
                                                this.props.toggleModal()
                                            }>
                                            cancel
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                {isLoading ? (
                    <div className='loading-fx'>
                        <SmallLoader />
                    </div>
                ) : null}
            </div>
        );
    };
}

export default connect(
    (state) => ({
        currentUser: state.auth.currentUser,
        dashboardResponse: state.auth.dashboardResponse,
    }),
    { SetConnection }
)(CreateTranscationPin);
