import BNPL  from "..";

export const {
	
	createAccountAction,
	editAccountAction,
	getAccountAction,
	sendOTPAction,
	validateOTPAction,
	getSummaryAction,
	repaymentAction,
	payBalanceAction

} = {
	createAccountAction: `${BNPL}/buy-and-pay-later`,
	editAccountAction: `${BNPL}/buy-and-pay-later/edit`,
	getAccountAction: `${BNPL}/buy-and-pay-later`,
	sendOTPAction: `${BNPL}/buy-and-pay-later/send-otp`,
	validateOTPAction: `${BNPL}/buy-and-pay-later/validate-otp`,
	getSummaryAction: `${BNPL}/buy-and-pay-later/order-summary`,
	repaymentAction: `${BNPL}/buy-and-pay-later/repayment-schedule`,
	payBalanceAction: `${BNPL}/buy-and-pay-later/balance-payment`,
};
