import React from "react";
import { FINISHED } from "../../../../../config/enums";
import { toCurrency } from "../../../../utils/helpers";

const VerififedWaybillDetail = ({ orderDetail, nextStep, token }) => {
	const { customerName, customerPhone, deliveryAddress, orderId, status } =
		orderDetail;

	return (
		<div className="col-xl-6 col-md-6 col-12 mb-4 px-md-4 px-2 mx-auto">
			<div className="low-form">
				<div className="step-box p-0 form-group">
					<div className="step-box pt-2 pb-0 px-4 border-top-0 border-left-0 border-right-0">
						<label>Order ID: {orderId}</label>
					</div>

					<div className="px-4">
						<small style={{ fontSize: "12px" }} className="all-fet">
							Customer Information
						</small>

						<p
							style={{ fontSize: "16px" }}
							className="saving-target mt-2 font-weight-bold"
						>
							{customerName}
						</p>

						<p className="bigMoni my-1">{customerPhone}</p>

						<p className="bigMoni my-1 ">
							Order Amount :{" "}
							<span className="ml-2">
								{toCurrency(orderDetail?.orderAmount)}
							</span>
						</p>

						<label className="font-weight-lighter">
							<small>{deliveryAddress}</small>
						</label>

						<div className="my-4">
							{status === FINISHED ? (
								<button className="btn create-acc">
									Order Confirmed
								</button>
							) : (
								<button
									className="btn create-acc"
									onClick={() => nextStep(token)}
								>
									Confirm Order
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default VerififedWaybillDetail;
