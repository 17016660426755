/* eslint-disable react-hooks/exhaustive-deps */
import React,{useState, useEffect} from "react"
import Modal from "components/utils/Modal/Modal"
import ModalHeader from "components/utils/Modal/CustomModalHeader/CustomModalHeader";
import "./PaymentPanel.scss";
import GShopping from "./../../components/utils/assets/group_shopping_icon.svg"
import { ReactSVG } from "react-svg";
import { getSummaryAction } from "lib/api/apiRoutesConfig/services/tradeMS/BNPL/subRoutes";
import { ToastsStore } from "react-toasts";
import { getErrorMessage } from "components/utils/helpers";
import { SmallLoader } from "components/utils/SmallLoader";
import { formatCurrency } from "components/utils/helpers";
import { API } from "lib/api";
import { useHistory } from "react-router-dom";



const PayUpFront = ({show, onClose}) => {
  return (
    <Modal show={show} scrollable>
      <ModalHeader
				heading="Pay Upfront"
				className="p-0"
        headerClassName="setup-modals-header px-sm-5 px-3 py-3"
        dismissable={() => onClose?.()}
      />
      <FeedBack onClose={onClose}/>
		</Modal>
  )
}
export default PayUpFront


const FeedBack = ({ onClose }) => {
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false);
  const [summary, setSummary] = useState(''|| null);

  const fetchBNPLSumarry = async () => {
		setIsLoading(true);
		try {
			const res = await API.get(getSummaryAction);
			const { status, data } = res || {};
      if (status === 200) {
        
        setSummary(data.data)
			}
    } catch (err) {
      console.log('error')
      onClose()
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		} finally {
			setIsLoading(false);
		}
	};

  
  const handleNextAction = () => {
    onClose()
    history.push("/market-place/delivery-details/repayment-schedule", {
      amt:formatCurrency(summary?.initialPaymentAmount)
    }
);

  }

  useEffect(() => {
    fetchBNPLSumarry()
  },[])

  return (
    <div className="mkt-payment-modal mkt-payment-modal__content">
      <div className="pay-upfront__content">
        <div className="d-flex flex-row justify-content-center  ">
         <ReactSVG src={GShopping} />
        </div>
        <div>
          <h4>Pay  30% of your groceries order upfront.</h4>
          <p>To complete your order, you are expected to pay 30% of your order amount +2.5% Interest as a down payment
          </p>
        </div>
        <div className="summary">
          <div className=" d-flex flex-row div-container ">
            <span className="title">Total Order Amount</span>
            <span className="amount">{formatCurrency(summary?.totalOrderAmount)}</span>
          </div>
          <div className=" d-flex flex-row div-container ">
            <span className="title">2.5% Interest</span>
            <span className="amount">
              {formatCurrency(summary?.interestAmount)}
            </span>
          </div>

           <div className=" d-flex flex-row amount-to-pay   ">
            <span className=" title">Amount to Pay Now</span>
            <span className=" amount">
              {formatCurrency(summary?.initialPaymentAmount)}
            </span>
          </div>

        </div>
      </div>
      <div className=" d-flex flex-column">
        <button
          onClick={handleNextAction}
            className="btn btn-mint-default "
            type="button"
            disabled={isLoading}
          >
            {isLoading ? (
              <SmallLoader />
            ) : (
              <>
                Continue
              </>
            )}
          </button>
     </div>

      
      
    </div>
  )
}