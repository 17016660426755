import React from "react";
import ModalHeader from "../../../../../../utils/Modal/CustomModalHeader/CustomModalHeader";
import {
	formatCurrency,
	HumanDateFormat,
	getDayTime,
} from "../../../../../../utils/helpers";

const DeactivateScheduleBill = ({ bill, deactivateBill, toggleModal }) => {
	return (
		<>
			<ModalHeader heading={bill.title} />
			<div className="px-4 py-1">
				<div className="dialog-info pb-1">
					<div className="row mb-3">
						<div className="col-6">
							<p className="mb-0">Bill Item</p>
							<label>{bill.billItemName}</label>
						</div>
						<div className="col-6">
							<div className="text-right">
								<p className="mb-0">Customer ID</p>
								<label>{bill.customerId}</label>
							</div>
						</div>
					</div>

					<div className="row mb-3">
						<div className="col-6">
							<p className="lite mb-0">Amount</p>
							<label>{formatCurrency(bill.amount)}</label>
						</div>
						<div className="col-6">
							<div className="text-right">
								<p className="mb-0">Automatic Renewal</p>
								<label>
									{bill.nextPaymentDate
										? "Active"
										: "In-active"}
								</label>
							</div>
						</div>
					</div>

					<div className="row mb-3">
						<div className="col-6">
							<p className="lite mb-0">Frequency</p>
							<label>{bill.frequency}</label>
						</div>
						<div className="col-6">
							<div className="text-right">
								<p className="mb-0">Start Date</p>
								<label>
									{HumanDateFormat(bill.startDate) +
										" " +
										getDayTime(bill.startDate)}
								</label>
							</div>
						</div>
					</div>

					<div className="row mb-3">
						<div className="col-6">
							<p className="lite mb-0">Next Payment Date</p>
							<label>
								{HumanDateFormat(bill.nextPaymentDate) +
									" " +
									getDayTime(bill.nextPaymentDate)}
							</label>
						</div>

						<div className="col-6">
							<div className="text-right">
								<p className="mb-0">End Date</p>
								<label>
									{bill.endDate === ""
										? "N/A"
										: HumanDateFormat(bill.endDate) +
										  " " +
										  getDayTime(bill.endDate)}
								</label>
							</div>
						</div>
					</div>
				</div>

				<div className="dialog-footer mt-4 mb-4">
					<div className="row">
						<div className="col-6">
							<button
								className="btn cancel"
								onClick={toggleModal}
							>
								CANCEL
							</button>
						</div>

						<div className="col-6">
							<button
								className="btn create-acc"
								type="submit"
								onClick={() => deactivateBill(bill?.id)}
							>
								DEACTIVATE
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default DeactivateScheduleBill;
