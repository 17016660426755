import Cookies from "js-cookie";
import {
	SHOW_VERIFY,
	UPDATE_CUSTOMER_AUTH_INFO,
	ALT_PHONE_NUMBER_VERIFIED,
} from "./types";
import { MINTYN_USER_NEW_ACCOUNT } from "components/constants";
import { cookieOptions } from "components/utils/helpers";

export const showVerify = (userdata) => (dispatch) => {
	dispatch({ type: SHOW_VERIFY, payload: userdata });
	Cookies.set(
		MINTYN_USER_NEW_ACCOUNT,
		JSON.stringify(userdata),
		cookieOptions
	);
};
export const updateCustomer = (userdata) => (dispatch) => {
	dispatch({ type: UPDATE_CUSTOMER_AUTH_INFO, payload: userdata });
	Cookies.set(
		MINTYN_USER_NEW_ACCOUNT,
		JSON.stringify(userdata),
		cookieOptions
	);
};
export const sayAltNumberVerified = (data) => (dispatch) => {
	dispatch({ type: ALT_PHONE_NUMBER_VERIFIED, payload: data });
	Cookies.set(MINTYN_USER_NEW_ACCOUNT, JSON.stringify(data), cookieOptions);
};
