import LoginView from "../../../pages/LoginView/LoginView";
import TwoFactorAuthentication from "../TwoFactorAuthentication/TwoFactorAuthentication";

export default [
	{
		title: "Login",
		exact: true,
		path: "/",
		component: LoginView,
	},
	{
		title: "Verifiy OTP",
		exact: true,
		path: "/confirm-OTP",
		component: TwoFactorAuthentication,
	},
];
