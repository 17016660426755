import React from "react"
import Down from "../../components/utils/assets/down-icon.svg"
import { formatCurrency } from "components/utils/helpers"
import MemberCard from "./MemberCard"

const OrderItemsCard = ({ list, type }) => {
  return (
    <div className="cv-items-details-card ">
      <div className="product-container">
        <div className="product">
          <div className="img">
            <img src={list.productImageUrl} alt="product" />
          </div>
          <div className=" product-info-container">
            <div className=" product-info">
              <p> {list.productName }</p>
            <div className="price"> 
              <span>QTY: { list.quantity}</span>
              
            </div>
            </div>
            <div className="price"> 
              <span className="amount">{formatCurrency(list.totalAmount)}</span>
            </div>
          </div>

        </div>
        <div className="icon">
          {!type && <img src={Down} alt="down"/>}
        </div>
      </div>
      {!type&&<div className=" dropdown-container mt-4">
        <DropDown type={type} />
      </div>}
    </div>
  )
}

export default OrderItemsCard

 const DropDown = ({type}) => {
    return (
          <div>
          <div className="members">
            <MemberCard/>
            <p className="user-name">you</p>
            </div>
          <div className="price-details">
            <p>₦35,000.00</p>
            <p className="percentage">20%</p>
          </div>
        </div>
    )
  }