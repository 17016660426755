import FadeIn from "components/dashboard/components/effects/FadeIn";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import "../../BillPayment.scss";
import CaretLeft from "assets/img/caret_left_gold.svg";
import { GroomPanel2 } from "components/dashboard/utils/GroomPanel/GroomPanel";
import EnterRRRNumber from "./EnterRRRNumber";
import ReviewPayment from "./ReviewPayment";

const MIN_STEP = 0;
const MAX_STEP = 1;

export default function RemitaViaRRR() {
	const history = useHistory();
	const [currentStep, setCurrentStep] = useState(MIN_STEP);
	const [transaction, setTransaction] = useState({});

	const goToNextStep = () => {
		setCurrentStep((prev) => Math.min(prev + 1, MAX_STEP));
	};

	const goToPreviousStep = () => {
		setCurrentStep((prev) => Math.max(prev - 1, MIN_STEP));
	};

	const handleGoBack = () => {
		if (currentStep === MIN_STEP) {
			history.goBack();
			return;
		}
		goToPreviousStep();
	};

	const renderBasedOnStep = () => {
		switch (currentStep) {
			case 0:
				return (
					<EnterRRRNumber
						setTransaction={(transactions) =>
							setTransaction(transactions)
						}
						goToNextStep={goToNextStep}
					/>
				);

			case 1:
				return <ReviewPayment transaction={transaction} />;

			default:
				return null;
		}
	};

	return (
		<>
			<Helmet>
				<title>
					{process.env.REACT_APP_NAME} - Bills Payment / Remita Pay
					Via RRR
				</title>
			</Helmet>

			<FadeIn>
				<section className="pb-5 bp-categories__category">
					<div className="d-flex align-items-center mb-5">
						<button
							className="back-btn d-flex align-items-center"
							onClick={handleGoBack}
						>
							<img src={CaretLeft} alt="" />
							<span>Back</span>
						</button>
					</div>

					<GroomPanel2
						title="Payment via RRR Number"
						className="pb-5"
					>
						<div className="bp-remita-pay-via-rrr">
							{renderBasedOnStep()}
						</div>
					</GroomPanel2>
				</section>
			</FadeIn>
		</>
	);
}
