import React from "react";
import PropTypes from "prop-types";
import IntlTelInput from "react-intl-tel-input";
import "./PhoneNumberInput.scss";
import "react-intl-tel-input/dist/main.css";

const PhoneNumberInput = ({
	inputFieldName,
	inputValue,
	handlePhoneNumberChange,
	error,
	isDisabled,
	inputStyle,
	showErrorMsg,
	preferredCountries = ["NG"],
	...rest
}) => {
	// useEffect(() => {
	// 	document
	// 		.querySelector("input[type='tel']")
	// 		.setAttribute("maxlength", "10");
	// });
	return (
		<>
			<IntlTelInput
				key={preferredCountries[0]}
				preferredCountries={preferredCountries}
				// onlyCountries={["NG"]}
				onPhoneNumberChange={handlePhoneNumberChange}
				separateDialCode={true}
				fieldName={inputFieldName}
				value={inputValue}
				disabled={isDisabled}
				// maxLength={15}
				inputClassName={
				`form-control primary    ${ error ? "has-error" : ""} ${inputStyle}`
				}
				autoComplete="off"
				{...rest}
			/>

			{showErrorMsg && error && (
				<span className="error-msg">{error}</span>
			)}
		</>
	);
};

PhoneNumberInput.propTypes = {
	error: PropTypes.any,
	inputFieldName: PropTypes.string.isRequired,
	inputValue: PropTypes.string.isRequired,
	handlePhoneNumberChange: PropTypes.func.isRequired,
	isDisabled: PropTypes.bool,
};

export default PhoneNumberInput;
