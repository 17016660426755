import CustomCurrencyInput from "components/utils/CustomCurrencyInput/CustomCurrencyInput";
import {
	formatCurrency,
	getErrorMessage,
	parseAmount,
} from "components/utils/helpers";
import ModalHeader from "components/utils/Modal/CustomModalHeader/CustomModalHeader";
import Modal from "components/utils/Modal/Modal";
import { SmallLoader } from "components/utils/SmallLoader";
import { ERROR, FINISHED, IDLE, LOADING } from "config/enums";
import { Formik } from "formik";
import { API } from "lib/api";
import { withdrawSpendAndSave } from "lib/api/apiRoutesConfig/services/SavingsMS/savingsGoals/subRoutes";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ToastsStore } from "react-toasts";
import * as yup from "yup";
import "../../SpendAndSave.scss";
import SASSuccessModal from "./SASSuccessModal";

const formValidationSchema = (totalBalance) =>
	yup.object().shape({
		amount: yup
			.number()
			.required("Amount is required")
			.max(
				totalBalance,
				`Maximum amount is ${formatCurrency(totalBalance)}`
			),
	});

export default function SASWithdrawal({
	show,
	onClose,
	totalBalance,
	refetch,
}) {
	const [currentState, setCurrentState] = useState(IDLE);
	const [successMsg, setSuccessMsg] = useState("");

	const renderBasedOnState = () => {
		switch (currentState) {
			case FINISHED:
				return (
					<SASSuccessModal
						message={successMsg}
						onClose={() => {
							setCurrentState(IDLE);
							onClose();
							refetch();
						}}
					/>
				);

			default:
				return (
					<SASWithdrawalForm
						currentState={currentState}
						totalBalance={totalBalance}
						setSuccessMsg={(message) => setSuccessMsg(message)}
						setCurrentState={(state) => setCurrentState(state)}
						onClose={onClose}
					/>
				);
		}
	};

	return (
		<Modal show={show}>
			{currentState !== FINISHED && (
				<ModalHeader
					heading="Spend & Save Withdrawal"
					className="p-0"
					headerClassName="setup-modals-header px-sm-5 px-3 py-3"
					dismissable={onClose}
				/>
			)}
			<>{renderBasedOnState()}</>
		</Modal>
	);
}

const SASWithdrawalForm = ({
	setSuccessMsg,
	currentState,
	setCurrentState,
	totalBalance,
	onClose,
}) => {
	const { dashboardResponse } = useSelector((state) => ({
		dashboardResponse: state.dashboard.dashboardResponse,
	}));

	const { accountId: creditAccountId } = dashboardResponse?.bankAccounts
		? dashboardResponse.bankAccounts[0]
		: {};

	const initialFormValues = {
		amount: "",
	};

	const handleWithdrawal = async (values) => {
		setCurrentState(LOADING);
		try {
			const res = await API.post(withdrawSpendAndSave, {
				...values,
				creditAccountId,
			});
			if (res.status) {
				setCurrentState(FINISHED);
				setSuccessMsg(res.data.message);
			}
		} catch (err) {
			setCurrentState(ERROR);
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		}
	};

	return (
		<div className="sas-modal px-sm-5 px-3">
			<Formik
				initialValues={initialFormValues}
				validationSchema={formValidationSchema(totalBalance)}
				onSubmit={(values) => handleWithdrawal(values)}
			>
				{({
					values,
					errors,
					touched,
					handleSubmit,
					setFieldValue,
					handleBlur,
				}) => {
					return (
						<form
							className="py-3"
							onSubmit={(e) => {
								e.preventDefault();
								handleSubmit();
							}}
						>
							<div className="mb-4">
								<CustomCurrencyInput
									className="sas-input"
									name="amount"
									label="How much will you like to withdraw?"
									id="amount"
									value={values.amount}
									placeholder="Enter amount"
									onBlur={handleBlur}
									error={touched.amount && errors.amount}
									onChange={(value) => {
										setFieldValue(
											"amount",
											parseAmount(value)
										);
									}}
								/>
								<span className="sas-form-info">
									Total balance:{" "}
									{formatCurrency(totalBalance)}
								</span>
							</div>

							<div className="sas-modal__footer">
								<button
									className="btn create-acc"
									disabled={currentState === LOADING}
									type="submit"
								>
									{currentState === LOADING ? (
										<SmallLoader />
									) : (
										"Continue"
									)}
								</button>

								<button
									className="btn btn-custom-bordered"
									type="button"
									onClick={onClose}
								>
									Cancel
								</button>
							</div>
						</form>
					);
				}}
			</Formik>
		</div>
	);
};
