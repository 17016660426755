import React, { Component } from "react";
import Helmet from "react-helmet";
import PageNavigator from "../../utils/PageNavigator/PageNavigator";
import GroomPanel from "../../utils/GroomPanel/GroomPanel";
import SettingsTabs from "./SettingsTabs/SettingsTabs";
import SettingsComponent from "./SettingsComponent/SettingsComponent";

class CustomerSettings extends Component {
	render() {
		return (
			<>
				<Helmet>
					<title>{process.env.REACT_APP_NAME} - Settings</title>
				</Helmet>
				<PageNavigator label="Settings" />
				<GroomPanel>
					<SettingsTabs />
					<SettingsComponent />
				</GroomPanel>
			</>
		);
	}
}

export default CustomerSettings;
