import React from "react";
import { Link } from "react-router-dom";
import Logo from "assets/img/new-logo.png";
import ReactHelmet from "../../../../utils/ReactHelmet/ReactHelmet";
import FadeIn from "../../../../dashboard/components/effects/FadeIn";

const AccountVerification = () => (
	<>
		<ReactHelmet title="Account Verification" />
		<div className="auth-container">
			<div className="row h-100 justify-content-center ">
				<div className="col-xl-6 col-lg-8 col-md-9">
					<div className="mintyn-logo">
						<a href="https://mintyn.com/">
							<img
								alt="Mintyn Bank"
								width={500}
								height={500}
								src={Logo}
							/>
						</a>
					</div>
					<section>
						<FadeIn>
							<div className="row justify-content-center">
								<div className="col-xl-9 col-lg-9 col-md-9">
									<div className="account-verification-panel">
										<p className="info-heading text-center">
											Continue with either of the options
											below
										</p>

										<div className="account-options">
											<Link
												to="/onboard/with-bvn"
												className="account-options__option-card"
											>
												<div>
													<h6>
														Use your Bank
														Verification Number
													</h6>
													<p>
														Please note that bank
														verification is a
														statutory requirement to
														own an operational bank
														account in Nigeria
													</p>
												</div>
											</Link>

											<Link
												to="/onboard/with-existing-bank-account"
												className="account-options__option-card"
											>
												<div>
													<h6>Bank Account</h6>
													<p>
														We do not link your
														Mintyn account with your
														existing bank account,
														this is an alternative
														to using Bank
														Verification Number
													</p>
												</div>
											</Link>
										</div>
									</div>
								</div>
							</div>
						</FadeIn>
					</section>
				</div>
			</div>
		</div>
	</>
);

export default AccountVerification;
