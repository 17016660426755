import React from "react";
import FormInputGroup from "components/utils/FormInputGroup/FormInputGroup";
import FormDatePickerGroup from "components/utils/FormDatePickerGroup/FormDatePickerGroup";
import CommonSelect from "components/utils/CommonSelect/CommonSelect";
import "../../BillPayment.scss";
import {
	formatEnum,
	getValidMobileNumberLength,
	parseAmount,
} from "components/utils/helpers";
import CustomCurrencyInput from "components/utils/CustomCurrencyInput/CustomCurrencyInput";

const setFieldTypeBasedOnType = (
	type,
	setFieldValue,
	variableName,
	values,
	selectOptions = [],
	errors,
	touched,
	setCustomPayload,
	customPayload,
	fieldProps
) => {
	switch (type) {
		case "alphanumeric":
		case "text":
		case "alphabetic":
		case "numeric":
			if (variableName?.toLowerCase()?.includes("amount")) {
				return (
					<CustomCurrencyInput
						className="bp-input"
						placeholder="Enter Amount"
						id={variableName}
						name={variableName}
						value={values[`${variableName}`] || ""}
						error={
							touched[`${variableName}`] &&
							errors[`${variableName}`]
						}
						onChange={(value) => {
							setFieldValue(variableName, parseAmount(value));
							setCustomPayload({
								...customPayload,
								[variableName]: parseAmount(value),
							});
						}}
						{...fieldProps}
					/>
				);
			}

			if (variableName?.toLowerCase()?.includes("phone")) {
				return (
					<FormInputGroup
						className="bp-input"
						placeholder="Enter a value"
						onChange={(e) => {
							const value = e.target.value
								.trim()
								.replace(/[^+0-9]/i, "");
							if (
								value.length <=
								getValidMobileNumberLength(value)
							) {
								setFieldValue(variableName, value);
								setCustomPayload({
									...customPayload,
									[variableName]: value,
								});
							}
						}}
						id={variableName}
						name={variableName}
						value={values[`${variableName}`] || ""}
						error={
							touched[`${variableName}`] &&
							errors[`${variableName}`]
						}
						{...fieldProps}
					/>
				);
			}

			return (
				<FormInputGroup
					inputMode={type === "numeric" ? "numeric" : "text"}
					className="bp-input"
					placeholder="Enter a value"
					onChange={(e) => {
						setFieldValue(variableName, e.target.value);
						setCustomPayload({
							...customPayload,
							[variableName]: e.target.value,
						});
					}}
					id={variableName}
					name={variableName}
					value={values[`${variableName}`] || ""}
					error={
						touched[`${variableName}`] && errors[`${variableName}`]
					}
					{...fieldProps}
				/>
			);

		case "date":
			return (
				<FormDatePickerGroup
					isClearable={false}
					placeholder="Select a date"
					name={variableName}
					selected={values[`${variableName}`] || undefined}
					onChange={(date) => {
						setFieldValue(variableName, date);
						setCustomPayload({
							...customPayload,
							[variableName]: date,
						});
					}}
					error={
						touched[`${variableName}`] && errors[`${variableName}`]
					}
					{...fieldProps}
				/>
			);

		case "singleselect":
			return (
				<CommonSelect
					isSearchable
					menuPortalTarget={null}
					name={variableName}
					// value={values[`${variableName}`] || null}
					placeholder="Select an option"
					handleChange={(option) => {
						setFieldValue(variableName, option?.value);
						setCustomPayload({
							...customPayload,
							[variableName]: option?.value,
						});
					}}
					options={selectOptions.map((item) => ({
						label: item.DISPLAY_NAME,
						value: item.VALUE,
					}))}
					error={
						touched[`${variableName}`] && errors[`${variableName}`]
					}
					showErrorMsg
					{...fieldProps}
				/>
			);

		case "multiselect":
			return (
				<CommonSelect
					isSearchable
					menuPortalTarget={null}
					name={variableName}
					isMulti
					// value={values[`${variableName}`] || null}
					placeholder="Select an option"
					handleChange={(option) => {
						setFieldValue(
							variableName,
							option?.map((item) => item?.value)
						);
						setCustomPayload({
							...customPayload,
							[variableName]: option?.map((item) => item?.value),
						});
					}}
					options={selectOptions.map((item) => ({
						label: item.DISPLAY_NAME,
						value: item?.VALUE,
					}))}
					error={
						touched[`${variableName}`] && errors[`${variableName}`]
					}
					showErrorMsg
					{...fieldProps}
				/>
			);

		default:
			return null;
	}
};

export default function CustomField({
	customFields = [],
	values,
	touched,
	errors,
	handleChange,
	setFieldValue,
	setCustomPayload,
	customPayload,
	...fieldProps
}) {
	return (
		<>
			{customFields.map((field, index) => (
				<div key={index} className="mb-4">
					<label
						htmlFor={`${field?.variable_name}`}
						className="text-capitalize"
					>
						{formatEnum(field?.variable_name)}
					</label>
					{setFieldTypeBasedOnType(
						field?.type,
						setFieldValue,
						field?.variable_name,
						values,
						field?.selectOptions,
						errors,
						touched,
						setCustomPayload,
						customPayload,
						{ ...fieldProps }
					)}
				</div>
			))}
		</>
	);
}
