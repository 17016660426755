import React, { Fragment } from "react";
import routes from "./routes";
import RouteContainer from "../../../routes/RouteContainer";

const ForgotPasswordWrapper = () => (
	<Fragment>
		<RouteContainer baseURL="/forgot-password" routes={routes} />
	</Fragment>
);

export default ForgotPasswordWrapper;
