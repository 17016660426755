
export const PepperStroke = (pathID, options) => {
    var path = document.getElementById(pathID);
    options = options || {}
    if (path !== null) {
        var duration = options.duration || 1000
        var easing = 'cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s'
        var reverse = options.reverse || false
        var undraw = options.undraw || false
        var callback = options.callback || function () { }

        var length = path.getTotalLength()
        var dashOffsetStates = [length, 0]
        if (reverse) {
            dashOffsetStates = [length, 2 * length]
        }
        if (undraw) {
            dashOffsetStates.reverse()
        }

        // Clear any previous transition
        path.style.transition = path.style.WebkitTransition = 'none';

        var dashArray = path.style.strokeDasharray || path.getAttribute("stroke-dasharray");

        if (dashArray !== '' && dashArray !== null) {
            // dashed path case
            // repeats dash pattern as many times as needed to cover path length
            var dashLength = dashArray.split(/[\s,]/).map(function (a) {
                return parseFloat(a) || 0
            }).reduce(function (a, b) {
                return a + b
            })
            var dashCount = length / dashLength + 1
            var a = new Array(Math.ceil(dashCount)).join(dashArray + " ")
            path.style.strokeDasharray = `${a}0 ${length}`
        } else {
            // non dashed path case
            path.style.strokeDasharray = length + ' ' + length;
        }
        path.style.strokeDashoffset = dashOffsetStates[0];
        path.getBoundingClientRect();
        path.style.transition = path.style.WebkitTransition =
            'stroke-dashoffset ' + duration + 'ms ' + easing;
        path.style.strokeDashoffset = dashOffsetStates[1]
        setTimeout(function () {
            path.style.strokeDasharray = dashArray //set back original dash array
            callback()
        }, duration)
    }

}