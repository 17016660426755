import CustomCurrencyInput from "components/utils/CustomCurrencyInput/CustomCurrencyInput";
import { getErrorMessage } from "components/utils/helpers";
import ModalHeader from "components/utils/Modal/CustomModalHeader/CustomModalHeader";
import Modal from "components/utils/Modal/Modal";
import { SmallLoader } from "components/utils/SmallLoader";
import { ERROR, FINISHED, IDLE, LOADING } from "config/enums";
import { API } from "lib/api";
import { goalWithdrawalTrancation } from "lib/api/apiRoutesConfig/services/SavingsMS/savingsGoals/subRoutes";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ToastsStore } from "react-toasts";
import SavingsGoalSuccessModal from "./SavingsGoalSuccessModal";

export default function SavingGoalWithdrawal({
	show,
	onClose,
	goalId,
	savingBalance,
}) {
	const [currentState, setCurrentState] = useState(IDLE);
	const [successMsg, setSuccessMsg] = useState("");

	const history = useHistory();

	const renderBasedOnState = () => {
		switch (currentState) {
			case FINISHED:
				return (
					<SavingsGoalSuccessModal
						title="Request Queued successfully"
						message={successMsg}
						onClose={onClose}
						onClick={() => {
							setCurrentState(IDLE);
							onClose();
							history.push("/dashboard");
						}}
					/>
				);

			default:
				return (
					<WithdrawalForm
						goalId={goalId}
						currentState={currentState}
						setSuccessMsg={(message) => setSuccessMsg(message)}
						setCurrentState={(state) => setCurrentState(state)}
						savingBalance={savingBalance}
					/>
				);
		}
	};

	return (
		<Modal show={show}>
			{currentState !== FINISHED && (
				<ModalHeader
					heading="Funds withdrawal"
					className="p-0"
					headerClassName="setup-modals-header px-sm-5 px-3 py-3"
					dismissable={onClose}
				/>
			)}
			<>{renderBasedOnState()}</>
		</Modal>
	);
}

const WithdrawalForm = ({
	goalId,
	setSuccessMsg,
	currentState,
	setCurrentState,
	savingBalance,
}) => {
	const { dashboardResponse } = useSelector((state) => ({
		dashboardResponse: state.dashboard.dashboardResponse,
	}));

	const { accountId: creditAccountId } = dashboardResponse?.bankAccounts
		? dashboardResponse.bankAccounts[0]
		: {};

	const handleWithdrawal = async () => {
		setCurrentState(LOADING);
		try {
			const response = await API.post(goalWithdrawalTrancation, {
				goalId,
				creditAccountId,
			});
			if (response.status) {
				setCurrentState(FINISHED);
				setSuccessMsg(response.data.message);
			}
		} catch (err) {
			setCurrentState(ERROR);
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		}
	};

	return (
		<div className="es-automatic-deposit px-sm-5 px-3">
			<form
				onSubmit={(e) => {
					e.preventDefault();
					handleWithdrawal();
				}}
			>
				<p style={{ fontSize: "14px" }}>
					You are all set for withdrawal. Make a better comeback with
					your savings.
				</p>

				<div className="mb-4">
					<CustomCurrencyInput
						className="es-input"
						name="amount"
						label="Withdraw Amount (₦)"
						id="amount"
						value={savingBalance}
						disabled
					/>
				</div>

				<button
					className="btn create-acc mt-3"
					disabled={currentState === LOADING}
					type="submit"
				>
					{currentState === LOADING ? (
						<SmallLoader />
					) : (
						"Withdraw Funds"
					)}
				</button>
			</form>
		</div>
	);
};
