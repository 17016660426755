import React from "react";
import Modal from "../Modal/Modal";
import "./ConfirmBackModal.scss";
import { useHistory } from "react-router-dom";

export default function ConfirmBackModal({ show, onConfirm, onClose }) {
	const history = useHistory();

	return (
		<Modal show={show} onClosed={onClose}>
			<div className="confirm-back-modal">
				<h3>Warning</h3>
				<p>
					Going back will erase any data you've already entered.{" "}
					<br />
					Are you sure you want to continue?
				</p>
				<div className="confirm-back-modal__footer">
					<button
						className="btn btn-bordered-light"
						onClick={() => {
							window.history.pushState(
								null,
								null,
								window.location.pathname
							);
							onClose();
						}}
					>
						Cancel
					</button>

					<button
						className="btn btn-mint-default"
						onClick={() => {
							if (onConfirm) {
								onConfirm();
								return;
							}
							history.goBack();
						}}
					>
						Confirm
					</button>
				</div>
			</div>
		</Modal>
	);
}
