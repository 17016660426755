import React, { Component } from "react";
import imageFiles from "../../../../common/imageFiles/imageFiles";
import RadioCheckmark from "../../../../utils/RadioCheckmark/RadioCheckmark";
import { SAMEBANK, OTHER_BANKS } from "../../../../../config/enums";
import "./AccountTypeSelect.scss";

const { icons: { mintIcon, smallBank } = {} } = imageFiles;

export default class AccountTypeSelect extends Component {
    state = {
        radioKeys: {
            name: "accountType",
            keys: [
                {
                    label: SAMEBANK,
                    imgSrc: mintIcon,
                },
                {
                    label: OTHER_BANKS,
                    imgSrc: smallBank,
                },
            ],
        },
    };

    render = () => {
        const { radioKeys } = this.state;
        const { handleRadio, selectedRadio } = this.props;
        return (
            <div className='mt-1'>
                <RadioCheckmark
                    selectedRadio={selectedRadio}
                    handleRadio={handleRadio}
                    radioKeys={radioKeys}
                />
            </div>
        );
    };
}
