import React, { Component } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import Resizer from "react-image-file-resizer";
import { ReactSVG } from "react-svg";
import { SelectBox } from "../../../../../styles/SelectBox";
import { IDTypes } from "../../../../enums";
import { SmallLoader } from "../../../../../utils/SmallLoader";
import {
	formatDateToString,
	getDateForDatepicker,
	getErrorMessage,
} from "../../../../../utils/helpers";
import ValidateForm from "../../../../../utils/Validator";
import { updateIdentityCard } from "../../../../../../lib/api/apiRoutesConfig/services/AccountMS/profile/subRoutes";
import { API } from "../../../../../../lib/api";
import FolderIcon from "../icons/folderIcon.svg";

const isExpiryDisabled = (cardType) =>
	cardType === "VOTERS_CARD" ||
	cardType === "NATIONAL_ID" ||
	cardType === "CORPORATE_ID" ||
	cardType === "STUDENT_ID" ||
	cardType === "OTHERS";

class UploadCustomerIDForm extends Component {
	state = {
		errors: {},
		loading: false,
		requestPayload: {
			cardNumber: "",
			cardType: null,
			image: "",
			expiryDate: "",
		},
	};

	handleField = (name, value) => {
		this.setState((prevState) => ({
			requestPayload: {
				...prevState.requestPayload,
				[name]: value,
			},
			errors: {
				...prevState.errors,
				[name]: false,
			},
		}));
	};

	handleChange = ({ target }) => {
		let { name, value } = target;
		this.setState((prevState) => ({
			requestPayload: {
				...prevState.requestPayload,
				[name]: value,
			},
			errors: {
				...prevState.errors,
				[name]: false,
			},
		}));
	};

	handleIDSelect = ({ name = "cardType", value }) => {
		this.setState((prevState) => ({
			requestPayload: {
				...prevState.requestPayload,
				[name]: value,
				expiryDate: "",
			},
			errors: {
				...prevState.errors,
				[name]: false,
				expiryDate: false,
			},
		}));
	};

	handleImage = (e) => {
		const file = e.target.files[0];
		if (file && ["image/png", "image/jpeg"].includes(file.type)) {
			const fileSize = (file.size / 1024) * 0.001;

			if (fileSize < 5) {
				new Promise((resolve) => {
					Resizer.imageFileResizer(
						file,
						300,
						300,
						"JPEG",
						100,
						0,
						(uri) => {
							resolve(uri);
							const file = new File([uri], "customerselfie.jpg", {
								type: "image/jpg",
							});
							this.handleField("image", file);
						},
						"blob"
					);
				});
			} else alert("image too big");
		}
	};

	updateIdentificationDetails = async (newRequestPayload) => {
		try {
			this.setState({ loading: true });
			const { image, expiryDate } = this.state.requestPayload;

			let form = new FormData();
			form.append("card", image);
			const res = await API.put(updateIdentityCard, form, {
				params: {
					...newRequestPayload,
					expiryDate: formatDateToString(expiryDate),
				},
			});

			if (res.status === 200) {
				this.props.updateCurrentStage(res.data);
			}
		} catch (err) {
			this.setState({
				loading: false,
				mainError: getErrorMessage(err),
			});
		}
	};

	handleSubmit = (e) => {
		e.preventDefault();
		const { requestPayload } = this.state;
		const { expiryDate, ...optionalPayload } = requestPayload;
		const validReqPayload = isExpiryDisabled(requestPayload.cardType)
			? optionalPayload
			: requestPayload;
		const { errors, formisValid } = ValidateForm(e, validReqPayload) || {};
		formisValid
			? this.updateIdentificationDetails(requestPayload)
			: this.setState({ errors });
	};

	render() {
		const { errors, requestPayload, loading, mainError } = this.state;
		const { cardNumber, cardType, image, expiryDate } = requestPayload;
		const expiryDatePickerVal = expiryDate
			? getDateForDatepicker(expiryDate)
			: "";

		return (
			<div className="modal-body verif-center px-4 py-3">
				<div className="low-form">
					{mainError && (
						<div className="desct error fade-in">
							<p>{mainError}</p>
						</div>
					)}

					<form onSubmit={this.handleSubmit}>
						<div className="row">
							<div className="col-6">
								<div className="form-group">
									<label>ID Type</label>
									<Select
										name="cardType"
										classNamePrefix="react-select"
										isSearchable={true}
										getOptionLabel={(option) =>
											option.label
												.replace(/_/g, " ")
												.toLowerCase()
										}
										styles={SelectBox(errors["cardType"])}
										onChange={this.handleIDSelect}
										placeholder={"Choose"}
										options={IDTypes}
									/>
								</div>
							</div>

							<div className="col-6">
								<div className="form-group">
									<label>Expiry Date</label>
									<DatePicker
										className={
											"w-100 form-control " +
											(errors["expiryDate"]
												? "has-error"
												: "")
										}
										value={expiryDatePickerVal}
										onChange={(date) =>
											this.handleField("expiryDate", date)
										}
										minDate={new Date()}
										showYearDropdown
										dateFormatCalendar="MMMM"
										scrollableYearDropdown
										dropdownMode="select"
										disabled={isExpiryDisabled(cardType)}
									/>
									<input
										type="hidden"
										name="expiryDate"
										value={
											expiryDate === null
												? ""
												: expiryDate
										}
									/>
								</div>
							</div>
						</div>

						<div className="form-group">
							<label>ID Number</label>
							<input
								name="cardNumber"
								type="text"
								maxLength={50}
								value={cardNumber}
								onChange={this.handleChange}
								className={
									"form-control " +
									(errors["cardNumber"] ? "has-error" : "")
								}
								autoComplete="off"
							/>
						</div>

						<div className="form-group">
							<label>Upload ID</label>
							<div className="row justify-content-center">
								<div className="col-xl-7">
									<div
										className="upload-btn-wrapper"
										style={{ maxHeight: "300px" }}
									>
										<div className="file-container">
											{image ? (
												<img
													alt="valid ID"
													src={URL.createObjectURL(
														image
													)}
													className="img-responsive contain-img myIdenti"
													style={{
														maxHeight: "100px",
													}}
												/>
											) : (
												<ReactSVG src={FolderIcon} />
											)}
											<p className="text-center mb-0">
												Upload identification Image
												<small>(PNG/JPEG)</small>
											</p>
											<p className="text-center">
												<small>
													maximum file size 4MB
												</small>
											</p>
										</div>
										<input
											type="file"
											name="image"
											style={{
												color: "transparent",
											}}
											title="click to choose file"
											onChange={this.handleImage}
											accept="image/*"
										/>
										<span className="error text-center">
											{errors["image"] === true
												? "Please upload a valid ID"
												: ""}{" "}
										</span>
									</div>
								</div>
							</div>
						</div>

						<div className="row justify-content-center">
							<div className="col-4">
								<span
									className="btn btn-cancel"
									onClick={this.props.toggleModal}
									disabled={loading}
								>
									CANCEL
								</span>
							</div>

							<div className="col-4">
								<button
									className="btn create-acc"
									disabled={loading}
								>
									{loading ? <SmallLoader /> : "SUBMIT"}
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		);
	}
}
export default UploadCustomerIDForm;
