import React from "react";
import { ReactSVG } from "react-svg";
import "./Disclaimer.scss";
import ExclamationIcon from "assets/img/mint_exclamation_icon.svg";

export default function Disclaimer({ message }) {
	return (
		<div className="setup-disclaimer d-flex align-items-start">
			<ReactSVG src={ExclamationIcon} />
			<div className="ml-3">
				<h4>DISCLAIMER:</h4>
				<p>{message}</p>
			</div>
		</div>
	);
}
