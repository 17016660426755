/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Modal from "components/utils/Modal/Modal";
import { ToastsStore } from "react-toasts";
import { getErrorMessage } from "components/utils/helpers";
import { API } from "lib/api";
import { postDebitAccountAction } from "lib/api/apiRoutesConfig/services/AccountMS/CAC/subRoutes";
import { SmallLoader } from "components/utils/SmallLoader";
// import CustomPinInput from "./CustomPinIput/CustomPinInput";
import CustomPinInput from "components/utils/CustomPinInput/CustomPinInput";
import { ReturnHome } from "./returnHome";

const ConfirmPayment = ({ show, onClose, nextModal, regInfo, refresh }) => {
	return (
		<>
			<Modal show={show} scrollable>
				<ConfirmPaymentContent
					onClose={onClose}
					nextModal={nextModal}
					regInfo={regInfo}
					refresh={refresh}
				/>
			</Modal>
		</>
	);
};
export default ConfirmPayment;

const ConfirmPaymentContent = ({ regInfo, nextModal }) => {
	const [pin, setPin] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [accId, setAccId] = useState("");

	useEffect(() => {
		setAccId(localStorage.getItem("a_id"));
	}, []);

	// Handle form submit
	const handleSubmit = async () => {
		setIsLoading(true);
		try {
			const res = await API.put(
				postDebitAccountAction(regInfo.sessionId),
				{
					debitAccountId: accId,
					transactionPin: pin,
				}
			);
			const { status } = res || {};
			if (status === 200) {
				// ToastsStore.success(data.message, 6000, "right-toast")
				nextModal();
			}
		} catch (err) {
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
			setIsLoading(false);
			// nextModal()
		}
	};
	return (
		<div className="confirm-pay-modal ">
			<div className=" content">
				<div className="modal-title">
					<p className="title-header">Transaction pin</p>
					<p>Enter transaction pin to proceed</p>
				</div>
			</div>
			<form className="reg-business-form-modal  row">
				<div className="col-12 ">
					<CustomPinInput
						hasDashedBorder
						className=" mt-2 mb-5"
						length={4}
						secret
						// title="Enter Transaction PIN to confirm"
						containerStyle={{ columnGap: "30px" }}
						onChange={(value) => {
							setPin(value);
						}}
					/>
				</div>
				<div className="col-12 ">
					<button
						onClick={handleSubmit}
						className="btn btn-mint-default"
						type="button"
						disabled={pin === "" || isLoading}
					>
						{isLoading ? <SmallLoader /> : "Continue"}
					</button>
				</div>
			</form>
			<ReturnHome className={"back-home-modal"} />
		</div>
	);
};
