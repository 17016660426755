import React, { Component } from "react";
import Helmet from "react-helmet";
import Axios from "axios";
import { ToastsStore } from "react-toasts";
import PageNavigator from "../../utils/PageNavigator/PageNavigator";
import GroomPanel from "../../utils/GroomPanel/GroomPanel";
import { API } from "../../../../lib/api";
import FailedTryAgain from "../../utils/FailedTryAgain/FailedTryAgain";
import {
    getTransferBeneficiaries,
    deleteBeneficiary,
} from "../../../../lib/api/apiRoutesConfig/services/FundsManagementMS/subRoutes";
import { getErrorMessage } from "../../../utils/helpers";
import { MintInfinite } from "../../utils/ShineLoader/ShineLoader";
import AllBeneficiariesTable from "./AllBeneficiariesTable/AllBeneficiariesTable";
import CreateBeneficiaryFilterRecords from "./CreateBeneficiaryFilterRecords/CreateBeneficiaryFilterRecords";
import DeleteBeneficiary from "./DeleteBeneficiary/DeleteBeneficiary";
import CreateBeneficiaryModal from "./CreateBeneficiaryModal/CreateBeneficiaryModal";
import config from "../../../../config";
import { SAMEBANK, OTHER_BANKS } from "../../../../config/enums";
import FadeIn from "../effects/FadeIn";

const LOADING = "LOADING";
const ERROR = "ERROR";
const FINISHED = "FINISHED";

const UserBlock = ({ name, accountNumber }) => (
    <div className='mint-row'>
        <div className='block-circle mr-2'>{name[0]}</div>
        <div className='taylor'>
            <h6 className='mb-0'>{name}</h6>
            <p className='mb-0'>{accountNumber}</p>
        </div>
    </div>
);

class BeneficiaryManagement extends Component {
    _isMounted = false;
    signal = Axios.CancelToken.source();

    state = {
        currentState: LOADING,
        showDelete: false,
    };

    componentDidMount = () => {
        this._isMounted = true;
        this.fetchBeneficiaries();
    };

    componentWillUnmount = () => {
        this.signal.cancel("canceling network calls");
        this._isMounted = false;
    };

    createRowData = (row) => {
        const { accountName, accountNumber, bankName } = row || {};
        return {
            data: [
                {
                    cell: (
                        <UserBlock
                            name={accountName}
                            accountNumber={accountNumber}
                        />
                    ),
                },
                {
                    cell: (
                        <div className='mint-row'>
                            <div className='taylor'>
                                <h6
                                    className='mb-0'
                                    style={{ marginTop: "2px" }}>
                                    {" "}
                                    {bankName}{" "}
                                </h6>
                            </div>
                        </div>
                    ),
                },
                {
                    cell: (
                        <button
                            className='btn send-money mt-1 mx-auto'
                            onClick={() => this.handleSendMoney(row)}>
                            Send Money
                        </button>
                    ),
                },
                {
                    cell: (
                        <button
                            className='btn remove-bene mt-1 mx-auto'
                            onClick={() => this.showDelete(row)}>
                            remove
                        </button>
                    ),
                },
            ],
        };
    };

    createTable = (data) => {
        let bodyData = [];
        data &&
            data.forEach((item) => {
                const newRow = this.createRowData(item);
                bodyData.push({ ...newRow });
            });
        return bodyData;
    };

    pushToBeneficiaryList = (data) => {
        const { beneficiariesList } = this.state;
        beneficiariesList.unshift(data);
        const tableData = this.createTable(beneficiariesList);
        this.setState({
            beneficiariesList: beneficiariesList,
            // filteredList: filteredList,
            tableData,
            showAddition: false,
        });
    };

    handleSearchFilterInput = (e) => {
        const query = e.target.value;
        const { beneficiariesList } = this.state;
        if (query === "") {
            const tableData = this.createTable(beneficiariesList);
            this.setState({ tableData, filteredList: beneficiariesList });
        } else {
            const filteredList = beneficiariesList.filter((el) => {
                const lowercasedFilter = query.toLowerCase();
                return el.accountName.toLowerCase().includes(lowercasedFilter);
            });
            const tableData = this.createTable(filteredList);
            this.setState({ tableData, filteredList });
        }
    };

    makeDeletionCall = async (ID) => {
        try {
            const url = deleteBeneficiary(ID);
            const res = await API.delete(url, {
                cancelToken: this.signal.token,
            });
            if (res.status === 200) {
                ToastsStore.success(res.data.message, 3000, "right-toast");
            }
        } catch (err) {
            let errorMsg = getErrorMessage(err);
            ToastsStore.error(errorMsg, 3000, "right-toast");
        }
    };

    handleDeletion = (ID) => {
        const { beneficiariesList, filteredList } = this.state;
        const updatedFilterList = filteredList.filter(
            (el) => el.beneficiaryId !== ID
        );
        const updatedBeneList = beneficiariesList.filter(
            (el) => el.beneficiaryId !== ID
        );
        const tableData = this.createTable(updatedFilterList);
        this.setState({
            beneficiariesList: updatedBeneList,
            filteredList: updatedFilterList,
            showDelete: false,
            selectedBeneficiary: {},
            tableData,
        });
        this.makeDeletionCall(ID);
    };

    fetchBeneficiaries = async () => {
        try {
            this.setState({ currentState: LOADING });
            const res = await API.get(getTransferBeneficiaries, {
                params: {
                    type: "all",
                },
                cancelToken: this.signal.token,
            });
            if (res.status === 200) {
                const data = res.data.data;
                const tableData = this.createTable(data);
                this.setState({
                    tableData,
                    beneficiariesList: data,
                    filteredList: data,
                    currentState: FINISHED,
                });
            }
        } catch (err) {
            this._isMounted &&
                this.setState({
                    currentState: ERROR,
                    fetchingError: getErrorMessage(err),
                });
        }
    };

    handleSendMoney = (row) => {
        const { history } = this.props;
        const bankCode = row.bankCode;
        const accountType =
            bankCode === config.MINT_BANK_CODE ? SAMEBANK : OTHER_BANKS;
        history.push({
            pathname: "/send-money",
            state: { accountType, availableBeneficiary: { ...row } },
        });
    };

    showDelete = (row) => {
        this.setState({
            showDelete: true,
            selectedBeneficiary: row,
        });
    };

    toggleAdditionBeneficiary = () => {
        this.setState((prevState) => ({
            showAddition: !prevState.showAddition,
        }));
    };

    renderBasedOnState = () => {
        const { currentState, fetchingError } = this.state;
        switch (currentState) {
            case LOADING:
                return <MintInfinite />;

            case ERROR:
                return (
                    <div
                        className='px-5 d-flex w-100 h-100 justify-content-center align-items-center'
                        style={{ position: "absolute" }}>
                        <FailedTryAgain
                            errorMssg={fetchingError}
                            handleTryAgain={() => this.fetchBeneficiaries()}
                        />
                    </div>
                );

            case FINISHED:
                const { tableData } = this.state;
                return (
                    <FadeIn>
                        <div>
                            <div className='pt-5'></div>
                            <div className='row justify-content-center align-item-center'>
                                <div className='col-lg-8'>
                                    <CreateBeneficiaryFilterRecords
                                        handleSearchFilterInput={
                                            this.handleSearchFilterInput
                                        }
                                        toggleAdditionBeneficiary={
                                            this.toggleAdditionBeneficiary
                                        }
                                    />
                                    <AllBeneficiariesTable
                                        bodyData={tableData}
                                    />
                                </div>
                            </div>
                        </div>
                    </FadeIn>
                );

            default:
                return "";
        }
    };

    render() {
        const { showDelete, showAddition, selectedBeneficiary } = this.state;
        return (
            <>
                <Helmet>
                    <title>
                        {process.env.REACT_APP_NAME} - Beneficiaries Management
                    </title>
                </Helmet>
                <PageNavigator label='Beneficiaries' />
                <GroomPanel>{this.renderBasedOnState()}</GroomPanel>
                {showDelete && (
                    <DeleteBeneficiary
                        show={showDelete}
                        toggleModal={() =>
                            this.setState({
                                showDelete: false,
                                selectedBeneficiary: {},
                            })
                        }
                        selectedBeneficiary={selectedBeneficiary}
                        handleDeletion={this.handleDeletion}
                    />
                )}

                {showAddition && (
                    <CreateBeneficiaryModal
                        show={showAddition}
                        toggleModal={this.toggleAdditionBeneficiary}
                        pushToBeneficiaryList={this.pushToBeneficiaryList}
                    />
                )}
            </>
        );
    }
}

export default BeneficiaryManagement;
