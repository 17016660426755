import {
	INIT_VERIFICATION,
	NEXT_WAYBILL_CONFIRMATION_STEP,
	PREV_WAYBILL_CONFIRMATION_STEP,
	UPDATE_WAYBILL_STATUS,
	UPDATE_WAYBILL_DETAILS,
	UPDATE_ORDER_DETAILS,
	CLEAR_VERIFICATION,
	GO_TO_STEP,
} from "../actions/types";

const defaultState = {
	currentStep: 1,
	waybill: {},
	order: {},
};

const initState = {};

export default (state = initState, action) => {
	switch (action.type) {
		case INIT_VERIFICATION: {
			const token = action.payload;
			return {
				...state,
				[token]: defaultState,
			};
		}

		case NEXT_WAYBILL_CONFIRMATION_STEP: {
			const token = action.payload;
			const prevData = state[token];
			return {
				...state,
				[token]: {
					...prevData,
					currentStep: prevData.currentStep + 1,
				},
			};
		}

		case PREV_WAYBILL_CONFIRMATION_STEP: {
			const token = action.payload;
			const prevData = state[token];
			return {
				...state,
				[token]: {
					...prevData,
					currentStep: prevData.currentStep - 1,
				},
			};
		}

		case GO_TO_STEP: {
			const { token, step } = action.payload;
			const prevData = state[token];
			return {
				...state,
				[token]: {
					...prevData,
					currentStep: step,
				},
			};
		}

		case UPDATE_WAYBILL_DETAILS: {
			const { token, waybill } = action.payload;
			const prevData = state[token];
			return {
				...state,
				[token]: {
					...prevData,
					waybill,
				},
			};
		}

		case UPDATE_WAYBILL_STATUS: {
			const { token, orderId, status } = action.payload;
			const prevData = state[token];
			let orderDetails = prevData?.waybill?.orderDetails;
			const index = orderDetails?.findIndex(
				(value) => value?.orderId === orderId
			);
			orderDetails[index] = { ...orderDetails[index], status };
			return {
				...state,
				[token]: {
					...prevData,
					waybill: {
						...prevData.waybill,
						orderDetails,
					},
				},
			};
		}

		case UPDATE_ORDER_DETAILS: {
			const { token, order } = action.payload;
			const prevData = state[token];
			return {
				...state,
				[token]: {
					...prevData,
					order,
				},
			};
		}

		case CLEAR_VERIFICATION:
			const token = action.payload;
			const _state = { ...state };
			delete _state[token];
			return _state;

		default:
			return state;
	}
};
