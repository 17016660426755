import CreateTranscationPin from "components/dashboard/components/security/CreateTranscationPin";
import CustomPinInput from "components/utils/CustomPinInput/CustomPinInput";
import ModalHeader from "components/utils/Modal/CustomModalHeader/CustomModalHeader";
import Modal from "components/utils/Modal/Modal";
import { SmallLoader } from "components/utils/SmallLoader";
import { ERROR, FINISHED, IDLE, INDIVIDUAL, LOADING } from "config/enums";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import "../../AccountStatement.scss";
import { ReactSVG } from "react-svg";
import TickIcon from "assets/img/tick_circle_icon.svg";
import { ToastsStore } from "react-toasts";
import { getErrorMessage } from "components/utils/helpers";
import { API } from "lib/api";
import { getAccountStatement } from "lib/api/apiRoutesConfig/services/FundsManagementMS/bankAccount/subRoutes";

export default function SubmitModal({ show, payload, onClose }) {
	const [currentState, setCurrentState] = useState(IDLE);
	const [successMsg, setSuccessMsg] = useState("");

	const renderBasedOnState = () => {
		switch (currentState) {
			case FINISHED:
				return <SuccessModal successMsg={successMsg} />;

			default:
				return (
					<SummaryDetails
						setCurrentState={(state) => setCurrentState(state)}
						setSuccessMsg={(message) => setSuccessMsg(message)}
						currentState={currentState}
						payload={payload}
						onClose={onClose}
					/>
				);
		}
	};

	return (
		<Modal show={show}>
			{currentState !== FINISHED && (
				<ModalHeader
					heading="Transaction PIN"
					className="p-0"
					headerClassName="setup-modals-header px-sm-5 px-3 py-3"
					dismissable={onClose}
				/>
			)}
			<>{renderBasedOnState()}</>
		</Modal>
	);
}

// Summary Details
const SummaryDetails = ({
	payload,
	setCurrentState,
	currentState,
	setSuccessMsg,
	onClose,
}) => {
	const [transactionPin, setTransactionPin] = useState("");
	const { dashboardResponse, loginResponse, activeAccountType } = useSelector(
		(state) => ({
			dashboardResponse: state.dashboard.dashboardResponse,
			loginResponse: state.auth.loginResponse,
			activeAccountType: state.auth.activeAccountType,
		})
	);

	const { accountId } = dashboardResponse?.bankAccounts
		? dashboardResponse.bankAccounts[0]
		: {};

	const accountType =
		activeAccountType === INDIVIDUAL
			? "activeIndividualAccount"
			: "activeCorporateAccount";

	const { transactionPinCreated } =
		loginResponse[accountType]?.profileSetting || {};

	const handleSubmit = async () => {
		setCurrentState(LOADING);
		try {
			const response = await API.put(getAccountStatement(accountId), {
				...payload,
				pin: transactionPin,
			});
			const { status, data } = response || {};
			if (status === 200) {
				setCurrentState(FINISHED);
				setSuccessMsg(data.message);
			}
		} catch (err) {
			setCurrentState(ERROR);
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
			// console.log(err)
		}
	};

	return (
		<div className="ast-summary px-sm-5 px-3">
			{transactionPinCreated ? (
				<CustomPinInput
					hasDashedBorder
					length={4}
					secret
					title="Enter Transaction PIN to Proceed"
					containerStyle={{ flexWrap: "wrap" }}
					onChange={(value) => {
						setTransactionPin(value);
					}}
				/>
			) : (
				<CreateTranscationPin
					ConfirmTranscation={handleSubmit}
					toggleModal={onClose}
				/>
			)}

			<div className="ast-summary-footer">
				<button className="btn btn-custom-bordered" onClick={onClose}>
					Cancel
				</button>
				<button
					className="btn btn-mint-default"
					disabled={
						transactionPin.length !== 4 || currentState === LOADING
					}
					onClick={handleSubmit}
				>
					{currentState === LOADING ? (
						<SmallLoader />
					) : (
						"Confirm Request"
					)}
				</button>
			</div>
		</div>
	);
};

// Success State
const SuccessModal = ({ successMsg }) => {
	const goToDashboard = () => (window.location.href = "/dashboard");
	return (
		<div className="ast-success-modal">
			<ReactSVG src={TickIcon} />
			<h2>Request Successful</h2>
			<p>{successMsg}</p>
			<button className="btn btn-mint-default" onClick={goToDashboard}>
				Done
			</button>
		</div>
	);
};
