import cacReg from "../";
export const {
  getDetailsAction,
  postBusinessDetailsAction,
  postDirectorDetailsAction,
  postIdentificationDetailsAction,
  postShareHolderDetailsAction,
  postShareHolderIdAction,
  postSecretaryDetailsAction,
  postSecretaryIdAction,
  postBusinessDocumentsAction,
  postTrusteesDetailsAction,
  postTrusteesIdAction,
  postAcceptTermsAction,
  postPayMintynAction,
  postDebitAccountAction,
  postPayCardAction,
  postVerifyPayCardAction
} = {

  getDetailsAction: `${cacReg}`,
  postBusinessDetailsAction: (sessionId) => `${cacReg}/${sessionId}/business-details`,
  postDirectorDetailsAction: (sessionId) => `${cacReg}/${sessionId}/director-details`,
  postIdentificationDetailsAction: (id) => `${cacReg}/${id}/director/identity-card`,
  postShareHolderDetailsAction: (sessionId) => `${cacReg}/${sessionId}/share-holder-details`,
  postShareHolderIdAction: (id) => `${cacReg}/${id}/share-holder/identity-card`,
  postSecretaryDetailsAction: (sessionId) => `${cacReg}/${sessionId}/secretary-details`,
  postSecretaryIdAction: (id) => `${cacReg}/${id}/secretary/identity-card`,
  postBusinessDocumentsAction: (sessionId) => `${cacReg}/${sessionId}/business-document`,
  postTrusteesDetailsAction: (sessionId) => `${cacReg}/${sessionId}/trustee-details`,
  postTrusteesIdAction: (id) => `${cacReg}/${id}/trustee/identity-card`,
  postAcceptTermsAction: (sessionId) => `${cacReg}/${sessionId}/policy-terms-condition-acceptance`,
  postPayMintynAction: (sessionId) => `${cacReg}/${sessionId}/payment-account-details`,
  postDebitAccountAction: (sessionId) => `${cacReg}/${sessionId}/debit-account`,
  postPayCardAction: (sessionId) => `${cacReg}/${sessionId}/generate-payment-ref`,
  postVerifyPayCardAction: (sessionId) => `${cacReg}/${sessionId}/verify-payment`,
};