import { ERROR, FINISHED, IDLE, LOADING } from "config/enums";
import React, { useEffect, useState } from "react";
import "../CreateSavingsGoal.scss";
import { Formik, useFormikContext } from "formik";
import * as yup from "yup";
import { API } from "lib/api";
import { formatCurrency, getErrorMessage } from "components/utils/helpers";
import { getAllSavingsTenors } from "lib/api/apiRoutesConfig/services/SavingsMS/common/subRoutes";
import { MintInfinite } from "components/dashboard/utils/ShineLoader/ShineLoader";
import FailedTryAgain from "components/dashboard/utils/FailedTryAgain/FailedTryAgain";
import FormDatePickerGroup from "components/utils/FormDatePickerGroup/FormDatePickerGroup";
import {
	addDays,
	differenceInCalendarDays,
	format as formatDate,
} from "date-fns";
import InfoIcon from "assets/img/info_gray.svg";
import { ReactSVG } from "react-svg";
import CustomSwitch from "components/dashboard/utils/CustomSwitch/CustomSwitch";
import { UncontrolledTooltip } from "reactstrap";

const formValidationSchema = yup.object().shape({
	durationInDays: yup
		.number()
		.required("Select a duration")
		.min(10, "Minimum duration is 10 days"),
	lockedSavings: yup.boolean().default(false),
	customDate: yup.date(),
	interestRate: yup.number(),
});

export default function MaturityPeriod({ payload, goToNextStep }) {
	const [showDatePicker, setShowDatePicker] = useState(false);
	const [currentState, setCurrentState] = useState(IDLE);
	const [savingsTenors, setSavingsTenors] = useState([]);
	const [errorMsg, setErrorMsg] = useState("");

	const initialFormValues = {
		lockedSavings: payload.lockedSavings,
		durationInDays: payload.durationInDays,
		customDate: undefined,
		interestRate: payload.interestRate,
	};

	const resetCustomDate = (setFieldValue, setFieldTouched) => {
		setFieldValue("customDate", undefined);
		setFieldTouched("durationInDays", false);
		setShowDatePicker(false);
	};

	const fetchSavingsTenors = async () => {
		setCurrentState(LOADING);
		try {
			const response = await API.get(getAllSavingsTenors);
			const { status, data } = response || {};
			if (status === 200) {
				setSavingsTenors(data.data);
				setCurrentState(FINISHED);
			}
		} catch (err) {
			setErrorMsg(getErrorMessage(err));
			setSavingsTenors([]);
			setCurrentState(ERROR);
		}
	};

	useEffect(() => {
		fetchSavingsTenors();
	}, []);

	const renderBasedOnState = () => {
		switch (currentState) {
			case LOADING:
				return (
					<div className="d-flex w-100 justify-content-center align-items-center saving-goal-loading">
						<MintInfinite relative />
					</div>
				);

			case ERROR:
				return (
					<div className="d-flex w-100 justify-content-center align-items-center saving-goal-loading">
						<FailedTryAgain
							errorMssg={errorMsg}
							handleTryAgain={fetchSavingsTenors}
						/>
					</div>
				);

			case FINISHED:
				return (
					<>
						<Formik
							initialValues={initialFormValues}
							validationSchema={formValidationSchema}
							onSubmit={(values) =>
								goToNextStep({
									...payload,
									...values,
									customDate: undefined,
								})
							}
						>
							{({
								values,
								errors,
								touched,
								handleSubmit,
								handleBlur,
								setFieldValue,
								resetForm,
								setFieldTouched,
							}) => {
								return (
									<>
										<form
											onSubmit={(e) => {
												e.preventDefault();
												handleSubmit();
											}}
										>
											{/* Duration options start */}
											<div>
												<div className="maturity-period-grid">
													<DurationTag
														title="10 days"
														selected={
															values.durationInDays ===
															10
														}
														setFieldTouched={
															setFieldTouched
														}
														onClick={() => {
															setFieldValue(
																"durationInDays",
																10
															);
															resetCustomDate(
																setFieldValue,
																setFieldTouched
															);
														}}
													/>
													<DurationTag
														title="30 days"
														selected={
															values.durationInDays ===
															30
														}
														setFieldTouched={
															setFieldTouched
														}
														onClick={() => {
															setFieldValue(
																"durationInDays",
																30
															);
															resetCustomDate(
																setFieldValue,
																setFieldTouched
															);
														}}
													/>
													<DurationTag
														title="60 days"
														selected={
															values.durationInDays ===
															60
														}
														setFieldTouched={
															setFieldTouched
														}
														onClick={() => {
															setFieldValue(
																"durationInDays",
																60
															);
															resetCustomDate(
																setFieldValue,
																setFieldTouched
															);
														}}
													/>
													<DurationTag
														title="90 days"
														selected={
															values.durationInDays ===
															90
														}
														setFieldTouched={
															setFieldTouched
														}
														onClick={() => {
															setFieldValue(
																"durationInDays",
																90
															);
															resetCustomDate(
																setFieldValue,
																setFieldTouched
															);
														}}
													/>
													<DurationTag
														title="182 days"
														selected={
															values.durationInDays ===
															182
														}
														setFieldTouched={
															setFieldTouched
														}
														onClick={() => {
															setFieldValue(
																"durationInDays",
																182
															);
															resetCustomDate(
																setFieldValue,
																setFieldTouched
															);
														}}
													/>
													<DurationTag
														title="365 days"
														selected={
															values.durationInDays ===
															365
														}
														setFieldTouched={
															setFieldTouched
														}
														onClick={() => {
															setFieldValue(
																"durationInDays",
																365
															);
															resetCustomDate(
																setFieldValue,
																setFieldTouched
															);
														}}
													/>
													<div className="w-100">
														{!showDatePicker ? (
															<DurationTag
																title="Pick my own date"
																onClick={() =>
																	setShowDatePicker(
																		true
																	)
																}
															/>
														) : (
															<FormDatePickerGroup
																isClearable={
																	false
																}
																placeholder="Select date"
																selected={
																	values.customDate
																}
																onBlur={
																	handleBlur
																}
																minDate={addDays(
																	new Date(),
																	10
																)}
																maxDate={addDays(
																	new Date(),
																	730
																)}
																name="customDate"
																onChange={(
																	date
																) => {
																	setFieldValue(
																		"customDate",
																		date
																	);
																	setFieldValue(
																		"durationInDays",
																		differenceInCalendarDays(
																			date,
																			new Date()
																		)
																	);
																}}
																error={
																	touched.durationInDays &&
																	errors.durationInDays
																}
															/>
														)}
													</div>
												</div>
												{touched.durationInDays &&
													errors.durationInDays && (
														<span className="error-msg">
															{
																errors.durationInDays
															}
														</span>
													)}
											</div>
											{/* Duration options end */}

											{/* Maturity period details starts */}
											{values.durationInDays &&
												savingsTenors.length > 0 &&
												savingsTenors
													.filter(
														(item) =>
															values.durationInDays >=
																item.minimumDuration &&
															values.durationInDays <=
																item.maximumDuration
													)
													.map((tenor, index) => {
														return (
															<div
																key={index}
																className="maturity-period-details"
															>
																<div className="maturity-period-details__item">
																	<span>
																		Maturity
																		period
																	</span>
																	<span>
																		{
																			values.durationInDays
																		}{" "}
																		days
																	</span>
																</div>
																<div className="maturity-period-details__item">
																	<span>
																		Minimum
																		fund
																		amount
																	</span>
																	<span>
																		{formatCurrency(
																			payload.fundingAmount
																		)}
																	</span>
																</div>
																<div className="maturity-period-details__item">
																	<span>
																		Annnual
																		interest
																		rate
																	</span>
																	<span>
																		{
																			tenor.interestRate
																		}
																		%
																	</span>
																</div>
																<div className="maturity-period-details__item">
																	<span>
																		Matures
																		by
																	</span>
																	<span>
																		{formatDate(
																			addDays(
																				new Date(),
																				values.durationInDays
																			),
																			"MMM dd, yyyy"
																		)}
																	</span>
																</div>
																<div className="maturity-period-details__item">
																	<span>
																		Withdrawal
																		possible
																		by
																	</span>
																	<span>
																		{formatDate(
																			addDays(
																				new Date(),
																				values.durationInDays
																			),
																			"MMM dd, yyyy"
																		)}
																	</span>
																</div>
															</div>
														);
													})}
											{/* Maturity period details ends */}

											{/* Lock goal starts */}
											{values.durationInDays &&
												savingsTenors.length > 0 && (
													<div className="lock-goal">
														<div className="lock-goal__label">
															<ReactSVG
																src={InfoIcon}
																className="mr-3"
																id="savingsGoalLockTooltip"
															/>
															<UncontrolledTooltip
																placement="right"
																target="savingsGoalLockTooltip"
															>
																Locking a goal
																means you will
																not be able to
																withdraw funds
																from it until
																the maturity
																date has reached
															</UncontrolledTooltip>
															Lock this goal
														</div>

														<CustomSwitch
															className="lock-switch"
															inputName="lockedSavings"
															isChecked={
																values.lockedSavings
															}
															handleCheckBox={() =>
																setFieldValue(
																	"lockedSavings",
																	!values.lockedSavings
																)
															}
														/>
													</div>
												)}
											{/* Lock goal ends */}

											<button
												className="btn btn-mint-default text-capitalize"
												type="submit"
												disabled={
													!values.durationInDays
												}
												style={{
													borderRadius: "15px",
													height: "50px",
													marginTop: "36px",
												}}
											>
												Continue
											</button>
										</form>

										<FormikObserver
											savingsTenors={savingsTenors}
										/>
									</>
								);
							}}
						</Formik>
					</>
				);

			default:
				return null;
		}
	};

	return (
		<div className="create-savings-goal-page__step">
			<h2>Choose Maturity Period</h2>
			<p>How long do you want to keep this savings?</p>

			{renderBasedOnState()}
		</div>
	);
}

const DurationTag = ({ title, selected, onClick, setFieldTouched }) => {
	return (
		<div
			role="button"
			className={`auto-debit-panel ${selected ? "selected" : ""}`}
			onClick={() => {
				setFieldTouched && setFieldTouched("customDate", false);
				onClick();
			}}
		>
			{title}
		</div>
	);
};

const FormikObserver = ({ savingsTenors }) => {
	const { values, setFieldValue } = useFormikContext();

	useEffect(() => {
		if (values.durationInDays && savingsTenors.length > 0) {
			const selectedTenor = savingsTenors.filter(
				(item) =>
					values.durationInDays >= item.minimumDuration &&
					values.durationInDays <= item.maximumDuration
			);

			setFieldValue("interestRate", selectedTenor[0].interestRate);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values, savingsTenors]);

	return null;
};
