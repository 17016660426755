import React, { Component } from "react";
import Axios from "axios";
import Modal from "../../../../../utils/Modal/Modal";
import ModalHeader from "../../../../../utils/Modal/CustomModalHeader/CustomModalHeader";
import { API } from "../../../../../../lib/api";
import { getTransferBeneficiaries } from "../../../../../../lib/api/apiRoutesConfig/services/FundsManagementMS/subRoutes";
import { MintInfinite } from "../../../../utils/ShineLoader/ShineLoader";
import Beneficiary from "./Beneficiary/Beneficiary";
import SmothScrollContent from "../../../../../utils/SmothScrollContent/SmothScrollContent";
import FadeIn from "../../../effects/FadeIn";
import { SAMEBANK } from "../../../../../../config/enums";
import { getErrorMessage } from "components/utils/helpers";
import { ToastsStore } from "react-toasts";
import { validateBeneficiary } from "lib/api/apiRoutesConfig/services/FundsManagementMS/fundTransfer/subRoutes";

class ChooseBeneficiaryModal extends Component {
	_isMounted = false;
	signal = Axios.CancelToken.source();

	state = {
		showDelete: false,
		fetching: true,
	};

	componentDidMount = () => {
		this._isMounted = true;
		this.fetchBeneficiaries();
	};

	componentWillUnmount = () => {
		this.signal.cancel("canceling network calls");
		this._isMounted = false;
	};

	fetchBeneficiaries = async () => {
		try {
			this.setState({ fetching: true });
			const { accountType } = this.props;
			const res = await API.get(getTransferBeneficiaries, {
				params: {
					type: accountType === SAMEBANK ? "samebank" : "otherbank",
				},
				cancelToken: this.signal.token,
			});
			if (res.status === 200) {
				const data = res.data.data;
				this.setState({
					beneficiaries: data,
					filteredList: data,
					fetching: false,
				});
			}
		} catch (err) {
			this._isMounted &&
				this.setState({
					fetching: false,
					beneficiaries: [],
					filteredList: [],
				});
		}
	};

	// validate beneficiary
	handleValidateBeneficiary = async (beneficiaryId, callBack) => {
		try {
			const res = await API.put(
				validateBeneficiary(beneficiaryId),
				null,
				{
					cancelToken: this.signal.token,
				}
			);
			if (res.status === 200) {
				callBack();
			}
		} catch (err) {
			ToastsStore.error(getErrorMessage(err), 6000, "setup-toast");
			console.log(err);
		}
	};

	handleSearchInput = (e) => {
		const { value: query } = e.target;
		const { beneficiaries } = this.state;
		if (query === "") {
			this.setState({ filteredList: beneficiaries });
		} else {
			let lowercasedFilter = query.toLowerCase();
			let newArray = beneficiaries.filter(function (el) {
				return el.accountName.toLowerCase().includes(lowercasedFilter);
			});
			this.setState({
				filteredList: newArray,
			});
		}
	};

	render() {
		const { show, toggleModal, acceptBankDetails } = this.props;
		const { fetching, filteredList } = this.state;

		return (
			<Modal show={show}>
				<ModalHeader
					heading="Beneficiaries"
					className="p-0"
					headerClassName="setup-modals-header px-sm-5 px-3 py-3"
					dismissable={toggleModal}
				/>
				<div className="modal-body">
					<div className="row">
						<div className={`col-12 ${fetching ? "pb-5" : ""}`}>
							<div className="my-3">
								<input
									type="text"
									name="query"
									className="form-control primary search"
									placeholder="Search a beneficiary"
									onChange={this.handleSearchInput}
									autoComplete="off"
								/>
							</div>
							{fetching ? (
								<MintInfinite relative />
							) : (
								<div className="mint-list mb-5">
									<SmothScrollContent ID="mintListScroll">
										<div>
											<FadeIn>
												{filteredList.length === 0 ? (
													<h6 className="mt-5 notready">
														No Beneficiary
													</h6>
												) : (
													filteredList.map(
														(item, index) => (
															<Beneficiary
																key={index}
																acceptBankDetails={(
																	row
																) =>
																	this.handleValidateBeneficiary(
																		item.beneficiaryId,
																		() => {
																			acceptBankDetails(
																				row
																			);
																		}
																	)
																}
																row={item}
															/>
														)
													)
												)}
											</FadeIn>
										</div>
									</SmothScrollContent>
								</div>
							)}
						</div>
					</div>
				</div>
			</Modal>
		);
	}
}

export default ChooseBeneficiaryModal;
