import AirtimeTopUp from "../AirtimeTopUp/AirtimeTopUp";
import DataBundle from "../DataBundle/DataBundle";
import GlobalTopUp from "../GlobalTopUp/GlobalTopUp";

export const defaultPath = "/top-up";

export default [
	{
		title: "Airtime Top Up",
		path: `${defaultPath}/airtime`,
		component: AirtimeTopUp,
	},
	{
		title: "Data Top Up",
		path: `${defaultPath}/data-bundle`,
		component: DataBundle,
	},
	{
		title: "International Top Up",
		path: `${defaultPath}/global`,
		component: GlobalTopUp,
	},
];
