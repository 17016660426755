import { FundsManagementMS } from "../";

export const {
    getTransferBeneficiaries,
    createBeneficiary,
    deleteBeneficiary,
} = {
    getTransferBeneficiaries: `${FundsManagementMS.default}/transfer-beneficiaries`,
    createBeneficiary: `${FundsManagementMS.default}/transfer-beneficiary`,
    deleteBeneficiary: (benefiaryId) =>
        `${FundsManagementMS.default}/transfer-beneficiary/${benefiaryId}`,
};
