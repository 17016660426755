import React from "react";
import "./CustomRadioButton.scss";
import PropTypes from "prop-types";
export default function CustomRadioButton({
	defaultChecked,
	label,
	onChange,
	name,
	checked,
	cls,
	...props
}) {
	return (
		<label
			className={`custom-radio-button ${cls ? cls : ""}`}
			aria-label={name}
			onClick={(e) => e.stopPropagation()}
		>
			<input
				type="radio"
				name={name}
				defaultChecked={defaultChecked}
				onChange={onChange}
				checked={checked}
				{...props}
			/>
			<span className="checkmark"></span>
			{label && <span className="inner-label">{label}</span>}
		</label>
	);
}

CustomRadioButton.propTypes = {
	defaultChecked: PropTypes.bool,
	label: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	name: PropTypes.string,
	checked: PropTypes.bool,
};
