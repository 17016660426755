import React from "react";
import "../../SpendAndSave.scss";
import SASTransactionHistory from "../SASTransactionHistory/SASTransactionHistory";
import SpendAndSaveItem from "../SpendAndSaveItem/SpendAndSaveItem";

export default function SASListWrapper({ data = {}, refetch }) {
	const { savings } = data || {};

	return (
		<div className="sas-list-wrapper">
			<SpendAndSaveItem data={data} refetch={refetch} />

			<div className="sas-list-transactions">
				<SASTransactionHistory data={savings} />
			</div>
		</div>
	);
}
