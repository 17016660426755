import React, { Component } from "react";
import { connect } from "react-redux";
import { updateSecurityQueStatus } from "../../../../../../actions/settingsActions";
import DynamicSelect from "../../../../../utils/DynamicSelect/DynamicSelect";
import { API } from "../../../../../../lib/api";
import { getSecurityQuestions } from "../../../../../../lib/api/apiRoutesConfig/services/AccountMS/common/subRoutes";
import { getErrorMessage } from "../../../../../utils/helpers";
import LoadingBox from "../../../../../utils/LoadingBox/LoadingBox";
import OperationStatus, {
	successResponseStatus,
	failureResponseStatus,
} from "../../../../utils/OperationStatus/OperationStatus";
import CustomModalHeader from "../../../../../utils/Modal/CustomModalHeader/CustomModalHeader";
import { createSecurityQuestion } from "../../../../../../lib/api/apiRoutesConfig/services/AccountMS/profile/settings/subRoutes";

class AnswerSecurityQuestion extends Component {
	_mounted = false;

	state = {
		securityQuestions: [],
		request: {
			questionCode: "",
			questionAnswer: "",
		},
	};

	componentDidMount = () => (this._mounted = true);
	componentWillUnmount = () => (this._mounted = false);

	fetchSecurityQuestions = async () => {
		try {
			this._mounted &&
				this.setState({ isFetching: true, fetchQuesError: false });
			let res = await API.get(getSecurityQuestions);
			if (res.status === 200) {
				let questionList = [];
				res.data.data.forEach(({ code, question }) => {
					questionList.push({ label: question, value: code });
				});
				this._mounted && this.setState({ isFetching: false });
				return questionList;
			}
		} catch (err) {
			this._mounted &&
				this.setState({
					fetchQuesError: getErrorMessage(err),
					isFetching: false,
				});
		}
	};

	handleSelect = ({ value }, { name }) => {
		this.setState((prevState) => ({
			...prevState,
			request: {
				...prevState.request,
				[name]: value,
			},
		}));
	};

	handleInput = (e) => {
		const { name, value } = e.target;
		this.setState((prevState) => ({
			...prevState,
			request: {
				...prevState.request,
				[name]: value,
			},
		}));
	};

	dismissModal = (e) => {
		e.preventDefault();
		const { updateSecurityQueStatus } = this.props;
		updateSecurityQueStatus();
	};

	handleSubmit = async (e) => {
		try {
			e.preventDefault();
			this._mounted &&
				this.setState({
					loading: true,
				});
			const { request } = this.state;
			let res = await API.post(createSecurityQuestion, request);
			if (res.status) {
				this._mounted &&
					this.setState({
						loading: false,
						finished: true,
						statusObj: {
							status: successResponseStatus,
							statusMessage: res.data.message,
							successLink: "/",
							successLinkText: "Okay",
							handleSuccessLink: this.dismissModal,
						},
					});
			}
		} catch (err) {
			this._mounted &&
				this.setState({
					loading: false,
					finished: true,
					statusObj: {
						status: failureResponseStatus,
						statusMessage: getErrorMessage(err),
					},
				});
		}
	};

	canBeSubmitted = () => {
		const {
			request: { questionAnswer, questionCode },
		} = this.state;
		return questionAnswer === "" || questionCode === "";
	};

	render() {
		const {
			isFetching,
			fetchQuesError,
			loading,
			finished,
			statusObj,
		} = this.state;
		const isDisabled = this.canBeSubmitted();

		return (
			<>
				{loading ? (
					<LoadingBox />
				) : finished ? (
					<OperationStatus
						statusObj={statusObj}
						handleTryAgain={() =>
							this.setState({ finished: false })
						}
					/>
				) : (
					<>
						<CustomModalHeader
							heading={"Secure your account"}
							dismissable={false}
						/>
						<div className="modal-body">
							<div className="low-form">
								<form onSubmit={this.handleSubmit}>
									<div className="form-group">
										<label>Choose Security Question</label>
										<DynamicSelect
											name="questionCode"
											onChange={this.handleSelect}
											loadOptions={
												this.fetchSecurityQuestions
											}
											isLoading={isFetching}
											serverError={fetchQuesError}
										/>
									</div>
									<div className="form-group">
										<label>Security Answer</label>
										<input
											className={`form-control more primary `}
											name="questionAnswer"
											onChange={this.handleInput}
											autoComplete="off"
										/>
									</div>
									<button
										className="btn btn-mint-default"
										disabled={isDisabled}
									>
										complete
									</button>
								</form>
							</div>
						</div>
					</>
				)}
			</>
		);
	}
}

export default connect(null, { updateSecurityQueStatus })(
	AnswerSecurityQuestion
);
