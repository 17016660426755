import {
	SET_SPLITPAYMENT,
	SET_PARTICIPANT,
	NEXT_STEP,
	SET_SUCCESS_MODAL,
} from "./types";

export const setNextStep = (data) => (dispatch) => {
	dispatch({
		type: NEXT_STEP,
		payload: data,
	});
};

export const setSplitPayment = (data) => (dispatch) => {
	dispatch({
		type: SET_SPLITPAYMENT,
		payload: data,
	});
};

export const setParticipants = (data) => (dispatch) => {
	dispatch({
		type: SET_PARTICIPANT,
		payload: data,
	});
};

export const setSuccessModal = (data) => (dispatch) => {
	dispatch({
		type: SET_SUCCESS_MODAL,
		payload: data,
	});
};
