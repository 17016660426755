import React, { Component } from "react";
import { SmallLoader } from "../../../../../../../../../utils/SmallLoader";
import TranscationPinInput from "../../../../../../../transcations/TranscationPinInput";
import ModalHeader from "../../../../../../../../../utils/Modal/CustomModalHeader/CustomModalHeader";

class CollectExistingPin extends Component {
	processSubmission = (e) => {
		e.preventDefault();
		const { confirmTranscation } = this.props;
		const pinInput = this.refs.pinInput;
		const pin = pinInput.getPin();
		pin.length !== 4 ? pinInput.shakeInvalid() : confirmTranscation(pin);
	};

	render() {
		const {
			toggleModal,
			loading,
			heading = "Enter Pin",
			btnName = "Continue",
			label = "Please Enter your Transaction Pin to continue",
		} = this.props;
		return (
			<>
				<ModalHeader
					heading={heading}
					dismissable={toggleModal}
					headerClassName="rel-button"
				/>
				<div className="px-4 py-1">
					<form onSubmit={this.processSubmission}>
						<div className="low-form">
							<div className="rounded-bordered mb-2 text-center pt-5">
								<label>{label}</label>
								<TranscationPinInput
									className="mb-5"
									label=""
									ref="pinInput"
								/>
							</div>
						</div>
						<div className="dialog-footer mt-3 mb-4">
							<div className="flex__between">
								<button
									className="btn btn-custom-bordered mr-2 text-capitalize"
									data-dismiss="modal"
									type="button"
									aria-label="Close"
									onClick={toggleModal}
								>
									Cancel
								</button>

								<button
									className="btn create-acc text-capitalize"
									type="submit"
								>
									{loading ? <SmallLoader /> : btnName}
								</button>
							</div>
						</div>
					</form>
				</div>
			</>
		);
	}
}

export default CollectExistingPin;
