import React, { useState } from "react";
import { useHistory } from "react-router-dom";
// import BagIcon from "./assets/bag-2.svg";
import Hint from "./assets/hint.svg";
import BusinessCardIcon from "./assets/business-card.svg";
import { ReactSVG } from "react-svg";
import { businessTypes } from "./utils/exportData";
import { ReturnHome } from "./utils/returnHome";
import Helmet from "react-helmet";
import FadeIn from "components/dashboard/components/effects/FadeIn";
import Requirements from "./utils/requirements";
import PublicNavbar from "./components/navbar";
import BackButton from "components/utils/BackButton/BackButton";

const BusinessType = () => {
	const [showRequirementsModal, setShowRequirementsModal] = useState(false);
	const history = useHistory();

	return (
		<>
			<Helmet>
				<title>
					{process.env.REACT_APP_NAME} - Select Business Type
				</title>
			</Helmet>
			<FadeIn>
				<PublicNavbar>
					<BackButton
						onClick={() => history.push("/register-business")}
					/>
				</PublicNavbar>
				<section className="business-type">
					<div className="inner-div">
						<div className="back-btn-div">
							<h4>Select business type</h4>
							<p>
								Select the type of business you would like to
								create.
							</p>
						</div>

						<div className=" type-list">
							{businessTypes &&
								businessTypes.map((list, index) => (
									<TypeCard
										key={index}
										title={list.title}
										content={list.content}
										price={list.price}
										url={list.url}
										included={list.included}
										setShowRequirementsModal={
											setShowRequirementsModal
										}
									/>
								))}
						</div>
						<Requirements
							show={showRequirementsModal}
							onClose={() => setShowRequirementsModal(false)}
						/>
						<ReturnHome className={"back-home"} />
					</div>
				</section>
			</FadeIn>
			{/* 2344859595 */}
		</>
	);
};
export default BusinessType;

export const TypeCard = ({
	title,
	content,
	price,
	url,
	included,
	setShowRequirementsModal,
}) => {
	const history = useHistory();

	return (
		<div className="type-card ">
			<div className="type-card-wrapper">
				<div className="icon-wrapper">
					<div className="icon">
						<ReactSVG src={BusinessCardIcon} />
					</div>
				</div>
				<h4>{title}</h4>
				<p>{content}</p>
				<div className=" fee">
					<p className=" text">Processing fee</p>
					<p className=" cost">{price}</p>
				</div>

				{included !== "" && (
					<div className="hint">
						<ReactSVG src={Hint} />
						<p>{included}</p>
					</div>
				)}
			</div>
			<div className=" w-100 d-flex flex-column align-items-center">
				{title === "Incorporated trustee" ? (
					<button
						onClick={() => setShowRequirementsModal(true)}
						className="btn btn-mint-default"
					>
						Get Started
					</button>
				) : (
					<button
						onClick={() =>
							history.push("/register-business/" + url)
						}
						className="btn btn-mint-default"
					>
						Get Started
					</button>
				)}
			</div>
		</div>
	);
};
