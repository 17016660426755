import React from "react";
import { ReactSVG } from "react-svg";
import TickIcon from "assets/img/tick_circle_icon.svg";
import "../../EmergencySavings.scss";
import CautionIcon from "assets/img/caution_circle_icon.svg";

export default function ConfirmationSuccessModal({
	message,
	buttonLabel = "Done",
	showCancelBtn,
	onClick,
	onClose,
	response,
}) {
	const { responseCode } = response || {};

	const renderTitleBasedOnResponseCode = () => {
		switch (responseCode) {
			case "01":
				return "Transaction Pending!";
			case "02":
				return "Transaction Failed!";
			default:
				return "Congratulations!";
		}
	};

	return (
		<div
			className={`es-success-modal ${
				showCancelBtn ? "showCancelBtn" : ""
			}`}
		>
			<ReactSVG
				src={
					responseCode === "01" || responseCode === "02"
						? CautionIcon
						: TickIcon
				}
			/>

			<h3>{renderTitleBasedOnResponseCode()}</h3>

			<p>{message}</p>

			<div className="es-success-modal__footer">
				<button className="btn btn-mint-default" onClick={onClick}>
					{buttonLabel}
				</button>

				{showCancelBtn && (
					<button
						className="btn btn-custom-bordered ml-3"
						onClick={onClose}
					>
						Cancel
					</button>
				)}
			</div>
		</div>
	);
}
