import React from "react";
import SingleCompletedInvestment from "./SingleCompletedInvestment";
import { ReactComponent as RoundIcon } from "../../../../../../assets/img/cd.svg";
import { ReactComponent as ReceiptIcon } from "../../../../../../assets/img/receipt-add.svg";
import { formatCurrency } from "../../../../../utils/helpers";

const CompletedInvestmentsContent = ({ data }) => {
	const { investments, totalExpectedReturns, totalProfit } = data || {};
	const { records, totalRecords } = investments || {};

	return (
		<>
			<h3 className="sub-heading my-4">Total Investments summary</h3>

			<ul className="row full-white">
				<li className="col-sm-6 col-lg-4  rounded">
					<div className="bg-primary investment-card full-width m-0">
						<p>
							<RoundIcon />
						</p>
						<p className="mt-3">Total Returns Collected</p>
						<h4>{formatCurrency(totalExpectedReturns)}</h4>
					</div>
				</li>
				<li className="col-sm-6 col-lg-4">
					<div className="bg-success investment-card full-width m-0">
						<p>
							<ReceiptIcon />
						</p>
						<p className="mt-3">Total Profit Collected</p>
						<h4>{formatCurrency(totalProfit)}</h4>
					</div>
				</li>
			</ul>

			<h3 className="sub-heading my-4">
				Completed Investments{" "}
				<b className="text--primary badge ml-2">{totalRecords}</b>
			</h3>

			<div className="d-flex overflow-auto">
				{records?.map((investment, i) => (
					<SingleCompletedInvestment
						key={i}
						count={i + 1}
						investment={investment}
					/>
				))}
			</div>
		</>
	);
};

export default CompletedInvestmentsContent;
