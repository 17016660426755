import PropTypes from "prop-types";
import React, { Component } from "react";
import AsyncSelect from "react-select/async";
import { SelectBox } from "../../styles/SelectBox";

class DynamicSelect extends Component {
    handleRetry = (e) => {
        e.preventDefault();
        this.props.loadOptions();
    };

    render() {
        const {
            name,
            onChange,
            isSearchable,
            loadOptions,
            isLoading,
            error,
            placeholder,
            serverError,
        } = this.props;
        return (
            <>
                <AsyncSelect
                    name={name}
                    onChange={onChange}
                    isSearchable={isSearchable}
                    loadOptions={loadOptions}
                    classNamePrefix='react-select'
                    styles={SelectBox(error)}
                    isLoading={isLoading}
                    // maxMenuHeight={"50px"}
                    placeholder={placeholder}
                    cacheOptions
                    defaultOptions
										menuPortalTarget={document.body}
										{...this.props}
                />

                {serverError && (
                    <div>
                        <span className='error-msg'>{serverError}</span>
                        {` `}
                        <a
                            href='#! '
                            onClick={this.handleRetry}
                            className='retry__button'>
                            retry?
                        </a>
                    </div>
                )}
            </>
        );
    }
}

DynamicSelect.defaultProps = {
    placeholder: "",
    isSearchable: false,
};

DynamicSelect.propTypes = {
    error: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    isSearchable: PropTypes.bool,
    name: PropTypes.string,
    placeholder: PropTypes.string,
};

export default DynamicSelect;
