import { formatCurrency, formatEnum } from "components/utils/helpers";
import React from "react";
import {
	transactionTypeEnum,
	transactionStatusEnum,
} from "../../../EmergencySavings/components/constants";
import "../../SavingGoals.scss";

export default function SavingsGoalTransactions({
	data = [],
	header = "Savings History",
}) {
	return (
		<>
			<h2>{header}</h2>
			<div className="es-list-transactions__table">
				{data &&
					data.map((item, index) => {
						const {
							reference,
							transactionDate,
							fundingSource,
							transactionStatus,
							transactionType,
							amount,
						} = item || {};
						return (
							<div
								key={index}
								className="es-list-transactions-table-row"
							>
								<div>
									<h3>{reference}</h3>
									<p>
										{new Date(transactionDate)
											.toLocaleTimeString("en-US", {
												year: "numeric",
												month: "long",
												day: "numeric",
												hour: "numeric",
												minute: "numeric",
											})
											.replace("at", ", ")}
										<br />
										via{" "}
										<span className="text-capitalize">
											{formatEnum(fundingSource)}
										</span>
									</p>
								</div>

								<div>
									<p
										className={
											transactionType ===
											transactionTypeEnum.CREDIT
												? "credit"
												: "debit"
										}
									>
										{formatCurrency(amount)}
									</p>
									<span
										className={`text-uppercase ${
											transactionStatus ===
											transactionStatusEnum.SUCCESSFUL
												? "success"
												: transactionStatus ===
												  transactionStatusEnum.FAILED
												? "failed"
												: transactionStatus ===
												  transactionStatusEnum.PENDING
												? "pending"
												: ""
										}`}
									>
										{formatEnum(transactionStatus)}
									</span>
								</div>
							</div>
						);
					})}
			</div>
		</>
	);
}
