import React from "react";

const AccountTypeWrapper = ({ loading, account, handleSubmit }) => {
	const { title, text, value } = account || {};
	const status = loading ? "loading" : "";

	const pushToQRCode = () => {
		!loading && handleSubmit(value);
	};

	return (
		<div
			className={`account-options__option-card ${status}`}
			onClick={pushToQRCode}
		>
			<div>
				<h6>{title}</h6>
				<p>{text}</p>
			</div>
		</div>
	);
};

export default AccountTypeWrapper;
