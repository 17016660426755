import React from "react"
import "./OrderHistory.scss";
import { Helmet } from "react-helmet";
import FadeIn from "components/dashboard/components/effects/FadeIn";
import CustomTabs from "components/utils/CustomTabs/CustomTabs";
import { NavLink, Redirect, Route, Switch, useHistory } from "react-router-dom";
import orderHistoryRoutes, { defaultPath } from "./orderHistoryRoutes";
import BackButton from "components/utils/BackButton/BackButton";


const OderHistory = () => {
  const history = useHistory();
  return (
    <>
      <Helmet>
				<title>{process.env.REACT_APP_NAME} - Order History</title>
      </Helmet>
      	<FadeIn>
        <div className="pb-5">
          <div className="mkt-back-button mb-4">
						<BackButton
							label="Back"
							onClick={() => history.goBack()}
						/>
					</div>
					<h1 className="page-title-text"> Order History</h1>
					<p className="page-lead-text">
						View your order History and check status
					</p>

					<CustomTabs className="mt-5">
						{orderHistoryRoutes.map((route, index) => (
							<li className="nav-item" key={index}>
								<NavLink className="nav-link" to={route.path}>
									{route.title}
								</NavLink>
							</li>
						))}
					</CustomTabs>

					<div className="order-history-panel">
							<Switch>
								{orderHistoryRoutes.map((route, index) => (
									<Route
										key={index}
										exact
										path={route.path}
										component={route.component}
									/>
								))}
								<Redirect
									path="*"
									to={`${defaultPath}/all-orders`}
								/>
							</Switch>
					</div>
				</div>
			</FadeIn>
    </>
  )
}
export default OderHistory