import React, { useState } from "react";
import queryString from "query-string";
import { connect } from "react-redux";
import OrderDeliveryDetail from "./OrderDeliveryDetail/OrderDeliveryDetail";
import { previousStep } from "../../../../actions/waybillComfirmation";
import ConfirmOrderModal from "./ConfirmOrderModal/ConfirmOrderModal";
import FadeIn from "../../../dashboard/components/effects/FadeIn";

const OrderDeliveryDetails = ({
	history,
	previousStep,
	waybillConfirmation,
}) => {
	const [showModal, setShowModal] = useState(false);

	const toggleModal = () => setShowModal((prev) => !prev);

	const { token } = queryString.parse(history.location.search);
	const { order } = waybillConfirmation[token] || {};

	const render = () => {
		if (!order)
			return (
				<div className="step-box col-lg-4 col-md-6 col-sm-8 col-10 mx-auto px-0">
					<p
						style={{ fontSize: "18px" }}
						className="wording py-5 w-100 text-center"
					>
						No Order Found
					</p>
				</div>
			);

		return <OrderDeliveryDetail order={order} toggle={toggleModal} />;
	};

	return (
		<FadeIn>
			<div className="col-md-10 col-12 py-5 position-relative">
				<div
					className="position-absolute pt-1 ml-2 mt-md-0 mt-n4"
					onClick={() => previousStep(token)}
				>
					<span className="bigback__btn--link">Go Back</span>
				</div>

				<h4
					style={{ fontSize: "16px" }}
					className="dashboardIntro__heading font-weight-bold mb-5 mt-md-0 mt-3 text-center"
				>
					Delivery details {order && `for ${order?.deliveryCode}`}
				</h4>

				<div className="row justify-content-center">{render()}</div>
			</div>

			{showModal && (
				<ConfirmOrderModal
					toggle={toggleModal}
					token={token}
					history={history}
				/>
			)}
		</FadeIn>
	);
};

export default connect(({ waybillConfirmation }) => ({ waybillConfirmation }), {
	previousStep,
})(OrderDeliveryDetails);
