/* eslint-disable no-mixed-operators */
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import FormInputGroup from "components/utils/FormInputGroup/FormInputGroup";
import { EQUAL, CUSTOM } from "config/enums";
import { Formik } from "formik";
import * as yup from "yup";
import AddParticipants from "./AddParticipants";
import { useDispatch, useSelector } from "react-redux";
import { INITIATOR } from "config/enums";
import {
	setNextStep,
	setSplitPayment,
	setParticipants,
} from "actions/splitPayment";
import CustomCurrencyInput from "components/utils/CustomCurrencyInput/CustomCurrencyInput";
import { parseAmount } from "components/utils/helpers";
import GoBack from "./utils/GoBack";

const StartSplitPayment = () => {
	const dispatch = useDispatch();
	const [splitOption, setSplitOption] = useState(EQUAL);

	const { step, participants, splitPayment } = useSelector(
		(state) => state.splitPayment
	);
	const { dashboardResponse } = useSelector((state) => state.dashboard);

	const initialFormValues = {
		name: splitPayment.name,
		description: splitPayment.description,
		amount: splitPayment.amount,
		creditAccountId: splitPayment.creditAccountId,
		splitPaymentType: splitPayment.splitPaymentType,
	};

	// Validation Schema
	const formValidationSchema = yup.object().shape({
		name: yup.string().required("Is required"),
		description: yup.string(),
		amount: yup.string().required("Is required"),
	});

	// Handle set split payment
	const handleSetSplitPayment = (values) => {
		const newValue = {
			...values,
			creditAccountId: dashboardResponse.bankAccounts[0]?.accountId,
			splitPaymentType: splitOption,
		};
		dispatch(setSplitPayment(newValue));
		checkparticipantExist(dashboardResponse);

		dispatch(setNextStep(2));
	};

	// Check if participant exist
	const checkparticipantExist = (values) => {
		const list = participants.filter(
			(participant, index) =>
				participant.accountNumber ===
				dashboardResponse.bankAccounts[0]?.accountNumber
		);
		if (list.length > 0) {
		} else {
			dispatch(
				setParticipants([
					...participants,
					{
						accountName:
							dashboardResponse.bankAccounts[0]?.accountName,
						accountNumber:
							dashboardResponse.bankAccounts[0]?.accountNumber,
						amount: splitOption === EQUAL ? values.amount : "",
						participantType: INITIATOR,
					},
				])
			);
		}
	};

	return (
		<>
			<Helmet>
				<title>
					{process.env.REACT_APP_NAME} - Start A Split Payment
				</title>
			</Helmet>
			{step === 1 && (
				<section className="pb-5 split-payment ">
					<div className="split-rquests-container ">
						<div className="split-rquests-container__header">
							<h2>Start a Split payment</h2>
						</div>
						<div className="split-rquests-container__content">
							<Formik
								initialValues={initialFormValues}
								validationSchema={formValidationSchema}
								onSubmit={(values) =>
									handleSetSplitPayment(values)
								}
							>
								{({
									values,
									errors,
									touched,
									handleSubmit,
									setFieldValue,
									handleChange,
									handleBlur,
								}) => {
									return (
										<form
											className="split-payment-form row"
											onSubmit={(e) => {
												e.preventDefault();
												handleSubmit();
											}}
										>
											<div className="mb-4 col-12 ">
												<FormInputGroup
													label="Name"
													id="name"
													name="name"
													className="split-request-input"
													onBlur={handleBlur}
													value={
														values.name ??
														splitPayment.name
													}
													onChange={handleChange}
													error={
														touched.name &&
														errors.name
													}
												/>
											</div>
											<div className="mb-4 col-12 ">
												<FormInputGroup
													label="Description (Optional)"
													id="description"
													name="description"
													className="split-request-input"
													onBlur={handleBlur}
													value={values.description}
													onChange={handleChange}
												/>
											</div>
											<div className="mb-4 col-12 ">
												<CustomCurrencyInput
													label="Amount to Split"
													id="amount"
													name="amount"
													className="split-request-input"
													onBlur={handleBlur}
													value={values.amount}
													onChange={(value) => {
														setFieldValue(
															"amount",
															parseAmount(value)
														);
													}}
													error={
														values.amount !== "" &&
														values.amount < 100
															? "Minimum amount is ₦100"
															: "" ||
															  (touched.amount &&
																	errors.amount)
													}
												/>
											</div>

											<div className="mb-4 col-12 ">
												<label>Choose an Option</label>
												<div className="split-options">
													<button
														onClick={() => {
															setSplitOption(
																EQUAL
															);
														}}
														className={`btn btn-mint-default text-capitalize ${
															splitOption ===
															EQUAL
																? ""
																: "in-active"
														} `}
														type="button"
													>
														Split Equally
													</button>
													<button
														onClick={() => {
															setSplitOption(
																CUSTOM
															);
														}}
														className={`btn btn-mint-default text-capitalize  ${
															splitOption ===
															CUSTOM
																? ""
																: "in-active "
														}  `}
														type="button"
													>
														Split Custom
													</button>
												</div>
											</div>
											<div className="col-12">
												<button
													disabled={
														values.amount < 100
													}
													className={`btn btn-mint-default text-capitalize `}
													type="submit"
												>
													Proceed
												</button>
											</div>
										</form>
									);
								}}
							</Formik>
							<GoBack url="my-requests" />
						</div>
					</div>
				</section>
			)}
			{step === 2 && <AddParticipants />}
		</>
	);
};
export default StartSplitPayment;
