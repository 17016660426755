import React, { useEffect } from "react";
import $ from 'jquery';
import 'malihu-custom-scrollbar-plugin';
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css';
require('jquery-mousewheel');

const SmothScrollContent = ({ children, className, tag, ID }) => {

    useEffect(() => (smoothScrollEffect()))

    const Tag = ({ label, children, ...props }) => React.createElement((label ? label : "div"), props, children)

    const smoothScrollEffect = () => {
        $(`#${ID}`).mCustomScrollbar({
            theme: "minimal-dark"
        });
    }

    return (
        <Tag
            label={tag}
            className={className}
            id={`${ID}`}>
            {children}
        </Tag>
    )
}

export default SmothScrollContent;