import React from "react";
import RouteSwitch from "../../../utils/RouteSwitch/RouteSwitch";
import InvestmentRoutes, { defaultPath } from "./routes";

const Investments = () => {
	return (
		<RouteSwitch routes={InvestmentRoutes} to={`${defaultPath}/active`} />
	);
};

export default Investments;
