import React from 'react';

const Wave = () => {
    return (
        <div className="waveAnimation">
            <div className="waveWrapperInner bgTop">
                <div className="wave waveTop"></div>
            </div>
            <div className="waveWrapperInner bgBottom">
                <div className="wave waveBottom"></div>
            </div>
        </div>
    )
}

export default Wave;