import React from "react";
import { defaultPath } from "../../requestRoutes";
import { useHistory } from "react-router-dom";

const GoBack = ({ url }) => {
	const history = useHistory();
	return (
		<p
			id="go-back"
			onClick={() => {
				history.push(`${defaultPath}/${url}`);
			}}
		>
			Go Back
		</p>
	);
};
export default GoBack;
