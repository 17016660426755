import React, { useState } from "react";
import { connect } from "react-redux";
import { ReactSVG } from "react-svg";
import { NO_ID_CARD, FAILED_V } from "../../../../../enums";
import UploadCustomerID from "../../../../../settings/popUps/UploadCustomerID/UploadCustomerID";
import Modal from "../../../../../../utils/Modal/Modal";
import "./IDVerificationStatusBanner.scss";
import imageFiles from "../../../../../../common/imageFiles/imageFiles";

const IDVerificationStatusBanner = (props) => {
	const [showModal, setShowModal] = useState(false);

	const { infoIcon, caretRightIcon } = imageFiles?.icons;

	const { identityCardVerificationStatus: ID } = props.profileSettings || {};
	const showBanner = ID === NO_ID_CARD || ID === FAILED_V;

	let [message, style] = [];
	if (ID === NO_ID_CARD) {
		message =
			"Complete your Verification to increase your transaction limit";
		style = "warning";
	} else if (ID === FAILED_V) {
		message = "ID Verification Failed. Verify Now";
		style = "danger";
	}

	const toggleModal = () => {
		setShowModal(!showModal);
	};

	return (
		<>
			{showBanner && (
				<div
					className={`verification__barner ${style}`}
					onClick={toggleModal}
				>
					<ReactSVG src={infoIcon} className="info" />
					<span>{message}</span>
					<ReactSVG src={caretRightIcon} className="caret__right" />
				</div>
			)}
			<Modal show={showModal}>
				<UploadCustomerID toggleModal={toggleModal} />
			</Modal>
		</>
	);
};

export default connect((state) => ({
	profileSettings: state.settings.profileSettings,
}))(IDVerificationStatusBanner);
