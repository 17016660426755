import GroomPanel from "components/dashboard/utils/GroomPanel/GroomPanel";
import React from "react";
import { useHistory } from "react-router-dom";
import { ReactSVG } from "react-svg";
import PiggyIcon from "assets/img/investment_piggy.svg";
import "../../SavingGoals.scss";

export default function EmptySavingGoalList() {
	const history = useHistory();

	return (
		<GroomPanel cls="saving-goal-groom">
			<div className="saving-goal-empty">
				<ReactSVG src={PiggyIcon} />

				<h3>You have no active Savings Goal</h3>
				<p>Create a new Savings Goal by tapping on the button below</p>

				<button
					className="btn btn-mint-default"
					onClick={() =>
						history.push("/savings/goals/create-savings-goal")
					}
				>
					Create New Savings Goal
				</button>
			</div>
		</GroomPanel>
	);
}
