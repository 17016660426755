export const SAMEBANK = "Mintyn";
export const OTHER_BANKS = "Other Banks";
export const INITIAL = "INITIAL";
export const REVIEW = "REVIEW";
export const INPUT_PIN = "INPUT_PIN";
export const LOADING = "LOADING";
export const ERROR = "ERROR";
export const FINISHED = "FINISHED";
export const IDLE = "IDLE";
export const EDITABLE = "VARIABLE";
export const FIXED_PRICE = "FIXED";
export const RESET_STAGE = "RESET_STAGE";
export const PHONE_VERIFICATION = "phone";
export const EMAIL_VERIFICATION = "email";
export const CUSTOMER_BASIC_INFORMATION = "CUSTOMER_BASIC_INFORMATION";
export const INDIVIDUAL = "INDIVIDUAL";
export const ENTERPRISE = "ENTERPRISE";
export const SOLE_PROPRIETORSHIP = "SOLE_PROPRIETORSHIP";
export const SOLE_PROPRIETORSHIP_NO_CAC = "SOLE_PROPRIETORSHIP_NO_CAC";
export const INCORPORATED_TRUSTEE = "INCORPORATED_TRUSTEE";
export const BUSINESS_DOCUMENTATION = "BUSINESS_DOCUMENTATION";
export const DIRECTORS_DOCUMENTATION = "DIRECTORS_DOCUMENTATION";
export const TRUSTEES_DOCUMENTATION = "TRUSTEES_DOCUMENTATION";
export const PENDING = "PENDING";
export const FAILED = "FAILED";
export const VERIFIED = "VERIFIED";
export const NOT_UPLOADED = "NOT_UPLOADED";
export const I_AM_TRUSTEE = "I_AM_TRUSTEE";
export const I_AM_DIRECTOR = "I_AM_DIRECTOR";
export const VARIABLE = "VARIABLE";
export const FIXED = "FIXED";
export const EQUAL = "EQUAL";
export const CUSTOM = "CUSTOM";
export const RECIPIENT = "RECIPIENT";
export const INITIATOR = "INITIATOR";
export const SENT = "SENT";
export const ACTIVE = "ACTIVE";
export const COMPLETED = "COMPLETED";
export const PAID = "PAID";
export const ACCOUNT = "ACCOUNT";
export const BENEFICIARIES = "BENEFICIARIES";

export const employmentStatus = {
	EMPLOYED: "EMPLOYED",
	UNEMPLOYED: "UNEMPLOYED",
	SELF_EMPLOYED: "SELF_EMPLOYED",
	STUDENT: "STUDENT",
};

export const basicInfoSteps = {
	BUSINESS: "BUSINESS",
	ADDRESS: "ADDRESS",
	GENERAL: "GENERAL",
};

export const beneficiaryTypeEnum = {
	SELF: "SELF",
	OTHERS: "OTHERS",
};

export const accountStatementTypeEnum = {
	PERSONAL_USE: "PERSONAL_USE",
	VISA_APPLICATION: "VISA_APPLICATION",
};

export const scheduleFrequencyEnum = {
	ONCE: "ONCE",
	DAILY: "DAILY",
	WEEKLY: "WEEKLY",
	MONTHLY: "MONTHLY",
};
