export const accountTypeList = [
	{
		title: "Individual Account",
		value: "INDIVIDUAL",
		text: "Individual current account is suitable for private individual. Do more for free!",
	},
	{
		title: "Enterprise",
		value: "ENTERPRISE",
		text: "Enterprise current account is suitable for business that require transactions to be approved by more than one person",
	},
	{
		title: "Sole Proprietor",
		value: "SOLE_PROPRIETORSHIP",
		text: "Sole proprietorship current account is suitable for a one-man business category ",
	},
	{
		title: "Incorporated Trustee",
		value: "INCORPORATED_TRUSTEE",
		text: "For business such as Non-government organizations, Churches, schools, cooperative societies, clubs. ",
	},
];

export const accountTypes = {
	INDIVIDUAL: "INDIVIDUAL",
	SOLE_PROPRIETORSHIP: "SOLE_PROPRIETORSHIP",
	ENTERPRISE: "ENTERPRISE",
	INCORPORATED_TRUSTEE: "INCORPORATED_TRUSTEE",
};
