import React from "react";
import "./CustomLargeBage.scss";

export default function CustomLargeBadge({ title, onClick, isSelected, cls }) {
	return (
		<div
			role="button"
			className={`custom-large-badge ${isSelected ? "selected" : ""} ${
				cls ? cls : ""
			}`}
			onClick={onClick}
		>
			{title}
		</div>
	);
}
