import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import FadeIn from "../effects/FadeIn";
import "./AccountStatement.scss";
import CaretLeft from "assets/img/caret_left_gold.svg";
import GroomPanel from "components/dashboard/utils/GroomPanel/GroomPanel";
import StatementType from "./components/StatementType/StatementType";
// import { accountStatementTypeEnum } from "config/enums";
import StatementForm from "./components/StatementForm/StatementForm";

const MIN_STEP = 0;
const MAX_STEP = 1;

export default function AccountStatement() {
	const history = useHistory();
	const [currentStep, setCurrentStep] = useState(MIN_STEP);
	const [statementType, setStatementType] = useState("");

	const goToNextStep = () => {
		setCurrentStep((prev) => Math.min(prev + 1, MAX_STEP));
	};

	const goToPreviousStep = () => {
		setCurrentStep((prev) => Math.max(prev - 1, MIN_STEP));
	};

	const handleGoBack = () => {
		if (currentStep === MIN_STEP) {
			history.goBack();
			return;
		}
		goToPreviousStep();
	};

	const renderBasedOnStep = () => {
		switch (currentStep) {
			case 0:
				return (
					<StatementType
						accountStatementType={statementType}
						setStatementType={(type) => setStatementType(type)}
						goToNextStep={goToNextStep}
					/>
				);

			case 1:
				return (
					<StatementForm
						accountStatementType={statementType}
						goToPrevStep={goToPreviousStep}
					/>
				);

			default:
				return null;
		}
	};

	return (
		<>
			<Helmet>
				<title>{process.env.REACT_APP_NAME} - Account Statement</title>
			</Helmet>

			<FadeIn>
				<section className="account-statement pb-5">
					<div className="d-flex align-items-center mb-5">
						<button
							className="back-btn d-flex align-items-center"
							onClick={handleGoBack}
						>
							<img src={CaretLeft} alt="" />
							<span>Back</span>
						</button>
					</div>

					<h1>Account Statement</h1>
					<p>Request statement from the past</p>

					<GroomPanel cls="account-statement__panel">
						{renderBasedOnStep()}
					</GroomPanel>
				</section>
			</FadeIn>
		</>
	);
}
