import React from "react";
import Logo from "assets/img/new-logo.png";
import ReactHelmet from "../../../../utils/ReactHelmet/ReactHelmet";
import ProfileCompletedContent from "./ProfileCompletedContent/ProfileCompletedContent";
import "./ProfileCompleted.scss";

const ProfileCompleted = () => (
	<>
		<ReactHelmet title="Complete" />

		<div className="auth-container">
			<div className="row h-100 justify-content-center ">
				<div className="col-xl-6 col-lg-8 col-md-9">
					<div className="mintyn-logo">
						<a href="https://mintyn.com/">
							<img
								alt="Mintyn Bank"
								width={500}
								height={500}
								src={Logo}
							/>
						</a>
					</div>
					<ProfileCompletedContent />
				</div>
			</div>
		</div>
	</>
);

export default ProfileCompleted;
