import React, { Component } from "react";
import { PasswordRules } from "../../../../../../../../utils/PasswordRules";
import ValidateForm from "../../../../../../../../utils/Validator";
import ModalHeader from "../../../../../../../../utils/Modal/CustomModalHeader/CustomModalHeader";

class ChangePasswordForm extends Component {
    state = {
        newRequest: {
            currentPassword: "",
            newPassword: "",
            comparePassword: "",
        },
    };

    handleInput = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
            newRequest: {
                ...prevState.newRequest,
                [name]: value,
            },
            errors: {
                ...prevState.errors,
                [name]: false
            }
        }));
    };

    validatePassword = (e) => {
        const { value } = e.target;
        let errorResponse = PasswordRules(value);
        this.handleInput(e);
        this.setState({ passwordRules: errorResponse });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const { newRequest } = this.state;
        const { handleRequest } = this.props;
        const { errors, formisValid } = ValidateForm(e, newRequest) || {};
        formisValid ? handleRequest(newRequest) : this.setState({ errors });
    };

    render() {
        const { toggleModal } = this.props;
        const { newRequest, passwordRules, errors = {} } = this.state;
        const { currentPassword, newPassword, comparePassword } = newRequest;
        return (
            <div>
                <ModalHeader
                    heading='Change Password'
                    dismissable={toggleModal}
                />
                <div className='modal-body'>
                    <form onSubmit={this.handleSubmit}>
                        <div className='low-form'>
                            <div className='form-group'>
                                <label>Current Password</label>
                                <input
                                    type='text'
                                    name='currentPassword'
                                    autoComplete='off'
                                    value={currentPassword}
                                    onChange={this.handleInput}
                                    className={
                                        "form-control passwordType " +
                                        (errors["currentPassword"]
                                            ? "has-error"
                                            : "")
                                    }
                                />
                            </div>

                            <div className='form-group'>
                                <label>New Password</label>
                                <input
                                    name='newPassword'
                                    type='text'
                                    value={newPassword}
                                    onChange={this.validatePassword}
                                    className={
                                        "form-control passwordType " +
                                        (errors["newPassword"]
                                            ? "has-error"
                                            : "")
                                    }
                                    autoComplete='off'
                                />
                                {passwordRules && (
                                    <span className='error-msg'>
                                        {passwordRules[0]}
                                    </span>
                                )}
                            </div>

                            <div className='form-group'>
                                <label>Confirm New Password</label>
                                <input
                                    type='text'
                                    name='comparePassword'
                                    autoComplete='off'
                                    value={comparePassword}
                                    onChange={this.handleInput}
                                    className={
                                        "form-control passwordType " +
                                        (errors["comparePassword"]
                                            ? "has-error"
                                            : "")
                                    }
                                />

                                <span className='error-msg'>
                                    {errors["comparePassword"]}
                                </span>
                            </div>

                            <div className='mt-4'>
                                <button className='btn create-acc'>
                                    Change Password
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default ChangePasswordForm;
