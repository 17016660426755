import { combineReducers } from "redux";
import authReducer from "./auth";
import settingsReducer from "./settings";
import dashboardReducer from "./dashboard";
import stepsReducer from "./steps";
import stylesReducer from "./style";
import waybillConfirmationReducer from "./waybillConfirmationReducer";
import marketPlaceReducer from "./marketPlace";
import splitPaymentReducer from "./splitPayment";
import { CLEAR_REDUX_STORE } from "../actions/types";
import sessionStorage from "redux-persist/lib/storage/session";
import { persistReducer } from "redux-persist";

const authPersistConfig = {
	key: "auth",
	storage: sessionStorage,
	blacklist: ["newAccount"],
};

const appReducer = combineReducers({
	auth: persistReducer(authPersistConfig, authReducer),
	settings: settingsReducer,
	dashboard: dashboardReducer,
	steps: stepsReducer,
	style: stylesReducer,
	waybillConfirmation: waybillConfirmationReducer,
	marketPlace: marketPlaceReducer,
	splitPayment: splitPaymentReducer,
});

const rootReducer = (state, action) => {
	if (action.type === CLEAR_REDUX_STORE) {
		sessionStorage.removeItem("persist:root");
		sessionStorage.removeItem("persist:auth");
		// state = undefined;
	}
	return appReducer(state, action);
};

export default rootReducer;
