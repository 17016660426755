import { API } from "lib/api";
import React from "react";
import Dashboard from "./dashboard/Dashboard";
import dashboardDetailsEndpoint from "../lib/api/apiRoutesConfig/services/FundsManagementMS/dashboard";
import Bouncy from "./utils/Bouncy";
import {
	logUserOut,
	clearSessionMssg,
	updateIsLoggedIn,
} from "../actions/loginActions";
import { updateDashResponse } from "../actions/dasboardActions";
import corporateDashboardDetails from "../lib/api/apiRoutesConfig/services/Corporate/AccountServiceMS/dashboard";
import {
	ENTERPRISE,
	INCORPORATED_TRUSTEE,
	INDIVIDUAL,
	SOLE_PROPRIETORSHIP,
	SOLE_PROPRIETORSHIP_NO_CAC,
} from "config/enums";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

export default function Home() {
	const history = useHistory();
	const [getting, setGetting] = React.useState(true);
	const { activeAccountType, currentUser, isLoggedIn } = useSelector(
		(state) => state.auth
	);
	const dispatch = useDispatch();

	// For Individual Account
	async function fetchDashboardInfo() {
		try {
			const res = await API.get(dashboardDetailsEndpoint);
			if (res.status === 200) {
				dispatch(updateDashResponse(res.data.data));
				setGetting(false);
			}
		} catch (err) {
			logUserOutRedirectLogin();
		}
	}

	// For Corporate Accounts
	async function fetchCorporateDashboardInfo() {
		try {
			const res = await API.get(corporateDashboardDetails);
			if (res.status === 200) {
				dispatch(updateDashResponse(res.data.data));
				setGetting(false);
			}
		} catch (err) {
			logUserOutRedirectLogin();
		}
	}

	function fetchDashboard() {
		if (activeAccountType === INDIVIDUAL) {
			fetchDashboardInfo();
		} else if (
			[
				ENTERPRISE,
				SOLE_PROPRIETORSHIP,
				SOLE_PROPRIETORSHIP_NO_CAC,
				INCORPORATED_TRUSTEE,
			].includes(activeAccountType)
		) {
			fetchCorporateDashboardInfo();
		}
	}

	function logUserOutRedirectLogin() {
		dispatch(logUserOut());
		history.replace({
			pathname: "/sign-in",
			message: "Please login again for security",
		});
	}

	React.useEffect(() => {
		dispatch(clearSessionMssg());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		console.log("Active account type", activeAccountType);
		fetchDashboard();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeAccountType]);

	React.useEffect(() => {
		if (Object.values(currentUser).length === 0 && isLoggedIn) {
			dispatch(updateIsLoggedIn(false));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentUser, isLoggedIn]);

	if (getting) {
		return (
			<div className="absolute-center d-flex align-items-center justify-content-center">
				<Bouncy />
			</div>
		);
	}

	return <Dashboard />;
}
