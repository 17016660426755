/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Modal from "components/utils/Modal/Modal";
import { ReactSVG } from "react-svg";
import RejectIcon from "./assets/reget.svg";
import { SmallLoader } from "components/utils/SmallLoader";

const DeleteRequest = ({ show, onClose, nextAction, isLoading }) => {
	return (
		<>
			<Modal show={show} scrollable>
				<Content
					onClose={onClose}
					nextAction={nextAction}
					isLoading={isLoading}
				/>
			</Modal>
		</>
	);
};
export default DeleteRequest;

const Content = ({ onClose, nextAction, isLoading }) => {
	return (
		<div className="request-reject-modal ">
			<div className=" content">
				<div className="icon">
					<ReactSVG src={RejectIcon} />
				</div>
				<h4>Delete Request</h4>
				<p>
					This action will permanently remove this request and their
					records? Are you sure you want to continue?
				</p>
			</div>
			<div className="col-12 ">
				<button
					onClick={() => {
						nextAction();
					}}
					disabled={isLoading}
					className="btn btn-mint-default"
					type="button"
				>
					{isLoading ? <SmallLoader /> : "Continue"}
				</button>
			</div>
			<div className="col-12 ">
				<button
					onClick={() => {
						onClose();
					}}
					className="btn border-button "
					type="button"
				>
					Cancel
				</button>
			</div>
		</div>
	);
};
