import React, { Component } from "react";
import FadeIn from "../../../dashboard/components/effects/FadeIn";
import ReactHelmet from "../../../utils/ReactHelmet/ReactHelmet";
import ValidateForm from "../../../utils/Validator";
import { PasswordRules } from "../../../utils/PasswordRules";
import { createFPNewPass } from "../../../../lib/api/apiRoutesConfig/services/AccountMS/forgot-password/subRoutes";
import { API } from "../../../../lib/api";
import { getErrorMessage } from "../../../utils/helpers";
import { SmallLoader } from "../../../utils/SmallLoader";
import { RESET_STAGE } from "../../../../config/enums";
import { resetSDDetails } from "../../../../lib/api/apiRoutesConfig/services/AccountMS/security-detail/subRoutes";
import Logo from "assets/img/new-logo.png";
import FormInputGroupV2 from "components/utils/FormInputGroup/FormInputGroupV2";

class CreateFPNewPassword extends Component {
	_mounted = false;

	state = {
		securityQuestions: [],
		alert: {
			alert_newkey: 1,
		},
		errors: {},
		request: {
			newPassword: "",
			comparePassword: "",
		},
		passwordRules: [],
	};

	componentDidMount = () => (this._mounted = true);
	componentWillUnmount = () => (this._mounted = false);

	handleInput = (e) => {
		const { name, value } = e.target;
		this.setState((prevState) => ({
			...prevState,
			request: {
				...prevState.request,
				[name]: value,
			},
			errors: {
				...prevState.errors,
				[name]: false,
			},
		}));
	};

	validatePassword = (e) => {
		this.handleInput(e);
		let errorResponse = PasswordRules(e.target.value);
		this.setState({ passwordRules: errorResponse });
	};

	showError = (err) => {
		const error = getErrorMessage(err);
		this.setState({
			request: {
				newPassword: "",
				comparePassword: "",
			},
			mainError: error,
			loading: false,
		});
	};

	processRequest = async () => {
		try {
			this.setState({
				mainError: null,
				loading: true,
			});
			const { sessionId } = this.props?.match?.params;
			const requestUrl = createFPNewPass(sessionId);
			const res = await API.post(requestUrl, {
				password: this.state.request.newPassword,
			});
			if (res.status === 200) {
				this.props.history.replace({
					pathname: "/sign-in",
					message: "Password Reset Successfully",
					success: true,
				});
			}
		} catch (err) {
			this.showError(err);
		}
	};

	resetUserSecurityDetails = async () => {
		try {
			this.setState({ loading: true });
			const { pinRequestPayload, sessionId } = this.props;
			const { newPassword: password, comparePassword: confirmPassword } =
				this.state.request;
			const payLoad = {
				...pinRequestPayload,
				password,
				confirmPassword,
			};
			const url = resetSDDetails(sessionId);
			const res = await API.post(url, {
				...payLoad,
			});
			if (res.status === 200) {
				this.props.history.replace({
					pathname: "/sign-in",
					message: "Security Details Reset Successfully",
					success: true,
				});
			}
		} catch (err) {
			this.showError(err);
		}
	};

	handleSubmit = (e) => {
		e.preventDefault();
		const { request } = this.state;
		const { stage } = this.props;
		const { errors, formisValid } = ValidateForm(e, request) || {};
		formisValid
			? stage === RESET_STAGE
				? this.resetUserSecurityDetails()
				: this.processRequest()
			: this.setState({ errors });
	};

	render() {
		const { passwordRules, loading, errors, mainError, request } =
			this.state;
		const { newPassword, comparePassword } = request;

		return (
			<>
				<ReactHelmet title="Set New Password" />
				<div className="auth-container">
					<div className="row h-100 justify-content-center align-items-center">
						<div className="col-xl-6 col-lg-8 col-md-9">
							<div className="">
								<FadeIn>
									<div className="row h-100 justify-content-center">
										<div className="col-xl-9 col-lg-9 col-md-9">
											<div className="verif-center px-4">
												<div className="mintyn-logo">
													<a href="https://mintyn.com/">
														<img
															alt="Mintyn Bank"
															width={500}
															height={500}
															src={Logo}
														/>
													</a>
												</div>
											</div>

											<div className="account-verification-panel">
												<h6 className="info-heading text-center">
													Create New Password
												</h6>
												{mainError && (
													<div className="desct error fade-in">
														<p>{mainError}</p>
													</div>
												)}
												<br />
												<form
													className="login-form"
													onSubmit={this.handleSubmit}
												>
													<div>
														<FormInputGroupV2
															label="Password"
															type="password"
															name="newPassword"
															value={newPassword}
															onChange={
																this
																	.validatePassword
															}
															error={
																!!errors[
																	"newPassword"
																]
															}
															autoComplete="off"
														/>
														<span className="error-msg">
															{passwordRules[0]}
														</span>
													</div>

													<div>
														<FormInputGroupV2
															label="Confirm Password"
															type="password"
															name="comparePassword"
															autoComplete="off"
															value={
																comparePassword
															}
															onChange={
																this.handleInput
															}
															error={
																errors[
																	"comparePassword"
																]
															}
														/>
													</div>

													<button
														className="btn-primary w-100"
														disabled={loading}
													>
														{loading ? (
															<SmallLoader />
														) : (
															"Continue"
														)}
													</button>
												</form>
											</div>
										</div>
									</div>
								</FadeIn>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default CreateFPNewPassword;
