export const genErrorMessage = `Unable To Complete Action`;

const dev = {
	paymentGateway: {
		PUBLIC_KEY: process.env.REACT_APP_FLUTTERWAVE_PUBLIC_KEY,
	},
	apiGateway: {
		BASE_URL: process.env.REACT_APP_API_BASE_URL,
		CLIENT_KEY: process.env.REACT_APP_CLIENT_KEY,
	},
	MINT_BANK_CODE: process.env.REACT_APP_MINT_BANK_CODE,
};

const staging = {
	paymentGateway: {
		PUBLIC_KEY: process.env.REACT_APP_FLUTTERWAVE_PUBLIC_KEY,
	},
	apiGateway: {
		BASE_URL: process.env.REACT_APP_API_BASE_URL,
		CLIENT_KEY: process.env.REACT_APP_CLIENT_KEY,
	},
	MINT_BANK_CODE: process.env.REACT_APP_MINT_BANK_CODE,
};

const config = process.env.REACT_APP_STAGE === "staging" ? staging : dev;

export default { ...config };
