import { NumberCurrency, formatCurrency } from "./helpers";

const ValidateForm = (e, request, restParamsObj = {}) => {
	let errors = {};
	let formisValid = true;
	request &&
		Object.keys(request).forEach((field) => {
			let target = e.target[field];
			if (target && target.value !== undefined) {
				// const fieldType = target.getAttribute("field-type");
				let value =
					target?.length > 0 && target?.[1]
						? target[1].value
						: target.value;
				if (value === "") {
					errors[field] = true;
					formisValid = false;
				} else if (field === "email" || target.type === "email") {
					/* eslint-disable-next-line no-useless-escape */
					let regTest = /\S+@\S+\.\S+/.test(value);
					errors[field] = regTest
						? false
						: "Please include a valid email";
					if (regTest === false) {
						formisValid = false;
					}
					// formisValid should equal regTest anyway
				} else if (field === "comparePassword") {
					const { newPassword: { value: newPasswordVal = {} } = {} } =
						e.target || {};
					if (value !== newPasswordVal) {
						errors["comparePassword"] = `Passwords do not match`;
						formisValid = false;
					}
				} else if (field === "amount") {
					const compareAmount = restParamsObj.compareAmount;
					if (
						compareAmount &&
						NumberCurrency(value) > compareAmount
					) {
						errors[
							field
						] = `Amount should not be greater than ${formatCurrency(
							compareAmount
						)}`;
						formisValid = false;
					}
				}
				// else if (fieldType === "phoneNumber") {
				//     if(value.length !== 11 ){
				//         errors[field] = "PhoneNumber must be 11 digits";
				//         formisValid = false;
				//     }
				// }
			}
		});

	return {
		errors,
		formisValid,
	};
};

export default ValidateForm;
