import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

function SEO({ title }) {
    return (
        <Helmet
            title={title}
            titleTemplate={`${process.env.REACT_APP_NAME} - ${title}`}
        />
    );
}

SEO.propTypes = {
    title: PropTypes.string.isRequired,
};

export default SEO;
