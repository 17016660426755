import React from "react";

const EmptyState = ({
	icon: Icon,
	title,
	subtitle,
	btnName,
	handleClick,
	children,
}) => {
	return (
		<div className="text-center p-3" style={{ maxWidth: "320px" }}>
			{Icon && <Icon />}

			<p className="sub-heading mt-3">{title}</p>
			<p className="split">{subtitle}</p>

			{btnName && (
				<button
					type="button"
					className="btn create-acc mt-3"
					onClick={handleClick}
				>
					{btnName}
				</button>
			)}

			{children}
		</div>
	);
};

export default EmptyState;
