import React, { Component } from "react";
import Axios from "axios";
import PersonalInformation from "./PersonalInformation/PersonalInformation";
import { API } from "../../../../../../lib/api";
import { profileDetails } from "../../../../../../lib/api/apiRoutesConfig/services/AccountMS/profile/subRoutes";
import PSSkeleton from "./PSSkeleton/PSSkeleton";
import EmployeeInformation from "./EmployeeInformation/EmployeeInformation";
import { LOADING, ERROR, FINISHED } from "../../../../../../config/enums";
import FailedTryAgain from "../../../../utils/FailedTryAgain/FailedTryAgain";
import FadeIn from "../../../effects/FadeIn";
import { getErrorMessage } from "../../../../../utils/helpers";
import IdentificationInformation from "./IdentificationInformation/IdentificationInformation";

class ProfileSettings extends Component {
    _isMounted = false;
    signal = Axios.CancelToken.source();

    state = {
        currentState: LOADING,
        userProfileDetails: {},
    };

    componentDidMount = () => {
        this._isMounted = true;
        this.fetchCustomerInformation();
    };

    componentWillUnmount = () => {
        this._isMounted = false;
    };

    fetchCustomerInformation = async () => {
        try {
            this._isMounted &&
                this.setState({
                    currentState: LOADING,
                });
            const res = await API.get(profileDetails, {
                cancelToken: this.signal.token,
            });
            if (res.status === 200) {
                this._isMounted &&
                    this.setState({
                        userProfileDetails: res.data.data,
                        currentState: FINISHED,
                    });
            }
        } catch (err) {
            this._isMounted &&
                this.setState({
                    currentState: ERROR,
                    fetchingError: getErrorMessage(err),
                });
        }
    };

    renderBasedOnState = () => {
        const { currentState, fetchingError } = this.state;
        switch (currentState) {
            case LOADING:
                return <PSSkeleton />;
            case ERROR:
                return (
                    <FadeIn>
                        <div className='px-5 d-flex w-100 h-100 justify-content-center align-items-center'>
                            <FailedTryAgain
                                errorMssg={fetchingError}
                                handleTryAgain={this.fetchCustomerInformation}
                            />
                        </div>
                    </FadeIn>
                );
            case FINISHED:
                const {
                    userProfileDetails: { employmentDetail, idCardDetail },
                } = this.state;
                return (
                    <>
                        <FadeIn>
                            <PersonalInformation />
                            <EmployeeInformation
                                employmentDetail={employmentDetail}
                            />
                            <IdentificationInformation
                                idCardDetail={idCardDetail}
                            />
                        </FadeIn>
                    </>
                );
            default:
                return "";
        }
    };

    render() {
        return (
            <div className='row justify-content-center'>
                <div className='col-lg-7'>
                    <div id='accordion'>{this.renderBasedOnState()}</div>
                </div>
            </div>
        );
    }
}

export default ProfileSettings;
