import "./PaymentPanel.scss";
import React from "react";
import Modal from "components/utils/Modal/Modal";
import ModalHeader from "components/utils/Modal/CustomModalHeader/CustomModalHeader";
import { Formik } from "formik";
import FormInputGroup from "components/utils/FormInputGroup/FormInputGroup";
import * as yup from "yup";
import { useState } from "react";
import { ToastsStore } from "react-toasts";
import { getErrorMessage } from "components/utils/helpers";
import { API } from "lib/api";
import { SmallLoader } from "components/utils/SmallLoader";
import { validateOTPAction } from "lib/api/apiRoutesConfig/services/tradeMS/BNPL/subRoutes";
import { getValidMobileNumberLength } from "components/utils/helpers";

const formValidationSchema = yup.object().shape({
	otp: yup.string().required("OTP is required"),
});

export default function EnterOTP({ show, onClose, nextModal }) {
	return (
		<Modal show={show} scrollable>
			<ModalHeader
				heading="Pay Later"
				className="p-0"
				headerClassName="setup-modals-header px-sm-5 px-3 py-3"
				dismissable={() => onClose?.()}
			/>
      <EnterOTPForm onClose={onClose} nextModal={nextModal} />
		</Modal>
	);
}

const EnterOTPForm = ({onClose, nextModal}) => {
	const [isLoading, setIsLoading] = useState(false);

	const initialFormValues = {
		otp: "",
	};

	const handleSubmit = async (values) => {
		setIsLoading(true);
		try {
			const res = await API.post(validateOTPAction, {
				...values,
			});
			const { status, } = res || {};
			if (status === 200) {
        onClose()
				nextModal()
				
			}
		} catch (err) {
			
      ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		} finally {
			setIsLoading(false);
		}
	};



	return (
    <div className="mkt-payment-modal ">
      <Formik
			initialValues={initialFormValues}
			validationSchema={formValidationSchema}
			onSubmit={(values) => handleSubmit(values)}
		>
			{({
				values,
				errors,
				touched,
				handleSubmit,
				setFieldValue,
				handleBlur,
			}) => {
				return (
					<form
						className="mkt-payment-modal-form "
						onSubmit={(e) => {
							e.preventDefault();
							handleSubmit();
						}}
					>

						<div className="mb-4 col-12">
							<FormInputGroup
								id="otp"
								placeholder=""
								name="otp"
								label=" Please enter the 6 digit OTP sent to your work email"
								className="mkt-pay-later-modal-input"
								onBlur={handleBlur}
								value={values.otp}
								onChange={(e) => {
										const value = e.target.value
											.trim()
											.replace(/[^+0-9]/i, "");
										if (
											value.length <=
											getValidMobileNumberLength(value)
										) {
											setFieldValue(e.target.name, value);
										}
									}}
								error={touched.otp && errors.otp}
							/>
						</div>

						<div className="col-12">
							<button
								className="btn btn-mint-default new-address-btn"
								type="submit"
								disabled={isLoading}
							>
								{isLoading ? (
									<SmallLoader />
								) : (
									<>
										Continue
									</>
								)}
							</button>
						</div>
					</form>
				);
			}}
		</Formik>
    </div>
	);
};
