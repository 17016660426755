import React from "react";
import "../../BillsPayment/BillPayment.scss";
import { ReactSVG } from "react-svg";
import TickIcon from "assets/img/tick_circle_icon.svg";
import CautionIcon from "assets/img/caution_circle_icon.svg";
import { formatCurrency } from "components/utils/helpers";
// import CalendarIcon from "assets/img/schedule_calendar.svg";
// import ShareIcon from "assets/img/share_icon.svg";

export default function BillsPaymentSuccessModal({
	message,
	buttonLabel = "Close",
	transaction,
	transactionResponse,
}) {
	const { customerId, rrr } = transaction || {};

	const {
		amount,
		billerName,
		transactionCode,
		transactionReference,
		transactionMessage,
	} = transactionResponse || {};

	const renderTitleBasedOnResponseCode = () => {
		switch (transactionCode) {
			case "01":
				return "Transaction Pending!";
			case "02":
				return "Transaction Failed!";
			default:
				return "Congratulations";
		}
	};

	const goToDashboard = () => (window.location.href = "/dashboard");

	return (
		<div className="bp-success-modal">
			<ReactSVG src={transactionCode === "00" ? TickIcon : CautionIcon} />
			<h2>{renderTitleBasedOnResponseCode()}</h2>
			<p>{transactionMessage || message}</p>

			<div className="bp-success-modal-body pb-5">
				<div className="bp-success-modal-info">
					<span>Amount</span>
					<span>{formatCurrency(amount)}</span>
				</div>

				<div className="bp-success-modal-info">
					<span>Customer ID</span>
					<span>{customerId || rrr}</span>
				</div>

				<div className="bp-success-modal-info">
					<span>Biller</span>
					<span>{billerName?.replace("VTU", "")}</span>
				</div>

				<div className="bp-success-modal-info">
					<span>Reference</span>
					<span>{transactionReference}</span>
				</div>

				<div className="bp-success-modal-info">
					<span>Date & Time</span>
					<span>
						{new Date()
							.toLocaleTimeString([], {
								year: "numeric",
								month: "long",
								day: "numeric",
								hour: "2-digit",
								minute: "2-digit",
							})
							.replace("at", "/")}
					</span>
				</div>
				{/* To be used later */}
				{/* <div className="bp-success-modal-info no-border">
					<span role="button">
						<ReactSVG
							src={CalendarIcon}
							style={{ marginRight: "5px" }}
						/>
						Schedule
					</span>

					<span role="button">
						<ReactSVG
							src={ShareIcon}
							style={{ marginRight: "5px" }}
						/>
						View Receipt
					</span>
				</div> */}
			</div>

			<button className="btn btn-mint-default" onClick={goToDashboard}>
				{buttonLabel}
			</button>
		</div>
	);
}
