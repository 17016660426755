import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import {
	defaultPath,
	remitaCategoryRoutes,
} from "../../../components/BillsPaymentRoutes/BillsPaymentRoutes";

export default function RemitaCategory() {
	return (
		<Switch>
			{remitaCategoryRoutes.map((route, index) => (
				<Route
					key={index}
					exact
					path={route.path}
					component={route.component}
				/>
			))}
			<Redirect path="*" to={`${defaultPath}/remita`} />
		</Switch>
	);
}
