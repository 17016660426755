import React from "react";
import TableCell from "../CustomTableCell/CustomTableCell";

const TableRow = ({ data = {}, onClick, className }) => {
    return (
        <tr className={className} onClick={onClick}>
            {Object.values(data).map((dataBit, i) => (
                <TableCell key={i} content={dataBit} HTMLTag='td' />
            ))}
        </tr>
    );
};

export default TableRow;
