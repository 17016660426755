import React from "react";
import { useSelector } from "react-redux";
// import { useHistory } from "react-router-dom";
import // basicInfoSteps,
// CUSTOMER_BASIC_INFORMATION,
"../../../../../config/enums";
import Logo from "assets/img/new-logo.png";
import ReactHelmet from "../../../../utils/ReactHelmet/ReactHelmet";
// import AddressBasicInformation from "./AddressBasicInformation/AddressBasicInformation";
import OthersBasicInformation from "./OthersBasicInformation/OthersBasicInformation";
// import BusinessBasicInformation from "./BusinessBasicInformation/BusinessBasicInformation";
import "./BasicInformation.scss";

const BasicInformation = () => {
	const { steps, auth } = useSelector((reducers) => reducers);
	const { stepData } = steps || {};
	const { sessionId } = auth?.newAccount || {};

	// const history = useHistory();

	// React.useEffect(() => {
	// 	// if (stepData?.nextStage !== CUSTOMER_BASIC_INFORMATION) {
	// 	// 	history.replace("/sign-up");
	// 	// }
	// 	// console.log(stepData);
	// 	// eslint-disable-next-line
	// }, []);

	// const renderBasedOnStep = () => {
	// 	switch (stepData?.basicInfoStep) {
	// 		case basicInfoSteps.BUSINESS:
	// 			return <BusinessBasicInformation stepData={stepData} />;

	// 		case basicInfoSteps.ADDRESS:
	// 			return (
	// 				<AddressBasicInformation
	// 					stepData={stepData}
	// 					sessionId={sessionId}
	// 				/>
	// 			);

	// 		case basicInfoSteps.GENERAL:
	// 			return (
	// 				<OthersBasicInformation
	// 					stepData={stepData}
	// 					sessionId={sessionId}
	// 				/>
	// 			);

	// 		default:
	// 			return "";
	// 	}
	// };

	return (
		<>
			<ReactHelmet title="Basic Information" />

			<div className="auth-container">
				<div className="row h-100 justify-content-center ">
					<div className="col-xl-5 col-lg-8 col-md-9">
						<div className="mintyn-logo">
							<a href="https://mintyn.com/">
								<img
									alt="Mintyn Bank"
									width={500}
									height={500}
									src={Logo}
								/>
							</a>
						</div>
						{/* {renderBasedOnStep()} */}
						<OthersBasicInformation
							stepData={stepData}
							sessionId={sessionId}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default BasicInformation;
