import CreateTranscationPin from "components/dashboard/components/security/CreateTranscationPin";
import CustomPinInput from "components/utils/CustomPinInput/CustomPinInput";
import {
	formatCurrency,
	formatEnum,
	getErrorMessage,
} from "components/utils/helpers";
import ModalHeader from "components/utils/Modal/CustomModalHeader/CustomModalHeader";
import Modal from "components/utils/Modal/Modal";
import { SmallLoader } from "components/utils/SmallLoader";
import { ERROR, FINISHED, IDLE, INDIVIDUAL, LOADING } from "config/enums";
import { API } from "lib/api";
import { completeBillsPaymentV2 } from "lib/api/apiRoutesConfig/services/BillsService/subRoutes";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ToastsStore } from "react-toasts";
import BillsPaymentSuccessModal from "../../components/BillsPaymentSuccessModal/BillsPaymentSuccessModal";
import "../BillPayment.scss";
import { format as formatDate } from "date-fns";

export default function BettingAndGamingSummary({
	show,
	transaction,
	onClose,
}) {
	const [currentState, setCurrentState] = useState(IDLE);
	const [successMsg, setSuccessMsg] = useState("");
	const [transactionResponse, setTransactionResponse] = useState();

	const renderBasedOnState = () => {
		switch (currentState) {
			case FINISHED:
				return (
					<BillsPaymentSuccessModal
						message={successMsg}
						transaction={transaction}
						transactionResponse={transactionResponse}
					/>
				);
			default:
				return (
					<SummaryDetails
						setCurrentState={(state) => setCurrentState(state)}
						setSuccessMsg={(message) => setSuccessMsg(message)}
						setTransactionResponse={(response) =>
							setTransactionResponse(response)
						}
						currentState={currentState}
						transaction={transaction}
						onClose={onClose}
					/>
				);
		}
	};

	return (
		<Modal show={show} scrollable>
			{currentState !== FINISHED && (
				<ModalHeader
					heading="Betting & Gaming Confirmation"
					className="p-0"
					headerClassName="setup-modals-header px-sm-5 px-3 py-3"
					dismissable={onClose}
				/>
			)}
			<>{renderBasedOnState()}</>
		</Modal>
	);
}

const SummaryDetails = ({
	transaction,
	setCurrentState,
	currentState,
	setSuccessMsg,
	setTransactionResponse,
	onClose,
}) => {
	const [transactionPin, setTransactionPin] = useState("");
	const { dashboardResponse, loginResponse, activeAccountType } = useSelector(
		(state) => ({
			dashboardResponse: state.dashboard.dashboardResponse,
			loginResponse: state.auth.loginResponse,
			activeAccountType: state.auth.activeAccountType,
		})
	);

	const { accountId: debitAccountId } = dashboardResponse?.bankAccounts
		? dashboardResponse.bankAccounts[0]
		: {};

	const accountType =
		activeAccountType === INDIVIDUAL
			? "activeIndividualAccount"
			: "activeCorporateAccount";

	const { transactionPinCreated } =
		loginResponse[accountType]?.profileSetting || {};

	const {
		amount,
		customerId,
		biller,
		vasBillerId,
		itemCode,
		startDateTime,
		endDate,
		title,
		frequency,
		recurringBill,
		addToBeneficiary,
		customerName,
		validatedCustomer,
		referenceNumber,
	} = transaction || {};

	const handleSubmit = async () => {
		setCurrentState(LOADING);
		try {
			const res = await API.post(completeBillsPaymentV2(vasBillerId), {
				amount,
				customerId,
				customerName,
				debitAccountId,
				itemCode,
				transactionPin,
				frequency,
				recurringBill,
				title,
				addToBeneficiary,
				reference: referenceNumber,
				startDateTime: startDateTime
					? formatDate(startDateTime, "dd/MM/yyyy HH")
					: startDateTime,
				endDate: endDate ? formatDate(endDate, "dd/MM/yyyy") : endDate,
			});
			if (res.status === 200) {
				setCurrentState(FINISHED);
				setSuccessMsg(res.data.message);
				setTransactionResponse(res.data.data);
			}
		} catch (err) {
			setCurrentState(ERROR);
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		}
	};

	return (
		<div className="bp-summary-form px-sm-5 px-3">
			<div className="bp-summary-info">
				<span>Amount to Pay</span>
				<span>{formatCurrency(amount)}</span>
			</div>

			<div className="bp-summary-info">
				<span>Customer ID</span>
				<span>{customerId}</span>
			</div>

			<div className="bp-summary-info">
				<span>Customer Name</span>
				<span>{validatedCustomer}</span>
			</div>

			<div className="bp-summary-info">
				<span>Reference Number</span>
				<span>{referenceNumber}</span>
			</div>

			<div className="bp-summary-info">
				<span>Biller</span>
				<span>{biller}</span>
			</div>

			{recurringBill && (
				<>
					<div className="bp-summary-info">
						<span>Bill Title</span>
						<span>{title}</span>
					</div>

					<div className="bp-summary-info">
						<span>Frequency</span>
						<span className="text-uppercase">
							{formatEnum(frequency)}
						</span>
					</div>

					<div className="bp-summary-info">
						<span>Start Date</span>
						<span>
							{formatDate(
								startDateTime ?? 0,
								"dd/MM/yyyy @ hh:mm a"
							)}
						</span>
					</div>

					<div className="bp-summary-info">
						<span>End Date</span>
						<span>{formatDate(endDate ?? 0, "dd/MM/yyyy")}</span>
					</div>
				</>
			)}

			{transactionPinCreated ? (
				<CustomPinInput
					hasDashedBorder
					className="my-5"
					length={4}
					secret
					title="Enter Transaction PIN to confirm"
					containerStyle={{ flexWrap: "wrap" }}
					onChange={(value) => {
						setTransactionPin(value);
					}}
				/>
			) : (
				<CreateTranscationPin
					ConfirmTranscation={handleSubmit}
					toggleModal={onClose}
				/>
			)}

			{transactionPinCreated && (
				<button
					className="btn create-acc"
					disabled={
						transactionPin.length !== 4 || currentState === LOADING
					}
					onClick={handleSubmit}
				>
					{currentState === LOADING ? (
						<SmallLoader />
					) : (
						"Confirm Payment"
					)}
				</button>
			)}
		</div>
	);
};
