import React, { Component } from "react";
import { CSSTransitionGroup } from 'react-transition-group';

export default class FadeIn extends Component {
    render = () => {
        return (
            <CSSTransitionGroup
                component={React.Fragment}
                transitionName="home-views"
                transitionAppear={true}
                transitionAppearTimeout={3000}
                transitionEnterTimeout={3000}
                transitionLeaveTimeout={3000}>
                {this.props.children}
            </CSSTransitionGroup>
        )
    }
}