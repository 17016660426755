import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import CreateSavingsGoal from "../CreateSavingsGoal/CreateSavingsGoal";
import SavingGoalDetails from "../SavingGoalDetails/SavingGoalDetails";

const defaultPath = "/savings/goals";

const routes = [
	{
		title: "Savings Goal Details",
		path: `${defaultPath}/details/:id`,
		component: SavingGoalDetails,
	},
	{
		title: "Create Savings Goal",
		path: `${defaultPath}/create-savings-goal`,
		component: CreateSavingsGoal,
	},
];

export default function SavingGoalsRoutes() {
	return (
		<Switch>
			{routes.map((route, index) => (
				<Route
					key={index}
					exact
					path={route.path}
					component={route.component}
				/>
			))}
			<Redirect path="*" to={`${defaultPath}`} />
		</Switch>
	);
}
