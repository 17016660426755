import React from "react";
import MintPillar from "../../assets/img/bigmint.svg";
// import "../../css/bounce.css";

const Bouncy = () => {
	return (
		<div style={{ height: "240.12px" }}>
			<div className="d-flex w-100 h-100">
				<img src={MintPillar} alt="mintyn" />
			</div>
		</div>
	);
};

export default Bouncy;
