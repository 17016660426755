import CustomSwitch from "components/dashboard/utils/CustomSwitch/CustomSwitch";
import { formatCurrency, formatEnum } from "components/utils/helpers";
import React, { useState } from "react";
import Chart from "react-apexcharts";
import SavingGoalWithdrawal from "../modals/SavingGoalWithdrawal";
import FundMintAccountModal from "components/dashboard/components/Landing/modals/FundMintAccount/FundMintAccount";
import SavingGoalDeposit from "../modals/SavingGoalDeposit";
import { ToastsStore } from "react-toasts";

export default function SavingGoalCard({ savingsGoal, setShowModal, refetch }) {
	const {
		savingsBalance,
		savingFrequency,
		savingsAmount,
		autoSaveEnabled,
		goalId,
		targetAmount,
		interestRate,
		accruedInterest,
		startDate,
		nextSavingsDate,
		maturityDate,
		noWithdrawalErrorMessage,
	} = savingsGoal || {};

	const [showWithdrawalModal, setShowWithdrawalModal] = useState(false);
	const [showDepositModal, setShowDepositModal] = useState(false);
	const [showFundingModal, setShowFundingModal] = useState(false);
	const [chartData] = useState({
		options: {
			labels: ["Savings goal"],
			plotOptions: {
				radialBar: {
					hollow: {
						margin: 0,
						size: "60%",
					},
					dataLabels: {
						name: {
							show: true,
							color: "#fff",
						},
						value: {
							show: true,
						},
					},
				},
			},
			fill: {
				opacity: 1.5,
				colors: ["#3E62BE"],
				type: "gradient",
				gradient: {
					gradientToColors: ["#3E62BE"],
					shadeIntensity: 1,
					opacityFrom: 1,
					opacityTo: 2,
					stops: [0, 50, 100],
					inverseColors: false,
				},
			},
			stroke: {
				lineCap: "round",
			},
		},
		series: [parseInt(((savingsBalance / targetAmount) * 100).toFixed(0))],
	});

	return (
		<>
			<div className="saving-goal-card">
				<Chart
					options={chartData.options}
					series={
						chartData.series[0] >= 100 ? [100] : chartData.series
					}
					type="radialBar"
					width="100%"
				/>

				<div className="saving-goal-card__footer">
					<button
						className="btn btn-savings-lg"
						onClick={() => setShowDepositModal(true)}
					>
						Add Funds
					</button>

					<button
						className="btn btn-bordered-light"
						onClick={() => {
							if (noWithdrawalErrorMessage) {
								ToastsStore.error(
									noWithdrawalErrorMessage,
									6000,
									"right-toast"
								);
								return;
							}
							setShowWithdrawalModal(true);
						}}
					>
						Withdraw
					</button>
				</div>

				<div className="d-flex justify-content-between">
					<div>
						<p>Target</p>
						<h4>{formatCurrency(targetAmount)}</h4>
					</div>
					<div>
						<p className="text-right">Balance</p>
						<h4 className="text-right">
							{formatCurrency(savingsBalance)}
						</h4>
					</div>
				</div>

				<div className="d-flex justify-content-between">
					<div>
						<p>Savings Frequency</p>
						<h4>
							{formatCurrency(savingsAmount)}
							{savingFrequency !== "NONE" && (
								<span style={{ fontSize: "12px" }}>
									/ {formatEnum(savingFrequency)}
								</span>
							)}
						</h4>
					</div>
					<div>
						<p className="text-right">Savings Plan Tier</p>
						<h4 className="text-right">DEFAULT</h4>
					</div>
				</div>

				<div className="d-flex justify-content-between">
					<div>
						<p>Interest P/A</p>
						<h4>{interestRate}%</h4>
					</div>
					<div>
						<p className="text-right">Accrued Interest</p>
						<h4 className="text-right">
							{formatCurrency(accruedInterest)}
						</h4>
					</div>
				</div>

				<div className="d-flex justify-content-between">
					<div>
						<p>Next Saving date</p>
						<h4>{nextSavingsDate || "---"}</h4>
					</div>
					<div>
						<p className="text-right">Start date</p>
						<h4 className="text-right">{startDate || "---"}</h4>
					</div>
				</div>

				<div className="d-flex justify-content-between">
					<div>
						<p>Maturity Date</p>
						<h4>{maturityDate || "---"}</h4>
					</div>
					<div></div>
				</div>

				<div
					className="d-flex justify-content-between align-items-center"
					style={{ marginBotton: "0px" }}
				>
					<div>
						<p>Automatic Deposit</p>
						<h4>{autoSaveEnabled ? "Active" : "Inactive"}</h4>
					</div>
					<div>
						<CustomSwitch
							className="es-switch"
							inputName="autoSaveEnabled"
							isChecked={autoSaveEnabled}
							handleCheckBox={() => setShowModal(true)}
						/>
					</div>
				</div>
			</div>

			<SavingGoalWithdrawal
				show={showWithdrawalModal}
				goalId={goalId}
				onClose={() => setShowWithdrawalModal(false)}
				savingBalance={savingsBalance}
			/>

			<SavingGoalDeposit
				show={showDepositModal}
				refetch={refetch}
				savingsGoal={savingsGoal}
				onClose={() => setShowDepositModal(false)}
				openFundingModal={() => setShowFundingModal(true)}
			/>

			<FundMintAccountModal
				show={showFundingModal}
				dismissModal={() => setShowFundingModal(false)}
			/>
		</>
	);
}
