import React from "react";
import { connect } from "react-redux";
import { Modal as ReactstrapModal } from "reactstrap";
import PropTypes from "prop-types";
import "./Modal.scss";

export const CloseModalBtn = ({ actionMethod }) => (
    <button type='button' className='btn close' onClick={() => actionMethod()}>
        &times;
    </button>
);

// new modal props isStatic to still show even when other Modals
// are forcefully closed
const Modal = ({
    show,
    isStatic,
    kickedOut,
    size,
    toggle,
    onClosed,
    children,
    theme,
		scrollable = true,
		...props
}) => (
    <ReactstrapModal
        isOpen={isStatic ? show : show && !kickedOut}
        size={size}
        fade={true}
        modalClassName={theme}
        toggle={toggle}
        zIndex={""}
				scrollable={scrollable}
        onClosed={onClosed}>
        {toggle && <CloseModalBtn actionMethod={toggle} {...props} />}
        {children}
    </ReactstrapModal>
);

Modal.propTypes = {
    show: PropTypes.bool,
    size: PropTypes.string,
    toggle: PropTypes.func,
    onClosed: PropTypes.func,
    children: PropTypes.any.isRequired,
		scrollable: PropTypes.bool
};

export default connect(
    (state, ownProps) => ({
        theme: state.style.theme,
        kickedOut: state.auth.kickedOut,
        ...ownProps,
    }),
    null
)(Modal);
