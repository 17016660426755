import InsufficientFundsModal from "components/utils/InsufficientFundsModal/InsufficientFundsModal";
import ModalHeader from "components/utils/Modal/CustomModalHeader/CustomModalHeader";
import Modal from "components/utils/Modal/Modal";
import { ERROR, FINISHED, IDLE, LOADING } from "config/enums";
import React, { useState } from "react";
import SavingsGoalSuccessModal from "./SavingsGoalSuccessModal";
import { Formik } from "formik";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { ReactSVG } from "react-svg";
import MintynCircle from "assets/img/Mintyn_Circle.svg";
import CustomCurrencyInput from "components/utils/CustomCurrencyInput/CustomCurrencyInput";
import { getErrorMessage, parseAmount } from "components/utils/helpers";
import { SmallLoader } from "components/utils/SmallLoader";
import { API } from "lib/api";
import {
	goalFundingTrancation,
	goalFundingWithRefernce,
	verifyReferenceFunding,
} from "lib/api/apiRoutesConfig/services/SavingsMS/savingsGoals/subRoutes";
import { ToastsStore } from "react-toasts";
import DebitCardCircle from "assets/img/debit_card_circle.svg";
import { FLUTTERWAVE } from "components/dashboard/enums";
import config from "../../../../../../../config";
import CustomFlutterWaveBtn from "components/dashboard/components/savings/Common/FundGoals/CardFlutterwaveFunding/CustomFlutterWaveBtn";
import imageFiles from "components/common/imageFiles/imageFiles";

const formValidationSchema = (initiatePayment) =>
	yup.object().shape({
		amount: yup.number().when([], {
			is: () => initiatePayment === true,
			then: yup
				.number()
				.required("Funding amount is required")
				.min(500, "Minimum amount is ₦500"),
			otherwise: yup
				.number()
				.required("Funding amount is required")
				.min(100, "Minimum amount is ₦100"),
		}),
	});

const MIN_STEP = 0;
const MAX_STEP = 1;

const {
	paymentGateway: { PUBLIC_KEY },
} = config;

export default function SavingGoalDeposit({
	show,
	onClose,
	refetch,
	savingsGoal,
	openFundingModal,
}) {
	const [currentState, setCurrentState] = useState(IDLE);
	const [successMsg, setSuccessMsg] = useState("");
	const [currentStep, setCurrentStep] = useState(MIN_STEP);
	const [errorMsg, setErrorMsg] = useState("");
	const [successResponse, setSuccessResponse] = useState(null);
	const currentUser = useSelector((state) => state.auth.currentUser);

	const { goalId } = savingsGoal || {};

	const goToNextStep = () => {
		setCurrentStep((prev) => Math.min(prev + 1, MAX_STEP));
	};

	const [fwConfig, setFwConfig] = useState({
		public_key: PUBLIC_KEY,
		tx_ref: "",
		amount: 0, // 100
		currency: "NGN",
		payment_options: "card,mobilemoney,ussd",
		customer: {
			email: currentUser?.email,
			firstname: currentUser?.firstName,
			lastname: currentUser?.lastName,
		},
		customizations: {
			title: "Mintyn Digital Bank",
			description: "Savings Goal Funding",
			logo: imageFiles.icons.mintIcon,
		},
	});

	const handleClose = () => {
		setCurrentState(IDLE);
		setCurrentStep(MIN_STEP);
		setSuccessResponse(null);
		onClose();
		setFwConfig((prev) => ({
			fwConfig: {
				...prev.fwConfig,
				tx_ref: null,
			},
		}));
		refetch();
	};

	const renderBasedOnState = () => {
		switch (currentState) {
			case FINISHED:
				return (
					<SavingsGoalSuccessModal
						message={successMsg}
						onClose={handleClose}
						onClick={handleClose}
						response={successResponse}
						buttonLabel="View Plan"
					/>
				);

			case ERROR:
				if (
					errorMsg.includes(
						"Sorry, you do not have sufficient balance in your account for this request."
					)
				) {
					return (
						<InsufficientFundsModal
							message={errorMsg}
							onClick={() => {
								onClose();
								setErrorMsg("");
								setCurrentState(IDLE);
								setCurrentStep(MIN_STEP);
								setSuccessResponse(null);
								openFundingModal();
							}}
						/>
					);
				}

				return null;

			default:
				return (
					<FundSavings
						goalId={goalId}
						currentState={currentState}
						currentStep={currentStep}
						setSuccessMsg={(message) => setSuccessMsg(message)}
						setCurrentState={(state) => setCurrentState(state)}
						goToNextStep={goToNextStep}
						fwConfig={fwConfig}
						setFwConfig={(values) => setFwConfig(values)}
						setErrorMsg={(message) => setErrorMsg(message)}
						handleClose={handleClose}
						setSuccessResponse={(response) =>
							setSuccessResponse(response)
						}
					/>
				);
		}
	};

	return (
		<Modal show={show}>
			{currentState !== FINISHED &&
				!errorMsg.includes(
					"Sorry, you do not have sufficient balance in your account for this request."
				) && (
					<ModalHeader
						heading={
							currentStep === MIN_STEP
								? "Fund Goal"
								: "Fund Amount"
						}
						className="p-0"
						headerClassName="setup-modals-header px-sm-5 px-3 py-3"
						dismissable={() => {
							onClose();
							setCurrentStep(MIN_STEP);
							setErrorMsg("");
						}}
					/>
				)}
			<>{renderBasedOnState()}</>
		</Modal>
	);
}

const FundSavings = ({
	goalId,
	currentState,
	currentStep,
	setSuccessMsg,
	setCurrentState,
	goToNextStep,
	setErrorMsg,
	setSuccessResponse,
	setFwConfig,
	fwConfig,
	handleClose,
}) => {
	const { dashboardResponse } = useSelector((state) => ({
		dashboardResponse: state.dashboard.dashboardResponse,
	}));

	const { accountId } = dashboardResponse?.bankAccounts
		? dashboardResponse.bankAccounts[0]
		: {};

	const [debitAccountId, setDebitAccountId] = useState("");

	const initialFormValues = {
		amount: "",
	};

	// for payment via debit card
	const [initiatePayment, setInitiatePayment] = useState(false);

	const handleFunding = async (values) => {
		setCurrentState(LOADING);
		setErrorMsg("");
		try {
			const response = await API.post(goalFundingTrancation, {
				...values,
				debitAccountId,
				goalId,
			});
			if (response.status) {
				setCurrentState(FINISHED);
				setSuccessMsg(response.data.message);
				setSuccessResponse(response.data.data);
			}
		} catch (err) {
			setErrorMsg(getErrorMessage(err));
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
			if (
				getErrorMessage(err).includes(
					"Sorry, you do not have sufficient balance in your account for this request."
				)
			) {
				setCurrentState(ERROR);
			} else {
				setCurrentState(IDLE);
			}
		}
	};

	// for payment via debit card
	// generate payment reference
	const generateTransactionRef = async ({ amount }) => {
		setCurrentState(LOADING);
		setErrorMsg("");
		try {
			let res = await API.post(goalFundingWithRefernce(goalId), {
				amount,
				paymentGateway: FLUTTERWAVE,
			});
			if (res.data) {
				const { amount, transactionReference } = res.data.data;
				setFwConfig({
					...fwConfig,
					amount: amount / 100, // kobo
					tx_ref: transactionReference,
				});
				document.querySelector("#flutterwave-btn button").click();
			}
		} catch (err) {
			setErrorMsg(getErrorMessage(err));
			ToastsStore.error(getErrorMessage(err), 6000, "setup-toast");
		}
	};

	// verify payment
	const handleVerifyPayment = async () => {
		const { tx_ref } = fwConfig;
		try {
			const res = await API.get(verifyReferenceFunding(tx_ref));
			if (res.data) {
				setCurrentState(FINISHED);
				setSuccessMsg(res.data.message);
				setSuccessResponse(res.data.data);
			}
		} catch (err) {}
	};

	const renderBasedOnStep = () => {
		switch (currentStep) {
			case 0:
				return (
					<>
						<FundingOption
							icon={MintynCircle}
							selected={debitAccountId === accountId}
							title="Your Mintyn Account"
							text="Fund your account directly from your Mintyn Account"
							cls="mb-0"
							onClick={() => {
								setInitiatePayment(false);
								setDebitAccountId(accountId);
							}}
						/>
						<FundingOption
							icon={DebitCardCircle}
							selected={initiatePayment}
							title="Your Debit Card"
							text="You can fund your account with any of  debit cards"
							cls="mt-3"
							onClick={() => {
								setDebitAccountId("");
								setInitiatePayment(true);
							}}
						/>
						<button
							className="btn create-acc mt-3"
							disabled={
								debitAccountId !== accountId && !initiatePayment
							}
							onClick={goToNextStep}
							style={{ height: "50px" }}
						>
							Continue
						</button>
					</>
				);

			case 1:
				return (
					<>
						<Formik
							initialValues={initialFormValues}
							validationSchema={formValidationSchema(
								initiatePayment
							)}
							onSubmit={(values) => {
								if (initiatePayment) {
									generateTransactionRef(values);
									return;
								}
								handleFunding(values);
							}}
						>
							{({
								values,
								errors,
								touched,
								handleSubmit,
								setFieldValue,
								handleBlur,
							}) => {
								return (
									<form
										onSubmit={(e) => {
											e.preventDefault();
											handleSubmit();
										}}
									>
										<div className="mb-4">
											<CustomCurrencyInput
												className="es-input"
												name="amount"
												id="amount"
												value={values.amount}
												placeholder="Enter Amount"
												onBlur={handleBlur}
												error={
													touched.amount &&
													errors.amount
												}
												onChange={(value) => {
													setFieldValue(
														"amount",
														parseAmount(value)
													);
												}}
											/>
										</div>

										<button
											className="btn create-acc mt-3"
											disabled={currentState === LOADING}
											type="submit"
										>
											{currentState === LOADING ? (
												<SmallLoader />
											) : (
												"Continue"
											)}
										</button>
									</form>
								);
							}}
						</Formik>

						<CustomFlutterWaveBtn
							fwConfig={fwConfig}
							handleCallback={handleVerifyPayment}
							handleClose={handleClose}
						/>
					</>
				);

			default:
				return null;
		}
	};

	return (
		<div className="es-automatic-deposit px-sm-5 px-3 funding">
			<h2>Fund your Savings goal with</h2>
			{renderBasedOnStep()}
		</div>
	);
};

const FundingOption = ({ selected, onClick, icon, title, text, cls = "" }) => {
	return (
		<div
			className={`es-automatic-deposit__debit-account ${
				selected ? "selected" : ""
			} ${cls}`}
			onClick={onClick}
		>
			<ReactSVG src={icon} />
			<div className="ml-4">
				<h4>{title}</h4>
				<p>{text}</p>
			</div>
		</div>
	);
};
