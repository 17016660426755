import VerifyFPPhoneNumber from "../../ForgotPassword/VerifyFPPhoneNumber/VerifyFPPhoneNumber";
import VerifyFPSecurityAnswer from "../../ForgotPassword/VerifyFPSecurityAnswer/VerifyFPSecurityAnswer";
import CreateFPNewPassword from "../../ForgotPassword/CreateFPNewPassword/CreateFPNewPassword";

export default [
	{
		title: "Verify FP Phone Number",
		path: "/:sessionId/verify-phone",
		component: VerifyFPPhoneNumber,
	},
	{
		title: "Verify FP Security Answers",
		path: "/:sessionId/answer-security-questions",
		component: VerifyFPSecurityAnswer,
	},
	{
		title: "Create FP New Password",
		path: "/:sessionId/new-password",
		component: CreateFPNewPassword,
	},
];
