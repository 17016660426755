import forgotPassword, { forgotPasswordV2 } from "../";

export const {
	sendFPVerificationCode,
	resendFPVerificationCode,
	verifyFPActivationCode,
	verifyFPSecurityQuestions,
	createFPNewPass,
} = {
	sendFPVerificationCode: `${forgotPasswordV2}/send-verification-code`,
	resendFPVerificationCode: (sessionId) =>
		`${forgotPassword}/${sessionId}/resend-verification-code`,
	verifyFPActivationCode: (sessionId) =>
		`${forgotPassword}/${sessionId}/verify-activation-code`,
	verifyFPSecurityQuestions: (sessionId) =>
		`${forgotPassword}/${sessionId}/verify-security-question`,
	createFPNewPass: (sessionId) =>
		`${forgotPassword}/${sessionId}/create-password`,
};
