import React from "react";
import FailedImage from "../../../../../../utils/FailedImage";
import "./FailedTranscation.scss";

const FailedTranscation = ({ message, handleTryAgain }) => {
	return (
		<div className="py-5">
			<FailedImage />
			<div className="smile mt-3">
				<p className="px-3">{message}</p>
			</div>
			<div className="row justify-content-center">
				<div className="col-md-5">
					<a
						className="btn create-acc mt-3 mb-3"
						href="#!"
						onClick={handleTryAgain}
						data-dismiss="modal"
					>
						Try Again
					</a>
				</div>
			</div>
		</div>
	);
};

export default FailedTranscation;
