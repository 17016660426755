import React from "react";
import { useHistory } from "react-router-dom";
import FadeIn from "../../../effects/FadeIn";
import "./BusinessDocumentation.scss";
import CaretLeft from "../../../../../../assets/img/caret_left_gold.svg";
import SetupCard from "../../components/SetupCard/SetupCard";
import { useSelector } from "react-redux";
import GovtIssuedIdModal from "../../components/modals/GovtIssuedIdModal";
import { useState } from "react";
import CACModal from "../../components/modals/CACModal";
import EnquiryModal from "../../components/modals/EnquiryModal";
import TINModal from "../../components/modals/TINModal";
import TINApplicationModal from "../../components/modals/TINApplicationModal";
import AccessControl from "components/auth/AccessControl/AccessControl";
import MOAModal from "../../components/modals/MOAModal";
import permissions from "components/auth/permissions";
import CITModal from "../../components/modals/CITModal";

export default function BusinessDocumentation() {
	const history = useHistory();
	const dashboardResponse = useSelector(
		(state) => state.dashboard?.dashboardResponse
	);
	const [showIdModal, setShowIdModal] = useState(false);
	const [showCACModal, setShowCACModal] = useState(false);
	const [showEnquiryModal, setShowEnquiryModal] = useState(false);
	const [showTINModal, setShowTinModal] = useState(false);
	const [showTINApplicationModal, setShowTINApplicationModal] =
		useState(false);
	const [showMOAModal, setShowMOAModal] = useState(false);
	const [showCITModal, setShowCITModal] = useState(false);

	const enquiryModalConfig = () => {
		return {
			iconType: "TIN",
			title: "Already have your TIN?",
			message:
				"If you already have your TIN you can proceed with Yes. If you do not have a TIN, you can choose to apply for one below.",
			positiveLabel: "Yes, I have my TIN",
			negativeLabel: "No, I want to apply",
			onPositive: () => {
				setShowEnquiryModal(false);
				setShowTinModal(true);
			},
			onNegative: () => {
				setShowEnquiryModal(false);
				setShowTINApplicationModal(true);
			},
		};
	};

	const refreshPage = () => {
		// window.location.reload();
		history.go(0);
	};

	const cacVerified =
		dashboardResponse?.setUpDetail?.businessSetUp?.cacVerified;

	const govtIdVerified =
		dashboardResponse?.setUpDetail?.businessSetUp?.govtIdVerified;

	const tinVerified =
		dashboardResponse?.setUpDetail?.businessSetUp?.tinVerified;

	const certificateOfIncorporatedTrusteeVerified =
		dashboardResponse?.setUpDetail?.businessSetUp
			?.certificateOfIncorporatedTrusteeVerified;

	const memorandumOfAssociationDocument =
		dashboardResponse?.setUpDetail?.businessSetUp
			?.memorandumOfAssociationDocument;

	return (
		<FadeIn>
			<section className="business-documentation-page pb-5">
				<div className="d-flex align-items-center mb-5">
					<button
						className="back-btn d-flex align-items-center"
						onClick={() => history.goBack()}
					>
						<img src={CaretLeft} alt="" />
						<span>Back</span>
					</button>
				</div>

				<p className="bdp-step-count">STEP 1</p>

				<h1>Business Documentation</h1>
				<p>
					Provide your business registration documents to help us
					verify your business.
				</p>

				<div className="row mt-5">
					<SetupCard
						title="Government Issued ID Card"
						description="Upload a valid means of identification."
						status={govtIdVerified?.status}
						onClick={() => setShowIdModal(true)}
					/>
					<SetupCard
						title="CAC Document"
						description="Upload a copy of your CAC document."
						status={cacVerified?.status}
						onClick={() => setShowCACModal(true)}
					/>
					<SetupCard
						title="Tax Identification Number (Optional)"
						description="Complete your TIN Verification."
						status={tinVerified?.status}
						onClick={() => setShowEnquiryModal(true)}
					/>
					<AccessControl
						allowedPermissions={[permissions.IS_ENTERPRISE]}
					>
						<SetupCard
							title="Board of Directors/Memart"
							description="Upload a copy of your Board of Director/Memart document."
							status={memorandumOfAssociationDocument?.status}
							onClick={() => setShowMOAModal(true)}
						/>
					</AccessControl>
					<AccessControl
						allowedPermissions={[
							permissions.IS_INCORPORATED_TRUSTEE,
						]}
					>
						<SetupCard
							title="Constitution of Incorporated Trustees"
							description="Upload a copy of your Constitution of Incorporated Trustees document."
							status={
								certificateOfIncorporatedTrusteeVerified?.status
							}
							onClick={() => setShowCITModal(true)}
						/>
					</AccessControl>
				</div>
			</section>

			{/* Modals */}
			<GovtIssuedIdModal
				show={showIdModal}
				documentationType="BUSINESS_DOCUMENTATION"
				onClose={() => setShowIdModal(false)}
				onSuccess={() => {
					setShowIdModal(false);
					refreshPage();
				}}
			/>

			<CACModal
				show={showCACModal}
				onClose={() => setShowCACModal(false)}
				onSuccess={() => {
					setShowCACModal(false);
					refreshPage();
				}}
			/>

			<TINModal
				show={showTINModal}
				onClose={() => setShowTinModal(false)}
				onSuccess={() => {
					setShowTinModal(false);
					refreshPage();
				}}
			/>

			<TINApplicationModal
				show={showTINApplicationModal}
				onClose={() => setShowTINApplicationModal(false)}
			/>

			<EnquiryModal
				show={showEnquiryModal}
				onClose={() => setShowEnquiryModal(false)}
				{...enquiryModalConfig()}
			/>

			<MOAModal
				show={showMOAModal}
				onClose={() => setShowMOAModal(false)}
				onSuccess={() => {
					setShowMOAModal(false);
					refreshPage();
				}}
			/>

			<CITModal
				show={showCITModal}
				onClose={() => setShowCITModal(false)}
				onSuccess={() => {
					setShowCITModal(false);
					refreshPage();
				}}
			/>
		</FadeIn>
	);
}
