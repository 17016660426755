import FadeIn from "components/dashboard/components/effects/FadeIn";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import CaretLeft from "assets/img/caret_left_gold.svg";
import { useHistory } from "react-router-dom";
import GroomPanel from "components/dashboard/utils/GroomPanel/GroomPanel";
import "../../SpendAndSave.scss";
import { Formik } from "formik";
import * as yup from "yup";
import { ERROR, FINISHED, IDLE, LOADING } from "config/enums";
import FormInputGroup from "components/utils/FormInputGroup/FormInputGroup";
import { getErrorMessage, parseAmount } from "components/utils/helpers";
import CustomRadioButton from "components/utils/CustomRadioButton/CustomRadioButton";
import CustomLargeBadge from "components/utils/CustomLargeBadge/CustomLargeBadge";
import FormDatePickerGroup from "components/utils/FormDatePickerGroup/FormDatePickerGroup";
import { addDays, differenceInCalendarDays } from "date-fns";
import { SmallLoader } from "components/utils/SmallLoader";
import { API } from "lib/api";
import { setupSpendAndSave } from "lib/api/apiRoutesConfig/services/SavingsMS/savingsGoals/subRoutes";
import { ToastsStore } from "react-toasts";
import Modal from "components/utils/Modal/Modal";
import SASSuccessModal from "../modals/SASSuccessModal";

const formValidationSchema = yup.object().shape({
	duration: yup.number().when("savingsLocked", {
		is: (value) => value === true,
		then: yup
			.number()
			.required("Select a duration")
			.min(30, "Minimum duration is 30 days"),
		otherwise: yup.number(),
	}),
	savingsLocked: yup.boolean().default(true).required("Select an option"),
	transactionPercentage: yup
		.number()
		.required("Enter a percentage value")
		.min(1, "Minimum percentage is 1%"),
	customDate: yup.date(),
});

export default function CreateSpendAndSave() {
	const history = useHistory();
	const [showDatePicker, setShowDatePicker] = useState(false);
	const [currentState, setCurrentState] = useState(IDLE);
	const [successMsg, setSuccessMsg] = useState("");

	const initialFormValues = {
		duration: "",
		savingsLocked: true,
		transactionPercentage: "",
		customDate: undefined,
	};

	const resetCustomDate = (setFieldValue, setFieldTouched) => {
		setFieldValue("customDate", undefined);
		setFieldTouched("duration", false);
		setShowDatePicker(false);
	};

	const handleSetup = async (values) => {
		setCurrentState(LOADING);
		try {
			const res = await API.post(setupSpendAndSave, {
				...values,
				customDate: undefined,
			});
			if (res.status) {
				setCurrentState(FINISHED);
				setSuccessMsg(res.data.message);
			}
		} catch (err) {
			setCurrentState(ERROR);
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		}
	};

	return (
		<>
			<Helmet>
				<title>
					{process.env.REACT_APP_NAME} - Create Spend and Save
				</title>
			</Helmet>

			<FadeIn>
				<section className="spend-and-save-page create-sas-page pb-5">
					<div className="d-flex align-items-center mb-5 justify-content-between">
						<button
							className="back-btn d-flex align-items-center"
							onClick={() => history.goBack()}
						>
							<img src={CaretLeft} alt="" />
							<span>Back</span>
						</button>
					</div>

					<h1 className="page-title-text">
						Create Spend and Save Goal
					</h1>
					<p className="page-lead-text">
						Please select your Saving Plan preferences and start
						saving
					</p>

					<GroomPanel cls="sas-groom">
						<Formik
							initialValues={initialFormValues}
							validationSchema={formValidationSchema}
							onSubmit={(values) => handleSetup(values)}
						>
							{({
								values,
								errors,
								touched,
								handleSubmit,
								setFieldValue,
								handleChange,
								handleBlur,
								resetForm,
								setFieldTouched,
							}) => {
								return (
									<form
										className="sas-groom__form"
										onSubmit={(e) => {
											e.preventDefault();
											handleSubmit();
										}}
									>
										<h2>Choose Spend and Save Target</h2>
										<p>
											Save this percentage on all
											transactions i.e fund transfer &
											bill payments.
										</p>

										<div className="mb-4">
											<FormInputGroup
												id="transactionPercentage"
												label="Enter percentage of transaction to be saved"
												name="transactionPercentage"
												placeholder="%"
												className="sas-input percentage"
												onBlur={handleBlur}
												value={
													values.transactionPercentage
												}
												onChange={(e) => {
													const value =
														e.target.value;
													setFieldValue(
														"transactionPercentage",
														parseAmount(value)
													);
												}}
												error={
													touched.transactionPercentage &&
													errors.transactionPercentage
												}
											/>
										</div>

										<div style={{ marginBottom: "50px" }}>
											<label>
												Would you like to safe lock your
												savings and earn interest?
											</label>
											<div className="d-flex align-items-center mt-2">
												<CustomRadioButton
													checked={
														values.savingsLocked ===
														true
													}
													name="savingsLocked"
													onChange={() =>
														setFieldValue(
															"savingsLocked",
															true
														)
													}
													label="Yes"
												/>
												<CustomRadioButton
													cls="ml-5"
													checked={
														values.savingsLocked ===
														false
													}
													name="savingsLocked"
													onChange={() => {
														setFieldValue(
															"savingsLocked",
															false
														);
														resetForm({
															values: {
																...values,
																savingsLocked: false,
																duration: "",
																transactionPercentage:
																	values.transactionPercentage,
																customDate:
																	undefined,
															},
														});
														setShowDatePicker(
															false
														);
													}}
													label="No"
												/>
											</div>
											{touched.savingsLocked &&
												errors.savingsLocked && (
													<span className="error-msg">
														{errors.savingsLocked}
													</span>
												)}
										</div>

										{values.savingsLocked && (
											<>
												<div className="mb-3">
													<label>
														Savings duration
													</label>
													<div className="sas-frequency">
														<CustomLargeBadge
															title="30 days"
															isSelected={
																values.duration ===
																30
															}
															onClick={() => {
																setFieldTouched(
																	"customDate",
																	false
																);
																setFieldValue(
																	"duration",
																	30
																);
																resetCustomDate(
																	setFieldValue,
																	setFieldTouched
																);
															}}
														/>
														<CustomLargeBadge
															title="60 days"
															isSelected={
																values.duration ===
																60
															}
															onClick={() => {
																setFieldTouched(
																	"customDate",
																	false
																);
																setFieldValue(
																	"duration",
																	60
																);
																resetCustomDate(
																	setFieldValue,
																	setFieldTouched
																);
															}}
														/>
														<CustomLargeBadge
															title="90 days"
															isSelected={
																values.duration ===
																90
															}
															onClick={() => {
																setFieldTouched(
																	"customDate",
																	false
																);
																setFieldValue(
																	"duration",
																	90
																);
																resetCustomDate(
																	setFieldValue,
																	setFieldTouched
																);
															}}
														/>
													</div>
													{touched.duration &&
														errors.duration && (
															<span className="error-msg">
																{
																	errors.duration
																}
															</span>
														)}
												</div>

												<div
													style={{
														marginBottom: "48px",
													}}
												>
													{!showDatePicker ? (
														<CustomLargeBadge
															title="Pick my own date"
															cls="w-100"
															onClick={() =>
																setShowDatePicker(
																	true
																)
															}
														/>
													) : (
														<FormDatePickerGroup
															isClearable={false}
															placeholder="Select date"
															selected={
																values.customDate
															}
															onBlur={handleBlur}
															minDate={addDays(
																new Date(),
																30
															)}
															name="customDate"
															onChange={(
																date
															) => {
																setFieldValue(
																	"customDate",
																	date
																);
																setFieldValue(
																	"duration",
																	differenceInCalendarDays(
																		date,
																		new Date()
																	)
																);
															}}
															error={
																touched.duration &&
																errors.duration
															}
														/>
													)}
												</div>
											</>
										)}

										<button
											className="btn create-acc mt-0"
											disabled={currentState === LOADING}
											type="submit"
										>
											{currentState === LOADING ? (
												<SmallLoader />
											) : (
												"Activate"
											)}
										</button>
									</form>
								);
							}}
						</Formik>
					</GroomPanel>
				</section>
			</FadeIn>

			{successMsg && (
				<SuccessModal show={!!successMsg} successMsg={successMsg} />
			)}
		</>
	);
}

// Success Modal
const SuccessModal = ({ show, successMsg }) => {
	const history = useHistory();
	return (
		<Modal show={show}>
			<SASSuccessModal
				message={successMsg}
				onClose={() => history.replace("/savings/spend-and-save/")}
			/>
		</Modal>
	);
};
