import React from "react";

const FailedTryAgain = ({
	errorMssg,
	paragraph,
	btnName = "Try Again",
	handleTryAgain,
	cls,
}) => {
	return (
		<div className="w-100">
			<h6 className="notready">{errorMssg}</h6>
			<div className="flex__center">
				{paragraph && (
					<p className="sub-heading text-center">{paragraph}</p>
				)}
			</div>
			<div className="row justify-content-center mt-3">
				<div className={`col-md-5 ${cls}`}>
					<button
						className="btn btn-mint-default"
						onClick={handleTryAgain}
					>
						{btnName}
					</button>
				</div>
			</div>
		</div>
	);
};

export default FailedTryAgain;
