import React, { useState, useEffect } from "react";
import { getOrdersAction } from "lib/api/apiRoutesConfig/services/tradeMS/individual/subRoutes"
import { API } from "lib/api"
import { ToastsStore } from "react-toasts";
import { getErrorMessage } from "components/utils/helpers"; 
import { PERSONAL_ORDER, } from "../../utils/constant";
import { OrderCard } from "../../utils/OrderCard"
import { useHistory } from "react-router-dom";
import { ERROR, FINISHED, IDLE, LOADING } from "config/enums";
import CustomPagination from "components/dashboard/utils/CustomPagination/CustomPagination";
import { MintInfinite } from "components/dashboard/utils/ShineLoader/ShineLoader";
import FailedTryAgain from "components/dashboard/utils/FailedTryAgain/FailedTryAgain";


const PersonalOrders = () => {
  const [errorMsg, setErrorMsg] = useState("");
  const [orders, setOrders] = useState([] || null)
  const [page, setPage] = useState(0);
	const [size] = useState(10);
	const [currentStatus, setCurrentStatus] = useState(IDLE);

  const history = useHistory()
  
  const fetchOrders = async (value) => {
    setCurrentStatus(LOADING);
		try {
      const res = await API.get(getOrdersAction(), {
        params: {
            orderType:value,
						page,
						size
					},
      });
			const { status, data } = res || {};
      if (status === 200) {
        console.log('orders',data.data)
        setOrders(data.data);
        setCurrentStatus(FINISHED);
			}
		} catch (err) {
      ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
      setErrorMsg(getErrorMessage(err));
			setCurrentStatus(ERROR);
		} 
  };

  
  useEffect(() => {
			fetchOrders(PERSONAL_ORDER)
		// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [page,size]);

  const renderBasedOnStatus = () => {
		const { records, totalPages } = orders;

		switch (currentStatus) {
			case LOADING:
				return (
					<div className="d-flex w-100 justify-content-center align-items-center order-loading">
						<MintInfinite relative />
					</div>
				);

			case ERROR:
				return (
					<div className="d-flex w-100 justify-content-center align-items-center order-loading">
						<FailedTryAgain
							errorMssg={errorMsg}
							handleTryAgain={() => fetchOrders("ALL")}
						/>
					</div>
				);

			case FINISHED:
				if (records?.length <= 0) {
					return <h6 className="notready">No Order</h6>;
				}

				return (
					<>
						{records?.map((item, index) => (
							<OrderCard
								key={index}
								list={item}
								onClick={() =>
									history.push("/market-place/cart-view", {
										item,
									})
								}
							/>
						))}

						<CustomPagination
							currentPage={page}
							pageSize={totalPages}
							goToPage={(page) => setPage(page)}
						/>
					</>
				);

			default:
				return null;
		}
	};
  
 return <>{renderBasedOnStatus()}</>;
}
export default PersonalOrders