import React, { Component, useEffect } from "react";
import { PepperStroke } from "./stroke";
import { useSelector } from "react-redux";
import { nightTheme } from "SwitchModes/themeModes";

class SuccessImage extends Component {
	componentDidMount = () => {
		PepperStroke("circle");
		PepperStroke("line_one");
		PepperStroke("line_two");
	};

	render = () => {
		return (
			<div className="d-flex justify-content-center w-100">
				<svg
					width="56"
					height="56"
					viewBox="0 0 56 56"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						id="circle"
						fillRule="evenodd"
						clipRule="evenodd"
						d="M2.33331 28C2.33331 13.8247 13.8247 2.33337 28 2.33337C42.1753 2.33337 53.6666 13.8247 53.6666 28C53.6666 42.1753 42.1753 53.6667 28 53.6667C13.8247 53.6667 2.33331 42.1753 2.33331 28Z"
						stroke="#d1a23c"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<line
						id="line_one"
						x1="16.1184"
						y1="27.1115"
						x2="23.1115"
						y2="34.8816"
						stroke="#d1a23c"
						strokeWidth="3"
						strokeLinecap="round"
					/>
					<line
						id="line_two"
						x1="23"
						y1="34.8787"
						x2="38.8787"
						y2="19"
						stroke="#d1a23c"
						strokeWidth="3"
						strokeLinecap="round"
					/>
				</svg>
			</div>
		);
	};
}

export default SuccessImage;

export const SuccessImageFunction = () => {
	const style = useSelector((state) => state.style);

	const isDarkMode = style.theme === nightTheme;

	useEffect(() => {
		PepperStroke("circle");
		PepperStroke("line_one");
		PepperStroke("line_two");
	}, []);

	return (
		<>
			<div className="d-flex justify-content-center w-100">
				<svg
					width="56"
					height="56"
					viewBox="0 0 56 56"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						id="circle"
						fillRule="evenodd"
						clipRule="evenodd"
						d="M2.33331 28C2.33331 13.8247 13.8247 2.33337 28 2.33337C42.1753 2.33337 53.6666 13.8247 53.6666 28C53.6666 42.1753 42.1753 53.6667 28 53.6667C13.8247 53.6667 2.33331 42.1753 2.33331 28Z"
						stroke={`${isDarkMode ? "#d1a23c" : "#000"}`}
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<line
						id="line_one"
						x1="16.1184"
						y1="27.1115"
						x2="23.1115"
						y2="34.8816"
						stroke={`${isDarkMode ? "#d1a23c" : "#000"}`}
						strokeWidth="3"
						strokeLinecap="round"
					/>
					<line
						id="line_two"
						x1="23"
						y1="34.8787"
						x2="38.8787"
						y2="19"
						stroke={`${isDarkMode ? "#d1a23c" : "#000"}`}
						strokeWidth="3"
						strokeLinecap="round"
					/>
				</svg>
			</div>
		</>
	);
};

export const CancelImageFunction = () => {
	const style = useSelector((state) => state.style);

	const isDarkMode = style.theme === nightTheme;

	useEffect(() => {
		PepperStroke("circle");
		PepperStroke("line_one");
		PepperStroke("line_two");
	}, []);

	return (
		<>
			<div className="d-flex justify-content-center w-100">
				<svg
					width="65"
					height="64"
					viewBox="0 0 65 64"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M32.5002 58.6666C47.1668 58.6666 59.1668 46.6666 59.1668 31.9999C59.1668 17.3333 47.1668 5.33325 32.5002 5.33325C17.8335 5.33325 5.8335 17.3333 5.8335 31.9999C5.8335 46.6666 17.8335 58.6666 32.5002 58.6666Z"
						fill="#D1A23C"
						fill-opacity="0.1"
						stroke={`${isDarkMode ? "#d1a23c" : "#000"}`}
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<g opacity="0.4">
						<path
							d="M24.9531 39.5465L40.0465 24.4531"
							stroke={`${isDarkMode ? "#d1a23c" : "#000"}`}
							stroke-width="1.5"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
						<path
							d="M40.0465 39.5465L24.9531 24.4531"
							stroke={`${isDarkMode ? "#d1a23c" : "#000"}`}
							stroke-width="1.5"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</g>
				</svg>
			</div>
		</>
	);
};
