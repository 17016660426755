/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Modal from "components/utils/Modal/Modal";
import { useHistory } from "react-router-dom";

const Requirements = ({ show, onClose }) => {
	return (
		<>
			<Modal show={show} scrollable>
				<RequirementsContent onClose={onClose} />
			</Modal>
		</>
	);
};
export default Requirements;

const RequirementsContent = ({ onClose }) => {
	const history = useHistory();
	return (
		<div className="requirements-modal">
			<div className=" content w-100">
				<div className="modal-top-content">
					<h3>Incorporated Trustees</h3>
					<h4>
						These are the required details to process an incoporated
						trustees registration
					</h4>
				</div>
				<div>
					<h4>Trustee details</h4>
					<h4>Trustee documents</h4>
					<div>
						<li>Constitution of Organization</li>
						<li>
							Signed copies of the minutes of the meeting
							appointing the trustees showing the people present
							together with the votes scored.{" "}
						</li>
						<li>The impression of the common seal</li>
						<li>The Letterhead of the proposed orgonization</li>
					</div>
					<h4>Trustees</h4>
					<h4>Trustees details</h4>
					<h4>Secretary information</h4>
					<div></div>
				</div>
			</div>
			<p className="tip">
				Please note that for completion of the application, we would
				make a pubication on the national newspaper on your behalf. This
				is in compliance with s.825-827 of Sec. S.823 of companies and
				Allied Matters Act, 2020 (CAMA)
			</p>
			<div className="reg-business-form-modal w-100">
				<div>
					<button
						onClick={() =>
							history.push(
								"/register-business/incorporated-trustee"
							)
						}
						className="btn btn-mint-default"
						type="button"
					>
						Continue
					</button>
				</div>
				<div className="text-center">
					<button
						onClick={() => onClose()}
						className="btn btn-mint-default cancel"
						type="button"
					>
						Cancel
					</button>
				</div>
			</div>
		</div>
	);
};
