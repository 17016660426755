import React from "react";
import Helmet from "react-helmet";
import PreAuthNav from "../../auth/PreAuthNav";
import Logo from "assets/img/new-logo.png";
import SignUp from "../../auth/SignUp/SignUp";
import { Link } from "react-router-dom";

const RegistrationView = () => {
	return (
		<>
			<Helmet>
				<title>{process.env.REACT_APP_NAME} - Open Account</title>
			</Helmet>
			<div className="auth-container">
				<div className="biz-reg-link">
					<Link className="btn-primary" to="/register-business">
						Register your business with CAC
					</Link>
				</div>
				<div className="row justify-content-center">
					<div className="col-xl-4 col-lg-6 col-md-6 col-sm-7 col-10">
						<div className="mintyn-logo">
							<a href="https://mintyn.com/">
								<img
									alt="Mintyn Bank"
									width={500}
									height={500}
									src={Logo}
								/>
							</a>
						</div>
						<div>
							<PreAuthNav />
							<div
								className="auth-tab-content mt-4"
								id="myTabContent"
							>
								<SignUp />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default RegistrationView;
