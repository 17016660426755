import React, { Component } from "react";
import FadeIn from "../../../dashboard/components/effects/FadeIn";
import ReactHelmet from "../../../utils/ReactHelmet/ReactHelmet";
import { getSecurityQuestions } from "../../../../lib/api/apiRoutesConfig/services/AccountMS/common/subRoutes";
import { API } from "../../../../lib/api";
import { getErrorMessage } from "../../../utils/helpers";
import Alert from "../../../utils/Alert";
import { SmallLoader } from "../../../utils/SmallLoader";
import { verifyFPSecurityQuestions } from "../../../../lib/api/apiRoutesConfig/services/AccountMS/forgot-password/subRoutes";
import ValidateForm from "../../../utils/Validator";
import Logo from "assets/img/new-logo.png";
import DynamicSelectV2 from "components/utils/DynamicSelect/DynamicSelectV2";
import FormInputGroupV2 from "components/utils/FormInputGroup/FormInputGroupV2";

class VerifyFPSecurityAnswer extends Component {
	_mounted = false;

	state = {
		securityQuestions: [],
		alert: {
			alert_newkey: 1,
		},
		errors: {},
		request: {
			questionCode: "",
			answer: "",
		},
	};

	// componentDidMount = () => (this._mounted = true);
	componentDidMount = () => {
		this._mounted = true;
		const { location } = this.props;
		const { userSecurityQuestion } = location.state || {};
		this.setState((prevState) => ({
			...prevState,
			request: {
				...prevState.request,
				questionCode: userSecurityQuestion[0]?.value || "",
			},
		}));
	};
	componentWillUnmount = () => (this._mounted = false);

	handleSelect = ({ value }, { name }) => {
		this.setState((prevState) => ({
			...prevState,
			request: {
				...prevState.request,
				[name]: value,
			},
			errors: {
				...prevState.errors,
				[name]: false,
			},
		}));
	};

	handleInput = (e) => {
		const { name, value } = e.target;
		this.setState((prevState) => ({
			...prevState,
			request: {
				...prevState.request,
				[name]: value,
			},
			errors: {
				...prevState.errors,
				[name]: false,
			},
		}));
	};

	setError = (errMsg) => {
		this._mounted &&
			this.setState((prevState) => ({
				loading: false,
				alert: {
					alert_newkey: prevState.alert.alert_newkey + 1,
					alert_show: "show",
					alert_message: errMsg,
					alert_type: "danger",
				},
			}));
	};

	fetchSecurityQuestions = async (userSecurityQuestion) => {
		try {
			this._mounted &&
				this.setState({ isFetching: true, fetchQuesError: false });
			let res = await API.get(getSecurityQuestions);
			if (res.status === 200) {
				let questionList = [];
				res.data.data.forEach(({ code, question }) => {
					questionList.push({ label: question, value: code });
				});
				this._mounted && this.setState({ isFetching: false });
				if (userSecurityQuestion && userSecurityQuestion?.length > 0)
					return userSecurityQuestion;

				return questionList;
			}
		} catch (err) {
			this._mounted &&
				this.setState({
					fetchQuesError: getErrorMessage(err),
					isFetching: false,
				});
		}
	};

	processRequest = async () => {
		try {
			this.setState({
				loading: true,
			});
			const { sessionId } = this.props?.match?.params;
			let requestUrl = verifyFPSecurityQuestions(sessionId);
			const res = await API.post(requestUrl, { ...this.state.request });
			if (res.status === 200) {
				let newRoute = `/forgot-password/${sessionId}/new-password`;
				this.props.history.replace(newRoute);
			}
		} catch (err) {
			const error = getErrorMessage(err);
			this.setState((prevState) => ({
				request: {
					...prevState.request,
					answer: "",
				},
				loading: false,
			}));
			this.setError(error);
		}
	};

	handleSubmit = (e) => {
		e.preventDefault();
		const { request } = this.state;
		const { errors, formisValid } = ValidateForm(e, request) || {};
		formisValid ? this.processRequest() : this.setState({ errors });
	};

	render() {
		const { alert, isFetching, loading, fetchQuesError, errors } =
			this.state;
		const { alert_show, alert_message, alert_newkey, alert_type } = alert;
		const { location } = this.props;
		const { userSecurityQuestion } = location.state || {};

		return (
			<>
				<ReactHelmet title="Answer Security Questions" />
				<div className="auth-container">
					<div className="row h-100 justify-content-center align-items-center">
						<div className="col-xl-6 col-lg-8 col-md-9">
							<div className="">
								<FadeIn>
									<div className="row h-100 justify-content-center">
										<div className="col-xl-9 col-lg-9 col-md-9">
											<div className="verif-center px-4">
												<div className="mintyn-logo">
													<a href="https://mintyn.com/">
														<img
															alt="Mintyn Bank"
															width={500}
															height={500}
															src={Logo}
														/>
													</a>
												</div>
											</div>

											<div className="account-verification-panel">
												<h6 className="info-heading mb-5 text-center">
													Security Question
												</h6>
												<Alert
													key={alert_newkey}
													type={alert_type}
													message={alert_message}
													show={alert_show}
												/>
												<form
													className="login-form"
													onSubmit={this.handleSubmit}
												>
													<DynamicSelectV2
														label="Security Question"
														isDisabled={
															userSecurityQuestion &&
															userSecurityQuestion?.length >
																0
														}
														name="questionCode"
														error={
															errors[
																"questionCode"
															]
														}
														onChange={
															this.handleSelect
														}
														loadOptions={() =>
															this.fetchSecurityQuestions(
																userSecurityQuestion
															)
														}
														value={
															userSecurityQuestion[0] ||
															{}
														}
														isLoading={isFetching}
														serverError={
															fetchQuesError
														}
													/>
													<FormInputGroupV2
														label="Security Answer"
														error={
															!!errors["answer"]
														}
														name="answer"
														onChange={
															this.handleInput
														}
														autoComplete="off"
													/>
													<button
														className="btn-primary w-100"
														disabled={loading}
													>
														{loading ? (
															<SmallLoader />
														) : (
															"Continue"
														)}
													</button>
												</form>
											</div>
										</div>
									</div>
								</FadeIn>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default VerifyFPSecurityAnswer;
