import React from "react";
import InvestmentWrapper from "../InvestmentWrapper/InvestmentWrapper";
import ActiveInvestmentsContent from "./ActiveInvestmentsContent/ActiveInvestmentsContent";
import { investmentStatus } from "../../../../constants";

const ActiveInvestments = () => {
	return (
		<InvestmentWrapper
			investmentStatus={investmentStatus.ACTIVE}
			component={ActiveInvestmentsContent}
		/>
	);
};

export default ActiveInvestments;
