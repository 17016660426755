import ModalHeader from "components/utils/Modal/CustomModalHeader/CustomModalHeader";
import Modal from "components/utils/Modal/Modal";
import { ERROR, FINISHED, IDLE, LOADING } from "config/enums";
import React, { useState } from "react";
import MintynCircle from "assets/img/Mintyn_Circle.svg";
import { ReactSVG } from "react-svg";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";
import CustomCurrencyInput from "components/utils/CustomCurrencyInput/CustomCurrencyInput";
import { getErrorMessage, parseAmount } from "components/utils/helpers";
import { SmallLoader } from "components/utils/SmallLoader";
import { ToastsStore } from "react-toasts";
import { API } from "lib/api";
import { goalFundingTrancation } from "lib/api/apiRoutesConfig/services/SavingsMS/savingsGoals/subRoutes";
import ConfirmationSuccessModal from "./ConfirmationSuccessModal";
import InsufficientFundsModal from "components/utils/InsufficientFundsModal/InsufficientFundsModal";

const formValidationSchema = yup.object().shape({
	amount: yup
		.number()
		.required("Funding amount is required")
		.min(100, "Minimum amount is ₦100"),
});

const MIN_STEP = 0;
const MAX_STEP = 1;

export default function DepositModal({
	show,
	onClose,
	refetch,
	savingsGoal,
	openFundingModal,
}) {
	const [currentState, setCurrentState] = useState(IDLE);
	const [successMsg, setSuccessMsg] = useState("");
	const [currentStep, setCurrentStep] = useState(MIN_STEP);
	const [errorMsg, setErrorMsg] = useState("");
	const [successResponse, setSuccessResponse] = useState(null);

	const { goalId } = savingsGoal || {};

	const goToNextStep = () => {
		setCurrentStep((prev) => Math.min(prev + 1, MAX_STEP));
	};

	const handleClose = () => {
		setCurrentState(IDLE);
		setCurrentStep(MIN_STEP);
		setSuccessResponse(null);
		onClose();
		refetch();
	};

	const renderBasedOnState = () => {
		switch (currentState) {
			case FINISHED:
				return (
					<ConfirmationSuccessModal
						message={successMsg}
						onClose={handleClose}
						onClick={handleClose}
						response={successResponse}
					/>
				);

			case ERROR:
				if (
					errorMsg.includes(
						"Sorry, you do not have sufficient balance in your account for this request."
					)
				) {
					return (
						<InsufficientFundsModal
							message={errorMsg}
							onClick={() => {
								onClose();
								setErrorMsg("");
								setCurrentState(IDLE);
								setCurrentStep(MIN_STEP);
								setSuccessResponse(null);
								openFundingModal();
							}}
						/>
					);
				}

				return null;

			default:
				return (
					<FundSavings
						goalId={goalId}
						currentState={currentState}
						currentStep={currentStep}
						setSuccessMsg={(message) => setSuccessMsg(message)}
						setCurrentState={(state) => setCurrentState(state)}
						goToNextStep={goToNextStep}
						setErrorMsg={(message) => setErrorMsg(message)}
						setSuccessResponse={(response) =>
							setSuccessResponse(response)
						}
					/>
				);
		}
	};

	return (
		<Modal show={show}>
			{currentState !== FINISHED &&
				!errorMsg.includes(
					"Sorry, you do not have sufficient balance in your account for this request."
				) && (
					<ModalHeader
						heading={
							currentStep === MIN_STEP
								? "Fund Goal"
								: "Fund Amount"
						}
						className="p-0"
						headerClassName="setup-modals-header px-sm-5 px-3 py-3"
						dismissable={() => {
							onClose();
							setCurrentStep(MIN_STEP);
							setErrorMsg("");
						}}
					/>
				)}
			<>{renderBasedOnState()}</>
		</Modal>
	);
}

const FundSavings = ({
	goalId,
	currentState,
	currentStep,
	setSuccessMsg,
	setCurrentState,
	goToNextStep,
	setErrorMsg,
	setSuccessResponse,
}) => {
	const { dashboardResponse } = useSelector((state) => ({
		dashboardResponse: state.dashboard.dashboardResponse,
	}));

	const { accountId } = dashboardResponse?.bankAccounts
		? dashboardResponse.bankAccounts[0]
		: {};

	const [debitAccountId, setDebitAccountId] = useState("");

	const initialFormValues = {
		amount: "",
	};

	const handleFunding = async (values) => {
		setCurrentState(LOADING);
		setErrorMsg("");
		try {
			const response = await API.post(goalFundingTrancation, {
				...values,
				debitAccountId,
				goalId,
			});
			if (response.status) {
				setCurrentState(FINISHED);
				setSuccessMsg(response.data.message);
				setSuccessResponse(response.data.data);
			}
		} catch (err) {
			// setCurrentState(ERROR);
			setErrorMsg(getErrorMessage(err));
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
			if (
				getErrorMessage(err).includes(
					"Sorry, you do not have sufficient balance in your account for this request."
				)
			) {
				setCurrentState(ERROR);
			} else {
				setCurrentState(IDLE);
			}
		}
	};

	const renderBasedOnStep = () => {
		switch (currentStep) {
			case 0:
				return (
					<>
						<div
							className={`es-automatic-deposit__debit-account ${
								debitAccountId === accountId ? "selected" : ""
							}`}
							onClick={() => setDebitAccountId(accountId)}
						>
							<ReactSVG src={MintynCircle} />
							<div className="ml-4">
								<h4>Your Mintyn Account</h4>
								<p>
									Fund your account directly from your Mintyn
									Account
								</p>
							</div>
						</div>

						<button
							className="btn create-acc mt-3"
							disabled={debitAccountId !== accountId}
							onClick={goToNextStep}
							style={{ height: "50px" }}
						>
							Continue
						</button>
					</>
				);

			case 1:
				return (
					<>
						<Formik
							initialValues={initialFormValues}
							validationSchema={formValidationSchema}
							onSubmit={(values) => handleFunding(values)}
						>
							{({
								values,
								errors,
								touched,
								handleSubmit,
								setFieldValue,
								handleBlur,
							}) => {
								return (
									<form
										onSubmit={(e) => {
											e.preventDefault();
											handleSubmit();
										}}
									>
										<div className="mb-4">
											<CustomCurrencyInput
												className="es-input"
												name="amount"
												id="amount"
												value={values.amount}
												placeholder="Enter Amount"
												onBlur={handleBlur}
												error={
													touched.amount &&
													errors.amount
												}
												onChange={(value) => {
													setFieldValue(
														"amount",
														parseAmount(value)
													);
												}}
											/>
										</div>

										<button
											className="btn create-acc mt-3"
											disabled={currentState === LOADING}
											type="submit"
										>
											{currentState === LOADING ? (
												<SmallLoader />
											) : (
												"Continue"
											)}
										</button>
									</form>
								);
							}}
						</Formik>
					</>
				);

			default:
				return null;
		}
	};

	return (
		<div className="es-automatic-deposit px-sm-5 px-3 funding">
			<h2>Fund your Emergency savings with</h2>
			{renderBasedOnStep()}
		</div>
	);
};
