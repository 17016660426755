import { SWITCH_MODES, UPDATE_COLOR_SCHEME } from "../actions/types";
import { dayTheme, nightTheme } from "../SwitchModes/themeModes";
// import { retrieveColorScheme } from "../storage/localStorage";

const initState = {
	// theme: retrieveColorScheme(),
	theme: localStorage.getItem("colorScheme") || "light",
};

export default (state = initState, action) => {
	switch (action.type) {
		case SWITCH_MODES:
			return {
				...state,
				theme: state.theme === dayTheme ? nightTheme : dayTheme,
			};

		case UPDATE_COLOR_SCHEME:
			return {
				...state,
				theme: action.payload,
			};

		default:
			return state;
	}
};
