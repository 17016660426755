import React from "react";

const ChatMintSupport = () => {
    return (
        <div className='card'>
            <div className='card-header' id='headingTwo'>
                <h5 className='mb-0'>
                    <button
                        className='btn btn-link'
                        data-toggle='collapse'
                        data-target='#collapseTwo'
                        aria-expanded='false'
                        aria-controls='collapseTwo'>
                        Chat with us
                    </button>
                </h5>
            </div>
            <div
                id='collapseTwo'
                className='collapse'
                aria-labelledby='headingTwo'
                data-parent='#accordion'>
                <div className='card-body'>
                    Send Mintyn Support Team an email support@mintyn.com
                </div>
            </div>
        </div>
    );
};

export default ChatMintSupport;
