import React from "react";
import Helmet from "react-helmet";
import { useLocation, useHistory } from "react-router-dom";
import PageNavigatorV2 from "../../../utils/PageNavigator/PageNavigatorV2";
import InvestmentTransactions from "../InvestmentTransactions/InvestmentTransactions";
import InvestmentDetailsContent from "./InvestmentDetailsContent/InvestmentDetailsContent";

const InvestmentDetails = () => {
	let { state } = useLocation();
	const { push } = useHistory();

	const investment = state?.state || {};

	return (
		<div>
			<Helmet>
				<title>{process.env.REACT_APP_NAME} - Investment Details</title>
			</Helmet>

			<div className="emergency__details primary">
				<span
					className="bigback__btn--link primary"
					onClick={() => push("/investments")}
				>
					Back
				</span>
			</div>

			<div className="mb-4 mt-5">
				<PageNavigatorV2
					title={`View Investment - #Investment ${
						investment?.count || 1
					}`}
					subtitle="See how your investment is doing"
				/>
			</div>

			<div className="row investment">
				<InvestmentDetailsContent investment={investment} />
				<div className="col-md-7 col-12">
					<InvestmentTransactions
						showBorder
						investment={investment}
					/>
				</div>
			</div>
		</div>
	);
};

export default InvestmentDetails;
