import React, { useState } from "react";
import { ReactComponent as EmptyIcon } from "../../../../../../../assets/img/empty.svg";
import DeactivateScheduleBillModal from "../../modals/DeactivateScheduleBillModal/DeactivateScheduleBillModal";
import {
	formatCurrency,
	HumanDateFormat,
	getDayTime,
} from "../../../../../../utils/helpers";

const BillsManagerLists = ({ bills, refetch }) => {
	const [show, setShow] = useState(false);
	const [bill, setBill] = useState({});

	const showModal = (data) => {
		setBill(data);
		setShow(true);
	};

	return (
		<>
			{bills.length > 0 ? (
				bills.map((data, i) => (
					<div
						key={i}
						className="border pointer rounded p-2 low-form form-group"
						onClick={() => showModal(data)}
					>
						<div className="d-flex justify-content-between">
							<label>{data.title}</label>
							<p
								className={`split ${
									data.nextPaymentDate && "text-success"
								}`}
							>
								{data.nextPaymentDate ? "Active" : "In-active"}
							</p>
						</div>

						<div className="d-flex justify-content-between">
							<p className="split mb-0 text-left">
								Amount{" "}
								<span className="d-block mt-2">
									{formatCurrency(data.amount)}
								</span>
							</p>

							<p className="split mb-0 text-right">
								Next Payment{" "}
								<span className="d-block mt-2 text-right">
									{HumanDateFormat(data.nextPaymentDate) +
										" " +
										getDayTime(data.nextPaymentDate)}
								</span>
							</p>
						</div>
					</div>
				))
			) : (
				<div className="text-center mt-5">
					<EmptyIcon height="4rem" />
					<h5 className="mt-4 form-heading">
						You dont have any active bills
					</h5>
				</div>
			)}

			{show && (
				<DeactivateScheduleBillModal
					bill={bill}
					toggleModal={() => setShow(false)}
					refetch={refetch}
				/>
			)}
		</>
	);
};

export default BillsManagerLists;
