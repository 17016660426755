import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import { Helmet } from "react-helmet";
import InfoIcon from "./utils/assets/alert-circle.svg";
import TransactionPin from "./utils/TransactionPin";
import SplitPaymentModal from "./utils/Split PaymentModal";
import Accepted from "./utils/Accepted";
import { useHistory } from "react-router-dom";
import { formatCurrency } from "components/utils/helpers";
import { API } from "lib/api";
import { getErrorMessage } from "components/utils/helpers";
import { ToastsStore } from "react-toasts";
import { paySplitPayment } from "lib/api/apiRoutesConfig/services/BillsService/subRoutes";
import GoBack from "./utils/GoBack";
import { useDispatch, useSelector } from "react-redux";
import { setSuccessModal } from "actions/splitPayment";

const Pay = () => {
	const { showSuccessModal } = useSelector((state) => state.splitPayment);
	const [showPinModal, setShowPinModal] = useState(false);
	const [showSplitPaymentModal, setShowSplitPaymentModal] = useState(false);
	const [showAcceptedModal, setShowAcceptedModal] =
		useState(showSuccessModal);
	const [isLoading, setIsLoading] = useState(false);
	const [pin, setPin] = useState("");

	const history = useHistory();
	const dispatch = useDispatch();

	const { location } = history;

	// Handle next action
	const handleNextAction = () => {
		setShowPinModal(false);
		setShowSplitPaymentModal(true);
	};

	const handleSubmit = async () => {
		setIsLoading(true);
		try {
			const res = await API.put(paySplitPayment, {
				transactionPin: pin,
				id: location.state.data.id,
			});
			const { status } = res || {};
			if (status === 200) {
				// ToastsStore.success(data.message, 6000, "right-toast");
				setShowSplitPaymentModal(false);
				setShowAcceptedModal(true);
				setIsLoading(false);
				dispatch(setSuccessModal(true));
			}
		} catch (err) {
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
			setIsLoading(false);
		}
	};

	return (
		<>
			<Helmet>
				<title>{process.env.REACT_APP_NAME} - Request Details</title>
			</Helmet>
			<section className="pb-5 split-payment ">
				<div className="split-rquests-container ">
					<div className="split-rquests-container__header">
						<h2>{location?.state?.data?.requestName}</h2>
					</div>
					<div className="split-rquests-container__content">
						<div className="manage-participants">
							<div className="participants-summary">
								<p>Request Details</p>
								<Summary data={location.state.data} />
							</div>
							<div className=" info-container">
								<div className="info">
									<ReactSVG src={InfoIcon} />
									<p>Disclaimer</p>
								</div>
								<p className=" content">
									By clicking on pay, you confirm that you
									have reviewed the details of this split
									payment request and you have authorized
									Mintyn MFB to debit your account of the
									split amount and credit the sender of this
									split payment request
								</p>
							</div>

							<button
								onClick={() => {
									setShowPinModal(true);
								}}
								className="btn btn-mint-default"
								type="button"
							>
								Pay
							</button>
						</div>
						<GoBack url="pending-requests" />
					</div>
					<TransactionPin
						show={showPinModal}
						data={location.state.data}
						handleChange={setPin}
						pin={pin}
						isLoading={isLoading}
						onClose={() => {
							setShowPinModal(false);
						}}
						nextModal={() => {
							handleNextAction();
						}}
					/>
					<SplitPaymentModal
						show={showSplitPaymentModal}
						nextModal={() => {
							handleSubmit();
						}}
						onClose={() => {
							setShowSplitPaymentModal(false);
						}}
						isLoading={isLoading}
					/>
					<Accepted
						show={showAcceptedModal}
						onClose={() => {
							setShowAcceptedModal(false);
						}}
					/>
				</div>
			</section>
		</>
	);
};
export default Pay;

const Summary = ({ data }) => {
	return (
		<>
			<div className="pending-participants">
				<div className="content">
					<p className=" title">Request Name</p>
					<p className=" details">{data.requestName}</p>
				</div>
				<div className="content">
					<p className=" title">Description</p>
					<p className=" details desc">{data.description}</p>
				</div>
				<div className="content">
					<p className=" title">Amount to pay</p>
					<p className=" details"> {formatCurrency(data.amount)}</p>
				</div>
				<div className="content">
					<p className=" title">Participants</p>
					<p className=" details">{data.numberOfParticipants}</p>
				</div>
				<div className="content">
					<p className=" title">Sender</p>
					<p className=" details">{data.sender}</p>
				</div>
			</div>
		</>
	);
};
