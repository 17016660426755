/* eslint-disable no-unreachable */
import React, { useState } from "react";
import Modal from "components/utils/Modal/Modal";
import { Formik } from "formik";
import * as yup from "yup";
import ModalHeader from "components/utils/Modal/CustomModalHeader/CustomModalHeader";
import { formatCurrency } from "components/utils/helpers";
import { API } from "lib/api";
import {
	postPayCardAction,
	postVerifyPayCardAction,
} from "lib/api/apiRoutesConfig/services/AccountMS/CAC/subRoutes";
import { getErrorMessage } from "components/utils/helpers";
import { ToastsStore } from "react-toasts";
import { SmallLoader } from "components/utils/SmallLoader";
import { FLUTTERWAVE } from "components/dashboard/enums";
import config from "../../../../src/config";
import imageFiles from "../../../../src/components/common/imageFiles/imageFiles";
import CustomFlutterWaveBtn from "components/dashboard/components/savings/Common/FundGoals/CardFlutterwaveFunding/CustomFlutterWaveBtn";
import FormInputGroup from "components/utils/FormInputGroup/FormInputGroup";
import {
	ENTERPRISE,
	SOLE_PROPRIETORSHIP,
	INCORPORATED_TRUSTEE,
} from "config/enums";
import { ReturnHome } from "./returnHome";
// import { ReturnHome } from "./returnHome";

const formValidationSchema = yup.object().shape({
	email: yup.string().trim().email("*Invalid email").required("*Is required"),
});

const {
	paymentGateway: { PUBLIC_KEY },
} = config;

const ProcessingFee = ({ show, onClose, nextModal, regInfo, businessType }) => {
	const [payCard, setPayCard] = useState(false);
	const [hideModal, setHideModal] = useState(false);

	return (
		<>
			<Modal show={!hideModal ? show : false} scrollable>
				<ModalHeader heading="" className="p-0" />
				<ProcessingFeeContent
					onClose={onClose}
					nextModal={nextModal}
					regInfo={regInfo}
					businessType={businessType}
					payCard={payCard}
					setPayCard={setPayCard}
					setHideModal={setHideModal}
				/>
			</Modal>
		</>
	);
};
export default ProcessingFee;

const ProcessingFeeContent = ({
	onClose,
	nextModal,
	regInfo,
	businessType,
	payCard,
	setPayCard,
	setHideModal,
}) => {
	const [isLoading, setIsLoading] = useState(false);

	const initialFormValues = {
		email: "",
	};

	const [fwConfig, setFwConfig] = useState({
		public_key: PUBLIC_KEY,
		tx_ref: "",
		amount: 100,
		currency: "NGN",
		payment_options: "card,mobilemoney,ussd",
		customer: {
			email: "",
		},
		customizations: {
			title: "Mintyn Digital Bank",
			description: "Business Name Registration Payment",
			logo: imageFiles.icons.mintIcon,
		},
	});

	// generate payment reference
	const generatePaymentRef = async (values) => {
		setIsLoading(true);
		const payload = new FormData();
		payload.append("paymentGateway", FLUTTERWAVE);
		try {
			let res = await API.put(
				postPayCardAction(regInfo?.sessionId),
				payload
			);
			if (res.data) {
				const { amount, paymentReference } = res.data.data;
				setFwConfig({
					...fwConfig,
					customer: {
						email: values.email,
					},
					amount: amount / 100, // kobo
					tx_ref: paymentReference,
				});
				document.querySelector("#flutterwave-btn button").click();
			}
			setIsLoading(false);
		} catch (err) {
			ToastsStore.error(getErrorMessage(err), 6000, "setup-toast");
			setIsLoading(false);
		}
	};

	// verify payment
	const handleVerifyPayment = async () => {
		const { tx_ref } = fwConfig;
		console.log("ref", tx_ref);
		const payload = new FormData();
		payload.append("paymentReference", tx_ref);
		try {
			onClose();
			const res = await API.put(
				postVerifyPayCardAction(regInfo?.sessionId),
				payload
			);
			if (res.data) {
				window.location.reload();
			}
		} catch (err) {
			// window.location.reload();
			console.log("err", err);
		}
	};

	// close modal and reset fwConfig
	const handleClose = () => {
		setPayCard(false);
		setHideModal(true);
		onClose();
		window.location.reload();
		setFwConfig((prev) => ({
			fwConfig: {
				...prev.fwConfig,
				tx_ref: null,
			},
		}));
	};

	const renderTitle = (value) => {
		switch (value) {
			case SOLE_PROPRIETORSHIP:
				return "Business Name Registration application";
				break;

			case ENTERPRISE:
				return "Company Name Registration application";
				break;

			case INCORPORATED_TRUSTEE:
				return "Incorporated Trustee Registration application";
				break;

			default:
				break;
		}
	};
	return (
		<div className="processing-fee-modal">
			<div className=" content">
				<CustomFlutterWaveBtn
					fwConfig={fwConfig}
					handleCallback={handleVerifyPayment}
					handleClose={handleClose}
				/>
				{!payCard ? (
					<>
						<p className="modal-title">
							{!payCard
								? "Processing Fees"
								: "Pay with Debit Card"}
						</p>
						<h4>{renderTitle(businessType)}</h4>
						<p>
							This service costs{" "}
							<span>
								{businessType === SOLE_PROPRIETORSHIP &&
									formatCurrency(
										regInfo?.soleProprietorshipBusinessCost
									)}
								{businessType === ENTERPRISE &&
									formatCurrency(
										regInfo?.enterpriseBusinessCost
									)}
								{businessType === INCORPORATED_TRUSTEE &&
									formatCurrency(
										regInfo?.incorporatedTrusteeCost
									)}
							</span>{" "}
							to process, please select your payment option.
						</p>
					</>
				) : (
					<>
						<h4>Enter your email address</h4>
						<p>
							Enter an email address that you own, payment receipt
							will be sent to this email address.
						</p>
					</>
				)}
			</div>

			{!payCard && (
				<div className=" mb-4 mt-5 col-12  ">
					<button
						className="btn btn-mint-default"
						type="button"
						onClick={() => nextModal("")}
						disabled={false}
					>
						<span>Pay using Mintyn accounts</span>
					</button>
					<button
						onClick={() => setPayCard(true)}
						className="btn btn-mint-default"
						type="button"
						disabled={isLoading}
					>
						{isLoading ? (
							<SmallLoader />
						) : (
							<span>Pay using any debit card</span>
						)}
					</button>
					<ReturnHome className={"back-home-modal"} />
				</div>
			)}

			{payCard && (
				<>
					<Formik
						initialValues={initialFormValues}
						validationSchema={formValidationSchema}
						onSubmit={(values) => generatePaymentRef(values)}
					>
						{({
							values,
							errors,
							touched,
							handleSubmit,
							setFieldValue,
							resetForm,
							handleChange,
							handleBlur,
						}) => {
							return (
								<form
									className="reg-business-form-modal w-100"
									onSubmit={(e) => {
										e.preventDefault();
										handleSubmit();
									}}
								>
									<div className="mb-4 col-12 w-100">
										<FormInputGroup
											id="email"
											name="email"
											label="Email address"
											className="red-business-input"
											placeholder="Input your email address"
											onBlur={handleBlur}
											value={values.email}
											onChange={handleChange}
											error={
												touched.email && errors.email
											}
										/>
									</div>

									<div className=" mb-1 col-12 ">
										<button
											className="btn mb-1 btn-mint-default pay"
											type="submit"
											disabled={isLoading}
										>
											{isLoading ? (
												<SmallLoader />
											) : (
												"Continue"
											)}
										</button>
										<div
											onClick={() => setPayCard(false)}
											role="button"
											className="btn btn-cancel"
										>
											Cancel
										</div>
									</div>
								</form>
							);
						}}
					</Formik>
				</>
			)}
		</div>
	);
};
