import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { logUserOut } from "../../../../actions/loginActions";

const LogOutButtonInput = ({ logUserOut, history }) => {
    const LogOut = async (e) => {
        e.preventDefault();
        await logUserOut();
        history.replace('/sign-in');
        // window.location.href = "/sign-in";
    };

    return (
        <a href='#!' onClick={LogOut}>
            <span className='side-icon logout-icon'></span>
            <span className='side-text'> Log out </span>
        </a>
    );
};

const LogOutButton = withRouter(LogOutButtonInput);
export default connect(null, { logUserOut })(LogOutButton);
