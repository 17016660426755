/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react"
import FadeIn from "components/dashboard/components/effects/FadeIn"
import { Helmet } from "react-helmet";
import BackButton from "components/utils/BackButton/BackButton";
import { useHistory, useLocation } from "react-router-dom";
import "./RepaymentSchedule.scss";
import FormInputGroup from "components/utils/FormInputGroup/FormInputGroup";
import FormDatePickerGroup from "components/utils/FormDatePickerGroup/FormDatePickerGroup";
import EnterPin from "./EnterPin";
import OrderConfirmed from "../PaymentPanel/OrderConfirmed"; 
import { API } from "lib/api";
import { ToastsStore } from "react-toasts";
import { repaymentAction } from "lib/api/apiRoutesConfig/services/tradeMS/BNPL/subRoutes";
import { getErrorMessage } from "components/utils/helpers";
import moment from "moment/moment";

const RepaymentSchedule = () => {
  const location = useLocation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
   const [repaymentDate, setRepaymentDate] = useState(null);
  const [accept, setAccept] = useState(false);
  const [showEnterPinModal, setShowEnterPinModal] = useState(false);
  const [showConfirmedOrderModal, setShowConfirmedOrderModal] = useState(false);
  const [deliveryAddressId, setDeliveryAddressId] = useState('');
  const [debitAccountId, setDebitAccountId] = useState('');
  const [orderId, setOrderId] = useState('')

 const [pin, setPin] = useState("");


  const { amt} = location.state || {};

  // Date now and 30 days from now
 const date =  new Date();
  const priorDate = new Date(new Date().setDate(date.getDate() + 28))
  const tomorrow = new Date(date)

  
	// Handle Enter pin modal
	const handleEnterPinModal = () => {
		setShowEnterPinModal(true)
  }
   const dateNew =  moment(repaymentDate?.toDateString()).format('DD/MM/YYYY');
// Handle next action
  const handleNextAction = () => {
    setShowEnterPinModal(false)
    setShowConfirmedOrderModal(true)
  }
  const handleSubmit = async (values) => {
    setIsLoading(true);

    try {
			const res = await API.post(repaymentAction, {
        authValue:pin,
        debitAccountId:debitAccountId || '',
        deliveryAddressId: deliveryAddressId || '',
        repaymentDate:dateNew || ''
			});
			const { status,data } = res || {};
			if (status === 200) {
        handleNextAction()
        setOrderId(data?.data?.orderId)
				
        setShowConfirmedOrderModal(true)
         
				// ToastsStore.success(data.message, 6000, "right-toast");
			}
    } catch (err) {
      ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		} finally {
			setIsLoading(false);
		}
  }

  useEffect(() => {
    setDeliveryAddressId(localStorage.getItem("addId"))
    setDebitAccountId(localStorage.getItem("deId"))
		}, [debitAccountId,deliveryAddressId,]);
  
  return (
    <>
      <Helmet>
				<title>
					{process.env.REACT_APP_NAME} - Market Place (Pay Later)
				</title>
      </Helmet>
      <FadeIn>
				<section className="pb-5 mkt-cart market-place">
					<div className="mkt-back-button">
            <BackButton onClick={() => {
              localStorage.setItem("st",1)
              history.goBack()}} />
					</div>

					<h1>Pay Later</h1>
          <p>Please note that your account will be charged for repayment on your preferred repayment date</p>
          <div className="mkt-repayment-schedule ">
				    <div className="mkt-repayment-schedule__header">
					      <h2>Repayment Schedule</h2>
            </div>
            <div className="mkt-repayment-schedule__content">
				
								<form
									className="mkt-repayment-form row"
									onSubmit={(e) => {
										e.preventDefault();
										handleSubmit();
									}}
              >
                  <div className="col-12">
										<FormDatePickerGroup
											isClearable={false}
											placeholder="Select date"
											selected={repaymentDate}
                      min={tomorrow.setDate(tomorrow.getDate()+1)}
                      max={priorDate}
                      name="repaymentDate"
                      onChange={(e)=>setRepaymentDate(e)}
											
										/>
                  </div>
                  <p className="mkt-repayment-form-info  col-12 mb-4  ">
											Your repayment date cannot exceed 30 days from first payment.
										</p>
								  <div className="mb-4 col-12 ">
										<FormInputGroup
											id="amt"
                      name="amt"
                      disabled
											value={amt}
											className="mkt-repayment-input"
										/>
                  </div>
                  <div className=" col-12 mb-5 custom-check custom-checkbox">
                    <input
                      onChange={()=>setAccept(!accept)}
                      type="checkbox"
                      className="custom-control-input"
                      id="accept"
                      name="accept"
                      checked={accept}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="accept"
                  >
                    Accept Terms and Conditions.
                  </label>
                  </div>
									<div className="col-12">
                  <button
                    onClick={handleEnterPinModal}
                    className={`btn btn-mint-default text-capitalize `}
                    type="button"
                    disabled={repaymentDate && accept? false : true}
                  >
                    Continue
										</button>
									</div>
								</form>
				    </div>
          </div>

          <EnterPin
            show={showEnterPinModal}
            nextModal={handleSubmit}
            handleChange={setPin}
            pin={pin}
            isLoading={isLoading}
            onClose={() =>setShowEnterPinModal(false)}
          />
          <OrderConfirmed
            title={'Personal Cart Order'}
					rText={'Continue Shopping'}
            content={`Thank you for choosing Mintyn Marketplace, your personal cart order ${ orderId } has been confirmed and is currently being processed.`}
            show={showConfirmedOrderModal}
					  onClose={() => setShowConfirmedOrderModal(false)}
          />
				</section>
			</FadeIn>
    </>
  )
}
export default RepaymentSchedule