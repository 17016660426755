import PropTypes from "prop-types";
import React from "react";

const Beneficiary = ({ row, acceptBankDetails }) => {
    const { accountName, accountNumber, bankName } = row;
    return (
        <div className='mint-row' onClick={() => acceptBankDetails(row)}>
            <div className='block-circle mr-3'>{accountName[0]}</div>
            <div className='taylor'>
                <h6 className='mb-0'>{accountName}</h6>
                <p className='mb-0'>{accountNumber}</p>
            </div>
            <div className='right-side'>
                <span>{bankName}</span>
            </div>
        </div>
    );
};

Beneficiary.propTypes = {
    row: PropTypes.shape({
        accountNumber: PropTypes.string.isRequired,
        accountName: PropTypes.string.isRequired,
        bankName: PropTypes.string.isRequired,
    }),
};

export default Beneficiary;
