import React, { Component } from "react";
import { connect } from "react-redux";
import Chart from "react-apexcharts";
import { formatCurrency, MonthDay } from "../../../../../../utils/helpers";
import { API } from "../../../../../../../lib/api";
import { dashboardTransactionStat } from "../../../../../../../lib/api/apiRoutesConfig/services/FundsManagementMS/dashboard/subRoutes";
import { nightTheme } from "../../../../../../../SwitchModes/themeModes";

class IncomeExpenseBarChart extends Component {
	state = {
		options: {
			chart: {
				height: 400,
				type: "area",
			},
			dataLabels: {
				enabled: false,
			},
			colors: ["#d1a23c", "#231F20"],
			fill: {
				type: "gradient",
				gradient: {
					shadeIntensity: 1,
					opacityFrom: 0.7,
					opacityTo: 0.9,
					stops: [0, 90, 100],
				},
			},
			yaxis: {
				lines: {
					show: false,
				},
				labels: {
					formatter: function (value) {
						return formatCurrency(value);
					},
				},
			},
			xaxis: {
				lines: {
					show: false,
				},
				labels: {
					formatter: function (value) {
						return value;
					},
				},
			},
			legend: {
				show: true,
				position: "top",
			},
		},
		series: [],
	};

	componentDidMount = () => {
		this._isMounted = true;
		this.fetchTranscationStats();
	};

	componentWillUnmount = () => {
		this._isMounted = false;
	};

	static getDerivedStateFromProps(nextProps, prevState) {
		const { theme } = nextProps || {};
		if (theme !== prevState.currentTheme) {
			const colorsArray =
				theme === nightTheme
					? ["#d1a23c", "#FF4C54"]
					: ["#d1a23c", "#231F20"];
			return {
				currentTheme: theme,
				options: {
					...prevState.options,
					colors: colorsArray,
				},
			};
		}
		return null;
	}

	fetchTranscationStats = async () => {
		try {
			const { bankAccounts } = this.props;
			const { accountId } = bankAccounts ? bankAccounts[0] : {};
			const url = dashboardTransactionStat(accountId);
			const res = await API.get(url);
			if (res.status === 200) {
				const { transactionStatisticsList = [] } = res.data.data;
				let incomeSeries = [];
				let expenseSeries = [];
				let dateSeries = [];
				transactionStatisticsList.forEach(
					({ totalIncome, totalExpense, transactionDate }) => {
						const newTranscationDate = new Date(transactionDate);
						const theDay = MonthDay(newTranscationDate);
						incomeSeries.push(totalIncome);
						expenseSeries.push(totalExpense);
						dateSeries.push(theDay);
					}
				);

				this._isMounted &&
					this.setState((prevState) => ({
						...prevState,
						series: [
							{
								name: "Income",
								data: incomeSeries,
							},
							{
								name: "expenses",
								data: expenseSeries,
							},
						],
						options: {
							...prevState.options,
							xaxis: {
								type: "datetime",
								categories: dateSeries,
							},
						},
					}));
			}
		} catch (err) {}
	};

	render() {
		return (
			<div className="shun mt-0">
				<div className="row">
					<div className="mixed-chart">
						<Chart
							options={this.state.options}
							series={this.state.series}
							type="area"
							width="600"
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default connect(
	({ dashboard: { dashboardResponse }, style: { theme } }) => ({
		bankAccounts: dashboardResponse.bankAccounts,
		theme,
	}),
	null
)(IncomeExpenseBarChart);
