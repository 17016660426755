import {
	MINTYN_INTERCOM_USER_HASH,
	MINTYN_USER_REFRESH_TOKEN,
	MINTYN_USER_TOKEN,
} from "components/constants";
import store from "../../../../src/store";
import { logUser } from "../../../actions/loginActions";
import { cookieOptions } from "components/utils/helpers";
import Cookies from "js-cookie";

const pushToDashboard = (LoginResponse, callBack) => {
	const {
		token: { accessToken, refreshToken, userHash },
		...rest
	} = LoginResponse;

	Cookies.set(MINTYN_USER_TOKEN, accessToken.token, cookieOptions);
	Cookies.set(MINTYN_USER_REFRESH_TOKEN, refreshToken.token, cookieOptions);
	Cookies.set(MINTYN_INTERCOM_USER_HASH, userHash, cookieOptions);

	store.dispatch(logUser(rest));
	callBack();
};

export default pushToDashboard;
