import "./RepaymentSchedule.scss";
import React from "react";
import Modal from "components/utils/Modal/Modal";
import ModalHeader from "components/utils/Modal/CustomModalHeader/CustomModalHeader";
import { SmallLoader } from "components/utils/SmallLoader";
import CustomPinInput from "components/utils/CustomPinInput/CustomPinInput";

export default function EnterPin({ show, onClose, nextModal,isLoading, handleChange, pin}) {
	return (
		<Modal show={show} scrollable>
			<ModalHeader
				heading="Enter PIN"
				className="p-0"
				headerClassName="setup-modals-header px-sm-5 px-3 py-3"
				dismissable={() => onClose?.()}
			/>
			<EnterPinForm
				onClose={onClose}
				isLoading={isLoading}
				handleChange={handleChange}
				pin={pin}
				nextModal={nextModal}
			
			/>
		</Modal>
	);
}


const EnterPinForm = ({isLoading,handleChange,onClose,nextModal, pin}) => {

	return (
    <div className="mkt-repayment-modal ">
			<div className="mkt-repayment-modal__content">
				<form
						className="mkt-repayment-modal-form "
						onSubmit={(e) => {
						}}
				>
						
							<CustomPinInput
							hasDashedBorder
							className="my-5"
							length={4}
							secret
							title="Enter Transaction PIN to confirm"
							containerStyle={{ columnGap:'30px'}}
							onChange={(value) => {
							handleChange(value)
							}}
						/>
					{/* <div className="pin-container">
						<span className="sub-text ">Enter Transaction PIN to confirm</span>
						<div className=" col-12">
							<FormInputGroup
								id="pin"
								placeholder=""
								name="pin"
								className="mkt-repayment-modal-input"
								value={pin.pin1}
							onChange={(e) => {
									const value = resolveInput(e.target.value)
									if ( value.length<=1) {
									handleChange({
									...pin,
									pin1: value
								})
									}
							}}
							/>
							<FormInputGroup
								id="pin"
								placeholder=""
								name="pin"
								className="mkt-repayment-modal-input"
								value={pin.pin2}
								onChange={(e) => {
									const value = resolveInput(e.target.value)
									if ( value.length<=1) {
									handleChange({
									...pin,
									pin2: value
								})
									}
							}}
							/>
							<FormInputGroup
								id="pin"
								placeholder=""
								name="pin"
								className="mkt-repayment-modal-input"
								value={pin.pin3}
								onChange={(e) => {
									const value = resolveInput(e.target.value)
									if ( value.length<=1) {
									handleChange({
									...pin,
									pin3: value
								})
									}
							}}
							/>
							<FormInputGroup
								id="pin"
								placeholder=""
								name="pin"
								className="mkt-repayment-modal-input"
								value={pin.pin4}
								onChange={(e) => {
									const value = resolveInput(e.target.value)
									if ( value.length<=1) {
									handleChange({
									...pin,
									pin4: value
								})
									}
							}}
							/>
						</div>
					</div> */}

					<div className="col-12 btn-container">
						<button
							onClick={()=>onClose?.()}
								className="btn btn-mint-default button-outline "
								type="button"
								disabled={isLoading}
							>
								{isLoading ? (
									<SmallLoader />
								) : (
									<>
										Cancel
									</>
								)}
							</button>
						<button
							onClick={()=>nextModal()}
								className="btn btn-mint-default "
								type="button"
							disabled={
								pin===""
									|| isLoading ? true : false}
							>
								{isLoading ? (
									<SmallLoader />
								) : (
									<>
										Confirm Transaction
									</>
								)}
							</button>
						</div>
					</form>
 			</div>
					
    </div>
	);
};
