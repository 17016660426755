import React, { Component } from "react";
import { connect } from "react-redux";
import IdleTimer from "react-idle-timer";
import { kickCustomerOut } from "../../actions/loginActions";

class HandleIdle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            timeout: 1000 * 60 * 1,
            showModal: false,
            userLoggedIn: false,
            isTimedOut: false,
        };
        this.idleTimer = null;
        this.onAction = this._onAction.bind(this);
        this.onActive = this._onActive.bind(this);
        this.onIdle = this._onIdle.bind(this);
    }

    _onAction(e) {
        this.setState({ isTimedOut: false });
    }

    _onActive(e) {
        this.setState({ isTimedOut: false });
    }

    _onIdle(e) {
        const isTimedOut = this.state.isTimedOut;
        if (isTimedOut) {
            const { kickCustomerOut } = this.props;
            kickCustomerOut("Your Session has timed out");
        } else {
            this.idleTimer.reset();
            this.setState({ isTimedOut: true });
        }
    }

    render() {
        return (
            <>
                <IdleTimer
                    ref={(ref) => {
                        this.idleTimer = ref;
                    }}
                    element={document}
                    onActive={this.onActive}
                    onIdle={this.onIdle}
                    onAction={this.onAction}
                    debounce={250}
                    timeout={this.state.timeout}
                />
            </>
        );
    }
}

export default connect(null, { kickCustomerOut })(HandleIdle);
