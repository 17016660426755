import React from "react";
import "./QuickActions.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
	ENTERPRISE,
	INCORPORATED_TRUSTEE,
	INDIVIDUAL,
} from "../../../../../../../config/enums";
import { ToastsStore } from "react-toasts";
import { useState } from "react";
import SavingGoalsCategories from "../../../modals/SavingGoalsCategories/SavingGoalsCategories";

export default function QuickActions() {
	const [showSavingsModal, setShowSavingsModal] = useState(false);

	const { loginResponse, activeAccountType } = useSelector(
		(state) => state.auth
	);

	const accountType =
		activeAccountType === INDIVIDUAL
			? "activeIndividualAccount"
			: "activeCorporateAccount";

	const { currentRole } = loginResponse[accountType]?.profileSetting || {};

	const ACTION_ITEMS = [
		{
			iconName: "top-up-icon",
			iconTitle: "Top Up",
			to: "/top-up",
		},
		{
			iconName: "transer-icon",
			iconTitle: "Transfer",
			to: "/send-money",
		},
		{
			iconName: "pay-bill-icon",
			iconTitle: "Pay Bill",
			to: "/pay-bills",
		},
		{
			iconName: "piggy-icon",
			iconTitle: "Savings",
			to: "/dashboard",
		},
	];

	return (
		<section className="col-lg-6 mt-4 mt-lg-0">
			<div className="quick-actions">
				<h4 className="sub-heading">Quick Actions</h4>
				<div className="quick-actions-row">
					{ACTION_ITEMS.map((item, index) => (
						<ActionLink
							key={index}
							to={item.to}
							iconName={item.iconName}
							iconTitle={item.iconTitle}
							currentRole={currentRole}
							setShowSavingsModal={setShowSavingsModal}
							activeAccountType={activeAccountType}
							isAccountVerified={
								activeAccountType !== INDIVIDUAL
									? loginResponse.activeCorporateAccount
											?.accountVerified
									: true
							}
						/>
					))}
				</div>
			</div>

			<SavingGoalsCategories
				show={showSavingsModal}
				toggleModal={() => setShowSavingsModal(false)}
			/>
		</section>
	);
}

const ActionLink = ({
	to,
	iconName,
	iconTitle,
	isAccountVerified,
	currentRole,
	setShowSavingsModal,
	activeAccountType,
}) => {
	const isApprover = currentRole === "APPROVER";

	const errorMsg = isApprover
		? "Sorry this feature is not accessible to you"
		: "Please complete your profile to get unlimited access to your account";

	return (
		<div className="quick-action">
			<Link
				to={to}
				onClick={(e) => {
					if (!isAccountVerified || isApprover) {
						e.preventDefault();
						ToastsStore.error(errorMsg, 6000, "right-toast");
						return;
					}

					if (iconTitle?.includes("Savings")) {
						e.preventDefault();

						if (
							activeAccountType === ENTERPRISE ||
							activeAccountType === INCORPORATED_TRUSTEE
						) {
							ToastsStore.error(
								"Sorry this feature is not yet accessible to you",
								6000,
								"right-toast"
							);
							return;
						}

						setShowSavingsModal(true);
						return;
					}
				}}
			>
				<div className="action-icon">
					<div className="action-icon-wrapper">
						<span className={iconName}></span>
					</div>
					<h6>{iconTitle}</h6>
				</div>
			</Link>
		</div>
	);
};
