import store from "../../store";

const SelectBoxV2 = (error) => {
	let errorColor = "#ee4b2b";

	let style = {
		control: (styles, { isFocused }) => ({
			...styles,
			color: style.color,
			backgroundColor: "#ebecef",
			height: "52px",
			fontSize: "16px",
			border: error
				? `1px solid ${errorColor} !important`
				: `1px solid transparent !important`,
			borderRadius: "15px !important",
			boxShadow: "none !important",
			textTransform: "capitalize",
		}),
		option: (styles, state) => ({
			...styles,
			backgroundColor: state.isSelected
				? "#CCD8E0"
				: styles.backgroundColor,
			textTransform: "capitalize",
			color: "#111827",
			// "&:hover": {
			// 	color: "#111827",
			// 	backgroundColor: state.isSelected
			// 		? "#CCD8E0 !important"
			// 		: "transparent !important",
			// },
		}),
		valueContainer: (styles, state) => {
			return {
				...styles,
				height: "100%",
				padding: "1px",
				paddingLeft: "16px",
				paddingRight: "16px",
			};
		},
		indicatorSeparator: () => ({ display: "none" }),
		dropdownIndicator: (styles, state) => {
			return {
				...styles,
				color: "#000",
			};
		},
		noOptionsMessage: (styles) => ({
			...styles,
			textAlign: "left",
			borderRadius: "4px !important",
		}),
		menu: (styles) => ({
			...styles,
			minHeight: "70px",
			borderRadius: "4px 4px 0px 0px !important",
			fontSize: "14px",
			backgroundColor: styles.backgroundColor,
		}),
		menuPortal: (base) => ({ ...base, zIndex: 2000 }),
	};

	return style;
};

store.subscribe(() => {
	SelectBoxV2();
});

export { SelectBoxV2 };
