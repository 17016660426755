import React, { Component } from "react";
import Alert from "../../utils/Alert";
import { SmallLoader } from "../../utils/SmallLoader";
import { getErrorMessage } from "../../utils/helpers";
import ValidateForm from "../../utils/Validator";
import { API } from "../../../lib/api";
import { sendFPVerificationCode } from "../../../lib/api/apiRoutesConfig/services/AccountMS/forgot-password/subRoutes";
import axios from "axios";
import PhoneNumberInputV2 from "components/utils/PhoneNumberInput/PhoneNumberInputV2";
import FormInputGroupV2 from "components/utils/FormInputGroup/FormInputGroupV2";

class ForgotPassword extends Component {
	signal = axios.CancelToken.source();
	_isMounted = false;
	state = {
		request: {
			bvn: "",
			phoneNumber: "",
		},
		errors: {},
		alert: {
			alert_newkey: 1,
		},
	};

	componentDidMount = () => {
		this._isMounted = true;
	};

	componentWillUnmount = () => {
		this._isMounted = false;
		this.signal.cancel("canceling network calls");
	};

	setError = (errMsg) => {
		this._isMounted &&
			this.setState((prevState) => ({
				loading: false,
				alert: {
					alert_newkey: prevState.alert.alert_newkey + 1,
					alert_show: "show",
					alert_message: errMsg,
					alert_type: "danger",
				},
			}));
	};

	handleBack = (e) => {
		e.preventDefault();
		this.props.goBack();
	};

	handleInput = (e) => {
		let { name, value } = e.target;
		value = value.replace(/[^0-9]/g, "");
		this._isMounted &&
			this.setState((prevState) => ({
				...prevState,
				errors: {
					...prevState.errors,
					[name]: false,
				},
				request: {
					...prevState.request,
					[name]: value,
				},
			}));
	};

	handlePhone = (__, value, countryData) => {
		let Numbers = value.replace(/[^0-9]/g, "");
		value = Numbers.replace(/^(-?)0+/, "");
		this._isMounted &&
			this.setState((prevState) => ({
				...prevState,
				request: {
					...prevState.request,
					phoneNumber: value,
				},
				errors: {
					...prevState.errors,
					phoneNumber: false,
				},
				dialCode: countryData.dialCode,
			}));
	};

	requestVerificationCode = async () => {
		try {
			this.setState({ loading: true });
			const { request, dialCode } = this.state;
			const requestPayload = { ...request };
			requestPayload.phoneNumber = "+" + dialCode + request.phoneNumber;
			const res = await API.post(
				sendFPVerificationCode,
				{
					...requestPayload,
				},
				{
					cancelToken: this.signal.token,
				}
			);
			if (res.status === 200) {
				const { sessionId } = res.data.data;
				const resetURl = `/forgot-password/${sessionId}/verify-phone`;
				this.props.history.replace(resetURl);
			}
		} catch (err) {
			const error = getErrorMessage(err);
			this.setState({
				request: {
					phoneNumber: "",
					bvn: "",
				},
			});
			this.setError(error);
		}
	};

	handleSubmit = (e) => {
		e.preventDefault();
		const { request } = this.state;
		const { errors, formisValid } = ValidateForm(e, request) || {};
		formisValid
			? (() => {
					this.requestVerificationCode();
			  })()
			: this.setState({ errors });
	};

	render = () => {
		const { loading, request, errors } = this.state;
		const { bvn, phoneNumber } = request;
		const { alert_show, alert_message, alert_newkey, alert_type } =
			this.state.alert;

		return (
			<div>
				<Alert
					key={alert_newkey}
					type={alert_type}
					message={alert_message}
					show={alert_show}
				/>
				<form onSubmit={this.handleSubmit} className="login-form">
					<PhoneNumberInputV2
						label="Phone Number"
						inputFieldName="phoneNumber"
						inputValue={phoneNumber}
						handlePhoneNumberChange={this.handlePhone}
						error={errors["phoneNumber"]}
						isDisabled={loading}
					/>

					<FormInputGroupV2
						label="BVN"
						name="bvn"
						type="text"
						autoComplete="off"
						error={!!errors["bvn"]}
						maxLength={11}
						onChange={this.handleInput}
						value={bvn}
						placeholder=""
					/>

					<button className="btn-primary w-100" disabled={loading}>
						{loading ? <SmallLoader /> : "Reset Password"}
					</button>

					<div className="form-footer">
						<a href="about:blank" onClick={this.handleBack}>
							back to login
						</a>
					</div>
				</form>
			</div>
		);
	};
}

export default ForgotPassword;
