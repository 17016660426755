/* eslint-disable no-unused-vars */
import React,{useState} from "react"
import Modal from "components/utils/Modal/Modal"
import ModalHeader from "components/utils/Modal/CustomModalHeader/CustomModalHeader"
import CustomRadioButton from "components/utils/CustomRadioButton/CustomRadioButton"
import { useDispatch } from "react-redux"
import { PaymentOptionList } from "../utils/exportData"
import { allowConfirmation } from "actions/marketPlace"
import "./PaymentPanel.scss";


const PaymentOptions = ({nextModal,show, onClose, balanceStatus,isChecked,setIsChecked}) => {
  return (
    	<Modal show={show} scrollable>
			<ModalHeader
				heading="Payment"
				className="p-0"
        headerClassName="setup-modals-header px-sm-5 px-3 py-3"
        dismissable={() => onClose?.()}
      />
      <PayOptionList nextModal={nextModal}
        onClose={onClose}
        balanceStatus={balanceStatus}
        isChecked={isChecked}
        setIsChecked={setIsChecked}
      />
		</Modal>
  )
}
export default PaymentOptions

const PayOptionList = ({ nextModal, onClose,balanceStatus,isChecked,setIsChecked }) => {
  const dispatch = useDispatch()
  const PAY_NOW = 'payNow'
  
  
  const handleNextAction = () => {
      onClose()
    if (isChecked !== PAY_NOW) {
      nextModal()
      dispatch(allowConfirmation(false))
    } else {
      dispatch(allowConfirmation(true))
    }
  }
  return (
    <div className="mkt-payment-modal mkt-payment-modal__content">
      {PaymentOptionList && PaymentOptionList.map((list, index) => (
        <div
          key={index}
          className={`mkt-payment-item ${ isChecked===list.name? "selected" : ""}`}
          >
          <CustomRadioButton
            value ={list.name}
            name={list.name}
            checked={isChecked=== list.name}
            onChange ={(e)=>setIsChecked(e.target.value)}
          />
          <div className="mkt-payment-item__content">
            <div>
              <h4>{list.title }</h4>
              <p>{list.sub}</p>
            </div>
          </div>
        </div>
      ))}
      <button
        onClick={handleNextAction}
        className="btn btn-mint-default new-address-btn"
      >
        {balanceStatus && isChecked !== PAY_NOW ? 'Clear Balance':'Continue'}
      </button>
      
      
    </div>
  )
}


