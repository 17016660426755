import React from "react";
import LTTableRow from "./LTTableRow/LTTableRow";
import "../LatestTransactions.scss";

const LTTable = ({ dataList }) => {
    return (
        <>
            {dataList ?
                (dataList.length === 0 ? (
                    <div className='px-3 py-5'>
                        <h6 className='text-center notready mt-5'>
                            No Recent Transactions
                        </h6>
                    </div>
                ) : (
                    <>
                        <table className='table transcation-table mb-0 lt-table'>
                            <tbody>
                                {dataList.map((data, i) => (
                                    <LTTableRow key={i} {...data} />
                                ))}
                            </tbody>
                        </table>
                        {/* <Link
                            to='/transaction-history'
                            className='btn big-button'>
                            Open Full Transaction history
                        </Link> */}
                    </>
                )): ""}
        </>
    );
};

export default LTTable;
