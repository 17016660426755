import MyRequest from "../components/MyRequest";
import PendingRequest from "../components/PendingRequest";
import StartSplitPayment from "../components/StartSplitPayment";
import ManageRequests from "../components/ManageRequests";
import Pay from "../components/Pay";

export const defaultPath = "/split-payment";

export default [
	{
		title: "Pending Requests",
		path: `${defaultPath}/pending-requests`,
		component: PendingRequest,
	},
	{
		title: "My Requests",
		path: `${defaultPath}/my-requests`,
		component: MyRequest,
	},
	{
		title: "Start A Split Payment",
		path: `${defaultPath}/my-requests/start-split-payment`,
		component: StartSplitPayment,
		hide: true,
	},
	{
		title: "Manage Participants",
		path: `${defaultPath}/my-requests/manage-requests`,
		component: ManageRequests,
		hide: true,
	},
	{
		title: "Pay",
		path: `${defaultPath}/pending-requests/pay`,
		component: Pay,
		hide: true,
	},
];
