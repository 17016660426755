import React from "react";
import "../../MarketPlace.scss";
// import { categories } from "./exportData";
import { ReactSVG } from "react-svg";
import MenuIcon from "assets/img/menu_category.svg";
import CategoryIcon from "assets/img/category_icon.svg";
import { ERROR, FINISHED, LOADING } from "config/enums";
import ShineLoader from "components/dashboard/utils/ShineLoader/ShineLoader";
import FailedTryAgain from "components/dashboard/utils/FailedTryAgain/FailedTryAgain";
// import CustomPagination from "components/dashboard/utils/CustomPagination/CustomPagination";

export const LeftSideNav = ({
	categories,
	selectedCategoryCode,
	onSelect,
	errorMsg,
	retry,
	currentStatus,
	page,
	goToPage,
}) => {
	const { records } = categories || {};

	const renderBasedOnStatus = () => {
		switch (currentStatus) {
			case LOADING:
				return (
					<div className="mkt-loading" style={{ marginTop: "25px" }}>
						<ShineLoader />
						<ShineLoader />
						<ShineLoader />
						<ShineLoader />
						<ShineLoader />
						<ShineLoader />
						<ShineLoader />
					</div>
				);

			case ERROR:
				return (
					<div className="mkt-loading">
						<FailedTryAgain
							errorMssg={errorMsg}
							handleTryAgain={retry}
						/>
					</div>
				);

			case FINISHED:
				if (records?.length <= 0)
					return <h6 className="notready">No Category</h6>;

				return (
					<>
						{records?.map((item, index) => (
							<li key={index}>
								<div
									className={`mkt-side-category ${
										selectedCategoryCode === item.code
											? "selected"
											: ""
									}`}
									role="button"
									onClick={() => {
										onSelect(item);
									}}
								>
									<ReactSVG src={CategoryIcon} />
									<span className="mkt-side-text">
										{item.name}
									</span>
								</div>
							</li>
						))}
					</>
				);

			default:
				return null;
		}
	};

	return (
		<>
			<div className=" d-flex flex-column left-side-menu">
				<nav>
					<ul className="pt-0 mkt-categories-list">
						<div className=" d-flex category-title">
							<ReactSVG src={MenuIcon} />
							<span>Categories</span>
						</div>
						{renderBasedOnStatus()}
					</ul>
				</nav>
			</div>
		</>
	);
};
