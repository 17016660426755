import { NCTO_CARD_ACTIVATION_PAYLOAD } from "components/constants";
import CustomPinInput from "components/utils/CustomPinInput/CustomPinInput";
import Cookies from "js-cookie";
import React from "react";
import { Link, Redirect } from "react-router-dom";
import EyeOpenIcon from "assets/img/eye-open-v2.svg";
import EyeCloseIcon from "assets/img/eye-close-v2.svg";
import { ERROR, FINISHED, IDLE, LOADING } from "config/enums";
import { ToastsStore } from "react-toasts";
import { getErrorMessage } from "components/utils/helpers";
import { API } from "lib/api";
import { activateCard } from "lib/api/apiRoutesConfig/services/NCTOService";
import { SmallLoader } from "components/utils/SmallLoader";
import ActivationSuccessModal from "./ActivationSuccessModal";
import ConfirmBackModal from "components/utils/ConfirmBackModal/ConfirmBackModal";
import usePopstate from "components/utils/usePopState";

export default function ActivateCard() {
	const sessionPayload = Cookies.get(NCTO_CARD_ACTIVATION_PAYLOAD);
	const [newPin, setNewPin] = React.useState("");
	const [confirmPin, setConfirmPin] = React.useState("");
	const [showNewPin, setShowNewPin] = React.useState(true);
	const [showConfirmPin, setShowConfirmPin] = React.useState(true);
	const [currentState, setCurrentState] = React.useState(IDLE);
	const [showSuccessModal, setShowSuccessModal] = React.useState(false);
	const [showConfirmBackModal, setShowConfirmBackModal] =
		React.useState(false);

	// eslint-disable-next-line no-unused-vars
	const { isBackButtonClicked } = usePopstate({
		isPrompt: false,
		callback: () => setShowConfirmBackModal(true),
	});

	const { sessionId } = JSON.parse(sessionPayload || "{}") || {};

	function handleShowNewPin() {
		const newPinField = document.getElementById("new-pin");
		newPinField.querySelectorAll("input").forEach((input) => {
			input.type = showNewPin ? "text" : "password";
		});
		setShowNewPin(!showNewPin);
	}

	function handleShowConfirmPin() {
		const confirmPinField = document.getElementById("confirm-new-pin");
		confirmPinField.querySelectorAll("input").forEach((input) => {
			input.type = showConfirmPin ? "text" : "password";
		});
		setShowConfirmPin(!showConfirmPin);
	}

	async function onActivateCard() {
		setCurrentState(LOADING);
		try {
			const res = await API.post(activateCard, {
				sessionId,
				newPin,
				confirmPin,
			});
			const { status } = res || {};
			if (status === 200) {
				setCurrentState(FINISHED);
				setShowSuccessModal(true);
			}
		} catch (err) {
			setCurrentState(ERROR);
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
		}
	}

	React.useLayoutEffect(() => {
		document.querySelectorAll("input").forEach((input) => {
			input.type = "password";
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		window.onbeforeunload = null;
		return () => (window.onbeforeunload = null);
	}, []);

	if (!sessionId) return <Redirect to="/ncto-card-activation" />;

	return (
		<>
			<div className="ncto-main-content">
				<div className="ncto-page-header">
					<h1>Create pin</h1>
					<p>Create Card pin to continue</p>
				</div>

				<div className="ncto-active-card-form">
					<div className="ncto-active-card-form__otp-container">
						<p>Enter Pin</p>
						<div
							id="new-pin"
							className="ncto-active-card-form__otp"
						>
							<CustomPinInput
								length={4}
								onChange={(value) => setNewPin(value)}
							/>
							<button onClick={handleShowNewPin}>
								<img
									alt=""
									width={60}
									height={60}
									src={
										showNewPin ? EyeCloseIcon : EyeOpenIcon
									}
								/>
							</button>
						</div>
					</div>

					<div className="ncto-active-card-form__otp-container">
						<p>Confirm Pin</p>
						<div
							id="confirm-new-pin"
							className="ncto-active-card-form__otp"
						>
							<CustomPinInput
								focus={false}
								length={4}
								onChange={(value) => setConfirmPin(value)}
							/>
							<button onClick={handleShowConfirmPin}>
								<img
									alt=""
									width={60}
									height={60}
									src={
										showConfirmPin
											? EyeCloseIcon
											: EyeOpenIcon
									}
								/>
							</button>
						</div>
						{newPin.length === 4 &&
							confirmPin.length === 4 &&
							confirmPin !== newPin && (
								<span className="ncto-active-card-form__otp-input error-msg">
									PINs don't match
								</span>
							)}
					</div>

					<button
						className="btn btn-mint-default"
						disabled={
							currentState === LOADING ||
							newPin.length < 4 ||
							confirmPin.length < 4 ||
							confirmPin !== newPin
						}
						onClick={onActivateCard}
					>
						{currentState === LOADING ? (
							<SmallLoader />
						) : (
							"Continue"
						)}
					</button>
				</div>

				<Link
					className="ncto-home-button"
					to="/ncto-card-activation"
					replace
				>
					Return to Home
				</Link>
			</div>

			<ActivationSuccessModal show={showSuccessModal} />

			<ConfirmBackModal
				show={showConfirmBackModal}
				onClose={() => setShowConfirmBackModal(false)}
			/>
		</>
	);
}
