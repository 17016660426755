import React, { Component } from "react";
import Axios from "axios";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { showVerify } from "../../../../../../actions/registrationActions";
import { SmallLoader } from "../../../../../utils/SmallLoader";
import Alert from "../../../../../utils/Alert";
import { formatDateToString } from "../../../../../utils/helpers";
import ValidateForm from "../../../../../utils/Validator";
import { API } from "../../../../../../lib/api";
import { getErrorMessage } from "../../../../../utils/helpers";
import { verifyBvnEndpoint } from "../../../../../../lib/api/apiRoutesConfig/services/AccountMS/signup/subRoutes";
import FormDatePickerGroupV2 from "../../../../../utils/FormDatePickerGroup/FormDatePickerGroupV2";
import FormInputGroupV2 from "../../../../../utils/FormInputGroup/FormInputGroupV2";
import ReactHelmet from "../../../../../utils/ReactHelmet/ReactHelmet";
import FadeIn from "../../../../../dashboard/components/effects/FadeIn";
import Logo from "assets/img/new-logo.png";

class SignupFormController extends Component {
	signal = Axios.CancelToken.source();
	_isMounted = false;

	state = {
		request: {
			accountType: "INDIVIDUAL",
			bvn: "",
			lastName: "",
			dateOfBirth: null,
		},
		errors: {},
		loading: false,
		alert: { alert_newkey: 1 },
	};

	componentDidMount = () => {
		this._isMounted = true;
	};

	componentWillUnmount = () => {
		this.signal.cancel("canceling network calls");
		this._isMounted = false;
	};

	setError = (err) => {
		this._isMounted &&
			this.setState((prevState) => ({
				loading: false,
				alert: {
					alert_newkey: prevState.alert.alert_newkey + 1,
					alert_show: "show",
					alert_message: getErrorMessage(err),
					alert_type: "danger",
				},
			}));
	};

	handleBvn = (e) => {
		let { name, value } = e.target;
		let Numbers = value.replace(/[^0-9]/g, "");
		value = Numbers.replace(/^(-?)0+/, "");
		this.setState((prevState) => ({
			...prevState,
			request: {
				...prevState.request,
				[name]: value,
			},
			errors: {
				...prevState.errors,
				[name]: false,
			},
		}));
	};

	handleField = (name, value) => {
		this.setState((prevState) => ({
			...prevState,
			request: {
				...prevState.request,
				[name]: value,
			},
			errors: {
				...prevState.errors,
				[name]: false,
			},
		}));
	};

	initateRegistration = async () => {
		try {
			this.setState({ loading: true });

			const { request } = this.state;
			let { phoneNumber } = this.props.userInfo;

			const res = await API.post(verifyBvnEndpoint, {
				...request,
				phoneNumber: "+" + phoneNumber,
				dateOfBirth: formatDateToString(request?.dateOfBirth),
				uniqueDeviceId: window.navigator.userAgent,
			});
			if (res.status === 200) {
				this.setState({ loading: false });
				const data = res.data.data;
				const { history, showVerify } = this.props;
				// clearStage();
				showVerify({ ...data });
				history.replace({ pathname: "/onboard/confirm-OTP" });
			}
		} catch (err) {
			this.setError(err);
		}
	};

	handleSubmit = (e) => {
		e.preventDefault();
		const { request } = this.state;
		const { errors, formisValid } = ValidateForm(e, request) || {};
		formisValid ? this.initateRegistration() : this.setState({ errors });
	};

	render() {
		const { errors, loading, alert, request } = this.state;
		const { alert_show, alert_message, alert_newkey, alert_type } = alert;
		const { bvn, lastName, dateOfBirth } = request;
		const {
			history,
			userInfo: { phoneNumber },
		} = this.props;
		phoneNumber === "" && history.push("/sign-up");

		return (
			<>
				<ReactHelmet title="Signup with BVN" />
				<div className="auth-container">
					<div className="row h-100 justify-content-center ">
						<div className="col-xl-6 col-lg-8 col-md-9">
							<div className="mintyn-logo">
								<a href="https://mintyn.com/">
									<img
										alt="Mintyn Bank"
										width={500}
										height={500}
										src={Logo}
									/>
								</a>
							</div>
							<section className="">
								<FadeIn>
									<div className="row justify-content-center account-verification-panel centered">
										<div className="col-xl-9 col-lg-9 col-md-9">
											<div>
												<p className="info-heading">
													Bank verification Number is
													required to own an
													operational bank account in
													Nigeria.
												</p>
												<Alert
													key={alert_newkey}
													type={alert_type}
													message={alert_message}
													show={alert_show}
												/>
												<br />
												<form
													className="login-form"
													onSubmit={this.handleSubmit}
												>
													<FormInputGroupV2
														name="bvn"
														label="Bank Verification number (BVN)"
														value={bvn}
														error={!!errors["bvn"]}
														max={11}
														min={11}
														onChange={
															this.handleBvn
														}
													/>

													<FormInputGroupV2
														name="lastName"
														label="Last Name"
														value={lastName}
														error={
															errors["lastName"]
														}
														onChange={(e) =>
															this.handleField(
																"lastName",
																e.target.value
															)
														}
													/>

													<FormDatePickerGroupV2
														label="Date Of Birth"
														name="dateOfBirth"
														selected={dateOfBirth}
														max={new Date()}
														error={
															!!errors[
																"dateOfBirth"
															]
														}
														onChange={(date) =>
															this.handleField(
																"dateOfBirth",
																date
															)
														}
													/>
													<button
														className="btn-primary w-100"
														disabled={loading}
													>
														{loading ? (
															<SmallLoader />
														) : (
															"Continue"
														)}
													</button>

													<div className="form-footer">
														<Link to="/onboard/with-existing-bank-account">
															Use Bank Account
															instead? click here
														</Link>
													</div>
												</form>
											</div>
										</div>
									</div>
								</FadeIn>
							</section>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const BVNVerification = withRouter(SignupFormController);
export default connect(
	(state) => ({ userInfo: state.steps.stepData.personalDetails }),
	{
		showVerify,
	}
)(BVNVerification);
