import React from "react";
import PropTypes from "prop-types";
import { ReactSVG } from "react-svg";
import "./RadioCheckmark.scss";

const RadioCheckmark = ({ selectedRadio, radioKeys, handleRadio }) => {
    return (
        <div className='form-row' onChange={handleRadio}>
            {radioKeys.keys.map(({ label, imgSrc }, i) => {
                return (
                    <div className='col-md-6' key={i}>
                        <label
                            className={`RadioCheckmark__container ${selectedRadio === label &&
                                "active"}`}>
                            {label}
                            <input
                                type='radio'
                                value={label}
                                name={radioKeys.name}
                                defaultChecked={selectedRadio === label}
                            />
                            <div className='broad'>
                                <ReactSVG src={imgSrc} title={label} />
                            </div>
                            <span className='checkmark'></span>
                        </label>
                    </div>
                );
            })}
        </div>
    );
};

RadioCheckmark.defaultProps = {
    selectedRadio: "",
    radioKeys: {
        name: "",
        keys: [
            {
                label: "",
                imgSrc: ""
            }
        ]
    }
};

RadioCheckmark.propTypes = {
    selectedRadio: PropTypes.string.isRequired,
    radioKeys: PropTypes.shape({
        name: PropTypes.string.isRequired,
        keys: PropTypes.array.isRequired
    }),
    handleRadio: PropTypes.func.isRequired
};

export default RadioCheckmark;
