import store from "../../store";
import { nightTheme } from "../../SwitchModes/themeModes";

const SelectBox = (error) => {
	let brandColor = "#d1a23c";
	let errorColor = "#a94442";
	const storeStyle = store.getState().style;
	const isDarkMode = storeStyle.theme === nightTheme;

	let style = {
		control: (styles, { isFocused }) => ({
			...styles,
			color: isDarkMode ? "#fffff" : style.color,
			backgroundColor: isFocused ? "#FFFFFF" : "#FFFFFF", //#FAFAFA
			height: "42px",
			fontSize: "13px",
			letterSpacing: "0.25px",
			border: error
				? `1px solid ${errorColor} !important`
				: `1px solid #EAECF0`,
			borderRadius: "15px !important",
			boxShadow: "none !important",
			textTransform: "capitalize",
			borderColor: isFocused ? brandColor : styles.borderColor,
			"&:hover": {
				borderColor: error
					? errorColor
					: isFocused
					? brandColor
					: "#EAECF0",
			},
		}),
		option: (styles, state) => ({
			...styles,
			backgroundColor: isDarkMode
				? state.isFocused
					? "#000000"
					: "#393536"
				: styles.backgroundColor,
			textTransform: "capitalize",
			color: isDarkMode ? "#FFF" : styles.color,
			"&:hover": {
				color: isDarkMode ? "#FFF" : styles.color,
				backgroundColor: isDarkMode
					? "#000000"
					: styles.backgroundColor,
			},
		}),
		placeholder: (styles, state) => ({
			...styles,
			whiteSpace: "nowrap",
		}),
		indicatorSeparator: () => ({ display: "none" }),
		noOptionsMessage: (styles) => ({
			...styles,
			textAlign: "left",
			borderRadius: "4px !important",
		}),
		menu: (styles) => ({
			...styles,
			minHeight: "70px",
			borderRadius: "4px 4px 0px 0px !important",
			fontSize: "14px",
			backgroundColor: isDarkMode ? "#393536" : styles.backgroundColor,
		}),
		menuPortal: (base) => ({ ...base, zIndex: 2000 }),
	};

	return style;
};

store.subscribe(() => {
	SelectBox();
});

export { SelectBox };
