import ActivateCard from "./steps/ActivateCard";
// import ConfirmPhoneNumber from "./steps/ConfirmPhoneNumber";
import InitialVerificationStep from "./steps/InitialVerificationStep";

const nctoCardActivationRoutes = [
	{
		title: "Home",
		exact: true,
		path: "/",
		component: InitialVerificationStep,
	},
	// {
	// 	title: "Confirm Phone Number",
	// 	path: "/confirm-phone-number",
	// 	component: ConfirmPhoneNumber,
	// },
	{
		title: "Confirm Phone Number",
		path: "/activate-card",
		component: ActivateCard,
	},
];

export default nctoCardActivationRoutes;
