import FadeIn from "components/dashboard/components/effects/FadeIn";
import React from "react";
import { Helmet } from "react-helmet";
import CaretLeft from "assets/img/caret_left_gold.svg";
import { useHistory, useLocation } from "react-router-dom";
import GroomPanel from "components/dashboard/utils/GroomPanel/GroomPanel";
import "./CreateEmergencySaving.scss";
import { useState } from "react";
import InitialStep from "./steps/InitialStep";
import CreateGoalName from "./steps/CreateGoalName";
import FundSavings from "./steps/FundSavings";
import Summary from "./steps/Summary";

const MIN_STEP = 0;
const MAX_STEP = 3;

export default function CreateEmergencySaving() {
	const history = useHistory();
	const location = useLocation();
	const { step } = location.state || {};

	const [currentStep, setCurrentStep] = useState(step || MIN_STEP);
	const [payload, setPayload] = useState({
		name: "",
		autoDebit: true,
		frequency: "",
		startDate: undefined,
		fundingAmount: "",
	});

	const goToNextStep = () => {
		setCurrentStep((prev) => Math.min(prev + 1, MAX_STEP));
	};

	const goToPreviousStep = () => {
		setCurrentStep((prev) => Math.max(prev - 1, MIN_STEP));
	};

	const handleGoBack = () => {
		if (currentStep === MIN_STEP || (step && currentStep === 1)) {
			history.goBack();
			return;
		}
		goToPreviousStep();
	};

	const renderBasedOnStep = () => {
		switch (currentStep) {
			case 0:
				return (
					<InitialStep
						setGoalName={(title) => {
							setPayload({
								...payload,
								name: title,
							});
							goToNextStep();
						}}
						goalName={payload.name}
					/>
				);

			case 1:
				return (
					<CreateGoalName
						goalName={payload.name}
						goToNextStep={(goalName) => {
							setPayload({
								...payload,
								name: goalName,
							});
							goToNextStep();
						}}
					/>
				);

			case 2:
				return (
					<FundSavings
						payload={payload}
						goToNextStep={(values) => {
							setPayload(values);
							goToNextStep();
						}}
					/>
				);

			case 3:
				return <Summary payload={payload} />;

			default:
				return null;
		}
	};

	return (
		<>
			<Helmet>
				<title>
					{process.env.REACT_APP_NAME} - Create Emergency Saving
				</title>
			</Helmet>

			<FadeIn>
				<section className="create-emergency-savings-page pb-5">
					<div className="d-flex align-items-center mb-5 justify-content-between">
						<button
							className="back-btn d-flex align-items-center"
							onClick={() => handleGoBack()}
						>
							<img src={CaretLeft} alt="" />
							<span>Back</span>
						</button>
					</div>

					<h1 className="page-title-text">
						Create new Emergency Savings
					</h1>
					<p className="page-lead-text mb-0">
						Please select your Emergency Savings preferences.
					</p>

					<GroomPanel cls="es-groom mt-5">
						<div
							className={`es-groom__header ${
								currentStep > MIN_STEP ? "shrink" : ""
							}`}
						>
							<span
								className="btn btn-setup-step d-flex align-items-center justify-content-center"
								style={{ cursor: "text" }}
							>
								Step {currentStep + 1}
							</span>
							<span className="step-count">
								{currentStep} of 4 Done
							</span>
						</div>

						{renderBasedOnStep()}
					</GroomPanel>
				</section>
			</FadeIn>
		</>
	);
}
