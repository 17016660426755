import React, { Component } from "react";
import { connect } from "react-redux";
import { setStage, sayVerified } from "../../../../../reducers/steps";
import PhoneNumberOTPVerificaion from "../../Verification/PhoneNumberOTPVerification/PhoneNumberOTPVerificaion";
import WelcomeCustomer from "../WelcomeCustomer/WelcomeCustomer";
import FadeIn from "../../../../dashboard/components/effects/FadeIn";
import Logo from "assets/img/new-logo.png";

class VerificationComplete extends Component {
	componentDidMount = () => {
		window.location.hash = "verif-stage";
		window.location.hash = "still-verif-stage"; //again because google chrome don't insert first hash into history
		window.onhashchange = function () {
			window.location.hash = "verif-stage";
		};
	};
	render = () => {
		const { codeVerify, setStage, sayVerified, history } = this.props;
		const { verifyStage, isVerified } = codeVerify;

		return (
			<div className="auth-container">
				<div className="row h-100 justify-content-center">
					<div className="col-xl-6 col-lg-8 col-md-9">
						<div className="mintyn-logo">
							<a href="https://mintyn.com/">
								<img
									alt="Mintyn Bank"
									width={500}
									height={500}
									src={Logo}
								/>
							</a>
						</div>
						<div className="">
							<FadeIn>
								{isVerified ? (
									<FadeIn>
										<WelcomeCustomer />
									</FadeIn>
								) : (
									<PhoneNumberOTPVerificaion
										verifyStage={verifyStage}
										isVerified={isVerified}
										sayVerified={sayVerified}
										setStage={setStage}
										history={history}
									/>
								)}
							</FadeIn>
						</div>
					</div>
				</div>
			</div>
		);
	};
}
export default connect((state) => ({ codeVerify: state.steps.codeVerify }), {
	setStage,
	sayVerified,
})(VerificationComplete);
