import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import UmbrellaIcon from "assets/img/savings-icons/umbrella_bordered.svg";
import {
	formatCurrency,
	formatEnum,
	getErrorMessage,
} from "components/utils/helpers";
import "../CreateEmergencySaving.scss";
import { ToastsStore } from "react-toasts";
import { API } from "lib/api";
import { createEmergencySavings } from "lib/api/apiRoutesConfig/services/SavingsMS/savingsGoals/subRoutes";
import { useSelector } from "react-redux";
import { format as formatDate } from "date-fns";
import { SmallLoader } from "components/utils/SmallLoader";
import { ERROR, FINISHED, IDLE, LOADING } from "config/enums";
import CreateEmergencyModal from "../../modals/CreateEmergencyModal";
import FundMintAccountModal from "components/dashboard/components/Landing/modals/FundMintAccount/FundMintAccount";

export default function Summary({ payload }) {
	const { name, startDate, autoDebit, fundingAmount, frequency } =
		payload || {};

	const { dashboardResponse } = useSelector((state) => ({
		dashboardResponse: state.dashboard.dashboardResponse,
	}));

	const { accountId: debitAccountId } = dashboardResponse?.bankAccounts
		? dashboardResponse.bankAccounts[0]
		: {};

	const [isAuthorized, setIsAuthorized] = useState(false);
	const [currentState, setCurrentState] = useState(IDLE);
	const [errorMsg, setErrorMsg] = useState("");
	const [successMsg, setSuccessMsg] = useState("");
	const [showModal, setShowModal] = useState(false);
	const [showFundingModal, setShowFundingModal] = useState(false);
	const [goalResponse, setGoalResponse] = useState([]);

	const handleCreateEmergencySavings = async () => {
		setCurrentState(LOADING);
		setErrorMsg("");
		try {
			const res = await API.post(createEmergencySavings, {
				...payload,
				debitAccountId,
				startDate: startDate
					? formatDate(startDate, "dd/MM/yyyy")
					: startDate,
			});
			const { status, data } = res || {};
			if (status === 200) {
				setSuccessMsg(data.message);
				setGoalResponse(data.data.savingsGoals);
				setCurrentState(FINISHED);
				setShowModal(true);
			}
		} catch (err) {
			setCurrentState(ERROR);
			setErrorMsg(getErrorMessage(err));
			ToastsStore.error(getErrorMessage(err), 6000, "right-toast");
			if (
				getErrorMessage(err).includes(
					"You have insufficient balance to fund your savings goal."
				)
			) {
				setShowModal(true);
			}
		}
	};

	return (
		<>
			<div className="create-emergency-savings-page__step shrink">
				<h2>Savings Summary</h2>
				<p>Summary of your savings detail</p>

				<div className="create-emergency-savings-page__summary">
					<div className="d-flex align-items-center justify-content-between">
						<ReactSVG src={UmbrellaIcon} />
						<h3>Not started</h3>
					</div>

					<div className="cesp-summary-info">
						<div>
							<h4>Saving Goal Name</h4>
							<h5>{name}</h5>
						</div>

						<div>
							<h4>Start Date</h4>
							<h5>
								{autoDebit && startDate
									? new Date(startDate)
											.toLocaleDateString([], {
												year: "numeric",
												month: "short",
												day: "numeric",
											})
											.replace("at", "/")
									: new Date()
											.toLocaleDateString([], {
												year: "numeric",
												month: "short",
												day: "numeric",
											})
											.replace("at", "/")}
							</h5>
						</div>

						<div>
							<h4>Fund with</h4>
							<h5>{formatCurrency(fundingAmount)}</h5>
						</div>

						<div>
							<h4>Frequency</h4>
							<h5 className="text-capitalize">
								{frequency && autoDebit
									? formatEnum(frequency)
									: "N/A"}
							</h5>
						</div>
					</div>

					<div className="mb-4 d-flex custom-control custom-checkbox d-flex align-items-center">
						<input
							type="checkbox"
							className="custom-control-input"
							id="isAuthorized"
							name="isAuthorized"
							onChange={() => {
								setIsAuthorized(!isAuthorized);
							}}
							checked={isAuthorized}
						/>
						<label
							className="custom-control-label"
							htmlFor="isAuthorized"
						>
							I hereby authorize Mintyn to fund this goal with{" "}
							{formatCurrency(fundingAmount)} and make it
							available for withdrawal
						</label>
					</div>

					<button
						className="btn create-acc mt-3 text-capitalize"
						disabled={currentState === LOADING || !isAuthorized}
						style={{ borderRadius: "15px", height: "50px" }}
						onClick={handleCreateEmergencySavings}
					>
						{currentState === LOADING ? (
							<SmallLoader />
						) : (
							"Fund Goal"
						)}
					</button>
				</div>
			</div>

			<CreateEmergencyModal
				show={showModal}
				currentState={currentState}
				errorMsg={errorMsg}
				successMsg={successMsg}
				onClose={() => setShowModal(false)}
				setCurrentState={(state) => setCurrentState(state)}
				setErrorMsg={(message) => setErrorMsg(message)}
				openFundingModal={() => setShowFundingModal(true)}
				goalResponse={goalResponse}
			/>

			<FundMintAccountModal
				show={showFundingModal}
				dismissModal={() => setShowFundingModal(false)}
			/>
		</>
	);
}
