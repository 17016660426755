import VerificationComplete from "../../Onboarding/ProfileCompletion/VerificationComplete/VerificationComplete";
import AccountVerification from "../../Onboarding/Verification/AccountVerification/AccountVerification";
import BVNVerification from "../../Onboarding//Verification/AccountVerification/BVNVerification/BVNVerification";
import ExisitingBankAccountVerification from "../../Onboarding/Verification/AccountVerification/ExisitingBankAccountVerification/ExisitingBankAccountVerification";
import AccountTypeVerification from "../ProfileCompletion/AccountTypeVerification/AccountTypeVerification";
import BasicInformation from "../ProfileCompletion/BasicInformation/BasicInformation";
import ProfileCompleted from "../ProfileCompletion/ProfileCompleted/ProfileCompleted";

export default [
	{
		title: "Verification Complete",
		exact: true,
		path: "/confirm-OTP",
		component: VerificationComplete,
	},
	{
		title: "Choose Verification Option",
		path: "/",
		exact: true,
		component: AccountVerification,
	},
	{
		title: "BVN Verification",
		path: "/with-bvn",
		exact: true,
		component: BVNVerification,
	},
	{
		title: "Bank Account Verification",
		path: "/with-existing-bank-account",
		exact: true,
		component: ExisitingBankAccountVerification,
	},
	{
		title: "Account Type Verification",
		path: "/account-type-verification",
		exact: true,
		component: AccountTypeVerification,
	},
	{
		title: "Completed",
		path: "/complete",
		exact: true,
		component: ProfileCompleted,
	},
	{
		title: "Basic Information",
		path: "/basic-information",
		exact: true,
		component: BasicInformation,
	},
];
