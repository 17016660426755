import React from "react";
import { ReactSVG } from "react-svg";
import Delete from "./assets/Delete.svg";
import { formatCurrency } from "components/utils/helpers";
import CustomCurrencyInput from "components/utils/CustomCurrencyInput/CustomCurrencyInput";
import { CUSTOM, EQUAL } from "config/enums";
import { parseAmount } from "components/utils/helpers";

const ParticipantsCard = ({
	name,
	initAmt,
	bal,
	amt,
	position,
	isInitiator = false,
	remove,
	splitOption,
	setInitiatorCustomAmt,
	customAmt,
}) => {
	return (
		<div className="participants-card-container">
			<div className="participants-card">
				<div>
					<p className=" name">{name}</p>

					{splitOption === EQUAL && (
						<p className=" amt"> {formatCurrency(amt)}</p>
					)}
					{!isInitiator && splitOption === CUSTOM && (
						<p className=" amt"> {formatCurrency(customAmt)}</p>
					)}
					{isInitiator && splitOption !== EQUAL && (
						<div>
							<CustomCurrencyInput
								id="amount"
								name="amount"
								className="split-request-input"
								placeholder="Enter amount"
								value={splitOption !== CUSTOM ? amt : customAmt}
								onChange={(value) => {
									setInitiatorCustomAmt(parseAmount(value));
								}}
								error={
									bal > initAmt && customAmt !== ""
										? "Amount is higher"
										: ""
								}
							/>
						</div>
					)}
				</div>
				<p className="user-state">{position}</p>
			</div>
			{!isInitiator && (
				<div
					onClick={() => {
						remove();
					}}
					className="delete"
				>
					<ReactSVG src={Delete} />
				</div>
			)}
		</div>
	);
};
export default ParticipantsCard;
