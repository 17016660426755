import { formatCurrency } from "components/utils/helpers";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { GROUP_CART } from "../utils/constant";
import "./CartSummary.scss";

export default function CartSummary({ header, cartType, hideCheckoutButton, goToNextStep, currentStep, isAddress}) {
	const history = useHistory();
	const { cart } = useSelector((state) => state.marketPlace);

	const { groupCart, personalCart } = cart || {};
	const currentCart = cartType === GROUP_CART ? groupCart : personalCart;
	const { deliveryCost, totalItemCost } = currentCart || {};

	const totalCost = deliveryCost + totalItemCost;

	return (
		<div className="mkt-cart-summary">
			<div className="mkt-cart-summary__header">
				<h2>{header}</h2>
			</div>

			<div className="mkt-cart-summary__content">
				<div>
					<div className="mkt-cart-summary-info">
						<span>Cost of goods</span>
						<span>{formatCurrency(totalItemCost)}</span>
					</div>
					<div className="mkt-cart-summary-info">
						<span>Delivery Fee</span>
						<span>{formatCurrency(deliveryCost)}</span>
					</div>
					<div className="mkt-cart-summary-info">
						<span>Total Cost</span>
						<span>{formatCurrency(totalCost)}</span>
					</div>
				</div>

				{currentStep=== 0 && isAddress?.length > 0  && (
					<button
						className="btn btn-mint-default"
						onClick={goToNextStep}
					>
						Continue
					</button>
				)}

					{currentStep=== 4  &&(
					<button
						className="btn btn-mint-default"
						onClick={ () =>
							history.push("/market-place/delivery-details", {
								cartType,
							})
						}
					>
						Proceed to Checkout
					</button>
				)}
			</div>
		</div>
	);
}
