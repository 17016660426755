import CustomTabs from "components/utils/CustomTabs/CustomTabs";
import React from "react";
import Helmet from "react-helmet";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";
import topUpRoutes, { defaultPath } from "./topUpRoutes";
import "./TopUp.scss";
import FadeIn from "../effects/FadeIn";
import { useSelector } from "react-redux";
import { INDIVIDUAL } from "config/enums";
import UserRoleWarning from "components/utils/UserRoleWarning/UserRoleWarning";

export default function TopUp() {
	const { loginResponse, activeAccountType } = useSelector(
		(state) => state.auth
	);

	const accountType =
		activeAccountType === INDIVIDUAL
			? "activeIndividualAccount"
			: "activeCorporateAccount";

	const { currentRole } = loginResponse[accountType]?.profileSetting || {};
	const isApprover = currentRole === "APPROVER";

	return (
		<>
			<Helmet>
				<title>{process.env.REACT_APP_NAME} - Top Up</title>
			</Helmet>

			<FadeIn>
				<div className="py-5">
					<h1 className="page-title-text">Top Up</h1>
					<p className="page-lead-text">
						Perform all top up transactions here.
					</p>

					<CustomTabs className="mt-5">
						{topUpRoutes.map((route, index) => (
							<li className="nav-item" key={index}>
								<NavLink className="nav-link" to={route.path}>
									{route.title}
								</NavLink>
							</li>
						))}
					</CustomTabs>

					<div className="top-up-panel">
						{isApprover ? (
							<UserRoleWarning />
						) : (
							<Switch>
								{topUpRoutes.map((route, index) => (
									<Route
										key={index}
										exact
										path={route.path}
										component={route.component}
									/>
								))}
								<Redirect
									path="*"
									to={`${defaultPath}/airtime`}
								/>
							</Switch>
						)}
					</div>
				</div>
			</FadeIn>
		</>
	);
}
