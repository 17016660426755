import React, { Component } from "react";
import { connect } from "react-redux";
import CreateTranscationPin from "../../../../../../security/CreateTranscationPin";
import CollectExistingPin from "./CollectExistingPin/CollectExistingPin";

class AcceptPinInput extends Component {
	render() {
        
		const {
			toggleModal,
			customerSettings,
			confirmTranscation,
		} = this.props;
		const { transactionPinCreated } = customerSettings || {};

		return (
			<>
				{transactionPinCreated ? (
					<CollectExistingPin
						confirmTranscation={confirmTranscation}
						toggleModal={toggleModal}
					/>
				) : (
					<CreateTranscationPin
						ConfirmTranscation={confirmTranscation}
						toggleModal={toggleModal}
					/>
				)}
			</>
		);
	}
}

export default connect(
	(state) => ({
		customerSettings: state.settings.profileSettings,
	}),
	null
)(AcceptPinInput);
