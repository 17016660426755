import React, { useState } from "react";
import "./SwitchAccountPanel.scss";
import CloseIcon from "../../../assets/img/close-icon.svg";
import CustomRadioButton from "../CustomRadioButton/CustomRadioButton";
import { formatEnum, getErrorMessage } from "../helpers";
import { LOADING, FINISHED, ERROR, IDLE } from "../../../config/enums";
import { ToastsStore } from "react-toasts";
import { API } from "../../../lib/api";
import { switchAccount } from "../../../lib/api/apiRoutesConfig/services/AccountMS/self-service/subRoutes";
import { TinyLoad } from "../SmallLoader";
import { useHistory } from "react-router-dom";
import pushToDashboard from "../../auth/Login/pushToDashboard";
import PropTypes from "prop-types";

export default function SwitchAccountPanel({
	show,
	onClose,
	accounts,
	activeAccountId,
}) {
	const [currentStatus, setCurrentStatus] = useState(IDLE);
	const history = useHistory();

	const goToDashboard = () => {
		history.push("/dashboard");
	};

	const handleSwitchAccount = async (accountId) => {
		setCurrentStatus(LOADING);
		try {
			const res = await API.put(switchAccount, {
				accountId,
			});
			if (res.status === 200) {
				setCurrentStatus(FINISHED);
				onClose();
				pushToDashboard(res.data.data, goToDashboard);
			}
		} catch (err) {
			const errorMsg = getErrorMessage(err);
			ToastsStore.error(errorMsg, 6000);
			setCurrentStatus(ERROR);
		}
	};

	const copyAccountDetails = (accountName, accountNumber) => {
		const copyValue = `Account Name: ${accountName} \nAccount Number: ${accountNumber}`;
		navigator.clipboard.writeText(copyValue);
		ToastsStore.success(
			"Account details copied to clipboard",
			6000,
			"right-toast"
		);
		onClose();
	};

	return (
		<div
			className={`switch-account-menu ${show ? "show" : ""}`}
			onClick={(e) => e.stopPropagation()}
		>
			<div className="d-flex justify-content-between align-items-center switch-account-menu-header">
				<div className="d-flex align-items-center">
					<h6 className="mr-2">Accounts</h6>
					{currentStatus === LOADING && <TinyLoad />}
				</div>
				<button
					onClick={(e) => {
						onClose();
					}}
				>
					<img src={CloseIcon} alt="" />
				</button>
			</div>

			<ul className="switch-account-menu-accounts">
				{accounts
					?.filter(
						(account) => account?.accountId !== activeAccountId
					)
					.map((account, index) => (
						<AccountItem
							key={index}
							accountName={account?.accountName}
							accountNumber={account?.primaryAccountNumber}
							accountType={formatEnum(account?.accountType)}
							// isActive={account?.accountId === activeAccountId}
							onSelect={() => {
								// if (account?.accountId === activeAccountId) return;
								handleSwitchAccount(account.accountId);
							}}
							onCopy={() =>
								copyAccountDetails(
									account?.accountName,
									account?.primaryAccountNumber
								)
							}
						/>
					))}
			</ul>
		</div>
	);
}

const AccountItem = ({
	isActive,
	onSelect,
	accountName,
	accountNumber,
	accountType,
	onCopy,
}) => {
	return (
		<li className="d-flex align-items-center justify-content-between">
			<div
				className="switch-account-account-details d-flex"
				onClick={() => onSelect()}
			>
				<CustomRadioButton
					checked={isActive}
					name={accountName + " " + accountNumber}
					onChange={() => onSelect()}
				/>
				<div className="switch-account-account-info">
					<h6>{accountName}</h6>
					<span>
						{accountNumber} &#x2022; {accountType}
					</span>
				</div>
			</div>
			<button className="btn btn-copy" onClick={() => onCopy()}>
				Copy
			</button>
		</li>
	);
};

SwitchAccountPanel.propTypes = {
	show: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	accounts: PropTypes.array.isRequired,
	activeAccountId: PropTypes.string.isRequired,
};
