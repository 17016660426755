import {
	SET_SPLITPAYMENT,
	SET_PARTICIPANT,
	NEXT_STEP,
	SET_SUCCESS_MODAL,
} from "../actions/types";

const initState = {
	step: 1,
	participants: [],
	showSuccessModal: false,
	splitPayment: {
		name: "",
		description: "",
		amount: "",
		creditAccountId: "",
		splitPaymentType: "",
	},
};

export default (state = initState, action) => {
	switch (action.type) {
		case NEXT_STEP:
			return {
				...state,
				step: action.payload,
			};

		case SET_SPLITPAYMENT:
			return {
				...state,
				splitPayment: {
					name: action.payload.name,
					description: action.payload.description,
					amount: action.payload.amount,
					creditAccountId: action.payload.creditAccountId,
					splitPaymentType: action.payload.splitPaymentType,
				},
			};

		case SET_PARTICIPANT:
			return {
				...state,
				participants: action.payload,
			};

		case SET_SUCCESS_MODAL:
			return {
				...state,
				showSuccessModal: action.payload,
			};

		default:
			return state;
	}
};
