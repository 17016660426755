export const SUCCESSFUL = "00";
export const PENDING = "01";
export const FAILED = "02";

export const MINT = "MINT";
export const FLUTTERWAVE = "FLUTTERWAVE";

export const VERIFIED = "VERIFIED";
export const PENDING_V = "PENDING";
export const NO_ID_CARD = "NO_ID_CARD";
export const FAILED_V = "FAILED";

export const INITIAL = "INITIAL";
export const SUCCESS = "SUCCESS";
export const ERROR = "ERROR";

export const IDTypes = [
	{
		label: "NATIONAL_ID",
		value: "NATIONAL_ID",
	},
	{
		label: "DRIVERS_LICENCE",
		value: "DRIVERS_LICENCE",
	},
	{
		label: "VOTERS_CARD",
		value: "VOTERS_CARD",
	},
	{
		label: "INTERNATIONAL_PASSPORT",
		value: "INTERNATIONAL_PASSPORT",
	},
	{
		label: "CORPORATE_ID",
		value: "CORPORATE_ID",
	},
	{
		label: "STUDENT_ID",
		value: "STUDENT_ID",
	},
	{
		label: "OTHERS",
		value: "OTHERS",
	},
];
