import React from "react";
import FadeIn from "../../../../../dashboard/components/effects/FadeIn";
import Playstore from "assets/img/new/playstore-download.png";
import AppStore from "assets/img/new/app-store-download.png";
import { useHistory } from "react-router-dom";

const ProfileCompletedContent = () => {
	const history = useHistory();

	return (
		<section className="account-verification-panel">
			<FadeIn>
				<div className="row justify-content-center">
					<div className="col-xl-9 col-lg-9 col-md-9">
						<h3 className="info-heading text-center">
							Your Free Current Account is Ready!!!
						</h3>

						<div className="completion-guardlines">
							<p>1. Click on the Appstore or Playstore icon</p>
							<p>2. On the app, click Create Account.</p>
							<p> 3. Input your phone number.</p>
							<p>
								4. Follow the prompt to access your account
								number.
							</p>
						</div>

						<button
							className="btn-primary w-100"
							onClick={() => history.replace("/sign-in")}
						>
							Done
						</button>

						<div className="completion-download-buttons">
							<a
								href="https://apps.apple.com/us/app/mymintapp/id1470644607?ls=1"
								target="_blank"
								rel="noopener noreferrer"
							>
								<img
									src={AppStore}
									alt="Get Mintyn app on App Store"
								/>
							</a>

							<a
								href="https://play.google.com/store/apps/details?id=com.mintfintech.app&hl=en"
								target="_blank"
								rel="noopener noreferrer"
							>
								<img
									src={Playstore}
									alt="Get Mintyn app on Playstore"
								/>
							</a>
						</div>

						<p className="completion-footer-text">
							Get 2,000 Naira when your friend opens a free Mintyn
							current account using your referral code. Your
							friend will get 300 Naira free airtime top-up, using
							your code.
						</p>
					</div>
				</div>
			</FadeIn>
		</section>
	);
};

export default ProfileCompletedContent;
