import React from "react";
import ArrowRight from "assets/img/arrow-right.svg";
import { ReactSVG } from "react-svg";
import "../../MarketPlace.scss";
import ProductCard from "../ProductCard/ProductCard";
import { useHistory } from "react-router-dom";

export default function PopularItems({ items = [],  cartType, viewProducts }) {
	const history = useHistory();

	return (
		<div className="mkt-popular-items ">
			<div className="d-flex align-items-center mkt-inner-items justify-content-between">
				<h3>Popular items</h3>
				<button onClick={viewProducts} className="btn view-all-btn">
					View all <ReactSVG src={ArrowRight} />
				</button>
			</div>

			{/* Items (Products) */}
			<div className="products">
				{items?.slice(0,3).map((product, index) => {
								return (
									<ProductCard
										key={index}
										product={product}
										cartType={cartType}
										onClick={() =>
											history.push(
												"/market-place/product-details",
												{ product, cartType }
											)
										}
									/>
								);
							})}
				
			</div>
		</div>
	);
}
