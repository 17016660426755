import React, { Component } from "react";
import LatestTransactions from "./LatestTransactions/LatestTransactions";
import IncomeExpense from "./IncomeExpense/IncomeExpense";
// import TransactionsHistoryWidget from "../widgets/TransactionsHistoryWidget";
// import BalanceWidget from "../widgets/BalanceWidget";

export default class IncomeExpenseTranscationHistory extends Component {
    render = () => {
        return (
            <section className='mt-45'>
                <div className='row g-5'>
                    <LatestTransactions />
                    <IncomeExpense/>
                    {/* <BalanceWidget /> */}
                    {/* <TransactionsHistoryWidget /> */}
                </div>
            </section>
        );
    };
}
