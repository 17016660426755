import React, { useEffect, useRef, useState } from "react";
import { formatFloatInput } from "../helpers";
import "./CustomCurrencyInput.scss";

export default function CustomCurrencyInput({
	label,
	type = "text",
	id,
	name,
	placeholder = "",
	value,
	className = "",
	readonly,
	error,
	max = 12,
	min,
	onFocus,
	onChange,
	required,
	currency = "₦",
	...rest
}) {
	const [inputValue, setInputValue] = useState(value);
	const currencyRef = useRef(null);
	const [inputPaddingLeft, setInputPaddingLeft] = useState("12px");

	const pushUpdate = (formattedInputValue) => {
		setInputValue(formattedInputValue);
		onChange(formattedInputValue);
	};

	const handleChange = (e) => {
		const [firstDigit] = e.target.value;
		if (firstDigit === "." || firstDigit === "0") return "";
		const formattedInputValue = formatFloatInput(e.target.value);
		pushUpdate(formattedInputValue);
	};

	useEffect(() => {
		if (!value) {
			setInputValue("");
		} else {
			setInputValue(formatFloatInput(value.toString()));
		}
	}, [value]);

	useEffect(() => {
		if (inputValue) {
			setInputPaddingLeft(`${currencyRef.current?.offsetWidth + 15}px`);
		} else {
			setInputPaddingLeft("12px");
		}
	}, [inputValue]);

	return (
		<div className="custom-currency-input">
			{label && <label htmlFor={id}>{label}</label>}

			<div className="custom-currency-input-container">
				{inputValue && (
					<span
						ref={currencyRef}
						className="custom-currency-container"
					>
						{currency}
					</span>
				)}

				<input
					type={type}
					id={id}
					name={name}
					placeholder={placeholder}
					value={inputValue}
					className={`form-control ${
						error ? "has-error" : ""
					} ${className}`}
					readOnly={readonly}
					autoComplete="off"
					maxLength={max}
					minLength={min}
					onFocus={onFocus}
					onChange={handleChange}
					required={required}
					style={{ paddingLeft: inputPaddingLeft || "12px" }}
					{...rest}
				/>
			</div>

			{error && <span className="error-msg">{error}</span>}
		</div>
	);
}
